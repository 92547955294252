export const getFlatViewWithRootProduct = (flatView, selectedInventoryTreeProduct) => {
  let flatViewWithRootProduct
  if (flatView && flatView.id && selectedInventoryTreeProduct) {
    flatViewWithRootProduct = {
      flatView,
      items: [ {
        productId: selectedInventoryTreeProduct.id,
        productUnit: selectedInventoryTreeProduct.unit,
        productName: selectedInventoryTreeProduct.name,
        impactAmount: flatView.totalImpact.amount,
        impactUnit: flatView.totalImpact.unit,
        impactPercentageFromRoot: 100,
        relativeImpactPercentage: 100,
        productAmount: 1,
      }, ...flatView.items ]
    }
  }
  return flatViewWithRootProduct
}