import { Environment } from '../enum'

export const downloadByUrl = (filePath: string) => {
  const { port, hostname, protocol } = window.location
  window.location.assign(`${protocol}//${hostname}:${port}${filePath}`)
}

export const getEnvFromUrl = (): Environment | null => {
  const { hostname } = window.location

  if (!hostname) return null

  switch (hostname) {
  case 'localhost':
    return Environment.dev
  case 'mobius.ecochain-tst.com':
  case 'internal.ecochain-tst.com':
    return Environment.tst
  case 'alpha.ecochain.com': // to be removed
  case 'mobius.ecochain-acc.com':
  case 'internal.ecochain-acc.com':
    return Environment.acc
  case 'mobius-prd.ecochain.com':
  case 'mobius.ecochain.com':
    return Environment.prd
  default:
    return Environment.unknown
  }
}

type NavigationValue = {
  pathname: string
  data: any
}

export const getNavigationStorageItem = (key: string) => {
  const navigation = localStorage.getItem('NAVIGATION')
  if (navigation) {
    return JSON.parse(navigation)[key]
  }
}

export const setNavigationStorageItem = (key: string, value: NavigationValue) => {
  const navigation = localStorage.getItem('NAVIGATION')
  const navigationObj = navigation ? JSON.parse(navigation) : {}
  localStorage.setItem('NAVIGATION', JSON.stringify({
    ...navigationObj,
    [key]: value
  }))
}

export const removeNavigationStorageItem = (key: string) => {
  const navigation = localStorage.getItem('NAVIGATION')
  if (navigation) {
    const navigationObj = JSON.parse(navigation)
    delete navigationObj[key]
    localStorage.setItem('NAVIGATION', JSON.stringify(navigationObj))
  }
}
