import React from 'react'
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { IColoredTagProps } from './coloredTag.interface'
import { normalize } from '../../../utils/tools'
import { DEFAULT_TRANSPARENCY_LVL } from '../../../utils/const'

const ColoredTag: React.FC<IColoredTagProps> = props => {
  const { tag, isClosable, onClose, isLoading } = props

  const TagIcon = () => <>
    { isLoading ?
      <LoadingOutlined className='close-colored-tag-icon' /> :
      <CloseOutlined className='close-colored-tag-icon' onClick={() => onClose && onClose()}/>
    }
  </>

  return <div
    data-cy={normalize(tag.name)}
    data-testid='colored-tag'
    className='colored-tag'
    style={{ color: tag.color, backgroundColor: tag.color + DEFAULT_TRANSPARENCY_LVL  }}>
    {tag.name}

    { isClosable ? <TagIcon/> : null }
  </div>
}

export default ColoredTag