import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { useReactiveVar } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { isValid, parseJsonString } from '../util/tools'
import { selectedWorkspaceVar, userVar } from '../../../graphql/cache'
import { getEnvFromUrl } from '../util/navigation'
import { Environment } from '../enum'

import { ImportReportComponent } from '../component/product/import/import-report.component'
import { ImportConfirmUploadComponent } from '../component/product/import/import-confirm-upload.component'
import { ImportExampleStructureComponent } from '../component/product/import/import-example-structure.component'
import { ImportUploadCsvComponent } from '../component/product/import/import-upload-csv.component'
import { ImportDownloadCsvComponent } from '../component/product/import/import-download-csv.component'
import DashboardContext from '../../dashboard/context/dashboard.context'

type TImportProductContainer = {
  afterImportProduct?: Function
}

export const ImportProductContainer = ({
  afterImportProduct
}: TImportProductContainer) => {
  const { t } = useTranslation([ 'common' ])
  const { id: userID = '' } = useReactiveVar(userVar) || {}
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { showImportProductDialog, updateDashboard } = useContext(DashboardContext)
  const { getIdTokenClaims } = useAuth0()

  const [ token, setToken ] = useState(null)
  const [ successImportReport, setSuccessImportReport ] = useState<any>(null)
  const [ failedImportReport, setFailedImportReport ] = useState<any>(null)
  const [ file, setFile ] = useState(null)
  const [ fileUploading, setFileUploading ] = useState(false)
  const [ showWarningMessage, setShowWarningMessage ] = useState(false)

  const environment = getEnvFromUrl()

  useEffect(() => {
    const loadToken = async () => {
      const { __raw: token = '' } = await getIdTokenClaims() as any
      setToken(token)
    }

    if (!isValid(token)) {
      loadToken()
    }
  }, [ token ])

  const getUploadAction = () => {
    const { hostname, protocol } = window.location
    return environment !== Environment.dev
      ? `${protocol}//${hostname}/product-import`
      : `${protocol}//${hostname}:7500/product-import`
  }

  const resetReport = () => {
    setFileUploading(false)
    setFile(null)
    setShowWarningMessage(false)
    setFailedImportReport(null)
    setSuccessImportReport(null)
  }

  const confirm = (file: any) => {
    resetReport()
    setFile(file)
    setShowWarningMessage(true)
    return false
  }

  const fileUploadProps = {
    accept: '.csv',
    showUploadList: false,
    beforeUpload: confirm,
    file
  }

  const handleUpload = async () => {
    if (spaceID && userID && file) {
      const formData = new FormData()
      formData.append('import', file)
      formData.append('spaceID', spaceID)
      formData.append('userID', userID)

      const headers = new Headers()
      headers.append('Authorization', `Bearer ${token}`)

      setFileUploading(true)
      setShowWarningMessage(false)
      const uploadAction = getUploadAction()
      const uploadResponse = await fetch(uploadAction, { method: 'POST', body: formData, headers })
      const { name: fileName = '' } = file

      resetReport()
      if (uploadResponse.status === 200) {
        const response = await uploadResponse.json()
        afterImportProduct && afterImportProduct()
        setSuccessImportReport({ ...response, fileName })
      } else if (uploadResponse.status >= 500) {
        const response = { rows: [ { msg: t('messages.error', { context: 'DEFAULT_ERROR' }) } ] }
        setFailedImportReport({ ...response, fileName })
      } else if (uploadResponse.status >= 400) {
        let response
        const contentType = uploadResponse.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          response = await uploadResponse.json()
        } else {
          const result = await uploadResponse.text()
          response = parseJsonString(result)
          if (!response) {
            response = { rows: [ { msg: result } ] }
          }
        }
        setFailedImportReport({ ...response, fileName })
      }
    }
  }

  const onHide = () => {
    resetReport()
    updateDashboard({ showImportProductDialog: false })
  }

  return (
    <Dialog
      header={t('actions.import', { context: 'products' })}
      visible={showImportProductDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
      style={{ width: '68rem' }}
    >
      <div data-testid="import-product-dialog" className="flex flex-column w-full pb-6">
        {!showWarningMessage && (
          <div className="flex flex-none h-8rem">
            <div className="flex flex-none w-12rem">
              <ImportUploadCsvComponent fileUploading={fileUploading} fileUploadProps={fileUploadProps} />
            </div>
            <div className="flex flex-grow-1 pl-4">
              <ImportDownloadCsvComponent />
            </div>
          </div>
        )}
        {showWarningMessage && (
          <div className="flex flex-none h-8rem">
            <ImportConfirmUploadComponent showWarningMessage={showWarningMessage} cancelUpload={() => resetReport()} continueUpload={handleUpload} />
          </div>
        )}
        <div className="flex-none py-4 px-3">
          <ul className="list-disc">
            <li className="text-sm text-gray-500">{t('messages.description', { context: 'importLineOne' })}</li>
            <li className="text-sm text-gray-500">{t('messages.description', { context: 'importLineTwo' })}</li>
            <li className="text-sm text-gray-500">{t('messages.description', { context: 'importLineThree' })}</li>
          </ul>
        </div>
        { (isValid(failedImportReport) || isValid(successImportReport)) && (
          <div className="flex-none">
            <ImportReportComponent successImportReport={successImportReport} failedImportReport={failedImportReport} />
          </div>
        )}
        <div className="flex-none pt-3">
          <ImportExampleStructureComponent />
        </div>
      </div>
    </Dialog>
  )
}
