import { connect } from 'react-redux'
import { trackEventSaga } from '../../redux/sagas/tracking.saga'

import HelpCenter from './helpCenter'

const mapDispatchToProps = {
  trackEventSaga
}

export default connect(
  null,
  mapDispatchToProps
)(HelpCenter)
