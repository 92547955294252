import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'

import { ComparisonEntity } from '../enum/entity'
import { TProductDetailContext } from '../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../product/provider/context/product-detail.context'
import { useTransformTree } from '../../shared/hook/use-transform-tree'
import { TreeComponent } from '../../product/component/tree/tree.component'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { TreeFooterComponent } from '../component/product/model/tree-footer.component'
import { TreeHeaderComponent } from '../component/product/model/tree-header.component'

import { selectedWorkspaceVar } from '../../../graphql/cache'

import { MenuAction } from '../../product/enum/menu-action'
import { store } from '../../../configureStore'
import { setSelectedProductAction } from '../../../redux/actions/global.actions'
import { setIsCreateNewInventoryItemAction, setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'

import { AddSubItemNodeComponent } from '../../product/component/tree/tree-add-subitem-node.component'
import { AnalysisMode } from '../enum/analysis'

const TreeWrapper = styled.div`
  min-height: 100%;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.1);
`

export type ProductWrapperLayoutProps = {
  entity?: ComparisonEntity
}
export const ProductWrapperLayout = ({
  entity = ComparisonEntity.Primary,
}: ProductWrapperLayoutProps) => {
  const {
    productInventory,
    totalImpact = null,
    inventoryItems = [],
    expandedKeys = [],
    refetchInventory = () => {},
    refetchInventoryItems = () => {},
    updateProductDetail,
    selectedKey,
    selectedAction,
    selectedProduct,
    selectedInventoryAmountV09
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    analysisMode,
    updateAnalysis,
    secondaryUpdateDetail = () => {},
    primaryUpdateDetail = () => {},
  } = useContext<TAnalysisContext>(AnalysisContext)
  const selectedSpace = useReactiveVar(selectedWorkspaceVar) || {}
  const analysisInScenario = analysisMode === AnalysisMode.Scenario
  const { product } = productInventory || {}
  const isPrimary = entity === ComparisonEntity.Primary
  const scenarioProductID = !isPrimary ? product?.id : null
  const inventoryTree = useTransformTree({
    expandedKeys,
    inventoryItems,
    rootInventoryItem: { inventoryItem: { amount: 1, impact: { ...totalImpact }, product } }
  })
  useEffect(() => {
    if (totalImpact && product && expandedKeys) {
      const defaultAnalysis = isPrimary ? {
        entity,
        primaryTotalImpact: totalImpact,
        primaryEntityName: product?.name,
        primaryEntityUnit: product?.unit,
        primaryExpandedKey: expandedKeys,
        primaryUpdateDetail: updateProductDetail,
        primaryInventoryItemsRefetch: refetchInventoryItems
      } : {
        entity,
        scenarioProductID,
        secondaryTotalImpact: totalImpact,
        secondaryEntityName: product?.name,
        secondaryEntityUnit: product?.unit,
        secondaryExpandedKey: expandedKeys,
        secondaryUpdateDetail: updateProductDetail,
        secondaryRootProductRefetch: refetchInventory,
        secondaryInventoryItemsRefetch: refetchInventoryItems
      }

      updateAnalysis(defaultAnalysis)
      !isPrimary && updateProductDetail({ treeIsSecondary: true })
    }

    return () => {
      updateAnalysis({ primaryExpandedKey: [], secondaryExpandedKey: [] })
    }
  }, [ totalImpact, product, expandedKeys, scenarioProductID ])

  useEffect(() => {
    if (selectedSpace && refetchInventoryItems) {
      refetchInventoryItems()
    }
  }, [ selectedSpace ])

  useEffect(() => {
    if (selectedKey) {
      updateAnalysis({ scenarioSelectedKey: selectedKey })
      isPrimary
        ? secondaryUpdateDetail({ selectedKey: null })
        : primaryUpdateDetail({ selectedKey: null })
    }
  }, [ selectedKey ])

  useEffect(() => {
    if (!isPrimary && selectedAction === MenuAction.AddTreeItem) {
      addTreeItemHandler()
    }
  }, [ selectedAction, selectedProduct ])

  const addTreeItemHandler = () => {
    primaryUpdateDetail({ selectedAction, selectedProduct, selectedInventoryAmountV09 })
    store.dispatch(setSelectedProductAction(selectedProduct))
    store.dispatch(setIsCreateNewInventoryItemAction(true))
    store.dispatch(setIsDetailsPanelOpenAction(true))
  }

  const dataCy = isPrimary ? 'primary-tree-container' : 'secondary-tree-container'
  return (
    <>
      { productInventory && (
        <TreeWrapper className="flex w-full border-round-lg text-gray-500 flex-column shadow-2 mb-5">
          <div className="flex w-full">
            <TreeHeaderComponent entity={entity} />
          </div>
          <div data-cy={dataCy} data-testid="tree-wrapper-body" className="bg-white flex-grow-1 border-top-1 border-gray-100 pt-3">
            <TreeComponent inventoryTree={inventoryTree[0].children} />
            { !isPrimary && analysisInScenario && (
              <div className="flex-none w-full mb-5 pl-3 pr-4" data-html2canvas-ignore>
                <AddSubItemNodeComponent />
              </div>
            )}
          </div>
          <div data-testid="tree-wrapper-footer" className="flex w-full h-3rem px-5 py-3 border-round-bottom-lg border-top-1 border-gray-100 tree-wrapper-footer">
            <TreeFooterComponent entity={entity} />
          </div>
        </TreeWrapper>
      )}
    </>
  )
}
