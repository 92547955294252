import { connect } from 'react-redux'
import SpaceSelector from '../../../../redux/selectors/space.selector'
// import { getAuthTokenSaga } from '../../../../redux/sagas/user.saga'
import { updateSpaceProductsSaga, getProductImportColumnsSaga } from '../../../../redux/sagas/product.saga'
import ProductImport from './productImport'
import GlobalSelector from '../../../../redux/selectors/global.selector'

const mapStateToProps = state => ({
  selectedSpaceId: SpaceSelector.spaceId(state),
  columns: GlobalSelector.productImportColumns(state),
  productLabel: GlobalSelector.productLabel(state),
  productImportModalVisibility: GlobalSelector.productImportModalVisibility(state),
})

const mapDispatchToProps = {
  // getAuthTokenSaga,
  updateSpaceProductsSaga,
  getProductImportColumnsSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductImport)
