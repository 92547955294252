import { connect } from 'react-redux'
import { setIsInventorySkipPhasesSaga } from '../../../../redux/sagas/inventoryTree.saga'
import ComparisonFlatInventory from './comparisonFlatInventory'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { setIsLifecycleSkipPhasesSaga } from '../../../../redux/sagas/lifecycle.saga'
import { selectedFlatViewItemSaga } from '../../../../redux/sagas/product.saga'

const  mapStateToProps = state => ({
  skipPhases: FlagsSelector.isSkipPhases(state),
  isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
  isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state)
})

const mapDispatchToProps = {
  setIsLifecycleSkipPhasesSaga,
  setIsInventorySkipPhasesSaga,
  selectedFlatViewItemSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComparisonFlatInventory)
