import React from 'react'
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Input, Row, Col, Button, Select, Form } from 'antd'
import { withTranslation } from 'react-i18next'
import TransportationLegs from './transportation-legs/transportationLegs.container'
import compose  from '../../../../utils/compose'
import { safeArray, returnNested, normalize } from '../../../../utils/tools'
const FormItem = Form.Item
const { TextArea } = Input

class TransportActivityTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelectProduct = this.handleSelectProduct.bind(this)
    this.handleDeselectProduct = this.handleDeselectProduct.bind(this)
    this.handleCancelAction = this.handleCancelAction.bind(this)
    this.handleSelectTemplate = this.handleSelectTemplate.bind(this)
    this.handleAddDestination = this.handleAddDestination.bind(this)
  }

  massReferencePropertyId = 'TWFzc0ZMT1dfUFJPUEVSVFlUZWNobmljYWwgZmxvdyBwcm9wZXJ0aWVz'
  tonReferenceUnitId = 'dFVuaXRzIG9mIG1hc3M='

  componentDidMount() {
    this.props.clearListOfProductsForTransportation()
    this.props.getVehicleListSaga(this.props.spaceId)
    this.props.getActivityProductsSaga(this.props.phaseId)
    this.props.updateTemplateListSaga(this.props.spaceId)
    this.handleAddDestination()
  }

  componentWillUnmount() {
    this.props.clearListOfProductsForTransportation()
    this.props.setVehicleList([])
    this.props.setActivityTemplatesAction([])
    this.props.setSelectedActivityTemplateAction(null)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedActivityTemplateId !== this.props.selectedActivityTemplateId) {
      this.fillFormWithTemplateData()
    }
  }

  fillFormWithTemplateData() {
    const { selectedActivityTemplate } = this.props
    const { setFieldsValue } = this.formRef.current

    setFieldsValue({
      name: selectedActivityTemplate.name,
      description: selectedActivityTemplate.description,
      start: selectedActivityTemplate.start,
      leg: selectedActivityTemplate.legs,
      legItem: selectedActivityTemplate.legs
    })
  }

  legStructure = {
    destination: '',
    distance: 0,
    transportProductID: ''
  }

  handleAddDestination() {
    const { getFieldValue, setFieldsValue } = this.formRef.current
    const leg = getFieldValue('leg')

    setFieldsValue({
      leg: leg.concat(this.legStructure)
    })
  }

  getGroupedActivityProducts = () => {
    return safeArray(this.props.selectedLifecyclePhasesList).map(phase => {
      phase.activityProducts = safeArray(this.props.activityProductList).filter(el => el.phase.id === phase.id)
      return phase
    })
  }

  handleSubmit = () => {

    this.formRef.current.validateFields().then(values => {

      this.props.createTransportActivitySaga({
        spaceID: this.props.spaceId,
        phaseID: this.props.phaseId,
        name: values.name,
        description: values.description,
        products: this.getProductForTransportation(values.productsForTransportation),
        start: values.start,
        templateID: values.template || null,
        legs: safeArray(returnNested(values, 'legItem')).map(el => {
          el.distance = Number(el.distance)
          return el
        })
      })
      this.handleCancelAction()
    })
  }

  handleCancelAction() {
    this.props.handleCancelModal()
    this.props.handleCancelOfCreationOfNewInventoryItem()
  }

  getProductForTransportation(productsForTransportation) {
    return safeArray(this.props.activityProductList)
      .filter(el => productsForTransportation.includes(el.key))
      .map(el => { return { inventoryID: el.inventoryID, productID: el.productID } })
  }

  handleSelectProduct(key) {
    const { getProductPropertyAmountSaga } = this.props

    const keyProductId = String(key.split('_productId_')[1])
    const selectedProductForTransporting = this.props.activityProductList.find(el => el.key === key)

    getProductPropertyAmountSaga(
      keyProductId,
      selectedProductForTransporting.inventoryID,
      this.massReferencePropertyId,
      this.tonReferenceUnitId,
      selectedProductForTransporting.product,
      key
    )
  }

  handleDeselectProduct(key) {
    this.props.removeProductFromListOfProductsForTransportation(key)
  }

  sortArrayOfProductsByProductName(list) {
    return list.sort((a, b) => (a.product.name > b.product.name) ? 1 : ((b.product.name > a.product.name) ? -1 : 0))
  }

  isProductHasMassReferencePropertyId(product) {
    return safeArray(returnNested(product, 'productProperties'))
      .filter(prop => prop.referenceProperty.id === this.massReferencePropertyId).length  > 0
  }

  getListOfProductsWithWrongReferenceProperty() {
    return safeArray(this.props.listOfProductsForTransportation)
      .filter(prod => !this.isProductHasMassReferencePropertyId(prod))
  }

  transportTemplateStyles = {
    formItemLayout: {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    },
    longLabelLayout: {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    },
    formItemLayoutFullSize: {
      wrapperCol: { span: 24 }
    },
    descriptionArea: {
      margin: 0
    }
  }

  handleSelectTemplate(templateId) {
    this.props.getActivityTemplateSaga(templateId)
  }

  formRef = React.createRef()

  render() {
    const { t, listOfProductsForTransportation, selectedActivityTemplateId } = this.props
    const listOfProductsWithWrongReferenceProperty = this.getListOfProductsWithWrongReferenceProperty()
    const groupedActivityProducts = this.getGroupedActivityProducts()
    const isDisabled = selectedActivityTemplateId ? true : false

    return (
      <Row>
        <Col span={12} offset={6}>
          <Row>
            <Col span={24} >
              <Form
                ref={this.formRef}
                onFinish={ this.handleSubmit }
                {...this.transportTemplateStyles.formItemLayout}
                className="field-container compact"
                hideRequiredMark
                initialValues={{
                  leg: [],
                  productsForTransportation: safeArray(listOfProductsForTransportation).map(el => el.id)
                }}
              >
                {
                  safeArray(this.props.activityTemplates).length > 0 ? (
                    <FormItem
                      name="Template"
                      label={t('global.template')}
                      rules={[
                        { message: t('err.VALUE_IS_EMPTY', { name: 'Template' }) },
                      ]}
                    >
                      <Select getPopupContainer={ node => node.parentElement } onSelect={ this.handleSelectTemplate }>
                        {
                          safeArray(this.props.activityTemplates).map(el =>
                            <Select.Option value={el.id} key={el.id}>{el.name}</Select.Option>)
                        }
                      </Select>
                    </FormItem>
                  ) : null
                }

                <FormItem
                  name="name"
                  label={t('global.name')}
                  rules={[
                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Name' }) },
                  ]}
                >
                  <Input data-cy="transport-name-input" autoFocus={true} disabled={ isDisabled }/>
                </FormItem>

                <FormItem
                  {...this.transportTemplateStyles.formItemLayoutFullSize}
                  name="description"
                  rules={[
                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Description' }) },
                  ]}
                >
                  <TextArea
                    style={this.transportTemplateStyles.descriptionArea}
                    onChange={this.onChange}
                    placeholder={t('global.description')}
                    disabled={ isDisabled }
                    data-cy="transport-description-input"
                  />

                </FormItem>

                <FormItem
                  name="productsForTransportation"
                  label={t('global.products')}
                  rules={[
                    { required: true, message: t('validation.product_name', { name: 'Products' }) }
                  ]}>

                  <Select
                    mode="multiple"
                    filterOption={(inputValue, option) => {
                      let filterKey = returnNested(option, 'props', 'label') ? 'label' : 'children'
                      const filterKeyLabel = returnNested(option, 'props', filterKey)
                      return filterKeyLabel ? String(filterKeyLabel).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false
                    }}
                    onSelect={this.handleSelectProduct}
                    onDeselect={this.handleDeselectProduct}
                    getPopupContainer={node => node.parentElement}
                    style={{ width: '100%' }}
                    data-cy="transport-product-select"
                    dropdownClassName="cy-transport-product-dropdown"
                  >
                    {
                      groupedActivityProducts.map(phase =>
                        <Select.OptGroup label={phase.name} key={phase.id}>

                          {phase.activityProducts.map(el =>
                            <Select.Option data-cy={`transport-product-named-${normalize(el.product.name)}`} key={el.key}>{el.product.name}</Select.Option>)}
                        </Select.OptGroup>
                      )
                    }
                  </Select>

                </FormItem>

                {
                  safeArray(listOfProductsForTransportation) ?
                    <p data-cy="total-weight-message">
                      <span>{ t('global.total_weight') } </span>
                      <span>
                        {
                          listOfProductsForTransportation && Number(listOfProductsForTransportation.reduce((accumulator, currentValue) =>
                            accumulator + Number(currentValue.transportWeightAmount), 0)).toFixed(3)
                        }
                      </span> {t('global.ton')}
                    </p> : null
                }

                {
                  listOfProductsWithWrongReferenceProperty &&
                      listOfProductsWithWrongReferenceProperty.length ?
                    <p data-cy="no-mass-alert-messages">
                      <ExclamationCircleOutlined />
                      {t('global.products_do_not_have_reference_property_mass')}
                      {listOfProductsWithWrongReferenceProperty.map((el, index) =>
                        <span key={el.key}>{el.name}{listOfProductsWithWrongReferenceProperty.length-1 === index ? '.' : ', '} </span>
                      )}
                    </p> : null
                }
                <Form.Item name="leg">
                  <TransportationLegs
                    formRef={ this.formRef }
                    isDisabled={ isDisabled }
                    handleAddDestination={this.handleAddDestination}
                  />
                </Form.Item>
                <Form.Item className="text-center" {...this.transportTemplateStyles.formItemLayoutFullSize}>
                  <Button
                    htmlType="submit"
                    className="button transparent"
                    data-cy="add-transport-template-phase"
                  >
                    <CheckOutlined /> { t('global.add') }
                  </Button>

                  <Button
                    className="button transparent"
                    onClick={this.handleCancelAction}>
                    <CloseOutlined />{t('global.cancel')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>

        </Col>
      </Row>
    )
  }
}
export default compose(
  withTranslation()
)(TransportActivityTemplate)
