import React from 'react'

import styled from 'styled-components'
import { castToJoinedAlphaNumericString } from '../../../shared/util/tools'

const CreateScenarioListItem = styled.div`
  border-radius: 0.25rem;
  height: 2.375rem;
  overflow: hidden;
  font-weight: 400;
  background: var(--surface-a);

  :hover {
    cursor: pointer;
    background: var(--gray-50);
  }
`

type CreateScenarioListComponentProp = {
  data?: any[]
  handleScenarioItemClick?: (param: string) => void
}

export const CreateScenarioListComponent = ({
  data = [],
  handleScenarioItemClick = () => {}
}: CreateScenarioListComponentProp) => (
  <>
    {
      data.map((item: any, index: number) => {
        const { id, label, dataCyTag } = item || {}
        const dataCy = `${dataCyTag}-${castToJoinedAlphaNumericString(label).toLowerCase()}`
        return (
          <CreateScenarioListItem
            key={`scenario-item-${index}`}
            data-cy={dataCy}
            data-testid={dataCy}
            onClick={() => {
              handleScenarioItemClick(id)
            }}
            className="flex w-full p-2 text-sm"
          >
            <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">{ label }</div>
          </CreateScenarioListItem>
        )
      })
    }
  </>
)
