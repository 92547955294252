import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeProductReference($productID: ID!, $referenceProductID: String!) {
    changeProductReference(productID: $productID, referenceProductID: $referenceProductID){
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
