import React from 'react'
import { withTranslation } from 'react-i18next'
import * as d3 from 'd3'
import ResponsiveWrapper from '../responsive-wrapper/ResponsiveWrapper'
import { cropText, isEmpty, returnNested, requiresScientificNotation } from '../../../../utils/tools'
import { D3 } from '../../../../utils/const'
import { Switch } from 'antd'
import compose from '../../../../utils/compose'
import isEqual from 'lodash.isequal'

let svg, xScale, yScale, transitionDuration = 0
const margin = { top: 20, right: 20, left: 100, bottom: 40 }

class D3Chart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      svg: null,
      data: null,
      isFilteredByimpact: false,
      containerWidth: 0
    }

    this.handleToggleFilterByImpactValue = this.handleToggleFilterByImpactValue.bind(this)
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)

    this.setState({
      data: this.prepareData(),
    }, this.initChart)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = ()  => {
    const middlePanelDom = document.querySelector('.inventory.middle-panel')
    const containerWidth = middlePanelDom ? middlePanelDom.clientWidth - 32 : 0
    this.setState({ containerWidth })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.leafInventory, prevProps.leafInventory) ||
      this.state.containerWidth !== prevState.containerWidth) {
      this.initChart()
      this.updateChart()
    }
  }

  initChart = () => {
    const { rootInventoryProductWithImpact } = this.props
    if (isEmpty(rootInventoryProductWithImpact)) return
    const svgDimensions = {
      width: Math.max(this.state.containerWidth, 100),
      height: 500
    }
    let width = svgDimensions.width - margin.left - margin.right
    let height = svgDimensions.height - margin.top - margin.bottom

    let D3data = this.state.data

    svg = d3.select(this.svgEl)
      .attr('width', svgDimensions.width)
      .attr('height', svgDimensions.height)

    svg.selectAll('*').remove()
    let groupForGraph = d3.select(this.svgEl).append('g').attr('id', 'group-for-graph')
      .attr('transform', `translate(${margin.left},${margin.top})`)
    this.handleInitTooltip()

    groupForGraph.append('g')
      .attr('class', 'x-axis-group')
      .attr('transform', 'translate(0,' + height + ')')
    xScale = d3.scaleBand().padding(0.1)
    this.handleXaxis({ D3data, height, width, groupForGraph })

    groupForGraph.append('g')
      .attr('class', 'y-axis-group')
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('transform', 'rotate(-90), translate(' + -(height/2) + ',' + (-3/4*margin.left) + ')')
      .style('text-anchor', 'middle')
      .html(returnNested(rootInventoryProductWithImpact, 'impact', 'unit'))
    yScale = d3.scaleLinear()
    this.handleYaxis({ D3data, height, margin, groupForGraph })

    this.handleBars({ D3data })
  }

  getPercentOfTotalImpact = percentage => {
    const { rootInventoryProductWithImpact } = this.props
    if (!rootInventoryProductWithImpact) return 0
    return (percentage/100) * rootInventoryProductWithImpact.impact.amount
  }

  prepareData = () => {
    const { leafInventory: leafInventoryItems } = this.props
    let leafInventoryItemsWithNoRootItem = (leafInventoryItems && leafInventoryItems.length > 1) ?
      leafInventoryItems.filter(el => el.product.id !== this.props.inventoryTreeSelectorProductId) : leafInventoryItems
    const cropTextLength = leafInventoryItemsWithNoRootItem && leafInventoryItemsWithNoRootItem.length < 5 ? Math.floor(60 / leafInventoryItemsWithNoRootItem.length) : 20

    let data = d3.rollups(leafInventoryItemsWithNoRootItem, v => {
      const isArrayNotEmpty = Array.isArray(v) && v.length
      return {
        name: isArrayNotEmpty && v[D3.KEY].product.name,
        impactTotal: d3.sum(v, d => returnNested(d, 'impact', 'amount')),
        impactUnit: isArrayNotEmpty && v[D3.KEY] && v[D3.KEY].impact.unit,
        amount: isArrayNotEmpty && v[D3.KEY].amount
      } }, d => cropText(d.product.name, cropTextLength, '...'))
      .sort((a, b) => d3.ascending(a[D3.KEY], b[D3.KEY]))
    if (this.state.isFilteredByimpact) {
      return data.filter(el => el[1].impactTotal > this.getPercentOfTotalImpact(1))
    }

    return data
  }

  updateChart() {
    let D3data = this.prepareData()
    if (isEmpty(this.svgEl)) return
    const svgDimensions = {
      width: Math.max(this.state.containerWidth, 100),
      height: 500
    }
    let width = svgDimensions.width - margin.left - margin.right
    let height = svgDimensions.height - margin.top - margin.bottom
    transitionDuration = ( Number(d3.select(this.svgEl).attr('width')) !== svgDimensions.width ) ? 0 : 1500

    d3.select(this.svgEl).attr('width', svgDimensions.width)

    this.handleXaxis({ D3data, width })
    this.handleYaxis({ D3data, height, width })
    this.handleBars({ D3data })
  }

  handleInitTooltip() {
    d3.selectAll('#D3div .tooltip').remove()
    let tooltip = d3.select('#D3div').append('div')
      .attr('class', 'tooltip')
      .attr('opacity', 0)

    tooltip.append('p').append('span')
  }

  handleXaxis({ D3data, width }) {
    if (!xScale) return
    xScale
      .rangeRound([ 0, width ])
      .domain(D3data.map(d => d[D3.VALUES].name))

    const xAxis = d3.axisBottom().scale(xScale)

    svg.selectAll('.x-axis-group').transition().duration(transitionDuration).call(xAxis)

    this.makeNiceLabels({ xAxis })
  }

  handleYaxis({ D3data, height }) {
    const { rootInventoryProductWithImpact } = this.props
    if (!xScale) return

    yScale
      .rangeRound([ height, 0 ])
      .domain([ Math.min(0, d3.min(D3data, d => d[D3.VALUES].impactTotal )), d3.max(D3data, d => d[D3.VALUES].impactTotal) ])
      .nice()

    const formatter = requiresScientificNotation(d3.max(D3data, d => d[D3.VALUES].impactTotal)) ? '.1e' : ''
    const yAxis = d3.axisLeft().scale(yScale).ticks(5)
      .tickFormat(d3.format(formatter))

    svg.selectAll('.y-axis-group').transition().duration(transitionDuration).call(yAxis)
    svg.selectAll('.y-axis-label').html(returnNested(rootInventoryProductWithImpact, 'impact', 'unit'))
  }

  makeNiceLabels({ xAxis }) {
    const rangeBandTiny = 15

    // no tick labels when bar width is really small
    if (xScale.step() < rangeBandTiny) {
      xAxis.tickValues([])
      svg.selectAll('.x-axis-group').call(xAxis)
      return
    }

    svg.selectAll('.x-axis-group').selectAll('.tick text')
      .style('text-anchor', 'start')
      .attr('dx', '0.5em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-90)')
  }

  handleBars({ D3data }) {
    if (!xScale) return
    const bars = d3.select(this.svgEl).select('#group-for-graph').selectAll('.bar,.barNegative')
      .data(D3data, function(d, e) { return e })
    bars.enter()
      .append('rect')
      .attr('class', d => (d[D3.VALUES].impactTotal > 0) ? 'bar' : 'barNegative')
      .on('mouseover', (evt, d) => this.props.mouseoverFunction(evt, d[D3.VALUES]) )
      .on('mouseout', () => this.props.mouseoutFunction())
      .attr('x', d =>  xScale(d[D3.VALUES].name))
      .attr('width', xScale.bandwidth())
      .attr('height', 0)
      .attr('y', yScale(0))
      .transition().duration(transitionDuration)
      .attr('height', d => Math.abs(yScale(d[D3.VALUES].impactTotal) - yScale(0)))
      .attr('y', d => yScale(Math.max(0, d[D3.VALUES].impactTotal)))

    bars.merge(bars)
      .transition().duration(transitionDuration)
      .attr('class', d => (d[D3.VALUES].impactTotal > 0) ? 'bar' : 'barNegative')
      .attr('x', d => xScale(d[D3.VALUES].name))
      .attr('width', xScale.bandwidth())
      .attr('height', d => Math.abs(yScale(d[D3.VALUES].impactTotal) - yScale(0)))
      .attr('y', d =>  yScale(Math.max(0, d[D3.VALUES].impactTotal)))

    bars.exit().remove()
  }

  handleToggleFilterByImpactValue(status) {
    this.setState({ isFilteredByimpact: status }, () => this.updateChart())
  }

  render() {
    const { t } = this.props
    return (
      <div className="barchart-wrapper">
        <div className="filter-low-impact-switcher">
          <span>{t('global.filter_low_impacts')}</span>
          <Switch
            checked={this.state.isFilteredByimpact}
            onChange={this.handleToggleFilterByImpactValue} />
        </div>
        <div id='D3div' data-cy="D3Chart-container" className="D3Chart-container">
          <svg ref={el => this.svgEl = el} />
        </div>
      </div>
    ) }
}

export default compose(
  withTranslation(),
  ResponsiveWrapper,
)(D3Chart)
