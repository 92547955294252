import React, { useEffect, useRef } from 'react'
import { Messages } from 'primereact/messages'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ImportConfirmUploadWrapper = styled.div`
  .p-message {
    margin: 0;
  }

  .p-message-wrapper {
    align-items: start;
  }

  .p-message-icon {
    padding-top: 0.5rem;
  }
`

type TImportConfirmUploadComponent = {
  showWarningMessage?: boolean,
  cancelUpload: () => void,
  continueUpload: () => void
}
export const ImportConfirmUploadComponent = ({
  showWarningMessage = false,
  cancelUpload,
  continueUpload
}: TImportConfirmUploadComponent) => {
  const { t } = useTranslation([ 'common' ])
  const messages = useRef<Messages>(null)

  const getWarningMessage = () => (
    <div className="w-full px-1">
      <div data-testid="import-warning-summary" className="w-full text-base font-semibold">{t('messages.warningSummary')}</div>
      <div data-testid="import-warning-detail" className="w-full text-sm pb-1">{t('messages.warning', { context: 'importUploadConfirmation' })}</div>
      <div className="w-full h-2rem">
        <Button data-testid="import-cancel-upload" label={t('actions.cancel', { context: 'upload' })} onClick={cancelUpload} className="p-button-sm p-button-outlined p-button-warning mr-2 h-full" />
        <Button data-testid="import-continue-upload" label={t('actions.continue')} onClick={continueUpload} className="p-button-sm p-button-warning h-full" />
      </div>
    </div>
  )

  useEffect(() => {
    messages.current?.clear()
    if (showWarningMessage) {
      messages.current?.show({
        closable: false, severity: 'warn', sticky: true, summary: getWarningMessage()
      })
    }
  }, [ showWarningMessage ])

  return (
    <ImportConfirmUploadWrapper data-testid="import-confirm-upload-wrapper" className="w-full">
      <Messages ref={messages} />
    </ImportConfirmUploadWrapper>
  )
}
