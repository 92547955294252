import { gql } from '@apollo/client'

export default gql`
    query product($id: ID!){
        product(id: $id) {
            lifecycles {
                id
                name
            }
        }
    }
`
