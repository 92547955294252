import { flatArray, isEmpty, returnNested, safeArray } from '../../../utils/tools'

export const calculateMaxImpactAmount = lifecycle => {
  if (isEmpty(lifecycle)) return
  const parseAbsAmount = amount => Math.abs(parseFloat(amount))
  const impacts = safeArray(lifecycle.phases).map(phase => {
    const inventoryItems = returnNested(phase, 'inventory', 'inventoryItems') || []
    const phaseTotalImpact = parseAbsAmount(returnNested(phase, 'inventory', 'totalImpact', 'amount')) || 0 // Math.max(NaN) == 1
    return [
      phaseTotalImpact,
      ...inventoryItems.map(inventoryItem => {
        return parseAbsAmount(returnNested(inventoryItem, 'impact', 'amount')) || 0 // Math.max(NaN) == 1
      })
    ]
  }) || []

  return Math.max(...flatArray(impacts))
}
