import { connect } from 'react-redux'
import {
  setIsCreateNewInventoryItemAction,
  setIsCreatePhaseAction,
  setIsDetailsPanelOpenAction
} from '../../../redux/actions/flags.actions'
import { reorderPhaseSaga, selectPhaseSaga } from '../../../redux/sagas/lifecycle.saga'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { selectedInventoryItemSelector } from '../../../redux/selectors/inventoryTree.selector'
import { LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import LifecycleTreeView from './lifecycleTreeView'

const mapStateToProps = state => ({
  selectedInventoryItem: selectedInventoryItemSelector(state),
  selectedPhaseId: LifecycleSelector.selectedPhaseId(state),
  isOrderingPhases: FlagsSelector.isOrderingPhases(state),
  isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
  isInventoryCompare: ComparisonSelector.isInventoryCompare(state)
})

const mapDispatchToProps = {
  selectPhaseSaga,
  setIsCreateNewInventoryItemAction,
  setIsCreatePhaseAction,
  setIsDetailsPanelOpenAction,
  reorderPhaseSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleTreeView)

