import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { AddNodeWrapperLayout } from '../../layout/add-node-wrapper.layout'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'
import { AnalysisMode } from '../../../analysis/enum/analysis'

export const AddSubItemNodeComponent = () => {
  const { t } = useTranslation([ 'product' ])
  const {
    addInventoryItem,
    inventoryItems = []
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { analysisMode, updateAnalysis } = useContext<TAnalysisContext>(AnalysisContext)
  const handleAddSubItem = (event: any) => {
    event.stopPropagation()
    if (analysisMode === AnalysisMode.Scenario) {
      updateAnalysis({ scenarioSelectedKey: 'root' })
    }
    addInventoryItem()
  }
  const hasChildren = inventoryItems.length > 0
  return (
    <AddNodeWrapperLayout onClick={handleAddSubItem} dataCy="add-item-from-button" dataTestId="add-item-node-link">
      <div className="flex-none flex align-items-center justify-content-center">
        <FontAwesomeIcon data-testid="add-item-node-icon" icon={faPlus} className="text-base" />
      </div>
      <div data-testid="add-item-node-text" className="flex-grow-1 flex align-items-center text-base justify-content-start line-height-4">
        { !hasChildren ? t('actions.add', { context: 'item' }) : t('actions.add', { context: 'anotherItem' })}
      </div>
    </AddNodeWrapperLayout>
  )
}

