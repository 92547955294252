import React, { useState } from 'react'
import { SelectButton } from 'primereact/selectbutton'

import { ViewType } from '../../enum'
import { SelectButtonWrapperLayout } from '../../layout/select-button-wrapper.layout'

type TaskbarToggleViewComponentProps = {
  onViewChange?: Function,
  selectedViewType?: ViewType
}
export const TaskbarToggleViewComponent = ({
  onViewChange,
  selectedViewType
}: TaskbarToggleViewComponentProps) => {
  const [ selectedView, setSelectedView ] = useState(selectedViewType)

  // eslint-disable-next-line
  const typeOptions = Object.values(ViewType).map((item, index) => {
    switch (item) {
    case ViewType.Grid:
      return { key: index, icon: 'pi pi-table', value: item }
    case ViewType.Tabular:
      return { key: index, icon: 'pi pi-bars', value: item }
    }
  })
  const onTagChange = (selectedViewType: any) => {
    setSelectedView(selectedViewType)
    onViewChange && onViewChange(selectedViewType)
  }

  const viewTemplate = (option: any) => <i data-testid={`view-option-${option.key}`} className={option.icon}></i>

  return (
    <SelectButtonWrapperLayout>
      <SelectButton
        className="h-2rem"
        unselectable={false}
        value={selectedView}
        options={typeOptions}
        onChange={(event: any) => onTagChange(event.value)}
        itemTemplate={viewTemplate}
        optionLabel="value"
      />
    </SelectButtonWrapperLayout>
  )
}
