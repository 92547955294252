export const
  SET_LAST_SELECTED_ACCOUNT_ID   = 'SET_LAST_SELECTED_ACCOUNT_ID',
  SET_LAST_SELECTED_SPACE_ID     = 'SET_LAST_SELECTED_SPACE_ID',
  SET_LAYOUT_VIEW_TYPE = 'SET_LAYOUT_VIEW_TYPE'

export const setLastSelectedAccountIdAction = (selectedUserId, lastSelectedAccountId) => ({
  type: 'SET_LAST_SELECTED_ACCOUNT_ID',
  selectedUserId,
  lastSelectedAccountId
})

export const setLastSelectedSpaceIdAction = (selectedUserId, lastSelectedSpaceId) => ({
  type: 'SET_LAST_SELECTED_SPACE_ID',
  selectedUserId,
  lastSelectedSpaceId
})

export const setLayoutViewTypeAction = (selectedUserId, layoutViewType) => ({
  type: 'SET_LAYOUT_VIEW_TYPE',
  selectedUserId,
  layoutViewType
})

