import React from 'react'
import { useNavigate, matchPath, useLocation } from 'react-router-dom'
import { TieredMenu } from 'primereact/tieredmenu'
import styled from 'styled-components'
import { FiPlus } from 'react-icons/fi'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import { AccountItemComponent } from './account-item'
import { SubmenuItem } from './submenu-item'

import { NavigationItemAttr, NavigationItemProps } from '../interface/navigation-items-props'
import { NavigationItemLayout } from '../layout/navigation-item.layout'
import { AccountNavigationComponentProps } from '../interface/account-navigation'

import { IAccount, IAuth0User, IUser } from '../../model'
import { ExternalSources, Navigation } from '../../shared/enum/navigation'

import { selectedAccountVar, userAuth0Var, userVar } from '../../../graphql/cache'
import { LOCAL_BUILD } from '../../../utils/const'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const AccountNavigation = styled.div`
  .main-menu-item-wrapper + .p-submenu-list {
    top: -18rem !important;
    left: calc(100% - 1.813rem);
    z-index: 1100;
  }

  li.p-menuitem-active:first-child {
    .main-menu-item {
      background: var(--surface-100)
    }
  }

  .sub-menu-item + .p-submenu-list {
    left: calc(100% + 0.5rem);
  }

  .p-submenu-list {
    border-radius: 0.25rem;
    box-shadow: 0px 0.3rem 0.6rem rgba(0, 0, 0, 0.15);

    .p-menuitem {
      padding: 0 0.5rem;
    }
  }

  .p-menu-separator {
    border-top: 1px solid var(--gray-100);
    background: var(--gray-100);
    height: 1px;
  }
`

export const AccountNavigationComponent = ({
  handleTalkToExpert,
  handleAccountSwitch,
  openAddAccountDialog,
  emptyAccount
}: AccountNavigationComponentProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const userAuth0: IAuth0User | null = userAuth0Var()
  const user: IUser | null = userVar()
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [ trackEventInSegment ] = getSegmentTrack()

  const isSelectedPath = (path: Navigation) => {
    const param = matchPath(path, pathname)
    return param !== null && param !== undefined
  }

  const menuItemTemplate = (item: any, options: any) => {
    const { label, data, dataCy } = item || {}
    const navItemProps: NavigationItemProps = {
      itemAttr: {
        label, data, options, dataCy
      },
      itemComponent: AccountItemComponent
    }
    return <NavigationItemLayout {...navItemProps} />
  }

  const openNewWindow = (pathname: string, event: TrackEventType | null = null) => {
    event && trackEventInSegment(event)
    window.open(pathname)
  }

  const navigateTo = (pathname: Navigation, event: TrackEventType | null = null) => {
    event && trackEventInSegment(event)
    navigate(pathname)
  }

  const subMenuItemTemplate = (item: any, options: any) => {
    const {
      label, icon, header, items = [], data = null, selected = false, dataCy
    } = item || {}
    const itemAttr: NavigationItemAttr = {
      label,
      icon,
      header,
      options,
      data,
      dataCy,
      hasItems: items.length > 0,
      scrollable: !!data,
      selected: data ? account?.id : selected,
      callback: handleAccountSwitch,
      changelog: label === LOCAL_BUILD
    }
    const navItemProps: NavigationItemProps = { itemAttr, mainMenuItem: false, itemComponent: SubmenuItem }
    return <NavigationItemLayout {...navItemProps} />
  }

  const { accounts = [] } = user || {}
  const accountMenuItems = accounts.map((account: IAccount) => ({ id: account.id, label: account.name, dataCyTag: 'account-named' }))

  const subMenuItems = [
    { label: t('labels.help'), header: true, template: subMenuItemTemplate },
    {
      label: t('actions.chatToOurTeam', { context: 'trial' }),
      dataCy: 'talk-expert-button',
      template: subMenuItemTemplate,
      command: () => handleTalkToExpert()
    },
    {
      label: t('labels.helpCenter', { ns: 'common' }),
      dataCy: 'help-center-button',
      template: subMenuItemTemplate,
      command: () => openNewWindow(ExternalSources.HelpCenterMobius, TrackEventType.VIEWED_HELP_CENTER)
    },
    { label: t('labels.account', { ns: 'common' }), header: true, template: subMenuItemTemplate },
    {
      label: t('actions.switchAccount'),
      items: [
        {
          data: accountMenuItems,
          template: subMenuItemTemplate
        },
        { separator: true },
        {
          label: t('actions.add', { context: 'account' }),
          icon: <FiPlus size={20} />,
          template: subMenuItemTemplate,
          dataCy: 'create-account-button',
          command: () => openAddAccountDialog()
        }
      ],
      template: subMenuItemTemplate,
      dataCy: 'submenu-switch-account'
    },
    {
      label: t('labels.settings'),
      template: subMenuItemTemplate,
      dataCy: 'account-settings-button',
      selected: isSelectedPath(Navigation.AccountSetting),
      command: () => navigateTo(Navigation.AccountSetting, TrackEventType.OPENED_SETTINGS)
    },
    {
      label: t('actions.viewProfile'),
      template: subMenuItemTemplate,
      dataCy: 'open-profile-button',
      selected: isSelectedPath(Navigation.UserProfile),
      command: () => navigateTo(Navigation.UserProfile, TrackEventType.OPENED_USER_PROFILE)
    },
    {
      label: t('actions.logout'),
      template: subMenuItemTemplate,
      dataCy: 'button_to_logout',
      command: () => navigateTo(Navigation.Logout, TrackEventType.LOGOUT)
    },
    {
      icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} />,
      label: LOCAL_BUILD,
      template: subMenuItemTemplate,
      command: () => openNewWindow(ExternalSources.HelpCenterChangelog, TrackEventType.VIEWED_CHANGELOG)
    }
  ]

  const menuitems = [
    {
      label: emptyAccount ? t('actions.header', { context: 'createAccount' }) : account?.name,
      items: subMenuItems,
      template: menuItemTemplate,
      data: { user, userAuth0 },
      dataCy: 'main-menu-item'
    }
  ]

  return (
    <AccountNavigation data-cy="sidebar-menu-account" className="card">
      <TieredMenu appendTo="self" className="surface-0 border-none w-full" model={menuitems} />
    </AccountNavigation>
  )
}
