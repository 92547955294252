import { gql } from '@apollo/client'
import lifecycle from '../fragment/lifecycle'

export default gql`
    query lifecycle($id: ID!){
        lifecycle(id: $id) {
            ...lifecycleFragment
        }
    }
    ${lifecycle}
`
