import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

import { useRefOverflow } from '../../hook/use-ref-overflow'

const BackLink = styled.div`
  cursor: pointer;
  color: var(--gray-500) !important;

  &:hover {
    color: var(--primary-500) !important;
  }

  .back-text-link {
    margin-top: 0.1rem;
  }
`
type TBackLinkProps = {
  handleBack: Function,
  backButtonLabel: string,
  tooltipContent?: React.ReactNode | string
}
export const BackLinkComponent = ({
  handleBack,
  tooltipContent,
  backButtonLabel
}: TBackLinkProps) => {
  const [ backLabelRef, hasBackLabelOverflow, backLabelDom ] = useRefOverflow()

  return (
    <>
      <BackLink onClick={() => handleBack()} className="flex align-items-center justify-content-start cursor-pointer">
        <Tooltip target={backLabelDom} onBeforeShow={() => hasBackLabelOverflow} position="bottom">{ tooltipContent }</Tooltip>
        <div className="flex flex-none mr-2">
          <FontAwesomeIcon data-testid="header-detail-back-icon" icon={faArrowLeft} className="xl" />
        </div>
        <div ref={backLabelRef} data-testid="header-detail-back-label" className="flex-grow-1 text-base line-height-3 white-space-nowrap overflow-hidden text-overflow-ellipsis pr-3 back-text-link">
          { backButtonLabel }
        </div>
      </BackLink>
    </>
  )
}
