import { gql } from '@apollo/client'
import role from '../fragment/role'

export default gql`
  mutation createRole($label: String!, $accountID: ID!) {
    createRole(label: $label, accountID: $accountID){
      ...roleFragment
    }
  }
  ${role}
`
