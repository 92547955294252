import clientWithAuth from  '../../v1/graphql/client-with-auth'
import * as query from '../../graphql/query/_index'
import * as mutation from '../../graphql/mutation/_index'
import { returnNested } from '../../utils/tools'

/**
 * The underlying graphql query returns promise
 * @see queryByParams
 * @param queryDefaultName is string name of query like 'getAccount'
 * @param queryArgs is Object like { accountID } or { accountID: accountId }
 * @param queryCustomName is string name of query like 'getAccount'
 * @returns {Promise<unknown>}
 */
const queryByParams = (queryDefaultName, queryArgs, queryCustomName=null) =>
  new Promise((resolve, reject) => {
    clientWithAuth.query({
      query: query[queryCustomName || queryDefaultName],
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: queryArgs,
    })
      .then(({ data: response }) => resolve(response[queryDefaultName]))
      .catch(err => reject(err))
  })

/**
 * The underlying graphql query returns cancellable promise
 * @see cancellableQueryByParams
 * @param queryDefaultName is string name of query like 'getAccount'
 * @param queryArgs is Object like { accountID } or { accountID: accountId }
 * @param queryCustomName is string name of query like 'getAccount'
 * @returns {Promise<unknown>}
 */
const cancellableQueryByParams = (queryDefaultName, queryArgs, queryCustomName=null) => {
  const abortController = new AbortController()
  const observable = clientWithAuth.watchQuery({
    query: query[queryCustomName || queryDefaultName],
    fetchPolicy: 'no-cache',
    variables: queryArgs,
    context: {
      fetchOptions: {
        signal: abortController.signal
      }
    },
    queryDeduplication: false
  })
  return new Promise((resolve, reject) => {
    const subscription = observable.subscribe(
      ({ data: response }) => {
        response[queryDefaultName].cancel = () => {
          abortController.abort()
          subscription.unsubscribe()
        }
        return resolve(response[queryDefaultName])
      },
      err => reject(err)
    )
  })
}

/**
 * The underlying graphql query returns promise
 * @see mutateByParams
 * @param mutationName is string name of mutation like 'cancelSubscription'
 * @param mutationArgs is Object like { accountID } or { accountID: accountId }
 * @returns {Promise<unknown>}
 */
const mutateByParams = (mutationName, mutationArgs) =>
  new Promise((resolve, reject) => {
    clientWithAuth.mutate({
      mutation: mutation[mutationName],
      fetchPolicy: 'no-cache',
      variables: mutationArgs,
      context: returnNested(mutationArgs, 'abortController') ? {
        fetchOptions: {
          signal: mutationArgs.abortController.signal
        }
      } : {}
    })
      .then(({ data: response }) => resolve(response[mutationName]))
      .catch(err => reject(err))
  })

const resource = {
  queryByParams,
  cancellableQueryByParams,
  mutateByParams
}
export default resource
