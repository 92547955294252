import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import resource from '../../utils/resource/resource'
import {
  setSelectedSpaceAction,
  addSpaceAction,
  setSpacePoliciesAction,
  setImpactMethodSetsAction,
  changeSpaceImpactSettingsAction,
  setSpaceResourceTypePoliciesAction,
  setSelectedProductAction,
  setSpaceNameAction,
  setFullSpaceProductListAction,
  setProductListSortTypeAction,
  deleteSpaceAction,
  setImpactCategoriesAction,
  setImpactMethodsAction,
  setNwSetsAction
} from '../actions/global.actions'
import { addSpaceToAccountAction, setAccountAction } from '../actions/account.actions'
import { setIsDetailsPanelOpenAction, setIsForcingWorkspacePanelAction, setIsCreatePhaseAction } from '../actions/flags.actions'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import { clearGlobalStateExceptAccountAction, clearFlagsStateAction } from '../actions/clear.actions'
import { setSelectedPhaseAction } from '../actions/lifecycle.actions'
import {
  setIsLoadingSpaceImpactMethodsAction,
  setIsSpaceImpactSettingsLoadingAction
} from '../actions/loading.actions'
import { returnNested } from '../../utils/tools'
import { RESOURCE_TYPE, PAGINATION } from '../../utils/const'
import { updateAccountDataSaga, updateAccountDataForNoAdminUserSaga } from './account.saga'

import { setReferenceDatabasesAction } from '../actions/referenceProducts.actions'
import { searchProductsSaga } from './product.saga'

import { dispatchErrors, updateSelectedProduct } from './helpers'
import { setLastSelectedSpaceIdAction } from '../actions/settings.actions'

import UserSelector from '../selectors/user.selector'
import AccountSelector from '../selectors/account.selector'
import { selectedWorkspaceVar, impactCategoriesVar } from '../../graphql/cache'

export const
  GET_SPACE_BY_ID = 'GET_SPACE_BY_ID',
  GET_SPACE_BY_SLUG_WITH_PRODUCTS = 'GET_SPACE_BY_SLUG_WITH_PRODUCTS',
  CREATE_SPACE = 'CREATE_SPACE',
  CREATE_WORKSPACE_FOR_NO_ADMIN_USER = 'CREATE_WORKSPACE_FOR_NO_ADMIN_USER',
  SET_SELECTED_SPACE = 'SET_SELECTED_SPACE_SAGA',
  SET_SPACE_POLICIES = 'SET_SPACE_POLICIES_SAGA',
  GET_IMPACT_METHOD_SETS = 'GET_IMPACT_METHOD_SETS_SAGA',
  SET_SPACE_IMPACT_SETTINGS = 'SET_SPACE_IMPACT_SETTINGS_SAGA',
  FORCE_WORKSPACE_PANEL = 'FORCE_WORKSPACE_PANEL_SAGA',
  RENAME_WORKSPACE = 'RENAME_WORKSPACE_SAGA',
  GET_FULL_SPACE_PROTUCT_LIST = 'GET_FULL_SPACE_PROTUCT_LIST_SAGA',
  SET_PRODUCT_LIST_SORT_TYPE = 'SET_PRODUCT_LIST_SORT_TYPE_SAGA',
  REMOVE_WORKSPACE = 'REMOVE_WORKSPACE_SAGA',
  GET_IMPACT_CATEGORIES_FOR_INVENTORY = 'GET_IMPACT_CATEGORIES_FOR_INVENTORY_SAGA',
  GET_IMPACT_LISTS = 'GET_IMPACT_LISTS_SAGA',
  GET_IMPACT_METHODS = 'GET_IMPACT_METHODS_SAGA',
  GET_IMPACT_CATEGORIES = 'GET_IMPACT_CATEGORIES_SAGA',
  GET_IMPACT_METHOD_LISTS = 'GET_IMPACT_METHOD_LISTS_SAGA',
  GET_NW_SETS = 'GET_NW_SETS_SAGA',
  GET_IMPACT_METHOD_DATABASES = 'GET_IMPACT_METHOD_DATABASES_SAGA'

export const getSpaceSaga = (spaceId, cb) => ({
  type: GET_SPACE_BY_ID,
  spaceId, cb
})

export const getSpaceBySlugWithProductsSaga = (slug, accountId) => ({
  type: GET_SPACE_BY_SLUG_WITH_PRODUCTS,
  slug, accountId
})

export const getFullSpaceProductListSaga = spaceID => ({
  type: GET_FULL_SPACE_PROTUCT_LIST,
  spaceID
})

export const setSelectedSpaceSaga = (spaceId, cb) => ({
  type: SET_SELECTED_SPACE,
  spaceId, cb
})

export const setSpacePoliciesSaga = spaceId => ({
  type: SET_SPACE_POLICIES,
  spaceId
})

export const getImpactMethodSetsSaga = cb => ({
  type: GET_IMPACT_METHOD_SETS,
  cb
})

export const getImpactListsSaga = (methodSetId, methodId, cb) => ({
  type: GET_IMPACT_LISTS,
  methodSetId, methodId, cb
})

export const getImpactMethodDatabasesSaga = (methodId, cb) => ({
  type: GET_IMPACT_METHOD_DATABASES,
  methodId, cb
})

export const getImpactCategoriesForInventorySaga = methodId => ({
  type: GET_IMPACT_CATEGORIES_FOR_INVENTORY,
  methodId
})

export const getImpactMethodsSaga = (methodSetId, cb) => ({
  type: GET_IMPACT_METHODS,
  methodSetId, cb
})

export const getImpactCategoriesSaga = (methodId, cb) => ({
  type: GET_IMPACT_CATEGORIES,
  methodId, cb
})
export const getImpactMethodListSaga = (methodId, cb) => ({
  type: GET_IMPACT_METHOD_LISTS,
  methodId, cb
})

export const getNwSetsSaga = (methodId, cb) => ({
  type: GET_NW_SETS,
  methodId, cb
})

export const setSpaceImpactSettingsSaga = ({
  spaceId,
  impactMethodId,
  impactCategoryId,
  nwSetId,
  nwSetType,
  excludeLT,
  databases,
  useMethodTotal,
  selectedProduct,
  cb
}) => ({
  type: SET_SPACE_IMPACT_SETTINGS,
  spaceId,
  impactMethodId,
  impactCategoryId,
  nwSetId,
  nwSetType,
  excludeLT,
  databases,
  useMethodTotal,
  selectedProduct,
  cb
})

export const forceWorkspacePanelSaga = status => ({
  type: FORCE_WORKSPACE_PANEL,
  status
})

export const renameWorkspaceSaga = (id, name, cb) => ({
  type: RENAME_WORKSPACE,
  id, name, cb
})

export const removeWorkspaceSaga = (spaceId, cb) => ({
  type: REMOVE_WORKSPACE,
  spaceId, cb
})

export const setProductListSortTypeSaga = ({ productListSortType, spaceID, currentProductPageNumber, labels }) => ({
  type: SET_PRODUCT_LIST_SORT_TYPE,
  productListSortType, spaceID, currentProductPageNumber, labels
})
/**
 * @param {{type: string, spaceId: string}} action
 * @returns {IterableIterator<*>}
 */
export function* getSpace(action) {
  try {
    // const space = yield call(resource.queryByParams, 'space', { id: action.spaceId })
    const { space = null } = selectedWorkspaceVar() || {}
    yield put(setSelectedSpaceAction(space))
    action.cb && action.cb(space?.slug)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, slug: string, accountId: string}}action
 * @returns {IterableIterator<*>}
 */
function* getSpaceBySlug() {
  try {
    // const spaceBySlug = yield call(resource.queryByParams, 'spaceBySlug', { slug: action.slug, accountID: action.accountId })
    // const space = yield call(resource.queryByParams, 'space', { id: returnNested(spaceBySlug, 'id') })
    const { space = null } = selectedWorkspaceVar() || {}
    yield put(setSelectedSpaceAction(space))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{accountID: string, name: string, [cb]: function}} action
 */
export function* createSpace(action) {
  try {
    const space = yield call(resource.mutateByParams, 'createSpace', { accountID: action.accountID, name: action.name })
    yield put(addSpaceAction(space))
    yield put(addSpaceToAccountAction(space))
    yield put(setSelectedSpaceSaga(returnNested(space, 'id')))
    yield put(updateAccountDataSaga(action.accountID))
    action.cb && action.cb(space.slug)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{accountID: string, name: string, [cb]: function}} action
 */
export function* createSpaceForNoAdminUser(action) {
  try {
    const space = yield call(resource.mutateByParams, 'createSpace', { accountID: action.accountID, name: action.name })
    yield put(addSpaceAction(space))
    yield put(addSpaceToAccountAction(space))
    yield put(setSelectedSpaceSaga(returnNested(space, 'id')))
    yield put(updateAccountDataForNoAdminUserSaga(action.accountID))
    action.cb && action.cb(space.slug)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{spaceId: string}} action
 */
function* setSelectedSpace(action) {
  try {
    const selectedUserId = yield select(UserSelector.selectedUserId)
    yield put(clearGlobalStateExceptAccountAction())
    yield put(clearFlagsStateAction())
    yield put(getSpaceSaga(action.spaceId, spaceSlug => {
      action.cb && action.cb(spaceSlug)
    }))
    yield put(setSpacePoliciesSaga(action.spaceId))
    yield put(setIsDetailsPanelOpenAction(false))
    yield put(setLastSelectedSpaceIdAction(selectedUserId, action.spaceId))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{spaceId: string}} action
 */
function* setSpacePolicies() {
  try {
    let { hasAccess: policies } = selectedWorkspaceVar() || {}
    const spaceResourceTypePolicies = yield call(resource.queryByParams, 'resourceTypePolicies', { resourceType: RESOURCE_TYPE.WORKSPACE })
    policies && (yield put(setSpacePoliciesAction(policies)))
    yield put(setSpaceResourceTypePoliciesAction(spaceResourceTypePolicies))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* getImpactCategoriesForInventory(action) {
  try {
    const impactCategories = yield call(resource.queryByParams, 'impactCategories', { methodID: action.methodId })
    impactCategoriesVar(impactCategories)
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingSpaceImpactMethodsAction(false))
  }
}

export function* getImpactMethodSets() {
  try {
    const impactMethodSets = yield call(resource.queryByParams, 'impactMethodSets')
    yield put(setImpactMethodSetsAction(impactMethodSets))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* getImpactMethods(action) {
  const { methodSetId, cb } = action
  try {
    const impactMethods = yield call(resource.queryByParams, 'impactMethods', { setID: methodSetId })
    yield put(setImpactMethodsAction(impactMethods))
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}


function* getReferenceDatabases(action) {
  const { methodId, cb } = action
  try {
    const referenceDatabases = yield call(resource.queryByParams, 'referenceDatabases' , { methodId })
    yield put(setReferenceDatabasesAction(referenceDatabases))
    cb && cb(referenceDatabases)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* getImpactCategories(action) {
  const { methodId, cb } = action
  try {
    const impactCategories = yield call(resource.queryByParams, 'impactCategories', { methodID: methodId })
    yield put(setImpactCategoriesAction(impactCategories))
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* getImpactMethodLists(action) {
  const { cb, methodId } = action
  yield put(setIsLoadingSpaceImpactMethodsAction(true))
  try {
    const [
      referenceDatabases
    ] = yield all([
      call(resource.queryByParams, 'referenceDatabases' , { methodId }),
      call( getImpactCategories, { methodId } ),
      call( getNwSets, { methodId } )
    ])
    yield put(setReferenceDatabasesAction(referenceDatabases))
    cb && cb({
      referenceDatabases
    })
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingSpaceImpactMethodsAction(false))
  }
}

export function* getNwSets(action) {
  const { methodId, cb } = action
  try {
    const nwSets = yield call(resource.queryByParams, 'nwSets', { methodID: methodId })
    yield put(setNwSetsAction(nwSets))
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* getImpactLists(action) {
  const { cb, methodId, methodSetId } = action
  yield put(setIsLoadingSpaceImpactMethodsAction(true))
  try {
    const impactCategoriesFromCache = impactCategoriesVar() || []
    yield all([
      put(setImpactCategoriesAction(impactCategoriesFromCache)),
      call( getImpactMethods, { methodSetId } ),
      call( getReferenceDatabases, { methodId } ),
      call( getNwSets, { methodId } )
    ])
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingSpaceImpactMethodsAction(false))
  }
}

export const createSpaceSaga = (accountID, name, cb) => ({
  type: CREATE_SPACE,
  accountID, name, cb
})

export const createSpaceForNoAdminUserSaga = (accountID, name, cb) => ({
  type: CREATE_WORKSPACE_FOR_NO_ADMIN_USER,
  accountID, name, cb
})

export function* setSpaceImpactSettings(action) {
  const {
    spaceId,
    impactMethodId,
    impactCategoryId,
    nwSetId,
    nwSetType,
    databases,
    excludeLT = false,
    useMethodTotal = false
  } = action || {}
  yield put(setIsSpaceImpactSettingsLoadingAction(true))
  try {
    const changeSpaceImpactSettingsArgs = {
      id: spaceId,
      nwSetType,
      excludeLT,
      databases,
      useMethodTotal,
      nwSetID: nwSetId,
      impactMethodID: impactMethodId,
      impactCategoryID: impactCategoryId,
    }
    const space = yield call(resource.mutateByParams, 'changeSpaceImpactSettings', changeSpaceImpactSettingsArgs)
    yield put(changeSpaceImpactSettingsAction(space.impactMethod, space.nwSet, space.impactCategory, space.excludeLT, space.useMethodTotal))
    yield call(updateSelectedProduct, action.selectedProduct)
    yield put(addSuccessNotificationAction('model.impact_settings_updated'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsSpaceImpactSettingsLoadingAction(false))
  }
}

export function* forceWorkspacePanel(action) {
  try {
    yield put(setSelectedProductAction(null))
    yield put(setIsCreatePhaseAction(false))
    yield put(setSelectedPhaseAction(null))
    yield put(setIsDetailsPanelOpenAction(true))
    yield put(setIsForcingWorkspacePanelAction(action.status))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* renameWorkspaceHandler(action) {
  try {
    const space = yield call(resource.mutateByParams, 'renameSpace', { id: action.id, name: action.name })
    yield put(setSpaceNameAction(space.name, space.slug))
    yield put(addSuccessNotificationAction('model.workspace_renamed_success'))
    action.cb && action.cb(space)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* removeWorkspaceHandler(action) {
  try {
    yield call(resource.mutateByParams, 'removeSpace', { id: action.spaceId })
    yield put(deleteSpaceAction(action.spaceId))
    yield put(clearGlobalStateExceptAccountAction())
    yield put(clearFlagsStateAction())

    const selectedAccountId = yield select(AccountSelector.selectedAccountId)
    const account = yield call(resource.queryByParams, 'account', { id: selectedAccountId })
    yield put(setAccountAction(account))

    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* getFullSpaceProductListHandler(action) {
  try {
    const fullProductList = yield call(resource.queryByParams, 'products', { spaceID: action.spaceID, categories: [] })
    yield put(setFullSpaceProductListAction(fullProductList))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* setProductListSortTypeHandler(action) {
  try {
    yield put(setProductListSortTypeAction(action.productListSortType))
    yield put(searchProductsSaga({
      spaceId: action.spaceID,
      currentPage: action.currentProductPageNumber,
      pageSize: PAGINATION.PAGE_SIZE,
      sortBy: action.productListSortType,
      labels: action.labels
    }))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export default function* spaceSaga() {
  yield takeLatest(GET_SPACE_BY_ID, getSpace)
  yield takeLatest(GET_SPACE_BY_SLUG_WITH_PRODUCTS, getSpaceBySlug)
  yield takeLatest(CREATE_SPACE, createSpace)
  yield takeLatest(CREATE_WORKSPACE_FOR_NO_ADMIN_USER, createSpaceForNoAdminUser)
  yield takeLatest(SET_SELECTED_SPACE, setSelectedSpace)
  yield takeLatest(SET_SPACE_POLICIES, setSpacePolicies)
  yield takeLatest(SET_SPACE_IMPACT_SETTINGS, setSpaceImpactSettings)
  yield takeLatest(FORCE_WORKSPACE_PANEL, forceWorkspacePanel)
  yield takeLatest(RENAME_WORKSPACE, renameWorkspaceHandler)
  yield takeLatest(GET_FULL_SPACE_PROTUCT_LIST, getFullSpaceProductListHandler)
  yield takeLatest(SET_PRODUCT_LIST_SORT_TYPE, setProductListSortTypeHandler)
  yield takeLatest(REMOVE_WORKSPACE, removeWorkspaceHandler)
  yield takeLatest(GET_IMPACT_CATEGORIES_FOR_INVENTORY, getImpactCategoriesForInventory)
  yield takeLatest(GET_IMPACT_METHOD_SETS, getImpactMethodSets)
  yield takeLatest(GET_IMPACT_METHOD_DATABASES, getReferenceDatabases)
  yield takeLatest(GET_IMPACT_LISTS, getImpactLists)
  yield takeLatest(GET_IMPACT_METHODS, getImpactMethods)
  yield takeLatest(GET_IMPACT_CATEGORIES, getImpactCategories)
  yield takeLatest(GET_IMPACT_METHOD_LISTS, getImpactMethodLists)
  yield takeLatest(GET_NW_SETS, getNwSets)
}
