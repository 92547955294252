import { Button, Card, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import compose from '../../../utils/compose'
import { withRouter } from '../../../utils/with-router'
import { withTranslation } from 'react-i18next'
import {  NAV } from '../../../utils/const'
import { returnNested, safeArray } from '../../../utils/tools'
import { useNavigate } from 'react-router-dom'
import TypeRemoveInput from '../../model/type-remove-input/typeRemoveInput.tsx'
import {currentWorkspaceSlugVar, setSelectedAccount, setSelectedWorkspace, userVar} from '../../../graphql/cache'
import cloneDeep from 'lodash.clonedeep'

function RemoveAccount(props) {
  const [ isOkDisabled, setIsOkDisabled ] = useState(true)
  const [ isShowRemoveAccountModal, setIsShowRemoveAccountModal ] = useState(false)
  const [ removeCallbackData, setRemoveCallbackData ] = useState({ removedAccountId: null, nextAccountId: null })

  const user = userVar()
  const {
    t,
    selectedAccountId,
    removeAccountSaga,
    listOfUserAccounts
  } = props
  const navigate = useNavigate()

  useEffect(() => {
    if ( removeCallbackData.removedAccountId ) {
      const { removedAccountId, filteredAccountList } = removeCallbackData

      setSelectedWorkspace({ loading: true })
      setSelectedAccount({ loading: true })
      currentWorkspaceSlugVar(null)

      const nextAccountId = returnNested(filteredAccountList, 0, 'id')
      const clonedUser = cloneDeep(user)
      clonedUser.accounts.splice(clonedUser.accounts.findIndex(account => account.id === removedAccountId), 1)

      userVar({ ...clonedUser })
      if ( nextAccountId ) {
        navigate(`${NAV.ACCOUNT}${nextAccountId}`)
      } else {
        navigate(`${NAV.ROOT}`)
      }
    }
  }, [ removeCallbackData ])

  const handleRemoveAccount = () => {
    const filteredAccountList = safeArray(listOfUserAccounts).filter(el => el.id !== selectedAccountId)
    setRemoveCallbackData({ removedAccountId: selectedAccountId, filteredAccountList })
    removeAccountSaga(selectedAccountId)
    setIsShowRemoveAccountModal(false)
  }

  return (
    <div className="remove-account-wrapper">
      <Card
        type="inner"
        className="card-item card-title-big"
        title={ t('account_settings.delete_account')}
        extra={<Button data-cy="remove-account-button" onClick={ () => setIsShowRemoveAccountModal( true ) } type="danger" className="button danger">
          {t('account_settings.delete_account')}
        </Button>}
      >
        <div>{ t('account_settings.removing_account_description') }</div>
      </Card>
      <Modal
        title={ t('account_settings.delete_account')}
        onOk={ handleRemoveAccount }
        visible={ isShowRemoveAccountModal }
        onCancel={ () => setIsShowRemoveAccountModal(false) }
        centered={ true }
        className='modal-window'
        maskClosable={ true }
        destroyOnClose={ true }
        okButtonProps={{ disabled: isOkDisabled }}
      >
        {t('account_settings.remove_account_confirmation')}
        <TypeRemoveInput
          inputPlaceholder={t('model.type_remove_to_confirm')}
          inputType='account-input'
          setIsRemoveWordTyped={isRemoveTyped => setIsOkDisabled(!isRemoveTyped)}/>
      </Modal>
    </div>
  )
}

export default compose(
  withTranslation(),
  withRouter
)(RemoveAccount)
