import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { Tree } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import InventoryTreeItem from '../inventory/inventory-tree/inventoryTreeItem.container'
import { InventoryTreeKey } from '../../../utils/inventoryTreeKey'
import { inventoryItemsToTree } from '../../../utils/treeUtils'
import { isEmpty, returnNested } from '../../../utils/tools'
import InventoryTreeHeader from '../inventory/inventory-tree/inventoryTreeHeader'

class PhaseInventoryTree extends React.PureComponent {
  state = {}

  constructor(props) {
    super(props)
    this.onItemTreeSelected = this.onItemTreeSelected.bind(this)
    this.onExpandTreeItem = this.onExpandTreeItem.bind(this)
  }

  /**
   * @param {array} selectedKeys
   */
  onItemTreeSelected(selectedKeys) {
    const { amountModalPreference, setIsDetailsPanelOpenAction } = this.props

    if ( amountModalPreference?.visible ) {
      setIsDetailsPanelOpenAction(false)
      return
    }

    this.props.selectedInventoryItemSaga(selectedKeys[0], this.props.phase)
  }

  /**
   *
   * @param expandedKeys
   * @param options {{expanded: bool, node: {}}}
   */
  onExpandTreeItem(expandedKeys, options) {
    const { phase, toggleExpandedKeySaga } = this.props
    toggleExpandedKeySaga(
      returnNested(phase, 'inventory', 'product', 'id'),
      returnNested(options, 'node', 'key'),
      options.expanded
    )
  }

  parseChildrenTreeItems = (inventoryTree, nodeChildrenList, maxImpactAmount) => {
    const { isLeftTree } = this.props
    let inventoryNodeList = []
    for (let inventoryNode of nodeChildrenList) {
      const nodeKey = InventoryTreeKey.createFromInventoryNode(inventoryTree, inventoryNode).inventoryTreeKey()
      const product = returnNested(inventoryNode, 'inventoryItem', 'product')
      const inventoryItemProps = {
        isLeftTree,
        amount: returnNested(inventoryNode, 'inventoryItem', 'amount'),
        name: returnNested(product, 'name'),
        impact: returnNested(inventoryNode, 'inventoryItem', 'impact'),
        maxImpactAmount,
        unit: returnNested(product, 'unit'),
        isShowLifecycleScenarioInventory: this.props.isShowLifecycleScenarioInventory,
        isInventoryCompare: this.props.isInventoryCompare,
        hasImpactSource: returnNested(product, 'referenceProduct') || !isEmpty(returnNested(product, 'customImpacts')),
        isLeaf: isEmpty(inventoryNode.children.length),
        nodeKey,
        isReadOnly: this.props.isReadOnly,
        areImpactsBarsShown: this.props.areImpactsBarsShown,
        isRoot: returnNested(inventoryNode, 'parentNode') === '',
        hasInventory: returnNested(inventoryNode, 'inventoryItem', 'product', 'hasInventory'),
        phase: this.props.phase,
        productId: product && product.id
      }
      inventoryNodeList.push(
        {
          title: <InventoryTreeItem { ...inventoryItemProps } />,
          key: nodeKey,
          selectable: true,
          className: 'inventory-item',
          isRoot: false,
          'data-cy': `tree-item-named-${ inventoryNode.inventoryItem.product.name.replace(/\s/g, '-').toLowerCase() }`,
          children: inventoryNode.children.length > 0
            ? this.parseChildrenTreeItems(inventoryTree, inventoryNode.children, maxImpactAmount, inventoryNode.nodeId)
            : []
        },
      )
    }
    return inventoryNodeList
  }

  render() {
    const { phase, selectedInventoryItem, maxImpactAmount, expandedKeys, currentPhaseIndex, isDetailsPanelOpen } = this.props
    if (isEmpty(phase)) return null
    const inventoryTree = phase.inventory
    const treeItems = inventoryItemsToTree(inventoryTree.inventoryItems || [])
    const treeData = this.parseChildrenTreeItems(inventoryTree, treeItems, maxImpactAmount)
    const impactUnit = returnNested(inventoryTree, 'totalImpact', 'unit')

    return (
      <React.Fragment>
        { currentPhaseIndex === 0 && !isEmpty(treeData) && <InventoryTreeHeader impactUnit={impactUnit} areImpactsBarsShown={this.props.areImpactsBarsShown} /> }
        {
          returnNested(inventoryTree, 'product') &&
            <Tree
              onSelect={ this.onItemTreeSelected }
              className="inventory-tree"
              selectedKeys={ isDetailsPanelOpen ? [ selectedInventoryItem ] : [] }
              expandedKeys={ expandedKeys }
              onExpand={ this.onExpandTreeItem }
              treeData={ treeData }
              isReadOnly={ this.props.isReadOnly }
              areImpactsBarsShown={this.props.areImpactsBarsShown}
              showLine
              switcherIcon={<CaretDownOutlined className="tree-switcher-icon" />}
            />
        }
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation(),
)(PhaseInventoryTree)
