import { Form, Divider } from 'antd'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { CUSTOMER_TYPE } from '../../../utils/const'
import { isEmpty, returnNested } from '../../../utils/tools'
import ModalWindow from '../../model/modalWindow/modalWindow.container'
import CustomerFields from './customerFields'

class CustomerModal extends React.Component {
  formRef = React.createRef()

  handleOnFinish = () => {
    const { okCallback } = this.props
    this.formRef.current.validateFields().then(values => {
      this.formRef.current.resetFields()
      okCallback(values)
    })
  }

  handleCancel = () => {
    const { cancelCallback } = this.props
    this.formRef.current.resetFields()
    cancelCallback && cancelCallback()
  }

  render() {
    const { customer, showCustomerModal, t } = this.props
    if (isEmpty(customer)) return null

    return <ModalWindow
      isShowModal={showCustomerModal}
      title={t('billing.customer_update_title')}
      closable
      cancelText={t('global.cancel')}
      okText={t('global.continue')}
      cancelHandler={ this.handleCancel }
      okHandler={() => { this.formRef.current.submit() }}
      destroyOnClose={true}
      content={ () => <React.Fragment>
        <span>{t('billing.customer_details_incomplete')}</span>
        <Divider />
        <Form
          ref={this.formRef}
          onFinish={this.handleOnFinish }
          layout="vertical"
          hideRequiredMark
          className='field-container editable-field'
          initialValues={{
            name: returnNested(customer, 'name'),
            email: returnNested(customer, 'email'),
            phone: returnNested(customer, 'phone'),
            country: returnNested(customer, 'address', 'country'),
            line1: returnNested(customer, 'address', 'line1'),
            line2: returnNested(customer, 'address', 'line2'),
            postalCode: returnNested(customer, 'address', 'postalCode'),
            city: returnNested(customer, 'address', 'city'),
            state: returnNested(customer, 'address', 'state'),
            customerType: returnNested(customer, 'vat') !== '' ? CUSTOMER_TYPE.BUSINESS : undefined,
            vat: returnNested(customer, 'vat'),
          }}>
          <CustomerFields customer={customer} isFormEditable={true}/>
        </Form>
      </React.Fragment>
      } />
  }
}

export default compose(
  withTranslation()
)(CustomerModal)
