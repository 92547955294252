export enum ImpactDatasetViewType {
  DatabaseSearch = 'tab-database-search-view',
  ElementaryFlow = 'tab-elementary-flow-view',
  CustomImpact = 'tab-custom-impact-view',
}

export enum ImpactDatasetViewMode {
  Result = 'result',
  Detail = 'detail'
}
