import React, { useContext, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { PRODUCT_EXPORT } from '../../../graphql/query'
import { ExportProductCsvComponent } from '../../component/dialog/export-product-csv.component'
import { ExportColumn } from '../../enum/export-column'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'

export const ExportProductCsvContainer = () => {
  const { t } = useTranslation([ 'product' ])
  const [ trackEventInSegment ] = getSegmentTrack()
  const toast = useRef<Toast>(null)
  const csvLinkRef = useRef<any>(null)
  const {
    showExportProductCsv = false,
    productInventory,
    updateProductDetail
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const { product: { id = '', name = null } = {} } = productInventory || {}

  const {
    loading: exportingProduct,
    data: exportedProduct
  } = useQuery(PRODUCT_EXPORT, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache'
  })

  const closeExportProductCsvDialog = () => {
    updateProductDetail({ showExportProductCsv: false })
  }

  const handleExportProductCsv = () => {
    csvLinkRef?.current?.link?.click()
    trackEventInSegment(TrackEventType.EXPORTED_INVENTORY)
    closeExportProductCsvDialog()
  }

  const getHeaders = () => {
    const exportColumnKeys = Object.values(ExportColumn)
    return exportColumnKeys.map((key: any) => ({ key, label: t('labels.headerExportProductCsv', { context: key }) }))
  }

  return (
    <>
      <ExportProductCsvComponent
        exportingProduct={exportingProduct}
        handleExportProductCsv={handleExportProductCsv}
        closeExportProductCsvDialog={closeExportProductCsvDialog}
        showExportProductCsv={showExportProductCsv}
      />

      {exportedProduct && (
        <CSVLink
          ref={csvLinkRef}
          data={exportedProduct.productExport}
          filename={`${name}_export.csv`}
          headers={getHeaders()}
          style={{ display: 'hide' }}
          separator=";"
        />
      )}

      <Toast data-testid="export-product-status" ref={toast} position="top-right" />
    </>
  )
}
