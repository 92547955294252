import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ReferenceProduct } from '../../../__generated__/graphql'
import { ProductType } from '../../shared/enum'

const Description = styled.div`
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #0000000f;
`

type ProductNameProps = {
  isInTooltip?: boolean;
}

const ProductName = styled.li<ProductNameProps>`
  font-size: 15px;
  font-weight: 500;
  color: ${(props: ProductNameProps) => (props.isInTooltip ? '#ffffff' : '#6b6b6b')};
`

type ReferenceProductInfoComponentProps = {
  referenceProduct: ReferenceProduct,
  isInTooltip?: boolean;
}

// TODO : From v0.9 : To be refactored
export const ReferenceProductInfoComponent = ({ referenceProduct, isInTooltip }: ReferenceProductInfoComponentProps) => {
  const { t } = useTranslation('common')
  const isElementaryFlow = referenceProduct.type === ProductType.ElementaryFow
  const {
    location,
    parentCategory,
    category,
    referenceUnit,
    referenceProperty
  } = referenceProduct

  return (
    <>
      <ul>
        <ProductName isInTooltip={isInTooltip}>{referenceProduct.name}</ProductName>
        {isElementaryFlow && <li>{t('labels.elementaryFlow')}</li>}
        {location?.name && <li>{location.name}</li>}
        {!isElementaryFlow && <li>{referenceProduct.databaseName}</li>}
        <li>
          {parentCategory?.name} / {category?.name}
        </li>
        <li>
          {parseFloat(referenceProduct.amount)} {referenceUnit?.name} {referenceProperty?.name}
        </li>
      </ul>

      {!isInTooltip && referenceProduct?.description && (
        <Description className="text-gray-500">
          {referenceProduct.description}
        </Description>
      )}
    </>
  )
}
