export const
  CLEAR_ROOT_STATE                    = 'CLEAR_ROOT_STATE',
  CLEAR_ROOT_STATE_EXCEPT_SETTINGS    = 'CLEAR_ROOT_STATE_EXCEPT_SETTINGS',
  CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT   = 'CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT',
  CLEAR_FLAGS_STATE                   = 'CLEAR_FLAGS_STATE',
  CLEAR_GLOBAL_STATE_EXCEPT_USER      = 'CLEAR_GLOBAL_STATE_EXCEPT_USER',
  CLEAR_INVENTORY_TREE_STATE          = 'CLEAR_INVENTORY_TREE_STATE',
  CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE    = 'CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE'

export const clearRootStateAction = () => ({ type: CLEAR_ROOT_STATE })
export const clearRootStateExceptSettingsAction = () => ({ type: CLEAR_ROOT_STATE_EXCEPT_SETTINGS })
export const clearGlobalStateExceptAccountAction = () => ({ type: CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT })
export const clearGlobalStateExceptUserAction = () => ({ type: CLEAR_GLOBAL_STATE_EXCEPT_USER })
export const clearFlagsStateAction = () => ({ type: CLEAR_FLAGS_STATE })
export const clearInventoryTreeStateAction = () => ({ type: CLEAR_INVENTORY_TREE_STATE })
export const clearUiWhenDetailPanelCloseAction = () => ({ type: CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE })
