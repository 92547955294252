import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/pro-solid-svg-icons'

import { HeaderTableComponent } from '../component/header/header-table.component'
import { TableComponent } from '../component/table/table.component'
import { EmptyStateScreenLayout } from '../../shared/layout/empty-state-screen.layout'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'

export const LifecycleDetailTableLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    lifecycleDetail,
    lifecycleTotalImpact,
    goToInventoryTab
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const lifecycleHasItems = lifecycleDetail?.phases?.some(phase => phase.inventory?.product?.hasInventory)
  const lifecycleHasNoImpact = parseFloat(lifecycleTotalImpact?.amount || '0') === 0

  if (!lifecycleHasItems || (lifecycleHasItems && lifecycleHasNoImpact)) {
    const goToInventory = () => goToInventoryTab?.('table')

    return (
      <EmptyStateScreenLayout
        wrapperClassName="h-30rem"
        title={t('labels.emptyState', { context: !lifecycleHasItems ? 'addItemsToShowTable' : 'addImpactToShowTable', ns: 'common' })}
        icon={<FontAwesomeIcon icon={faTable} className="text-primary-100" fontSize={106} />}
        footer={<Button onClick={goToInventory}>{t('actions.go_toModel', { ns: 'common' })}</Button>}
      />
    )
  }

  return (
    <div className="flex flex-column border-round-lg bg-white w-full tree-panel tree-panel-shadow">
      <div className="flex-none flex p-5 pb-2 h-4rem w-full">
        <HeaderTableComponent />
      </div>
      <div className="flex flex-column flex-grow-1 p-5 pt-3 w-full h-full">
        <TableComponent />
      </div>
    </div>
  )
}
