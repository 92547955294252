import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Checkbox } from 'primereact/checkbox'
import { classNames } from 'primereact/utils'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

const CollapseAllWrapper = styled.div`
  width: fit-content;

  .p-checkbox-box {
    width: 1rem;
    height: 1rem;
  }
`

export const ModelHeaderComponent = () => {
  const { t } = useTranslation([ 'lifecycle', 'common' ])
  const { expandedPhases = [], updateLifecycleDetail, highlightCollapseAll = false } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  return (
    <div className="flex align-items-center justify-content-start w-full h-full" data-html2canvas-ignore>
      <div className="flex flex-grow-1 text-gray-700 text-2xl">
        { t('labels.headerModal') }
      </div>
      <div className="flex flex-none gap-3">
        <CollapseAllWrapper
          data-testid="collapse-all-wrapper"
          className={classNames('flex align-items-center h-2rem px-2 border-1 border-round-sm text-gray-500', {
            'border-gray-100': !highlightCollapseAll,
            'border-blue-500': highlightCollapseAll
          })}
        >
          <div className="flex flex-none align-items-center gap-1">
            <Checkbox
              data-testid="hide-impact-bar-filter"
              data-cy="hide-impact-bar-filter"
              className="mt-1"
              inputId="hide-impact-bar"
              disabled={expandedPhases.length === 0}
              onChange={() => updateLifecycleDetail({ expandedPhases: [] })}
              checked={expandedPhases.length === 0}
            />
            <div
              data-testid="collapse-all-text"
              className={classNames('flex text-md font-normal line-height-1', {
                'text-gray-500': expandedPhases.length !== 0 && !highlightCollapseAll,
                'text-gray-200': expandedPhases.length === 0,
                'text-blue-500': highlightCollapseAll
              })}
            >
              { t('labels.collapse', { context: 'all', ns: 'common' }) }
            </div>
          </div>
        </CollapseAllWrapper>
      </div>
    </div>
  )
}
