import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

type ExportProductCsvComponentProps = {
  exportingProduct?: boolean,
  showExportProductCsv: boolean,
  closeExportProductCsvDialog: () => void,
  handleExportProductCsv: () => void
}
export const ExportProductCsvComponent = ({
  exportingProduct = false,
  showExportProductCsv,
  closeExportProductCsvDialog,
  handleExportProductCsv
}: ExportProductCsvComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { productInventory } = useContext<TProductDetailContext>(ProductDetailContext)

  const { product } = productInventory || {}

  const onHide = () => {
    closeExportProductCsvDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="export-product-csv"
      header="Export inventory"
      visible={showExportProductCsv}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div data-testid="export-product-csv-name" className="col-12 p-0">
          The '
          { product?.name }
          ' export CSV file is ready for download
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-export-product"
            label={t('actions.cancel', { ns: 'common' })}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleExportProductCsv}
            data-testid="button-export-product"
            data-cy="button-export-product"
            label="Export inventory"
            iconPos="left"
            loading={exportingProduct}
            className="p-button-primary justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
