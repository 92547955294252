import { gql } from '@apollo/client'

export default gql`
  fragment accountFragment on Account {
    id
    name
    v2ApiCredentials {
        key
        secret
    }
    hasValidSubscription
    mayTrialBeStarted
    subscriptionId
    subscriptionStatus
    usage {
        type
        count
        limit
    }
    invitedUsers {
        name
        email
        roles {
            id
            name
            label
            account {
                id
            }
            users {
                id
            }
            apiClients {
                id
            }
        }
    }
    trialStart
    trialEnd
    isTrialing
    createdAt
    spaces {
        id
        name
        slug
        permissions {
            id
            who {
                id
                name
            }
            what {
                id
            }
            how {
                id
            }
        }
        processes {
            id
            name
        }
        lifecycles {
            id
            name
        }
    }
  }
`
