export const
  SET_REFERENCE_PRODUCTS_RESULT = 'SET_REFERENCE_PRODUCTS_RESULT',
  SET_REFERENCE_DATABASES = 'SET_REFERENCE_DATABASES',
  SET_REFERENCE_PAGE_SIZE = 'SET_REFERENCE_PAGE_SIZE'

export const setReferenceProductResultsAction = results => ({
  type: SET_REFERENCE_PRODUCTS_RESULT,
  results
})

export const setReferenceDatabasesAction = databases => ({
  type: SET_REFERENCE_DATABASES,
  databases
})

export const setReferencePageSizeAction = referencePageSize => ({
  type: SET_REFERENCE_PAGE_SIZE,
  referencePageSize
})
