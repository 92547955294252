import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { TieredMenu } from 'primereact/tieredmenu'
import styled from 'styled-components'

import {
  FiList, FiSettings, FiTrash2, FiPlus
} from 'react-icons/fi'

import { useTranslation } from 'react-i18next'
import { NavigationItemAttr, NavigationItemProps } from '../interface/navigation-items-props'
import { NavigationItemLayout } from '../layout/navigation-item.layout'
import { WorkspaceMenuItemComponent } from './workspace-menu-item'
import { ISpace, ISpaceAccess } from '../../model'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { SubmenuItem } from './submenu-item'
import { WorkspaceNavigationProps } from '../interface/workspace-navigation'
import { Policy } from '../../shared/enum'
import { castToJoinedAlphaNumericString, isValid } from '../../shared/util/tools'

const WorkspaceNavigation = styled.div`
  .main-menu-item-wrapper + .p-submenu-list {
    top: 4rem !important;
    left: 0.6rem;
  }

  li.p-menuitem-active:first-child {
    .main-menu-item {
      background: var(--surface-100)
    }
  }

  .p-submenu-list {
    border-radius: 0.25rem;
    box-shadow: 0px 0.3rem 0.6rem rgba(0, 0, 0, 0.15);
    z-index: 2;
    .p-menuitem {
      padding: 0 0.5rem;
    }
  }

  .p-menu-separator {
    border-top: 1px solid var(--gray-100);
    background: var(--gray-100);
    height: 1px;
  }
`

export const WorkspaceNavigationComponent = ({
  handleSwitchWorkspace,
  openAddWorkspaceDialog,
  openRemoveWorkspaceDialog,
  handleWorkspaceSetting
}: WorkspaceNavigationProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])

  const selectedAccount = useReactiveVar(selectedAccountVar) || {}
  const selectedWorkspace: ISpaceAccess | null = useReactiveVar(selectedWorkspaceVar)
  const {
    account = null,
    hasAccess: hasAccountAccess = []
  } = selectedAccount
  const {
    spaces = [],
    isTrialing = false,
    hasValidSubscription = false
  } = account || {}
  const {
    space: {
      id: spaceId = null,
      name: spaceName = null
    } = {},
    hasAccess: hasSpaceAccess = [],
    emptyWorkspace = true
  } = selectedWorkspace || {}

  const spaceMenuItems = spaces.map(({ slug, id, name }: ISpace) => ({
    id, label: name!, slug, dataCyTag: 'workspace-named'
  }))

  const hasSpaces = () => spaces.length > 0
  const hasAccountActiveSubscription = () => (isTrialing || hasValidSubscription)

  const mainItemTemplate = (item: any, options: any) => {
    const {
      label, icon, data, showBorder, selected, disabled, items = [], dataCy, iconClassName
    } = item || {}
    const navItemProps: NavigationItemProps = {
      itemAttr: {
        label, icon, data, options, showBorder, selected, disabled, hasItems: (items.length > 0), dataCy, iconClassName
      },
      itemComponent: WorkspaceMenuItemComponent
    }
    return <NavigationItemLayout {...navItemProps} />
  }

  const subMenuItemTemplate = (item: any, options: any) => {
    const {
      label, icon, header, items = [], data = null, disabled, dataCy
    } = item || {}

    const itemAttr: NavigationItemAttr = {
      label,
      icon,
      header,
      options,
      data,
      disabled,
      dataCy,
      hasItems: items.length > 0,
      scrollable: !!data,
      selected: data && spaceId ? spaceId : null,
      callback: handleSwitchWorkspace
    }
    const navItemProps: NavigationItemProps = { itemAttr, mainMenuItem: false, itemComponent: SubmenuItem }
    return <NavigationItemLayout {...navItemProps} />
  }

  const canWriteAccount = () => hasAccountAccess.some((policy: any) => (policy === Policy.AccountManagement || policy === Policy.AccountWrite))

  const canManageSpace = () => hasSpaceAccess.some((policy: any) => (policy === Policy.SpaceManagement))

  const subMenuItems = [
    ...(hasSpaces() ? ([
      {
        data: spaceMenuItems,
        template: subMenuItemTemplate
      },
      { separator: hasSpaces() }
    ]) : []),
    {
      label: t('labels.settings', { context: 'workspace', ns: 'common' }),
      icon: <FiSettings size={20} />,
      disabled: !hasSpaces(),
      template: subMenuItemTemplate,
      dataCy: 'workspace-settings-button',
      command: () => handleWorkspaceSetting()
    },
    {
      label: t('actions.add', { context: 'workspace' }),
      icon: <FiPlus size={20} />,
      disabled: !canWriteAccount(),
      template: subMenuItemTemplate,
      command: () => openAddWorkspaceDialog(),
      dataCy: 'create-workspace-button'
    },
    {
      label: t('actions.delete', { context: 'workspace' }),
      icon: <FiTrash2 size={20} />,
      disabled: !hasSpaces() || !canManageSpace(),
      template: subMenuItemTemplate,
      command: () => openRemoveWorkspaceDialog(),
      dataCy: 'workspace-delete-button'
    }
  ]

  const dataCy = `sidebar-menu-workspace-${castToJoinedAlphaNumericString(spaceName).toLowerCase()}`
  const menuitems = [
    {
      label: hasSpaces() ? spaceName! : t('labels.emptyWorkspace'),
      icon: <FiList size={22} />,
      disabled: !hasAccountActiveSubscription(),
      dataCy,
      iconClassName: 'nav-icon-workspace',
      items: subMenuItems,
      template: mainItemTemplate,
      showBorder: true,
    }
  ]

  return (
    <>
      { !isValid(spaceId) && !emptyWorkspace && (
        <div
          data-testid="workspace-navigation-loading"
          data-cy="loading-something"
          className="w-full px-8 py-4"
        >
          <i className="pi pi-spin pi-spinner text-bluegray-50"></i>
        </div>
      )}
      { (isValid(spaceId) || emptyWorkspace) && (
        <WorkspaceNavigation className="card">
          <TieredMenu model={menuitems} className="surface-0 border-none w-full" />
        </WorkspaceNavigation>
      )}
    </>
  )
}
