import React, { useState, useCallback } from 'react'

export const useRefOverflow = (callback?: Function): [React.Ref<HTMLDivElement> | undefined, boolean] => {
  const [ hasOverflow, setHasOverflow ] = useState(false)

  const ref = useCallback((node: any) => {

    const trigger = () => {
      setTimeout(() => {
        const { scrollWidth, clientWidth } = node
        setHasOverflow(scrollWidth > clientWidth)

        if (callback) callback(hasOverflow)
      })
    }

    if (node) {
      node.addEventListener('mouseenter', trigger)
    }
  }, [])

  return [ ref, hasOverflow ]
}