import { connect } from 'react-redux'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import ModalWindow from './modalWindow'

const mapStateToProps = state => ({
  isDataViaNetworkRequesting: FlagsSelector.isDataViaNetworkRequesting(state),
})

export default connect(
  mapStateToProps,
  null
)(ModalWindow)
