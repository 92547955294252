import { gql } from '../../../__generated__'

export const CREATE_PRODUCT = gql(`
  mutation createProduct($name: String!, $spaceID: ID!, $folderID: ID, $labels: [ID!]!, $unit: String) {
    createProduct(name: $name, spaceID: $spaceID, labels: $labels, folderID: $folderID, unit: $unit) {
      ...extendedProductFragment
    }
  }
`)

export const REPLACE_LABEL = gql(`
  mutation replaceLabel($productID: ID!, $labelToReplace: ID!, $replacementLabel: ID!) {
    replaceLabel(productID: $productID, labelToReplace: $labelToReplace, replacementLabel: $replacementLabel) {
      id
      name
    }
  }
`)

export const CHANGE_PRODUCT_PRIVATE_STATE = gql(`
  mutation changeProductFavoriteState($productID: ID!, $favorite: Boolean!) {
    changeProductFavoriteState(productID: $productID, favorite: $favorite){
      ...extendedProductFragment
    }
  }
`)

export const COPY_PRODUCT = gql(`
  mutation copyProduct($productID: ID!) {
    copyProduct(productID: $productID) {
      ...extendedProductFragment
    }
  }
`)

export const REMOVE_PRODUCT = gql(`
  mutation removeProduct($productID: ID!) {
    removeProduct(productID: $productID)
  }
`)

export const REQUEST_PRODUCT_EXPORT = gql(`
  mutation requestProductExport($productID: ID!, $timezone: String!) {
    requestProductExport(productID: $productID, timezone: $timezone)
  }
`)

export const IMPORT_PRODUCT_TEMPLATE = gql(`
  mutation importProductTemplate($spaceID: ID!, $productTemplate: ProductTemplate!) {
    importProductTemplate(spaceID: $spaceID, productTemplate: $productTemplate) {
      id
      name
    }
  }
`)

export const ADD_PRODUCT_PROPERTY = gql(`
  mutation addProductProperty($productID: ID!, $referencePropertyID: ID!, $conversionFactor: String!, $referenceUnitID: ID!) {
    addProductProperty(productID: $productID, referencePropertyID: $referencePropertyID, conversionFactor: $conversionFactor, referenceUnitID: $referenceUnitID){
      id
      name
    }
  }
`)

export const CHANGE_PRODUCT_PROPERTY = gql(`
  mutation changeProductProperty($productID: ID!, $referencePropertyID: ID!, $conversionFactor: String!, $referenceUnitID: ID!) {
    changeProductProperty(productID: $productID, referencePropertyID: $referencePropertyID, conversionFactor: $conversionFactor, referenceUnitID: $referenceUnitID){
      id
      name
    }
  }
`)
