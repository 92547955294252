import { IReferenceProduct } from '../../model'
import { isValid } from '../../shared/util/tools'
import { IDatabaseSearchResult, IElementaryFlowResult } from '../interface/impact-dataset-result'

export const transformToDatabaseSearchResult = (referenceProducts: IReferenceProduct [] = []): IDatabaseSearchResult[] => referenceProducts
  .filter(referenceProducts => isValid(referenceProducts.id))
  .map(referenceProduct => ({
    id: referenceProduct.id,
    datasetName: referenceProduct.name,
    geography: referenceProduct.location?.name,
    referenceProduct: referenceProduct.name,
    property: referenceProduct?.referenceProperty?.name,
    unit: referenceProduct?.referenceUnit?.name,
    database: referenceProduct.databaseName,
    description: referenceProduct.description
  } as IDatabaseSearchResult))

export const transformToElementaryFlowResult = (referenceProducts: IReferenceProduct [] = []): IElementaryFlowResult[] => referenceProducts
  .filter(referenceProducts => isValid(referenceProducts.id))
  .map(referenceProduct => ({
    id: referenceProduct.id,
    datasetName: referenceProduct.name,
    subCompartment: referenceProduct.category?.name,
    compartment: referenceProduct.parentCategory?.name,
    property: referenceProduct?.referenceProperty?.name,
    unit: referenceProduct?.referenceUnit?.name,
    description: referenceProduct.description
  } as IElementaryFlowResult))
