import React from 'react'
import { Button, Tooltip, Drawer } from 'antd'
import { FiX } from 'react-icons/fi'
import { withTranslation } from 'react-i18next'
import compose from '../../../utils/compose'
import WorkspaceDetails from './workspace-details/workspaceDetails.container'
import { IDetailsPanelProps } from './detailsPanel.interface'

const DetailsPanel:React.FC<IDetailsPanelProps> = ({
  isDetailsPanelOpen,
  isForcingWorkspacePanel,
  clearUiWhenDetailPanelCloseAction,
  children,
  t
}) => {

  return <div onClick={ e => e.stopPropagation() }>
    <Drawer
      placement="right"
      onClose={clearUiWhenDetailPanelCloseAction}
      visible={isDetailsPanelOpen}
      width={342}
      zIndex={990}
      mask={false}
      bodyStyle={{ padding: 0, margin: 0 }}
      closeIcon={false}
      data-cy="details-panel-container"
      className="details-panel"
      headerStyle={{ display: 'none' }}
    >
      <Tooltip overlayClassName="cy-press-esc-tooltip" title={t('model.pressEsc')} placement="left">
        <Button
          data-cy="close-details-panel"
          size="large"
          className="details-panel__close-button"
          icon={<FiX size={20} />}
          onClick={ clearUiWhenDetailPanelCloseAction }
        />
      </Tooltip>
      {isForcingWorkspacePanel ?
        <WorkspaceDetails />
        :
        children
      }
    </Drawer>
  </div>
}

export default compose(
  withTranslation(),
)(DetailsPanel)
