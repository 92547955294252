import React from 'react'
import styled from 'styled-components'

interface CardItemWrapperProps {
  readonly boxShadow?: string
}
const CardItemWrapper = styled.div<CardItemWrapperProps>`
  &.card-item-wrapper {
    box-shadow: ${(props: CardItemWrapperProps) => (props?.boxShadow ? props?.boxShadow : 'none')}
  }
  .custom-card-body {
    height: calc(100% - 2rem);
  }
`

export type SimpleCardTemplateComponentProps = {
  children?: React.ReactNode
  header?: React.ReactNode
  className?: string,
  headerClassName?: string,
  bodyClassName?: string,
  boxShadow?: string
}

export const SimpleCardTemplateComponent = ({
  children,
  header,
  className,
  headerClassName,
  bodyClassName,
  boxShadow
}: SimpleCardTemplateComponentProps) => (
  <CardItemWrapper data-testid="card-item-wrapper" boxShadow={boxShadow} className={`flex flex-column w-full h-full card-item-wrapper ${className}`}>
    <div data-testid="card-item-header" className={`w-full h-2rem custom-card-header ${headerClassName}`}>
      { header }
    </div>
    <div data-testid="card-item-body" className={`w-full custom-card-body ${bodyClassName}`}>
      { children }
    </div>
  </CardItemWrapper>
)
