import React, { useEffect } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Sidebar } from 'primereact/sidebar'

import { Trans, useTranslation } from 'react-i18next'
import { LogoIcon } from '../../../component/shared/customIcons'
import { DefaultWorkspaces, Navigation } from '../../shared/enum'

import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'

const EmptyAccountOptionsWrapper = styled.div`
  background-color: var(--primarydark-50);
  background-image: url("/assets/images/top-left-vector.png");

  svg {
    fill: var(--green-500);
  }

  .header-logo svg {
    height: 1.5rem;
  }

  .footer-logo svg {
    height: 1rem;
  }
`

const RightVectorBackground = styled.div`
  background-image: url("/assets/images/mid-right-vector.png");
  background-position: right center;
`

const GlobalStyle = createGlobalStyle`
  div.appcues,
  appcues-container,
  appcues-checklist {
    z-index: 1100 !important;
  }
`

type TAccountCreated = {
  id: string,
  name: string
}

export type TEmptyAccountSidebarProps = {
  showEmptyAccountLoader?: boolean,
  setShowEmptyAccountLoader?: Function,
  accountCreating?: boolean,
  accountCreated?: TAccountCreated | null,
}
export const EmptyAccountSidebarComponent = ({
  showEmptyAccountLoader = false,
  setShowEmptyAccountLoader = () => {},
  accountCreating = true,
  accountCreated
}: TEmptyAccountSidebarProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const navigate = useNavigate()

  const [ trackEventInSegment ] = getSegmentTrack()
  useEffect(() => {
    if (!accountCreating && accountCreated) {
      openEmptyWorkspace()
    }
  }, [ accountCreated, accountCreating ])

  const openEmptyWorkspace = () => {
    setShowEmptyAccountLoader(false)
    trackEventInSegment(TrackEventType.OPENED_PERSONAL_WORKSPACE)
    accountCreated?.id && navigate(generatePath(Navigation.WorkspaceDetail, {
      accountId: accountCreated.id,
      workspaceSlug: DefaultWorkspaces.YourWorkspace
    }))
  }

  return (
    <>
      { showEmptyAccountLoader && <GlobalStyle /> }
      <Sidebar visible={showEmptyAccountLoader} fullScreen onHide={() => {}}>
        <EmptyAccountOptionsWrapper data-testid="empty-account-sidebar" className="relative w-full h-full bg-no-repeat">
          <RightVectorBackground className="absolute right-0 w-6 h-full bg-no-repeat" />
          <div className="absolute flex flex-column w-full h-full">
            <div className="flex flex-column flex-grow-1 align-items-center justify-content-center">
              <div className="flex flex-column">
                <div className="flex justify-content-center font-semibold text-center text-3xl pb-3 line-height-2 gap-1">
                  <div className="flex-none header-logo"><LogoIcon /></div>
                  <div className="flex-none">{ t('labels.logoText', { context: 'mobius', ns: 'common' }) }</div>
                </div>
                { accountCreating && (
                  <>
                    <div className="flex justify-content-center text-center ">
                      <i className="pi pi-spin pi-spinner text-primary text-xl"></i>
                    </div>
                    <div className="flex justify-content-center font-semibold text-center text-2xl py-3 line-height-2">
                      <Trans t={t} i18nKey="labels.emptyAccountScreen_loadingTitle" components={{ 1: <br /> }} />
                    </div>
                    <div className="flex justify-content-center text-center text-base text-gray-500 font-medium line-height-2">
                      { t('labels.emptyAccountScreen', { context: 'loadingSubtitle' }) }
                    </div>
                  </>
                ) }
              </div>
            </div>
            <div className="flex w-full h-3rem px-5">
              <div className="flex w-full text-base font-medium px-2">
                <div className="flex-none footer-logo mr-2"><LogoIcon /></div>
                <div className="flex-none">{ t('labels.logoText', { context: 'ecochain', ns: 'common' }) }</div>
              </div>
              <div className="flex w-full text-base justify-content-end font-medium px-2">{ t('labels.emptyAccountScreen', { context: 'footerText' }) }</div>
            </div>
          </div>
        </EmptyAccountOptionsWrapper>
      </Sidebar>
    </>
  )
}
