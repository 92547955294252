import { gql } from '@apollo/client'

export default gql`
  query accountApiClients($id: ID!){
    accountApiClients(id: $id){
      id
      name
      clientID
      clientSecret
      roles {
        id
      }
    }
  }
`
