import React from 'react'

import { requiresScientificNotation, roundNumber, scientificNotation } from '../../../../utils/tools'
import { NUMBER_FORMAT } from '../../../../utils/const'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'antd'

class ScientificNotation extends React.Component {
  render() {
    const { dataCy, value, unit, precision = NUMBER_FORMAT.PRECISION, className, noTooltip, onValueClick = () => {} } = this.props
    let returnVal

    if (!value || isNaN(value)) return '-'
    if (requiresScientificNotation(value) === false) {
      const val = value ? roundNumber(value, precision) : 0
      returnVal = <span data-cy={dataCy} className={ className } onClick={ e => onValueClick(e) }>{ val } { unit }</span>
    } else {
      const sn = scientificNotation(value, 2)
      returnVal = <span data-cy={dataCy} className={ className } onClick={ e => onValueClick(e) }>{ sn.coefficient } &middot; 10 <sup>{ sn.exponent }</sup> { unit }</span>
    }
    return noTooltip ? returnVal : <Tooltip placement="top" title={ value } mouseEnterDelay={ 1 }>{ returnVal } </Tooltip>
  }
}

export { ScientificNotation }
export default withTranslation()(ScientificNotation)
