import React, { useContext, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { useTranslation } from 'react-i18next'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'

import { IProduct } from '../../model'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableHeaderComponent } from '../../shared/component/overview/table-header-template.component'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { Navigation, ProductLabel, ProductOverviewTableColumn } from '../../shared/enum'

import { TableBodyTemplateComponent } from '../component/view-template/table-body-template.component'
import { TProductContext } from '../interface/product-context.type'
import ProductContext from '../provider/context/product.context'
import { setOverviewSetting } from '../../shared/util/overview'
import { EntityType } from '../../../utils/const'
import { SortingOrder, ProductSortField } from '../../../__generated__/graphql'

type TableListLayoutProps = {
  products: IProduct[],
  areFavorites?: boolean
}

export const TableListLayout = ({
  products = [],
  areFavorites = false
}: TableListLayoutProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    label, selectedSortBy, selectedSortOrder, updateProduct
  } = useContext<TProductContext>(ProductContext)
  const [ trackEventInSegment ] = getSegmentTrack()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const navigate = useNavigate()
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)

  const handleSortTable = (sortBy: ProductSortField, sortOrderTo: SortingOrder) => {
    const sortUpdate = { selectedSortBy: sortBy, selectedSortOrder: sortOrderTo }
    updateProduct(sortUpdate)
    setOverviewSetting(sortUpdate)
  }

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const navigateToDetail = (event?: any) => {
    const { data: { id, __typename } } = event

    if (__typename !== EntityType.FOLDER) {
      trackEventInSegment(TrackEventType.VIEWED_PRODUCT)
      account?.id && space?.slug && navigate(generatePath(Navigation.ProductObjectInventory, {
        accountId: account.id,
        workspaceSlug: space.slug,
        productId: id
      }))
    } else {
      trackEventInSegment(TrackEventType.VIEWED_FOLDER)
      const navigation = label === ProductLabel.Product
        ? Navigation.ProductOverviewFolder : Navigation.ObjectOverviewFolder
      account?.id && space?.slug && navigate(generatePath(navigation, {
        accountId: account.id,
        workspaceSlug: space.slug,
        folderId: id
      }))
      updateProduct({ currentPage: 1 })
    }
  }

  return (
    <TableWrapperComponent>
      <DataTable
        value={products}
        emptyMessage=""
        onRowClick={navigateToDetail}
      >
        <Column
          field="name"
          className="border-round-left-lg"
          header={(
            <TableHeaderComponent<ProductSortField>
              label={t('labels.tableHeader', { context: 'name' })}
              sortBy={ProductSortField.Name}
              sortDisabled={areFavorites}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: IProduct) => (
            <TableBodyTemplateComponent
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              column={ProductOverviewTableColumn.Name}
              value={{
                name: item.name, id: item.id, favorite: item.favorite, isMouseOver: item.id === mouseOverOn, __typename: item.__typename
              }}
            />
          )}
        />
        <Column
          field="updatedAt"
          header={(
            <TableHeaderComponent
              label={t('labels.tableHeader', { context: 'updated' })}
              sortBy={ProductSortField.Updated}
              sortDisabled={areFavorites}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: IProduct) => (
            <TableBodyTemplateComponent
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              column={ProductOverviewTableColumn.LastChanged}
              value={{ id: item.id, updatedAt: item.updatedAt }}
            />
          )}
          style={{ width: '140px' }}
        />
        <Column
          field="createdAt"
          header={(
            <TableHeaderComponent
              label={t('labels.tableHeader', { context: 'created' })}
              sortBy={ProductSortField.Created}
              sortDisabled={areFavorites}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: IProduct) => (
            <TableBodyTemplateComponent
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              column={ProductOverviewTableColumn.Created}
              value={{ id: item.id, createdAt: item.createdAt }}
            />
          )}
          style={{ width: '140px' }}
        />
        <Column
          field="description"
          className="border-round-right-lg"
          header={<TableHeaderComponent label={t('labels.tableHeader', { context: 'tagsDescription' })} />}
          body={(item: IProduct) => (
            <TableBodyTemplateComponent
              onRowMouseEnter={onRowMouseEnter}
              onRowMouseLeave={onRowMouseLeave}
              column={ProductOverviewTableColumn.TagsDescription}
              value={{ id: item.id, tags: item.tags, description: item.description }}
            />
          )}
        />
      </DataTable>
    </TableWrapperComponent>
  )
}
