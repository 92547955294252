import { gql } from '@apollo/client'
import template from '../fragment/template'

export default gql`
  query activityTemplates($spaceID: ID!) {
    activityTemplates(spaceID: $spaceID){
      ...templateFragment
    }
  }
  ${template}
`
