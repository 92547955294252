import React, { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { ThemeProvider } from 'styled-components'
import { useParams } from 'react-router-dom'
import { Frame } from '../../component/layout'
import { ProductDetailProvider } from './provider/product-detail.provider'
import { ProductDetailContainer } from './container/product-detail.container'

import { styledComponentTheme } from '../shared/constants/theme'
// import NoMatchPage from '../../component/pages/errors/noMatchPage'
import { store } from '../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { ProductProvider } from './provider/product.provider'
import { useUrlQuery } from '../shared/hook/use-url-query'
import { isValid } from '../shared/util/tools'
import InventoryPage from '../../component/pages/inventory-page/inventoryPage.container'
import { useIsMounted } from '../shared/hook/use-is-mounted'
import { ProductDetailComponent } from './component/product-detail.component'
import { selectedWorkspaceVar } from '../../graphql/cache'

export const ProductDetailEntry = () => {
  const { productId = '' } = useParams()
  const urlQuery = useUrlQuery()
  const isMounted = useIsMounted()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const productScenarioId = urlQuery.get('productScenarioId')
  const compareEntityId = urlQuery.get('compareEntityId')
  useEffect(() => {
    if (isMounted()) {
      // TODO : Added for compatibility with v0.9
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }
  }, [ isMounted ])
  const loadOldInventory = () => isValid(productScenarioId) || isValid(compareEntityId)

  return (
    <>
      { loadOldInventory() ? <InventoryPage />
        : (
          <ThemeProvider theme={styledComponentTheme}>
            <ProductProvider>
              <ProductDetailProvider>
                <Frame>
                  { () => (
                    <>
                      { space && (
                        <ProductDetailContainer productId={productId}>
                          <ProductDetailComponent />
                        </ProductDetailContainer>
                      ) }
                    </>
                  ) }
                </Frame>
              </ProductDetailProvider>
            </ProductProvider>
          </ThemeProvider>
        )}
    </>
  )
}
