import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'

import { TableFooterComponent } from './table-footer.component'
import { TableHeaderComponent } from './table-header.component'
import { TableColumnHeaderComponent } from './table-column-header.component'
import { TableBodyTemplateComponent } from './table-body.component'

import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { EComparisonLifecycleTableColumn } from '../../../enum/table-column'
import { ComparisonEntity } from '../../../enum/entity'
import { COMPARISON_LIFECYCLE_TABLE_SORT_MAP } from '../../../constant/table'

import { TTransformMultiLeafItem } from '../../../../shared/util/transform'
import { TableWrapperInventoryComponent } from '../../../../shared/component/general/table-wrapper-inventory.component'
import { SortOrder } from '../../../../shared/enum'

import { store } from '../../../../../configureStore'
import FlagsSelector from '../../../../../redux/selectors/flags.selector'
import { SelectedProductSelector } from '../../../../../redux/selectors/product.selector'
import { clearUiWhenDetailPanelCloseAction } from '../../../../../redux/actions/clear.actions'
import { showProductInDetailsPanelSaga } from '../../../../../redux/sagas/product.saga'
import { AnalysisMode } from '../../../enum/analysis'
import { setIsDetailPanelReadonlyV1Action, setIsDetailsPanelOpenAction } from '../../../../../redux/actions/flags.actions'
import { useIsMounted } from '../../../../shared/hook/use-is-mounted'
import { TLifecycleDetailContext } from '../../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../../lifecycle/provider/context/lifecycle-detail.context'
import { isValid } from '../../../../shared/util/tools'

const TableAnalysisWrapper = styled.div`
.p-datatable {
  td.footer-impact-change,
  th.header-impact-change {
    width: 2rem;
    padding: 0.25rem 0.5rem;
  }
}
`

export const TableComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    analysisMode,
    updateAnalysis,
    primaryTotalImpact: { unit: totalImpactUnit = '' } = {},
    secondaryTotalImpact: { unit: secondaryTotalImpactUnit = '' } = {},
    hiddenImpactBar,
    multiLifecycleLeafItems = []
  } = useContext<TAnalysisContext>(AnalysisContext)
  const isMounted = useIsMounted()
  const componentIsMounted = isMounted()

  useEffect(() => {
    if (componentIsMounted) {
      // TODO : Added for compatibility with v0.9
      updateAnalysis({ scenarioSelectedKey: null })
    }
  }, [ componentIsMounted ])
  const [ selectedSortBy, setSelectedSortBy ] = useState<EComparisonLifecycleTableColumn>(EComparisonLifecycleTableColumn.PrimaryImpact)
  const [ selectedSortOrder, setSelectedSortOrder ] = useState<SortOrder>(SortOrder.Desc)
  const [ comparisonTableList, setComparisonTableViewList ] = useState<TTransformMultiLeafItem[]>([])
  const { updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const [ selectedItemId, setSelectedItemId ] = useState<string | null>(null)
  const [ selectedPhaseName, setSelectedPhaseName ] = useState<string | null | undefined>(null)

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  const selectedReduxProductId = SelectedProductSelector.productId(state)
  useEffect(() => {
    if (isDetailsPanelOpen) {
      setSelectedItemId(selectedReduxProductId)
    } else {
      setSelectedItemId(null)
    }
  }, [ isDetailsPanelOpen ])

  useEffect(() => {
    if (selectedSortBy && selectedSortOrder && multiLifecycleLeafItems.length > 0) {
      const sortedLeafList = getSortedList()
      setComparisonTableViewList([ ...sortedLeafList ])
    }
  }, [
    selectedSortBy,
    selectedSortOrder,
    multiLifecycleLeafItems,
    totalImpactUnit,
    secondaryTotalImpactUnit
  ])

  const hdrColSpanWithImpactChange = !hiddenImpactBar ? 5 : 4
  const hdrColSpanWithoutImpactChange = !hiddenImpactBar ? 4 : 3

  const getSortedList = () => {
    const { entity, sortBy } = COMPARISON_LIFECYCLE_TABLE_SORT_MAP[selectedSortBy] || {}
    const flatList = multiLifecycleLeafItems.flatMap(({ phaseName, order, transformedMultiLeafItem = [] }) => transformedMultiLeafItem.map(item => ({ phaseName, order, ...item })));

    const sortedList = flatList.sort((a: any, b: any) => {
      let aFieldToSort = a
      let bFieldToSort = b
      if (entity) {
        aFieldToSort = entity === ComparisonEntity.Primary ? a.primaryLeafItem : a.secondaryLeafItem
        bFieldToSort = entity === ComparisonEntity.Primary ? b.primaryLeafItem : b.secondaryLeafItem
      }

      let sortByA = aFieldToSort[sortBy]
      let sortByB = bFieldToSort[sortBy]
      if (selectedSortBy === EComparisonLifecycleTableColumn.PrimaryImpact
        || selectedSortBy === EComparisonLifecycleTableColumn.SecondaryImpact) {
        sortByA = parseFloat(sortByA)
        sortByB = parseFloat(sortByB)
      }

      if (!isValid(aFieldToSort[sortBy]) || sortByA < sortByB) return -1
      return (!isValid(bFieldToSort[sortBy]) || sortByA > sortByB) ? 1 : 0
    })

    if (selectedSortOrder === SortOrder.Desc) {
      return sortedList.reverse()
    }

    return sortedList
  }

  const handleSortTable = (sortBy: EComparisonLifecycleTableColumn, sortOrderTo: SortOrder) => {
    setSelectedSortBy(sortBy)
    setSelectedSortOrder(sortOrderTo)
  }

  const onRowClick = (id: string, phaseName?: string) => {
    if (id === selectedItemId && phaseName === selectedPhaseName) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    setSelectedItemId(id)
    setSelectedPhaseName(phaseName)

    updateLifecycleDetail({ showLifecycleDetailPanelOnly: false })
    store.dispatch(setIsDetailsPanelOpenAction(true))
    const scenarioMode = analysisMode === AnalysisMode.Scenario
    store.dispatch(setIsDetailPanelReadonlyV1Action(!scenarioMode))
    store.dispatch(showProductInDetailsPanelSaga(id))
  }

  const getSelectedRow = () => multiLifecycleLeafItems.find((item: TTransformMultiLeafItem) => item.id === selectedItemId)

  const tableColumnTemplate = (label: string, sortBy?: EComparisonLifecycleTableColumn, alignRight: boolean = true, sortDisabled = false) => (
    <TableColumnHeaderComponent
      {...{
        label, sortBy, alignRight, sortDisabled
      }}
      selectedSortBy={selectedSortBy}
      selectedSortOrder={selectedSortOrder}
      onSortClick={handleSortTable}
    />
  )
  const bodyTemplate = (rowData: TTransformMultiLeafItem, column: EComparisonLifecycleTableColumn, rowDetail: any) => <TableBodyTemplateComponent value={rowData} onRowClick={onRowClick} column={column} rowIndex={rowDetail.rowIndex} />

  const tableHeaderTemplate = (entity: ComparisonEntity) => <TableHeaderComponent entity={entity} />
  const tableFooterTemplate = (column: EComparisonLifecycleTableColumn) => <TableFooterComponent column={column} />

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="" colSpan={3} />
        <Column header={() => tableHeaderTemplate(ComparisonEntity.Primary)} colSpan={hdrColSpanWithoutImpactChange} />
        <Column header={() => tableHeaderTemplate(ComparisonEntity.Secondary)} colSpan={hdrColSpanWithImpactChange} />
      </Row>
      <Row>
        <Column
          field={EComparisonLifecycleTableColumn.Id}
          header={tableColumnTemplate('', EComparisonLifecycleTableColumn.Id, false, true)}
        />

        <Column
          field={EComparisonLifecycleTableColumn.PhaseName}
          header={tableColumnTemplate(t('labels.phase', { ns: 'common' }), EComparisonLifecycleTableColumn.PhaseName, false)}
        />

        <Column
          field={EComparisonLifecycleTableColumn.Item}
          header={tableColumnTemplate(t('labels.tableHeader', { context: 'nameOfItems', ns: 'common' }), EComparisonLifecycleTableColumn.Item, false)}
        />

        <Column field={EComparisonLifecycleTableColumn.PrimaryAmount} header={tableColumnTemplate(t('labels.amount', { ns: 'common' }), EComparisonLifecycleTableColumn.PrimaryAmount, false)} />

        <Column field={EComparisonLifecycleTableColumn.PrimaryUnit} header={tableColumnTemplate(t('labels.unit', { ns: 'common' }), EComparisonLifecycleTableColumn.PrimaryUnit, false)} />

        <Column field={EComparisonLifecycleTableColumn.PrimaryImpact} header={tableColumnTemplate(totalImpactUnit, EComparisonLifecycleTableColumn.PrimaryImpact)} />

        { !hiddenImpactBar && <Column field={EComparisonLifecycleTableColumn.PrimaryImpactBar} header={tableColumnTemplate('', EComparisonLifecycleTableColumn.PrimaryImpactBar, false, true)} /> }

        <Column field={EComparisonLifecycleTableColumn.SecondaryAmount} header={tableColumnTemplate(t('labels.amount', { ns: 'common' }), EComparisonLifecycleTableColumn.SecondaryAmount, false)} />

        <Column field={EComparisonLifecycleTableColumn.SecondaryUnit} header={tableColumnTemplate(t('labels.unit', { ns: 'common' }), EComparisonLifecycleTableColumn.SecondaryUnit, false)} />

        <Column field={EComparisonLifecycleTableColumn.SecondaryImpact} header={tableColumnTemplate(secondaryTotalImpactUnit, EComparisonLifecycleTableColumn.SecondaryImpact)} />

        <Column field={EComparisonLifecycleTableColumn.ImpactChangeEffect} className="header-impact-change" header={tableColumnTemplate('', EComparisonLifecycleTableColumn.ImpactChangeEffect, true)} />

        { !hiddenImpactBar && <Column field={EComparisonLifecycleTableColumn.SecondaryImpactBar} header={tableColumnTemplate('', EComparisonLifecycleTableColumn.SecondaryImpactBar, false, true)} /> }

      </Row>
    </ColumnGroup>
  )

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={tableFooterTemplate(EComparisonLifecycleTableColumn.Id)} colSpan={3} />

        <Column footer={tableFooterTemplate(EComparisonLifecycleTableColumn.PrimaryImpact)} colSpan={3} />

        { !hiddenImpactBar && <Column footer={<></>} /> }

        <Column footer={tableFooterTemplate(EComparisonLifecycleTableColumn.SecondaryImpact)} colSpan={3} />

        <Column footer={tableFooterTemplate(EComparisonLifecycleTableColumn.ImpactChangeEffect)} className="footer-impact-change" />

        { !hiddenImpactBar && <Column footer={<></>} /> }
      </Row>
    </ColumnGroup>
  )

  return (
    <TableAnalysisWrapper className="flex w-full">
      <TableWrapperInventoryComponent>
        <DataTable
          value={comparisonTableList}
          responsiveLayout="scroll"
          selectionMode="single"
          headerColumnGroup={headerGroup}
          footerColumnGroup={footerGroup}
          selection={getSelectedRow()}
        >
          <Column
            field={EComparisonLifecycleTableColumn.Id}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.Id, rowDetail)}
            header={tableColumnTemplate('', EComparisonLifecycleTableColumn.Id, false, true)}
            style={{ width: '2rem' }}
          />
          <Column
            field={EComparisonLifecycleTableColumn.PhaseName}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.PhaseName, rowDetail)}
            header={tableColumnTemplate(t('labels.phase', { ns: 'common' }), EComparisonLifecycleTableColumn.PhaseName, false, true)}
            style={{ width: '8.75rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.Item}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.Item, rowDetail)}
            header={tableColumnTemplate(t('labels.tableHeader', { context: 'nameOfItems', ns: 'common' }), EComparisonLifecycleTableColumn.Item, false)}
          />

          <Column
            field={EComparisonLifecycleTableColumn.PrimaryAmount}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.PrimaryAmount, rowDetail)}
            style={{ width: '6.75rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.PrimaryUnit}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.PrimaryUnit, rowDetail)}
            style={{ width: '5.25rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.PrimaryImpact}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.PrimaryImpact, rowDetail)}
            style={{ width: '8.75rem' }}
          />

          { !hiddenImpactBar && (
            <Column
              field={EComparisonLifecycleTableColumn.PrimaryImpactBar}
              body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.PrimaryImpactBar, rowDetail)}
              style={{ width: '9.5rem', minWidth: '9.5rem' }}
            />
          )}

          <Column
            field={EComparisonLifecycleTableColumn.SecondaryAmount}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.SecondaryAmount, rowDetail)}
            style={{ width: '6.75rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.SecondaryUnit}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.SecondaryUnit, rowDetail)}
            style={{ width: '5.25rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.SecondaryImpact}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.SecondaryImpact, rowDetail)}
            style={{ width: '8.75rem' }}
          />

          <Column
            field={EComparisonLifecycleTableColumn.ImpactChangeEffect}
            body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.ImpactChangeEffect, rowDetail)}
            style={{ width: '1.75rem' }}
          />

          { !hiddenImpactBar && (
            <Column
              field={EComparisonLifecycleTableColumn.SecondaryImpactBar}
              body={(rowData: TTransformMultiLeafItem, rowDetail: any) => bodyTemplate(rowData, EComparisonLifecycleTableColumn.SecondaryImpactBar, rowDetail)}
              style={{ width: '9.5rem', minWidth: '9.5rem' }}
            />
          ) }

        </DataTable>
      </TableWrapperInventoryComponent>
    </TableAnalysisWrapper>
  )
}
