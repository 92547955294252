import React, { useEffect, useState } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

import {
  // faCircleInfo,
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategoryFilterItem } from '../../interface/category-filter-item'
import { Category } from '../../../../__generated__/graphql'
import { isValid } from '../../../shared/util/tools'

const CategoryItem = styled.li`
  &:hover {
    .p-radiobutton-box {
      border-color: var(--primary-500) !important;
    }
  }
`

export type TCategoryComponentProps = {
  categoryFilterItem: CategoryFilterItem,
  handleCategorySelection: (param1: Category, param2?: string) => void
}
export const CategoryFilterComponent = ({
  categoryFilterItem,
  handleCategorySelection
}: TCategoryComponentProps) => {
  const [ showCategories, setShowCategories ] = useState<boolean>(true)
  const { parentCategory, categories, selectedCategory } = categoryFilterItem

  useEffect(() => {
    selectedCategory
      ? setShowCategories(false)
      : setShowCategories(true)
  }, [ selectedCategory ])

  const toggleCategories = () => {
    setShowCategories(!showCategories)
  }

  const onSelect = (category: Category) => {
    handleCategorySelection(category, parentCategory?.id)
    setShowCategories(false)
  }

  return (
    <div className="flex flex-column w-full py-3">
      {/*
      TODO : To be implemented with the filter section
      This is placeholder for the data
    */}
      <div className={classNames(
        'flex w-full align-items-center py-1 border-bottom-1 border-gray-100 gap-2',
        { 'text-gray-500': showCategories, 'text-primary-500': !showCategories }
      )}
      >
        {/* Uncomment this icon when we will have content for it */}
        {/* <div className="flex w-1rem align-items-center text-primary-500">
          <FontAwesomeIcon icon={faCircleInfo} className="text-sm" />
        </div> */}
        <div
          data-testid="parent-category-name"
          className={classNames(
            'flex flex-grow-1 align-items-center text-sm font-medium text-gray-500 line-height-2 cursor-pointer',
            {
              'text-gray-500': !isValid(selectedCategory),
              'text-primary-500': isValid(selectedCategory)
            }
          )}
          onClick={toggleCategories}
        >
          { parentCategory?.name ? parentCategory.name : 'Category' }
          { selectedCategory && <> (1) </>}
        </div>
        <div data-testid="toggle-show-categories" className="flex w-1rem align-items-center text-gray-500 cursor-pointer" onClick={toggleCategories}>
          <FontAwesomeIcon icon={showCategories ? faChevronDown : faChevronUp} className="text-md" />
        </div>
      </div>

      { showCategories && (
        <ul data-testid="category-filter-wrapper" className="w-full pt-2">
          { categories?.map((category, index) => (
            <CategoryItem
              data-testid={`category-item-${index}`}
              key={`category-item-${index}`}
              className="flex align-items-center gap-2 w-full py-1 cursor-pointer"
            >
              <RadioButton
                inputId={category.id}
                name="category"
                value={category}
                onChange={(event: any) => onSelect(event.value)}
                checked={category.id === selectedCategory?.id}
              />
              <label
                htmlFor={category.id}
                className="line-height-2 text-sm text-gray-800 cursor-pointer"
              >
                { category.name }
              </label>
            </CategoryItem>
          )) }
        </ul>
      ) }
    </div>
  )
}
