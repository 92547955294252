import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'

export type DeletePhaseComponentProps = {
  showDeletePhaseDialog: boolean,
  closeDeletePhaseDialog: () => void,
  handleDeletePhase: () => void
}
export const DeletePhaseComponent = ({
  showDeletePhaseDialog,
  closeDeletePhaseDialog,
  handleDeletePhase,
}: DeletePhaseComponentProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const { selectedPhase } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const onHide = () => {
    closeDeletePhaseDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="delete-lifecycle-dialog"
      header={t('labels.header', { ns: 'lifecycle', context: 'deletePhase' })}
      visible={showDeletePhaseDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div className="col-12 p-0">
          { t('messages.removedPhase', { ns: 'lifecycle', context: 'warning', name: selectedPhase?.name }) }
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product"
            label={t('actions.cancel')}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleDeletePhase}
            data-testid="button-delete-product"
            label={t('form.label', { context: 'delete' })}
            iconPos="left"
            loading={false}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
