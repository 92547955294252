import { gql } from '@apollo/client'
import customer from '../fragment/customer'

export default gql`
  mutation updateBillingCustomer($accountID: ID!, $email: String!, $name: String!, $phone: String!, $vat: String, $address: AddressInput! ) {
      updateBillingCustomer(accountID: $accountID, email: $email, name: $name, phone: $phone, vat: $vat, address: $address){
          ...customerFragment
      }
  }
  ${customer}
`
