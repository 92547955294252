import { createContext } from 'react'

import { TProductDetailContext } from '../../interface/product-detail-context.type'

const ProductDetailContext = createContext<TProductDetailContext>({
  addInventoryItem: () => {},
  updateProductDetail: () => {},
  getMaxImpactAmount: () => {},
  isLeafInventoryLoadingEnabled: () => {}
})

export default ProductDetailContext
