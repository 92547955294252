import { connect } from 'react-redux'
import InventoryTreeItemActions from './inventoryTreeItemActions'
import {
  selectedInventoryItemForCreateProductSaga,
  selectedInventoryItemForRemoveProductSaga,
} from '../../../../redux/sagas/product.saga'
import { updateLifecyclesItemsSaga } from '../../../../redux/sagas/lifecycle.saga'
import { updateInventoryItemsSaga } from '../../../../redux/sagas/inventoryTree.saga'
import { selectInventoryRootItemSaga } from '../../../../redux/sagas/inventoryTree.saga'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import AccountSelector from '../../../../redux/selectors/account.selector'
import SpaceSelector from '../../../../redux/selectors/space.selector'

const mapStateToProps = state => {
  return ({
    lifecycleId: LifecycleSelector.lifecycleId(state),
    selectedLifecycleName: LifecycleSelector.selectedLifecycleName(state),
    isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
    selectedSpaceSlug: SpaceSelector.spaceSlug(state),
    accountId: AccountSelector.selectedAccountId(state),
    selectedPhase: LifecycleSelector.selectedPhase(state)
  })
}

const mapDispatchToProps = {
  selectedInventoryItemForCreateProductSaga,
  selectedInventoryItemForRemoveProductSaga,
  selectInventoryRootItemSaga,
  updateLifecyclesItemsSaga,
  updateInventoryItemsSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryTreeItemActions)
