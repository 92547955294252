import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

type DeleteCustomImpactComponentProps = {
  removingInProgress?: boolean,
  showDialog: boolean,
  closeDialog: () => void,
  handleDelete: () => void
}
export const DeleteCustomImpactComponent = ({
  removingInProgress = false,
  showDialog,
  closeDialog,
  handleDelete
}: DeleteCustomImpactComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])

  const onHide = () => {
    closeDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="delete-custom-impact-dialog"
      header={t('labels.headerRemove', { context: 'customImpact' })}
      visible={showDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div data-testid="delete-custom-impact-message" className="col-12 p-0">
          { t('messages.warning', { context: 'removeCustomImpact' }) }
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-customImpact"
            label={t('actions.cancel', { ns: 'common' })}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleDelete}
            data-testid="button-delete-customImpact"
            label={t('actions.remove', { ns: 'common' })}
            iconPos="left"
            loading={removingInProgress}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
