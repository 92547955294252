import { combineReducers } from 'redux'
import { CLEAR_ROOT_STATE_EXCEPT_SETTINGS, CLEAR_ROOT_STATE } from '../actions/clear.actions'

import inventoryTreeViewReducer from './inventoryTree.reducer'
import productScenarioInventoryTreeReducer from './productScenarioInventoryTree.reducer'
import lifecycle from './lifecycle.reducer'
import flags from './flags.reducer'
import loading from './loading.reducer'
import global from './global.reducer'
import inventoryTreeExpandedKeysReducer from './inventoryTreeExpandedKey.reducer'
import comparisonReducer from './comparison.reducer'
import notificationReducer from './notification.reducer'
import inventoryLifecyclesReducer from './inventoryLifecycles.reducer'
import referenceProductsReducer from './referenceProducts.reducer'
import settingsReducer from './settings.reducer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import { persistReducer } from 'redux-persist'

const appReducer = combineReducers({
  account: combineReducers({
    inventory: combineReducers({
      inventoryTreeView: inventoryTreeViewReducer,
      productScenarioInventoryTree: productScenarioInventoryTreeReducer,
      inventoryLifecycles: inventoryLifecyclesReducer,
    })
  }),
  inventoryTreeExpandedKeys: inventoryTreeExpandedKeysReducer,
  comparison: comparisonReducer,
  lifecycle,
  flags,
  loading,
  global,
  referenceProducts: referenceProductsReducer,
  notifications: notificationReducer,
  settings: settingsReducer
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_ROOT_STATE_EXCEPT_SETTINGS) {
    state = { settings: { ...state.settings } }
  }
  if (action.type === CLEAR_ROOT_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [ 'account', 'lifecycle', 'comparison' ]
}
export default persistReducer(rootPersistConfig, rootReducer)
