import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { store } from '../../../configureStore'
import { clearRootStateExceptSettingsAction } from '../../../redux/actions/clear.actions'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { useUrlQuery } from '../../shared/hook/use-url-query'

export const SignUp = () => {
  const { loginWithRedirect } = useAuth0()
  const urlQuery = useUrlQuery()

  useEffect(() => {
    // TODO : Added for compatibility with v0.9
    store.dispatch(clearRootStateExceptSettingsAction())
    loginWithRedirect({
      authorizationParams: {
        action: 'signup',
        type: urlQuery.get('type') || 'regular'
      }
    })
  }, [])
  return <ProgressSpinnerComponent size={2} />
}
