import React from 'react'
import { Button } from 'primereact/button'
import styled from 'styled-components'

const OverviewTableCreateWrapper = styled.div`
  background-image: url("/assets/border_strock.svg");
  color: var(--gray-500);
  text-align: center;
  
  :hover {
    cursor: pointer;
    box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
  }

`

export type TOverviewTableCreateComponentProp = {
  createLabel?: string
  onCreateClick?: (param?: any) => void
}

export const OverviewTableCreateComponent = ({
  createLabel,
  onCreateClick = () => {}
}: TOverviewTableCreateComponentProp) => (
  <OverviewTableCreateWrapper
    data-testid="overview-create-wrapper"
    onClick={onCreateClick}
    className="flex align-items-center justify-content-start w-full h-4rem p-3 m-1"
  >
    <div className="flex align-items-center justify-content-center w-2rem h-2rem mr-3">
      <Button data-testid="overview-create-button" icon="pi pi-plus" className="p-button-outlined p-button-plain w-full h-full" />
    </div>
    <div data-testid="overview-create-label" className="flex flex-grow-1 align-items-center justify-content-start text-basis">
      { createLabel }
    </div>
  </OverviewTableCreateWrapper>
)
