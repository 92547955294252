import React from 'react'
import { AiOutlineTag, AiOutlineSkin } from 'react-icons/ai'
import { FiGrid } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { SimpleCardTemplateComponent } from '../../general/simple-card-template.component'
import { LinkBoxComponent } from '../../general/link-box.component'
import { downloadByUrl } from '../../../util/navigation'
import { Navigation } from '../../../enum'

export const ImportDownloadCsvComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const downloadLinkList = [
    {
      path: Navigation.CSV_TEMPLATE_DEMO,
      type: 'primary-2',
      icon: <i className="pi pi-file-excel"></i>,
      iconClassName: 'px-2 py-1',
      subtitle: t('actions.download', { context: 'fullImport' })
    },
    {
      path: Navigation.CSV_T_SHIRT,
      type: 'primary-1',
      icon: <AiOutlineSkin size={18} />,
      subtitle: t('labels.tShirt')
    },
    {
      path: Navigation.CSV_LIST_PRODUCTS,
      type: 'primary-3',
      icon: <AiOutlineTag size={18} />,
      subtitle: t('labels.list', { context: 'product' })
    },
    {
      path: Navigation.CSV_LIST_OBJECTS,
      type: 'primary-4',
      icon: <FiGrid size={18} />,
      subtitle: t('labels.list', { context: 'object' })
    }
  ]
  const getDownloadCardHeader = () => <span className="text-sm font-semibold p-2">{t('actions.download', { context: 'templates' })}</span>

  const getDownloadCardBody = () => (
    <div className="flex w-full h-full pt-0">
      {
        downloadLinkList.map((link, index) => (
          <div key={`download-key-${index}`} className="col pt-0 ">
            <LinkBoxComponent
              type={link.type}
              dataTestId={`download-link-${index}`}
              onClick={() => downloadByUrl(link.path)}
              className="border-round-lg p-4 py-3"
              icon={link.icon}
              iconClassName={`border-circle icon-padding-2 ${link.iconClassName}`}
              title="Example .CSV"
              subtitle={link.subtitle}
            />
          </div>
        ))
      }
    </div>
  )

  return (
    <SimpleCardTemplateComponent className="bg-gray-50 border-round-xl p-2 w-full h-full" header={getDownloadCardHeader()}>
      { getDownloadCardBody() }
    </SimpleCardTemplateComponent>
  )
}
