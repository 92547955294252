import React, { Component } from 'react'
import { Switch, Table } from 'antd'
import classnames from 'classnames'

import { isEmpty, normalize, returnNested } from '../../../../utils/tools'
import { ScientificNotation } from '../../inventory/scientific-notation/scientificNotation'
import { withTranslation } from 'react-i18next'
import compose from '../../../../utils/compose'
import { EntityType, ImpactEffect } from '../../../../utils/const'
import ImpactBar from '../impactBar'
import ImpactArrow from '../../../helpers/display/impactArrow/impactArrow'
const entityIsLifecycle = (cfv, entityName) =>  returnNested(cfv, 'headers', entityName, '__typename') === EntityType.LIFECYCLE

class ComparisonFlatInventory extends Component {
  state = {
    showPercentages: false,
    selectedRowKeys: []
  }

  getIsShowScenario = () => {
    const { isShowProductScenarioInventory, isShowLifecycleScenarioInventory } = this.props
    return isShowProductScenarioInventory || isShowLifecycleScenarioInventory
  }

  getTableHeaderTitle = entityHeader => <span>
    <b>{ entityHeader.name }</b>
    { (entityHeader.amount && entityHeader.unit) &&
    <React.Fragment>
      <br/>
      <i>{ entityHeader.amount } { entityHeader.unit }</i>
    </React.Fragment>
    }
  </span>

  getColumns = (headers, totals, showPercentages, skipPhases) => {
    const { t } = this.props

    const scenarioEntity = {
      title: this.getTableHeaderTitle(headers.entityB),
      children: [
        {
          title: 'Amount',
          className: 'product-amount cy-compare-product-amount',
          dataIndex: 'bAmount',
          align: 'right',
          sorter: (a, b) => a.bAmount - b.bAmount,
          render: value => <ScientificNotation value={ value }/>
        }, {
          className: 'product-unit cy-comapre--product-unit',
          dataIndex: 'unit',
          render: (text, record) => returnNested(record, 'bAmount') && text,
          key: 'bUnit',
          align: 'left',
        },
        {
          className: 'impact-amount cy-compare-impact-amount',
          dataIndex: 'bImpactAmount',
          title: t('model.impact_in_unit', { unit: returnNested(totals, 'entityB', 'unit') }),
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.bImpactAmount - b.bImpactAmount,
          render: value => <ScientificNotation value={ value }/>
        }
      ]
    }

    const singleEntity = {
      title: this.getTableHeaderTitle(headers.entityA),
      children: [
        {
          title: 'Amount',
          className: 'product-amount cy-product-amount',
          dataIndex: 'aAmount',
          align: 'right',
          sorter: (a, b) => a.aAmount - b.aAmount,
          render: value => <ScientificNotation value={ value }/>
        }, {
          className: 'product-unit cy-product-unit',
          dataIndex: 'unit',
          key: 'aUnit',
          render: (text, record) => returnNested(record, 'aAmount') && text,
          align: 'left',
        },
        {
          className: 'impact-amount cy-impact-amount',
          dataIndex: 'aImpactAmount',
          title: t('model.impact_in_unit', { unit: returnNested(totals, 'entityA', 'unit') }),
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.aImpactAmount - b.aImpactAmount,
          render: value => <ScientificNotation value={ value }/>
        }
      ]
    }

    const valueCols = this.getIsShowScenario() ? [ scenarioEntity, singleEntity ] : [ singleEntity, scenarioEntity ]

    const scenarioPercentageCols = {
      title: this.getTableHeaderTitle(headers.entityB),
      children: [
        {
          title: t('model.impact_in_unit', { unit: returnNested(totals, 'entityB', 'unit') }),
          width: '30%',
          className: 'impact-bar compare-impact-bar cy-compare-impact-bar',
          dataIndex: 'bPercentage',
          defaultSortOrder: 'descend',
          align: this.getIsShowScenario() ? 'right' : 'left',
          sorter: (a, b) => Math.abs(a.bImpactAmount) - Math.abs(b.bImpactAmount),
          render: (value, record) => {
            const tooltip = <ScientificNotation value={ record.bImpactAmount } unit={record.bImpactUnit} />
            return <ImpactBar tooltip={tooltip} percent={value} reversed={this.getIsShowScenario()}  />
          }
        }
      ]
    }

    const singlePercentageCols = {
      title: this.getTableHeaderTitle(headers.entityA),
      children: [
        {
          title: t('model.impact_in_unit', { unit: returnNested(totals, 'entityA', 'unit') }),
          width: '30%',
          className: 'impact-bar cy-impact-bar',
          dataIndex: 'aPercentage',
          defaultSortOrder: 'descend',
          align: this.getIsShowScenario() ? 'left' : 'right',
          sorter: (a, b) => Math.abs(a.aImpactAmount) - Math.abs(b.aImpactAmount),
          render: (value, record) => {
            const tooltip = <ScientificNotation value={ record.aImpactAmount } unit={record.aImpactUnit} />
            return <ImpactBar tooltip={tooltip} percent={value} reversed={!this.getIsShowScenario()} />
          }
        }
      ]
    }

    const percentageCols = this.getIsShowScenario() ? [ scenarioPercentageCols, singlePercentageCols ] : [ singlePercentageCols, scenarioPercentageCols ]

    const columns = [ {
      title: 'Name',
      dataIndex: 'productName',
      className: 'product-name cy-product-name',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    } ]

    !showPercentages
      ? columns.splice( columns.length, 0, ...valueCols)
      : columns.splice( columns.length, 0, ...percentageCols)

    if (!skipPhases) {
      columns.splice( 0, 0, {
        title: 'Phase',
        dataIndex: 'phaseName',
        className: 'phase-name cy-phase-name',
        sorter: (a, b) => a.phaseOrder - b.phaseOrder,
      } )
    }

    columns.splice(columns.length, 0,
      {
        className: 'compare-arrow cy-compare-arrow',
        dataIndex: 'impactEffect',
        align: 'center',
        width: '3%',
        render: value => <ImpactArrow
          impactEffect={this.getImpactEffect(value)}
          size="large"
        />
      })
    return columns
  }

  getImpactEffect = impactEffect => {
    if (this.getIsShowScenario()) return impactEffect
    if (impactEffect === ImpactEffect.POSITIVE) return ImpactEffect.NEGATIVE
    if (impactEffect === ImpactEffect.NEGATIVE) return ImpactEffect.POSITIVE

    return ImpactEffect.NONE
  }
  /**
   * @param {{entityA: {Impact}, entityB: {Impact}, impactEffect: string}} totals
   *
   */
  totalsInFooter = totals => {
    const { showPercentages } = this.state
    const { t, skipPhases } = this.props
    const colSpan = showPercentages ? 1 : 3
    const colSpanTitle = skipPhases ? colSpan : colSpan + 1
    const totalsImpactEffect = this.getImpactEffect(totals.impactEffect)
    return (
      <tr className="summary">
        <th colSpan={colSpanTitle}>{t('model.total_impact')}</th>
        <td colSpan={colSpan} data-cy="left-total-impact">
          <ScientificNotation value={ this.getIsShowScenario() ? totals.entityB.amount : totals.entityA.amount }/>
          { this.getIsShowScenario() ? totals.entityB.unit : totals.entityA.unit }
        </td>
        <td data-cy="right-total-impact">
          <ScientificNotation value={ this.getIsShowScenario() ? totals.entityA.amount : totals.entityB.amount }/>
          { this.getIsShowScenario() ? totals.entityA.unit : totals.entityB.unit }
        </td>
        <td data-cy="impact-arrow" className="compare-arrow">
          <ImpactArrow
            impactEffect={totalsImpactEffect}
            size="large"
          />
        </td>
      </tr>
    )
  }

  handleShowPercentages = checked => {
    this.setState({ showPercentages: checked })
  }

  handleShowPhases = checked => {
    const { comparisonFlatView } = this.props
    if (entityIsLifecycle(comparisonFlatView, this.getIsShowScenario() ? 'entityB' : 'entityA')) {
      this.props.setIsLifecycleSkipPhasesSaga(!checked)
    } else {
      this.props.setIsInventorySkipPhasesSaga(!checked)
    }
  }

  handleSelectRow = product => {
    const { selectedFlatViewItemSaga } = this.props
    selectedFlatViewItemSaga(product.productId)
    const rowIndexKey = product.productId + product.phaseId
    this.setState({ selectedRowKeys: [ rowIndexKey ] })
  }

  render() {
    const { comparisonFlatView, skipPhases,  t } = this.props
    const { showPercentages, selectedRowKeys } = this.state

    if (isEmpty(comparisonFlatView) || !Array.isArray(comparisonFlatView.items)) return null
    const showPhasesSwitch = entityIsLifecycle(comparisonFlatView, 'entityA') || entityIsLifecycle(comparisonFlatView, 'entityB')
    const rowSelection = {
      type: 'radio',
      selectedRowKeys,
      onChange: record => {
        this.setSelectedProduct(record.productId, record.phaseId)
      },
      columnWidth: 0,
      renderCell: () => '',
    }

    return (
      <React.Fragment>
        <div className="leaf-inventory-container">
          <div className={ classnames('leaf-inventory-table', { percentages: showPercentages }) }>
            <Table
              data-cy="leaf-inventory-table"
              dataSource={ comparisonFlatView.items || [] }
              pagination={ false }
              columns={ this.getColumns(comparisonFlatView.headers, comparisonFlatView.totals, showPercentages, comparisonFlatView.skipPhases) }
              rowClassName={ record => `cy-row-for-${ normalize(record.productName) }` }
              summary={ () => this.totalsInFooter(comparisonFlatView.totals) }
              rowKey={ record => record.productId + record.phaseId }
              size="small"
              bordered
              rowSelection={rowSelection}
              onRow={record => {
                return {
                  onClick: () => { this.handleSelectRow(record) }
                }
              }}
            />
          </div>
          <div className="switches-container">
            <div className="percentages-switch switch" data-cy="show-percentages-switch">
              {t('model.show_bars')} <Switch onChange={this.handleShowPercentages} size={'small'} checked={showPercentages} checkedChildren={t('global.on')} unCheckedChildren={t('global.off')} />
            </div>
            { showPhasesSwitch &&
            <div className="phases-switch switch" data-cy="leaf-inventory-show-phases-switch">
              { t('model.show_phases') } <Switch
                onChange={ this.handleShowPhases }
                size={ 'small' }
                checked={ !skipPhases }
                checkedChildren={ t('global.on') }
                unCheckedChildren={ t('global.off') }
              />
            </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation()
)(ComparisonFlatInventory)
