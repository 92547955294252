import { EntityType } from './const'
import { maxByProp, percentage, getImpactEffect } from './math'
import { returnNested, safeArray } from './tools'

/**
 * @typedef {Object} FlatViewEntity
 * @property {string} id
 * @property {string} name
 * @property {string} amount
 * @property {string} unit
 * @property {string} name
 * @property Phase[] phases
 * @property {string} __typename
 * @property {Impact} totalImpact
 * @property {InventoryItem[]} leafInventoryItems
 */

export default class ComparisonFlatView {
  /** @type {FlatViewEntity} */
  _entityA
  /** @type {FlatViewEntity} */
  _entityB

  /**
   * @param {FlatViewEntity} entityA
   * @param {FlatViewEntity} entityB
   */
  constructor(entityA, entityB) {
    this._entityA = entityA
    this._entityB = entityB
  }

  _getPhaseOrder = phase => [
    ...safeArray(returnNested(this._entityA, 'phases')),
    ...safeArray(returnNested(this._entityB, 'phases'))
  ]
    .findIndex(p => p.type === returnNested(phase, 'type'))

  /**
   * To be used by max impact
   */

  _mapEntity = (keyPrefix, item, includePhase) => {
    const impactAmount = Number(returnNested(item, 'impact', 'amount')) || 0
    const amount = Number(returnNested(item, 'amount')) || 0
    let result = {
      productId: returnNested(item, 'product', 'id'),
      productName: returnNested(item, 'product', 'name'),
      unit: returnNested(item, 'product', 'unit') || 'unit',
    }
    if (includePhase) {
      result = {
        ...result,
        phaseId: returnNested(item, 'phase', 'id'),
        phaseName: returnNested(item, 'phase', 'name'),
        phaseOrder: this._getPhaseOrder(returnNested(item, 'phase')),
      }
    }

    result[`${ keyPrefix }Amount`] = amount
    result[`${ keyPrefix }ImpactAmount`] = impactAmount
    result[`${ keyPrefix }ImpactUnit`] = returnNested(item, 'impact', 'unit')
    return result
  }

  _mapPrevValues = (keyPrefix, existingItem, item) => {
    const prevAmount = Number(returnNested(existingItem, `${ keyPrefix }Amount`)) || 0
    const prevImpactAmount = Number(returnNested(existingItem, `${ keyPrefix }ImpactAmount`)) || 0

    existingItem[`${ keyPrefix }Amount`] = Number(returnNested(item, 'amount')) + prevAmount
    existingItem[`${ keyPrefix }ImpactAmount`] = Number(returnNested(item, 'impact', 'amount')) + prevImpactAmount
    return existingItem
  }

  _mapEntryHeader = entry => {
    const result = {
      id: returnNested(entry, 'id'),
      name: returnNested(entry, 'name'),
      amount: returnNested(entry, 'amount'),
      unit: returnNested(entry, 'unit'),
      __typename: returnNested(entry, '__typename')
    }

    if (returnNested(entry, '__typename') === EntityType.PRODUCT) {
      result.amount = returnNested(entry, 'amount') || 1
      result.unit = returnNested(entry, 'unit') || 'unit'
    }
    return result
  }

  _getTotals = () => ({
    entityA: {
      amount: returnNested(this, '_entityA', 'totalImpact', 'amount'),
      unit: returnNested(this, '_entityA', 'totalImpact', 'unit')
    },
    entityB: {
      amount: returnNested(this, '_entityB', 'totalImpact', 'amount'),
      unit: returnNested(this, '_entityB', 'totalImpact', 'unit')
    },
    impactEffect: getImpactEffect(
      returnNested(this, '_entityA', 'totalImpact', 'amount'),
      returnNested(this, '_entityB', 'totalImpact', 'amount'))
  })
  /**
   * @param {Array.<{aImpactAmount: string, bImpactAmount: string}>} items
   * @returns {*}
   * @private
   */
  _calculatePercentages = items => {
    const maxImpact = Math.max(maxByProp(items, 'aImpactAmount', false), maxByProp(items, 'bImpactAmount', false))
    // Calculate item impact effects and percentages
    return safeArray(items).map(item => ({
      ...item,
      impactEffect: getImpactEffect(item.aImpactAmount, item.bImpactAmount),
      aPercentage: percentage(item.aImpactAmount, maxImpact),
      bPercentage: percentage(item.bImpactAmount, maxImpact),
    }))

  }
  flatViewWithoutPhases = () => {
    if (!this._entityA || !this._entityB) return

    let result = {
      items: new Map(),
      skipPhases: true,
      headers: {
        entityA: this._mapEntryHeader(this._entityA),
        entityB: this._mapEntryHeader(this._entityB)
      },
      totals: {
        entityA: {},
        entityB: {},
        impactEffect: null
      }
    }

    const tmpItems = new Map()
    const getItemKey = item => returnNested(item, 'product', 'id')
    // Map entryA
    for (const item of safeArray(this._entityA.leafInventoryItems)) {
      const key = getItemKey(item)
      const existingItem = tmpItems.get(key)
      existingItem === undefined
        ? tmpItems.set(key, this._mapEntity('a', item, false))
        : tmpItems.set(key, this._mapPrevValues('a', existingItem, item))
    }

    // Map entryB
    for (const item of safeArray(this._entityB.leafInventoryItems)) {
      const key = getItemKey(item)
      const existingItem = tmpItems.get(key)
      existingItem === undefined
        ? tmpItems.set(key, this._mapEntity('b', item, false))
        : tmpItems.set(key, this._mapPrevValues('b', existingItem, item))
    }
    result.items = this._calculatePercentages(Array.from(tmpItems.values()))
    result.totals = this._getTotals()
    return result
  }

  flatViewWithPhases = () => {
    if (!this._entityA || !this._entityB) return

    let result = {
      items: new Map(),
      skipPhases: false,
      headers: {
        entityA: this._mapEntryHeader(this._entityA),
        entityB: this._mapEntryHeader(this._entityB)
      },
      totals: {
        entityA: {},
        entityB: {},
        impactEffect: null
      }
    }
    const tmpItems = new Map()
    const getItemKey = item => returnNested(item, 'product', 'id') + returnNested(item, 'phase', 'type')

    // Map entryA
    for (const item of safeArray(this._entityA.leafInventoryItems)) {
      const key = getItemKey(item)
      const existingItem = tmpItems.get(key)
      existingItem === undefined
        ? tmpItems.set(key, this._mapEntity('a', item, true))
        : tmpItems.set(key, this._mapPrevValues('a', existingItem, item))
    }

    // Map entryB
    for (const item of safeArray(this._entityB.leafInventoryItems)) {
      const key = getItemKey(item)
      const existingItem = tmpItems.get(key)
      existingItem === undefined
        ? tmpItems.set(key, this._mapEntity('b', item, true))
        : tmpItems.set(key, this._mapPrevValues('b', existingItem, item))
    }
    result.items = this._calculatePercentages(Array.from(tmpItems.values()))
    result.totals = this._getTotals()
    return result
  }
}
