import { gql } from '@apollo/client'

export default gql`
  mutation applyCouponCode($accountID: ID!, $code: String!) {
    applyCouponCode(accountID: $accountID, code: $code) {
        start
        end
        couponCode
    }
  }
`
