import React, { useContext, useEffect, useRef } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { CREATE_ITEM_FOLDER, RENAME_ITEM_FOLDER } from '../../../graphql/mutation'
import { CreateFolderComponent } from '../../component/dialog/create-folder.component'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { ProductLabel } from '../../../shared/enum'

type TCreateFolderContainerProps = {
  afterCreateFolder: () => void
}

export const CreateFolderContainer = ({
  afterCreateFolder
}: TCreateFolderContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    label: labels = ProductLabel.Product,
    showCreateFolderDialog = false,
    currentFolder = null,
    updateProduct,
    selectedEntity
  } = useContext<TProductContext>(ProductContext)
  const { id: parentID = '' } = currentFolder || {}

  const [
    createItemFolder,
    {
      error: failedCreatingFolder,
      data: createdFolderData = {},
      loading: folderCreating
    }
  ] = useMutation(CREATE_ITEM_FOLDER)

  const folderCreated = createdFolderData?.createItemFolder

  useEffect(() => {
    if (!folderCreating && (folderCreated || failedCreatingFolder)) {
      createFolderCallback()
    }
  }, [ folderCreating, folderCreated, failedCreatingFolder ])

  const [
    renameItemFolder,
    {
      error: failedRenamingItemFolder,
      data: renamedItemFolderData = {},
      loading: renamingItemFolder
    }
  ] = useMutation(RENAME_ITEM_FOLDER)
  const renamedItemFolder = renamedItemFolderData?.renameItemFolder

  useEffect(() => {
    if (!renamingItemFolder && (renamedItemFolder || failedRenamingItemFolder)) {
      renameFolderCallback()
    }
  }, [ renamingItemFolder, folderCreated, failedRenamingItemFolder ])

  const closeCreateFolderDialog = () => {
    updateProduct({ showCreateFolderDialog: false })
  }

  const handleCreateFolder = (name: string) => {
    spaceID && createItemFolder({
      variables: {
        name, labels, spaceID, parentID
      }
    })
  }

  const handleRenameFolder = (newName: string) => {
    const folderID = selectedEntity?.id
    folderID && renameItemFolder({ variables: { folderID, newName } })
  }

  const createFolderCallback = () => {
    try {
      if (failedCreatingFolder) {
        throw failedCreatingFolder
      } else if (folderCreated) {
        afterCreateFolder()
        closeCreateFolderDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'createFolder' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed creating the folder', error?.graphQLErrors, t)
    }
  }

  const renameFolderCallback = () => {
    try {
      if (failedRenamingItemFolder) {
        throw failedRenamingItemFolder
      } else if (renamedItemFolder) {
        afterCreateFolder()
        closeCreateFolderDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'renameFolder', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(
        toast,
        t('messages.errorSummary', { context: 'renameFolder', ns: 'product' }),
        error?.graphQLErrors,
        t
      )
    }
  }

  return (
    <>
      {showCreateFolderDialog && (
        <CreateFolderComponent
          folderCreating={folderCreating}
          handleRenameFolder={handleRenameFolder}
          handleCreateFolder={handleCreateFolder}
          closeCreateFolderDialog={closeCreateFolderDialog}
          showCreateFolderDialog={showCreateFolderDialog}
        />
      )}

      <Toast data-testid="create-product-status" ref={toast} position="top-right" />
    </>
  )
}
