import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { DashboardContainer } from './container/dashboard.container'

import { DashboardProvider } from './provider/dashboard.provider'

import LoadingPage from '../../component/shared/loadingPage'

export const Dashboard = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
  useEffect(() => {
    const handleRedirectCallback = async () => {
      await loginWithRedirect()
    }

    if (!isAuthenticated && !isLoading) {
      handleRedirectCallback()
    }
  }, [ isAuthenticated, isLoading ])

  if (isAuthenticated && !isLoading) {
    return (
      <DashboardProvider>
        <DashboardContainer />
      </DashboardProvider>
    )
  }

  return <LoadingPage />
}
