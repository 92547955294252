import { gql } from '../../../__generated__'

export const REMOVE_CONVERSION_ITEM = gql(`
  mutation removeConversionItem($conversionID: ID!, $productID: ID!) {
    removeConversionItem(conversionID: $conversionID, productID: $productID)
  }
`)

export const CHANGE_INVENTORY_ITEM_AMOUNT = gql(`
  mutation changeInventoryItemAmount($inventoryItemID: ID!, $inventoryItemAmount: String!, $targetID: String!, $targetAmount: String!) {
    changeInventoryItemAmount(inventoryItemID: $inventoryItemID, inventoryItemAmount: $inventoryItemAmount, targetID: $targetID, targetAmount: $targetAmount){
        ...extendedProductFragment
    }
  }
`)
