import React, { useContext, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-regular-svg-icons'

import { OverviewCardTemplateLayout } from '../../../shared/layout/overview-card-template.component'
import { ListType, Navigation, ProductLabel } from '../../../shared/enum'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { MenuFolderContainer } from '../../container/menu/menu-folder.container'
import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { normalize } from '../../../../utils/tools'

type ViewTemplateFolderProps = {
  id: string,
  name: string,
  label: ProductLabel
}
export const ViewTemplateFolderComponent = ({
  id = '',
  name = '',
  label = ProductLabel.Product
}: ViewTemplateFolderProps) => {
  const navigate = useNavigate()
  const [ trackEventInSegment ] = getSegmentTrack()
  const { updateProduct } = useContext<TProductContext>(ProductContext)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const [ isMouseOver, setIsMouseOver ] = useState<boolean>(false)

  const navigateToFolder = () => {
    trackEventInSegment(TrackEventType.VIEWED_FOLDER)
    const pathname: Navigation = label === ProductLabel.Product
      ? Navigation.ProductOverviewFolder : Navigation.ObjectOverviewFolder
    account?.id && space?.slug && id && navigate(generatePath(pathname, {
      accountId: account?.id,
      workspaceSlug: space?.slug,
      folderId: id
    }))
    updateProduct({ currentPage: 1 })
  }

  return (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      data-cy={`item-named-${normalize(name)}`} // TODO: find a good replacement of normalize()
      className="flex w-full h-full p-0 m-0"
    >
      <OverviewCardTemplateLayout
        listType={ListType.Folder}
        key={id}
        clickHandler={navigateToFolder}
      >
        <div data-testid="overview-view-body" className="flex w-full align-content-center flex-column h-full pt-4">
          <div className="flex flex-none w-full h-3rem align-items-center justify-content-center">
            <div data-testid="overview-view-icon" className="flex flex-none align-items-center justify-content-center w-3rem h-3rem border-round-sm overview-card-icon">
              <FontAwesomeIcon icon={faFolder} size="2xl" />
            </div>
          </div>
          <div data-testid="overview-view-textLabel" className="flex flex-none w-full h-3rem py-3 align-items-center justify-content-center text-base">{ name }</div>
        </div>

        <div className="absolute bottom-0 right-0 pb-3 pr-3">
          <MenuFolderContainer {...{ id, name, isMouseOver }} />
        </div>
      </OverviewCardTemplateLayout>
    </div>
  )
}
