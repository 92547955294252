import React, {
  useContext,
  useEffect, useRef
} from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Toast } from 'primereact/toast'
import styled from 'styled-components'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'

import { Messages } from 'primereact/messages'
import { selectedAccountVar, userAuth0Var } from '../../../graphql/cache'
import DashboardContext from '../context/dashboard.context'
import { Navigation, Policy } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { START_TRIAL } from '../../graphql/mutation/account'
import { displayGraphqlErrors } from '../../shared/util/error'
import { TrialExtendBanner } from '../component/trial-extend-banner'
import { EmailVerificationBanner } from '../component/email-verification-banner'
import { BannerType } from '../../shared/enum/banner'

type TopBannerWrapperProp = {
  activeTopBanners: []
}

const TopBannerWrapper = styled.div<TopBannerWrapperProp>`
  width: 100%;
  height: ${(props: TopBannerWrapperProp) => `${props.activeTopBanners.length * 60}px`};


  .p-message {
    border: none;
    margin: 0;
  }

  .p-message-summary,
  .p-message-wrapper {
    width: 100%;
  }

  .p-message-icon {
    display: none;
  }
`

export const TopBannerContainer = () => {
  const {
    account: {
      id: accountID = null,
      isTrialing = false,
      hasValidSubscription = false,
      mayTrialBeStarted = false,
    } = {},
    hasAccess: hasAccountAccess = [],
    emptyAccount
  } = useReactiveVar(selectedAccountVar) || {}
  const { emailVerified, appMetadata } = userAuth0Var() || {}
  const [
    startTrial,
    {
      error: failedStartingTrial, data, loading: statingTrial
    }
  ] = useMutation(START_TRIAL)
  useEffect(() => {
    if (!statingTrial && (data || failedStartingTrial)) {
      startTrialCallback()
    }
  }, [ failedStartingTrial, data, failedStartingTrial ])
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const messagesTrial = useRef<Messages>(null)
  const messagesEmail = useRef<Messages>(null)
  const toast = useRef<Toast>(null)
  const { activeTopBanners = [], updateDashboard } = useContext(DashboardContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [ trackEventInSegment ] = getSegmentTrack()

  const canExtendTrial = (): boolean => hasAccountAccess.some((policy: any) => (policy === Policy.AccountManagement || policy === Policy.BillingManagement))

  const canUpgrade = (): boolean => hasAccountAccess.some((policy: any) => (policy === Policy.AccountManagement))

  const isOnBillingPage = isValid(matchPath(Navigation.AccountSettingBilling, `${location.pathname}${location.search}`))
  const hasValidSubscriptionOrInTrial = () => (isTrialing || hasValidSubscription)

  const startTrialCallback = () => {
    try {
      if (failedStartingTrial) {
        throw failedStartingTrial
      } else if (data && data.startTrial) {
        trackEventInSegment(TrackEventType.TRIAL_EXTENDED)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.successDetail', { context: 'extendTrial' }),
          life: 3000
        })

        setTimeout(() => {
          window.location.href = Navigation.Account
        }, 1000)
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed starting trial', error?.graphQLErrors)
    }
  }

  const extendTrial = () => {
    accountID && startTrial({ variables: { accountID } })
  }

  const upgradeNow = () => {
    navigate(Navigation.AccountSettingBilling)
  }

  useEffect(() => {
    if (!emptyAccount && !hasValidSubscriptionOrInTrial() && hasAccountAccess.length > 0) {
      const isTrialBannerActive = activeTopBanners.some(item => item === BannerType.TrialExtend)
      if (!isTrialBannerActive) updateDashboard({ activeTopBanners: [ ...activeTopBanners, BannerType.TrialExtend ] })
      setTimeout(() => {
        messagesTrial.current?.replace({
          severity: mayTrialBeStarted ? 'info' : 'error',
          sticky: true,
          summary: <TrialExtendBanner {...{
            upgradeOnly: !mayTrialBeStarted, isOnBillingPage, extendTrial, upgradeNow, canExtendTrial, canUpgrade
          }}
          />
        })
      })
    } else {
      messagesTrial.current?.clear()
      updateDashboard({ activeTopBanners: [ ...activeTopBanners.filter(item => item === BannerType.TrialExtend) ] })
    }
  }, [ isOnBillingPage, accountID, emptyAccount ])

  useEffect(() => {
    if (appMetadata?.isEmailVerificationEnabled && !emailVerified && accountID && !emptyAccount) {
      const isVerifyEmailBannerActive = activeTopBanners.some(item => item === BannerType.EmailVerified)
      if (!isVerifyEmailBannerActive) updateDashboard({ activeTopBanners: [ ...activeTopBanners, BannerType.EmailVerified ] })
      setTimeout(() => {
        messagesEmail.current?.replace({
          severity: 'info',
          sticky: true,
          closable: false,
          summary: <EmailVerificationBanner />
        })
      })
    } else {
      messagesEmail.current?.clear()
      updateDashboard({ activeTopBanners: [ ...activeTopBanners.filter(item => item === BannerType.EmailVerified) ] })
    }
  }, [ emailVerified, accountID, emptyAccount ])

  useEffect(() => {
    if (accountID) {
      messagesTrial.current?.clear()
      messagesEmail.current?.clear()
    }
  }, [ accountID ])

  const onRemoveTrialBanner = () => {
    messagesTrial.current?.clear()
    updateDashboard({ activeTopBanners: [ ...activeTopBanners.filter(item => item === BannerType.TrialExtend) ] })
  }

  if (emptyAccount) {
    return <></>
  }

  return (
    <TopBannerWrapper activeTopBanners={activeTopBanners}>
      <Messages data-testid="email-verification-message" ref={messagesEmail} />
      <Messages data-testid="start-trial-message" ref={messagesTrial} onRemove={onRemoveTrialBanner} />
      <Toast data-testid="start-trial-status" ref={toast} position="top-right" />
    </TopBannerWrapper>
  )
}
