import { connect } from 'react-redux'
import { toggleExpandedKeySaga } from '../../../redux/sagas/inventoryTree.saga'
import InventoryExpandedKeysSelector from '../../../redux/selectors/inventoryExpandedKeys.selector'
import { returnNested } from '../../../utils/tools'
import { selectedInventoryItemSaga } from '../../../redux/sagas/product.saga'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import GlobalSelector from '../../../redux/selectors/global.selector'
import { setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'
import PhaseInventoryTree from './phaseInventoryTree'

const mapStateToProps = (state, ownProps) => {
  return {
    expandedKeys: InventoryExpandedKeysSelector.expandedKeysByRootId(state, returnNested(ownProps, 'phase', 'inventory', 'product', 'id')),
    isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
    isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state),
    isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
    amountModalPreference: GlobalSelector.amountModalPreference(state)
  }
}

const mapDispatchToProps = {
  toggleExpandedKeySaga,
  selectedInventoryItemSaga,
  setIsDetailsPanelOpenAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhaseInventoryTree)

