import { createReducer } from '../../utils/reducerTools'
import { CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT, CLEAR_GLOBAL_STATE_EXCEPT_USER } from '../actions/clear.actions'
import {
  SET_IS_LOADING_LEFT_TREE,
  SET_IS_LOADING_RIGHT_TREE,
  SET_IS_LOADING_REFERENCE_PRODUCTS,
  SET_IS_LOADING_SPACE_IMPACT_METHODS,
  SET_IS_LOADING_SPACE_IMPACT_SETTINGS,
} from '../actions/loading.actions'

const defaultLoadingState = {
  isLoadingReferenceProducts: false,
  isLoadingSpaceImpactMethods: false,
  isLoadingSpaceImpactSettings: false,
  isLoadingLeftTree: false,
  isLoadingRightTree: false,
}

export default createReducer(defaultLoadingState, {
  [CLEAR_GLOBAL_STATE_EXCEPT_USER]: () => ({ ...defaultLoadingState }),
  [CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT]: () => ({ ...defaultLoadingState }),
  [SET_IS_LOADING_REFERENCE_PRODUCTS]: (state, action) => ({ ...state, isLoadingReferenceProducts: action.status }),
  [SET_IS_LOADING_SPACE_IMPACT_METHODS]: (state, action) => ({ ...state, isLoadingSpaceImpactMethods: action.status }),
  [SET_IS_LOADING_SPACE_IMPACT_SETTINGS]: (state, action) => ({ ...state, isLoadingSpaceImpactSettings: action.status }),
  [SET_IS_LOADING_LEFT_TREE]: (state, action) => ({ ...state, isLoadingLeftTree: action.status }),
  [SET_IS_LOADING_RIGHT_TREE]: (state, action) => ({ ...state, isLoadingRightTree: action.status })
})

