import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'

import LifecycleContext from '../provider/context/lifecycle.context'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import { TableListLayout } from './table-list.layout'
import { OverviewTableCreateComponent } from '../../shared/component/overview/overview-table-create.component'
import DashboardContext from '../../dashboard/context/dashboard.context'

export const TableViewLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const { updateDashboard } = useContext(DashboardContext)
  const {
    currentPage = 1,
    totalItems = 0,
    tablePageSize = 0,
    loadingLifeCycleOverview,
    allLifecycles = [],
    updateLifecycle
  } = useContext<TLifecycleContext>(LifecycleContext)
  const [ firstProduct, setFirstProduct ] = useState(0)
  useEffect(() => {
    if (currentPage === 1 && firstProduct !== 0) {
      setFirstProduct(0)
    }
  }, [ currentPage ])

  const hasMore = () => totalItems > tablePageSize

  const onCustomPageChange = (event: any) => {
    setFirstProduct(event.first)
    updateLifecycle({ currentPage: event.page + 1 })
  }

  const openCreateLifeCycleDialog = () => {
    updateDashboard({ showCreateLifeCycleDialog: true })
  }

  return (
    <>
      { loadingLifeCycleOverview ? (
        <ProgressSpinnerComponent dataTestId="loading-products" size={2} />
      ) : (
        <>
          <div data-testid="table-view-allTitle" className="w-full px-3 pt-4">
            { t('labels.all', { context: 'lifecycle' }) }
          </div>
          <div data-testid="table-view-allTable" className="flex flex-wrap w-full px-3">
            { allLifecycles.length > 0
              ? <TableListLayout lifeCycles={allLifecycles} />
              : (
                <OverviewTableCreateComponent
                  onCreateClick={openCreateLifeCycleDialog}
                  createLabel={t('actions.createFirst', { context: 'lifecycle' })}
                />
              )}
          </div>
          { hasMore() && <PagePaginatorComponent first={firstProduct} pageSize={tablePageSize} totalItems={totalItems} onPageChange={onCustomPageChange} />}
        </>
      )}
    </>
  )
}
