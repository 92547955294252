import compose from '../../../../utils/compose'
import { returnNested } from '../../../../utils/tools'
import { withTranslation } from 'react-i18next'
import React from 'react'
import WorkspaceDetails from '../../details-panel/workspace-details/workspaceDetails.container'
import PhaseDetails from '../../details-panel/phase-details/phaseDetails.container'
import ProductDetails from '../../details-panel/product-details/productDetails.container'
import LifecycleDetails from '../../details-panel/lifecycle-details/lifecycleDetails.container'

const getDetailsPanel = props =>  {
  const {
    selectedPhaseId,
    selectedProductId,
    isCreatePhase,
    isForcingWorkspacePanel,
    selectedLifecycle,
    lifecycleScenario,
    detailsPanelLifecycleId,
    entityToCompare,
    updatePageItems,
    isEnabledForV1 = false
  } = props

  let lifecycle
  switch (detailsPanelLifecycleId) {
  case returnNested(lifecycleScenario, 'id'):
    lifecycle = lifecycleScenario
    break
  case returnNested(entityToCompare, 'id'):
    lifecycle = entityToCompare
    break
  default:
    lifecycle = selectedLifecycle
  }
  if (isForcingWorkspacePanel) {
    return <WorkspaceDetails/>
  }
  if ((selectedPhaseId || isCreatePhase) && !selectedProductId ) {
    return <PhaseDetails lifecycle={lifecycle} updatePageItems={ updatePageItems }/>
  }
  if (selectedProductId) {
    return <ProductDetails isEnabledForV1={isEnabledForV1} updatePageItems={ updatePageItems }/>
  }
  return <LifecycleDetails lifecycle={lifecycle}/>
}

const LifecycleDetailPanelWrapper = props => {
  return getDetailsPanel(props)
}

export default compose(
  withTranslation()
)(LifecycleDetailPanelWrapper)
