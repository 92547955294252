import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

export const HeaderTableComponent = () => {
  const { t } = useTranslation([ 'lifecycle', 'common' ])
  const { lifecycleTotalImpact } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  return (
    <div className="flex align-items-center justify-content-start w-full h-full" data-html2canvas-ignore>
      <div className="flex flex-grow-1 text-gray-700 text-2xl">
        { t('labels.header', { context: 'chart', unit: lifecycleTotalImpact?.unit, ns: 'common' }) }
      </div>
      <div className="flex flex-none gap-3"></div>
    </div>
  )
}
