import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { ProductLabel } from '../../shared/enum'

import ProductContext from '../provider/context/product.context'
import { TProductContext } from '../interface/product-context.type'

import { TableListLayout } from './table-list.layout'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'
import { OverviewTableCreateComponent } from '../../shared/component/overview/overview-table-create.component'
import DashboardContext from '../../dashboard/context/dashboard.context'

export const TableViewLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    label = ProductLabel.Product,
    currentPage = 1,
    totalItems = 0,
    tablePageSize = 0,
    emptyFilterResult,
    loadingProductOverview,
    favProducts = [],
    allProducts = [],
    updateProduct
  } = useContext<TProductContext>(ProductContext)
  const { updateDashboard } = useContext(DashboardContext)
  const [ firstProduct, setFirstProduct ] = useState(0)
  useEffect(() => {
    if (currentPage === 1 && firstProduct !== 0) {
      setFirstProduct(0)
    }
  }, [ currentPage ])

  const hasMore = () => totalItems > tablePageSize

  const onCustomPageChange = (event: any) => {
    setFirstProduct(event.first)
    updateProduct({ currentPage: event.page + 1 })
  }

  const openCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: true })
  }

  return (
    <>
      { loadingProductOverview ? (
        <ProgressSpinnerComponent dataTestId="loading-products" size={2} />
      ) : (
        <>
          { favProducts.length > 0 && (
            <>
              <div data-testid="table-view-favTitle" className="w-full px-3 pt-3">
                { t('labels.favorites') }
              </div>
              <div data-testid="table-view-favTable" className="flex flex-wrap w-full px-3">
                <TableListLayout products={favProducts} areFavorites />
              </div>
            </>
          )}
          { !emptyFilterResult ? (
            <>
              <div data-testid="table-view-allTitle" className="w-full px-3 pt-4">
                { t('labels.all', { context: label }) }
              </div>
              <div data-testid="table-view-allTable" className="flex flex-wrap w-full px-3">
                { allProducts.length > 0
                  ? <TableListLayout products={allProducts} />
                  : <OverviewTableCreateComponent onCreateClick={openCreateProductDialog} createLabel={t('actions.createFirst', { context: label })} />}
              </div>
              { hasMore() && <PagePaginatorComponent first={firstProduct} pageSize={tablePageSize} totalItems={totalItems} onPageChange={onCustomPageChange} />}
            </>
          ) : (
            <div data-testid="table-view-emptyFilter" className="flex flex-column text-center text-gray-200 text-lg w-full py-8">
              <span>{ t('labels.emptyFilterResult') }</span>
            </div>
          )}
        </>
      )}
    </>
  )
}
