import { gql } from '@apollo/client'
import role from '../fragment/role'

export default gql`
  mutation unassignUserFromRole($roleID: ID!, $userID: ID!) {
    unassignUserFromRole(roleID: $roleID, userID: $userID) {
      ...roleFragment
    }
  }
  ${role}
`
