import { connect } from 'react-redux'
import { trackPageSaga } from '../../redux/sagas/tracking.saga'
import AccountSelector from '../../redux/selectors/account.selector'
import FlagsSelector from '../../redux/selectors/flags.selector'

import billing from './billing'
import GlobalSelector from '../../redux/selectors/global.selector'

const mapStateToProps = state => ({
  subscriptionId: AccountSelector.subscriptionId(state),
  selectedAccountSubscription: AccountSelector.selectedAccountSubscription(state),
  isDataViaNetworkRequesting: FlagsSelector.isDataViaNetworkRequesting(state),
  hasAccountActiveSubscription: AccountSelector.hasAccountActiveSubscription(state),
  isTrialExpired: AccountSelector.isTrialExpired(state),
  hasValidSubscription: AccountSelector.hasValidSubscription(state),
  subscriptionProducts: GlobalSelector.subscriptionProducts(state)
})

const mapDispatchToProps = {
  trackPageSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(billing)
