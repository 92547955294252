import { gql } from '@apollo/client'
import { account } from '../fragment/account'

export const ACCOUNT = gql`
  query account($id: ID!){
    account(id: $id) {
      ...accountFragment
    }
  }
  ${account}
`
