import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useReactiveVar, useQuery, useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { MoveToFolderComponent } from '../../component/dialog/move-to-folder.component'
import { ProductLabel } from '../../../shared/enum'
import { LIST_ITEM_FOLDERS } from '../../../graphql/query/folder'
import { ICurrentFolder, TFolder } from '../../../model'
import { MOVE_FOLDER, MOVE_ITEM } from '../../../graphql/mutation'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { MenuAction } from '../../enum/menu-action'
import { EntityType } from '../../../shared/enum/entity-type'

type TMoveToFolderContainerProps = {
  afterMoveToFolder: () => void
}

export const MoveToFolderContainer = ({
  afterMoveToFolder
}: TMoveToFolderContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const toast = useRef<Toast>(null)
  const { space: { id: spaceID = '' } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    label = ProductLabel.Product,
    showMoveToFolderDialog = false,
    currentFolder = null,
    updateProduct,
    selectedEntity,
    selectedAction
  } = useContext<TProductContext>(ProductContext)
  const [ currentModalFolder, setCurrentModalFolder ] = useState<ICurrentFolder | null>(null)
  const [ folders, setFolders ] = useState<TFolder[]>([])
  const { id: folderID = '' } = currentModalFolder || {}

  const {
    loading: loadingFolders,
    data: foldersData
  } = useQuery(LIST_ITEM_FOLDERS, {
    skip: !spaceID || !showMoveToFolderDialog,
    variables: { spaceID, labels: [ label ], folderID },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingFolders && foldersData) {
      const { currentFolder, items = [] } = foldersData?.listItemFolders || {}
      const folders = items
        .filter((item: any) => item?.__typename === EntityType.Folder)
        .map((item: any) => ({ id: item.id, name: item.name } as TFolder))
      setCurrentModalFolder(currentFolder as ICurrentFolder)
      if (isFolderToFolder()) {
        setFolders(folders.filter((item: TFolder) => item.id !== selectedEntity?.id))
      } else {
        setFolders(folders)
      }
    }
  }, [ loadingFolders, foldersData ])

  const [
    moveItemFolder,
    {
      error: failedMovingItemFolder,
      data: moveItemFolderData,
      loading: movingItemFolder
    }
  ] = useMutation(MOVE_FOLDER)
  const movedItemFolder = moveItemFolderData?.moveItemFolder

  useEffect(() => {
    if (!movingItemFolder && (movedItemFolder || failedMovingItemFolder)) {
      moveToCallback()
    }
  }, [ movingItemFolder, movedItemFolder, failedMovingItemFolder ])

  const [
    moveItem,
    {
      error: failedMovingItem,
      data: moveItemData,
      loading: movingItem
    }
  ] = useMutation(MOVE_ITEM)
  const movedItem = moveItemData?.moveItem

  useEffect(() => {
    if (!movingItem && (movedItem || failedMovingItem)) {
      moveToCallback()
    }
  }, [ movingItem, movedItem, failedMovingItem ])

  useEffect(() => {
    setCurrentModalFolder(currentFolder)
  }, [ currentFolder ])

  const closeMoveToFolderDialog = () => {
    setCurrentModalFolder(null)
    updateProduct({ selectedEntity: null, showMoveToFolderDialog: false })
  }

  const openFolder = (folder: TFolder | null) => {
    setCurrentModalFolder(folder)
  }

  const isFolderToFolder = () => (selectedAction === MenuAction.MoveFolderToFolder)

  const moveProductTo = (folder?: TFolder | null) => {
    const newFolderID = folder?.id || ''
    const productID = selectedEntity?.id
    productID && moveItem({ variables: { productID, newFolderID } })
  }

  const moveFolderTo = (folder?: TFolder | null) => {
    const toFolderID = folder?.id || ''
    const folderID = selectedEntity?.id
    folderID && moveItemFolder({ variables: { folderID, toFolderID } })
  }

  const moveToCallback = () => {
    try {
      if (failedMovingItem) {
        throw failedMovingItem
      } else if (failedMovingItemFolder) {
        throw failedMovingItemFolder
      } else if (movedItem || movedItemFolder) {
        afterMoveToFolder()
        closeMoveToFolderDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'movedToFolder', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'movedToFolder', ns: 'product' }), error?.graphQLErrors)
    }
  }

  return (
    <>
      <MoveToFolderComponent
        folders={folders}
        currentFolder={currentFolder}
        currentModalFolder={currentModalFolder}
        openFolder={openFolder}
        moveTo={isFolderToFolder() ? moveFolderTo : moveProductTo}
        closeMoveToFolderDialog={closeMoveToFolderDialog}
        showMoveToFolderDialog={showMoveToFolderDialog}
      />

      <Toast data-testid="create-product-status" ref={toast} position="top-right" />
    </>
  )
}
