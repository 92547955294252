import { gql } from '@apollo/client'

export default gql`
  mutation changePhaseType($id: ID!, $type: String!) {
    changePhaseType(id: $id, type: $type) {
      id
      type
    }
  }
`
