import { connect } from 'react-redux'
import { renameProductAction } from '../../../../../redux/actions/global.actions'
import {
  changeProductTypeSaga,
  changeProductUnitSaga,
  renameProductSaga,
  changeProductDescriptionSaga,
  getModulesSaga,
  assignModuleToProductSaga
} from '../../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../../redux/selectors/flags.selector'
import SpaceSelector from '../../../../../redux/selectors/space.selector'
import GlobalSelector from '../../../../../redux/selectors/global.selector'
import ProductGeneralInformation from './productGeneralInformation'

const mapStateToProps = state => ({
  currentProductPageNumber: SpaceSelector.currentProductPageNumber(state),
  isShowScenarioInventory: FlagsSelector.isShowScenarioInventory(state),
  modules: GlobalSelector.modules(state),
  productLabel: GlobalSelector.productLabel(state)
})

const mapDispatchToProps = {
  renameProduct: renameProductAction,
  getModulesSaga,
  renameProductSaga,
  changeProductUnitSaga,
  changeProductTypeSaga,
  changeProductDescriptionSaga,
  assignModuleToProductSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGeneralInformation)
