import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import compose from '../../../../utils/compose'
import { TRACK_EVENT_TYPE } from '../../../../utils/const'
import { InventoryPageLocation, ObjectListPageLocation, ProductListPageLocation } from '../../../shared/locations'
import ScenarioList from './scenarioList'
import { matchLocation } from '../../../../utils/location'

class ProductScenarioDropdown extends PureComponent {

  createProductScenario = () => {
    const { entityId, copyProductSaga, trackEventSaga, addInventoryProductScenarioAction } = this.props
    copyProductSaga(entityId, productCopy => {
      if (matchLocation(InventoryPageLocation)) {
        const createdProductForScenario = { id: productCopy.id, name: productCopy.name, slug: productCopy.slug }
        addInventoryProductScenarioAction(createdProductForScenario)
      }
      this.loadProductScenario(productCopy.id)
      trackEventSaga(TRACK_EVENT_TYPE.CREATED_PRODUCT_SCENARIO)
    })
  }

  selectProductScenarioHandler = productScenarioId => {
    productScenarioId
      ? this.loadProductScenario(productScenarioId)
      : this.quitProductScenario()
  }

  getProductLocationSource = () => (
    (matchLocation(InventoryPageLocation) && InventoryPageLocation) ||
    (matchLocation(ProductListPageLocation) && ProductListPageLocation) ||
    (matchLocation(ObjectListPageLocation) && ObjectListPageLocation)
  )

  loadProductScenario(productScenarioId) {
    const { navigate, accountId, closeProductToolModal, trackEventSaga, entityId } = this.props
    const productLocationSource = this.getProductLocationSource()
    navigate(InventoryPageLocation.toUrl({
      ...productLocationSource.parseLocationParams(),
      accountId,
      productId: entityId,
      productScenarioId,
      compareEntityId: undefined,
      compareEntityType: undefined
    }))
    closeProductToolModal()
    trackEventSaga(TRACK_EVENT_TYPE.OPENED_PRODUCT_SCENARIO)
  }

  quitProductScenario = () => {
    const {
      accountId,
      quitProductScenarioSaga,
      navigate,
      closeProductToolModal,
      entityId
    } = this.props
    quitProductScenarioSaga(() => {
      const productLocationSource = this.getProductLocationSource()
      navigate(InventoryPageLocation.toUrl({
        ...productLocationSource.parseLocationParams(),
        accountId,
        productId: entityId,
        productScenarioId: undefined,
        compareEntityId: undefined,
        compareEntityType: undefined
      }))
      closeProductToolModal()
    })
  }

  render() {
    const { productScenarioId, scenarioList, t } = this.props
    return <ScenarioList
      label={ t('model.product_scenario_description') }
      value = { productScenarioId }
      scenarioList={ scenarioList }
      onChangeHandler = { this.selectProductScenarioHandler }
      createHandler={ this.createProductScenario }
    />
  }
}

ProductScenarioDropdown.propTypes = {
  closeProductToolModal: PropTypes.func.isRequired
}

export default compose(
  withRouter,
  withTranslation()
)(ProductScenarioDropdown)

