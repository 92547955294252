import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { usersSettingVar } from '../../../graphql/cache'
import { IAccount, IUser } from '../../model'
import { HAS_ACCESS } from '../../graphql/query'
import { Policy } from '../../shared/enum'
import { IUserSetting } from '../../shared/interface/user-setting'

export interface IUseAccountResult {
  loading?: boolean
  hasAccess?: any
  account?: IAccount
  refetchAccount?: Function
  refetchAccess?: Function
  emptyAccount?: boolean
}

export const useAccountQuery = (user: IUser | null): IUseAccountResult => {
  const { accountId: urlAccountId = null } = useParams()
  const [ selectedAccountId, setSelectedAccountId ] = useState<string>('')
  const policies: Policy[] = [
    Policy.AccountManagement,
    Policy.AccountRead,
    Policy.AccountWrite,
    Policy.BillingManagement
  ]
  const { loading, data: accountAccessData, refetch: refetchAccess } = useQuery(HAS_ACCESS, {
    skip: !selectedAccountId || !selectedAccountId,
    variables: {
      resourceID: selectedAccountId,
      policies
    }
  })

  const getCurrentAccountId = (accounts: IAccount[]) => {
    const usersSetting = usersSettingVar()
    const { selectedAccountId = null } = usersSetting.find((setting: IUserSetting) => setting.userId === user?.id) || {}
    if (accounts.some(account => (account.id === urlAccountId))) {
      return urlAccountId
    } if (accounts.some(account => (account.id === selectedAccountId))) {
      return selectedAccountId
    }

    return accounts[0].id
  }

  if (user && user?.accounts && user?.accounts?.length > 0) {
    const id = getCurrentAccountId(user.accounts)
    if (id && id !== selectedAccountId) {
      setSelectedAccountId(id)
    }
    const account = user.accounts.find(item => item.id === id)
    const { hasAccess } = accountAccessData || {}

    return {
      loading, account, hasAccess, refetchAccess
    }
  } if (user && user?.accounts?.length === 0) {
    return { loading: false, emptyAccount: true }
  }

  return { loading: true }
}
