import { gql } from '@apollo/client'

export default gql`
  query referenceProperties {
    referenceProperties {
      id
      name
      referenceUnits {
        id
        name
        conversionUnitID
      }
    }
  }
`
