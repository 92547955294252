import React from 'react'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { CrownOutlined, CheckOutlined } from '@ant-design/icons'
import { Row, Col, Avatar, Card, Button, Divider, Form, Input } from 'antd'
import { VALIDATION, DEFAULT, ACCOUNT_ROLES } from '../../utils/const'
import { keyboardHandler, returnNested } from '../../utils/tools'
import LeaveAccount from './leave-account/leaveAccount.container'
import RemoveAccount from './remove-account/removeAccount.container'
import DashboardContext from '../../v1/dashboard/context/dashboard.context'

class AccountManagement extends React.Component {
  static contextType = DashboardContext

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  handleSubmit = () => {
    const { renameAccountSaga, selectedAccountId, selectedAccountName, loadAccountSettingsSaga } = this.props

    const { refetchUser } = this.context

    const callback = () => {
      refetchUser()
      loadAccountSettingsSaga(selectedAccountId)
    }
    this.formRef.current.validateFields().then(values => {
      if (values.accountName === selectedAccountName) return
      renameAccountSaga(selectedAccountId, values.accountName, callback)
    })
  }

  handleEscapeKey = (evt, cb) => {
    keyboardHandler(returnNested(evt, 'key'), 'escape', cb)
  }

  onPressEscape = () => {
    this.formRef.current.resetFields()
  }

  isUserAdmin = () => {
    const { accountRolesList, selectedUserId } = this.props
    const accountAdmin = accountRolesList &&
      accountRolesList.find(el => el.label === ACCOUNT_ROLES.ADMIN)
    if (!accountAdmin) return false
    return accountAdmin.users.find(el => el.id === selectedUserId)
  }

  formRef = React.createRef()

  render() {
    const { selectedAccountId, selectedAccountName, t, isLoadingSettingCompleted } = this.props

    return (
      <div className="user-profile">
        {selectedAccountId ?
          <React.Fragment>
            {
              this.isUserAdmin() ?
                <div>
                  <Row>
                    <Col span={14}>
                      <Card className="user-profile-content">
                        <Row type="flex" justify="start" >
                          <Col span={4} className="text-center">
                            <Avatar size={DEFAULT.AVATAR_SIZE} icon={<CrownOutlined />} />
                          </Col>
                          <Col span={18}>

                            <Form
                              {...this.formItemLayout}
                              onFinish={this.handleSubmit}
                              hideRequiredMark
                              ref={this.formRef}
                              initialValues={{
                                accountName: selectedAccountName
                              }}
                            >
                              <Form.Item
                                label={t('account_settings.account_name')}
                                name="accountName"
                                rules={[
                                  { required: true, message: t('validation.missing_account_name') },
                                  { whitespace: true, message: t('validation.missing_account_name') },
                                  {
                                    min: VALIDATION.ACCOUNT_NAME_LENGTH,
                                    message: t('validation.account_name_length', { length: VALIDATION.ACCOUNT_NAME_LENGTH })
                                  },
                                ]}
                              >
                                <Input data-cy="account-name-input"
                                  onKeyUp={evt => this.handleEscapeKey(evt, this.onPressEscape)} />
                              </Form.Item>
                              <Divider />
                              <div className="text-right">
                                <Button
                                  htmlType="submit"
                                  data-cy="save-account-name"
                                  type="primary"
                                  icon={<CheckOutlined />}
                                >
                                  { t('global.save') }
                                </Button>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
                : null
            }
            <Row>
              <Col span={24}>
                <LeaveAccount/>
              </Col>
            </Row>
            {
              (this.isUserAdmin() && isLoadingSettingCompleted) ?
                <div>
                  <Row>
                    <Col span={24}>
                      <RemoveAccount/>
                    </Col>
                  </Row>
                </div>
                : null
            }
          </React.Fragment>
          : null
        }
      </div>

    )
  }
}

export default compose(
  withTranslation()
)(AccountManagement)
