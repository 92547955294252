import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Select, Tooltip } from 'antd'
import { TRACK_EVENT_TYPE } from '../../../utils/const'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { impactCategoriesVar } from '../../../graphql/cache'

const ChangeImpactCategory = props => {
  const {
    t,
    selectedSpace,
    selectedImpactMethod,
    setSpaceImpactSettingsSaga,
    trackEventSaga,
    updateInventoryItemsSaga,
    updateLifecyclesItemsSaga,
    selectedProduct
  } = props

  const {
    id,
    excludeLT,
    useMethodTotal,
    nwSet,
    impactCategory
  } = selectedSpace || {}

  const { id: selectedImpactCategoryId } = impactCategory || {}
  const impactCategories = useReactiveVar(impactCategoriesVar)

  const buildOptionsList = (value, name) =>
    <Select.Option value={value} key={value} title={name}>
      <span data-testid="selected-impact-category">{name}</span>
    </Select.Option>

  const getImpactCategoryListOptions = () => {
    return impactCategories && impactCategories.map(category => buildOptionsList(category.id, category.name))
  }

  const changeImpactCategoryHandler = impactCategoryId => {
    const { id: nwSetId, type: nwSetType } = nwSet || {}
    setSpaceImpactSettingsSaga({
      spaceId: id,
      impactMethodId: selectedImpactMethod.id,
      impactCategoryId: impactCategoryId,
      nwSetId,
      nwSetType,
      excludeLT: excludeLT,
      useMethodTotal: useMethodTotal,
      selectedProduct,
      cb: () => {
        selectedProduct ? updateInventoryItemsSaga() : updateLifecyclesItemsSaga()
        trackEventSaga(TRACK_EVENT_TYPE.CHANGED_IMPACT_SETTINGS)
      }
    })
  }

  return (
    <Tooltip
      title={ t('model.workspace_impact_settings') }
      placement="top"
      mouseEnterDelay={1}
    >
      <Select
        className="select-transparent"
        data-cy={'impact-category-global-select'}
        onChange={impactCategoryId => changeImpactCategoryHandler(impactCategoryId)}
        placeholder={t('model.select_impact_category')}
        dropdownMatchSelectWidth={false}
        bordered={false}
        value={selectedImpactCategoryId}
      >
        {getImpactCategoryListOptions()}
      </Select>
    </Tooltip>
  )
}

export { ChangeImpactCategory }
export default compose(
  withTranslation()
)(ChangeImpactCategory)
