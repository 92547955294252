import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { RESOURCE_TYPE } from '../../utils/const'
import resource from '../../utils/resource/resource'
import { returnNested, safeArray } from '../../utils/tools'
import {
  addAccountPermissionAction,
  changeAccountPermissionAction,
  removeAccountPermissionAction,
  setAccountPaidUsersCountAction,
  setAccountPermissionsAction,
  setAccountResourceTypePoliciesAction,
  setAccountRolesAction,
  setAccountUsersAction,
  setAccountV2APICredentialsAction,
  setAccountNameAction,
  addInvitedUserAction,
  removeAccountFromUserAccountListAction,
  setInvitedUsersListAction
} from '../actions/account.actions'
import { clearFlagsStateAction, clearGlobalStateExceptAccountAction, clearGlobalStateExceptUserAction } from '../actions/clear.actions'
import {
  addAccountToUser,
  setAccountInvoicesAction,
  setAccountPoliciesAction,
  setAccountSubscriptionAction,
  setSubscriptionProductsAction,
  setSelectedAccountAction,
  setSelectedAccountCustomerAction,
  removeInvitationAction,
  addInvitationRoleToUserAction,
  removeInvitationRoleFromUserAction,
  addAccountUserAction,
  removeAccountUserAction
} from '../actions/global.actions'

import { setLastSelectedAccountIdAction } from '../actions/settings.actions'

import { addSuccessNotificationAction } from '../actions/notification.actions'
import SpaceSelector from '../selectors/space.selector'
import UserSelector from '../selectors/user.selector'
import AccountSelector from '../selectors/account.selector'
import { dispatchErrors } from './helpers'
import { setSelectedSpaceSaga } from './space.saga'
import { fetchSubscriptionProducts } from './stripe.saga'
import SettingsSelector from '../selectors/settings.selector'
import { selectedAccountVar } from '../../graphql/cache'
import { setIsLoadingSettingCompletedAction } from '../actions/flags.actions'

export const
  CHANGE_V2_API_CREDENTIALS = 'CHANGE_V2_API_CREDENTIALS_SAGA',
  SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT_SAGA',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT_SAGA',
  SET_ACCOUNT_USERS_AND_ROLES = 'SET_ACCOUNT_USERS_AND_ROLES_SAGA',
  SET_ACCOUNT_SUBSCRIPTION = 'SET_ACCOUNT_SUBSCRIPTION_SAGA',
  SET_ACCOUNT_INVOICES = 'SET_ACCOUNT_INVOICES_SAGA',
  ADD_ACCOUNT_PERMISSION = 'ADD_ACCOUNT_PERMISSION_SAGA',
  SET_ACCOUNT_TRIAL_INFO = 'SET_ACCOUNT_TRIAL_INFO_SAGA',
  SET_ACCOUNT_CUSTOMER = 'SET_ACCOUNT_CUSTOMER_SAGA',
  REMOVE_ACCOUNT = 'REMOVE_ACCOUNT',
  REMOVE_ACCOUNT_PERMISSION = 'REMOVE_ACCOUNT_PERMISSION_SAGA',
  REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT_SAGA',
  REMOVE_INVITATION = 'REMOVE_INVITATION_SAGA',
  REMOVE_INVITATION_ROLE = 'REMOVE_INVITATION_ROLE_SAGA',
  RENAME_ACCOUNT = 'RENAME_ACCOUNT_SAGA',
  CHANGE_ACCOUNT_PERMISSION = 'CHANGE_ACCOUNT_PERMISSION_SAGA',
  LOAD_ACCOUNT_SETTINGS = 'LOAD_ACCOUNT_SETTINGS_SAGA',
  SET_ACCOUNT_USAGE = 'SET_ACCOUNT_USAGE_SAGA',
  SET_ACCOUNT_PAID_USERS_COUNT = 'SET_ACCOUNT_PAID_USERS_COUNT_SAGA',
  ADD_INVITATION_ROLE = 'ADD_INVITATION_ROLE_SAGA',
  UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA_SAGA',
  UPDATE_ACCOUNT_DATA_FOR_NO_ADMIN_USER = 'UPDATE_ACCOUNT_DATA_FOR_NO_ADMIN_USER_SAGA',
  INVITE_USER = 'INVITE_USER_SAGA',
  LEAVE_ACCOUNT = 'LEAVE_ACCOUNT_SAGA',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT_SAGA',
  SET_ACCOUNT_PERMISSIONS = 'SET_ACCOUNT_PERMISSIONS_SAGA'

export const changeV2APICredentialsSaga = (accountID, key, secret) => ({
  type: CHANGE_V2_API_CREDENTIALS,
  accountID, key, secret
})

export const setSelectedAccountSaga = (accountId, cb) => ({
  type: SET_SELECTED_ACCOUNT,
  accountId, cb
})
/**
 * Params for creating an account.
 * @typedef {Object} CreateAccountParams
 * @property {string} accountName
 * @property {boolean} switchToAccount
 * @property {function} cb
 */

/**
 * @param {CreateAccountParams} params
 */
export const createNewAccountSaga = params => ({
  type: CREATE_ACCOUNT,
  ...params
})

export const setAccountUsersAndRolesSaga = accountId => ({
  type: SET_ACCOUNT_USERS_AND_ROLES,
  accountId
})

export const addInvitationRoleSaga = (accountId, email, role, cb) => ({
  type: ADD_INVITATION_ROLE,
  accountId, email, role, cb
})

export const removeInvitationRoleSaga = (accountId, email, roleId, cb) => ({
  type: REMOVE_INVITATION_ROLE,
  accountId, email, roleId, cb
})

export const removeInvitationSaga = (accountId, invitationEmail, cb) => ({
  type: REMOVE_INVITATION,
  accountId, invitationEmail, cb
})

export const setAccountSubscriptionSaga = accountId => ({
  type: SET_ACCOUNT_SUBSCRIPTION,
  accountId
})

export const setAccountInvoicesSaga = accountId => ({
  type: SET_ACCOUNT_INVOICES,
  accountId
})

export const setAccountCustomerSaga = accountId => ({
  type: SET_ACCOUNT_CUSTOMER,
  accountId
})

export const addAccountPermissionSaga = (roleId, policies, accountId, resourceType, cb) => ({
  type: ADD_ACCOUNT_PERMISSION,
  roleId, policies, accountId, resourceType, cb
})

export const removeAccountPermissionSaga = permissionId => ({
  type: REMOVE_ACCOUNT_PERMISSION,
  permissionId
})

export const changeAccountPermissionSaga = (permissionId, ownerId, policies, cb) => ({
  type: CHANGE_ACCOUNT_PERMISSION,
  permissionId, ownerId, policies, cb
})

export const loadAccountSettingsSaga = accountId => ({
  type: LOAD_ACCOUNT_SETTINGS,
  accountId
})

export const selectedAccountPaidUsersCountSaga = accountId => ({
  type: SET_ACCOUNT_PAID_USERS_COUNT,
  accountId
})

export const updateAccountDataSaga = accountId => ({
  type: UPDATE_ACCOUNT_DATA,
  accountId
})

export const updateAccountDataForNoAdminUserSaga = accountId => ({
  type: UPDATE_ACCOUNT_DATA_FOR_NO_ADMIN_USER,
  accountId
})

export const renameAccountSaga = (accountId, accountName, cb) => ({
  type: RENAME_ACCOUNT,
  accountId, accountName, cb
})

export const leaveAccountSaga = (accountId, nextAccountId, cb) => ({
  type: LEAVE_ACCOUNT,
  accountId, nextAccountId, cb
})

export const switchAccountSaga = (accountId, cb) => ({
  type: SWITCH_ACCOUNT,
  accountId, cb
})

export const inviteUserSaga = (accountId, userEmail, userName, userRoles, cb) => ({
  type: INVITE_USER,
  accountId, userEmail, userName, userRoles, cb
})

export const removeUserFromAccountSaga = (accountId, userId, cb) => ({
  type: REMOVE_USER_FROM_ACCOUNT,
  accountId, userId, cb
})

export const removeAccountSaga = (accountId, nextAccountId, cb) => ({
  type: REMOVE_ACCOUNT,
  accountId, nextAccountId, cb
})

export const setAccountPermissionsSaga = accountId => ({
  type: SET_ACCOUNT_PERMISSIONS,
  accountId
})

/**
 * @param {{accountID: string, key: string, secret: string}} action
 */
function* updateV2APICredentials(action) {
  try {
    const changeV2APICredentialsArgs = {
      accountID: action.accountID,
      key: action.key,
      secret: action.secret
    }
    yield call(resource.mutateByParams, 'changeV2APICredentials', changeV2APICredentialsArgs)
    yield put(setAccountV2APICredentialsAction(action.key, action.secret))
    yield put(addSuccessNotificationAction('account_settings.v2_api_credentials_saved'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string, cb: function}} action
 */
export function* setSelectedAccount(action) {

  try {
    const account = yield select(UserSelector.userAccountById, action.accountId)
    if (account) {
      const { hasAccess: policies } = selectedAccountVar() || {}
      // const policies = yield call(resource.queryByParams, 'hasAccess', {
      //   resourceID: action.accountId,
      //   policies: [
      //     POLICY.ACCOUNT_MANAGEMENT,
      //     POLICY.ACCOUNT_READ,
      //     POLICY.ACCOUNT_WRITE,
      //     POLICY.BILLING_MANAGEMENT
      //   ]
      // })

      const accountSpaces = yield select(UserSelector.accountSpaces, action.accountId)
      const selectedSpaceId = yield select(SpaceSelector.spaceId)
      const lastSelectedSpaceId = yield select(SettingsSelector.lastSelectedSpaceId)
      const isSelectedSpaceInAccount = safeArray(accountSpaces).some(space => space.id === selectedSpaceId)
      const isLastSelectedSpaceInAccount = safeArray(accountSpaces).some(space => space.id === lastSelectedSpaceId)

      yield put(setAccountPoliciesAction(policies))
      yield put(setSelectedAccountAction(account))

      const spaceIdToSelect = isSelectedSpaceInAccount ? selectedSpaceId
        : isLastSelectedSpaceInAccount ? lastSelectedSpaceId
          : returnNested(accountSpaces, 0, 'id')

      const hasAccountActiveSubscription = yield select(AccountSelector.hasAccountActiveSubscription)
      if (hasAccountActiveSubscription && spaceIdToSelect) {
        yield put(setSelectedSpaceSaga(spaceIdToSelect))
      }
      action.cb && action.cb()
    }
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export function* fetchAccountCustomer(action) {
  try {
    const customer  = yield call(resource.queryByParams, 'subscriptionCustomer', { accountID: action.accountId })
    yield put(setSelectedAccountCustomerAction(customer))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {CreateAccountParams} action
 */
function* createNewAccount(action) {
  try {
    const account = yield call(resource.mutateByParams, 'createAccount', { name: action.accountName })
    yield put(addAccountToUser(account))
    if (action.switchToAccount) {
      const userId = yield select(UserSelector.selectedUserId)
      yield put(clearFlagsStateAction())
      clearGlobalStateExceptAccountAction()
      yield put(setSelectedAccountSaga(account.id))
      yield put(setLastSelectedAccountIdAction(userId, account.id))
    }
    action.cb && action.cb(account.id)
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* setAccountUsersAndRoles(action) {
  try {
    const [
      roles,
      users,
      accountResourceTypePolicies,
    ] = yield all([
      call(resource.queryByParams, 'accountRoles', { id: action.accountId }),
      call(resource.queryByParams, 'accountUsers', { id: action.accountId }),
      call(resource.queryByParams, 'resourceTypePolicies', { resourceType: RESOURCE_TYPE.ACCOUNT })
    ])

    yield put(setAccountRolesAction(roles))
    yield put(setAccountUsersAction(users))
    yield put(setAccountResourceTypePoliciesAction(accountResourceTypePolicies))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* setAccountPermissions(action) {
  try {
    const permissions = yield call(resource.queryByParams, 'accountPermissions', { id: action.accountId })
    yield put(setAccountPermissionsAction(permissions))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* setAccountSubscription(action) {
  try {
    const subscription = yield call(resource.queryByParams, 'subscription', { accountID: action.accountId })
    yield put(setAccountSubscriptionAction(subscription))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeInvitationHandler(action) {
  try {
    yield call(resource.mutateByParams, 'removeInvitation', { accountID: action.accountId, email: action.invitationEmail })
    yield put(removeInvitationAction(action.invitationEmail))
    yield put(addSuccessNotificationAction('account_settings.user_deleted_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}
/**
 * @param {{accountId: string}} action
 */
function* fetchAccountPaidUsersCount(action) {
  try {
    const count  = yield call(resource.queryByParams, 'accountPaidUsersCount', { id: action.accountId })
    yield put(setAccountPaidUsersCountAction(count))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* setAccountInvoices(action) {
  try {
    const invoices = yield call(resource.queryByParams, 'invoices', { accountID: action.accountId })
    yield put(setAccountInvoicesAction(invoices))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* addAccountPermission(action) {
  try {
    const createPermissionArgs = {
      permitteeID: action.roleId,
      policies: action.policies,
      resourceID: action.accountId,
      resourceType: action.resourceType
    }
    const permissions = yield call(resource.mutateByParams, 'createPermission', createPermissionArgs)
    yield put(addAccountPermissionAction(permissions))
    yield put(addSuccessNotificationAction('model.add_user_permission_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* removeAccountPermission(action) {
  try {
    yield call(resource.mutateByParams, 'removePermission', { permissionID: action.permissionId })
    yield put(removeAccountPermissionAction(action.permissionId))
    yield put(addSuccessNotificationAction('model.remove_user_permission_success'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* changeAccountPermission(action) {
  try {
    const changePermissionArgs = {
      permissionID: action.permissionId,
      permitteeID: action.ownerId,
      policies: action.policies
    }
    const permissions = yield call(resource.mutateByParams, 'changePermission', changePermissionArgs)
    yield put(changeAccountPermissionAction(permissions))
    yield put(addSuccessNotificationAction('model.update_account_permission_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {{accountId: string}} action
 */
function* loadAccountSettings(action) {
  try {
    // setAccountSubscription takes on average 4 sec
    // We prevent seeing possible plans until we are sure we dont have a subscription
    const accountForInvitedUsers = yield call(resource.queryByParams, 'account', { id: action.accountId }, 'accountInvitedUsers')
    yield put(setInvitedUsersListAction(accountForInvitedUsers?.invitedUsers))
    setSubscriptionProductsAction([])
    yield put(setIsLoadingSettingCompletedAction(false))
    yield call(setAccountSubscription, setAccountSubscriptionSaga(action.accountId))
    yield all([
      call(setAccountInvoices, setAccountInvoicesSaga(action.accountId)),
      call(fetchAccountCustomer, setAccountCustomerSaga(action.accountId)),
      call(fetchSubscriptionProducts),
      call(fetchAccountPaidUsersCount, selectedAccountPaidUsersCountSaga(action.accountId))
    ])
    yield put(setIsLoadingSettingCompletedAction(true))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* addInvitationRoleHandler(action) {
  try {
    const addInvitationRoleArgs = {
      accountID: action.accountId,
      email: action.email,
      roleID: action.role.id
    }
    yield call(resource.mutateByParams, 'addInvitationRole', addInvitationRoleArgs)
    yield put(addInvitationRoleToUserAction(action.email, action.role))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeInvitationRoleHandler(action) {
  try { //accountId, email, role
    const removeInvitationRoleArgs = {
      accountID: action.accountId,
      email: action.email,
      roleID: action.roleId
    }
    yield call(resource.mutateByParams, 'removeInvitationRole', removeInvitationRoleArgs)
    yield put(removeInvitationRoleFromUserAction(action.email, action.roleId))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* updateAccountDataHandler(action) {
  try {
    const selectedAccountId = yield select(AccountSelector.selectedAccountId)
    const accountId = action.accountId || selectedAccountId
    if (accountId) {
      yield call(setAccountUsersAndRolesSaga, accountId),
      yield call(setSelectedAccountSaga, accountId)
    }
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* updateAccountDataForNoAdminUserHandler(action) {
  try {
    const selectedAccountId = yield select(AccountSelector.selectedAccountId)
    const accountId = action.accountId || selectedAccountId
    if (accountId) {
      yield put(setSelectedAccountSaga(accountId))
    }
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* renameAccountHandler(action) {
  try {
    const account = yield call(resource.mutateByParams, 'renameAccount', { accountID: action.accountId, name: action.accountName })
    yield put(setAccountNameAction(account.id, account.name))
    yield put(addSuccessNotificationAction('model.rename_account_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* inviteUser(action) {
  try {
    const addUserArgs = {
      accountID: action.accountId,
      email: action.userEmail,
      name: action.userName,
      roleIDs: action.userRoles
    }
    const user = yield call(resource.mutateByParams, 'addUser', addUserArgs)
    if (user) {
      yield put(addAccountUserAction(user))
    } else {
      const accountRolesList = yield select(AccountSelector.accountRolesList)
      const notExistingUser = {
        isInvited: true,
        email: action.userEmail,
        name: action.userName,
        roles: safeArray(accountRolesList).filter(role => safeArray(action.userRoles).includes(role.id))
      }
      yield put(addInvitedUserAction(notExistingUser))
    }
    yield put(selectedAccountPaidUsersCountSaga(action.accountId))
    yield put(addSuccessNotificationAction('account_settings.user_added_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeUserFromAccountHandler(action) {
  try {
    yield call(resource.mutateByParams, 'removeUserFromAccount', { accountID: action.accountId, userID: action.userId })

    yield put(removeAccountUserAction(action.userId))
    yield put(selectedAccountPaidUsersCountSaga(action.accountId))
    yield put(addSuccessNotificationAction('account_settings.user_deleted_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* leaveAccountHandler(action) {
  try {
    yield call(resource.mutateByParams, 'leaveAccount', { accountID: action.accountId })
    yield all([
      put(removeAccountFromUserAccountListAction(action.accountId)),
      put(clearGlobalStateExceptUserAction()),
      put(clearFlagsStateAction()),
      put(addSuccessNotificationAction('account_settings.user_left_account_success'))
    ])
    yield put(setSelectedAccountSaga(action.nextAccountId))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeAccountHandler(action) {
  try {
    const userAccounts = yield select(UserSelector.userAccounts)
    yield call(resource.mutateByParams, 'removeAccount', { accountID: action.accountId })
    yield all([
      put(removeAccountFromUserAccountListAction(action.accountId)),
      put(clearGlobalStateExceptUserAction()),
      put(clearFlagsStateAction()),
    ])
    if (userAccounts.length > 1) yield put(addSuccessNotificationAction('account_settings.user_removed_account_success'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* switchAccountHandler(action) {
  try {
    const selectedUserId = yield select(UserSelector.selectedUserId)
    yield put(clearGlobalStateExceptUserAction())
    yield put(clearFlagsStateAction())
    yield put(setLastSelectedAccountIdAction(selectedUserId, action.accountId))
    yield put(setSelectedAccountSaga(action.accountId))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export default function* accountSaga() {
  yield takeLatest(CHANGE_V2_API_CREDENTIALS, updateV2APICredentials)
  yield takeLatest(SET_SELECTED_ACCOUNT, setSelectedAccount)
  yield takeLatest(CREATE_ACCOUNT, createNewAccount)
  yield takeLatest(SET_ACCOUNT_USERS_AND_ROLES, setAccountUsersAndRoles)
  yield takeLatest(SET_ACCOUNT_SUBSCRIPTION, setAccountSubscription)
  yield takeLatest(SET_ACCOUNT_INVOICES, setAccountInvoices)
  yield takeLatest(ADD_ACCOUNT_PERMISSION, addAccountPermission)
  yield takeLatest(SET_ACCOUNT_CUSTOMER, fetchAccountCustomer)
  yield takeLatest(REMOVE_ACCOUNT_PERMISSION, removeAccountPermission)
  yield takeLatest(CHANGE_ACCOUNT_PERMISSION, changeAccountPermission)
  yield takeLatest(LOAD_ACCOUNT_SETTINGS, loadAccountSettings)
  yield takeLatest(SET_ACCOUNT_PAID_USERS_COUNT, fetchAccountPaidUsersCount)
  yield takeLatest(REMOVE_INVITATION, removeInvitationHandler)
  yield takeLatest(ADD_INVITATION_ROLE, addInvitationRoleHandler)
  yield takeLatest(REMOVE_INVITATION_ROLE, removeInvitationRoleHandler)
  yield takeLatest(UPDATE_ACCOUNT_DATA, updateAccountDataHandler)
  yield takeLatest(UPDATE_ACCOUNT_DATA_FOR_NO_ADMIN_USER, updateAccountDataForNoAdminUserHandler)
  yield takeLatest(RENAME_ACCOUNT, renameAccountHandler)
  yield takeLatest(INVITE_USER, inviteUser)
  yield takeLatest(REMOVE_USER_FROM_ACCOUNT, removeUserFromAccountHandler)
  yield takeLatest(REMOVE_ACCOUNT, removeAccountHandler)
  yield takeLatest(LEAVE_ACCOUNT, leaveAccountHandler)
  yield takeLatest(SWITCH_ACCOUNT, switchAccountHandler)
  yield takeLatest(SET_ACCOUNT_PERMISSIONS, setAccountPermissions)
}
