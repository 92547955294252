import { connect } from 'react-redux'
import ChangePhase from './changePhase'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import {
  renamePhaseSaga,
  changePhaseTypeSaga
} from '../../../../redux/sagas/lifecycle.saga'

const mapStateToProps = state => ({
  selectedPhase: LifecycleSelector.selectedPhase(state)
})

const mapDispatchToProps = {
  renamePhaseSaga,
  changePhaseTypeSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePhase)
