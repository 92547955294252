import { all, call, cancelled, delay, put, select, takeLatest } from 'redux-saga/effects'
import ComparisonFlatView from '../../utils/comparisonFlatView'
import { EntityType, SAGA } from '../../utils/const'
import resource from '../../utils/resource/resource'
import { returnNested, safeArray } from '../../utils/tools'

import {
  setComparisonEntitiesAction,
  setComparisonFlatViewAction,
  setEntityToCompareAction,
  setIsComparisonEntriesLoadingAction
} from '../actions/comparison.actions'
import { setIsLoadingRightTreeAction } from '../actions/loading.actions'
import ComparisonSelector from '../selectors/comparison.selector'
import FlagsSelector from '../selectors/flags.selector'
import { InventoryTreeSelector } from '../selectors/inventoryTree.selector'
import { LifecycleSelector } from '../selectors/lifecycle.selector'
import SpaceSelector from '../selectors/space.selector'
import { dispatchErrors, getLifecycleEntity, getProductEntity } from './helpers'

export const LOAD_COMPARISON_ENTITIES = 'LOAD_COMPARISON_ENTITIES_SAGA'

export const getComparisonEntitiesSaga = query => ({
  type: LOAD_COMPARISON_ENTITIES,
  query
})

export function* getComparisonEntity(entityId, entityType) {
  yield put(setIsLoadingRightTreeAction(true))

  try {
    const entity = yield entityType === EntityType.PRODUCT
      ? call(getProductEntity, entityId)
      : call(getLifecycleEntity, entityId)

    yield put(setEntityToCompareAction(entity))
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingRightTreeAction(false))
  }
}

/**
 * @param {{Entity}} entityA
 * @param {{Entity}} entityB
 * @returns {IterableIterator<*>}
 */
export function* mapCompareEntities(entityA, entityB) {
  try {
    const entityIsInList = yield select(ComparisonSelector.findById, returnNested(entityB, 'id'))
    if (!entityIsInList && entityB && entityB.id) {
      const { id, name, amount, unit, __typename } = entityB
      yield put(setComparisonEntitiesAction([ { id, name, amount, unit, __typename } ]))
    }
    const skipPhases = yield select(FlagsSelector.isSkipPhases)
    const comparisonFlatView = new ComparisonFlatView(entityA, entityB)
    const flatView = skipPhases
      ? comparisonFlatView.flatViewWithoutPhases()
      : comparisonFlatView.flatViewWithPhases()
    yield put(setComparisonFlatViewAction(flatView))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* loadComparisonEntities(action) {
  try {
    yield delay(SAGA.DEBOUNCE_MS)
    const { query } = action
    const spaceId = yield select(SpaceSelector.spaceId)
    let productId = yield select(InventoryTreeSelector.productId)
    if (!productId) {
      productId = yield select(LifecycleSelector.productId)
    }

    yield put(setIsComparisonEntriesLoadingAction(true))
    let [ rawProducts, rawLifecycles ] = yield all([
      call(resource.cancellableQueryByParams, 'products', { spaceID: spaceId, query }),
      call(resource.cancellableQueryByParams, 'lifecycles', { spaceID: spaceId, query })
    ])

    const mapEntry = entry => ({
      id: entry.id,
      name: entry.name,
      amount: entry.amount,
      unit: entry.unit,
      labels: safeArray(entry.labels),
      __typename: entry.__typename
    })
    const lifecycles = safeArray(rawLifecycles).map(mapEntry)
    const products = safeArray(returnNested(rawProducts, 'items')).map(mapEntry)

    yield put(setComparisonEntitiesAction(products.concat(lifecycles)))
    yield put(setIsComparisonEntriesLoadingAction(false))

  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsComparisonEntriesLoadingAction(false))
    if (yield cancelled()) {
      // TODO MOB-3533 find a way to call cancel function of abortable graphql queries
      // rawProducts && rawProducts.cancel()
      // rawLifecycles && rawLifecycles.cancel()
    }
  }
}

export default function* comparisonSaga() {
  yield takeLatest(LOAD_COMPARISON_ENTITIES, loadComparisonEntities)
}
