import { gql } from '../../../__generated__'

export const LIST_ITEM_FOLDERS = gql(`
  query listItemFolders($spaceID: ID!, $labels: [ID!]!, $folderID: ID)
  {
    listItemFolders(spaceID: $spaceID, labels: $labels, folderID: $folderID)
    {
      items {
        ... on Folder {
          id
          name
        }
      }
      total
      currentFolder {
        id
        name
        parent {
          id
          name
        }
      }
    }
  }
`)
