import { gql } from '@apollo/client'
import subscription from '../fragment/subscription'

export default gql`
  query subscription($accountID: ID!){
      subscription(accountID: $accountID){
          ...subscriptionFragment
      }
  }
  ${subscription}
`
