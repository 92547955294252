export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME'
export const REMOVE_ACCOUNT_FROM_USER_ACCOUNT_LIST = 'REMOVE_ACCOUNT_FROM_USER_ACCOUNT_LIST'
export const SET_INVITED_USERS_LIST = 'SET_INVITED_USERS_LIST'

export const addSpaceToAccountAction = space => ({
  type: 'ADD_SPACE_TO_ACCOUNT',
  space
})

export const setAccountAction = account => ({
  type: 'SET_ACCOUNT',
  account
})

export const setAccountV2APICredentialsAction = (key, secret) => ({
  type: 'SET_ACCOUNT_V2_API_CREDENTIALS',
  key, secret
})

export const addAccountPermissionAction = accountPermission => ({
  type: 'ADD_ACCOUNT_PERMISSION',
  accountPermission
})

export const removeAccountPermissionAction = accountPermissionId => ({
  type: 'REMOVE_ACCOUNT_PERMISSION',
  accountPermissionId
})

export const changeAccountPermissionAction = accountPermission => ({
  type: 'CHANGE_ACCOUNT_PERMISSION',
  accountPermission
})

export const addAccountRoleAction = accountRole => ({
  type: 'ADD_ACCOUNT_ROLE',
  accountRole
})

export const removeAccountRoleAction = accountRoleId => ({
  type: 'REMOVE_ACCOUNT_ROLE',
  accountRoleId
})

export const addAccountUserToRoleAction = (roleId, accountUserId, accountUserName) => ({
  type: 'ADD_ACCOUNT_USER_TO_ROLE',
  roleId, accountUserId, accountUserName
})

export const removeAccountUserFromRoleAction = (roleId, accountUserId) => ({
  type: 'REMOVE_ACCOUNT_USER_FROM_ROLE',
  roleId, accountUserId
})

export const addAccountApiClientToRoleAction = (roleId, accountApiClientId, accountApiClientName) => ({
  type: 'ADD_ACCOUNT_API_CLIENT_TO_ROLE_ACTION',
  roleId, accountApiClientId, accountApiClientName
})

export const removeAccountApiClientFromRoleAction = (roleId, accountApiClientId) => ({
  type: 'REMOVE_ACCOUNT_API_CLIENT_FROM_ROLE_ACTION',
  roleId, accountApiClientId
})

export const setAccountRolesAction = accountRoles => ({
  type: 'SET_ACCOUNT_ROLES',
  accountRoles
})

export const setAccountResourceTypePoliciesAction = resourceType => ({
  type: 'SET_ACCOUNT_RESOURCE_TYPE_POLICIES_LIST',
  resourceType
})

export const setAccountApiClientsAction = accountApiClients => ({
  type: 'SET_ACCOUNT_API_CLIENTS',
  accountApiClients
})

export const setAccountUsersAction = accountUsers => ({
  type: 'SET_ACCOUNT_USERS',
  accountUsers
})

export const setAccountPermissionsAction = accountPermissions => ({
  type: 'SET_ACCOUNT_PERMISSIONS',
  accountPermissions
})

export const addAccountApiClientAction = accountApiClient => ({
  type: 'ADD_ACCOUNT_API_CLIENT',
  accountApiClient
})

export const removeAccountApiClientAction = apiClientId => ({
  type: 'REMOVE_ACCOUNT_API_CLIENT',
  apiClientId
})

export const setAccountUsageAction = usage => ({
  type: 'SET_ACCOUNT_USAGE',
  usage
})

export const setAccountPaidUsersCountAction = count => ({
  type: 'SET_ACCOUNT_PAID_USERS_COUNT',
  count
})

export const addInvitedUserAction = user => ({
  type: 'ADD_INVITED_USER_ACTION',
  user
})

export const setAccountNameAction = (accountId, accountName) => ({
  type: SET_ACCOUNT_NAME,
  accountId, accountName
})

export const removeAccountFromUserAccountListAction = accountId => ({
  type: REMOVE_ACCOUNT_FROM_USER_ACCOUNT_LIST,
  accountId
})

export const setInvitedUsersListAction = invitedUsersList => ({
  type: SET_INVITED_USERS_LIST,
  invitedUsersList
})
