import { gql } from '../../../__generated__'

export const REMOVE_PRODUCT_REFERENCE = gql(`
  mutation removeProductReference($productID: ID!) {
    removeProductReference(productID: $productID){
        ...extendedProductFragment
    }
  }
`)

export const CHANGE_PRODUCT_REFERENCE = gql(`
  mutation changeProductReference($productID: ID!, $referenceProductID: String!) {
    changeProductReference(productID: $productID, referenceProductID: $referenceProductID){
      ...extendedProductFragment
    }
  }
`)
