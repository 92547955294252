import { connect } from 'react-redux'
import { copyLifecycleSaga } from '../../../../redux/sagas/lifecycle.saga'
import { quitLifecycleScenarioSaga } from '../../../../redux/sagas/lifecycleScenario.saga'
import { trackEventSaga, } from '../../../../redux/sagas/tracking.saga'
import { LifecycleSelector, LifecycleScenarioSelector } from '../../../../redux/selectors/lifecycle.selector'
import LifecycleScenarioDropdown from './lifecycleScenarioDropdown'

const mapStateToProps = state => ({
  lifecycleId: LifecycleSelector.lifecycleId(state),
  scenarioList: LifecycleSelector.scenarios(state),
  lifecycleScenarioId: LifecycleScenarioSelector.lifecycleId(state),
  selectedLifecycle: LifecycleSelector.selectedLifecycle(state)
})

const mapDispatchToProps = {
  copyLifecycleSaga,
  trackEventSaga,
  quitLifecycleScenarioSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleScenarioDropdown)
