import { useCallback, useContext } from 'react'
import { IDashboardContext } from '../interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

export type TErrorMessage = {
  extensions?: any
  message?: string
  path?: any
}

type ToastTranslationText = {
  context?: string, label: string, ns?: string
}

type HandleResponseParams = {
  error?: any,
  data?: any,
  callback?: Function,
  successToastSummary?: ToastTranslationText,
  successToastDetail: ToastTranslationText,
  errorToastSummary?: ToastTranslationText,
  afterSuccessToastShown?: Function,
  afterSuccessToastHidden?: Function
}

export const useHandleMutationResponse = (t: Function) => {
  const { notifyToastPrime } = useContext<IDashboardContext>(DashboardContext)

  const showErrorToastMessages = useCallback((summary: ToastTranslationText, errors: Readonly<TErrorMessage[]> = []) => {
    if (notifyToastPrime && errors) {
      errors.forEach(({ message, extensions }) => {
        const { code: context = null, args = {} } = extensions || {}

        let detail = ''
        if (context) {
          detail = t && t('messages.error', { context, ns: 'common', ...args })
        } else if (typeof message === 'string') {
          detail = message
        }

        notifyToastPrime.show({
          severity: 'error',
          detail,
          life: 3000,
          summary: t(summary.label, { context: summary.context, ns: summary.ns })
        })
      })
    }
  }, [])

  const handleResponse = ({
    error,
    data,
    callback = () => {},
    successToastDetail,
    successToastSummary = { label: 'messages.successSummary', ns: 'common' },
    errorToastSummary = { label: 'messages.errorSummary', ns: 'common' }
  }: HandleResponseParams) => {
    if (error) {
      showErrorToastMessages(errorToastSummary, error?.graphQLErrors)
    } else if (data) {
      callback(data)
      if (notifyToastPrime) {
        notifyToastPrime.show({
          severity: 'success',
          summary: t(successToastSummary.label, { context: successToastSummary.context, ns: successToastSummary.ns }),
          detail: t(successToastDetail.label, { context: successToastDetail.context, ns: successToastDetail.ns })
        })
      }
    }
  }

  return { handleResponse }
}
