import React, { useContext, useEffect } from 'react'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'

import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { useLifecycleInsightQuery } from '../../shared/hook/query/use-lifecycle-insight.query'
import { transformLifecycleLeafItems } from '../../shared/util/transform-lifecycle'

type TLifecycleInsightContainerProps = {
  lifecycleId?: string,
  children?: React.ReactNode
}
export const LifecycleInsightContainer = ({
  children,
  lifecycleId = ''
}: TLifecycleInsightContainerProps) => {
  const {
    lifecycleTotalImpact,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    loading: loadingLifecycleLeafItems,
    data: lifecycleLeafItemsData,
    refetch: refetchLifecycleLeafItems
  } = useLifecycleInsightQuery(lifecycleId)

  const { lifecycle: lifecycleLeafItems = null } = lifecycleLeafItemsData || {}

  useEffect(() => {
    if (!loadingLifecycleLeafItems && lifecycleLeafItems) {
      const transformedLifecycleLeafItems = transformLifecycleLeafItems(lifecycleLeafItems, lifecycleTotalImpact)
      updateLifecycleDetail({
        transformedLifecycleLeafItems,
        lifecycleLeafItems,
        loadingLifecycleLeafItems,
        refetchLifecycleLeafItems
      })
    } else {
      updateLifecycleDetail({
        loadingLifecycleLeafItems
      })
    }
  }, [ loadingLifecycleLeafItems ])

  return (
    <>
      { loadingLifecycleLeafItems && <ProgressSpinnerComponent dataTestId="loading-product-detail" size={2} />}

      { !loadingLifecycleLeafItems && children }
    </>
  )
}
