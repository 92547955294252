import React, { useState, useRef, useEffect, useContext } from 'react'
import compose from '../../../../../utils/compose'
import { Select, Form } from 'antd'
import { returnNested } from '../../../../../utils/tools'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../../utils/with-router'
import PropTypes from 'prop-types'
import { VALIDATION, PRODUCT_TYPE } from '../../../../../utils/const'
import { ProductInventoryProperties } from '../product-inventory-properties/productInventoryProperties'
import SelectWrapper from '../../../../helpers/form/selectWrapper'
import InputWrapper from '../../../../helpers/form/inputWrapper'
import { ObjectListPageLocation, ProductListPageLocation } from '../../../../shared/locations'
import { matchLocation } from '../../../../../utils/location'
import { DetailsPanelCard } from '../../detailPanelCard'
import ProductDetailContext from '../../../../../v1/product/provider/context/product-detail.context'
import { AnalysisMode } from '../../../../../v1/analysis/enum/analysis'

const ProductGeneralInformation = props => {
  const {
    t,
    getModulesSaga,
    selectedProduct,
    isDetailsPanelReadOnly,
    isShowScenarioInventory,
    modules,
    productLabel,
    renameProductSaga,
    productMutator,
    updatePageItems,
    currentProductPageNumber,
    changeProductUnitSaga,
    changeProductDescriptionSaga,
    changeProductTypeSaga,
    assignModuleToProductSaga
  } = props

  useEffect(() => {
    if (!modules || !modules.length) {
      getModulesSaga()
    }
  }, [])

  const [ isEditName, setIsEditName ] = useState(false)
  const [ isEditUnit, setIsEditUnit ] = useState(false)
  const [ isEditType, setIsEditType ] = useState(false)
  const [ isEditDescription, setIsEditDescription ] = useState(false)
  const [ isEditModule, setIsEditModule ] = useState(false)
  const { analysisMode } = useContext(ProductDetailContext)
  const analysisInScenarioModeV1 = analysisMode === AnalysisMode.Scenario

  const formRef = useRef()
  const { description } = selectedProduct
  const { unit } = selectedProduct
  const initialValues = {
    productName: returnNested(selectedProduct, 'name'),
    productUnit: unit && unit !== '' ? unit : '-',
    productType: returnNested(selectedProduct, 'type'),
    productDescription: description && description !== '' ? description : '-',
    modules: returnNested(selectedProduct, 'module', 'code')
  }

  const handleSaveName = () => {
    const withProductListUpdate = matchLocation(ProductListPageLocation, true) || matchLocation(ObjectListPageLocation, true)
    const { validateFields, getFieldValue } = formRef.current
    if (returnNested(selectedProduct, 'name') === getFieldValue('productName')) return resetEditFields()

    validateFields([ 'productName' ]).then(values => {
      resetEditFields()
      renameProductSaga({
        productMutator,
        productId: selectedProduct.id,
        name: values.productName,
        currentPage: currentProductPageNumber,
        labels: [ productLabel ],
        withProductListUpdate,
        cb: () => {
          updatePageItems()
        }
      })
    })
  }

  const handleSaveUnit = () => {
    const { validateFields, getFieldValue } = formRef.current

    if (returnNested(selectedProduct, 'unit') === getFieldValue('productUnit')) return resetEditFields()
    validateFields([ 'productUnit' ]).then(values => {
      resetEditFields()
      changeProductUnitSaga(productMutator, selectedProduct.id, values.productUnit, () => {
        updatePageItems()
      })
    })
  }

  const handleSaveDescription = () => {
    const { validateFields, getFieldValue } = formRef.current

    if (returnNested(selectedProduct, 'description') === getFieldValue('productDescription')) return resetEditFields()

    validateFields([ 'productDescription' ]).then(values => {
      resetEditFields()
      changeProductDescriptionSaga(selectedProduct.id, values.productDescription, () => updatePageItems())
    })
  }

  const handleSaveType = () => {

    formRef.current.validateFields([ 'productType' ]).then(values => {
      changeProductTypeSaga(productMutator, selectedProduct.id, values.productType, () => {
        updatePageItems()
        resetEditFields()
      })
    })
  }

  const handleSaveModule = () => {
    formRef.current.validateFields([ 'modules' ]).then(values => {
      if (!selectedProduct.module && !values.modules) return
      if ( returnNested(selectedProduct, 'module', 'code') !== values.modules ) {
        assignModuleToProductSaga({
          productID: selectedProduct.id,
          module: values.modules,
          cb: () => updatePageItems()
        })
      }
    })
  }

  const resetEditFields = () => {
    setIsEditName(false)
    setIsEditUnit(false)
    setIsEditType(false)
    setIsEditModule(false)
    setIsEditDescription(false)
  }

  const onPressEscape = () => {
    formRef.current.resetFields()
    resetEditFields()
  }

  const getModuleName = moduleItem => {
    return moduleItem && moduleItem.code ? `${moduleItem.code}: ${t(`modules.${moduleItem.code}`)}` : `${t('global.no_module')}`
  }

  return <DetailsPanelCard
    className="product-detail-general-info"
    title={t('model.product_general_info')}>
    <Form
      ref={formRef}
      layout="vertical"
      hideRequiredMark
      className="field-container"
      initialValues={initialValues}>
      <InputWrapper
        isFieldEditable={isEditName}
        dataCy='product-name'
        displayedText={initialValues.productName }
        disabled={ isDetailsPanelReadOnly }
        editHandler={() => setIsEditName(true)}
        fieldLabel= {t('global.name')}
        fieldName= "productName"
        fieldRules= {[
          { min: VALIDATION.PRODUCT_NAME_LENGTH, message: t('validation.product_name', { name: 'Product name' }) },
          { required: true, message: t('validation.is_required', { name: 'Product name' }) }
        ]}
        onBlur={ handleSaveName }
        onPressEnter={ handleSaveName }
        onPressEscape={ onPressEscape }
      />
      <InputWrapper
        isFieldEditable={isEditUnit}
        dataCy='product-unit'
        displayedText={initialValues.productUnit }
        disabled={ isDetailsPanelReadOnly }
        editHandler={() => setIsEditUnit(true)}
        fieldLabel= {t('global.unit')}
        fieldName= "productUnit"
        fieldRules= {[
          { required: true, message: t('validation.is_required', { name: 'Product Unit' }) },
          { validator: async (rule, value) => {
            if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
              throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Unit' }))
            }
          } }
        ]}
        onBlur={ handleSaveUnit }
        onPressEnter={ handleSaveUnit }
        onPressEscape={ onPressEscape }
      />
      <InputWrapper
        type='textarea'
        isFieldEditable={isEditDescription}
        dataCy='product-description'
        displayedText={initialValues.productDescription }
        disabled={ isDetailsPanelReadOnly }
        editHandler={() => setIsEditDescription(true)}
        fieldLabel= {t('global.description')}
        fieldName= "productDescription"
        onBlur={ handleSaveDescription }
        onPressEnter={ handleSaveDescription }
        onPressEscape={ onPressEscape }
      />
      <div className='select-wrapper'>
        <SelectWrapper
          isFieldEditable={isEditType}
          dataCy= {'product-type'}
          displayedText={initialValues.productType === PRODUCT_TYPE.WASTE ? t('model.product_type_waste') : t('model.product_type_normal') }
          disabled={ isDetailsPanelReadOnly || isShowScenarioInventory || analysisInScenarioModeV1 }
          editHandler={() => setIsEditType(true)}
          fieldLabel= {t('global.type')}
          fieldName= "productType"
          fieldRules= {[
            { required: true, message: t('validation.is_required', { name: 'Product Type' }) }
          ]}
          onSelect={handleSaveType}
          onBlur={resetEditFields}
          onPressEscape={ onPressEscape }
        >
          <Select.Option value={PRODUCT_TYPE.NORMAL}>{ t('model.product_type_normal') }</Select.Option>
          <Select.Option value={PRODUCT_TYPE.WASTE}>{ t('model.product_type_waste') }</Select.Option>
        </SelectWrapper>
      </div>
      <div className='select-wrapper'>
        <SelectWrapper
          isFieldEditable={isEditModule}
          editHandler={() => setIsEditModule(true)}
          dataCy='modules'
          displayedText={getModuleName(selectedProduct.module)}
          disabled={ isDetailsPanelReadOnly }
          fieldLabel= {t('global.module')}
          fieldName= "modules"
          onSelect={handleSaveModule}
          onBlur={resetEditFields}
          onPressEscape={ onPressEscape }
        >
          <Select.Option key='reset' value=''>{ getModuleName(null) }</Select.Option>
          {
            modules && modules.map(moduleItem =>
              <Select.Option key={moduleItem.code} value={moduleItem.code}>{ getModuleName(moduleItem) }</Select.Option>)
          }
        </SelectWrapper>
      </div>
    </Form>
  </DetailsPanelCard>
}

ProductInventoryProperties.propTypes = {
  t: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  updatePageItems: PropTypes.func.isRequired,
  productMutator: PropTypes.object.isRequired,
  isDetailsPanelReadOnly: PropTypes.bool.isRequired,
}

export { ProductGeneralInformation }
export default compose(
  withRouter,
  withTranslation()
)(ProductGeneralInformation)
