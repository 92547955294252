import React, { useRef } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { Tag } from 'primereact/tag'
import { getRelativeTime } from '../../util/date'
import { normalize } from '../../../../utils/tools'

type TOverviewViewComponentProps = {
  id?: string,
  icon?: React.ReactNode
  textLabel?: string,
  tags?: any[],
  updatedAt?: string,
  description?: string,
}

export const OverviewViewComponent = ({
  id,
  icon,
  textLabel,
  tags,
  updatedAt = '',
  description = ''
}: TOverviewViewComponentProps) => {
  const labelRef = useRef(null)
  return (
    <>
      <div data-testid="overview-view-header" className="flex flex-none w-full overview-card-header">
        <div data-testid="overview-view-icon" className="flex flex-none align-items-center justify-content-center w-2rem h-2rem border-round-sm mr-1 overview-card-icon">
          { icon }
        </div>
        <div data-testid="overview-view-tags" className="flex flex-wrap flex-grow-1 justify-content-end overview-card-tag">
          {
            tags?.map((tag: any) => (
              <Tag
                data-testid={`overview-tag-${tag.id}`}
                data-cy={normalize(tag.name)}
                key={tag.id}
                style={{
                  color: tag.color,
                  backgroundColor: `${tag.color}1a`
                }}
                value={tag.name}
                className="mb-1 mr-1 h-1rem font-light text-xs px-1 py-0 max-w-full white-space-nowrap overflow-hidden text-overflow-ellipsis"
              />
            ))
          }
        </div>
      </div>
      <div data-testid="overview-view-body" className="flex w-full align-items-center justify-content-center overview-card-body">
        <div className="w-full text-base overview-card-label line-height-3">
          <div data-testid="overview-view-textLabel" id={`overview-card-textLabel-${id}`} ref={labelRef} className="overview-card-textLabel">{ textLabel }</div>
        </div>

        <Tooltip target={`#overview-card-textLabel-${id}`} position="bottom">
          <div data-testid="overview-view-textLabel-tooltip" className="max-w-15rem">
            <div className="w-full font-semibold">{ textLabel }</div>
            <div className="w-full">{ description }</div>
          </div>
        </Tooltip>
      </div>
      <div data-testid="overview-view-footer" className="flex flex-none w-full overview-card-footer">
        <div data-testid="overview-view-updated" className="flex-grow-1 text-xs text-gray-200">
          { getRelativeTime(new Date(updatedAt)) }
        </div>
      </div>
    </>
  )
}
