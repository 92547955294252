import { gql } from '@apollo/client'
import template from '../fragment/template'

export default gql`
  query activityTemplate($id: ID!) {
    activityTemplate(id: $id){
      ...templateFragment
      ... on TransportActivityTemplate {
        start
        legs {
          destination
          distance
          transportProductID
        }
      }
    }
  }
  ${template}
`
