import { connect } from 'react-redux'
import SpaceSelector from '../../../redux/selectors/space.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import { changeProductCustomImpactsSaga } from '../../../redux/sagas/product.saga'
import { setSpaceImpactSettingsSaga } from '../../../redux/sagas/space.saga'
import customImpactForm from './customImpactForm'

const mapStateToProps = state => ({
  selectedImpactMethodId: SpaceSelector.impactMethodId(state),
  selectedProductId: SelectedProductSelector.productId(state),
  selectedProduct: SelectedProductSelector.product(state),
  selectedSpace: SpaceSelector.selectedSpace(state),
  selectedImpactCategoryId: SpaceSelector.impactCategoryId(state),
  selectedProductCustomImpacts: SelectedProductSelector.customImpacts(state),
  selectedExcludeLT: SpaceSelector.impactExcludeLT(state),
})

const mapDispatchToProps = {
  changeProductCustomImpactsSaga,
  setSpaceImpactSettingsSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(customImpactForm)
