import { makeVar, InMemoryCache } from '@apollo/client'
import { IAccountAccess, IAuth0User, IImpactCategory, ISpaceAccess, IUser } from '../v1/model'
import { IUserSetting } from '../v1/shared/interface/user-setting'
import { LocalStorage } from '../v1/shared/enum/storage'

export const getUsersSetting = (): IUserSetting[] => {
  const localSetting = localStorage.getItem(LocalStorage.UserSetting)
  return localSetting ? JSON.parse(localSetting) : []
}

export const setUsersSetting = (updatedUserSetting: IUserSetting) => {
  const localSettings = getUsersSetting()
  const selectedIndex = localSettings.findIndex( (element: IUserSetting) => element.userId === updatedUserSetting.userId )
  if ( selectedIndex >= 0 ) {
    localSettings[selectedIndex] = { ...localSettings[selectedIndex], ...updatedUserSetting }
  } else {
    localSettings.push(updatedUserSetting)
  }
  localStorage.setItem(LocalStorage.UserSetting, JSON.stringify(localSettings))
  usersSettingVar(localSettings)
}

export const setSelectedAccount = (accountAccess: IAccountAccess | null = null) => {
  const { id: userId = null } = userVar() || {}
  const { account = null } = accountAccess || {}
  if ( userId && account ) {
    setUsersSetting({ userId, selectedAccountId: account?.id })
  }
  selectedAccountVar(accountAccess)
}

export const setSelectedWorkspace = (spaceAccess: ISpaceAccess | null) => {
  const { id: userId = null } = userVar() || {}
  const { space = null } = spaceAccess || {}
  if ( userId && space ) {
    setUsersSetting({ userId, selectedWorkspaceId: space?.id })
  }
  selectedWorkspaceVar(spaceAccess)
}

export const userVar = makeVar<IUser | null>(null)
export const userAuth0Var = makeVar<IAuth0User | null>(null)
export const usersSettingVar = makeVar<IUserSetting[]>(getUsersSetting())
export const selectedAccountVar = makeVar<IAccountAccess | null>(null)
export const selectedWorkspaceVar = makeVar<ISpaceAccess | null>(null)
export const currentWorkspaceSlugVar = makeVar<string | null>(null)
export const impactCategoriesVar = makeVar<IImpactCategory[]>([])
// TODO : To Be Removed : For compatibility with V0.9
export const enableNewScenarioV1Var = makeVar<boolean>(false)

export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        selectedAccount: {
          read() {
            return selectedAccountVar()
          }
        },
        selectedWorkspace: {
          read() {
            return selectedWorkspaceVar()
          }
        }
      }
    }
  }
})