import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import { IControllerRender } from '../../shared/interface/react-form-hook'
import { AddWorkspaceComponentProps } from '../interface/workspace-navigation'

export const AddWorkspaceComponent = ({
  handleWorkspaceCreation,
  showAddWorkspaceDialog,
  closeAddWorkspaceDialog,
  workspaceCreating
}: AddWorkspaceComponentProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const defaultValues = { name: '' }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  useEffect(() => {
    if (!workspaceCreating) {
      reset()
    }
  }, [ workspaceCreating ])

  const onSubmit = (data: any) => handleWorkspaceCreation(data)

  const onHide = () => {
    reset()
    closeAddWorkspaceDialog()
  }

  const getErrorMessage = (context: string): string => t('form.errorMessage', { context })

  return (
    <Dialog
      className="w-30rem"
      header={t('actions.header', { context: 'addWorkspace' })}
      visible={showAddWorkspaceDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 p-0">
            <label htmlFor="name" className="text-sm">
              { t('form.placeholder', { context: 'workspaceName' }) }
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true, minLength: 2 }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-cy="new-workspace-name"
                  data-testid="input-workspace-name"
                  placeholder={t('form.placeholder', { context: 'workspaceName' })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            {
              errors?.name
              && <small className="p-error">{ getErrorMessage('workspaceName') }</small>
            }
          </div>

          <div className="col-12 pt-6 px-0 flex justify-content-end">
            <div className="flex-none">
              <Button
                type="submit"
                data-cy="submit-new-workspace"
                data-testid="create-workspace-submit"
                label={t('form.label', { context: 'create', ns: 'common' })}
                iconPos="left"
                loading={workspaceCreating}
                className="p-button-primary justify-content-end"
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}
