import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { TabMenu } from 'primereact/tabmenu'

import { useTranslation } from 'react-i18next'
import { useUrlQuery } from '../../../shared/hook/use-url-query'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'

import { TrackEventType } from '../../../shared/enum/track-events'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Navigation } from '../../../shared/enum'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ChangeImpactCategoryContainer } from '../../../product/container/change-impact-category.container'
import { getSegmentTrack } from '../../../shared/util/segment'

export const TaskbarDetailComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const urlQuery = useUrlQuery()
  const navigate = useNavigate()
  const { lifecycleId } = useParams()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { selectedInventoryViewType, updateLifecycleDetail, refetchLifecycle } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const [ trackEventInSegment ] = getSegmentTrack()
  const activeTabKey: InventoryViewType = urlQuery.get('activeTabKey') as InventoryViewType
  const { impactCategory } = space || {}

  useEffect(() => {
    if (impactCategory?.id) {
      refetchLifecycle && refetchLifecycle()
    }
  }, [ impactCategory?.id ])

  useEffect(() => {
    if (activeTabKey) {
      const TabEventTypes: TrackEventType[] = [
        TrackEventType.VIEWED_LIFECYCLE_MODEL,
        TrackEventType.VIEWED_LIFECYCLE_TABLE,
        TrackEventType.VIEWED_LIFECYCLE_BAR_CHART,
        TrackEventType.VIEWED_LIFECYCLE_DONUT_CHART
      ]
      trackEventInSegment(TabEventTypes[getActiveIndex(activeTabKey)])
      updateLifecycleDetail({ selectedInventoryViewType: activeTabKey })
    } else {
      updateLifecycleDetail({ selectedInventoryViewType: InventoryViewType.Model })
    }
  }, [ activeTabKey ])

  const items = [
    { label: t('labels.tab', { context: 'model' }), className: 'cy-tab-model' },
    { label: t('labels.tab', { context: 'table' }), className: 'cy-tab-table' },
    { label: t('labels.tab', { context: 'barChart' }), className: 'cy-tab-barchart' },
    { label: t('labels.tab', { context: 'donutChart' }), className: 'cy-tab-doughnut' }
  ]

  const getActiveIndex = (selectedViewType?: InventoryViewType) => Object.values(InventoryViewType).findIndex(viewType => viewType === selectedViewType) || 0

  const handleTabChange = (event: any) => {
    const { index = 0 } = event || {}
    const activeTab = Object.values(InventoryViewType)[index]
    if (selectedInventoryViewType !== Object.values(InventoryViewType)[index]) {
      addToBackToList(items[getActiveIndex(selectedInventoryViewType)].label)
      account?.id && space?.slug && lifecycleId && navigate(generatePath(Navigation.LifeCycleDetailTabs, {
        accountId: account.id,
        workspaceSlug: space.slug,
        lifecycleId,
        activeTab
      }))
    }
  }

  return (
    <div className="flex align-items-center justify-content-start w-full">
      <div className="flex flex-grow-1">
        <TabMenu model={items} activeIndex={getActiveIndex(selectedInventoryViewType)} onTabChange={(event: any) => handleTabChange(event)} />
      </div>
      <div className="flex flex-none h-full mb-1">
        <ChangeImpactCategoryContainer />
      </div>
    </div>
  )
}
