import {
  faArchway,
  faBlockBrick,
  faBowlSoftServe,
  faCandyBar,
  faCanFood,
  faClothesHanger,
  faLampStreet,
  faNoteSticky,
  faSack,
  faShoePrints,
  faSocks,
  faVest,
  faWandMagicSparkles,
  faWashingMachine,
  faWineBottle,
  faSpeaker,
  faGlassCitrus,
  faBreadLoaf,
  faCoffeePot,
  faTomato,
  faSolarPanel,
  faCupTogo,
  faChimney,
} from '@fortawesome/pro-solid-svg-icons'
import { ProductTemplate } from '../../../__generated__/graphql'
import { IProductTemplate } from '../interface/product-template'

export const PRODUCT_TEMPLATES: IProductTemplate[] = [
  {
    key: ProductTemplate.Brick1PalletWith1000PcsV39,
    iconName: faBlockBrick,
    name: 'brick',
    industry: 'constructionMaterials',
    isPopular: true
  },
  {
    key: ProductTemplate.BoxerShortsV39,
    iconName: faClothesHanger,
    name: 'boxerShorts',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.ChocolateBarMilkPeanutsV39,
    iconName: faCandyBar,
    name: 'chocolateBar',
    industry: 'foodBeverage',
    isPopular: true
  },
  {
    key: ProductTemplate.StreetLightV39,
    iconName: faLampStreet,
    name: 'streetLight',
    industry: 'electronics',
    isPopular: true
  },
  {
    key: ProductTemplate.AluminiumCan330mlV39,
    iconName: faCanFood,
    name: 'aluminiumCan',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.GlassBottle330mlV39,
    iconName: faWineBottle,
    name: 'glassBottle',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.RainJacketV39,
    iconName: faVest,
    name: 'rainJacket',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.Concrete1m3V39,
    iconName: faArchway,
    name: 'concrete',
    industry: 'constructionMaterials',
    isPopular: false
  },
  {
    key: ProductTemplate.JeansV39,
    iconName: faWashingMachine,
    name: 'jeans',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.PlasticBagsV39,
    iconName: faSack,
    name: 'plasticBags',
    industry: 'packaging',
    isPopular: false
  },
  {
    key: ProductTemplate.SocksV39,
    iconName: faSocks,
    name: 'socks',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.Yoghurt1lV39,
    iconName: faBowlSoftServe,
    name: 'yoghurt',
    industry: 'foodBeverage',
    isPopular: false
  },
  {
    key: ProductTemplate.SneakersV39,
    iconName: faShoePrints,
    name: 'sneakers',
    industry: 'apparelTextile',
    isPopular: false
  },
  {
    key: ProductTemplate.ProductStructureV39,
    iconName: faWandMagicSparkles,
    name: 'productStructure',
    industry: 'general',
    isPopular: true
  },
  {
    key: ProductTemplate.StickyNoteV39,
    iconName: faNoteSticky,
    name: 'stickyNote',
    industry: 'general',
    isPopular: true
  },
  {
    key: ProductTemplate.BluetoothSpeakerV39,
    iconName: faSpeaker,
    name: 'bluetoothSpeaker',
    industry: 'electronics',
    isPopular: false
  },
  {
    key: ProductTemplate.OrangeJuiceV39,
    iconName: faGlassCitrus,
    name: 'orangeJuice',
    industry: 'foodBeverage',
    isPopular: false
  },
  {
    key: ProductTemplate.BreadV39,
    iconName: faBreadLoaf,
    name: 'bread',
    industry: 'foodBeverage',
    isPopular: false
  },
  {
    key: ProductTemplate.TomatoSauceV39,
    iconName: faTomato,
    name: 'tomatoSauce',
    industry: 'foodBeverage',
    isPopular: false
  },
  {
    key: ProductTemplate.ProductStructureComponentsV39,
    iconName: faWandMagicSparkles,
    name: 'productStructureComponents',
    industry: 'general',
    isPopular: false
  },
  {
    key: ProductTemplate.ProductStructureEn15804V39,
    iconName: faWandMagicSparkles,
    name: 'productStructureEN15804',
    industry: 'general',
    isPopular: false
  },
  {
    key: ProductTemplate.ElectricKettleV39,
    iconName: faCoffeePot,
    name: 'electricKettle',
    industry: 'electronics',
    isPopular: false
  },
  {
    key: ProductTemplate.SolarPanelV39,
    iconName: faSolarPanel,
    name: 'solarPanel',
    industry: 'electronics',
    isPopular: false
  },
  {
    key: ProductTemplate.RoofTilesV39,
    iconName: faChimney,
    name: 'roofTiles',
    industry: 'constructionMaterials',
    isPopular: false
  },
  {
    key: ProductTemplate.CoffeeCupsV39,
    iconName: faCupTogo,
    name: 'coffeeCups',
    industry: 'packaging',
    isPopular: false
  }
]
