import React, { useContext, useEffect } from 'react'

import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { useLifecycleInsightQuery } from '../../shared/hook/query/use-lifecycle-insight.query'
import { IPhase } from '../../model'
import { transformLifecycleLeafItems } from '../../shared/util/transform-lifecycle'

type TLifecycleInsightContainerProps = {
  children?: React.ReactNode
}
export const LifecycleInsightContainer = ({
  children
}: TLifecycleInsightContainerProps) => {
  const {
    primaryEntityId = '',
    secondaryEntityId = '',
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext)
  const {
    loading: secondaryLeafLoading,
    data: secondaryLifecycleData,
    refetch: secondaryLeafItemsRefetch
  } = useLifecycleInsightQuery(secondaryEntityId)

  const {
    loading: primaryLeafLoading,
    data: primaryLifecycleData,
    refetch: primaryLeafItemsRefetch
  } = useLifecycleInsightQuery(primaryEntityId)

  const { lifecycle: primaryLifecycle = null } = primaryLifecycleData || {}
  const { lifecycle: secondaryLifecycle = null } = secondaryLifecycleData || {}

  const loadingLeafItems = secondaryLeafLoading || primaryLeafLoading

  const getLifecycleTotalImpact = (phases: IPhase[] = []) => {
    if (phases.length === 0) return

    const unit = phases[0].inventory?.totalImpact?.unit
    const status = phases[0].inventory?.totalImpact?.status

    const totalAmount = phases.reduce((acc, phase) => {
      const amount = phase.inventory?.totalImpact?.amount
      return acc + (amount ? parseFloat(amount) : 0)
    }, 0)

    return { amount: totalAmount.toString(), unit, status }
  }

  useEffect(() => {
    if (!primaryLeafLoading && primaryLifecycle) {
      const primaryTotalImpact = getLifecycleTotalImpact(primaryLifecycle.phases)
      const primaryLifecycleLeafItems = transformLifecycleLeafItems(primaryLifecycle, primaryTotalImpact)
      const {
        name: primaryEntityName,
        unit: primaryEntityUnit
      } = primaryLifecycle || {}
      updateAnalysis({
        primaryEntityName,
        primaryEntityUnit,
        primaryLifecycle,
        primaryLifecycleLeafItems,
        primaryTotalImpact,
        loadingLeafItems,
        primaryLeafItemsRefetch
      })
    } else if (primaryLeafLoading) {
      updateAnalysis({ loadingLeafItems })
    }
  }, [ primaryLeafLoading, primaryLifecycle ])

  useEffect(() => {
    if (!secondaryLeafLoading && secondaryLifecycle) {
      const secondaryTotalImpact = getLifecycleTotalImpact(secondaryLifecycle.phases)
      const secondaryLifecycleLeafItems = transformLifecycleLeafItems(secondaryLifecycle, secondaryTotalImpact)
      const {
        name: secondaryEntityName,
        unit: secondaryEntityUnit
      } = secondaryLifecycle || {}
      updateAnalysis({
        secondaryEntityName,
        secondaryEntityUnit,
        secondaryLifecycle,
        secondaryLifecycleLeafItems,
        secondaryTotalImpact,
        loadingLeafItems,
        secondaryLeafItemsRefetch
      })
    } else if (secondaryLeafLoading) {
      updateAnalysis({ loadingLeafItems })
    }
  }, [ secondaryLifecycle, secondaryLeafLoading ])

  return (
    <>
      { loadingLeafItems && <ProgressSpinnerComponent dataTestId="loading-analysis-insight" size={2} /> }

      { !loadingLeafItems && children }
    </>
  )
}
