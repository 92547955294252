import React from 'react'
import { isEmpty, notify } from '../../../utils/tools'
import { notification } from 'antd'
import { withTranslation } from 'react-i18next'
import compose from '../../../utils/compose'
import PropTypes from 'prop-types'

class Notification extends React.Component {

  componentDidMount() {
    this.displayNotifications()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.errors !== this.props.errors ||
      prevProps.successes !== this.props.successes
    ) {
      this.displayNotifications()
    }
  }

  displayNotifications = () => {
    const { errors, successes, clearNotifications, t } = this.props
    !isEmpty(errors)
    && notify({
      type: 'error',
      message: errors.map(msg => {
        return { message: msg }
      }),
      notification,
      t
    })
    !isEmpty(successes)
    && notify({
      type: 'success',
      // TODO this is a workaround the fact that notify does not support multiple success messages
      message: !isEmpty(successes) && successes[0],
      notification,
      t
    })

    if (!isEmpty(errors) || !isEmpty(successes)) {
      clearNotifications()
    }
  }

  render() {
    return null
  }
}

Notification.propTypes = {
  clearNotifications: PropTypes.func.isRequired
}

export default compose(
  withTranslation(),
)(Notification)
