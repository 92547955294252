import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Frame } from '../../component/layout'
import { WorkspaceProvider } from './provider/workspace.provider'
import { selectedAccountVar, selectedWorkspaceVar } from '../../graphql/cache'
import { GettingStartedLayout } from './layout/getting-started.layout'
import { ProgressSpinnerComponent } from '../shared/component/general/progress-spinner.component'

export const GettingStartedEntry = () => {
  const { loading: loadingWorkspace = true } = useReactiveVar(selectedWorkspaceVar) || {}
  const { loading: loadingAccount = true } = useReactiveVar(selectedAccountVar) || {}

  if (loadingWorkspace || loadingAccount) {
    return <ProgressSpinnerComponent dataTestId="loading-getting-stated-entry" size={2} />
  }

  return (
    <WorkspaceProvider>
      <Frame>
        {() => (
          <>
            <GettingStartedLayout />
          </>
        )}
      </Frame>
    </WorkspaceProvider>
  )
}
