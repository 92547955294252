import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { MenuAction } from '../../enum/menu-action'

type DeleteProductComponentProps = {
  removingProduct?: boolean,
  showDeleteProductDialog: boolean,
  closeDeleteProductDialog: () => void,
  handleDeleteProduct: () => void
  handleDeleteFolder: () => void
}
export const DeleteEntityComponent = ({
  removingProduct = false,
  showDeleteProductDialog,
  closeDeleteProductDialog,
  handleDeleteProduct,
  handleDeleteFolder
}: DeleteProductComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { selectedEntity, selectedAction } = useContext<TProductContext>(ProductContext)

  const isDeleteProduct = selectedAction !== MenuAction.DeleteFolder

  const onHide = () => {
    closeDeleteProductDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="delete-entity-dialog"
      header={t('actions.delete', { context: isDeleteProduct ? 'product' : 'folder' })}
      visible={showDeleteProductDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div className="col-12 p-0" data-testid="delete-entity-message">
          { t('messages.warning', {
            context: isDeleteProduct ? 'removeProduct' : 'removeFolder',
            name: selectedEntity?.name
          })}
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product"
            label={t('actions.cancel', { ns: 'common' })}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={isDeleteProduct ? handleDeleteProduct : handleDeleteFolder}
            data-testid="button-delete-product"
            data-cy="button-delete-product"
            label={t('form.label', { context: 'delete', ns: 'common' })}
            iconPos="left"
            loading={removingProduct}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
