import { Button, Result } from 'antd'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { NAV } from '../../../utils/const'

class NoMatchPage extends React.Component {
  render() {
    const { t } = this.props
    return <Result
      status="404"
      title="404"
      subTitle={t('err.no_match_page_message')}
      extra={<Button data-testid="no-match-page-button" href={NAV.ACCOUNT}>{t('global.back_home')}</Button>}
    />
  }
}
export default compose(
  withTranslation(),
)(NoMatchPage)
