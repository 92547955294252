import React from 'react'
import { Button } from 'primereact/button'

export type TOverviewCreateComponentProp = {
  createLabel?: string
}

export const OverviewCreateComponent = ({
  createLabel
}: TOverviewCreateComponentProp) => (
  <div data-testid="overview-create-wrapper" className="flex align-items-center justify-content-center w-full h-full">
    <div className="flex flex-column">
      <div className="flex align-items-center justify-content-center w-full h-2rem">
        <Button data-testid="overview-create-button" icon="pi pi-plus" className="p-button-outlined p-button-plain w-2rem h-full" />
      </div>
      <div data-testid="overview-create-label" className="flex align-items-center justify-content-center text-basis w-full pt-3">
        { createLabel }
      </div>
    </div>
  </div>
)
