import React from 'react'

type EmptyStateScreenLayoutProps = {
  wrapperClassName?: string;
  title?: React.ReactNode;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  footer?: React.ReactNode;
}

export const EmptyStateScreenLayout = ({
  wrapperClassName,
  title,
  icon,
  description,
  footer,
}: EmptyStateScreenLayoutProps) => (
  <div
    data-testid="empty-state-screen"
    className={`flex align-items-center justify-content-center text-center ${wrapperClassName}`}
  >
    <div className="flex flex-column align-items-center">
      {title && (
        <div data-testid="empty-state-screen-title" className="text-2xl mb-6 w-21rem font-semibold">{title}</div>
      )}
      {icon && (
        <div className="mb-6" data-testid="empty-state-screen-icon">{icon}</div>
      )}
      {description && (
        <div className="text-base text-gray-700 w-14rem mb-4" data-testid="empty-state-screen-description">{description}</div>
      )}
      {footer && (
        <div className="flex justify-content-center" data-testid="empty-state-screen-footer">
          {footer}
        </div>
      )}
    </div>
  </div>
)
