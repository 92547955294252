import { gql } from '@apollo/client'
import referenceProduct from '../fragment/referenceProduct'

export default gql`
  query productWithImpact($id: ID!){
    productWithImpact(id: $id) {
      product {
        id
        name
        type
        unit
        hasInventory
        description
        module {
          code
        }
        tags {
          id
          name
          color
        }
        customImpacts {
          amount
          impactCategoryID
          impactMethodID
          excludeLT
        }
        referenceProduct {
          ...referenceProduct
        }
        productProperties {
          referenceProperty {
            id
            name
          }
          conversionFactor
          referenceUnit {
            id
            name
          }
        }
        scenarios{
          id
          name
        }
      }
      impact {
        amount
        unit
        status
      }
    }
  }
  ${referenceProduct}
`
