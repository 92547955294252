import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from '@fortawesome/pro-light-svg-icons'

import { TaskbarFilterQueryComponent } from './taskbar-filter-query.component'
import { TaskbarFilterTypeComponent } from './taskbar-filter-type.component'
import { TaskbarFilterTagsComponent } from './taskbar-filter-tags.component'
import { TaskbarToggleViewComponent } from '../../../shared/component/general/taskbar-toggle-view.component'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { ProductLabel, ViewType } from '../../../shared/enum'
import { setOverviewSetting } from '../../../shared/util/overview'

export const TaskbarComponent = () => {
  const {
    label,
    selectedViewType,
    updateProduct
  } = useContext<TProductContext>(ProductContext)

  const onViewChange = (selectedViewType: ViewType) => {
    updateProduct({ selectedViewType, currentPage: 1 })
    setOverviewSetting({ selectedViewType })
  }

  const onClickCreateFolder = () => {
    updateProduct({ showCreateFolderDialog: true })
  }

  return (
    <div className="flex w-full h-full">
      <div data-testid="product-taskbar-filter" className="flex flex-grow-1 gap-2 h-2rem">
        <TaskbarFilterQueryComponent />

        { label === ProductLabel.Object && <TaskbarFilterTypeComponent />}

        <TaskbarFilterTagsComponent />
      </div>
      <div data-testid="product-taskbar-actions" className="flex flex-none w-13rem h-2rem justify-content-end gap-3">
        <Button
          icon={<FontAwesomeIcon icon={faFolderPlus} />}
          data-testid="products-create-folder"
          data-cy="add-folder-action"
          onClick={onClickCreateFolder}
          className="p-button-outlined p-button-plain w-2rem h-2rem"
        />

        <TaskbarToggleViewComponent onViewChange={onViewChange} selectedViewType={selectedViewType} />
      </div>
    </div>
  )
}
