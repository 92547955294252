import { gql } from '@apollo/client'

export default gql`
  mutation changeSpaceImpactSettings($id: ID!, $impactMethodID: ID!, $impactCategoryID: ID, $nwSetID: ID, $nwSetType: Int, $excludeLT: Boolean!, $useMethodTotal: Boolean!, $databases: [ID!]) {
    changeSpaceImpactSettings(id: $id, impactMethodID: $impactMethodID, impactCategoryID: $impactCategoryID, nwSetID: $nwSetID, nwSetType: $nwSetType, excludeLT: $excludeLT, useMethodTotal: $useMethodTotal, databases: $databases) {
      id
      name
      impactMethod{
        id
        name
        description
        impactMethodSetID
      }
      nwSet{
        id
        type
        name
        unit
      }
      impactCategory{
        id
        impactMethodID
        name
        unit
      }
      excludeLT
      useMethodTotal
    }
  }
`
