import { connect } from 'react-redux'
import  FlagsSelector from '../../../../redux/selectors/flags.selector'
import { setSelectedPhaseAction } from '../../../../redux/actions/lifecycle.actions'
import { setIsOrderingPhasesAction, setIsCreateNewInventoryItemAction, setIsCreatePhaseAction } from '../../../../redux/actions/flags.actions'
import PhasesManagement from './phasesManagement'

const mapStateToProps = state => ({
  isOrderingPhases: FlagsSelector.isOrderingPhases(state),
})

const mapDispatchToProps = {
  setIsOrderingPhasesAction,
  setIsCreatePhaseAction,
  setIsCreateNewInventoryItemAction,
  setSelectedPhaseAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhasesManagement)
