import React, { useContext, useEffect, useRef } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { COPY_LIFECYCLE } from '../../graphql/mutation'
import { CreateScenarioComponent } from '../component/create-scenario.component'
import { displayGraphqlErrors } from '../../shared/util/error'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'

import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { ILifecycle } from '../../model'
import { store } from '../../../configureStore'
import { setSelectedLifecycleAction } from '../../../redux/actions/lifecycle.actions'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { EntityType } from '../../shared/enum/entity-type'
import { Navigation } from '../../shared/enum'

type TCreateScenarioContainerProps = {
  showCreateScenarioDialog: boolean,
  updateLifecycle: Function,
  selectedLifecycle?: ILifecycle,
  afterCreateScenario: () => void
}

export const CreateScenarioContainer = ({
  afterCreateScenario,
  showCreateScenarioDialog = false,
  updateLifecycle,
  selectedLifecycle = {}
}: TCreateScenarioContainerProps) => {
  const { t } = useTranslation([ 'common' ])

  const [ trackEventInSegment ] = getSegmentTrack()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const navigate = useNavigate()
  const toast = useRef<Toast>(null)
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleComparison = isFeatureEnabled(Feature.LegacyLifecycleComparisonScenario) || false

  const [
    copyLifecycle,
    {
      error: failedCopyingLifecycle,
      data: copyLifecycleData,
      loading: copyingLifecycle
    }
  ] = useMutation(COPY_LIFECYCLE)
  const copiedLifecycle = copyLifecycleData?.copyLifecycle
  const { id: accountId = null } = account || {}
  const { slug: workspaceSlug = null } = space || {}

  useEffect(() => {
    if (!copyingLifecycle && (copiedLifecycle || failedCopyingLifecycle)) {
      createScenarioCallback()
    }
  }, [ copyingLifecycle, copiedLifecycle, failedCopyingLifecycle ])

  const closeCreateScenarioDialog = () => {
    updateLifecycle({ showCreateScenarioDialog: false })
  }

  const handleCreateScenario = () => {
    selectedLifecycle?.id && copyLifecycle({ variables: { lifecycleID: selectedLifecycle?.id } })
  }

  const createScenarioCallback = () => {
    try {
      if (failedCopyingLifecycle) {
        throw failedCopyingLifecycle
      } else if (copiedLifecycle) {
        chooseScenario(copiedLifecycle.id)
        afterCreateScenario()
        closeCreateScenarioDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: 'Successfully created the scenario',
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed creating the scenario', error?.graphQLErrors)
    }
  }

  const chooseScenario = (scenarioId: string) => {
    if (!accountId || !workspaceSlug || !selectedLifecycle?.id) return

    // TODO : From v0.9 : To be refactored
    store.dispatch(setSelectedLifecycleAction(null))

    trackEventInSegment(TrackEventType.OPENED_LIFECYCLE_SCENARIO)
    if (!enableLegacyLifecycleComparison) {
      const pathname = generatePath(Navigation.LifeCycleScenarioV1, { accountId, workspaceSlug, lifecycleId: selectedLifecycle?.id })
      navigate({ pathname, search: `activeTabKey=${InventoryViewType.Model}&type=${EntityType.LifeCycle}&id=${scenarioId}` })
    } else {
      navigate(`/account/${accountId}/workspace/${workspaceSlug}/lifecycle/${selectedLifecycle?.id}/inventory/?lifecycleScenarioId=${scenarioId}`)
    }
  }

  return (
    <>
      <CreateScenarioComponent
        handleChooseScenario={chooseScenario}
        handleCreateScenario={handleCreateScenario}
        closeCreateScenarioDialog={closeCreateScenarioDialog}
        showCreateScenarioDialog={showCreateScenarioDialog}
        selectedLifecycle={selectedLifecycle}
        copyingLifecycle={copyingLifecycle}
      />

      <Toast data-testid="create-scenario-status" ref={toast} position="top-right" />
    </>
  )
}
