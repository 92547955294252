import React from 'react'
import { useNavigate } from 'react-router-dom'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import ModalWindow from '../../../model/modalWindow/modalWindow.container'
import { IComparisonSelectorDialogProps } from './comparisonSelectorDialog.interface'
import CompareDropdown from '../../../model/product-tools/compare-dropdown/compareDropdown.container'

const ComparisonSelectorDialog: React.FC<IComparisonSelectorDialogProps> = ({
  closeComparisonModal,
  isSelectingComparison,
  t
}) => {
  const navigate = useNavigate()

  const compareDropdownProps = {
    navigate,
    closeProductToolModal: closeComparisonModal
  }

  const modalWindowProps = {
    isShowModal: isSelectingComparison,
    title: t('model.comparison'),
    cancelHandler: closeComparisonModal,
    cancelText: t('global.cancel'),
    content: () => <CompareDropdown {...compareDropdownProps} />
  }
  return <ModalWindow {...modalWindowProps} />
}

export { ComparisonSelectorDialog }
export default compose(
  withTranslation()
)(ComparisonSelectorDialog)
