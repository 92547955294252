import React from 'react'
import { Input } from 'antd'
import { DEFAULT } from '../../../utils/const'
import { ITypeRemoveInputProps } from './typeRemoveInput.interface'

const TypeRemoveInput = (props:ITypeRemoveInputProps) => {
  const { setIsRemoveWordTyped, inputType, inputPlaceholder } = props

  const validateTypedWord = (e:React.FormEvent<HTMLInputElement>): void => {
    e.currentTarget.value === DEFAULT.REMOVE ?
      setIsRemoveWordTyped(true) : setIsRemoveWordTyped(false)
  }

  return <Input
    className='delete-input'
    placeholder={inputPlaceholder}
    data-cy={`remove-${inputType}`}
    onChange={ validateTypedWord }/>
}

export default TypeRemoveInput
