import { returnNested, safeArray, getObjectValues } from './tools'
import { percentage } from './math'

const nanValue = '-'

export default class FlatView {
  /** @type {FlatViewEntity} */
  _entity

  /**
   * @param {FlatViewEntity} entity
   */
  constructor(entity) {
    this._entity = entity
  }

  leafInventoryItems = () => returnNested(this._entity,  'leafInventoryItems')
  totalImpact = () => returnNested(this._entity, 'totalImpact')
  totalImpactAmount = () => returnNested(this.totalImpact(), 'amount')

  _getPhaseOrder = phase => safeArray(returnNested(this._entity, 'phases')).findIndex(p => p.type === returnNested(phase, 'type'))
  flatViewWithPhases = () => {
    const rootImpact = this.totalImpactAmount()
    const leafInventoryItems = this.leafInventoryItems()
    const maxImpact = Math.max(...safeArray(leafInventoryItems).map(({ impact }) => impact.amount))

    return {
      name: returnNested(this._entity, 'name'),
      id: returnNested(this._entity, 'id'),
      __typename: returnNested(this._entity, '__typename'),
      totalImpact: this.totalImpact(),
      items: safeArray(leafInventoryItems).map(item => ({
        phaseId: returnNested(item, 'phase', 'id'),
        phaseName: returnNested(item, 'phase', 'name'),
        phaseOrder: this._getPhaseOrder(returnNested(item, 'phase')),
        productId: returnNested(item, 'product', 'id'),
        productName: returnNested(item, 'product', 'name'),
        productAmount: returnNested(item, 'amount'),
        productUnit: returnNested(item, 'product', 'unit') || 'unit',
        impactAmount: returnNested(item, 'impact', 'amount'),
        impactUnit: returnNested(item, 'impact', 'unit'),
        impactPercentageFromRoot: rootImpact
          ? percentage(returnNested(item, 'impact', 'amount'), rootImpact)
          : nanValue,
        relativeImpactPercentage: rootImpact
          ? percentage(returnNested(item, 'impact', 'amount'), maxImpact)
          : nanValue
      }))
    }
  }

  flatViewSkipPhases = () => {
    const rootImpact = this.totalImpactAmount()
    let tmp = []
    for (const item of safeArray(this.leafInventoryItems())) {
      const productId = returnNested(item, 'product', 'id')
      const prevAmount = Number(returnNested(tmp, productId, 'productAmount')) || 0
      const prevImpactAmount = Number(returnNested(tmp, productId, 'impactAmount')) || 0
      const impactAmount = Number(returnNested(item, 'impact', 'amount')) + prevImpactAmount
      const productAmount = Number(returnNested(item, 'amount')) + prevAmount

      tmp[productId] = {
        productId: returnNested(item, 'product', 'id'),
        productName: returnNested(item, 'product', 'name'),
        productAmount,
        productUnit: returnNested(item, 'product', 'unit') || 'unit',
        impactAmount,
        impactUnit: returnNested(item, 'impact', 'unit'),
      }
    }

    const maxImpact = Math.max(...getObjectValues(tmp).map(({ impactAmount }) => impactAmount))

    tmp = getObjectValues(tmp).map(item => ({
      ...item,
      impactPercentageFromRoot: rootImpact
        ? percentage(returnNested(item, 'impactAmount'), rootImpact)
        : nanValue,
      relativeImpactPercentage: rootImpact
        ? percentage(returnNested(item, 'impactAmount'), maxImpact)
        : nanValue
    }))

    return {
      name: returnNested(this._entity, 'name'),
      id: returnNested(this._entity, 'id'),
      __typename: returnNested(this._entity, '__typename'),
      totalImpact: this.totalImpact(),
      items: tmp
    }
  }
}
