import { applyMiddleware, compose, createStore } from 'redux'
import { persistStore } from 'redux-persist'
import rootReducer from './redux/reducers/rootReducer'
import createSagaMiddleware from 'redux-saga'
import rootSagas from './redux/sagas/rootSagas'

// Partial fix : `redux-${process.env.REACT_APP_VERSION}` || 'npm_local_build',

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    }) : compose

const sagaMiddleware = createSagaMiddleware()

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
)

export const store = createStore(
  rootReducer, enhancer
)
sagaMiddleware.run(rootSagas)

export const persistor = persistStore(store)
