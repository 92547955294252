import { Col, Radio, Row, Select } from 'antd'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { validatePhoneByCountry } from '../../../utils/validation'
import { CUSTOMER_TYPE } from '../../../utils/const'
import { getCountryName, stripeCountries } from '../../../utils/countries'
import { isEmpty, returnNested } from '../../../utils/tools'
import InputWrapper from '../../helpers/form/inputWrapper'
import RadioGroupWrapper from '../../helpers/form/radioGroupWrapper'
import SelectWrapper from '../../helpers/form/selectWrapper'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'

const { Option } = Select

class CustomerFields extends React.Component {
  state = {
    showVatField: false
  }
  componentDidMount() {
    const { customer } = this.props

    if (!isEmpty(returnNested(customer, 'vat'))) {
      this.setState({
        showVatField: true
      })
    }
  }

  handleOnChangeCustomerType = ({ target: { value } }) => {
    this.setState({
      showVatField: value === CUSTOMER_TYPE.BUSINESS
    })
  }

  render() {
    const { t, customer, isFormEditable } = this.props
    if (isEmpty(customer)) return null
    const countryOptions = stripeCountries().map(country => <Option value={ country.code } key={ country.code }>{ country.name }</Option>)
    const formFields = {
      name: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ customer.name }
        fieldName="name"
        fieldLabel={ t('global.name') }
        fieldRules={ [
          { required: true, message: t('validation.require_field', { name: t('global.name') }) }
        ] }
      />,
      email: <InputWrapper
        isFieldEditable = {isFormEditable}
        autoFocus={false}
        displayedText = {customer.email}
        fieldName = "email"
        fieldLabel = {t('account_settings.email') }
        fieldRules={ [
          { required: true, message: t('validation.require_field', { name: t('account_settings.email') }) },
          { type: 'email', message: 'The input is not valid E-mail!', }
        ] }
      />,
      phone: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ customer.phone || '-' }
        fieldName="phone"
        fieldLabel={ t('billing.phone') }
        dependencies={[ 'country' ]}
        fieldRules={ [
          ({ getFieldValue }) => ({
            validator(rule, value) {
              const phoneValidator = validatePhoneByCountry(value, getFieldValue('country'))
              if (phoneValidator.isValid) {
                return Promise.resolve()
              }
              return Promise.reject(t(phoneValidator.errMessage, { name: t('billing.phone') }) )
            }
          })
        ] }
      />,
      vat: <InputWrapper
        autoFocus={false}
        isFieldEditable={ isFormEditable }
        displayedText={ returnNested(customer, 'vat') }
        fieldName="vat"
        fieldLabel={ t('billing.vat_label') }
        fieldRules={ [
          { required: true, message: t('validation.require_field', { name: t('billing.vat_label') }) },
        ] }
      />,
      type: <RadioGroupWrapper
        isFieldEditable={ isFormEditable }
        displayedText={
          isEmpty(returnNested(customer, 'vat'))
            ? t('billing.customer_type_private_label')
            : t('billing.customer_type_business_label')
        }
        fieldName="customerType"
        fieldLabel={ t('billing.customer_type_label') }
        onChange={this.handleOnChangeCustomerType}
        fieldRules={ [
          { required: true, message: t('validation.require_field', { name: t('billing.customer_type_label') }) },
        ]}
      >
        <Radio value={CUSTOMER_TYPE.PRIVATE}>{ t('billing.customer_type_private_label') }</Radio>
        <Radio value={CUSTOMER_TYPE.BUSINESS}>{ t('billing.customer_type_business_label') }</Radio>
      </RadioGroupWrapper>,
      country: <div className='select-wrapper'><SelectWrapper
        isFieldEditable={isFormEditable}
        autoFocus={false}
        autoComplete="chrome-off"
        displayedText={getCountryName(returnNested(customer, 'address', 'country'))}
        fieldLabel= {t('global.country')}
        fieldName= "country"
        fieldRules= {[
          { required: true, message: t('validation.require_field', { name: t('global.country') }) }
        ]}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().startsWith(input.toLowerCase())
        }
        dropdownMatchSelectWidth={false}
        getPopupContainer={ node => node.parentElement }
        placeholder={t('billing.select_country')}
      >
        {countryOptions}
      </SelectWrapper></div>,
      address1: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ returnNested(customer, 'address', 'line1') }
        fieldName="line1"
        fieldLabel={ `${t('billing.address')} ${t('billing.address_line_1_label')}` }
        fieldRules={ [
          { required: true, message: t('validation.require_field', { name: 'Line 1' }) },
        ] }
      />,
      address2: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ returnNested(customer, 'address', 'line2') }
        fieldName="line2"
        fieldLabel={ `${t('billing.address')} ${t('billing.address_line_2_label')}` }
      />,
      postalCode: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ returnNested(customer, 'address', 'postalCode') }
        fieldNormalize={value =>  !isEmpty(value) ? value.toUpperCase() : '' }
        fieldName="postalCode"
        fieldLabel={ t('billing.address_postal_code_label') }
        dependencies={[ 'country' ]}
        fieldRules= {[
          { required: true, message: t('validation.require_field', { name: t('billing.address_postal_code_label') }) },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              const country = getFieldValue('country')
              if (!country || !value || !postcodeValidatorExistsForCountry(country) || postcodeValidator(value, country)) {
                return Promise.resolve()
              }
              return Promise.reject(t('validation.invalid_postal_code', { postalCode: value, country: getCountryName(country) }) )
            }
          })
        ]}
      />,
      city: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ returnNested(customer, 'address', 'city') }
        fieldName="city"
        fieldLabel={ t('billing.address_city_label') }
        fieldRules= {[
          { required: true, message: t('validation.require_field', { name: t('billing.address_city_label') }) }
        ]}
      />,
      state: <InputWrapper
        isFieldEditable={ isFormEditable }
        autoFocus={false}
        displayedText={ returnNested(customer, 'address', 'state') }
        fieldName="state"
        fieldLabel={ t('billing.address_state_label') }
      />
    }

    return (
      <Row gutter={32} className="customer-details-form">
        <Col span={12}><h4>{t('billing.customer_details')}</h4></Col>
        <Col span={12}><h4>{t('billing.billing_address')}</h4></Col>
        <Col span={12}>{formFields.name}</Col>
        <Col span={12}>{formFields.country}</Col>
        <Col span={12}>{formFields.email}</Col>
        <Col span={12}>{formFields.state}</Col>
        <Col span={12}>{formFields.phone}</Col>
        <Col span={12}>{formFields.city}</Col>
        <Col span={12}>{formFields.type}</Col>
        <Col span={12}>{formFields.address1}</Col>
        <Col span={12}>{this.state.showVatField  && formFields.vat}</Col>
        <Col span={12}>{formFields.address2}</Col>
        <Col span={12} offset={12}>{formFields.postalCode}</Col>

      </Row>
    )
  }
}
export default compose(
  withTranslation()
)(CustomerFields)
