import React from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Input, Row, Col, Timeline, Select, Form } from 'antd'
import { withTranslation } from 'react-i18next'
import compose  from '../../../../../utils/compose'
import { safeArray, normalize, returnNested } from '../../../../../utils/tools'
const FormItem = Form.Item
class TransportationLegs extends React.Component {
  legStyle() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      }
    }
  }

  render() {
    const {
      formRef,
      t,
      handleAddDestination,
      isDisabled
    } = this.props

    const currentForm = returnNested(formRef, 'current')

    const legs = currentForm ? currentForm.getFieldValue('leg') : []

    const formItems = legs.map((k, index) => (// k?
      <Timeline.Item key={index}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem
              name={[ 'legItem', index, 'destination' ]}
              rules={[
                { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Destination' }) },
              ]}
              {...this.legStyle} label={t('global.to')}
            >
              <Input data-cy="transport-to-input" disabled={ isDisabled } />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <FormItem
              {...this.legStyle} label={t('global.by')}
              name={[ 'legItem', index, 'transportProductID' ]}
              rules={[
                { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'By' }) },
              ]}
            >
              <Select data-cy="transport-by-select" dropdownClassName="cy-transport-by-dropdown" className="full-width" getPopupContainer={ node => node.parentElement } disabled={ isDisabled }>
                {
                  safeArray(this.props.vehicleList).map(el => <Select.Option data-cy={`transport-named-${normalize(el.name)}`} value={el.id} key={el.id}>{el.name}</Select.Option>)
                }
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              {...this.legStyle} label={t('global.distance')}
              name={[ 'legItem', index, 'distance' ]}
              rules={[ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Distance' }) } ]}
            >
              <Input data-cy="transport-distance-input" type='number' className='transport-distance-input' suffix='km' disabled={ isDisabled }/>
            </FormItem>
          </Col>

        </Row>
      </Timeline.Item>
    ))

    return (
      <Row>
        {
          <Col>
            <Timeline>
              <Timeline.Item key={'start'}>
                <FormItem
                  {...this.legStyle} label={t('global.from')}
                  name="start"
                  rules={[ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Start' }) } ]}
                >
                  <Input data-cy="transport-from-input" disabled={ isDisabled }/>
                </FormItem>
              </Timeline.Item>

              { formItems }

              {
                !isDisabled && <Timeline.Item
                  key='add-new'
                  dot={<PlusCircleOutlined onClick={ handleAddDestination } className='add-leg-button' />}>
                  <span data-cy="add-destination-button" className='pointer' onClick={ handleAddDestination }>{t('global.add_destination_point')}</span>
                </Timeline.Item>
              }

            </Timeline>
          </Col>
        }
      </Row>
    )
  }
}
export default compose(
  withTranslation()
)(TransportationLegs)
