import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { Frame } from '../../component/layout'
import { LifecycleProvider } from './provider/lifecycle.provider'
import { LifecycleOverviewContainer } from './container/lifecycle-overview.container'
import { getOverviewPageSize } from '../shared/util/screen'
import { useWindowSize } from '../shared/hook/use-window-size'
import { store } from '../../configureStore'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { getOverviewSetting } from '../shared/util/overview'
import { IDashboardContext } from '../shared/interface/workspace-context-type'
import DashboardContext from '../dashboard/context/dashboard.context'
import { useIsMounted } from '../shared/hook/use-is-mounted'
import { LifeCycleSortField } from '../../__generated__/graphql'
import { selectedWorkspaceVar } from '../../graphql/cache'

export const LifecycleOverviewEntry = () => {
  const isMounted = useIsMounted()
  const [ screenWidth, screenHeight ] = useWindowSize()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { cardPageSize, tablePageSize } = getOverviewPageSize(screenWidth, screenHeight)
  const { selectedViewType, selectedSortBy, selectedSortOrder } = getOverviewSetting()
  const { clearBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    isMounted() && clearBackToList()
  }, [ isMounted ])

  store.dispatch(clearUiWhenDetailPanelCloseAction())
  store.dispatch(setIsDetailsPanelOpenAction(false))
  return (
    <>
      { screenWidth && space && (
        <LifecycleProvider
          cardPageSize={cardPageSize}
          tablePageSize={tablePageSize}
          selectedViewType={selectedViewType}
          selectedSortBy={selectedSortBy as LifeCycleSortField}
          selectedSortOrder={selectedSortOrder}
        >
          <Frame>
            { () => <LifecycleOverviewContainer /> }
          </Frame>
        </LifecycleProvider>
      )}
    </>
  )
}
