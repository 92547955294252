import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'

import { faCircleInfo, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { SuggestionLimit } from '../enum/suggestion-limit'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { useDebouncedValue } from '../../shared/hook/use-debounced-value'

const SearchField = styled.div`
  .p-inputtext {
    border-color: var(--gray-100) !important;
  }
  .p-inputtext::placeholder {
    color: var(--gray-300);
    opacity: 1; /* Firefox */
  }
  .p-inputtext:focus {
    box-shadow: none;
  }
`

export const SearchFieldComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])
  const { selectedEntity } = useContext<IDashboardContext>(DashboardContext)
  const { updateImpactDataset } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const {
    query = '',
    updateFilterValue,
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ searchEnabled, setSearchEnabled ] = useState<boolean>(false)
  const [ searchValue, setSearchValue ] = useState<string>(query)
  const debouncedValue = useDebouncedValue(searchValue, 800)

  useEffect(() => {
    if (searchEnabled) {
      updateFilterValue({ query: debouncedValue as string })
    }
  }, [ debouncedValue ])

  const getSuggestionsFromName = () => {
    const { name = '' } = selectedEntity || {}
    const parsedProductName = name.replace(/[^A-Z]+/ig, ' ').trim()
    const suggestionsRowList = parsedProductName.split(' ')

    const suggestions = suggestionsRowList
      .filter(word => word.length > 1)
      .slice(0, SuggestionLimit.NumberOfWords)

    if (suggestions.length > 1 && suggestions.length <= SuggestionLimit.NumberOfWords) {
      suggestions.push(suggestionsRowList.slice(0, SuggestionLimit.MaxLastWordLength).join(' '))
    }
    return suggestions
  }

  const selectSuggestion = (suggestion: any) => {
    if (!query.includes(suggestion)) {
      const updatedSearchQuery = `${query} ${suggestion}`
      defineQuery(updatedSearchQuery)
    }
  }

  const defineQuery = (query: string) => {
    setSearchEnabled(true)
    setSearchValue(query)
  }

  const tagList = getSuggestionsFromName()

  return (
    <div className="flex w-full align-items-center pb-3">
      <SearchField className="flex w-28rem h-2rem">
        <div className="p-inputgroup">

          <span className="p-inputgroup-addon bg-white px-2 min-w-min">
            <i className="pi pi-search text-sm" />
          </span>

          <InputText
            data-testid="search-database-input"
            data-cy="search-database-input"
            value={searchValue}
            onChange={(event: any) => defineQuery(event.target.value)}
            className="w-full h-2rem border-left-none border-right-none  text-xs px-1"
            placeholder="Search in database"
            autoFocus
          />

          <span className="p-inputgroup-addon border-left-none bg-white px-2 min-w-min">
            { query && <FontAwesomeIcon data-testid="clear-search-fields" icon={faXmark} className="text-basis text-gray-300 cursor-pointer" onClick={() => defineQuery('')} /> }
          </span>

          <span className="p-inputgroup-addon bg-white cursor-pointer">
            <FontAwesomeIcon data-testid="show-search-tips" icon={faCircleInfo} className="text-basis text-primary-500" onClick={() => updateImpactDataset({ showSearchTips: true })} />
          </span>
        </div>
      </SearchField>
      <div className="flex align-items-center justify-content-start flex-grow-1 flex-wrap gap-2 pl-3">
        <div className="flex align-items-center text-gray-300">{ t('labels.suggestions') }: </div>
        { tagList.map((tag, index) => (
          <Tag
            className="text-gray-300 bg-gray-50 font-normal white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm cursor-pointer"
            onClick={() => selectSuggestion(tag)}
            data-testid={`chip-filter-category-${index}`}
            key={`chip-filter-category-${index}`}
            value={tag}
          />
        ))}
      </div>
    </div>
  )
}
