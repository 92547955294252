import { connect } from 'react-redux'
import GlobalSelector from '../../../../redux/selectors/global.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import SpaceSelector from '../../../../redux/selectors/space.selector'
import {
  clearListOfProductsForTransportation,
  removeProductFromListOfProductsForTransportation,
  setVehicleList,
  setActivityTemplatesAction,
  setSelectedActivityTemplateAction
} from '../../../../redux/actions/global.actions'
import {
  getPossibleInventoryItemsSaga,
  getProductPropertyAmountSaga,
  createTransportActivitySaga,
  getVehicleListSaga,
  getActivityProductsSaga
} from '../../../../redux/sagas/product.saga'
import { updateTemplateListSaga, getActivityTemplateSaga } from '../../../../redux/sagas/activityTemplate.saga'
import TransportActivityTemplate from './transportActivityTemplate'

const mapStateToProps = state => ({
  selectedLifecyclePhasesList: LifecycleSelector.selectedLifecyclePhasesList(state),
  spaceId: SpaceSelector.spaceId(state),
  phaseId: LifecycleSelector.selectedPhaseId(state),
  listOfProductsForTransportation: GlobalSelector.listOfProductsForTransportation(state),
  activityProductList: GlobalSelector.activityProductList(state),
  activityTemplates: GlobalSelector.activityTemplates(state),
  selectedActivityTemplate: GlobalSelector.selectedActivityTemplate(state),
  selectedActivityTemplateId: GlobalSelector.selectedActivityTemplateId(state)
})

const mapDispatchToProps = {
  removeProductFromListOfProductsForTransportation,
  clearListOfProductsForTransportation,
  getPossibleInventoryItemsSaga,
  getProductPropertyAmountSaga,
  createTransportActivitySaga,
  getVehicleListSaga,
  setVehicleList,
  getActivityProductsSaga,
  updateTemplateListSaga,
  setActivityTemplatesAction,
  setSelectedActivityTemplateAction,
  getActivityTemplateSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportActivityTemplate)
