import { NumberPrecision, ScientificNotationThreshold } from '../enum'
import { DecimalViewType, NotationRange } from '../enum/decimal'
import { isValid } from './tools'

type TGetChangeProps = {
  finalValue?: number | null
  startingValue?: number | null
}
export const getChangeRatio = ({ finalValue, startingValue }: TGetChangeProps) => ((finalValue && startingValue && startingValue !== 0) ? ((finalValue - startingValue) / startingValue) : null)

type TCastNumberToDisplayProps = {
  value?: any
  threshold?: ScientificNotationThreshold
  decimalPlaces?: NumberPrecision
}
export const castNumberToDisplayValue = ({
  value,
  threshold = ScientificNotationThreshold.Medium,
  decimalPlaces = NumberPrecision.Medium
}: TCastNumberToDisplayProps): string[] => {
  const parsedValue = (isValid(value) && value !== '') ? Number.parseFloat(value) : 0.0
  const exponential = threshold - 1
  const abs = Math.abs(parsedValue)
  if ((
    abs < Number(`1e${exponential}`)
    && abs > Number(`1e-${exponential}`)
  ) || parsedValue === 0) {
    return [ parsedValue.toFixed(decimalPlaces).toString() ]
  }
  const scientificNotation = Number.parseFloat(value).toExponential(decimalPlaces)
  return scientificNotation.split('e').map(item => item)
}

export const roundToMaxDecimalPlace = (value: number, max: number = 2) => Math.round((value + Number.EPSILON) * 10 ** max) / 10 ** max

type TGetNotationProps = {
  roundedCoefficient: number
  exponent?: string
}

type TGetNumericNotationProps = TGetNotationProps & {
  parsedValue: number
}
const getNumericNotation = ({
  parsedValue,
  roundedCoefficient,
  exponent
}: TGetNumericNotationProps) => {
  if (parsedValue === 0) return [ 0 ]

  const absoluteValue = Math.abs(parsedValue)
  if (parsedValue === 0 || absoluteValue === 1) {
    return [ parsedValue ]
  } if (absoluteValue < NotationRange.ExtraSmall
    || absoluteValue > NotationRange.Large) {
    return [ roundedCoefficient, exponent ]
  } if (absoluteValue < NotationRange.Small) {
    return [ roundToMaxDecimalPlace(parsedValue, 5) ]
  } if (absoluteValue <= NotationRange.Medium) {
    return [ parsedValue.toFixed(2) ]
  }

  return [ Math.round(parsedValue) ]
}

const getDefaultNotation = ({
  roundedCoefficient,
  exponent
}: TGetNotationProps) => {
  // Evaluates if the exponent is '+2' and coefficient is 1
  if (exponent === '+2' && Math.abs(roundedCoefficient - 1) < Number.EPSILON) {
    return [ 100 ]
  }

  return [ roundedCoefficient, exponent ]
}

const getParsedValue = (decimalViewType: DecimalViewType = DecimalViewType.ScientificValue, value?: any) => {
  if (!isValid(value) && value === '') return 0.0

  const isPercentage = decimalViewType === DecimalViewType.PercentageValue
  const parsedValue = Number.parseFloat(value)
  return isPercentage ? parsedValue * 100 : parsedValue
}

type TGetDecimalDisplayValueProps = {
  value?: any
  threshold?: ScientificNotationThreshold
  decimalPlaces?: NumberPrecision
  decimalViewType?: DecimalViewType
}
export const getDecimalDisplayValue = ({
  value,
  threshold,
  decimalPlaces,
  decimalViewType
}: TGetDecimalDisplayValueProps) => {
  const parsedValue = getParsedValue(decimalViewType, value)
  const [ coefficient, exponent ] = castNumberToDisplayValue({ value: parsedValue.toString(), threshold, decimalPlaces })
  const roundedCoefficient = roundToMaxDecimalPlace(Number.parseFloat(coefficient))
  switch (decimalViewType) {
  case DecimalViewType.ScientificValue:
    return parsedValue === 0 ? [ parsedValue ] : castNumberToDisplayValue({ value: parsedValue.toString(), threshold: ScientificNotationThreshold.None })
  case DecimalViewType.PercentageValue:
  case DecimalViewType.NumericValue:
    return getNumericNotation({ parsedValue, roundedCoefficient, exponent })
  }

  return getDefaultNotation({ roundedCoefficient, exponent })
}
