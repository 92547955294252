import { connect } from 'react-redux'
import AccountSelector from '../../../../redux/selectors/account.selector'
import SubscriptionProductDetails from './subscriptionProductDetails'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'

const mapStateToProps = state => {
  return ({
    subscription: AccountSelector.selectedAccountSubscription(state),
    subscriptionProduct: AccountSelector.firstPlan(state),
    subscriptionSourcedFrom: AccountSelector.subscriptionSourcedFrom(state)
  })
}

const mapDispatchToProps = {
  trackEventSaga
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionProductDetails)
