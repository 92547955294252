import React, { useContext, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import styled from 'styled-components'

import DashboardContext from '../context/dashboard.context'

const PLAYER_MIN_WIDTH = 200;
const PLAYER_INIT_WIDTH = 460;
const PLAYER_RATIO = 0.56
const PLAYER_INIT_HEIGHT = PLAYER_INIT_WIDTH * PLAYER_RATIO;

const PipVideoPlayerWrapper = styled.div`
  .bg-primarydark-500 {
    background: var(--primarydark-500);
  }
  .player-container {
    z-index: 1001;
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }
  .resize-handler {
    bottom: -2.625rem;
    right: -0.625rem;
    cursor: nwse-resize;
  }
  .video-overlay {
    top: 2rem;
    bottom: -2rem;
    display: none;
  }
  `

export const PipVideoPlayerComponent = () => {
  const { pipVideo, showPipPlayer, updateDashboard } = useContext(DashboardContext)
  const playerRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [ playerSize, setPlayerSize ] = useState({ width: PLAYER_INIT_WIDTH, height: PLAYER_INIT_HEIGHT })

  let offsetX:number = 0
  let offsetY:number = 0
  let initialXPosition = 0;
  let initialPlayerWidth = PLAYER_INIT_WIDTH;

  const onMove = (e:any) => {
    e.stopPropagation()
    if (!playerRef.current) return
    playerRef.current.style.left = `${e.pageX - offsetX}px`
    playerRef.current.style.top = `${e.pageY - offsetY}px`
  }
  const onMoveStart = (e:any) => {
    e.stopPropagation()
    e.preventDefault();

    if (!playerRef.current || !overlayRef.current) return
    overlayRef.current.style.display = 'block'
    offsetX = e.clientX - playerRef.current.getBoundingClientRect().left
    offsetY = e.clientY - playerRef.current.getBoundingClientRect().top
    document.addEventListener('mousemove', onMove)
  }
  const onMoveEnd = () => {
    if (!overlayRef.current) return
    overlayRef.current.style.display = 'none'
    document.removeEventListener('mousemove', onMove)
  }

  const onResize = (e: any) => {
    e.stopPropagation()
    e.preventDefault();

    const difference = e.clientX - initialXPosition
    const newWidth = initialPlayerWidth + difference;
    const newHeight = newWidth * PLAYER_RATIO;

    if (newWidth >= PLAYER_MIN_WIDTH) {
      setPlayerSize({ width: newWidth, height: newHeight })
    }
  };

  const onResizeStart = (e:any) => {
    e.stopPropagation()
    e.preventDefault();

    if (!playerRef.current || !overlayRef.current) return
    overlayRef.current.style.display = 'block'
    initialXPosition = e.clientX;
    initialPlayerWidth = parseInt(window.getComputedStyle(playerRef.current).width, 10);

    document.addEventListener('mousemove', onResize);
    document.addEventListener('mouseup', onResizeEnd);
  };

  const onResizeEnd = () => {
    if (!overlayRef.current) return
    overlayRef.current.style.display = 'none'
    document.removeEventListener('mouseup', onResizeEnd);
    document.removeEventListener('mousemove', onResize);
  };

  return (
    <PipVideoPlayerWrapper>
      {pipVideo?.path && showPipPlayer && (
        <div
          className="fixed bg-white player-container"
          style={{
            width: `${playerSize.width}px`,
            height: `${playerSize.height}px`,
            top: `calc(100% - 3.125rem - ${PLAYER_INIT_HEIGHT}px)`,
            left: `calc(100% - 1.25rem - ${PLAYER_INIT_WIDTH}px)`
          }}
          ref={playerRef}
        >
          <div
            className="bg-primarydark-500 cursor-move h-2rem flex justify-content-end align-content-center pt-1"
            onMouseDown={onMoveStart}
            onMouseUp={onMoveEnd}
            data-testid="drag-header"
          >
            <FontAwesomeIcon
              icon={faClose}
              data-testid="close-button"
              className="flex text-2xl cursor-pointer text-white mr-2"
              onClick={() => updateDashboard({ showPipPlayer: false })}
            />
          </div>

          <iframe
            className="w-full h-full flex"
            src={pipVideo.path}
            title={pipVideo.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          >
          </iframe>
          <div
            ref={overlayRef}
            className="absolute left-0 right-0 opacity-0 video-overlay"
          />
          <span
            onMouseDown={onResizeStart}
            data-testid="resize-handler"
            className="absolute w-2rem h-2rem opacity-0 resize-handler"
          />
        </div>
      )}
    </PipVideoPlayerWrapper>
  )
}
