import React from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import ModalWindow from '../../../model/modalWindow/modalWindow.container'
import { IScenarioSelectorDialogProps } from './scenarioSelectorDialog.interface'
import LifecycleScenarioDropdown from '../../../model/product-tools/scenario-dropdown/lifecycleScenarioDropdown.container'
import ProductScenarioDropdown from '../../../model/product-tools/scenario-dropdown/productScenarioDropdown.container'
import { useNavigate } from 'react-router-dom'

const ScenarioSelectorDialog: React.FC<IScenarioSelectorDialogProps> = ({
  closeScenarioModal,
  isSelectingScenario,
  isLifecycle,
  entityId,
  scenarioList,
  t
}) => {
  const navigate = useNavigate()

  return <ModalWindow {...{
    isShowModal: isSelectingScenario,
    title: t('model.scenario'),
    cancelHandler: closeScenarioModal,
    cancelText: t('global.cancel'),
    content: () => isLifecycle ?
      <LifecycleScenarioDropdown {...{
        navigate,
        scenarioList,
        entityId,
        successCb: closeScenarioModal
      }}/>
      :
      <ProductScenarioDropdown {...{
        navigate,
        scenarioList,
        entityId,
        closeProductToolModal: closeScenarioModal
      }}/> }} />
}

export default compose(
  withTranslation()
)(ScenarioSelectorDialog)
