import React from 'react'
import { Input } from 'antd'

export const LocationFilter = ({
  locationQuery,
  handleLocationChange,
  locationPlaceholder
}) => {

  return (
    <Input
      value={locationQuery}
      data-cy='input-filter-by-country'
      placeholder={ locationPlaceholder }
      onChange={ event => handleLocationChange(event.target.value) } />
  )
}