import { returnNested, isEmpty } from '../../utils/tools'
import { EntityType } from '../../utils/const'
import PhaseType from '../../component/model/lifecycle/phaseType'
import { getInventoryNodeFromInventoryItemKey, InventoryTreeKey } from '../../utils/inventoryTreeKey'
import {
  InventoryTreeSelector,
  ProductScenarioSelector,
  selectedInventoryItemSelector
} from './inventoryTree.selector'
import ComparisonSelector from './comparison.selector'
import { LifecycleSelector, LifecycleScenarioSelector } from './lifecycle.selector'
import FlagsSelector from './flags.selector'
import {
  ProductDetailsMutations,
  ProductScenarioMutations
} from '../../utils/ProductMutator'

const getActiveInventory = state => {
  const selectedInventoryItem = selectedInventoryItemSelector(state)
  const fromKey = selectedInventoryItem && InventoryTreeKey.createFromKey(selectedInventoryItem)

  if (!fromKey) return null
  switch (true) {
  case fromKey.rootId === InventoryTreeSelector.productId(state):
    return InventoryTreeSelector.inventoryTree(state)
  case fromKey.rootId === ProductScenarioSelector.productId(state):
    return ProductScenarioSelector.inventoryTree(state)
  case fromKey.rootId === ComparisonSelector.comparisonProductId(state):
    return LifecycleScenarioSelector.phaseInventoryByProductId(state, fromKey.rootId)
  case !isEmpty(LifecycleSelector.phaseInventoryByProductId(state, fromKey.rootId)):
    return LifecycleSelector.phaseInventoryByProductId(state, fromKey.rootId)
  case !isEmpty(LifecycleScenarioSelector.phaseInventoryByProductId(state, fromKey.rootId)):
    return LifecycleScenarioSelector.phaseInventoryByProductId(state, fromKey.rootId)
  default:
    return null
  }
}

const getIsProductScenarioMutations = (
  inventoryRootId,
  productScenarioInventoryRootId,
  fromKey,
  lifecycleScenarioInventory
) => {
  const isProductScenarioMutations = fromKey &&
  inventoryRootId &&
  (
    inventoryRootId === productScenarioInventoryRootId ||
    inventoryRootId === returnNested(lifecycleScenarioInventory, 'product', 'id')
  )
  return isProductScenarioMutations
}

const getProductMutator = state => {
  const selectedInventoryItem = selectedInventoryItemSelector(state)
  const productScenarioInventoryRootId = ProductScenarioSelector.productId(state)
  const fromKey = selectedInventoryItem && InventoryTreeKey.createFromKey(selectedInventoryItem)
  const activeInventoryTree = getActiveInventory(state)
  const inventoryRootId = returnNested(activeInventoryTree, 'product', 'id')
  const lifecycleScenarioInventory = LifecycleScenarioSelector.phaseInventoryByProductId(state, inventoryRootId)
  if (getIsProductScenarioMutations(inventoryRootId, productScenarioInventoryRootId, fromKey, lifecycleScenarioInventory)) {
    const nodeId = returnNested(getInventoryNodeFromInventoryItemKey(activeInventoryTree, fromKey.inventoryTreeKey()), 'nodeId') || 'root'
    return new ProductScenarioMutations(activeInventoryTree.product.id, nodeId, fromKey.productId)
  }
  return new ProductDetailsMutations()
}

const getIsDetailsPanelReadOnly = state => {
  const isShowProductScenarioInventory =  FlagsSelector.isShowProductScenarioInventory(state)
  const isShowLifecycleScenarioInventory =  FlagsSelector.isShowLifecycleScenarioInventory(state)
  const isShowComparisonInventory = ComparisonSelector.isInventoryCompare(state)
  const activeInventoryTree = getActiveInventory(state)
  const productId = returnNested(activeInventoryTree, 'product', 'id')

  if (isShowProductScenarioInventory) {
    // noinspection UnnecessaryLocalVariableJS
    const isInventoryTree = productId === InventoryTreeSelector.productId(state)
    return isInventoryTree
  }

  if (isShowLifecycleScenarioInventory) {
    const lifecyclePhase = LifecycleSelector.phaseByProductId(state, productId)
    if (lifecyclePhase && PhaseType.isProductionPhase(lifecyclePhase)) {
      return true
    }
    // noinspection UnnecessaryLocalVariableJS
    const isLifecyclePhase = !isEmpty(lifecyclePhase)
    return isLifecyclePhase
  }

  if (isShowComparisonInventory) {
    const { entityType } = ComparisonSelector.entityIdAndTypeToCompare(state)
    if (entityType === EntityType.PRODUCT) {
      return productId === ComparisonSelector.comparisonProductId(state)
    }
    if (entityType === EntityType.LIFECYCLE) {
      return ComparisonSelector.isPhaseSelected(state, LifecycleSelector.selectedPhaseId(state))
    }
  }

  return false
}

const productMutator = state => getProductMutator(state)
const activeInventory = state => getActiveInventory(state)
const isDetailsPanelReadOnly = state => getIsDetailsPanelReadOnly(state)

const ProductMutationSelector = {
  productMutator,
  activeInventory,
  isDetailsPanelReadOnly
}

export default ProductMutationSelector

