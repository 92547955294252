import { gql } from '@apollo/client'
import invoice from '../fragment/invoice'

export default gql`
  query invoices($accountID: ID!){
    invoices(accountID: $accountID) {
        ...invoiceFragment
    }
  }
  ${invoice}
`
