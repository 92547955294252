import React, { useState, useEffect } from 'react'
import { Button, Divider, List, Tooltip } from 'antd'
import classnames from 'classnames'

import { withTranslation } from 'react-i18next'
import { normalize, floatToString } from '../../../utils/tools'
import compose from '../../../utils/compose'
import { useRefOverflow } from '../../helpers/hooks/refOverflow'

const SearchListItem = ( {
  referProductsToEachOther,
  isElementaryFlow,
  referenceProduct,
  selectedProduct,
  t
} ) => {
  const {
    id,
    name,
    amount,
    location,
    category,
    description,
    databaseID,
    databaseName,
    referenceUnit,
    parentCategory,
    referenceProperty,
  } = referenceProduct || {}

  const [ nameRef, hasNameOverflow ] = useRefOverflow()
  const [ isResultDetailShown, setIsResultDetailShown ] = useState(false)
  useEffect(() => {
    setIsResultDetailShown(false)
  }, [ id ])

  const isReferenceProductSelected = referenceProduct => {
    const { referenceProduct: selectedReferenceProduct } = selectedProduct || {}
    return referenceProduct?.id === selectedReferenceProduct?.id
  }

  const toggleInformation = event => {
    event.preventDefault()
    event.stopPropagation()
    setIsResultDetailShown(!isResultDetailShown)
  }

  const getProductItemActions = () => {
    const actionButton = !isReferenceProductSelected(referenceProduct) ? (<Button
      data-cy={ 'reference-product-' + normalize(name) }
      className='button primary reference-result__add'
      onClick={ () => {
        referProductsToEachOther(referenceProduct)
      } }>
      + { t('global.add') }
    </Button>) : (<Button
      disabled
      data-cy={ 'reference-product-' + normalize(name) }
      onClick={ () => {
        referProductsToEachOther(referenceProduct)
      } }>
      { t('global.selectedReference') }
    </Button>)
    return [ actionButton ]
  }

  return (
    <List.Item
      data-cy={ 'reference-product-item-' + normalize(name) }
      className={classnames('reference-result__item', {
        'reference-result__item--opened': isResultDetailShown
      })}
      key={ id }
      actions={ getProductItemActions() }
      onClick={event => toggleInformation(event)}
    >
      <div className={classnames('reference-result__card', {
        'reference-result__card--selected': isReferenceProductSelected(referenceProduct)
      })}>
        <div className="reference-result__header">
          <Tooltip placement="top" title={(hasNameOverflow) ? name : null}>
            <h3 ref={nameRef} className={classnames('reference-result__name', {
              'reference-result__name--closed': true
            })}>{name}</h3>
          </Tooltip>
          <div className="reference-result__subtitle">
            <span className='reference-result__value'>
              {
                isElementaryFlow ? `${parentCategory?.name} / ${category?.name}` : location?.name
              }
            </span>
            { (isElementaryFlow || location) && <Divider className="reference-result__divider" type='vertical'/>}
            <span className='reference-result__value'>{`${floatToString(amount)} ${referenceUnit?.name} ${referenceProperty?.name}`}</span>
            { (!isElementaryFlow && databaseID) && <Divider className="reference-result__divider" type='vertical'/>}
            { !isElementaryFlow && <span className='reference-result__value'>{ databaseName }</span>}
          </div>
          {!isResultDetailShown && <Button type="link" className="reference-result__info reference-result__info--more">{t('global.moreInformation')}</Button>}
          {isResultDetailShown && <Button type="link" className="reference-result__info">{t('global.hideInformation')}</Button>}
        </div>
        {isResultDetailShown && <div className="reference-result__content">
          <div className="reference-result__attr">
            <div className="reference-result__label">{t('global.category')}</div>
            <div className="reference-result__value">{ isElementaryFlow ? `${parentCategory?.name} / ${category?.name}` : category?.name }</div>
          </div>
          { !isElementaryFlow && <div className="reference-result__attr">
            <div className="reference-result__label">{t('model.database')}</div>
            <div className="reference-result__value">{ databaseName }</div>
          </div>
          }
          {description && <div className="reference-result__description">
            <div className="reference-result__label">{t('global.description')}</div>
            <div className="reference-result__value">
              { description }
            </div>
          </div>}
          <div className='clear-fix'></div>
        </div>}
      </div>
    </List.Item>
  )
}

export default compose(
  withTranslation(),
)(SearchListItem)
