import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBringForward, faFileCirclePlus, faAngleDown } from '@fortawesome/pro-regular-svg-icons'

import ProductContext from '../../provider/context/product.context'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { TProductContext } from '../../interface/product-context.type'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import { isValid } from '../../../shared/util/tools'

export const MenuCompareComponent = () => {
  const { t } = useTranslation([ 'common', 'product' ])
  const menu = useRef<Menu>(null)

  const { updateProduct } = useContext<TProductContext>(ProductContext)
  const {
    productInventory = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const { product } = productInventory

  const items = [
    {
      key: 0,
      label: t('actions.compare', { context: 'withExisting', ns: 'product' }),
      icon: <FontAwesomeIcon data-cy="menu-item-comparison" icon={faBringForward} rotation={90} className="text-sm mr-1" />,
      command: () => {
        updateDashboard({ showCreateComparisonDialog: true })
      },
      template: MenuItemComponent
    },
    {
      key: 1,
      label: t('actions.compare', { context: 'byScenario', ns: 'product' }),
      icon: <FontAwesomeIcon data-cy="menu-item-scenario" icon={faFileCirclePlus} className="text-sm mr-1" />,
      command: () => {
        updateProduct({ showCreateScenarioDialog: true, selectedEntity: { id: product?.id, name: product?.name } })
      },
      template: MenuItemComponent
    }
  ]
  const thereIsCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)

  const isCompareEnabled = () => isValid(product?.referenceProduct) || product?.hasInventory || thereIsCustomImpact

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" className="mt-1  w-auto" />

      { !isCompareEnabled() && <Tooltip className="w-15rem" target=".disabled-compare-button" position="bottom" disabled={isCompareEnabled()} /> }

      <div className="flex disabled-compare-button" data-pr-tooltip={t('messages.description', { context: 'addItemsOrImpact', ns: 'product' })}>
        <Button
          className="p-button p-component p-button-outlined p-button-plain text-sm w-8rem h-2rem px-2"
          icon={<FontAwesomeIcon icon={faBringForward} rotation={90} className="mr-2" />}
          onClick={(event: any) => menu?.current?.toggle(event)}
          label={t('actions.compare', { ns: 'product' })}
          disabled={!isCompareEnabled()}
          aria-controls="popup_menu"
          aria-haspopup
          data-testid="menu-compare-inventory"
        >
          <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
        </Button>
      </div>
    </>
  )
}
