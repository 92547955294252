import { SVG } from './assets'

const pdfStore = {}

const docConfig = {
  pageOrientation: 'landscape',
  pageMargins: [ 40, 40, 40, 120 ],
  background: function (page) {
    if (page !== 2) {
      return [
        {
          svg: SVG.BACKGROUND,
          height: 595,
          alignment: 'right'
        }
      ]
    }
  }
}

const getDocStyles = () => {
  const { style } = pdfStore || {}
  return {
    defaultStyle: {
      font: 'Rubik 300-400',
      fontSize: 12
    },
    styles: {
      body: {
        font: 'Rubik 300-400',
      },
      subheader: {
        fontSize: 18,
        color: style.colors.primaryDark500,
        font: 'Rubik 500-700'
      },
      header: {
        fontSize: 24,
        color: style.colors.primary500,
        font: 'Rubik 500-700'
      },
      legend: {
        fontSize: 10
      },
      navy: {
        color: style.colors.primaryDark500
      },
      footerText: {
        fontSize: 9,
        color: style.colors.gray200
      },
      footerTitle: {
        fontSize: 10,
        font: 'Rubik 500-700',
        color: style.colors.primaryDark500
      },
      link: {
        color: style.colors.green500,
        fontSize: 10,
        bold: true
      },
      rubikHeavy: {
        font: 'Rubik 500-700',
        bold: true
      },
      graphAmount: {
        fontSize: 50,
        lineHeight: 0.9
      },
      graphAmountSn: {
        fontSize: 30,
        lineHeight: 0.9,
      }
    }
  }
}

const getSnDefinition = (amount, style = {}) => {
  const {
    value,
    coefficient,
    exponent
  } = amount || {}

  if (!coefficient || !exponent) return {
    text: value, style: style.default
  }
  return [
    { text: `${coefficient }  · 10`, style: style.sn },
    { text: exponent, sup: { offset: '30%', fontSize: 8 }, style: style.sn }
  ]
}

const getLegendDefinition = () => {
  const { data } = pdfStore || {}
  const rows = []
  let rowIndex = 0

  data.legendData.forEach((item, index) => {
    if (index > 0 && index % 3 === 0) rowIndex = 0

    if (!rows[rowIndex]) rows[rowIndex] = []

    const styledSvg = {
      svg: SVG.LEGEND_BULLET.replace('fill="#ffffff"', `fill="${item.color}"`),
      alignment: 'right'
    }
    const styledName = {
      text: item.name
    }
    const styledPerc = {
      text: `${item.perc}%`,
      bold: true,
      alignment: 'right'
    }

    rows[rowIndex].push(styledSvg, styledName, styledPerc)
    rowIndex += 1
  })

  rows.forEach(row => {
    if (row.length === 3) row.push({ text: '' }, { text: '' }, { text: '' })
  })

  const table = {
    headerRows: 0,
    widths: [ 10, 95, 35, 30, 95, 35 ],
    body: rows
  }

  return table
}

const getLeftSection = () => {
  const { data } = pdfStore || {}

  return [
    {
      svg: SVG.ECOCHAIN_LOGO,
      width: 120,
    },
    {
      text: 'Product footprint of',
      style: 'subheader',
      margin: [ 0, 50, 0, 0 ]
    },
    {
      text: data.name,
      style: 'header',
      margin: [ 0, 0, 0, 8 ]
    },
    {
      text: `Unit: ${data.amount} ${data.unit}\n\n`
    },
    {
      text: data.description + '\n\n'
    },
    {
      text: [
        'The impact on ',
        data.impactCategory,
        ' is ',
        {
          text: getSnDefinition(data.totalImpact?.amount, { default: 'rubikHeavy', sn: 'rubikHeavy' })
        },
        {
          text: ` ${data.totalImpact?.unit}`,
          style: 'rubikHeavy'
        },
        '. The graph shows a breakdown of the highest contributors.\n\n'
      ]
    },
    {
      layout: {
        defaultBorder: false,
        paddingTop: function () { return 6 },
        paddingBottom: function () { return 6 },
      },
      style: 'legend',
      margin: [ 0, 8, 0, 0 ],
      table: getLegendDefinition()
    }
  ]
}

const getRightSection = () => {
  const { data } = pdfStore || {}
  return [
    {
      text: data.accountNameCropped,
      style: 'subheader',
      fontSize: 16,
      alignment: 'right',
      margin: [ 40, 0, 0, 0 ]
    },
    {
      text: data.date,
      style: 'navy',
      fontSize: 10,
      alignment: 'right',
    },
    {
      text: `* Impact category: ${data.impactCategory}\n\n* Method: ${data.impactMethod}`,
      style: 'navy',
      fontSize: 10,
      absolutePosition: {
        x: 660,
        y: 400
      }
    },
  ]
}

const getGraphSection = () => {
  const { data } = pdfStore || {}
  return [
    {
      svg: SVG.DOUGHNUT_WHITE_BG,
      relativePosition: {
        x: 0,
        y: 0
      }
    },
    {
      image: data.doughnut,
      width: 281,
      alignment: 'center',
      relativePosition: {
        x: 0,
        y: 0
      }
    },
    {
      columns: [
        {
          width: 160,
          height: 160,
          relativePosition: {
            x: 100,
            y: data.totalImpact.amount.exponent && data.totalImpact.amount.coefficient ? 115 : 100
          },
          stack: [
            {
              text: getSnDefinition(data.totalImpact.amount, { default: 'graphAmount', sn: 'graphAmountSn' }),
              alignment: 'center',
              fontSize: 50,
              lineHeight: 0.9
            },
            {
              text: data.totalImpact.unit + '*',
              alignment: 'center',
              fontSize: 20
            }
          ]
        }
      ]
    }
  ]
}

const getFooterSection= () => {
  const { data } = pdfStore || {}
  return [
    {
      text: 'Made with Mobius',
      style: 'footerTitle'
    },
    {
      text: 'mobius.ecochain.com',
      link: 'https://mobius.ecochain.com',
      style: 'link'
    },
    {
      text: [
        {
          text: 'Disclaimer',
          style: 'rubikHeavy'
        },
        {
          text: ` This document contains confidential and proprietary business information of ${data.accountName} It may only be printed or (photo) copied or otherwise used with the written consent of ${data.email}. Care must be taken when comparing results of different LCA studies. Please consult an LCA practitioner and "Requirements for comparability" as defined in ISO 14025:2020 Clause 6.7.2. for comparative assertions.`
        }
      ],
      alignment: 'justify',
      margin: [ 0, 12, 20, 0 ],
      width: 500,
      style: 'footerText'
    }
  ]
}

export const getDocDefinition = (data, style) => {
  pdfStore.data = { ...data }
  pdfStore.style = { ...style }

  return {
    ...docConfig,
    ...getDocStyles(),
    footer: {
      columns: [
        {
          width: 600,
          stack: getFooterSection(),
          margin: [ 40, 0, 0, 0 ],
        }
      ]
    },
    content: [
      {
        columns: [
          {
            width: 350,
            height: 450,
            stack: getLeftSection(),
            style: 'body'
          },
          {/* empty middle column */},
          {
            width: 200,
            stack: getRightSection(),
            style: 'body'
          }
        ]
      },
      {
        columns: [
          {
            width: 281,
            height: 281,
            absolutePosition: {
              x: 440,
              y: 100
            },
            stack: getGraphSection()
          }
        ]
      }
    ],
  }
}
