
import React from 'react'
import compose from '../utils/compose'
import { Result, Row, Col, Button, Avatar, Typography, Image } from 'antd'
import { FrownOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { detect } from 'detect-browser'
import { LogoIcon } from './shared/customIcons'
import { userVar } from '../graphql/cache'

const { Text, Paragraph } = Typography

const browserCompatibilityList = [
  { name: 'edge', minVersion: 10 },    /* ES5 v10, Promise v12 */
  { name: 'edge-chromium', minVersion: 79 }, /* Version 79 is the first */
  { name: 'chrome', minVersion: 23 },  /* ES5 v23, Promise v33 */
  { name: 'safari', minVersion: 6 },    /* ES5 v6, Promise v8 */
  { name: 'firefox', minVersion: 21 }, /* ES5 v21, Promise v29 */
  { name: 'opera', minVersion: 23 },   /* ES5 v23, Promise v20 */
]

const downloadList = [
  { name: 'Google Chrome', icon: 'chrome-logo.svg', url: 'https://www.google.com/chrome/' },
  { name: 'Mozilla Firefox', icon: 'firefox-logo.svg', url: 'https://www.mozilla.org/en-US/firefox/new/' },
  { name: 'Microsoft Edge', icon: 'edge-logo.svg', url: 'https://www.microsoft.com/en-us/edge' },
  { name: 'Opera', icon: 'opera-logo.svg', url: 'https://www.opera.com/' },
]

class BrowserCompatibility extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBrowserCompatible: this.isBrowserCompatible()
    }
  }

  isBrowserCompatible = () => {
    const currentBrowser = detect()
    const browserMajorVersion = Number(currentBrowser.version.substr(0, currentBrowser.version.indexOf('.')))
    return browserCompatibilityList.some(browser =>
      currentBrowser.name === browser.name &&
      browserMajorVersion >= browser.minVersion
    )
  }

  render() {
    const { t } = this.props
    const { isBrowserCompatible } = this.state
    const user = userVar()
    const isFirstAccountCreation = user?.accounts?.length === 0

    const downloadSection = <React.Fragment>
      <Row type="flex" className="browser-list">
        {downloadList.map(browser => <Col flex="1" key={browser.name} className="browser-item">
          <Avatar shape="square" size={82} src={<Image src={`/assets/images/browser-icons/${browser.icon}`} style={{ width: '82px' }} />} />
          <Text  type="secondary" className="browser-name">{browser.name}</Text>
          <Button size="small" type="link" onClick={() => window.open(browser.url, '_blank')}>{t('browser_download.download')}</Button>
        </Col>)}
      </Row>
      <Paragraph className="footer">
        <Avatar  className="ecochain-logo" shape="square" size={32} icon={<LogoIcon className="logo primary-fill" />} />
        <span className="logo-text">{t('global.ecochain')}</span>
      </Paragraph>
    </React.Fragment>

    if (!isBrowserCompatible && !isFirstAccountCreation) {
      return (
        <React.Fragment>
          <Row className="browsers-page">
            <Col span={18} offset={3}>
              <Result
                icon={<FrownOutlined />}
                title={t('browser_download.page_title')}
                subTitle={<Paragraph>{t('browser_download.page_message')}</Paragraph>}
                extra={downloadSection}
              />
            </Col>
          </Row>
        </React.Fragment>
      )
    }
    return this.props.children
  }
}

export default compose(
  withTranslation()
)(BrowserCompatibility)
