export const
  SET_NEW_INVENTORY_TREE              = 'SET_NEW_INVENTORY_TREE',
  ADD_INVENTORY_PRODUCT_SCENARIO      = 'ADD_INVENTORY_PRODUCT_SCENARIO',
  SET_PRODUCT_SCENARIO_INVENTORY_TREE = 'SET_PRODUCT_SCENARIO_INVENTORY_TREE',
  ADD_LIFECYCLE_TO_INVENTORY          = 'ADD_LIFECYCLE_TO_INVENTORY',
  SET_INVENTORY_LIFECYCLES            = 'SET_INVENTORY_LIFECYCLES',
  REMOVE_LIFECYCLE_FROM_INVENTORY     = 'REMOVE_LIFECYCLE_FROM_INVENTORY',
  SET_INVENTORY_ITEMS_TO_DISPLAY       = 'SET_INVENTORY_ITEMS_TO_DISPLAY'

export const setNewInventoryTreeAction = inventoryTree => ({
  type: SET_NEW_INVENTORY_TREE,
  inventoryTree
})

export const setInventoryItemsToDisplayAction = inventoryItemsToDisplay => ({
  type: SET_INVENTORY_ITEMS_TO_DISPLAY,
  inventoryItemsToDisplay
})

export const addInventoryProductScenarioAction = scenario => ({
  type: ADD_INVENTORY_PRODUCT_SCENARIO,
  scenario
})

export const setProductScenarioInventoryTreeAction = productScenario => ({
  type: SET_PRODUCT_SCENARIO_INVENTORY_TREE,
  productScenario
})

export const setInventoryLifecyclesAction = lifecycles => ({
  type: SET_INVENTORY_LIFECYCLES,
  lifecycles
})

export const addLifecycleToInventoryAction = lifecycle => ({
  type: ADD_LIFECYCLE_TO_INVENTORY,
  lifecycle
})

export const removeLifecycleFromInventoryAction = lifecycleId => ({
  type: REMOVE_LIFECYCLE_FROM_INVENTORY,
  lifecycleId
})
