import { gql } from '@apollo/client'
import phase from './phase'

export default gql`
  fragment lifecycleFragment on Lifecycle {
      id
      name
      amount
      unit
      description
      slug
      product{
          id
          name
          lifecycles{
              id
              name
              slug
          }
      }
      scenarios{
          id
          name
          slug
      }
      inventory{
          totalImpact{
              amount
              unit
              status
          }
          leafInventoryItems {
              amount
              impact {
                  amount
                  unit
                  status
              }
              product {
                  id
                  name
                  unit
              }
              phase{
                  id
                  name
                  type
              }
          }
      }
      phases {
          ...phaseFragment
      }
  }
  ${phase}
`
