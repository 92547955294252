import React from 'react'
import { useTranslation } from 'react-i18next'

import { SplitScreenLayout } from '../../shared/layout/split-screen.layout'
import { ModelPanelComponent } from '../component/product/model-panel.component'
import { ComparisonEntity } from '../enum/entity'

export const ModelLayout = () => {
  const { t } = useTranslation([ 'product' ])

  return (
    <div className="flex flex-column w-full border-round-lg bg-white">
      <div data-testid="detail-body-header" className="flex-none flex p-5 w-full text-gray-700 text-2xl">
        { t('labels.headerModal') }
      </div>
      <div className="w-full flex-grow-1 p-5 pt-0 model-layout-body">
        <SplitScreenLayout className="flex-wrap" firstClassName="w-full lg:w-6 lg:pr-3" secondClassName="w-full pt-3 lg:w-6 lg:pl-3 lg:pt-0">
          <ModelPanelComponent entity={ComparisonEntity.Primary} />
          <ModelPanelComponent entity={ComparisonEntity.Secondary} />
        </SplitScreenLayout>
      </div>
    </div>
  )
}
