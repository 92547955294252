import React, { useContext } from 'react'
import { SelectButton } from 'primereact/selectbutton'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuperscript } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { DecimalViewType } from '../../enum/decimal'

import { SelectButtonWrapperLayout } from '../../layout/select-button-wrapper.layout'

import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import { isValid } from '../../util/tools'

export const DecimalViewTogglerComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    updateProductDetail,
    selectedDecimalViewType,
    productInventory = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { product } = productInventory

  // eslint-disable-next-line
  const decimalViewOptions = Object.values(DecimalViewType).map((item, index) => {
    switch (item) {
    case DecimalViewType.NumericValue:
      return { key: index, label: '123', value: item }
    case DecimalViewType.ScientificValue:
      return {
        key: index,
        label: <FontAwesomeIcon icon={faSuperscript} />,
        value: item
      }
    case DecimalViewType.PercentageValue:
      return { key: index, label: '%', value: item }
    }
  })
  const onTagChange = (selectedViewType: any) => {
    updateProductDetail({ selectedDecimalViewType: selectedViewType })
  }

  const viewTemplate = (option: any) => {
    const tooltipContent = [ 'numeric', 'scientific', 'percentage' ]
    return (
      <>
        <Tooltip className="w-10rem" target={`.flat-view-toggler-opt-${option.key}`} position="bottom" />
        <div
          data-testid={`flat-view-toggler-opt-${option.key}`}
          data-pr-tooltip={t('labels.decimalType', { context: tooltipContent[option.key] })}
          className={`flex align-items-center justify-content-center text-xs line-height-2 w-1rem h-1rem flat-view-toggler-opt-${option.key}`}
        >
          { option.label }
        </div>
      </>
    )
  }

  const thereIsCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const isEnabled = () => isValid(product?.referenceProduct) || product?.hasInventory || thereIsCustomImpact

  return (
    <SelectButtonWrapperLayout>
      { !isEnabled() && <Tooltip className="w-15rem" target=".disabled-toggle-button" position="bottom" disabled={isEnabled()} /> }

      <div className="flex disabled-toggle-button" data-pr-tooltip={t('messages.description', { context: 'addImpactToProduct' })}>
        <SelectButton
          className="h-2rem"
          unselectable={false}
          disabled={!isEnabled()}
          value={selectedDecimalViewType}
          options={decimalViewOptions}
          onChange={(event: any) => onTagChange(event.value)}
          itemTemplate={viewTemplate}
          optionLabel="value"
          data-cy="impact-view-switch"
        />
      </div>
    </SelectButtonWrapperLayout>
  )
}
