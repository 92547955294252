import React, { useContext } from 'react'
import styled from 'styled-components'

import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import { HeaderDetailComponent } from '../component/header/header-detail.component'
import { TaskbarDetailComponent } from '../component/taskbar/taskbar-detail.component'
import { ModelLayout } from './lifecycle-model.layout'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { InsightLayout } from './lifecycle-insight.layout'
import { ModelFixedSectionComponent } from '../component/model/model-fixed-section.component'

const LifecycleWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`
export const LifecycleDetailLayout = () => {
  const {
    showPdfInsightsResult,
    lifecycleDetail,
    selectedInventoryViewType
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model
  const isInsightRendered = () => !isModelViewType() || showPdfInsightsResult

  return (
    <LifecycleWrapper
      data-testid="lifecycle-detail-wrapper"
      className="relative w-full h-screen flex-column lifecycle-detail-wrapper"
    >
      <div className="absolute top-0 left-0 flex w-full h-full flex-column">
        <div data-testid="lifecycle-detail-header" className="flex flex-column w-full h-8rem mb-3 px-6 py-2">
          { lifecycleDetail && <HeaderDetailComponent />}
        </div>
        <div data-testid="lifecycle-detail-taskbar" className="flex w-full h-3rem px-6 py-2">
          <TaskbarDetailComponent />
        </div>
        <div data-testid="lifecycle-detail-body" className="flex w-full px-6 pt-3 flex-column">
          { isModelViewType() && <ModelLayout /> }
          { isInsightRendered() && <InsightLayout /> }
        </div>
      </div>

      { isModelViewType() && <ModelFixedSectionComponent /> }
    </LifecycleWrapper>
  )
}
