import { gql } from '@apollo/client'

export default gql`
  query activityProducts($phaseID: ID!) {
    activityProducts(phaseID: $phaseID){
      inventoryID
      phase {
        id
        name
      }
      product {
        id
        name
        productProperties {
          referenceProperty {
            id
            name
          }
        }
      }
      productID
    }
  }
`
