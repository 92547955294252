import { printWarning } from '../../utils/devTools'
import { InventoryTreeKey } from '../../utils/inventoryTreeKey'
import { returnNested, safeArray, safeList, hasIndex } from '../../utils/tools'
import { PRODUCT_LABEL, SORT_ORDER } from '../../utils/const'
import { fromJS } from 'immutable'
import {
  CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT,
  CLEAR_GLOBAL_STATE_EXCEPT_USER,
  CLEAR_INVENTORY_TREE_STATE,
  CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE
} from '../actions/clear.actions'
import {
  getUpdatedListByActionParams,
  mapAccountPermission,
} from '../../utils/reducerTools'
import {
  ADD_ACCOUNT_TO_USER,
  SET_ACCOUNT_CUSTOMER,
  SET_ACCOUNT_TRIAL_INFO,
  SET_POSSIBLE_INVENTORY_ITEMS,
  SET_FAVORITE_PRODUCTS,
  SET_PRODUCT_LABEL,
  ADD_PRODUCT_TO_FAVORITE,
  REMOVE_PRODUCT_FROM_FAVORITE,
  SET_PRODUCT_IMPORT_MODAL_VISIBILITY,
  SET_PRODUCT_IMPORT_COLUMNS,
  REMOVE_PRODUCT_REFERENCE,
  SET_PRODUCT_LIST_SORT_TYPE,
  ADD_TAG_TO_SELECTED_PRODUCT,
  ADD_TAGS_TO_SELECTED_PRODUCT,
  SET_SPACE_PRODUCT_TAG_LIST,
  SET_PRODUCT_TAGS_TO_PRODUCT_FILTER,
  REMOVE_TAG_FROM_SELECTED_PRODUCT,
  SET_MODULES,
  SET_MODULE_TO_PRODUCT,
  SET_VERSION_NUMBER,
  SET_AMOUNT_MODAL_PREFERENCE,
  SET_SELECTED_FOLDER,
  SET_IMPACT_METHODS,
  SET_IMPACT_CATEGORIES,
  SET_NW_SETS
} from '../actions/global.actions'
import {
  SET_ACCOUNT_NAME,
  SET_INVITED_USERS_LIST
} from '../actions/account.actions'

const defaultGlobalsState = {
  selectedSpace: null,
  selectedAccount: null,
  selectedInventoryItem: '',
  selectedProduct: null,
  impactMethodsList: null,
  impactMethodSetsList: null,
  possibleProductList: null,
  workspaceActivePanels: null,
  lifecycleActivePanels: null,
  selectedProductToCompareId: null,
  referencePropertiesList: [],
  possibleInventoryItems: [],
  sidebarExpandedKeys: [ 'workspace-list', 'lifecycle-list', 'process-list', 'product-list' ],
  user: null,
  productFilter: {
    product: { selectedCategories: [], query: '', productTags: [] },
    object: { selectedCategories: [], query: '', productTags: [] }
  },
  listOfProductsForTransportation: [],
  vehicleList: [],
  activityTemplates: [],
  activityProductList: [],
  resourcePolicies: {
    selectedSpace: [],
    selectedAccount: []
  },
  resourceTypePoliciesLists: {
    account: [],
    space: []
  },
  selectedActivityTemplate: null,
  currentProductPageNumber: 1,
  currentProcessPageNumber: 1,
  currentLifecyclePageNumber: 1,
  productLabel: PRODUCT_LABEL.PRODUCT,
  subscriptionProducts: [],
  invoices: [],
  favoriteProducts: [],
  productImportModalVisibility: false,
  productImportColumns: [],
  fullSpaceProductList: [],
  productListSortType: { field: 'updated', order: SORT_ORDER.DESCENDING },
  spaceProductTagList: [],
  selectedDatabases: [],
  versionNumber: 0,
  amountModalPreference: { visible: false, key: null, top: 0, left: 0 },
  selectedFolder: null,
  invitedUsersList: [],
  impactMethods: [],
  impactCategories: [],
  nwSets: []
}

const globalReducer = (state = defaultGlobalsState, action={} ) => {
  switch (action.type) {
  case CLEAR_GLOBAL_STATE_EXCEPT_USER:
    return {
      ...defaultGlobalsState,
      user: {
        ...state.user
      },
      impactMethodSetsList: state.impactMethodSetsList,
      versionNumber: state.versionNumber
    }
  case CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT:
    return {
      ...defaultGlobalsState,
      selectedAccount: {
        ...state.selectedAccount,
        inventory: null
      },
      user: {
        ...state.user
      },
      resourcePolicies: {
        selectedSpace: [],
        selectedAccount: [ ...state.resourcePolicies.selectedAccount ]
      },
      impactMethodSetsList: state.impactMethodSetsList,
      versionNumber: state.versionNumber
    }
  case 'SET_USER':
    return { ...state, user: action.user }
  case SET_PRODUCT_LIST_SORT_TYPE:
    return { ...state, productListSortType: action.productListSortType }
  case 'SET_IMPACT_METHOD_SETS':
    return { ...state, impactMethodSetsList: action.impactMethodSetsList }
  case SET_IMPACT_METHODS:
    return { ...state, impactMethods: action.impactMethods }
  case SET_IMPACT_CATEGORIES:
    return { ...state, impactCategories: action.impactCategories }
  case SET_NW_SETS:
    return { ...state, nwSets: action.nwSets }
  case 'SET_SELECTED_SPACE':
    return { ...state, selectedSpace: action.space }
  case 'SET_FULL_SPACE_PRODUCT_LIST':
    return { ...state, fullSpaceProductList: action.list }
  case 'SET_SELECTED_SPACE_PRODUCTS':
    return {
      ...state,
      selectedSpace: {
        ...state.selectedSpace,
        products: action.products
      }
    }
  case 'SET_SELECTED_SPACE_FOLDERS':
    return {
      ...state,
      selectedSpace: {
        ...state.selectedSpace,
        folders: action.folders
      }
    }
  case 'CHANGE_SPACE_IMPACT_SETTINGS':
    return { ...state, selectedSpace:
      {
        ...state.selectedSpace,
        impactMethod: action.impactMethod,
        nwSet: action.nwSet,
        impactCategory: action.impactCategory,
        excludeLT: action.excludeLT,
        useMethodTotal: action.useMethodTotal
      }
    }
  case 'SET_SELECTED_SPACE_PROCESSES':
    return {
      ...state,
      selectedSpace: {
        ...state.selectedSpace,
        processes: action.processes
      }
    }
  case 'REMOVE_LIFECYCLE_FROM_SPACE':{
    let selectedSpaceForRemoveLifecycle = { ...state.selectedSpace }
    selectedSpaceForRemoveLifecycle.lifecycles = selectedSpaceForRemoveLifecycle.lifecycles.filter(ls => ls.id !== action.lifecycleId)

    return { ...state, selectedSpace: selectedSpaceForRemoveLifecycle }
  }
  case 'ADD_LIFECYCLE_TO_SPACE':{
    let selectedSpaceCopyForAddLifecycle = { ...state.selectedSpace }

    selectedSpaceCopyForAddLifecycle.lifecycles = [ ...selectedSpaceCopyForAddLifecycle.lifecycles, action.lifecycle ]
    return {
      ...state,
      selectedSpace: selectedSpaceCopyForAddLifecycle
    }
  }
  case ADD_ACCOUNT_TO_USER:{
    return {
      ...state,
      user: {
        ...state.user,
        accounts: []
          .concat(safeArray(returnNested(state.user, 'accounts')), action.account)
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    }
  }
  case 'SET_SELECTED_ACCOUNT':{
    return { ...state, selectedAccount: action.account }
  }
  case 'ADD_SPACE':{
    let selectedAccountCopyForAddSpace = { ...state.selectedAccount }
    let selectedUserCopyForAddSpace = { ...state.user }
    let accountIndexForAddSpace = safeArray(state.user.accounts).findIndex(account => account.id === state.selectedAccount.id)

    selectedUserCopyForAddSpace = hasIndex(accountIndexForAddSpace) ? fromJS(selectedUserCopyForAddSpace)
      .updateIn(
        [ 'accounts', accountIndexForAddSpace, 'spaces' ], spaces => safeList(spaces)
          .push({ ...action.space, __typename: 'Space' } )
      ).toJS()
      : selectedUserCopyForAddSpace

    selectedAccountCopyForAddSpace.spaces = [ ...safeArray(selectedAccountCopyForAddSpace.spaces), action.space ]
    return {
      ...state,
      selectedAccount: selectedAccountCopyForAddSpace,
      user: selectedUserCopyForAddSpace
    }
  }
  case 'DELETE_SPACE':{
    let selectedAccountCopyForDeleteSpace = { ...state.selectedAccount }
    let selectedUserCopyForDeleteSpace = { ...state.user }
    let accountIndexForRemoveSpace = safeArray(state.user.accounts).findIndex(account => account.id === state.selectedAccount.id)

    selectedUserCopyForDeleteSpace = hasIndex(accountIndexForRemoveSpace)
      ? fromJS(selectedUserCopyForDeleteSpace)
        .updateIn(
          [ 'accounts', accountIndexForRemoveSpace, 'spaces', ], spaces => safeList(spaces)
            .filter(space => action.spaceId !== space.get('id'))
        ).toJS()
      : selectedUserCopyForDeleteSpace
    selectedAccountCopyForDeleteSpace.spaces = safeArray(selectedAccountCopyForDeleteSpace.spaces).filter(el => el.id !== action.spaceId)
    return {
      ...state,
      selectedAccount: selectedAccountCopyForDeleteSpace,
      user: selectedUserCopyForDeleteSpace
    }
  }
  case 'SET_SPACE_NAME':{
    let selectedAccountCopyForRenameSpace = { ...state.selectedAccount }
    let selectedSpaceCopyForRenameSpace = { ...state.selectedSpace }
    selectedAccountCopyForRenameSpace.spaces = selectedAccountCopyForRenameSpace.spaces.map(el => {
      if (el.id === state.selectedSpace.id) {
        el.name = action.name
        el.slug = action.slug
      }
      return el
    })
    selectedSpaceCopyForRenameSpace.name = action.name
    selectedSpaceCopyForRenameSpace.slug = action.slug
    return {
      ...state,
      selectedAccount: selectedAccountCopyForRenameSpace,
      selectedSpace: selectedSpaceCopyForRenameSpace
    }
  }
  case 'SET_POSSIBLE_PRODUCT_LIST':
    return { ...state, possibleProductList: action.productList }
  case SET_POSSIBLE_INVENTORY_ITEMS:
    return { ...state, possibleInventoryItems: action.items }
  case SET_SPACE_PRODUCT_TAG_LIST:
    return { ...state, spaceProductTagList: action.spaceProductTagList }
  case ADD_TAG_TO_SELECTED_PRODUCT:{
    let selectedProductForProductChange = { ...state.selectedProduct }
    let spaceForProductChange = { ...state.selectedSpace }
    let favoriteProductsToAddTags = { ...state.favoriteProducts }

    if (spaceForProductChange?.products) {
      spaceForProductChange.products.items = spaceForProductChange.products.items.map(el => {
        if (el.id === state.selectedProduct.id) {
          el.tags = [
            ...state.selectedProduct.tags,
            action.productTag
          ]
        }
        return el
      })
    }

    if (favoriteProductsToAddTags?.items) {
      favoriteProductsToAddTags.items = favoriteProductsToAddTags.items.map(el => {
        if (el.id === state.selectedProduct.id) {
          el.tags = [
            ...state.selectedProduct.tags,
            action.productTag
          ]
        }
        return el
      })
    }

    selectedProductForProductChange.tags = [
      ...selectedProductForProductChange.tags,
      action.productTag
    ]

    return {
      ...state,
      favoriteProducts: favoriteProductsToAddTags,
      selectedProduct: selectedProductForProductChange,
      selectedSpace: spaceForProductChange
    }
  }
  case ADD_TAGS_TO_SELECTED_PRODUCT:{
    let selectedProductForNewTags = { ...state.selectedProduct }
    let selectedSpaceForAddProducts = { ...state.selectedSpace }

    if (selectedSpaceForAddProducts.products) {
      selectedSpaceForAddProducts.products.items = selectedSpaceForAddProducts.products.items.map(el => {
        if (el.id === state.selectedProduct.id) {
          el.tags = action.productTagList
        }
        return el
      })
    }

    selectedProductForNewTags.tags = action.productTagList
    return {
      ...state,
      selectedProduct: selectedProductForNewTags,
      selectedSpace: selectedSpaceForAddProducts
    }
  }
  case REMOVE_TAG_FROM_SELECTED_PRODUCT:{
    let selectedProductForRemoveTag = { ...state.selectedProduct }
    let selectedSpaceForRemoveTag = { ...state.selectedSpace }
    let favoriteProductsToRemoveTags = { ...state.favoriteProducts }

    if (selectedSpaceForRemoveTag?.products?.items) {
      selectedSpaceForRemoveTag.products.items =
        selectedSpaceForRemoveTag.products?.items?.map(el => {
          if (el.id === state.selectedProduct.id) {
            el.tags = el.tags.filter(tag => tag.id !== action.tagId)
          }
          return el
        })
    }

    if (favoriteProductsToRemoveTags?.items) {
      favoriteProductsToRemoveTags.items = favoriteProductsToRemoveTags.items.map(el => {
        if (el.id === state.selectedProduct.id) {
          el.tags = el.tags.filter(tag => tag.id !== action.tagId)
        }
        return el
      })
    }

    selectedProductForRemoveTag.tags = selectedProductForRemoveTag?.tags.filter(tag => tag.id !== action.tagId)

    return {
      ...state,
      favoriteProducts: favoriteProductsToRemoveTags,
      selectedProduct: selectedProductForRemoveTag,
      selectedSpace: selectedSpaceForRemoveTag
    }
  }
  case 'SET_SELECTED_PRODUCT':{
    return {
      ...state,
      selectedProduct: action.product
    }
  }
  case 'SET_SPACE_ACTIVE_PANELS':
    return { ...state, workspaceActivePanels: action.workspaceActivePanels }
  case SET_MODULES:
    return { ...state, modules: action.modules }
  case SET_MODULE_TO_PRODUCT:{
    let selectedProductForAddingNewModule = { ...state.selectedProduct }
    selectedProductForAddingNewModule.module = action.module

    return { ...state, selectedProduct: selectedProductForAddingNewModule }
  }
  case 'ADD_SPACE_PERMISSION':{
    let selectedSpaceForAddPermission = { ...state.selectedSpace }
    selectedSpaceForAddPermission.permissions.push(action.spacePermission)
    return { ...state, selectedSpace: selectedSpaceForAddPermission }
  }
  case 'CHANGE_SPACE_PERMISSION':{
    let updatedSpacePermission = state.selectedSpace.permissions.map(el => action.spacePermission.id === el.id ? action.spacePermission : el)
    return { ...state, selectedSpace: { ...state.selectedSpace, permissions: updatedSpacePermission } }
  }
  case 'REMOVE_SPACE_ROLE':{
    let selectedSpaceForRemovePermission = { ...state.selectedSpace }
    let updatedSpacePermissions = selectedSpaceForRemovePermission.permissions.filter(el => el.id !== action.spacePermissionId)
    selectedSpaceForRemovePermission.permissions = updatedSpacePermissions
    return { ...state, selectedSpace: selectedSpaceForRemovePermission }
  }
  case 'ADD_ACCOUNT_PERMISSION':{
    let selectedAccountForAddPermission = { ...state.selectedAccount }
    let mappedAccountPermission = mapAccountPermission(action.accountPermission)
    selectedAccountForAddPermission.accountPermissions = [ ...safeArray(selectedAccountForAddPermission.accountPermissions), mappedAccountPermission ]

    return { ...state, selectedAccount: selectedAccountForAddPermission }
  }
  case 'CHANGE_ACCOUNT_PERMISSION':{
    let mappedAccountForChangePermission = mapAccountPermission(action.accountPermission)
    let updatedAccountPermission = safeArray(state.selectedAccount.accountPermissions).map(el => action.accountPermission.id === el.id ? mappedAccountForChangePermission : el)
    return { ...state, selectedAccount: { ...state.selectedAccount, accountPermissions: updatedAccountPermission } }
  }
  case 'REMOVE_ACCOUNT_PERMISSION':{
    let selectedAccountForRemovePermission = { ...state.selectedAccount }
    let updatedAccountPermissions = safeArray(selectedAccountForRemovePermission.accountPermissions).filter(el => el.id !== action.accountPermissionId)
    selectedAccountForRemovePermission.accountPermissions = updatedAccountPermissions
    return { ...state, selectedAccount: selectedAccountForRemovePermission }
  }
  case 'ADD_ACCOUNT_ROLE':{
    let selectedAccountForAddRole = { ...state.selectedAccount }
    selectedAccountForAddRole.accountRoles = [ ...safeArray(selectedAccountForAddRole.accountRoles), action.accountRole ]
    return { ...state, selectedAccount: selectedAccountForAddRole }
  }
  case 'ADD_ACCOUNT_USER_TO_ROLE':{
    let roleIndexForAddUser = safeArray(state.selectedAccount.accountRoles).findIndex(role => role.id === action.roleId)
    let userIndexForAddRole = safeArray(state.selectedAccount.accountUsers).findIndex(user => user.id === action.accountUserId)
    let selectedAccountForAddUserToRole = { ...state.selectedAccount }
    selectedAccountForAddUserToRole = (hasIndex(roleIndexForAddUser) && hasIndex(userIndexForAddRole))
      ? fromJS(selectedAccountForAddUserToRole)
        .updateIn(
          [ 'accountUsers', userIndexForAddRole, 'roles' ], roles => safeList(roles)
            .push({ id: action.roleId, __typename: 'Role' })
        )
        .updateIn(
          [ 'accountRoles', roleIndexForAddUser, 'users' ], users => roleIndexForAddUser > -1 && safeList(users)
            .push({ id: action.accountUserId, name: action.accountUserName, __typename: 'User' })
        ).toJS()
      : selectedAccountForAddUserToRole
    return { ...state, selectedAccount: selectedAccountForAddUserToRole }
  }
  case 'REMOVE_ACCOUNT_USER_FROM_ROLE':{
    let roleIndexForRemoveUser = safeArray(state.selectedAccount.accountRoles).findIndex(role => role.id === action.roleId)
    let userIndexForRemoveRole = safeArray(state.selectedAccount.accountUsers).findIndex(user => user.id === action.accountUserId)
    let selectedAccountForRemoveUserFromRole = { ...state.selectedAccount }
    selectedAccountForRemoveUserFromRole = (hasIndex(userIndexForRemoveRole) && hasIndex(roleIndexForRemoveUser))
      ? fromJS(selectedAccountForRemoveUserFromRole)
        .updateIn(
          [ 'accountUsers', userIndexForRemoveRole, 'roles', ], roles => safeList(roles)
            .filter(role => action.roleId !== role.get('id'))
        )
        .updateIn(
          [ 'accountRoles', roleIndexForRemoveUser, 'users' ], users => safeList(users)
            .filter(user => action.accountUserId !== user.get('id'))
        ).toJS()
      : selectedAccountForRemoveUserFromRole
    return { ...state, selectedAccount: selectedAccountForRemoveUserFromRole }
  }
  case 'REMOVE_ACCOUNT_FROM_USER_ACCOUNT_LIST':{
    let userForRemovingAccount = { ...state.user }
    userForRemovingAccount.accounts = userForRemovingAccount.accounts.filter(el => el.id !== action.accountId)
    return { ...state, user: userForRemovingAccount }
  }
  case 'ADD_ACCOUNT_API_CLIENT_TO_ROLE_ACTION':{
    let roleIndexForAddApiClient = safeArray(state.selectedAccount.accountRoles).findIndex(role => role.id === action.roleId)
    let apiClientIndexForAddRole = safeArray(state.selectedAccount.accountApiClients).findIndex(apiClient => apiClient.id === action.accountApiClientId)
    let selectedAccountForAddApiClientToRole = { ...state.selectedAccount }
    selectedAccountForAddApiClientToRole = (hasIndex(roleIndexForAddApiClient) && hasIndex(apiClientIndexForAddRole))
      ? fromJS(selectedAccountForAddApiClientToRole)
        .updateIn(
          [ 'accountApiClients', apiClientIndexForAddRole, 'roles' ], roles => safeList(roles)
            .push({ id: action.roleId, __typename: 'Role' })
        )
        .updateIn(
          [ 'accountRoles', roleIndexForAddApiClient, 'apiClients' ], apiClients => safeList(apiClients)
            .push({ id: action.accountApiClientId, name: action.accountApiClientName, __typename: 'APIClient' })
        ).toJS()
      : selectedAccountForAddApiClientToRole
    return { ...state, selectedAccount: selectedAccountForAddApiClientToRole }
  }
  case 'REMOVE_ACCOUNT_API_CLIENT_FROM_ROLE_ACTION':{
    let roleIndexForRemoveApiClient = safeArray(state.selectedAccount.accountRoles).findIndex(role => role.id === action.roleId)
    let apiClientIndexForRemoveRole = safeArray(state.selectedAccount.accountApiClients).findIndex(apiClient => apiClient.id === action.accountApiClientId)
    let selectedAccountForRemoveApiClientFromRole = { ...state.selectedAccount }
    selectedAccountForRemoveApiClientFromRole = (hasIndex(roleIndexForRemoveApiClient) && hasIndex(apiClientIndexForRemoveRole))
      ? fromJS(state.selectedAccount)
        .updateIn(
          [ 'accountApiClients', apiClientIndexForRemoveRole, 'roles', ], roles => safeList(roles)
            .filter(role => action.roleId !== role.get('id'))
        )
        .updateIn(
          [ 'accountRoles', roleIndexForRemoveApiClient, 'apiClients' ], apiClients => safeList(apiClients)
            .filter(apiClient => action.accountApiClientId !== apiClient.get('id'))
        ).toJS()
      : selectedAccountForRemoveApiClientFromRole
    return { ...state, selectedAccount: selectedAccountForRemoveApiClientFromRole }
  }
  case 'REMOVE_ACCOUNT_ROLE':{
    let selectedAccountForRemoveRole = { ...state.selectedAccount }
    let updatedAccountRoles = safeArray(selectedAccountForRemoveRole.accountRoles).filter(el => el.id !== action.accountRoleId)
    selectedAccountForRemoveRole.accountRoles = updatedAccountRoles
    return { ...state, selectedAccount: selectedAccountForRemoveRole }
  }
  case 'ADD_ACCOUNT_USER':{
    let selectedAccountForAddUser = { ...state.selectedAccount }
    selectedAccountForAddUser.accountUsers = selectedAccountForAddUser.accountUsers || []
    action.accountUser && selectedAccountForAddUser.accountUsers.push(action.accountUser)
    const rolesToAddIdList = safeArray(action.accountUser.roles).map(role => role.id)
    selectedAccountForAddUser = fromJS(selectedAccountForAddUser)
      .update(
        'accountRoles', roles => safeList(roles).map(role => rolesToAddIdList.includes(role.get('id'))
          ? role.update( 'users', users => safeList(users).push({ id: action.accountUser.id, name: action.accountUser.name, __typename: 'User' }))
          : role
        )).toJS()
    return { ...state, selectedAccount: selectedAccountForAddUser }
  }
  case 'REMOVE_ACCOUNT_USER':{
    let selectedAccountForRemoveUser = { ...state.selectedAccount }
    selectedAccountForRemoveUser.accountUsers = safeArray(selectedAccountForRemoveUser.accountUsers).filter(user => user.id !== action.accountUserId)
    selectedAccountForRemoveUser.accountPermissions = safeArray(selectedAccountForRemoveUser.accountPermissions).filter(permission => permission.ownerId !== action.accountUserId)
    selectedAccountForRemoveUser = fromJS(selectedAccountForRemoveUser)
      .update(
        'accountRoles', roles => safeList(roles).map(role => role
          .update( 'users', users => safeList(users)
            .filter(user => user.get('id') !== action.accountUserId)
          )
        )).toJS()
    return { ...state, selectedAccount: selectedAccountForRemoveUser }
  }
  case 'REMOVE_INVITATION':{
    let invitedUsersListForRemovingInvitation = [ ...state.invitedUsersList ].filter(invitation => invitation.email !== action.invitationEmail)

    return { ...state, invitedUsersList: invitedUsersListForRemovingInvitation }
  }
  case 'ADD_INVITED_USER_ACTION':{
    let selectedAccountForAddingUser = { ...state.selectedAccount }
    selectedAccountForAddingUser.invitedUsers = [
      ...safeArray(selectedAccountForAddingUser.invitedUsers),
      action.user
    ]
    return { ...state,
      invitedUsersList: [
        ...state.invitedUsersList,
        action.user
      ]
    }
  }
  case 'ADD_INVITATION_ROLE_TO_USER':{
    let invitedUsersListForAddingRoleToUser = [ ...state.invitedUsersList ].map(user => {
      if (user.email === action.email) {
        user.roles = [ ...user.roles, action.role ]
      }
      return user
    })

    return { ...state, invitedUsersList: invitedUsersListForAddingRoleToUser }

  }
  case 'REMOVE_INVITATION_ROLE_FROM_USER':{
    let invitedUsersListForRemovingRoleFromUser = [ ...state.invitedUsersList ].map(user => {
      if (user.email === action.email) {
        user.roles = user.roles.filter(role => role.id !== action.roleId)
      }
      return user
    })

    return { ...state, invitedUsersList: invitedUsersListForRemovingRoleFromUser }
  }
  case 'CHANGE_PRODUCT_REFERENCE':{
    let selectedProductForReferenceChange = { ...state.selectedProduct }

    selectedProductForReferenceChange.referenceProduct = action.referenceProduct

    return {
      ...state,
      selectedProduct: selectedProductForReferenceChange
    }

  }
  case 'RENAME_PRODUCT':{
    let selectedProductForProductRename = { ...state.selectedProduct }
    let selectedSpaceForProductRename = { ...state.selectedSpace }
    const renameProductFn = product => {
      return (product.id === action.productId)
        ? { ...product, name: action.newName }
        : product
    }

    // Rename products in space
    if (selectedSpaceForProductRename && Array.isArray(selectedSpaceForProductRename.products)) {
      selectedSpaceForProductRename.products = selectedSpaceForProductRename.products.map(renameProductFn)
    }

    // Rename selected product
    if ( selectedProductForProductRename.id === action.id) {
      selectedProductForProductRename = { selectedProductForProductRename, name: action.newName }
    }
    return {
      ...state,
      selectedProduct: selectedProductForProductRename,
      selectedSpace: selectedSpaceForProductRename
    }
  }
  case 'SET_SELECTED_INVENTORY_ITEM_KEY':{
    if ((typeof action.key === 'string' || action.key instanceof String) && !InventoryTreeKey.createFromKey(action.key)) {
      printWarning('SET_SELECTED_INVENTORY_ITEM_KEY: action.key "%s" is not a valid InventoryTreeKey', action.key)
    }
    return { ...state, selectedInventoryItem: action.key }
  }
  case 'UPDATE_SELECTED_INVENTORY_ITEM_KEY':{
    const keyForUpdateSelectedScenarioInventoryItemKey = InventoryTreeKey.createFromKey(state.selectedInventoryItem)
    keyForUpdateSelectedScenarioInventoryItemKey.productId = action.productId
    return { ...state, selectedInventoryItem: keyForUpdateSelectedScenarioInventoryItemKey.inventoryTreeKey() }
  }
  case 'ADD_ROOT_PROCESS':{
    let selectedSpaceCopyForAddRootProcess = { ...state.selectedSpace }

    selectedSpaceCopyForAddRootProcess.processes = [ ...selectedSpaceCopyForAddRootProcess.processes, action.process ]
    return { ...state, selectedSpace: selectedSpaceCopyForAddRootProcess }
  }
  case 'SET_ACCOUNT_ROLES':{
    let selectedAccountCopyForSetAccountRoles = { ...state.selectedAccount }
    selectedAccountCopyForSetAccountRoles.accountRoles = action.accountRoles

    return { ...state, selectedAccount: selectedAccountCopyForSetAccountRoles }
  }
  case 'SET_ACCOUNT_USERS':{
    let selectedAccountCopyForSetAccountUsers = { ...state.selectedAccount }
    selectedAccountCopyForSetAccountUsers.accountUsers = action.accountUsers

    return { ...state, selectedAccount: selectedAccountCopyForSetAccountUsers }
  }
  case 'SET_ACCOUNT_PERMISSIONS':{
    let selectedAccountCopyForSetAccountPermissions = { ...state.selectedAccount }
    selectedAccountCopyForSetAccountPermissions.accountPermissions = action.accountPermissions.map(permission => mapAccountPermission(permission))

    return { ...state, selectedAccount: selectedAccountCopyForSetAccountPermissions }
  }
  case SET_ACCOUNT_TRIAL_INFO:{
    return { ...state,
      selectedAccount: {
        ...state.selectedAccount,
        trialStart: action.trialStart,
        trialEnd: action.trialEnd,
        isTrialing: action.isTrialing,
        createdAt: action.createdAt
      }
    }
  }
  case SET_ACCOUNT_CUSTOMER:{
    return { ...state,
      selectedAccount: {
        ...state.selectedAccount,
        customer: action.customer,
      }
    }
  }
  case 'SET_ACCOUNT_RESOURCE_TYPE_POLICIES_LIST':{
    return {
      ...state,
      resourceTypePoliciesLists: {
        ...state.resourceTypePoliciesLists,
        account: action.resourceType
      }
    }
  }
  case 'SET_ACCOUNT_API_CLIENTS':{
    let selectedAccountCopyForSetAccountApiClients = { ...state.selectedAccount }
    selectedAccountCopyForSetAccountApiClients.accountApiClients = action.accountApiClients

    return { ...state, selectedAccount: selectedAccountCopyForSetAccountApiClients }
  }
  case 'SET_ACCOUNT_USAGE':
    return {
      ...state,
      selectedAccount: {
        ...state.selectedAccount,
        usage: action.usage
      } }
  case 'SET_ACCOUNT_PAID_USERS_COUNT':
    return {
      ...state,
      selectedAccount: {
        ...state.selectedAccount,
        paidUsersCount: action.count
      } }
  case 'SET_ACCOUNT_V2_API_CREDENTIALS':
    return {
      ...state,
      selectedAccount: {
        ...state.selectedAccount,
        v2ApiCredentials: {
          key: action.key,
          secret: action.secret
        }
      },
    }
  case 'ADD_ACCOUNT_API_CLIENT':{
    let selectedAccountCopyForAddAccountApiClient = { ...state.selectedAccount }
    selectedAccountCopyForAddAccountApiClient.accountApiClients = selectedAccountCopyForAddAccountApiClient.accountApiClients || []
    selectedAccountCopyForAddAccountApiClient.accountApiClients.push(action.accountApiClient)
    const apiClientRolesToAddIdList = safeArray(action.accountApiClient.roles).map(role => role.id)
    selectedAccountCopyForAddAccountApiClient = fromJS(selectedAccountCopyForAddAccountApiClient)
      .update(
        'accountRoles', roles => safeList(roles).map(role => apiClientRolesToAddIdList.includes(role.get('id'))
          ? role.update( 'apiClients', apiClients => safeList(apiClients).push({ id: action.accountApiClient.id, name: action.accountApiClient.name, __typename: 'APIClient' }))
          : role
        )).toJS()
    return { ...state, selectedAccount: selectedAccountCopyForAddAccountApiClient }
  }
  case 'REMOVE_ACCOUNT_API_CLIENT':{
    let selectedAccountCopyForRemoveAccountApiClient = { ...state.selectedAccount }
    selectedAccountCopyForRemoveAccountApiClient.accountApiClients = safeArray(selectedAccountCopyForRemoveAccountApiClient.accountApiClients).filter(apiClient => apiClient.id !== action.apiClientId)
    selectedAccountCopyForRemoveAccountApiClient.accountPermissions = safeArray(selectedAccountCopyForRemoveAccountApiClient.accountPermissions).filter(permission => permission.ownerId !== action.apiClientId)
    selectedAccountCopyForRemoveAccountApiClient = fromJS(selectedAccountCopyForRemoveAccountApiClient)
      .update(
        'accountRoles', roles => safeList(roles).map(role => role
          .update( 'apiClients', apiClients => safeList(apiClients)
            .filter(apiClient => apiClient.get('id') !== action.apiClientId)
          )
        )).toJS()
    return { ...state, selectedAccount: selectedAccountCopyForRemoveAccountApiClient }
  }
  case 'SET_INVENTORY_ROOT_ITEM_ID':
    return { ...state, inventoryRootItemId: action.inventoryRootItemId }
  case 'SET_INVENTORY_ROOT_ITEM_NAME':
    return { ...state, inventoryRootItemName: action.inventoryRootItemName }
  case 'SET_REFERENCE_PROPERTIES_LIST':
    return { ...state, referencePropertiesList: action.referencePropertiesList }
  case 'REMOVE_ROOT_PROCESS':{
    let selectedSpaceForRemoveRootProcess = { ...state.selectedSpace }
    selectedSpaceForRemoveRootProcess.processes = selectedSpaceForRemoveRootProcess.processes.filter(process => process.id !== action.processId)

    return { ...state, selectedSpace: selectedSpaceForRemoveRootProcess }
  }
  case 'REMOVE_PRODUCT':{
    const returnStateForRemoveProduct = { ...state }
    // Remove from selectedSpace
    const spaceProductsForRemoveProduct = returnNested(state, 'selectedSpace', 'products', 'items')
    const spaceProductsForRemoveFavoriteProduct = returnNested(state, 'favoriteProducts', 'items')
    if (spaceProductsForRemoveProduct) {
      returnStateForRemoveProduct.selectedSpace = { ...state.selectedSpace }
      returnStateForRemoveProduct.selectedSpace.products.items =
      spaceProductsForRemoveProduct.filter(product => product.id !== action.productId)
      returnStateForRemoveProduct.favoriteProducts = { ...state.favoriteProducts }
      returnStateForRemoveProduct.favoriteProducts.items =
      spaceProductsForRemoveFavoriteProduct.filter(product => product.id !== action.productId)
    }

    if (state.selectedProduct && state.selectedProduct.id === action.productId) {
      returnStateForRemoveProduct.selectedProduct = null
    }

    return returnStateForRemoveProduct
  }
  case REMOVE_PRODUCT_REFERENCE:
    return { ...state, selectedProduct: {
      ...state.selectedProduct,
      referenceProduct: null
    } }
  case 'SET_CATEGORIES_TO_PRODUCT_FILTER':
    return {
      ...state,
      productFilter: {
        ...state.productFilter,
        [state.productLabel]: {
          ...state.productFilter[state.productLabel],
          selectedCategories: action.categories
        }
      }
    }
  case SET_PRODUCT_TAGS_TO_PRODUCT_FILTER:
    return {
      ...state,
      productFilter: {
        ...state.productFilter,
        [state.productLabel]: {
          ...state.productFilter[state.productLabel],
          productTags: action.productTags
        }
      }
    }
  case 'SET_QUERY_OF_PRODUCT_FILTER':
    return {
      ...state,
      productFilter: {
        ...state.productFilter,
        [state.productLabel]: {
          ...state.productFilter[state.productLabel],
          query: String(action.query)
        }
      }
    }
  case 'CLEAR_PRODUCT_FILTERS':
    return {
      ...state,
      productFilter: {
        ...state.productFilter,
        [state.productLabel]: {
          selectedCategories: [], query: '', productTags: []
        }
      }
    }
  case 'SET_LIFECYCLE_NAME_IN_PANEL_LIST':{
    let selectedSpaceCopyForRenameLifecycle = { ...state.selectedSpace }
    selectedSpaceCopyForRenameLifecycle.lifecycles = selectedSpaceCopyForRenameLifecycle.lifecycles.map(el => {
      if (el.id === action.lifecycleId) {
        el.name = action.lifecycleName
        el.slug = action.lifecycleSlug
      }
      return el
    })
    return { ...state, selectedSpace: selectedSpaceCopyForRenameLifecycle }
  }
  case 'SET_SIDEBAR_EXPANDED_KEYS':
    return { ...state, sidebarExpandedKeys: action.keys }
  case 'SET_ACCOUNT_POLICIES':
    return {
      ...state,
      resourcePolicies: {
        ...state.resourcePolicies,
        selectedAccount: action.policies
      }
    }
  case 'SET_SPACE_POLICIES':
    return {
      ...state,
      resourcePolicies: {
        ...state.resourcePolicies,
        selectedSpace: action.policies
      }
    }
  case 'SET_SPACE_RESOURCE_TYPE_POLICIES_LIST':
    return {
      ...state,
      resourceTypePoliciesLists: {
        ...state.resourceTypePoliciesLists,
        space: action.resourceType
      }
    }
  case 'ADD_PRODUCT_TO_LIST_OF_PRODUCTS_FOR_TRANSPORTATION':
    return { ...state, listOfProductsForTransportation: [ ...state.listOfProductsForTransportation, action.product ] }
  case 'CLEAR_LIST_OF_PRODUCTS_FOR_TRANSPORTATION':
    return { ...state, listOfProductsForTransportation: [] }
  case 'REMOVE_PRODUCT_FROM_LIST_OF_PRODUCTS_FOR_TRANSPORTATION':
    return {
      ...state,
      listOfProductsForTransportation: state.listOfProductsForTransportation.filter(product => product.key !== action.key)
    }
  case 'SET_TRANSPORTATION_PRODUCTS':
    return { ...state, vehicleList: action.vehicleList }
  case 'SET_ACTIVITY_TEMPLATES':
    return { ...state, activityTemplates: action.activityTemplates }
  case 'SET_SELECTED_ACTIVITY_TEMPLATE':
    return { ...state, selectedActivityTemplate: action.template }
  case 'SET_ACTIVITY_PRODUCT_LIST':
    return { ...state, activityProductList: action.activityProductList }
  case 'REMOVE_ACTIVITY_TEMPLATE':
    return { ...state, activityTemplates: state.activityTemplates.filter(template => template.id !== action.templateId) }
  case 'SET_USER_PROFILE':
    return { ...state,
      user: {
        ...state.user,
        metadata: action.userMetadata
      }
    }
  case 'SET_ACCOUNT_SUBSCRIPTION':
    return { ...state,
      selectedAccount: {
        ...state.selectedAccount,
        subscription: action.subscription
      }
    }
  case 'SET_ACCOUNT_INVOICES':
    return { ...state,
      selectedAccount: {
        ...state.selectedAccount,
        invoices: action.invoices
      }
    }

  case 'SET_CURRENT_PRODUCT_PAGE_NUMBER':
    return { ...state, currentProductPageNumber: action.pageNumber }
  case 'SET_CURRENT_PROCESS_PAGE_NUMBER':
    return { ...state, currentProcessPageNumber: action.pageNumber }
  case 'SET_CURRENT_LIFECYCLE_PAGE_NUMBER':
    return { ...state, currentLifecyclePageNumber: action.pageNumber }
  case 'SET_SUBSCRIPTION_PRODUCTS':
    return { ...state, subscriptionProducts: action.subscriptionProducts }
  case SET_PRODUCT_LABEL:
    return { ...state, productLabel: action.label }
  case SET_FAVORITE_PRODUCTS:
    return { ...state, favoriteProducts: action.products }
  case ADD_PRODUCT_TO_FAVORITE:{
    let productIndexForAddFavorites = safeArray(state.selectedSpace.products.items)
      .findIndex(product => product.id === action.product.id)
    let selectedSpaceCopyForAddFavorites = fromJS({ ...state.selectedSpace })
      .setIn(
        [ 'products', 'items', productIndexForAddFavorites, 'favorite' ], true
      ).toJS()

    return {
      ...state,
      favoriteProducts: {
        ...state.favoriteProducts,
        items: [ ...state.favoriteProducts.items, action.product ]
      },
      selectedSpace: selectedSpaceCopyForAddFavorites
    }
  }
  case REMOVE_PRODUCT_FROM_FAVORITE:{
    let favoriteProductsToRemoveProduct = [ ...state.favoriteProducts.items ]
    let productIndexForRemoveFavorites = safeArray(state.selectedSpace.products.items)
      .findIndex(product => product.id === action.productId)
    let selectedSpaceCopyForRemoveFavorites = fromJS({ ...state.selectedSpace })
      .setIn(
        [ 'products', 'items', productIndexForRemoveFavorites, 'favorite' ], false
      ).toJS()
    return {
      ...state,
      favoriteProducts: {
        ...state.favoriteProducts,
        items: favoriteProductsToRemoveProduct.filter(product => product.id !== action.productId)
      },
      selectedSpace: selectedSpaceCopyForRemoveFavorites
    }
  }
  case 'CHANGE_PRODUCT_DESCRIPTION':{
    let selectedProductForDescriptionChange = { ...state.selectedProduct }
    let selectedSpaceForProductListUpdate = { ...state.selectedSpace }

    selectedProductForDescriptionChange.description = action.description

    if (selectedSpaceForProductListUpdate.products) {
      selectedSpaceForProductListUpdate.products.items =
      selectedSpaceForProductListUpdate.products.items
        .map(product => {
          if (product.id === action.productId) {
            product.description = action.description
          }
          return product
        })
    }

    return {
      ...state,
      selectedProduct: selectedProductForDescriptionChange,
      selectedSpace: selectedSpaceForProductListUpdate
    }
  }
  case SET_ACCOUNT_NAME:{
    let selectedAccountForChangeName = { ...state.selectedAccount }
    selectedAccountForChangeName.name = action.accountName

    let userAccountIndexToChangeName = safeArray(state.user.accounts)
      .findIndex(account => account.id === action.accountId)
    let userForChangeAccountName = fromJS({ ...state.user })
      .setIn(
        [ 'accounts', userAccountIndexToChangeName, 'name' ], action.accountName
      ).toJS()

    return {
      ...state,
      selectedAccount: selectedAccountForChangeName,
      user: userForChangeAccountName
    }
  }
  case SET_PRODUCT_IMPORT_MODAL_VISIBILITY:
    return {
      ...state,
      productImportModalVisibility: action.visible
    }
  case SET_PRODUCT_IMPORT_COLUMNS:
    return {
      ...state,
      productImportColumns: action.columns
    }
  case CLEAR_INVENTORY_TREE_STATE:
    return {
      ...state,
      inventoryRootItemId: null,
      inventoryRootItemName: null,
      selectedInventoryItem: null,
      selectedProduct: null
    }
  case CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE:
    return {
      ...state,
      selectedInventoryItem: null,
      selectedProduct: null
    }
  case SET_VERSION_NUMBER:
    return {
      ...state,
      versionNumber: action.versionNumber
    }
  case SET_AMOUNT_MODAL_PREFERENCE:
    return {
      ...state,
      amountModalPreference: action.amountModalPreference
    }
  case SET_SELECTED_FOLDER:
    return {
      ...state,
      selectedFolder: action.folder
    }
  case SET_INVITED_USERS_LIST:
    return {
      ...state,
      invitedUsersList: action.invitedUsersList
    }
  default:
    return state
  }
}

export default globalReducer
