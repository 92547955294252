import { fromJS } from 'immutable'
import { isEmpty, returnNested, safeArray } from '../../utils/tools'
import {
  SET_COMPARISON_ENTITIES,
  SET_COMPARISON_FLAT_VIEW,
  SET_ENTITY_TO_COMPARE,
  SET_IS_COMPARISON_ENTRIES_LOADING,
  SET_IS_INVENTORY_COMPARE,
} from '../actions/comparison.actions'
import { REMOVE_PRODUCT, RENAME_PRODUCT } from '../actions/global.actions'
import {
  REMOVE_LIFECYCLE, REMOVE_LIFECYCLE_SCENARIO,
  RENAME_LIFECYCLE, RENAME_LIFECYCLE_SCENARIO,
  REORDER_PHASES,
  SET_LIFECYCLE_AMOUNT, SET_LIFECYCLE_SCENARIO_AMOUNT, SET_LIFECYCLE_SCENARIO_UNIT,
  SET_LIFECYCLE_UNIT,
  SET_LIFECYCLE_PRODUCT,
  SET_LIFECYCLE_SCENARIO_PRODUCT
} from '../actions/lifecycle.actions'
import { CLEAR_INVENTORY_TREE_STATE } from '../actions/clear.actions'

const defaultState = {
  entities: [],
  entityToCompare: undefined,
  isComparisonEntriesLoading: false,
  isInventoryCompare: false,
  comparisonFlatView: null,
  inventoryItems: null
}

const comparisonReducer = (state = defaultState, action={}) => {
  switch (action.type) {
  case 'CLEAR_GLOBAL_STATE_EXCEPT_USER':
  case 'CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT':
    return { ...defaultState }
  case SET_COMPARISON_FLAT_VIEW:
    return {
      ...state,
      comparisonFlatView: action.flatView
    }
  case SET_COMPARISON_ENTITIES:
    return {
      ...state,
      entities: action.entities
    }
  case SET_ENTITY_TO_COMPARE:
    return {
      ...state,
      entityToCompare: action.entity
    }
  case SET_IS_COMPARISON_ENTRIES_LOADING:
    return {
      ...state,
      isComparisonEntriesLoading: action.status
    }
  case REORDER_PHASES:{
    let newStore = fromJS(state)

    if (newStore.hasIn([ 'comparisonFlatView', 'items' ])) {
      newStore = newStore.updateIn([ 'comparisonFlatView', 'items' ], items => {
        return items.map(item => {
          return item.set('phaseOrder', safeArray(action.phaseIds).findIndex(phaseId => phaseId === item.get('phaseId')))
        })
      })
    }

    const safePhases = safeArray(returnNested(state, 'entityToCompare', 'phases'))
    const isChangeComparisonLifecycle = returnNested(state, 'entityToCompare', 'id') === action.lifecycleId
    if (isChangeComparisonLifecycle && !isEmpty(safePhases)) {
      const reorderedPhases = []
      for (let phaseIndex = 0; phaseIndex < safeArray(action.phaseIds).length; phaseIndex++) {
        const foundPhaseInLifecycle = safePhases.find(phase => phase.id === action.phaseIds[phaseIndex])
        foundPhaseInLifecycle && reorderedPhases.push(foundPhaseInLifecycle)
      }
      newStore = newStore.setIn([ 'entityToCompare', 'phases' ], fromJS(reorderedPhases))
    }

    return newStore ? newStore.toJS() : state
  }
  case RENAME_PRODUCT:{
    const renameProductEntityIndex = state.entities.findIndex(entity => entity.id === action.productId)
    if (renameProductEntityIndex === -1) return state
    return fromJS(state)
      .setIn([ 'entities', renameProductEntityIndex, 'name' ], action.newName)
      .toJS()
  }
  case RENAME_LIFECYCLE:
  case RENAME_LIFECYCLE_SCENARIO:{
    const renameLifecycleEntityIndex = state.entities.findIndex(entity => entity.id === action.lifecycleId)
    let stateRenameToUpdate = fromJS(state)
    if (renameLifecycleEntityIndex !== -1) {
      stateRenameToUpdate = stateRenameToUpdate.setIn([ 'entities', renameLifecycleEntityIndex, 'name' ], action.lifecycleName)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityA', 'id') === action.lifecycleId) {
      stateRenameToUpdate = stateRenameToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityA', 'name' ], action.lifecycleName)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityB', 'id') === action.lifecycleId) {
      stateRenameToUpdate = stateRenameToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityB', 'name' ], action.lifecycleName)
    }
    return stateRenameToUpdate.toJS()
  }
  case SET_IS_INVENTORY_COMPARE:
    return { ...state, isInventoryCompare: action.status }
  case REMOVE_PRODUCT:{
    const removeProductEntityIndex = state.entities.findIndex(entity => entity.id === action.productId)
    if (removeProductEntityIndex === -1) return state
    let removeProductCpState = fromJS(state).removeIn([ 'entities', removeProductEntityIndex ])
    if (removeProductCpState.getIn([ 'entityToCompare', 'id' ]) === action.productId) {
      removeProductCpState = removeProductCpState.delete('entityToCompare')
      removeProductCpState = removeProductCpState.set('isInventoryCompare', false)
    }
    return removeProductCpState.toJS()
  }
  case REMOVE_LIFECYCLE:
  case REMOVE_LIFECYCLE_SCENARIO:{
    const rlEntityIndex = state.entities.findIndex(entity => entity.id === action.id)
    let stateRemoveLifecycleToUpdate = fromJS(state)
    if (rlEntityIndex !== -1) {
      stateRemoveLifecycleToUpdate = stateRemoveLifecycleToUpdate.removeIn([ 'entities', rlEntityIndex ])
    }
    if (stateRemoveLifecycleToUpdate.getIn([ 'entityToCompare', 'id' ]) === action.id) {
      stateRemoveLifecycleToUpdate = stateRemoveLifecycleToUpdate.delete('entityToCompare')
      stateRemoveLifecycleToUpdate = stateRemoveLifecycleToUpdate.set('isInventoryCompare', false)
    }
    return stateRemoveLifecycleToUpdate.toJS()
  }
  case SET_LIFECYCLE_AMOUNT:
  case SET_LIFECYCLE_SCENARIO_AMOUNT:{
    const setLifecycleAmountEntityIndex = state.entities.findIndex(entity => entity.id === action.lifecycleId)
    let stateAmountToUpdate = fromJS(state)
    if (setLifecycleAmountEntityIndex !== -1) {
      stateAmountToUpdate = stateAmountToUpdate.setIn([ 'entities', setLifecycleAmountEntityIndex, 'amount' ], action.lifecycleAmount)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityA', 'id') === action.lifecycleId) {
      stateAmountToUpdate = stateAmountToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityA', 'amount' ], action.lifecycleAmount)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityB', 'id') === action.lifecycleId) {
      stateAmountToUpdate = stateAmountToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityB', 'amount' ], action.lifecycleAmount)
    }
    return stateAmountToUpdate.toJS()
  }
  case SET_LIFECYCLE_PRODUCT:
  case SET_LIFECYCLE_SCENARIO_PRODUCT:{
    const setLifecycleProductEntityIndex = state.entities.findIndex(entity => entity.id === action.lifecycleId)
    let stateProductToUpdate = fromJS(state)
    if (setLifecycleProductEntityIndex !== -1) {
      stateProductToUpdate = stateProductToUpdate.setIn([ 'entities', setLifecycleProductEntityIndex, 'product' ], action.lifecycleProduct)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityA', 'id') === action.lifecycleId) {
      stateProductToUpdate = stateProductToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityA', 'product' ], action.lifecycleProduct)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityB', 'id') === action.lifecycleId) {
      stateProductToUpdate = stateProductToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityB', 'product' ], action.lifecycleProduct)
    }
    return stateProductToUpdate.toJS()
  }
  case SET_LIFECYCLE_UNIT:
  case SET_LIFECYCLE_SCENARIO_UNIT:{
    const setLifecycleUnitEntityIndex = state.entities.findIndex(entity => entity.id === action.lifecycleId)
    let stateUnitToUpdate = fromJS(state)
    if (setLifecycleUnitEntityIndex !== -1) {
      stateUnitToUpdate = stateUnitToUpdate.setIn([ 'entities', setLifecycleUnitEntityIndex, 'unit' ], action.lifecycleUnit)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityA', 'id') === action.lifecycleId) {
      stateUnitToUpdate = stateUnitToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityA', 'unit' ], action.lifecycleUnit)
    }
    if (returnNested(state, 'comparisonFlatView', 'headers', 'entityB', 'id') === action.lifecycleId) {
      stateUnitToUpdate = stateUnitToUpdate.setIn([ 'comparisonFlatView', 'headers', 'entityB', 'unit' ], action.lifecycleUnit)
    }
    return stateUnitToUpdate.toJS()
  }
  case CLEAR_INVENTORY_TREE_STATE:
    return {
      ...state,
      comparisonFlatView: null,
      entityToCompare: null,
      entities: [],
      isInventoryCompare: false
    }
  default:
    return state
  }
}

export default comparisonReducer
