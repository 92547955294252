import { call, put, takeLatest } from 'redux-saga/effects'
import resource from '../../utils/resource/resource'
import {
  setAccountSubscriptionAction,
  setSubscriptionProductsAction,
  setSelectedAccountCustomerAction
} from '../actions/global.actions'
import { loadAccountSettingsSaga } from './account.saga'
import { dispatchErrors } from './helpers'

export const
  GET_SUBSCRIPTION_PRODUCTS   = 'GET_SUBSCRIPTION_PRODUCTS_SAGA',
  START_CHECKOUT_SETUP        = 'START_CHECKOUT_SETUP_SAGA',
  START_CHECKOUT_SUBSCRIPTION = 'START_CHECKOUT_SUBSCRIPTION_SAGA',
  CANCEL_SUBSCRIPTION         = 'CANCEL_SUBSCRIPTION_SAGA',
  CHANGE_SUBSCRIPTION_PRODUCT = 'CHANGE_SUBSCRIPTION_PRODUCT_SAGA',
  UPDATE_BILLING_CUSTOMER     = 'UPDATE_BILLING_CUSTOMER_SAGA'

export const startCheckoutSetupSaga = (accountId, successUrl, cancelUrl) => ({
  type: START_CHECKOUT_SETUP,
  accountId, successUrl, cancelUrl
})

export const startCheckoutSubscriptionSaga = (accountId, subscriptionProductId, successUrl, cancelUrl, defaultQuantity) => ({
  type: START_CHECKOUT_SUBSCRIPTION,
  accountId, subscriptionProductId, successUrl, cancelUrl, defaultQuantity
})
export const changeSubscriptionProductSaga = (accountId, subscriptionProductId, cb) => ({
  type: CHANGE_SUBSCRIPTION_PRODUCT,
  accountId, subscriptionProductId, cb
})

export const cancelSubscriptionSaga = (accountId, cb) => ({
  type: CANCEL_SUBSCRIPTION,
  accountId, cb
})

export const getSubscriptionProductsSaga = () => ({
  type: GET_SUBSCRIPTION_PRODUCTS
})
/**
 * Params for creating an account.
 * @typedef {Object} UpdateBillingCustomerParams
 * @property {string} accountId
 * @property {string} name
 * @property {string} email
 * @property {string} phone
 * @property {string} country
 * @property {string} line1
 * @property {string} line2
 * @property {string} postalCode
 * @property {string} city
 * @property {string} state
 * @property {string} vat
 * @property {function} cb
 */
/**
 * @param {UpdateBillingCustomerParams} params
 */
export const updateBillingCustomerSaga = params => ({
  type: UPDATE_BILLING_CUSTOMER,
  ...params
})

function* startCheckoutSetupGen(action) {
  try {
    const startCheckoutSetupArgs = {
      accountID: action.accountId,
      successURL: action.successUrl,
      cancelURL: action.cancelUrl
    }
    const { redirectUrl }  = yield call(resource.mutateByParams, 'startCheckoutSetup', startCheckoutSetupArgs)
    window.location = redirectUrl
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* checkoutSubscription(action) {
  try {
    const startCheckoutSubscriptionArgs = {
      accountID: action.accountId,
      planID: action.subscriptionProductId,
      successURL: action.successUrl,
      cancelURL: action.cancelUrl,
      defaultQuantity: action.defaultQuantity
    }
    const { redirectUrl }  = yield call(resource.mutateByParams, 'startCheckoutSubscription', startCheckoutSubscriptionArgs)
    window.location = redirectUrl
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* doCancelSubscription(action) {
  try {
    const { accountId, cb = () => {} } = action || {}
    yield call(resource.mutateByParams, 'cancelSubscription', { accountID: accountId })
    yield put(loadAccountSettingsSaga(accountId))
    cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* doChangeSubscriptionProduct(action) {
  try {
    const subscription = yield call(resource.mutateByParams, 'changePlan', { accountID: action.accountId, planID: action.subscriptionProductId })
    yield put(setAccountSubscriptionAction(subscription))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export function* fetchSubscriptionProducts() {
  try {
    const subscriptionProducts = yield call(resource.queryByParams, 'subscriptionProducts')
    yield put(setSubscriptionProductsAction(subscriptionProducts))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

/**
 * @param {UpdateBillingCustomerParams} action
 */
export function* doUpdateBillingCustomer(action) {
  try {
    const updateBillingCustomerArgs = {
      accountID: action.accountId,
      email: action.email,
      name: action.name,
      phone: action.phone,
      vat: action.vat,
      address: {
        country: action.country,
        line1: action.line1,
        line2: action.line2,
        postalCode: action.postalCode,
        city: action.city,
        state: action.state,
      }
    }
    const customer = yield call(resource.mutateByParams, 'updateBillingCustomer', updateBillingCustomerArgs)

    yield put(setSelectedAccountCustomerAction(customer))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export default function* stripeSaga() {
  yield takeLatest(START_CHECKOUT_SETUP, startCheckoutSetupGen)
  yield takeLatest(START_CHECKOUT_SUBSCRIPTION, checkoutSubscription)
  yield takeLatest(GET_SUBSCRIPTION_PRODUCTS, fetchSubscriptionProducts)
  yield takeLatest(CANCEL_SUBSCRIPTION, doCancelSubscription)
  yield takeLatest(CHANGE_SUBSCRIPTION_PRODUCT, doChangeSubscriptionProduct)
  yield takeLatest(UPDATE_BILLING_CUSTOMER, doUpdateBillingCustomer)
}
