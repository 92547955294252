import React, { useContext } from 'react'
import { withRouter } from '../../../../utils/with-router'
import ProductReferenceProperties from './product-reference-properties/productReferenceProperties.container'
import ProductInventoryProperties from './product-inventory-properties/productInventoryProperties.container'
import ProductGeneralInformation from './product-general-information/productGeveralInformation.container'
import ProductImpactInformation from './product-impact-information/productImpactInformation.container'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { returnNested } from '../../../../utils/tools'
import DetailsPanelTitle from '../detailsPanelTitle'
import TagsForm from '../../tags-form/tagsForm.container'
import ProductDetailContext from '../../../../v1/product/provider/context/product-detail.context'
import { AnalysisMode } from '../../../../v1/analysis/enum/analysis'
import AnalysisContext from '../../../../v1/analysis/provider/context/analysis.context'

const ProductDetails  = props => {
  const {
    selectedProduct,
    selectedInventoryItem,
    isCreateNewInventoryItem,
    updatePageItems,
    activeInventory,
    productMutator,
    isDetailsPanelReadOnly,
    isDetailPanelReadonlyV1: readonlyScenarioTree,
    isEnabledForV1 = false,
    t
  } = props
  const { analysisMode } = useContext(ProductDetailContext)
  const { getProductScenarioMutatorV1, scenarioSelectedKey } = useContext(AnalysisContext)

  const analysisInComparisonMode = analysisMode === AnalysisMode.Comparison
  const analysisInScenarioMode = analysisMode === AnalysisMode.Scenario
  const readonlyInventoryV1 = analysisInComparisonMode || readonlyScenarioTree
  const selectedProductMutator = ( analysisInScenarioMode && getProductScenarioMutatorV1 && selectedProduct && scenarioSelectedKey ) ?
    getProductScenarioMutatorV1(selectedProduct.id) : productMutator

  const inventoryPropertiesComponent = <ProductInventoryProperties
    inventoryTree={ activeInventory }
    selectedInventoryItem={ selectedInventoryItem }
    selectedProduct={ selectedProduct }
    isCreateNewInventoryItem={ isCreateNewInventoryItem }
    updatePageItems={ updatePageItems }
    isDetailsPanelReadOnly={ isDetailsPanelReadOnly || readonlyInventoryV1 }
    productMutator={selectedProductMutator}
    isEnabledForV1={isEnabledForV1}
    key={ returnNested(selectedProduct, 'id') + '_product_details' } />

  return (
    returnNested(selectedProduct, 'id') ? <div className="details-panel-wrapper">
      <DetailsPanelTitle
        title={ isCreateNewInventoryItem ? t('global.addPanelTitle', { productName: returnNested(selectedProduct, 'name') }) : returnNested(selectedProduct, 'name') }
        className="product-title"
        data-cy="selected-product-name-text" />

      <div className='details-panel-body'>
        {isCreateNewInventoryItem ?
          <div className="panel-section no-collapse">{inventoryPropertiesComponent}</div>
          :
          <>
            <ProductImpactInformation
              data-cy="product-impact-tab"
              selectedProduct={ selectedProduct }
              updatePageItems={ updatePageItems }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly || readonlyInventoryV1 }
              productMutator={ selectedProductMutator }
              key={ `${ selectedProduct && selectedProduct.id }_product_reference` } />

            <ProductGeneralInformation
              selectedProduct={ selectedProduct }
              updatePageItems={ updatePageItems }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly || readonlyInventoryV1 }
              productMutator={selectedProductMutator}
              key={ `${ selectedProduct && selectedProduct.id }_product_details` } />

            <ProductReferenceProperties
              selectedProduct={ selectedProduct }
              updatePageItems={ updatePageItems }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly || readonlyInventoryV1 }
              productMutator={selectedProductMutator}
              key={ `${ selectedProduct && selectedProduct.id }_product_reference_property` } />

            <TagsForm key={ selectedProduct.id }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly || readonlyInventoryV1 }
              updatePageItems={ updatePageItems } />
          </>
        }
      </div>
    </div> : null
  )
}

export { ProductDetails }
export default compose(
  withRouter,
  withTranslation(),
)(ProductDetails)
