import { connect } from 'react-redux'
import { InventoryTreeSelector } from '../../../../redux/selectors/inventoryTree.selector'
import D3Chart from './d3Chart'

const mapStateToProps = state => ({
  inventoryTreeSelectorProductId: InventoryTreeSelector.productId(state)
})

export default connect(
  mapStateToProps,
  null
)(D3Chart)