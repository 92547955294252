export const
  SET_DESCRIPTION_FOR_LIFECYCLES_PAGINATED_ITEM        = 'SET_DESCRIPTION_FOR_LIFECYCLES_PAGINATED_ITEM',
  SET_SELECTED_LIFECYCLE             = 'SET_SELECTED_LIFECYCLE',
  SET_LIFECYCLE_SCENARIO             = 'SET_LIFECYCLE_SCENARIO',
  SET_FLAT_VIEW                      = 'SET_FLAT_VIEW',
  SET_SELECTED_PHASE                 = 'SET_SELECTED_PHASE',
  RENAME_LIFECYCLE                   = 'RENAME_LIFECYCLE',
  RENAME_LIFECYCLE_SCENARIO          = 'RENAME_LIFECYCLE_SCENARIO',
  RENAME_LIFECYCLES_PAGINATED        = 'RENAME_LIFECYCLES_PAGINATED',
  RENAME_PHASE                       = 'RENAME_PHASE',
  REMOVE_PHASE                       = 'REMOVE_PHASE',
  REMOVE_LIFECYCLE                   = 'REMOVE_LIFECYCLE',
  REMOVE_LIFECYCLE_SCENARIO          = 'REMOVE_LIFECYCLE_SCENARIO',
  REMOVE_LIFECYCLES_PAGINATED        = 'REMOVE_LIFECYCLES_PAGINATED',
  REORDER_PHASES                     = 'REORDER_PHASES',
  SET_PHASE_TYPE                     = 'SET_PHASE_TYPE',
  SET_LIFECYCLE_DESCRIPTION          = 'SET_LIFECYCLE_DESCRIPTION',
  SET_LIFECYCLE_SCENARIO_DESCRIPTION = 'SET_LIFECYCLE_SCENARIO_DESCRIPTION',
  SET_LIFECYCLE_AMOUNT               = 'SET_LIFECYCLE_AMOUNT',
  SET_LIFECYCLE_SCENARIO_AMOUNT      = 'SET_LIFECYCLE_SCENARIO_AMOUNT',
  SET_LIFECYCLE_UNIT                 = 'SET_LIFECYCLE_UNIT',
  SET_LIFECYCLE_SCENARIO_UNIT        = 'SET_LIFECYCLE_SCENARIO_UNIT',
  SET_DETAILS_PANEL_LIFECYCLE_ID     = 'SET_DETAILS_PANEL_LIFECYCLE_ID',
  SET_LIFECYCLES_PAGINATED           = 'SET_LIFECYCLES_PAGINATED',
  SET_LIFECYCLE_PRODUCT              = 'SET_LIFECYCLE_PRODUCT',
  SET_LIFECYCLE_SCENARIO_PRODUCT     = 'SET_LIFECYCLE_SCENARIO_PRODUCT'

export const setSelectedLifecycleAction = lifecycle => ({
  type: SET_SELECTED_LIFECYCLE,
  lifecycle
})

export const setLifecyclesPaginatedAction = lifecyclesPaginated => ({
  type: SET_LIFECYCLES_PAGINATED,
  lifecyclesPaginated
})

export const setLifecycleScenarioAction = lifecycle => ({
  type: SET_LIFECYCLE_SCENARIO,
  lifecycle
})

export const setFlatViewAction = flatView => ({
  type: SET_FLAT_VIEW,
  flatView
})

export const setSelectedPhaseAction = phase => ({
  type: SET_SELECTED_PHASE,
  phase
})

export const renameLifecycleAction = (lifecycleName, lifecycleId) => ({
  type: RENAME_LIFECYCLE,
  lifecycleName, lifecycleId
})

export const renameLifecyclesPaginatedAction = ({ lifecycleName, lifecycleId }) => {
  return {
    type: RENAME_LIFECYCLES_PAGINATED,
    lifecycleName, lifecycleId
  }
}

export const setDescriptionForLifecyclesPaginatedListItemAction = ({ lifecycleDescription, lifecycleId }) => {
  return {
    type: SET_DESCRIPTION_FOR_LIFECYCLES_PAGINATED_ITEM,
    lifecycleDescription, lifecycleId
  }
}

export const renameLifecycleScenarioAction = (lifecycleName, lifecycleId) => ({
  type: RENAME_LIFECYCLE_SCENARIO,
  lifecycleName, lifecycleId
})

export const renamePhaseAction = (id, name) => ({
  type: RENAME_PHASE,
  id, name
})

export const removePhaseAction = id => ({
  type: REMOVE_PHASE,
  id
})

export const removeLifecycleFromPaginatedListAction = id => ({
  type: REMOVE_LIFECYCLES_PAGINATED,
  id
})

export const setPhaseTypeAction = (id, phaseType) => ({
  type: SET_PHASE_TYPE,
  id, phaseType
})
export const reorderPhasesAction = (lifecycleId, phaseIds) => ({
  type: REORDER_PHASES,
  lifecycleId, phaseIds
})

export const removeLifecycleAction = id => ({
  type: REMOVE_LIFECYCLE,
  id
})

export const removeLifecycleScenarioAction = id => ({
  type: REMOVE_LIFECYCLE_SCENARIO,
  id
})

export const setLifecycleDescriptionAction = (lifecycleDescription, lifecycleId) => ({
  type: SET_LIFECYCLE_DESCRIPTION,
  lifecycleDescription, lifecycleId
})

export const setLifecycleScenarioDescriptionAction = (lifecycleDescription, lifecycleId) => ({
  type: SET_LIFECYCLE_SCENARIO_DESCRIPTION,
  lifecycleDescription, lifecycleId
})

export const setLifecycleAmountAction = (lifecycleAmount, lifecycleId) => ({
  type: SET_LIFECYCLE_AMOUNT,
  lifecycleAmount, lifecycleId
})

export const setLifecycleScenarioAmountAction = (lifecycleAmount, lifecycleId) => ({
  type: SET_LIFECYCLE_SCENARIO_AMOUNT,
  lifecycleAmount, lifecycleId
})

export const setLifecycleUnitAction = (lifecycleUnit, lifecycleId) => ({
  type: SET_LIFECYCLE_UNIT,
  lifecycleUnit, lifecycleId
})
export const setLifecycleScenarioUnitAction = (lifecycleUnit, lifecycleId) => ({
  type: SET_LIFECYCLE_SCENARIO_UNIT,
  lifecycleUnit, lifecycleId
})

export const setDetailsPanelLifecycleIdAction = lifecycleId => ({
  type: SET_DETAILS_PANEL_LIFECYCLE_ID,
  lifecycleId
})

export const setLifecycleProductAction = (lifecycleProduct, lifecycleId) => ({
  type: SET_LIFECYCLE_PRODUCT,
  lifecycleProduct, lifecycleId
})

export const setLifecycleScenarioProductAction = (lifecycleProduct, lifecycleId) => ({
  type: SET_LIFECYCLE_SCENARIO_PRODUCT,
  lifecycleProduct, lifecycleId
})
