import { connect } from 'react-redux'
import ProductImpactInformation from './productImpactInformation'
import SpaceSelector from '../../../../../redux/selectors/space.selector'
import { referencePropertiesListSelector } from '../../../../../redux/selectors/product.selector'
import { changeProductPropertySaga } from '../../../../../redux/sagas/product.saga'

const mapStateToProps = state => ({
  impactMethod: SpaceSelector.impactMethod(state),
  impactNwSet: SpaceSelector.impactNwSet(state),
  impactCategory: SpaceSelector.impactCategory(state),
  impactUseMethodTotal: SpaceSelector.impactUseMethodTotal(state),
  impactExcludeLT: SpaceSelector.impactExcludeLT(state),
  referencePropertiesList: referencePropertiesListSelector(state)
})

const mapDispatchToProps = {
  changeProductPropertySaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductImpactInformation)
