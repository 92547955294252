import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { EDatabaseSearchColumn } from '../enum/impact-dataset-column'

import { IDatabaseSearchResult } from '../interface/impact-dataset-result'
import { transformToDatabaseSearchResult } from '../util/transform'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableBodyTemplateComponent } from './table/table-body-template.component'
import { IProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TableHeaderComponent } from './table/table-header-template.component'
import { ReferenceProductType } from '../enum/reference-product'
import { DatasetSortField, SortingOrder } from '../../../__generated__/graphql'

const TableWrapper = styled.div`
  .p-datatable {
    min-width: 50rem;

    .p-datatable-tbody {
      tr {
        border-radius: 0.5rem;
        box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.05);

        &:hover {
          box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.25);
          background-color: var(--primary-50);
        }

        td {
          cursor: auto;
        }
      }
    }

    .p-datatable-emptymessage {
      display:none !important;
    }
  }
`
type TDatabaseSearchComponentProp = {
  showResults: boolean
}
export const DatabaseSearchComponent = ({ showResults }: TDatabaseSearchComponentProp) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const {
    referenceProducts = [],
    selectedSortBy,
    selectedSortOrder,
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)
  const { selectedEntity = {} } = useContext<IDashboardContext>(DashboardContext)

  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct || {}

  let dataToTransform = referenceProducts
  if (referenceProducts.length > 0 && currentReferenceProduct?.id) {
    dataToTransform = [
      currentReferenceProduct?.type === ReferenceProductType.ProductSystem ? currentReferenceProduct : {},
      ...referenceProducts.filter(referenceProduct => referenceProduct.id !== currentReferenceProduct?.id)
    ]
  }
  const impactDatasetResults = transformToDatabaseSearchResult(dataToTransform)

  const handleSortTable = (sortBy: DatasetSortField, sortOrderTo: SortingOrder) => {
    if (selectedSortBy === sortBy && selectedSortOrder === SortingOrder.Desc) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
      return
    }

    updateFilterValue({ selectedSortBy: sortBy, selectedSortOrder: sortOrderTo })
  }

  const headerTemplate = ({
    label,
    column,
    sortBy = DatasetSortField.Name,
    sortDisabled = true
  } : {
    label: string,
    column?: EDatabaseSearchColumn,
    sortBy?: DatasetSortField,
    sortDisabled?: boolean
  }) => (
    <TableHeaderComponent
      {...{
        selectedSortOrder, sortDisabled, label, column, sortBy, selectedSortBy, onSortClick: handleSortTable
      }}
    />
  )

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const bodyTemplate = (rowData: IDatabaseSearchResult, column: EDatabaseSearchColumn) => (
    <TableBodyTemplateComponent
      value={rowData}
      column={column}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      isMouseOver={rowData.id === mouseOverOn}
    />
  )

  const setRowColorForSelected = (impactDataset: IDatabaseSearchResult) => {
    const isSelected = currentReferenceProduct?.id === impactDataset.id
    return { 'bg-primary-50': isSelected }
  }

  return (
    <TableWrapper
      className="flex w-full"
      data-testid="datasets-search-results-table"
    >
      <TableWrapperComponent>
        <DataTable
          value={showResults ? impactDatasetResults : []}
          rowClassName={setRowColorForSelected}
        >
          <Column
            field={EDatabaseSearchColumn.DatasetName}
            className="border-round-left-lg"
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.DatasetName)}
            header={headerTemplate({ label: t('labels.datasetName'), column: EDatabaseSearchColumn.DatasetName, sortDisabled: false })}
          />

          <Column
            field={EDatabaseSearchColumn.Geography}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Geography)}
            header={headerTemplate({ label: t('labels.geography'), column: EDatabaseSearchColumn.Geography, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Property}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Property)}
            header={headerTemplate({ label: t('labels.property', { ns: 'common' }), column: EDatabaseSearchColumn.Property, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Database}
            style={{ width: '9rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Database)}
            header={headerTemplate({ label: t('labels.database'), column: EDatabaseSearchColumn.Database, sortDisabled: true })}
          />

          <Column
            field={EDatabaseSearchColumn.Action}
            className="border-round-right-lg"
            style={{ width: '10rem' }}
            body={(rowData: IDatabaseSearchResult) => bodyTemplate(rowData, EDatabaseSearchColumn.Action)}
            header={() => <></>}
          />
        </DataTable>
      </TableWrapperComponent>
    </TableWrapper>
  )
}
