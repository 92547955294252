import React from 'react'
import * as Sentry from '@sentry/react'
import { render, createPortal } from 'react-dom'
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client'
import apolloLogger from 'apollo-link-logger'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { store } from '../../configureStore'
import { printWarning } from '../../utils/devTools'
import ErrorHandler from '../../component/error/errorHandler'

import GlobalSelector from '../../redux/selectors/global.selector'
import { getVersionNumber } from '../../utils/nginx'
import { cache, userAuth0Var } from '../../graphql/cache'

declare global {
  interface Error { statusCode: number; }
}
const networkLoading = { status: false }

// Error link
const errorLink = onError(({
  graphQLErrors,
  networkError,
  operation,
  response
}) => {
  store.dispatch({
    type: 'SET_IS_DATA_VIA_NETWORK_REQUESTING',
    status: false
  })

  if (graphQLErrors) {
    graphQLErrors.map(
      ({
        extensions, message, locations, path
      }) => {
        const graphqlErrorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`
        printWarning(graphqlErrorMessage)
        if (message !== 'An error occurred') return

        return Sentry.withScope(scope => {
          scope.setLevel('error')
          scope.setTag('kind', 'GraphQL')
          scope.setExtra('graphql_query', operation?.operationName)
          scope.setExtra('graphql_variables', operation?.variables)
          scope.setExtra('graphql_response', JSON.stringify(response))
          scope.setExtra('stacktrace', JSON.stringify(extensions))
          Sentry.captureMessage(`[GraphQL error]: Operation name: ${operation?.operationName}`)
        })
      }
    )

    getVersionNumber().then(
      response => {
        if (GlobalSelector.versionNumber(store.getState()) !== response.version) {
          const renderOn = document.querySelector('.middle-container') || document.getElementById('root')
          return renderOn && render(createPortal(<ErrorHandler />, renderOn), document.createElement('div'))
        }
      }
    )
  }

  if (networkError) {
    const networkErrorMessage = `[Network error]: ${networkError}`
    printWarning(networkErrorMessage)
  }
})

const withAuthTokenLink = setContext(async (_, previousContext) => {
  const { getIdTokenClaims } = userAuth0Var() || {}
  if (!getIdTokenClaims) return
  const { headers } = previousContext
  const claims = await getIdTokenClaims()
  return {
    headers: {
      ...headers,
      ...(claims && { Authorization: `Bearer ${claims.__raw}` }),
    },
  }
})

const resetAuthTokenLink = onError(({
  networkError,
}) => {
  if (networkError && networkError.statusCode === 401) {
    const { AUTH_RETURNTOURI = 'no-env' } = window.env || {}
    const { logout } = userAuth0Var() || {}
    if (!logout) return
    logout({
      logoutParams: {
        returnTo: AUTH_RETURNTOURI
      }
    })
  }
})

const authenticationLink = withAuthTokenLink.concat(resetAuthTokenLink)

const API_URL = (window.env && window.env.API_URL) || 'env-vars-missing'

// HTTP Link
const httpLink = new HttpLink({
  uri: API_URL,
  credentials: 'same-origin',
})

// Uncomment below in order to test if JS errors are logged properly,
// both locally and in jenkins when running cypress e2e tests
// Locally expect logs under: config/Testing/e2e-cypress/cypress/e2e-hodor-js-logs
// In jenkins, just go to JENKINS_URL/userContent
/*
  let err=new Error('This is my App error message')
  throw(err)
  */

const startRequestMiddleware = new ApolloLink((operation, forward) => {
  if (!networkLoading.status) {
    store.dispatch({
      type: 'SET_IS_DATA_VIA_NETWORK_REQUESTING',
      status: true
    })

    networkLoading.status = true
  }
  return forward(operation)
})

const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map(res => {
  const context = operation.getContext()
  const { response: { headers } } = context

  headers

  networkLoading.status = false
  store.dispatch({
    type: 'SET_IS_DATA_VIA_NETWORK_REQUESTING',
    status: false
  })
  return res
}))

const httpLinkWithMiddleware = afterwareLink.concat(startRequestMiddleware)

// Client
const link = ApolloLink.from([
  process.env.NODE_ENV !== 'production' ? apolloLogger : null,
  errorLink,
  authenticationLink,
  httpLinkWithMiddleware,
  httpLink,
].filter(x => !!x))

const clientWithAuth = new ApolloClient({
  link,
  cache
})

export default clientWithAuth
