import { gql } from '../../../__generated__'

export const CREATE_SPACE = gql(`
  mutation createSpace($name: String!, $accountID: ID!) {
    createSpace(name: $name, accountID: $accountID) {
      id
      name
      slug
    }
  }
`)

export const REMOVE_SPACE = gql(`
  mutation removeSpace($id: ID!) {
    removeSpace(id: $id)
  }
`)
