import React from 'react'
import { withTranslation } from 'react-i18next'
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'

import { Card, Col, Divider, Input, Popover, Row, Tag, Typography } from 'antd'

import compose from '../../utils/compose'
import { getOnlyLetters } from '../../utils/tools'
import { SUGGESTION_LIMIT } from '../../utils/const'

const { Paragraph, Text } = Typography

const SearchByQuery = ({ t, query, setQuery, selectedProductName }) => {
  const getSuggestionsFromName = () => {
    const parsedProductName = getOnlyLetters(selectedProductName)
    const suggestionsRowList = parsedProductName.split(' ')

    const suggestions = suggestionsRowList
      .filter(word => word.length > 1)
      .slice(0, SUGGESTION_LIMIT.NUMBER_OF_WORDS)

    if (suggestions.length > 1 && suggestions.length <= SUGGESTION_LIMIT.NUMBER_OF_WORDS) {
      suggestions.push(suggestionsRowList.slice(0, SUGGESTION_LIMIT.MAX_LAST_WORD_LENGTH).join(' '))
    }
    return suggestions
  }

  const selectSuggestion = event => {
    const suggestion = event.target.innerText
    if (!query.includes(suggestion)) {
      const updatedSearchQuery = query + ' ' + event.target.innerText
      setQuery(updatedSearchQuery.trim())
    }
  }

  const PopoverContent = (
    <Card style={{ width: 800 }} bordered={false}>
      <Row gutter={16} className='search-reference__tips'>
        <Col span={12}>
          <h3>{t('search_reference_product.search_tips')}</h3>
          <Divider/>
          <Paragraph><Text code>|</Text> { t('search_reference_product.tip_or') }</Paragraph>
          <Paragraph><Text code>'</Text> { t('search_reference_product.tip_match') }</Paragraph>
        </Col>
        <Col span={12}>
          <h3>{t('search_reference_product.search_examples')}</h3>
          <Divider className='divider-half-margin'/>
          <Paragraph><Text code>'chromium steel' -pipe</Text> { t('search_reference_product.example_match') }</Paragraph>
        </Col>
      </Row>
    </Card>
  )
  const tagList = getSuggestionsFromName()

  return (<Row>
    <Col span={14}>
      <Input className='search-reference__input'
        size='large'
        data-cy='input-search-reference-product'
        value={query}
        placeholder={t('global.search')}
        onChange={event => setQuery(event.target.value)}
        prefix={<SearchOutlined/>}
        suffix={
          <Popover placement='leftBottom' content={PopoverContent} trigger='hover'>
            <InfoCircleOutlined/>
          </Popover>
        }
      />
    </Col>
    <Col span={14} className='search-reference__suggestions'>
      <span>Suggestions: </span>
      {tagList.map((tag, index) => (
        <Tag
          key={index}
          onClick={event => selectSuggestion(event)}
          data-cy={`suggestion-named-${tag.toLocaleLowerCase()}`}
          className='search-reference__tag'
        >
          {tag}
        </Tag>)
      )}
    </Col>
  </Row>)
}

export { SearchByQuery }
export default compose(
  withTranslation()
)(SearchByQuery)
