import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

import { IPhase } from '../../../model'
import { setIsCreateNewInventoryItemAction, setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import { selectPhaseSaga } from '../../../../redux/sagas/lifecycle.saga'
import { AddNodeWrapperLayout } from '../../../product/layout/add-node-wrapper.layout'
import { store } from '../../../../configureStore'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

type TPhaseAddSubItemComponentProps = {
  phase: IPhase
}
export const PhaseAddSubItemComponent = ({
  phase
}: TPhaseAddSubItemComponentProps) => {
  const { t } = useTranslation([ 'lifecycle' ])
  const { updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const handleAddSubItem = (event: any) => {
    event.stopPropagation()
    updateLifecycleDetail({ selectedPhase: phase, showLifecycleDetailPanelOnly: false })
    store.dispatch(setIsCreateNewInventoryItemAction(true))
    store.dispatch(selectPhaseSaga(phase))
    store.dispatch(setIsDetailsPanelOpenAction(true))
  }

  return (
    <AddNodeWrapperLayout onClick={handleAddSubItem} dataCy="add-item-from-button" dataTestId="phase-add-sub-item">
      <div className="flex-none flex align-items-center justify-content-center">
        <FontAwesomeIcon data-testid="add-item-node-icon" icon={faPlus} className="text-base" />
      </div>
      <div data-testid="add-item-node-text" className="flex-grow-1 flex align-items-center text-base justify-content-start line-height-4">
        { t('actions.add', { context: 'itemToPhase' }) }
      </div>
    </AddNodeWrapperLayout>
  )
}

