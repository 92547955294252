import React, { useState } from 'react'
import { Panel } from 'primereact/panel'
import styled from 'styled-components'

import { GettingStatedChecklistItem } from '../../../shared/enum/sign-up-experience'
import { GettingStartedPanelHeaderComponent } from './getting-started-panel-header.component'
import { GettingStartedPanelContentComponent } from './getting-started-panel-content.component'

const PhaseItemWrapper = styled.div`
  .p-panel {
    width: 100%;
  }
  .p-panel-content {
    padding: 0;
    cursor: default;
  }

  .step-background {
    background: var(--primarydark-50);
  }
`
export const GettingStartedChecklistItemComponent = ({ gettingStartedChecklistItem, step }: { step: number, gettingStartedChecklistItem: GettingStatedChecklistItem }) => {
  const [ panelCollapsed, setPanelCollapsed ] = useState<boolean>(true)

  return (
    <PhaseItemWrapper className="flex w-full bg-white border-round-md p-2 relative">
      <Panel
        toggleable
        collapsed={panelCollapsed}
        onToggle={(e: any) => setPanelCollapsed(e.value)}
        headerTemplate={(options: any) => (
          <GettingStartedPanelHeaderComponent {...{
            gettingStartedChecklistItem, options, step, setPanelCollapsed
          }}
          />
        )}
      >
        <GettingStartedPanelContentComponent {...{ gettingStartedChecklistItem, step }} />
      </Panel>
    </PhaseItemWrapper>
  )
}
