import { connect } from 'react-redux'
import { setIsCreateLifecycleAction } from '../../../../redux/actions/flags.actions'
import { setQueryOfProductFilterAction } from '../../../../redux/actions/global.actions'
import { searchProductsSaga } from '../../../../redux/sagas/product.saga'
import { createLifecycleItemSaga } from '../../../../redux/sagas/lifecycle.saga'
import { inventoryRootItemIdSelector } from '../../../../redux/selectors/inventoryTree.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import AccountSelector from '../../../../redux/selectors/account.selector'
import SpaceSelector from '../../../../redux/selectors/space.selector'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'

import CreateLifecycle from './createLifecycle'

const mapStateToProps = state => ({
  accountId: AccountSelector.selectedAccountId(state),
  selectedSpaceId: SpaceSelector.spaceId(state),
  selectedSpaceProducts: SpaceSelector.spaceProducts(state),
  rootItemId: inventoryRootItemIdSelector(state),
  selectedLifecycle: LifecycleSelector.selectedLifecycle(state),
  spaceProducts: SpaceSelector.spaceProducts(state),
  workspaceSlug: SpaceSelector.spaceSlug(state)
})

const mapDispatchToProps = {
  createLifecycleItemSaga,
  trackEventSaga,
  setIsCreateLifecycleAction,
  searchProductsSaga,
  setQueryOfProductFilterAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLifecycle)
