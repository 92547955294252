import { connect } from 'react-redux'
import { clearRootStateExceptSettingsAction } from '../../redux/actions/clear.actions'
import { getUserSaga } from '../../redux/sagas/user.saga'
import LoginCallback from './loginCallback'

const mapDispatchToProps = {
  clearRootStateExceptSettingsAction,
  getUserSaga
}

export default connect(
  null,
  mapDispatchToProps
)(LoginCallback)
