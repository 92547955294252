import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IControllerRender } from '../../../shared/interface/react-form-hook'

const EditNameWrapper = styled.div`
  .phase-name-input {
    padding: 0 0.5rem;
    width: 12rem !important;
    height: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--primary-500);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .btn-menu-item {
    color: var(--gray-300);
    border-color: var(--gray-300);

    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
`

type PhaseEditComponentProps = {
  name?: string,
  handleEditPhase: (param: string) => void
  handleCancel: () => void
}
export const PhaseEditComponent = ({
  name,
  handleEditPhase,
  handleCancel
}: PhaseEditComponentProps) => {
  const { t } = useTranslation([ 'lifecycle', 'common' ])

  const defaultValues = { name }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  const preventEvent = (event?: any) => {
    event?.preventDefault()
    event?.stopPropagation()
  }

  const onKeyDown = (event: any) => {
    const elt = document.querySelector<HTMLInputElement>('.phase-name-input')

    if (event.key === 'Escape') {
      onCancelChangeAmount()
    }

    if (elt && event.key === 'Backspace' && elt.clientWidth > 165) {
      elt.style.width = `${elt.scrollWidth - 8}px`
    } else if (elt && elt.scrollWidth > elt.clientWidth) {
      elt.style.width = `${elt.scrollWidth + 8}px`
    }
  }

  const preventEventPropagation = (event: any) => {
    event.stopPropagation()
  }

  const onSubmit = ({ name = '' }: any) => {
    reset()
    handleEditPhase(name)
  }

  const onCancelChangeAmount = (event?: any) => {
    preventEvent(event)
    handleCancel()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditNameWrapper className="flex align-items-center h-full">
          <div className="flex flex-column">
            <Controller
              name="name"
              control={control}
              rules={{ required: t('form.phaseName', { context: 'error' }) || '' }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-cy="account_name_input"
                  data-testid="input-phase-name"
                  onClick={preventEvent}
                  onKeyDown={() => onKeyDown}
                  placeholder={t('form.phaseName', { context: 'placeholder' })}
                  className={classNames('phase-name-input', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            { errors?.name && <small className="p-error">{ errors?.name.message }</small> }
          </div>
          <div className="flex-none">
            <Button
              icon="pi pi-times"
              type="button"
              data-testid="tree-item-amount-cancel"
              tooltip={t('actions.cancel', { ns: 'common' })}
              tooltipOptions={{ position: 'bottom' }}
              className="p-button-outlined btn-menu-item ml-2"
              onClick={onCancelChangeAmount}
            />
          </div>
          <div className="flex-none">
            <Button
              icon="pi pi-check"
              type="submit"
              data-testid="tree-item-amount-submit"
              tooltip={t('actions.save', { ns: 'common' })}
              tooltipOptions={{ position: 'bottom' }}
              className="p-button-outlined btn-menu-item ml-2 mr-1"
              onClick={preventEventPropagation}
            />
          </div>
        </EditNameWrapper>
      </form>
    </>
  )
}
