import { gql } from '@apollo/client'

export default gql`
  query impactMethodSets {
    impactMethodSets {
      id
      name
    }
  }
`
