import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { InventoryTreeKey } from '../../utils/inventoryTreeKey'
import resource from '../../utils/resource/resource'
import { returnNested } from '../../utils/tools'
import { getExpandedInventoryKeysMap } from '../../utils/treeUtils'
import { setEntityToCompareAction } from '../actions/comparison.actions'
import { setIsCreateNewInventoryItemAction } from '../actions/flags.actions'
import { setSelectedInventoryItemKeyAction, setSelectedProductAction } from '../actions/global.actions'
import { setInventoryExpandedKeysAction } from '../actions/inventoryExpandedKeys.actions'
import { setProductScenarioInventoryTreeAction } from '../actions/inventoryTree.actions'
import { setIsLoadingRightTreeAction } from '../actions/loading.actions'
import InventoryExpandedKeysSelector from '../selectors/inventoryExpandedKeys.selector'
import {
  InventoryTreeSelector,
  ProductScenarioSelector,
  selectedInventoryItemSelector
} from '../selectors/inventoryTree.selector'
import { dispatchErrors, mapProductEntity } from './helpers'

export const
  GET_PRODUCT_SCENARIO = 'GET_PRODUCT_SCENARIO_SAGA',
  QUIT_PRODUCT_SCENARIO = 'QUIT_PRODUCT_SCENARIO_SAGA'

export const
  getProductScenarioSaga = params => ({
    type: GET_PRODUCT_SCENARIO,
    params
  }),
  quitProductScenarioSaga = cb => ({
    type: QUIT_PRODUCT_SCENARIO,
    cb
  })

/**
 * @param {{params: {productId: string}}} action
 */
export function* getProductScenario(action) {
  const { productId } = action.params
  yield put(setIsLoadingRightTreeAction(true))

  try {
    const inventory = yield call(resource.queryByParams, 'inventory', { productID: productId })
    const expandedKeysMap = yield select(InventoryExpandedKeysSelector.expandedKeysMapByRootId, productId)

    let effects = [
      put(setProductScenarioInventoryTreeAction(inventory)),
      put(setEntityToCompareAction(mapProductEntity(inventory))),
      put(setInventoryExpandedKeysAction(productId, getExpandedInventoryKeysMap(inventory, expandedKeysMap)))
    ]
    yield all(effects)
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingRightTreeAction(false))
  }
}
// Ensure there is always one item selected
function* quitProductScenarioFn(action) {
  const selectedInventoryItem = yield select(selectedInventoryItemSelector)
  const productScenarioProductId = yield select(ProductScenarioSelector.productId)

  // If selected item is in scenario, select the root product in inventory
  const selectedInventoryRootId = selectedInventoryItem && returnNested(InventoryTreeKey.createFromKey(selectedInventoryItem), 'rootId')
  if (selectedInventoryRootId === productScenarioProductId) {
    const inventoryTree = yield select(InventoryTreeSelector.inventoryTree)
    const inventoryTreeProductId = yield select(InventoryTreeSelector.productId)
    const inventoryRootKey = InventoryTreeKey.createFromRootId(inventoryTreeProductId).inventoryTreeKey()

    const inventoryTreeRootProduct = { ...inventoryTree.product }
    inventoryTreeRootProduct.impact = inventoryTree.totalImpact

    yield put(setSelectedProductAction(inventoryTreeRootProduct))
    yield put(setIsCreateNewInventoryItemAction(false))
    yield put(setSelectedInventoryItemKeyAction(inventoryRootKey))
  }
  action.cb && action.cb()
}

export default function* productScenarioInventoryTreeSaga() {
  yield takeLatest(GET_PRODUCT_SCENARIO, getProductScenario)
  yield takeLatest(QUIT_PRODUCT_SCENARIO, quitProductScenarioFn)

}

