import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation, Trans } from 'react-i18next'
import { IControllerRender } from '../../interface/react-form-hook'
import { ProductLabel } from '../../enum'

interface CreateProductWrapperProps {
  readonly showMessage?: boolean
}
const CreateProductWrapper = styled.div<CreateProductWrapperProps>`
  .unit-info-button {
    color: ${(props: CreateProductWrapperProps) => (props.showMessage === true ? 'var(--primary-500)' : 'var(--gray-500)')};

    :hover {
      cursor: pointer;
      color: var(--primary-500) !important;
    }
  }
`

type CreateProductComponentProps = {
  showCreateProductDialog: boolean,
  label?: ProductLabel,
  closeCreateProductDialog: () => void,
  handleCreateProduct: (param: string, unit: string) => void
}
export const CreateProductComponent = ({
  showCreateProductDialog,
  label = ProductLabel.Product,
  closeCreateProductDialog,
  handleCreateProduct
}: CreateProductComponentProps) => {
  const [ showMessage, setShowMessage ] = useState<boolean>(false)
  const messageRef = useRef<Messages>(null)
  const { t } = useTranslation([ 'common', 'product' ])
  const defaultValues = { name: '', unit: '' }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  useEffect(() => {
    reset()
    clearMessages()
    setShowMessage(false)
  }, [ showCreateProductDialog ])

  useEffect(() => {
    if (showMessage) {
      messageRef?.current?.show([
        {
          severity: 'info',
          closable: false,
          sticky: true,
          content: (
            <div className="flex" data-testid="unit-tooltip-description">
              <div className="pr-3 pt-1">
                <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
              </div>
              <div className="flex flex-column">
                <div className="font-medium">{t('messages.infoSummary', { context: 'unit', ns: 'product' })}</div>
                <div className="font-normal pt-2"><Trans t={t} i18nKey="messages.infoDetail_unit" ns="product" /></div>
              </div>
            </div>
          )
        }
      ])
    } else {
      clearMessages()
    }
  }, [ showMessage ])

  const onSubmit = (data: any) => {
    const { name, unit } = data
    handleCreateProduct(name, unit)
  }

  const onHide = () => {
    reset()
    closeCreateProductDialog()
  }

  const clearMessages = () => {
    messageRef?.current?.clear()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="create-product-dialog"
      header={t('actions.headerCreateNew', { context: label })}
      visible={showCreateProductDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <CreateProductWrapper className="w-full pb-6" showMessage={showMessage}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 p-0">
            <label htmlFor="name" className="text-sm">
              { t('form.placeholderCreate', { context: label }) }
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true, minLength: 2 }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-testid="new-product-name"
                  data-cy="add-new-product-input"
                  placeholder={t('form.placeholderCreate', { context: label })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            {
              errors?.name
              && <small className="p-error"></small>
            }
          </div>

          <div className="col-12 p-0 pt-3">
            <label htmlFor="unit" className="text-sm">
              { t('labels.unit') }
              {' '}
              (
              { t('labels.optional') }
              )
              <Button
                type="button"
                icon={<FontAwesomeIcon icon={faCircleInfo} />}
                className="p-button-link py-0 unit-info-button"
                data-testid="show-unit-description"
                onClick={() => setShowMessage(!showMessage)}
              />
            </label>
            <Controller
              name="unit"
              control={control}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  data-testid="new-product-unit"
                  data-cy="add-new-product-input-unit"
                  placeholder={t('labels.unit')}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
          </div>
          <div className="col-12 p-0">
            <Messages ref={messageRef} />
          </div>
          <div className="col-12 pt-6 px-0 flex justify-content-end">
            <div className="flex-none">
              <Button
                type="submit"
                data-testid="submit-new-product"
                data-cy="confirm-modal"
                label={t('labels.create')}
                iconPos="left"
                loading={false}
                className="p-button-primary justify-content-end"
              />
            </div>
          </div>
        </form>
      </CreateProductWrapper>
    </Dialog>
  )
}
