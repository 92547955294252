import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation removeProductProperty($productID: ID!, $referencePropertyID: ID!) {
    removeProductProperty(productID: $productID, referencePropertyID: $referencePropertyID){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
