import { connect } from 'react-redux'
import {
  createTagSaga,
  tagsSearchSaga,
  addTagToProductSaga,
  removeTagFromProductSaga
} from '../../../redux/sagas/product.saga'
import {
  setSpaceProductTagListAction,
  setProductTagsToProductFilterAction
} from '../../../redux/actions/global.actions'
import SpaceSelector from '../../../redux/selectors/space.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import TagsForm from './tagsForm.tsx'
import GlobalSelector from '../../../redux/selectors/global.selector'

const mapStateToProps = state => ({
  productFilterSelectedTags: GlobalSelector.productFilterSelectedTags(state),
  spaceID: SpaceSelector.spaceId(state),
  productID: SelectedProductSelector.productId(state),
  selectedProduct: SelectedProductSelector.product(state)
})

const mapDispatchToProps = {
  createTagSaga,
  tagsSearchSaga,
  addTagToProductSaga,
  removeTagFromProductSaga,
  setSpaceProductTagListAction,
  setProductTagsToProductFilterAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsForm)
