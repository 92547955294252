import React, { useState } from 'react'
import { IWorkspaceProvider } from '../interface/workspace-context.type'
import WorkspaceContext from './context/workspace.context'

type WorkspaceProviderProp = {
  children?: React.ReactNode
}

export const WorkspaceProvider = ({
  children
}:WorkspaceProviderProp) => {
  const [ workspace, setWorkspace ] = useState<IWorkspaceProvider>({})

  const updateWorkspace = (newState: IWorkspaceProvider = {}) => {
    setWorkspace(prevState => ({ ...prevState, ...newState }))
  }

  return (
    <WorkspaceContext.Provider value={{ ...workspace, updateWorkspace }}>
      { children }
    </WorkspaceContext.Provider>
  )
}
