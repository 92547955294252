import { APPLICATION_MODE, LOCAL_BUILD } from './const'
import { getEnvironmentFromUrl } from './tools'

export const getVersionNumber = () => {

  if ( getEnvironmentFromUrl() === APPLICATION_MODE.DEV ) {
    return new Promise(resolve =>
      setTimeout(() => {
        resolve({ version: LOCAL_BUILD })
      }))
  } else {
    return fetch('/version')
      .then(response => response.json())
  }

}