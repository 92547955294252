import { connect } from 'react-redux'
import {
  addAccountUserToRoleAction,
  removeAccountUserFromRoleAction
} from '../../redux/actions/account.actions'
import {
  removeInvitationSaga,
  addInvitationRoleSaga,
  removeInvitationRoleSaga,
  inviteUserSaga,
  removeUserFromAccountSaga
} from '../../redux/sagas/account.saga'
import userManagement from './userManagement'
import { trackEventSaga, trackPageSaga } from '../../redux/sagas/tracking.saga'
import FlagsSelector from '../../redux/selectors/flags.selector'
import { returnNested } from '../../utils/tools'

const mapDispatchToProps = {
  trackEventSaga,
  trackPageSaga,
  addAccountUserToRole: addAccountUserToRoleAction,
  removeAccountUserFromRole: removeAccountUserFromRoleAction,
  removeInvitationSaga,
  addInvitationRoleSaga,
  removeInvitationRoleSaga,
  inviteUserSaga,
  removeUserFromAccountSaga
}

const mapStateToProps = state => {
  return ({
    invitedUsersList: returnNested(state, 'global', 'invitedUsersList'),
    isDataViaNetworkRequesting: FlagsSelector.isDataViaNetworkRequesting(state)
  })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(userManagement)
