import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import { IControllerRender } from '../../shared/interface/react-form-hook'
import { RemoveWorkspaceComponentProps } from '../interface/workspace-navigation'

export const RemoveWorkspaceComponent = ({
  handleWorkspaceRemoval,
  showRemoveWorkspaceDialog,
  closeRemoveWorkspaceDialog,
  workspaceRemoving,
  selectedWorkspaceName
}: RemoveWorkspaceComponentProps) => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const defaultValues = { remove: '' }
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ defaultValues })

  useEffect(() => {
    if (!workspaceRemoving) {
      reset()
    }
  }, [ workspaceRemoving ])

  const onSubmit = (data: any) => handleWorkspaceRemoval(data)

  const closeDialog = () => {
    reset()
    closeRemoveWorkspaceDialog()
  }
  const getErrorMessage = (context: string): string => t('form.errorMessage', { context })

  return (
    <Dialog
      className="w-30rem"
      header={t('actions.delete', { context: 'workspace' })}
      visible={showRemoveWorkspaceDialog}
      draggable={false}
      dismissableMask
      onHide={closeDialog}
    >
      <div className="w-full pb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 text-sm p-0">
            { t('messages.warning', { context: 'removeWorkspaceConfirm', name: selectedWorkspaceName })}
          </div>
          <div className="col-12 text-red-500 text-sm p-0 pt-3">
            { t('messages.warning', { context: 'removeWorkspaceRedText' })}
          </div>
          <div className="col-12 text-sm p-0">
            { t('messages.warning', { context: 'removeWorkspacePermanent', name: selectedWorkspaceName })}
          </div>
          <div className="col-12 p-0 pt-4">
            <label htmlFor="name" className="text-sm">
              { t('form.placeholder', { ns: 'common', context: 'confirmRemove' }) }
            </label>
            <Controller
              name="remove"
              control={control}
              rules={{
                validate: () => getValues('remove') === 'REMOVE'
              }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-cy="remove-workspace-input"
                  data-testid="input-remove-workspace"
                  placeholder={t('form.placeholder', { ns: 'common', context: 'confirmRemove' })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            {
              errors?.remove
              && <small className="p-error">{ getErrorMessage('remove') }</small>
            }
          </div>

          <div className="col-12 flex justify-content-end pt-6">
            <div className="flex-none align-items-center">
              <Button
                type="submit"
                data-testid="submit-remove-workspace"
                label={t('form.label', { ns: 'common', context: 'delete' })}
                iconPos="left"
                loading={workspaceRemoving}
                className="p-button-danger px-3"
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}
