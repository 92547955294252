import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import compose from '../../utils/compose'
import { withRouter } from '../../utils/with-router'
import { NAV } from '../../utils/const'
import { returnNested } from '../../utils/tools'
import LoadingPage from '../shared/loadingPage'

function LoginCallback(props) {
  const navigate = useNavigate()
  const { handleRedirectCallback } = useAuth0()

  useEffect(() => {
    handleRedirectCallback()
      .then(() => {
        props.clearRootStateExceptSettingsAction()
        navigate(NAV.ACCOUNT)
      })
      .catch(e => {
        const isPasswordReseterror = e.toString().includes('PASSWORD_RESET')
        if (isPasswordReseterror) {
          const email = e.toString().split('email:')[1]
          navigate(`${NAV.PASSWORD_RESET}${email}`)
          return
        }
        if (returnNested(e, 'error_description' ) === 'EMAIL_NOT_ALLOWED') {
          navigate(NAV.AUTHORIZATION_FAILED)
          return
        }
        navigate(NAV.LOGOUT)
      })
  }, [])
  return <LoadingPage/>
}

export default compose(
  withRouter,
)(LoginCallback)

