import { useReactiveVar } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import { EmptyStateScreenLayout } from '../../shared/layout/empty-state-screen.layout'

import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'

type TAnalysisEmptyStateComponentProps = {
  path: string,
  title: string,
  backButtonLabel: string,
  icon?: any
}
export const AnalysisEmptyStateComponent = ({
  title,
  path,
  backButtonLabel,
  icon
}: TAnalysisEmptyStateComponentProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation([ 'common' ])
  const { primaryEntityId, secondaryEntityId } = useContext<TAnalysisContext>(AnalysisContext)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const goToModel = () => {
    addToBackToList(backButtonLabel)
    account?.id && space?.slug && primaryEntityId && navigate(generatePath(path, {
      accountId: account.id,
      workspaceSlug: space.slug,
      productId: primaryEntityId,
      secondaryProductId: secondaryEntityId,
      activeTab: InventoryViewType.Model
    }))
  }

  return (
    <EmptyStateScreenLayout
      wrapperClassName="h-30rem"
      title={title}
      icon={icon ? <FontAwesomeIcon icon={icon} className="text-primary-100" fontSize={106} /> : null}
      footer={<Button onClick={goToModel}>{ t('actions.go_toModel') }</Button>}
    />
  )
}
