import React from 'react'
import { IDetailsPanelCardProps } from './detailPanel.interface'

export const DetailsPanelCard = ({
  title,
  action,
  className,
  children
}: IDetailsPanelCardProps) => {
  return <div className={`details-card ${ className }`}>
    {(title || action) && <div className="details-card__header">
      <div className="details-card__title">
        { title }
      </div>
      <div className="details-card__action">
        { action }
      </div>
    </div>}
    <div className="details-card__content">
      { children }
    </div>
  </div>
}
