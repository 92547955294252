import React, { useEffect, useRef } from 'react'
import Sunburst from 'sunburst-chart'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { roundNumber, requiresScientificNotation, scientificNotation, returnNested, cropText } from '../../../utils/tools'
import { tabSunburst } from '../../shared/locations'
import { NUMBER_FORMAT } from '../../../utils/const'

const SunburstGraph = props => {
  const {
    activeTabKey,
    leafInventory,
    rootProduct,
    sunburstId,
    inventoryTreeSelectorProductId,
    orderedPhases,
    skipPhases,
    squareSize,
    colorSet,
    colorSetReversed,
    phaseColorSet,
    t
  } = props

  const sunburstRef = useRef()

  useEffect(() => {
    if (activeTabKey === tabSunburst) {
      buildSunburstGraph()
    }
  }, [ activeTabKey, skipPhases, leafInventory ])

  const buildSunburstGraph = () => {
    const sunburstData = leafInventory && leafInventory.length && getSunburstData()
    sunburstData && Sunburst()
      .data(sunburstData)
      .size('size')
      .color('color')
      .tooltipTitle(el => el.title)
      .width(squareSize)
      .height(squareSize)
      .centerRadius(0.05)
      .radiusScaleExponent(1)(sunburstRef.current)
  }

  const getRootTitle = (rootProduct, impactAmount) => {
    let title = `${cropText(returnNested(rootProduct, 'name'), 40, '...')} ${impactAmount} ${returnNested(rootProduct, 'impact', 'amount')}`
    if (returnNested(rootProduct, '__typename') === 'Lifecycle' && returnNested(rootProduct, 'amount') && returnNested(rootProduct, 'unit')) {
      return `${ rootProduct.amount } ${ rootProduct.unit } ${ t('global.of') } ${title}`
    } else {
      return `1 ${ returnNested(rootProduct, 'unit') || t('global.unit') } ${ t('global.of') } ${title}`
    }
  }

  const getSunburstData = () => {
    const rootImpactAmount = returnNested(rootProduct, 'impact', 'amount') || 0
    const impactAmount = formatNumber(rootImpactAmount)

    return {
      name: cropText(returnNested(rootProduct, 'name'), 40, '...'),
      color: '#fff',
      children: getSunburstItems(),
      title: getRootTitle(rootProduct, impactAmount)
    }
  }

  const formatNumber = value => {
    const isSn = requiresScientificNotation(value)
    const valueSn = scientificNotation(value)
    return isSn ? `${valueSn.coefficient} · 10 ${valueSn.exponent}` : roundNumber(value, NUMBER_FORMAT.PRECISION)
  }

  const getSunburstItems = () => {
    let sunburstItemsList = []
    let groupedInventoryItems = {}

    let leafInventoryItemsWithNoRootItem = (leafInventory && leafInventory.length > 1) ?
      leafInventory.filter(el => (el.product.id !== inventoryTreeSelectorProductId) && (Number(el.impact.amount) > 0)) :
      leafInventory.filter(el => Number(el.impact.amount) > 0)

    leafInventoryItemsWithNoRootItem.forEach(el => {
      let nodeKey
      if (skipPhases) {
        nodeKey = `${el.product.id}`
      } else {
        nodeKey = `${el.product.id}${el.phase ? el.phase.id : 'nophase'}`
      }

      if (groupedInventoryItems[nodeKey]) {
        groupedInventoryItems[nodeKey].totalImpact = Number(groupedInventoryItems[nodeKey].totalImpact) + Number(el.impact.amount)
        groupedInventoryItems[nodeKey].amount = Number(groupedInventoryItems[nodeKey].amount) + Number(el.amount)
      } else {
        groupedInventoryItems[nodeKey] = {
          totalImpact: Number(el.impact.amount),
          amount: Number(el.amount),
          ...el
        }
      }
    })
    Object.values(groupedInventoryItems).forEach(el => {
      sunburstItemsList.push({
        name: el.product.name,
        size: Number(el.totalImpact),
        color: (el.phase && skipPhases) ? colorSetReversed[el.product.id] : colorSet[el.product.id],
        title: `${formatNumber(el.amount)} ${el.product.unit} ${ t('global.of') } ${el.product.name} = ${formatNumber(el.totalImpact)} ${el.impact.unit}`,
        phaseId: el.phase ? el.phase.id : null
      })
    })

    const getPhaseTitle = (phase, impactAmount) => {
      let title = phase && phase.name
      return impactAmount ? `${title} ${impactAmount} ${phase.inventory.totalImpact.unit}` : title
    }

    if (!skipPhases && orderedPhases) {
      let phaseList = []
      let filteredPhases = orderedPhases.filter(el => el.inventory.totalImpact.amount > 0)
      if (!filteredPhases.length || sunburstItemsList.every( val => val.phaseId === null )) {
        filteredPhases = [ { name: 'No phase' } ]
      }

      filteredPhases.forEach(phase => {
        let impactAmount = ''
        if (phase.inventory) {
          impactAmount = formatNumber(phase.inventory.totalImpact.amount)
        }

        let phaseColor = phase && phaseColorSet && phaseColorSet[phase.name]
        phaseList.push({
          name: (phase && phase.name),
          color: phaseColor,
          title: getPhaseTitle(phase, impactAmount),
          children: sunburstItemsList.filter(el => {
            if (el.phaseId) {
              return el.phaseId === phase.id
            } else {
              return true
            }
          }).map(item => {
            item.color = phaseColor
            return item
          })
        })
      })
      return phaseList
    }
    return sunburstItemsList
  }

  return <div className='sunburst-container' data-cy={`${sunburstId}-container`}>
    <div id={sunburstId} ref={ sunburstRef }></div>
  </div>
}

export default compose(
  withTranslation()
)(SunburstGraph)
