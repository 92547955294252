import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

type TImpactBarProps = {
  crop?: boolean
  type?: boolean
  width?: any
  theme?: any
  impactType?: any
  mouseOverNode?: boolean
}

const ImpactBar = styled.div<TImpactBarProps>`
  width: ${(props: TImpactBarProps) => props.width};
  height: 1rem;
  border-radius: 0.25rem;
  background: ${(props: TImpactBarProps) => {
    const from = props.theme.inventoryTree[`${props.impactType}`]
    return `var(${from})`
  }};
`

type TImpactBarComponentProps = {
  nodeKey?: string
  impactPercent: string | number,
  impactType: any,
  disabledTooltip?: boolean
  getTooltipContent?: Function
  inventoryKey?: string,
}

export const ImpactBarComponent = ({
  nodeKey = '',
  impactPercent,
  impactType,
  getTooltipContent,
  disabledTooltip = false,
  inventoryKey = ''
}: TImpactBarComponentProps) => {
  const impactBarIdentifier = `impact-bar-${nodeKey.replaceAll(':', '-')}-${inventoryKey}`
  const percentageFloat = parseFloat(String(impactPercent))
  const width = percentageFloat < 1.3 && percentageFloat !== 0 ? '1.3%' : impactPercent

  return (
    <>
      { !disabledTooltip && (
        <Tooltip
          data-testid="impact-bar-tooltip"
          target={`.${impactBarIdentifier}`}
          position="bottom"
        >
          { getTooltipContent ? getTooltipContent() : impactPercent }
        </Tooltip>
      )}
      <ImpactBar data-testid="impact-bar-component" data-cy={impactBarIdentifier} data-cy-percent={width} className={impactBarIdentifier} impactType={impactType} width={width} />
    </>
  )
}
