import { takeLatest } from 'redux-saga/effects'
import { productMutation } from './helpers'

export const REMOVE_CONVERSION_ITEM = 'REMOVE_CONVERSION_ITEM_SAGA'

export const removeConversionItemSaga = (productMutator, conversionId, productId, cb) => ({
  type: REMOVE_CONVERSION_ITEM,
  productMutator, conversionId, productId, cb
})

/**
 * @param {{type: string, productMutator: {}, conversionId: string, productId: string, cb: function}} action
 */
export function* removeConversionItem(action) {
  yield productMutation(
    action.productMutator,
    'removeConversionItem',
    { conversionID: action.conversionId, productID: action.productId },
    'model.product_general_info_item_removed',
    action.cb
  )
}

export default function* conversionSaga() {
  yield takeLatest(REMOVE_CONVERSION_ITEM, removeConversionItem)
}
