import { connect } from 'react-redux'
import ReferenceProduct from './referenceProduct'
import { removeProductReferenceSaga } from '../../../../../redux/sagas/product.saga'
import { setIsImpactSelectorDialogShowedAction } from '../../../../../redux/actions/flags.actions'
import FlagsSelector from '../../../../../redux/selectors/flags.selector'

const mapStateToProps = state => {
  return ({
    isSearchReferenceModalShowed: FlagsSelector.isSearchReferenceModalShowed(state)
  })
}

const mapDispatchToProps = {
  removeProductReferenceSaga,
  setIsImpactSelectorDialogShowedAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceProduct)
