import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TableWrapperInventoryComponent } from '../../../shared/component/general/table-wrapper-inventory.component'
import { ELifecycleTableColumn } from '../../enum/table-column'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ILifecycleFlatLeafItem } from '../../interface/transformed-lifecycle-leaf-item'
import { transformToLifecycleFlatLeafItems } from '../../../shared/util/transform-lifecycle'
import { store } from '../../../../configureStore'
import { TableFooterComponent } from './table-footer.component'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import { TableBodyTemplateComponent } from './table-body.component'
import { TableHeaderComponent } from './table-header.component'
import { SortOrder } from '../../../shared/enum'
import { LIFECYCLE_TABLE_SORT_MAP } from '../../constant/table'

const TableAnalysisWrapper = styled.div`
.p-datatable {
  td.footer-impact-change,
  th{
    padding-right: 0;
    &.header-impact-change {
      width: 2rem;
      padding: 0.25rem 0.5rem;
    }
  }
}
`

export const TableComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { transformedLifecycleLeafItems = [], updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ selectedItemId, setSelectedItemId ] = useState<string | null>(null)

  const [ selectedSortBy, setSelectedSortBy ] = useState<ELifecycleTableColumn>(ELifecycleTableColumn.PhaseName)
  const [ selectedSortOrder, setSelectedSortOrder ] = useState<SortOrder>(SortOrder.Asc)
  const [ lifecycleTableList, setLifecycleTableList ] = useState<ILifecycleFlatLeafItem[]>([])

  const transformedFlatLeafItems = useMemo(
    () => transformToLifecycleFlatLeafItems(transformedLifecycleLeafItems),
    [ transformedLifecycleLeafItems ]
  )

  useEffect(() => {
    if (selectedSortBy && selectedSortOrder) {
      const sortedLeafList = getSortedList()
      setLifecycleTableList([ ...sortedLeafList ])
    }
  }, [ selectedSortBy, selectedSortOrder, transformedFlatLeafItems ])

  const getSortedList = () => {
    const sortedList = transformedFlatLeafItems.sort((a: any, b: any) => {
      const sortBy = LIFECYCLE_TABLE_SORT_MAP[selectedSortBy]
      let sortByA = a[sortBy]
      let sortByB = b[sortBy]
      if (selectedSortBy === ELifecycleTableColumn.Impact
        || selectedSortBy === ELifecycleTableColumn.ImpactBar) {
        sortByA = parseFloat(sortByA)
        sortByB = parseFloat(sortByB)
      }

      if (sortByA === sortByB) return 0
      return sortByA > sortByB ? 1 : -1
    })

    if (selectedSortOrder === SortOrder.Desc) {
      return sortedList.reverse()
    }

    return sortedList
  }

  const handleSortTable = (sortBy: ELifecycleTableColumn, sortOrderTo: SortOrder) => {
    setSelectedSortBy(sortBy)
    setSelectedSortOrder(sortOrderTo)
  }

  const onRowClick = (itemId: string, rowId: string) => {
    if (itemId === 'impact') return null

    if (itemId === selectedItemId) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    setSelectedItemId(rowId)
    store.dispatch(showProductInDetailsPanelSaga(itemId))
    updateLifecycleDetail({ showLifecycleDetailPanelOnly: false })
  }

  const getSelectedRow = () => lifecycleTableList.find((item: ILifecycleFlatLeafItem) => item.rowId === selectedItemId)
  const footerTemplate = () => <TableFooterComponent />

  const bodyTemplate = (rowData: ILifecycleFlatLeafItem, column: ELifecycleTableColumn) => <TableBodyTemplateComponent value={rowData} onRowClick={onRowClick} column={column} />

  const headerTemplate = (label: string, sortBy?: ELifecycleTableColumn, alignRight: boolean = true, sortDisabled = false) => (
    <TableHeaderComponent
      label={label}
      sortBy={sortBy}
      selectedSortBy={selectedSortBy}
      selectedSortOrder={selectedSortOrder}
      alignRight={alignRight}
      sortDisabled={sortDisabled}
      onSortClick={handleSortTable}
    />
  )

  return (
    <TableAnalysisWrapper className="flex w-full">
      <TableWrapperInventoryComponent>
        <DataTable
          value={lifecycleTableList}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={getSelectedRow()}
          footer={footerTemplate()}
        >
          <Column
            field={ELifecycleTableColumn.Id}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.Id)}
            header={headerTemplate('', ELifecycleTableColumn.Id, false, true)}
            style={{ width: '2rem' }}
          />
          <Column
            field={ELifecycleTableColumn.PhaseName}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.PhaseName)}
            header={headerTemplate('Phase', ELifecycleTableColumn.PhaseName, false)}
            style={{ width: '10rem' }}
          />
          <Column
            field={ELifecycleTableColumn.Amount}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.Amount)}
            header={headerTemplate(t('labels.amount', { ns: 'common' }), ELifecycleTableColumn.Amount, false)}
            style={{ width: '5.5rem' }}
          />
          <Column
            field={ELifecycleTableColumn.Unit}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.Unit)}
            header={headerTemplate(t('labels.unit', { ns: 'common' }), ELifecycleTableColumn.Unit, false)}
            style={{ width: '2.25rem' }}
          />
          <Column
            field={ELifecycleTableColumn.Item}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.Item)}
            header={headerTemplate(t('labels.tableHeader', { context: 'nameOfItems', ns: 'common' }), ELifecycleTableColumn.Item, false)}
          />
          <Column
            field={ELifecycleTableColumn.Impact}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.Impact)}
            header={headerTemplate(t('labels.impact', { ns: 'common' }), ELifecycleTableColumn.Impact)}
            style={{ width: '8.75rem' }}
          />
          <Column
            field={ELifecycleTableColumn.ImpactBar}
            body={(rowData: ILifecycleFlatLeafItem) => bodyTemplate(rowData, ELifecycleTableColumn.ImpactBar)}
            header={headerTemplate('', ELifecycleTableColumn.ImpactBar, false, true)}
            style={{ width: '9.5rem' }}
          />
        </DataTable>
      </TableWrapperInventoryComponent>
    </TableAnalysisWrapper>
  )
}
