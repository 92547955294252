export const
  SET_COMPARISON_FLAT_VIEW = 'SET_COMPARISON_FLAT_VIEW',
  SET_IS_COMPARISON_ENTRIES_LOADING = 'SET_IS_COMPARISON_ENTRIES_LOADING',
  SET_COMPARISON_ENTITIES = 'SET_COMPARISON_ENTITIES',
  SET_ENTITY_TO_COMPARE = 'SET_ENTITY_TO_COMPARE',
  SET_IS_INVENTORY_COMPARE = 'SET_IS_INVENTORY_COMPARE'

export const setEntityToCompareAction = entity => ({
  type: SET_ENTITY_TO_COMPARE,
  entity
})
export const setComparisonFlatViewAction = flatView => ({
  type: SET_COMPARISON_FLAT_VIEW,
  flatView
})

export const setIsComparisonEntriesLoadingAction = status => ({
  type: SET_IS_COMPARISON_ENTRIES_LOADING,
  status
})

export const setComparisonEntitiesAction = entities => ({
  type: SET_COMPARISON_ENTITIES,
  entities
})

export const setIsInventoryCompareAction = status => ({
  type: SET_IS_INVENTORY_COMPARE,
  status
})

