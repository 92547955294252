import React, { useContext } from 'react'

import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { CategoryType } from '../enum/category'
import { CategoryFilterContainer } from '../container/category-filter.container'
import { FilterChipsComponent } from '../component/filter/filter-chips.component'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../shared/util/impact'

const CategoryFilterWrapper = styled.div`
  height: calc(100% - 2rem);
  overflow: overlay;

  .p-button-label {
    font-size: .875rem;
  }
`

type TSidebarFilterLayoutProps = {
  categoryType: CategoryType
}
export const SidebarFilterLayout = ({
  categoryType
}: TSidebarFilterLayoutProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    updateFilterValue,
    categoryFilterItems = []
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const clearFilters = () => {
    const updatedFilterItems = [
      { ...categoryFilterItems[0], selectedCategory: null }
    ]

    setSelectedDatabasesStorageItem([])
    updateFilterValue({
      categoryFilterItems: updatedFilterItems,
      selectedDatabases: [],
      selectedLocations: [],
      selectedProperties: []
    })
  }

  return (
    <>
      <div className="flex w-full px-4">
        <div className="flex w-1rem h-full align-items-center text-gray-500">
          <FontAwesomeIcon data-testid="sidebar-filter-icon" icon={faFilter} className="text-base" />
        </div>
        <div className="flex flex-grow-1 align-items-center h-full pl-2 text-gray-700 font-medium text-base">
          { t('labels.filters') }
        </div>
        <div className="flex ">
          <span onClick={clearFilters} className="cursor-pointer font-medium align-content-end">{ t('labels.clearFilters') }</span>
        </div>
      </div>

      <div className="flex flex-column w-full px-4 pb-0">
        <FilterChipsComponent onClearFilters={clearFilters} />
      </div>

      <CategoryFilterWrapper className="flex flex-column w-full px-4 pb-0">
        <CategoryFilterContainer type={categoryType} />
      </CategoryFilterWrapper>
    </>
  )
}
