import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { withRouter } from '../../../../../utils/with-router'
import compose from '../../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import { returnNested, floatToString } from '../../../../../utils/tools'
import { NWSET_TYPE } from '../../../../../utils/const'
import ScientificNotation from '../../../inventory/scientific-notation/scientificNotation'
import ReferenceProduct from '../reference-product/referenceProduct.container'
import { SyncOutlined } from '@ant-design/icons'
import CustomImpact from '../custom-impact/customImpact.container'
import ReferencePropertyDialog from '../../../../helpers/dialogs/referencePropertyDialog/referencePropertyDialog'
import { DetailsPanelCard } from '../../detailPanelCard'
import { Status } from '../../../../../v1/shared/enum/status'

const ProductImpactInformation = ({
  t,
  impactNwSet,
  impactMethod,
  impactCategory,
  productMutator,
  impactExcludeLT,
  selectedProduct,
  updatePageItems,
  impactUseMethodTotal,
  isDetailsPanelReadOnly,
  referencePropertiesList,
  changeProductPropertySaga
}) => {

  const [ showRefPropertyDialog, setShowRefPropertyDialog ] = useState(false)

  const getTooltipContent = () => {
    const nwSetType = returnNested(impactNwSet, 'type')
    const getNwSetLabel = () => {
      switch (nwSetType) {
      case NWSET_TYPE.NORMALIZED:
        return t('model.normalized')
      case NWSET_TYPE.WEIGHTED:
        return t('model.weighted')
      case NWSET_TYPE.NORM_WEIGHT:
        return t('model.normalized_weighted')
      default:
        return null

      }
    }
    return <ul>
      <li>{returnNested(impactMethod, 'name')}</li>
      {nwSetType ?
        <li>{`${getNwSetLabel()}: ${returnNested(impactNwSet, 'name')}`}</li>
        : null
      }
      <li>{impactUseMethodTotal ? t('model.single_score') : returnNested(impactCategory, 'name')}</li>
      <li>{`${t('model.exclude_lt')}: ${impactExcludeLT ? t('global.yes') :  t('global.no')}`}</li>
      <li><strong><ScientificNotation value={returnNested(selectedProduct, 'impact', 'amount')} unit={returnNested(selectedProduct, 'impact', 'unit')} /></strong></li>
    </ul>
  }

  const saveReferenceProperty = values => {
    changeProductPropertySaga({
      productMutator,
      productId: selectedProduct.id,
      propertyId: values.referenceProperty.id,
      conversionFactor: floatToString(values.conversionFactor),
      propertyUnitId: values.referenceUnit.id,
      skipMessage: false,
      callback: () => {
        updatePageItems()
      }
    })
    setShowRefPropertyDialog(false)
  }

  const cancelReferenceProperty = () => {
    setShowRefPropertyDialog(false)
  }

  const thereIsSomeCustomImpact = selectedProduct.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const isCustomImpactShowed = selectedProduct && !selectedProduct.referenceProduct && thereIsSomeCustomImpact

  return <DetailsPanelCard
    className="product-impact"
    title={t('global.impact')}>
    {
      returnNested(selectedProduct, 'impact') ?
        <React.Fragment>
          <Tooltip
            placement="top"
            title={getTooltipContent} mouseEnterDelay={1}
          >
            <div data-cy="product-details-impact-category" className="impact-category">
              <div className="impact-label">{impactUseMethodTotal ? t('model.single_score') : returnNested(impactCategory, 'name')}</div>
              <div data-cy="product-details-impact-amount-unit" className="impact-value">
                {selectedProduct.impact.status === Status.Pending && <SyncOutlined className='impact-pending' spin /> }
                {selectedProduct.impact.status === Status.NotCompatible && <FontAwesomeIcon
                    data-testid="detail-panel-invalid-impact-icon"
                    icon={faExclamationTriangle}
                    className="text-sm text-orange-700 pr-1"
                  /> }
                <ScientificNotation value={selectedProduct.impact.amount} unit={selectedProduct.impact.unit} noTooltip={true} />
              </div>
            </div>
          </Tooltip>
          { isCustomImpactShowed ?
            <CustomImpact
              selectedProduct={ selectedProduct }
              updatePageItems={ updatePageItems }
              productMutator={ productMutator }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly }
            />
            :
            <ReferenceProduct
              selectedProduct={ selectedProduct }
              updatePageItems={ updatePageItems }
              isDetailsPanelReadOnly={ isDetailsPanelReadOnly }
              productMutator={ productMutator }
              key={ `${ selectedProduct.id }_product_reference` }
            />
          }
        </React.Fragment>
        : null
    }

    <ReferencePropertyDialog
      visible={ showRefPropertyDialog }
      selectedProduct={selectedProduct}
      handleSave={ saveReferenceProperty }
      handleCancel={ cancelReferenceProperty }
      referencePropertiesList={ referencePropertiesList } />

  </DetailsPanelCard>
}

export { ProductImpactInformation }
export default compose(
  withTranslation(),
  withRouter,
)(ProductImpactInformation)
