import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'

import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'

import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'

import { store } from '../../../../configureStore'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { HeaderDetailBackComponent } from './header-detail-back.component'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

import { setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import { setSelectedLifecycleAction } from '../../../../redux/actions/lifecycle.actions'
import { MenuToolsContainer } from '../../container/menu/menu-tools.container'
import { normalize } from '../../../../utils/tools'

const LifecycleNameBar = styled.div`
  .root-product-name {
    cursor: pointer;
    &:hover {
      color: var(--primary-500) !important;
    }
  }
`

export const HeaderDetailComponent = () => {
  const [ productRef, hasLifecycleOverflow, productDom ] = useRefOverflow()
  const { lifecycleDetail, updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const openDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedLifecycleId = LifecycleSelector.lifecycleId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (lifecycleDetail?.id === selectedLifecycleId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    store.dispatch(setIsDetailsPanelOpenAction(true))
    store.dispatch(setSelectedLifecycleAction(lifecycleDetail))
    updateLifecycleDetail({ showLifecycleDetailPanelOnly: true })
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      {lifecycleDetail?.name}
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <>
      <div data-cy="go-back-button" className="flex align-items-center justify-content-start h-4rem">
        <HeaderDetailBackComponent />
      </div>
      <LifecycleNameBar className="flex align-items-center justify-content-start h-3rem">
        <Tooltip target={productDom} onBeforeShow={() => hasLifecycleOverflow} position="bottom">{getTooltipContent()}</Tooltip>
        <div className="flex align-items-center justify-content-center border-1 border-round h-3rem w-3rem px-3 text-primary-500 bg-primary-50 border-primary-100">
          <FontAwesomeIcon data-testid="header-detail-back-icon" icon={faRecycle} className="text-3xl" />
        </div>
        <div
          ref={productRef}
          onClick={openDetailPanel}
          data-cy={`nav-link-named-${normalize(lifecycleDetail?.name)}`}
          data-testid="header-detail-name"
          className="flex-grow-1 text-gray-800 text-3xl font-medium px-3 white-space-nowrap overflow-hidden text-overflow-ellipsis py-1 root-product-name"
        >
          { lifecycleDetail?.name }
        </div>
        <div data-testid="header-detail-menu-tools" className="flex-none flex h-full w-6rem">
          <MenuToolsContainer />
        </div>
      </LifecycleNameBar>
    </>
  )
}
