import { LightBlueBackgroundImage } from '../../../assets/pdf-svgs'
import {
  getFooterSection, getGraphSection, getLeftSection, getRightSection
} from './pdf-sections'
import { pdfTheme } from '../../constants/theme'

const { colors } = pdfTheme || {}

const documentConfig = {
  pageOrientation: 'landscape',
  pageMargins: [ 40, 40, 40, 120 ],
  background(page: number) {
    if (page !== 2) {
      return [
        {
          svg: LightBlueBackgroundImage,
          height: 595,
          alignment: 'right'
        }
      ]
    }
  }
}

const documentStyle = {
  defaultStyle: {
    font: 'Rubik 300-400',
    fontSize: 12
  },
  styles: {
    body: {
      font: 'Rubik 300-400',
    },
    subheader: {
      fontSize: 18,
      color: colors.primaryDark500,
      font: 'Rubik 500-700'
    },
    header: {
      fontSize: 24,
      color: colors.primary500,
      font: 'Rubik 500-700'
    },
    legend: {
      fontSize: 10
    },
    navy: {
      color: colors.primaryDark500
    },
    footerText: {
      fontSize: 9,
      color: colors.gray200
    },
    footerTitle: {
      fontSize: 10,
      font: 'Rubik 500-700',
      color: colors.primaryDark500
    },
    link: {
      color: colors.green500,
      fontSize: 10,
      bold: true
    },
    rubikHeavy: {
      font: 'Rubik 500-700',
      bold: true
    },
    graphAmount: {
      fontSize: 50,
      lineHeight: 0.9
    },
    graphAmountSn: {
      fontSize: 30,
      lineHeight: 0.9,
    }
  }
}

export const getPdfDefinition = (data: any) => ({
  ...documentConfig,
  ...documentStyle,
  footer: {
    columns: [
      {
        width: 600,
        stack: getFooterSection(data),
        margin: [ 40, 0, 0, 0 ],
      }
    ]
  },
  content: [
    {
      columns: [
        {
          width: 350,
          height: 450,
          stack: getLeftSection(data),
          style: 'body'
        },
        {/* empty middle column */},
        {
          width: 200,
          stack: getRightSection(data),
          style: 'body'
        }
      ]
    },
    {
      columns: [
        {
          width: 281,
          height: 281,
          absolutePosition: {
            x: 440,
            y: 100
          },
          stack: getGraphSection(data)
        }
      ]
    }
  ],
})
