import { connect } from 'react-redux'
import AccountSelector from '../../../redux/selectors/account.selector'
import InvoiceList from './invoiceList'

const mapStateToProps = state => ({
  selectedAccountInvoices: AccountSelector.selectedAccountInvoices(state),
})

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceList)
