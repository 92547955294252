import React, { useContext } from 'react'
import styled from 'styled-components'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faBoxesPacking } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'

import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'
import { ExternalSources, Navigation } from '../../shared/enum'
import { OverviewCardTemplateLayout } from '../../shared/layout/overview-card-template.component'

const CardWrapper = styled.div`
  .overview-card-default {
    box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);

    :hover {
      cursor: default;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
    }
  }
`

export const DatasetLimitComponent = () => {
  const { t } = useTranslation([ 'common', 'workspace' ])
  const navigate = useNavigate()

  const {
    showDatasetLimitDialog = false,
    openTalkToExpert = () => {},
    updateDashboard,
  } = useContext<IDashboardContext>(DashboardContext)

  const closeDatasetLimitDialog = () => {
    updateDashboard({ showDatasetLimitDialog: false })
  }

  const bookCall = () => {
    openTalkToExpert(ExternalSources.HubSpotCalendarForDiscoveryCallUrl1)
    closeDatasetLimitDialog()
  }

  const getSubscription = () => {
    navigate(Navigation.AccountSettingBilling)
    closeDatasetLimitDialog()
  }

  return (
    <Dialog
      data-testid="dataset-limit-dialog"
      header={t('labels.datasetLimitReached', { context: 'title' })}
      draggable={false}
      dismissableMask
      onHide={closeDatasetLimitDialog}
      style={{ width: '39rem' }}
      visible={showDatasetLimitDialog}
    >
      <div className="flex flex-column w-full pb-6 gap-5">
        <div data-testid="dataset-limit-description" className="flex w-full">
          {t('labels.datasetLimitReached', { context: 'description' })}
        </div>
        <div className="flex justify-content-center gap-4">

          <CardWrapper className="flex flex-column justify-content-center w-17rem">
            <OverviewCardTemplateLayout>
              <div className="flex flex-column w-full gap-3">
                <FontAwesomeIcon data-testid="dataset-limit-icon-book" className="pt-3 text-5xl text-primary-500" icon={faVideo} />

                <div data-testid="dataset-limit-text-book" className="flex justify-content-center text-center text-sm font-normal text-gray-500 px-5">
                  {t('labels.datasetLimitReached', { context: 'textBook' })}
                </div>

                <div className="flex justify-content-center">
                  <Button
                    data-testid="dataset-limit-btn-book"
                    label={t('actions.book', { context: 'freeCall', ns: 'workspace' })}
                    className="p-button p-component p-button-primary text-sm w-11rem h-2rem"
                    onClick={bookCall}
                  />
                </div>
              </div>
            </OverviewCardTemplateLayout>
          </CardWrapper>

          <CardWrapper className="flex flex-column justify-content-center w-17rem">
            <OverviewCardTemplateLayout>
              <div className="flex justify-content-center flex-column w-full gap-3">
                <FontAwesomeIcon data-testid="dataset-limit-icon-subscribe" className="pt-3 text-5xl text-primary-500" icon={faBoxesPacking} />

                <div data-testid="dataset-limit-text-subscribe" className="flex justify-content-center text-center text-sm font-normal text-gray-500">
                  {t('labels.datasetLimitReached', { context: 'textSubscription' })}
                </div>

                <div className="flex justify-content-center">
                  <Button
                    data-testid="dataset-limit-btn-subscribe"
                    label={t('actions.get', { context: 'subscription' })}
                    className="p-button p-component p-button-outlined p-button-plain text-sm w-11rem h-2rem"
                    onClick={getSubscription}
                  />
                </div>
              </div>
            </OverviewCardTemplateLayout>
          </CardWrapper>

        </div>
      </div>
    </Dialog>
  )
}
