import { LARGE_INVENTORY_AMOUNT } from './const'
import { InventoryTreeKey } from './inventoryTreeKey'
import { returnNested, returnNestedArray } from './tools'

export const inventoryItemsToTree = (inventoryTreeItems = []) => {
  let mappedArr = {},
    tree = []
  for (let inventoryItem of inventoryTreeItems) {
    mappedArr[inventoryItem.nodeId] = {
      ...inventoryItem,
      children: []
    }
  }
  for (let id in mappedArr) {
    if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
      const mappedElem = mappedArr[id]
      mappedElem.parentNode
        ? mappedArr[mappedElem.parentNode] && mappedArr[mappedElem.parentNode]['children'].push(mappedElem)
        : tree.push(mappedElem)
    }
  }
  return tree
}

export const getInventoryKeys = inventory => {
  const inventoryRootId = returnNested(inventory, 'product', 'id')
  if (!inventoryRootId) return []
  const inventoryItems = returnNestedArray(inventory, 'inventoryItems')
  const rootKey = InventoryTreeKey.createFromRootId(inventoryRootId).inventoryTreeKey()

  const inventoryKeys =  inventoryItems.map(node => InventoryTreeKey.createFromInventoryNode(inventory, node).inventoryTreeKey())
  return [
    rootKey,
    ...inventoryKeys
  ]
}
export const getExpandedInventoryKeysMap = (inventory, expandedKeys, largeInventorySize = LARGE_INVENTORY_AMOUNT) => {
  const inventoryKeys = getInventoryKeys(inventory)
  // Large inventory are collapsed by default, small ones are expanded
  const defaultExpandedValue = inventoryKeys.length < largeInventorySize
  return inventoryKeys.reduce(function(acc, inventoryKey) {
    const inventoryTreeKey = InventoryTreeKey.createFromKey(inventoryKey)
    if (inventoryTreeKey.isRoot()) {
      acc[inventoryKey] = true // Always expand the root
    } else {
      Object.prototype.hasOwnProperty.call(expandedKeys, inventoryKey) // User already expanded/collapsed the node, don't overwrite his/her choice
        ? acc[inventoryKey] = expandedKeys[inventoryKey] !== defaultExpandedValue ? expandedKeys[inventoryKey] : defaultExpandedValue
        : acc[inventoryKey] = defaultExpandedValue // Always expand the root
    }
    return acc
  }, {})
}

export const getRootNodeOfInventoryItem = nodeId => {
  const nodeArray = nodeId.split(':')
  return `${nodeArray[0]}:${nodeArray[1]}`
}

export const isInventoryItemWithChildNodes = inventoryItem => {
  const nodeArray = inventoryItem.nodeId.split(':')
  return nodeArray.length === 3
}
