import libphonenumber from 'google-libphonenumber'

/**
 * Validate phone number format by country.
 *
 * @param {String} phone phone number.
 * @param {String} country country code.
 *
 */
export const libphonePhoneByCountyValidator = (phone, country) => {
  if (!phone || !country) return false
  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
    const number = phoneUtil.parseAndKeepRawInput(phone, country)
    return phoneUtil.isValidNumber(number)
  } catch (error) {
    return false
  }
}
