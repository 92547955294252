
import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'

export type TProgressSpinnerComponentProps = {
  size?: number
  dataTestId?: string
}

export const ProgressSpinnerComponent = ({
  size,
  dataTestId
}: TProgressSpinnerComponentProps) => (
  <div data-testid={dataTestId} className="flex w-full h-full align-items-center justify-content-center">
    <ProgressSpinner className={`w-${size}rem h-${size}rem`} />
  </div>
)
