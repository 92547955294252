import { connect } from 'react-redux'
import { trackEventSaga } from '../../redux/sagas/tracking.saga'

import Changelog from './changelog'

const mapDispatchToProps = {
  trackEventSaga
}

export default connect(
  null,
  mapDispatchToProps
)(Changelog)
