import { gql } from '@apollo/client'
import referenceProduct from '../fragment/referenceProduct'

export default gql`
  query possibleInventoryItems($productID: ID!) {
    possibleInventoryItems(productID: $productID) {
      id
      name
      type
      unit
      labels {
        id
      }
      referenceProduct {
        ...referenceProduct
      }
    }
  }
  ${referenceProduct}
`
