import memoize from 'lodash.memoize'
import { isEmpty, returnNested } from '../../utils/tools'

const root = state => returnNested(state, 'inventoryTreeExpandedKeys')

const expandedKeysMapByRootId = (state, inventoryRootId) => {
  const expandedKeysMap = returnNested(root(state), inventoryRootId)
  if (isEmpty(expandedKeysMap)) return {}
  return expandedKeysMap
}

const expandedKeysByRootId = (state, inventoryRootId) => {
  const expandedKeys = returnNested(root(state), inventoryRootId)
  if (isEmpty(expandedKeys)) return []
  return Object.keys(expandedKeys).reduce((returnVal, key) => {
    if (expandedKeys[key]) {
      returnVal.push(key)
    }
    return returnVal
  }, [])
}
const memoizeExpandedKeysByRootId = memoize(
  expandedKeysByRootId, (state, inventoryRootId) => {
    const inventoryTreeExpandedKeysStr = JSON.stringify(returnNested(state, 'inventoryTreeExpandedKeys'))
    return `${ inventoryTreeExpandedKeysStr }${ inventoryRootId }`
  })

const InventoryExpandedKeysSelector = {
  expandedKeysMapByRootId,
  expandedKeysByRootId: memoizeExpandedKeysByRootId,
}

export default InventoryExpandedKeysSelector
