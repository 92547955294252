import { Card, Col, Progress, Row, Statistic } from 'antd'
import { TeamOutlined, ApartmentOutlined } from '@ant-design/icons'
import React from 'react'
import { withTranslation } from 'react-i18next'
import compose from '../../../utils/compose'
import { USAGE_TYPE } from '../../../utils/const'
import { returnNested, safeArray } from '../../../utils/tools'

class Usage extends React.Component {
  renderUsage = usage => {
    const { t } = this.props
    let { count, limit } = usage
    if (!limit) limit = count // user has no subscription
    const isUnlimited =  limit < 0
    const suffix = isUnlimited ? undefined : `/ ${limit}`
    const percentage = count / limit * 100
    return <div>
      <Statistic
        title={t(`billing.usage_type.${usage.type}`)}
        value={count}
        prefix={<ApartmentOutlined />}
        suffix={suffix}
      />
      {
        !isUnlimited &&
        <Progress percent={percentage} showInfo={false} />
      }

    </div>
  }
  render() {
    const {
      selectedAccountUsage,
      isStandardSubscription,
      selectedAccountSubscription,
      selectedAccountPaidUsersCount,
      isTrialing,
      isTrialExpired,
      hasValidSubscription,
      t
    } = this.props
    const userSuffix = !isStandardSubscription ? `/ ${returnNested(selectedAccountSubscription, 'quantity')}` : undefined
    // For now enterprise accounts have unlimited products
    const productUsage = safeArray(selectedAccountUsage).find(usage => usage.type === USAGE_TYPE.ECOINVENT)
    const showProductUsage = productUsage && !hasValidSubscription && (isTrialing || isTrialExpired)

    return (
      <div className="plan-usage">
        <Card type="inner" className="card-item card-title-big" data-cy="usage-card" title={t('billing.usage')} >
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title={t('billing.paid_users_count_title')}
                value={selectedAccountPaidUsersCount}
                prefix={<TeamOutlined />}
                suffix={userSuffix}
              />
            </Col>
            {showProductUsage && (
              <Col span={12}>
                {this.renderUsage(productUsage)}
              </Col>
            )}
          </Row>
        </Card>
      </div>
    )
  }
}

export default compose(
  withTranslation(),
)(Usage)
