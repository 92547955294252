import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { store } from '../../../configureStore'
import { clearRootStateExceptSettingsAction } from '../../../redux/actions/clear.actions'
import { trackEventInSegment } from '../../../utils/SegmentWrapper'
import { TrackEventType } from '../../shared/enum/track-events'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { setSelectedDatabasesStorageItem } from '../../shared/util/impact'

const { AUTH_RETURNTOURI = 'no-env' } = window.env || {}

export const Logout = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    trackEventInSegment(TrackEventType.LOGOUT)

    // TODO : Added for compatibility with v0.9
    store.dispatch(clearRootStateExceptSettingsAction())

    setSelectedDatabasesStorageItem([])
    logout({
      logoutParams: {
        returnTo: AUTH_RETURNTOURI,
      }
    })
  }, [])

  return <ProgressSpinnerComponent size={2} />
}
