import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation performScenarioAction($action: String!, $args: String!, $inventoryNodeID: ID!, $productID: ID!, $scenarioProductID: ID!) {
    performScenarioAction(action: $action, args: $args, inventoryNodeID: $inventoryNodeID, productID: $productID, scenarioProductID: $scenarioProductID){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
