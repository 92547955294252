import { returnNested, safeArray } from '../../utils/tools'
import { POLICY } from '../../utils/const'

const resourcePolicies = state => returnNested(state, 'global', 'resourcePolicies')
const selectedAccountPolicyList = state => returnNested(resourcePolicies(state), 'selectedAccount')
const selectedSpacePolicyList = state => returnNested(resourcePolicies(state), 'selectedSpace')

// ACCOUNT POLICIES
const canManageAccount = state => safeArray(selectedAccountPolicyList(state)).some( el => {
  return  el === POLICY.ACCOUNT_MANAGEMENT
})
const canWriteAccount = state => safeArray(selectedAccountPolicyList(state)).some( el => {
  return  el === POLICY.ACCOUNT_MANAGEMENT ||
          el === POLICY.ACCOUNT_WRITE
})
const canReadAccount = state => safeArray(selectedAccountPolicyList(state)).some( el => {
  return  el === POLICY.ACCOUNT_MANAGEMENT ||
          el === POLICY.ACCOUNT_WRITE ||
          el === POLICY.ACCOUNT_READ
})

// WORKSPACE POLICIES
const canManageSpace = state => safeArray(selectedSpacePolicyList(state)).some( el => {
  return  el === POLICY.SPACE_MANAGEMENT
})
const canWriteSpace = state => safeArray(selectedSpacePolicyList(state)).some( el => {
  return  el === POLICY.SPACE_MANAGEMENT ||
          el === POLICY.SPACE_WRITE
})
const canReadSpace = state => safeArray(selectedSpacePolicyList(state)).some( el => {
  return  el === POLICY.SPACE_MANAGEMENT ||
          el === POLICY.SPACE_WRITE ||
          el === POLICY.SPACE_READ
})

// BILLING POLICIES
const canManageBilling = state => safeArray(selectedAccountPolicyList(state)).some( el => {
  return  el === POLICY.BILLING_MANAGEMENT
})

const PoliciesSelector = {
  selectedAccountPolicyList,
  selectedSpacePolicyList,
  canManageAccount,
  canWriteAccount,
  canReadAccount,
  canManageSpace,
  canWriteSpace,
  canReadSpace,
  canManageBilling
}

export default PoliciesSelector
