import React from 'react'
import classnames from 'classnames'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { Form, Switch } from 'antd'
import SingleFieldText from './singleFieldText'

class SwitchWrapper extends React.PureComponent {

  getText = () => {
    const {
      t,
      dataCy,
      editHandler,
      disabled,
      switchStatus,
      checkedChildren= t('global.yes'),
      unCheckedChildren= t('global.no'),
    } = this.props
    const displayedText = switchStatus ? checkedChildren : unCheckedChildren

    return (
      <SingleFieldText
        displayedText={displayedText}
        editHandler={editHandler}
        disabled={disabled}
        dataCy={ dataCy}
      />
    )
  }

  getSelect = () => {
    const {
      t,
      autoFocus = true,
      dataCy,
      className,
      disabled,
      checkedChildren= t('global.yes'),
      unCheckedChildren= t('global.no'),
      onChange
    } = this.props

    return (
      <Switch
        autoFocus={autoFocus}
        className={classnames(`${dataCy}-switch`, { className })}
        data-cy={`${dataCy}-switch`}
        disabled={disabled}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={onChange}
      />
    )
  }

  render() {
    const {
      isFieldEditable,
      fieldLabel,
      fieldName,
      fieldRules,
      fieldValuePropName = 'checked'
    } = this.props

    return (
      <Form.Item className="field-container" name={fieldName} label={ fieldLabel } rules={fieldRules} valuePropName={fieldValuePropName}>
        {isFieldEditable ? this.getSelect() : this.getText()}
      </Form.Item>
    )
  }
}

export { SwitchWrapper }
export default compose(
  withTranslation()
)(SwitchWrapper)
