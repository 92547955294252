import React, { useContext } from 'react'

import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { InventoryViewType } from '../enum/inventory-view-type'
import { InventoryFlatViewComponent } from './chart/inventory-flat-view.component'
import { InventoryDoughnutViewComponent } from './chart/inventory-doughnut-view.component'
import { InventoryBarViewComponent } from './chart/inventory-bar-view.component'
import { ProductPdfDoughnutComponent } from './chart/product-pdf-doughnut.component'

export const ProductInsightComponent = () => {
  const {
    selectedInventoryViewType,
    showPdfInsightsResult,
    transformedLeafItems,
    loadingLeafInventoryItems
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const isInsightViewType = (viewType: InventoryViewType) => !loadingLeafInventoryItems && viewType === selectedInventoryViewType

  return (
    <>

      { isInsightViewType(InventoryViewType.FlatView) && <InventoryFlatViewComponent /> }

      { isInsightViewType(InventoryViewType.BarChart) && <InventoryBarViewComponent /> }

      { isInsightViewType(InventoryViewType.Sunburst) && <InventoryDoughnutViewComponent /> }

      { transformedLeafItems && showPdfInsightsResult && <ProductPdfDoughnutComponent /> }

    </>
  )
}
