import React, { useState } from 'react'
import { Modal, Button, Spin } from 'antd'
import compose from '../../utils/compose'
import { withTranslation, Trans } from 'react-i18next'
import { getVersionNumber } from '../../utils/nginx'
import { store } from '../../configureStore'
import { setVersionNumberAction } from '../../redux/actions/global.actions'
import { LoadingOutlined } from '@ant-design/icons'

const ErrorHandler = ({ t }) => {
  const [ visible, setVisible ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  const loadingIcon = <LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin />

  const reload = async () => {
    const response = await getVersionNumber()
    store.dispatch(setVersionNumberAction(response.version))
    window.location.reload()
    setLoading(false)
  }

  // TODO: Check Trans component after translation upgrade
  return (<Modal
    visible={visible}
    className="modal-window"
    title={ t('err.errorHandler', { context: 'title' }) }
    onCancel={ () => setVisible(false) }
    footer={[
      <Button
        type="primary"
        onClick={
          () => {
            setLoading(true)
            reload()
          }
        }
        key="button-reload" >
        { t('err.errorHandler', { context: 'reload' }) }
      </Button>,
    ]}
  >
    {
      loading ? <Spin className='loading center' spinning={loading} indicator={loadingIcon} />
        : <Trans i18nKey="errorHandler_content">
          Mobius is trying to keep up while you make sustainable impact, but has now encountered a problem. Please try reloading the page. <br/><br/>
          You can always go back to <a href='/'>Mobius Home</a> or contact us at <a href='mailto:support@ecochain.com'>support@ecochain.com</a>
        </Trans>
    }
  </Modal>)
}

export { ErrorHandler }
export default compose(
  withTranslation()
)(ErrorHandler)
