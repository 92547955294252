import { returnNested, isNotEmpty, isEmpty, safeArray } from '../../utils/tools'

const currentAccount = state => {
  const fromSelectedAccount = returnNested(state, 'global', 'selectedAccount')
  const userAccounts = returnNested(state, 'global', 'user', 'accounts')
  const fromUserAccounts = userAccounts && isNotEmpty(userAccounts) && userAccounts[0]
  return fromSelectedAccount || fromUserAccounts || null
}

const
  root = state => returnNested(state, 'global', 'selectedAccount'),
  selectedAccountId = state => returnNested(root(state), 'id'),
  selectedAccountName = state => returnNested(root(state), 'name'),
  v2ApiCredentials = state => returnNested(root(state), 'v2ApiCredentials'),
  accountUsersList = state => returnNested(root(state), 'accountUsers'),
  accountRolesList = state => returnNested(root(state), 'accountRoles'),
  accountPermissionsList = state => returnNested(root(state), 'accountPermissions'),
  accountApiClientsList = state => returnNested(root(state), 'accountApiClients'),
  accountTypePoliciesList = state => returnNested(state, 'global', 'resourceTypePoliciesLists', 'account'),
  selectedAccountPolicies = state => returnNested(state, 'global', 'resourcePolicies', 'selectedAccount'),
  selectedAccountSubscription = state => returnNested(root(state), 'subscription'),
  selectedAccountCustomer = state => returnNested(root(state), 'customer'),
  firstPlan = state => returnNested(selectedAccountSubscription(state), 'items', 0, 'plan'),
  trialStart = state => returnNested(root(state), 'trialStart'),
  trialEnd = state => returnNested(root(state), 'trialEnd'),
  isTrialing = state => returnNested(root(state), 'isTrialing'),
  createdAt = state => returnNested(root(state), 'createdAt'),
  subscriptionId = state => returnNested(root(state), 'subscriptionId'),
  selectedAccountInvoices = state => returnNested(root(state), 'invoices'),
  selectedAccountUsage = state => returnNested(root(state), 'usage'),
  selectedAccountPaidUsersCount = state => returnNested(root(state), 'paidUsersCount'),
  hasValidSubscription = state => returnNested(root(state), 'hasValidSubscription'),
  invitedUsersList = state => returnNested(state, 'global', 'invitedUsersList'),
  subscriptionSourcedFrom = state => returnNested(root(state), 'subscriptionSourcedFrom'),
  hasAccountActiveSubscription = state => isEmpty(root(state))
  || isTrialing(state)
  || hasValidSubscription(state),
  isStandardSubscription = state => {
    const items = safeArray(returnNested(selectedAccountSubscription(state), 'items'))
    // Pay per user == false is enterprise subscription
    const payPerUserIndex = items.findIndex(item => returnNested(item, 'plan', 'isPayPerUser') === false)
    const hasPaymentMethod = returnNested(selectedAccountSubscription(state), 'paymentMethod', 'id')
    return payPerUserIndex === -1 || hasPaymentMethod
  },
  isTrialExpired = state => !isTrialing(state) && !subscriptionId(state)

const AccountSelector ={
  selectedAccount: root,
  selectedAccountId,
  selectedAccountName,
  currentAccount,
  selectedAccountPolicies,
  v2ApiCredentials,
  accountUsersList,
  accountRolesList,
  accountApiClientsList,
  accountTypePoliciesList,
  selectedAccountSubscription,
  selectedAccountCustomer,
  firstPlan,
  trialStart,
  createdAt,
  trialEnd,
  isTrialing,
  selectedAccountInvoices,
  selectedAccountUsage,
  selectedAccountPaidUsersCount,
  hasValidSubscription,
  hasAccountActiveSubscription,
  isStandardSubscription,
  subscriptionId,
  isTrialExpired,
  accountPermissionsList,
  invitedUsersList,
  subscriptionSourcedFrom
}

export default AccountSelector
