import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React, { useEffect, useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { SUBSCRIPTION_STATUS, TRACK_EVENT_TYPE } from '../../utils/const'
import { returnNested } from '../../utils/tools'
import compose from '../../utils/compose'
import SubscriptionChoice from './billing/subscriptionChoice.container'
import SubscriptionDetails from './billing/subscriptionDetails.container'
import DashboardContext from '../../v1/dashboard/context/dashboard.context'

const Billing = props => {
  const {
    selectedAccountSubscription,
    isDataViaNetworkRequesting,
    trackPageSaga,
    hasAccountActiveSubscription,
    isTrialExpired,
    hasValidSubscription,
    subscriptionId,
    subscriptionProducts,
  } = props

  const { refetchUser } = useContext(DashboardContext)

  useEffect(() => {
    refetchUser && refetchUser()
  }, [])

  useEffect(() => {
    trackPageSaga(TRACK_EVENT_TYPE.VIEWED_BILLING_SETTINGS)
  }, [
    selectedAccountSubscription,
    hasValidSubscription,
    isTrialExpired,
    hasAccountActiveSubscription,
    subscriptionId,
    subscriptionProducts
  ])

  const isPlanChoiceShowed = () => !subscriptionId || returnNested(selectedAccountSubscription, 'status') === SUBSCRIPTION_STATUS.CANCELED

  return (
    <React.Fragment>
      <Spin
        spinning={isDataViaNetworkRequesting}
        indicator={<LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin/>}
      >
        {
          isPlanChoiceShowed() &&
          <SubscriptionChoice isUpdate={false} />
        }
        <SubscriptionDetails />
      </Spin>
    </React.Fragment>
  )
}

export default compose(
  withTranslation(),
)(Billing)
