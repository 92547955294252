import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { Layout, Modal } from 'antd'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../utils/with-router'
import { BarLoader } from 'react-spinners'
import Notification from '../shared/notification/notification.container'
import ProductImport from '../model/product-tools/import/productImport.container'
import { selectedAccountVar, selectedWorkspaceVar, userVar } from '../../graphql/cache'
import { AccountSettingPageLocation } from '../shared/locations'
import { isValid } from '../../v1/shared/util/tools'

const Frame = props => {
  const {
    children,
    isDataViaNetworkRequesting,
    isDetailsPanelOpen,
    productImportModalVisibility,
    setProductImportModalVisibilityAction,
    setIsDetailsPanelOpenAction,
    setIsForcingWorkspacePanelAction,
    loadDashboardDetailV1Saga
  } = props
  const user = useReactiveVar(userVar)
  const selectedWorkspace = useReactiveVar(selectedWorkspaceVar)
  const selectedAccount = useReactiveVar(selectedAccountVar)
  const navigate = useNavigate()

  const redirectToBillingPage = () => {
    if ( location.search !== '?activeTabKey=billingManagement') {
      navigate(AccountSettingPageLocation.toUrl({
        activeTabKey: 'billingManagement'
      }))
    }
  }

  const hasValidSubscription = () =>{
    const { account: { isTrialing = false, hasValidSubscription = false } = {} } = selectedAccount || {}
    return ( isTrialing || hasValidSubscription )
  }

  useEffect(() => {
    loadDashboardDetailV1Saga({ user, selectedAccount, selectedWorkspace })

    if ( isValid(selectedAccount?.account) && !selectedAccount.loading && !hasValidSubscription() ) {
      redirectToBillingPage()
    }
  }, [ user, selectedAccount, selectedWorkspace ])

  const handleMainContainerClick = () => {
    if ( isDetailsPanelOpen ) {
      setIsDetailsPanelOpenAction(false)
      setIsForcingWorkspacePanelAction(false)
    }
  }

  const productImportModal = productImportModalVisibility => {
    return <Modal
      className="search-modal product-import-search-modal"
      title={ <h1 className='page-header-title'>Import products</h1> }
      visible={ productImportModalVisibility }
      onCancel={ () => setProductImportModalVisibilityAction(false) }
      footer={ null }
      width='80%'
    >
      <ProductImport />
    </Modal>
  }

  return (<>
    {<div className="frame">
      <Notification />
      {(isDataViaNetworkRequesting || selectedWorkspace?.loading ) && <div className='loader-wrapper' data-cy='loading-something'>
        <BarLoader height={4} color={'#56ca19'} loading={true}/>
      </div>}

      <div onClick={handleMainContainerClick} className="main-content" data-cy="main-scrollable-div">
        <React.Fragment>
          <Layout className="model">
            <Layout className='middle-container'>
              <Layout.Content style={{ height: '100%' }}>
                { children() }
              </Layout.Content>
            </Layout>
          </Layout>
        </React.Fragment>
      </div>

      { productImportModal(productImportModalVisibility) }

    </div>}
  </>)
}

export default compose(
  withTranslation(),
  withRouter,
)(Frame)
export { Frame }
