import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'

import { IControllerRender } from '../../shared/interface/react-form-hook'
import { selectedWorkspaceVar } from '../../../graphql/cache'

const CustomImpactForm = styled.div`
  width: 70rem;
`
const CustomImpactFormItem = styled.div`
  width: 32rem;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
`

const CustomImpactFooter = styled.div`
  width: calc(100% - 10px);
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);

  .p-button {
    height: 2.5rem;
  }
`

export type TCustomImpactFormItem = {
  id?: string;
  amount?: string;
  name?: string;
  unit?: string;
}
type CustomImpactComponentProps = {
  changingProductCustomImpacts?: boolean
  defaultCustomImpacts?: TCustomImpactFormItem[]
  handleChangeCustomImpact: Function
}
export const CustomImpactComponent = ({
  changingProductCustomImpacts = false,
  defaultCustomImpacts = [],
  handleChangeCustomImpact
}: CustomImpactComponentProps) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const { space = {} } = useReactiveVar(selectedWorkspaceVar) || {}

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState
  } = useForm({
    defaultValues: {
      impacts: [ ...defaultCustomImpacts ]
    },
    mode: 'onChange'
  })
  const { errors } = formState
  const { fields = [] } = useFieldArray({ control, name: 'impacts' })
  const [ hasChangedCustomImpact, setHasChangedCustomImpact ] = useState<boolean>(false)
  const { impacts: impactErrors = {} } = errors

  useEffect(() => {
    const subscription = watch(({ impacts }: any) => {
      const changeExist = impacts.some((field: any, index: number) => field.amount !== defaultCustomImpacts[index].amount)
      setHasChangedCustomImpact(changeExist)
    })
    return () => subscription.unsubscribe()
  }, [ watch ])

  const onSubmit = (data: any) => handleChangeCustomImpact(data.impacts);

  const resetValues = () => {
    const itemsWithZeroAmount = defaultCustomImpacts.map(item => ({
      ...item,
      amount: '0'
    }))
    reset({ impacts: itemsWithZeroAmount })
  }

  const isInvalidCustomInput = (index: number) => impactErrors && Object.keys(impactErrors).includes(index.toString())
  const { impactMethod, excludeLT } = space

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
      <div className="flex w-full relative h-full justify-content-center">
        <div className="flex relative w-full h-full overflow-y-auto justify-content-center pb-5">
          <CustomImpactForm className="flex flex-wrap gap-5 p-5 pt-6">
            <div className="flex w-full h-2rem">
              <span className="mr-3">
                {t('labels.impact_method', { ns: 'common' })}:
                {' '}
                <b>{ impactMethod?.name }</b>
              </span>
              <span className="ml-3">
                {t('labels.exclude_ltImpacts', { ns: 'common' })}:
                {' '}
                <b>{ excludeLT ? `${t('labels.yes', { ns: 'common' })}` : `${t('labels.no', { ns: 'common' })}`}</b>
              </span>
            </div>
            { fields.map(
              (item: any, index: number) => (
                <CustomImpactFormItem key={`custom-impact-form-item-${index}`} className="flex flex-column p-3 border-round-md">
                  <div className="flex align-items-center gap-2">
                    <div className={classNames('flex align-items-center flex-grow-1', { 'p-error': isInvalidCustomInput(index) })}>
                      { item.name }
                    </div>
                    <div className="w-5rem h-2rem">
                      <Controller
                        name={`impacts.${index}.amount`}
                        control={control}
                        rules={{ required: 'REQUIRED' }}
                        render={({ field, fieldState }: IControllerRender) => (
                          <InputText
                            id={field.name}
                            {...field}
                            lang="en-IN"
                            type="number"
                            data-testid={`custom-impact-form-input-${index}`}
                            data-cy={`custom-impact-form-input-${index}`}
                            className={classNames('w-full h-full', { 'p-invalid': fieldState.error })}
                          />
                        )}
                      />
                    </div>
                    <div className={classNames('flex align-items-center w-6rem', { 'p-error': isInvalidCustomInput(index) })}>
                      { item.unit }
                    </div>
                  </div>
                  {
                    isInvalidCustomInput(index) && <small className="p-error">{ t('messages.impactCategoryRequired', { context: 'error' })}</small>
                  }
                </CustomImpactFormItem>
              )
            )}
            <div className="w-full h-4rem" />
          </CustomImpactForm>
        </div>
        <CustomImpactFooter className="flex justify-content-end align-items-center h-4rem absolute bottom-0 left-0 bg-white gap-3 px-3">
          <Button
            onClick={(event: any) => {
              event.stopPropagation()
              event.preventDefault()
              resetValues()
            }}
            label={t('actions.clearAll', { ns: 'common' })}
            iconPos="left"
            loading={false}
            data-testid="custom-impact-reset"
            data-cy="custom-impact-reset"
            className="p-button-outlined p-button-plain"
          />

          <Button
            type="submit"
            label={t('actions.save', { ns: 'common' })}
            iconPos="left"
            disabled={changingProductCustomImpacts || !hasChangedCustomImpact}
            loading={changingProductCustomImpacts}
            data-testid="custom-impact-save"
            data-cy="custom-impact-save"
            className="p-button-primary"
          />
        </CustomImpactFooter>
      </div>
    </form>
  )
}

