import { gql } from '@apollo/client'

export default gql`
    fragment subscriptionFragment on Subscription {
        id
        status
        currentPeriodStart
        currentPeriodEnd
        quantity
        paymentMethod {
            id
            type
            card {
                brand
                expMonth
                expYear
                lastFour
            }
            isDefault
        }
        items {
            id
            quantity
            plan {
                id
                amount
                currency
                isPayPerUser
                interval
                defaultQuantity
                lookupKey
            }
        }
        invoices {
            id
            amountDue
            amountPaid
            currency
            hostedURL
            pdfURL
            created
        }
        discount {
            start
            end
            couponCode
        }
    }
`
