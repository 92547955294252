import { isNotEmpty, returnNested, safeArray } from '../../utils/tools'

const root = state => returnNested(state, 'global', 'user')
const userAccounts = state => returnNested(root(state), 'accounts')
const selectedUserId = state => returnNested(root(state), 'id')
const selectedUserEmail = state => returnNested(root(state), 'email')

const userAccountById = (state, accountId) => safeArray(userAccounts(state)).find(el => el.id === accountId)
const userFirstAccount = state => {
  const currentUserAccounts = userAccounts(state)
  return currentUserAccounts && isNotEmpty(currentUserAccounts) && currentUserAccounts[0]
}
const accountSpaces = (state, accountId) => {
  const account = userAccountById(state, accountId)
  return safeArray(returnNested(account, 'spaces'))
}

const selectedAccountPolicies = state => returnNested(state, 'global', 'resourcePolicies', 'selectedAccount')
const selectedSpacePolicies = state => returnNested(state, 'global', 'resourcePolicies', 'selectedSpace')

const UserSelector = {
  selectedUser: root,
  selectedUserId,
  selectedUserEmail,
  userAccounts,
  userFirstAccount,
  userAccountById,
  selectedAccountPolicies,
  selectedSpacePolicies,
  accountSpaces
}

export default UserSelector
