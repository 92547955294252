import { connect } from 'react-redux'
import {
  addAccountRoleAction,
  removeAccountRoleAction,
  addAccountUserToRoleAction,
  removeAccountUserFromRoleAction,
  addAccountApiClientToRoleAction,
  removeAccountApiClientFromRoleAction
} from '../../redux/actions/account.actions'
import { trackPageSaga } from '../../redux/sagas/tracking.saga'

import roleManagement from './roleManagement'

const mapDispatchToProps = {
  trackPageSaga,
  addAccountRole: addAccountRoleAction,
  removeAccountRole: removeAccountRoleAction,
  addAccountUserToRole: addAccountUserToRoleAction,
  removeAccountUserFromRole: removeAccountUserFromRoleAction,
  addAccountApiClientToRole: addAccountApiClientToRoleAction,
  removeAccountApiClientFromRole: removeAccountApiClientFromRoleAction
}

export default connect(
  null,
  mapDispatchToProps
)(roleManagement)
