import React from 'react'
import * as d3 from 'd3'

import { Arc } from 'd3-shape'
import { DecimalViewType } from '../../../shared/enum'
import { getDecimalDisplayValue } from '../../../shared/util/decimal'

import { TDonutPhase } from '../../interface/donut-chart.type'
import { removeDonutChartTooltipDisplay, setDonutChartTooltipDisplay } from '../../util/chart'
import { DonutPhaseItemComponent } from './donut-phase-item.component'
import { IImpact } from '../../../model'

type TPathProps = {
  index: number
  radius: number,
  slice: any,
  sliceColor: string,
  donutPhase: TDonutPhase,
  showSinglePhase: boolean,
  lifecycleTotalImpact?: IImpact,
  handleOnClick: (donutPhase: TDonutPhase, sliceColor: string) => void
}
export const DonutPhaseComponent = ({
  index,
  radius,
  slice,
  sliceColor,
  donutPhase,
  showSinglePhase,
  lifecycleTotalImpact,
  handleOnClick
}: TPathProps) => {
  const pathRef = React.useRef(null)
  const [ isHovered, setIsHover ] = React.useState<boolean>(false)

  const totalImpactAmount = showSinglePhase ? donutPhase.totalImpactAmount : lifecycleTotalImpact?.amount
  const totalImpactUnit = lifecycleTotalImpact?.unit
  const selectedDecimalViewType = DecimalViewType.NumericValue
  const [ coefficient, exponent ] = getDecimalDisplayValue({ value: totalImpactAmount, decimalViewType: selectedDecimalViewType })

  const outerRadius = isHovered ? radius * 1.1 : radius
  const innerRadius = radius * 0.7

  const arc = d3.arc<any, Arc<any, TDonutPhase>>()
    .innerRadius(innerRadius + 20)
    .outerRadius(outerRadius - 5)
    .padAngle(0.007)
    .cornerRadius(5)

  const hoverArc = d3.arc<any, Arc<any, TDonutPhase>>()
    .innerRadius(innerRadius + 20)
    .outerRadius(outerRadius * 1.5)

  const onMouseOver = (event: any) => {
    setIsHover(true)
    const [ coefficient, exponent ] = getDecimalDisplayValue({
      value: donutPhase.totalImpactAmount,
      decimalViewType: selectedDecimalViewType
    })

    setDonutChartTooltipDisplay({
      x: event.clientX,
      y: event.clientY,
      visibility: 'visible',
      title: donutPhase.name,
      sliceColor,
      totalImpactUnit,
      impactType: donutPhase.totalImpactType,
      coefficient,
      exponent
    })
  }

  const onMouseOut = () => {
    setIsHover(false)
    removeDonutChartTooltipDisplay()
  }

  const onClick = () => {
    handleOnClick(donutPhase, sliceColor)
  }

  return (
    <>
      <path
        d={hoverArc(slice) || undefined}
        fill="none"
        pointerEvents="all"
        onMouseOver={() => setIsHover(true)}
        onMouseOut={() => setIsHover(false)}
      />
      <path
        ref={pathRef}
        d={arc(slice) || undefined}
        fill={sliceColor}
        className="donut-chart-phase"
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />

      <DonutPhaseItemComponent {...{
        radius: outerRadius, slice, sliceColor, children: donutPhase.children, onPhaseItemHover: setIsHover
      }}
      />

      { index === 0 && coefficient && (
        <text x={0} y={-10} textAnchor="middle">
          <tspan fontFamily="Rubik" fontSize="20px" fontWeight={600} textAnchor="middle">
            { coefficient }
            { exponent && (
              <>
                <tspan dy="-0.1em" dx="0.2em" fontSize="14px">.</tspan>
                <tspan dy="0.10em" dx="0.2em">10</tspan>
                <tspan dy="-0.8em" fontSize="12px">{ exponent }</tspan>
              </>
            ) }
          </tspan>
          <tspan x={0} dy="1.5em" fontFamily="Rubik" fontSize="20px" fontWeight={400}>{ donutPhase.totalImpactUnit }</tspan>
        </text>
      ) }
    </>
  )
}
