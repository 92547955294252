import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'

import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { DeleteLifecycleComponent } from '../component/delete-lifecycle.component'
import { REMOVE_LIFECYCLE } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../../shared/util/error'

type TDeleteLifecycleContainerProps = {
  afterDeleteLifecycle: () => void
}

export const DeleteLifecycleContainer = ({
  afterDeleteLifecycle
}: TDeleteLifecycleContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const toast = useRef<Toast>(null)
  const { showDeleteLifecycleDialog = false, selectedLifecycle, updateLifecycle } = useContext<TLifecycleContext>(LifecycleContext)
  const [
    removeLifecycle,
    {
      error: failedRemovingLifecycle,
      data: removeLifecycleData,
      loading: removingLifecycle
    }
  ] = useMutation(REMOVE_LIFECYCLE)
  const lifecycleRemoved = removeLifecycleData?.removeLifecycle

  useEffect(() => {
    if (!removingLifecycle && (lifecycleRemoved || failedRemovingLifecycle)) {
      removeLifecycleCallback()
    }
  }, [ removingLifecycle, lifecycleRemoved, failedRemovingLifecycle ])

  const closeDeleteLifecycleDialog = () => {
    updateLifecycle({ showDeleteLifecycleDialog: false })
  }

  const handleDeleteLifecycle = () => {
    selectedLifecycle?.id && removeLifecycle({ variables: { id: selectedLifecycle?.id } })
  }

  const removeLifecycleCallback = () => {
    try {
      if (failedRemovingLifecycle) {
        throw failedRemovingLifecycle
      } else if (lifecycleRemoved) {
        afterDeleteLifecycle()
        closeDeleteLifecycleDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: 'Successfully deleted the lifecycle',
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed deleting the lifecycle', error?.graphQLErrors)
    }
  }

  return (
    <>
      <DeleteLifecycleComponent
        handleDeleteLifecycle={handleDeleteLifecycle}
        closeDeleteLifecycleDialog={closeDeleteLifecycleDialog}
        showDeleteLifecycleDialog={showDeleteLifecycleDialog}
      />

      <Toast data-testid="delete-lifecycle-status" ref={toast} position="top-right" />
    </>
  )
}
