import { connect } from 'react-redux'
import { setIsImpactSelectorDialogShowedAction } from '../../../../../redux/actions/flags.actions'

import CustomImpact from './customImpact'

const mapDispatchToProps = {
  setIsImpactSelectorDialogShowedAction
}

export default connect(
  null,
  mapDispatchToProps
)(CustomImpact)
