export enum NumberPrecision {
  Low = 1,
  Medium = 2,
  High = 3
}

export enum ScientificNotationThreshold {
  None = 1,
  Low = 2,
  Medium = 3
}
