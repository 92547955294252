import { connect } from 'react-redux'
import { startCheckoutSetupSaga } from '../../../../redux/sagas/stripe.saga'
import AccountSelector from '../../../../redux/selectors/account.selector'
import PaymentMethod from './paymentMethod'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'

const mapStateToProps = state => ({
  selectedAccountID: AccountSelector.selectedAccountId(state),
  subscription: AccountSelector.selectedAccountSubscription(state),
})

const mapDispatchToProps = {
  startCheckoutSetupSaga,
  trackEventSaga
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethod)
