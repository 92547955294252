import React from 'react'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { IImpactArrowProps } from './impactArrow.interface'
import { ImpactEffect } from '../../../../utils/const'

const ImpactArrow = ({
  impactEffect = ImpactEffect.NONE,
  percentage,
  size
}: IImpactArrowProps) => {
  const getImpactArrow = () => {
    return (
      (impactEffect === ImpactEffect.POSITIVE) ?
        <AiOutlineArrowDown className={'impact-arrow__icon impact-arrow__icon--positive'} /> :
        <AiOutlineArrowUp className={'impact-arrow__icon impact-arrow__icon--negative'} />
    )
  }

  if (impactEffect === ImpactEffect.NONE) return null

  return <span className="impact-arrow">
    <span className={`impact-arrow impact-arrow${size ? `--${size}` : ''}`}>
      {getImpactArrow()}
      {percentage && <span
        className={`impact-arrow__percentages impact-arrow__percentages--${impactEffect}`}
      >
        {impactEffect === ImpactEffect.NEGATIVE && '+'}
        {percentage}%
      </span>}
    </span>
  </span>
}

export default ImpactArrow
