import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { Frame } from '../../layout'
import LifecycleDetails from '../../model/details-panel/lifecycle-details/lifecycleDetails.container'
import ProductDetails from '../../model/details-panel/product-details/productDetails.container'
import WorkspaceDetails from '../../model/details-panel/workspace-details/workspaceDetails.container'
import PageHeader from '../../model/page-header/page-header'
import Inventory from '../../model/inventory/inventory.container'
import { tabChartView, tabFlatView, tabSunburst, tabTreeView } from '../../shared/locations'
import { goToProductList, goToInventoryRootProduct } from '../../../utils/goBackNavigation'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import ProductTools from '../../model/product-tools/productTools.container'
import { TRACK_EVENT_TYPE, IMPACT_CALCULATION_STATUS, TIME } from '../../../utils/const'
import DetailsPanel from '../../model/details-panel/detailsPanel.container'
import ImpactSelectorDialog from '../../helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'

const InventoryPage = props => {
  const {
    clearInventoryTreeStateAction,
    productId,
    productLabel,
    productScenarioId,
    compareEntityId,
    compareEntityType,
    trackPageSaga,
    setIsSkipPhasesAction,
    loadInventoryPageSaga,
    activeTabKey,
    inventoryTotalImpactStatus,
    isCreateLifecycle,
    selectedProductId,
    selectedSpaceId,
    workspaceSlug,
    selectedAccountId,
    isInventoryCompare,
    isShowProductScenarioInventory,
    inventoryProductName,
    inventoryRootItemName,
    inventoryRootItemId,
    getSpaceBySlugWithProductsSaga,
    updateInventoryItemsSaga,
    impactCategoryId,
    productMutator,
    excludeLT,
    useMethodTotal,
    nwSetId,
    t
  } = props

  const tabTrackByKey = new Map([
    [ tabTreeView, () => trackPageSaga(TRACK_EVENT_TYPE.VIEWED_TREEVIEW) ],
    [ tabFlatView, () => trackPageSaga(TRACK_EVENT_TYPE.VIEWED_FLATVIEW) ],
    [ tabChartView, () => trackPageSaga(TRACK_EVENT_TYPE.VIEWED_BARCHART) ],
    [ tabSunburst, () => trackPageSaga(TRACK_EVENT_TYPE.VIEWED_SUNBURST) ]
  ])

  useEffect(() => {
    loadWorkspace()
    setIsSkipPhasesAction(true)
    trackTabChange(activeTabKey)
    loadInventoryPageSaga({
      productId,
      productScenarioId,
      entityId: compareEntityId,
      entityType: compareEntityType,
      shouldSelectedProductBeUpdated: false
    })

    return () => {
      clearInventoryTreeStateAction()
    }
  }, [])

  useEffect(() => {
    const inventoryIntervalUpdate = updateInventoryEachMinute()
    return () => {
      clearInterval(inventoryIntervalUpdate)
    }
  }, [ inventoryTotalImpactStatus ])

  useEffect(() => loadWorkspace(), [ workspaceSlug ])
  useEffect(() => trackTabChange(), [ activeTabKey ])
  useEffect(() => {
    loadInventoryPageSaga({
      productId,
      productScenarioId,
      entityId: compareEntityId,
      entityType: compareEntityType,
      shouldSelectedProductBeUpdated: false
    })
  }, [ productId, productScenarioId, compareEntityId, impactCategoryId, excludeLT, useMethodTotal, nwSetId ])

  const updateInventoryEachMinute = () => {
    return setInterval(() => {
      if (inventoryTotalImpactStatus !== IMPACT_CALCULATION_STATUS.OK) {
        updateInventoryItemsSaga()
      }
    }, TIME.FIVE_MINUTES)
  }

  const trackTabChange = () => {
    const trackAction = tabTrackByKey && tabTrackByKey.get(activeTabKey)
    trackAction && trackAction()
  }

  const loadWorkspace = () => {
    getSpaceBySlugWithProductsSaga(workspaceSlug, selectedAccountId)
  }

  const getDetailsPanel = () => {
    const lifecyclePanel = isCreateLifecycle && <LifecycleDetails/>
    const productPanel = !isCreateLifecycle && selectedProductId && <ProductDetails updatePageItems={ updateInventoryItemsSaga }/>
    const spacePanel = !isCreateLifecycle && !selectedProductId && selectedSpaceId && <WorkspaceDetails updatePageItems={ updateInventoryItemsSaga } />

    return lifecyclePanel || productPanel || spacePanel
  }

  const getPageHeaderProps = () => {
    const pageHeaderData = {
      headerType: productLabel,
      headerTitle: inventoryRootItemName,
      goToPreviousPage: goToProductList,
      addCustomButton: <ProductTools />
    }
    if (isShowProductScenarioInventory || isInventoryCompare) {
      pageHeaderData.headerTitle = isShowProductScenarioInventory ? t('model.scenario') : t('model.comparison')
      pageHeaderData.goToPreviousPage = goToInventoryRootProduct
      pageHeaderData.previousNavigationStateName = inventoryProductName
    }

    return pageHeaderData
  }

  return <Frame>
    { () => <React.Fragment>
      <Row>
        <Col span={24} className='inventory middle-panel' data-cy="middle-panel-for-inventory">
          <PageHeader {...getPageHeaderProps()} />
          <section className='page-container'>
            <Inventory
              key={ inventoryRootItemId }
              activeTabKey={ activeTabKey }
            />
          </section>
        </Col>
      </Row>
      <DetailsPanel>
        {getDetailsPanel()}
      </DetailsPanel>
      { selectedProductId && <ImpactSelectorDialog
        productMutator={ productMutator }
        updatePageItems={ updateInventoryItemsSaga }
      />
      }
    </React.Fragment> }
  </Frame>
}

export default compose(
  withTranslation()
)(InventoryPage)

