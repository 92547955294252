import { connect } from 'react-redux'
import FlagsSelector from '../redux/selectors/flags.selector'
import UserSelector from '../redux/selectors/user.selector'
import AccountSelector from '../redux/selectors/account.selector'
import SpaceSelector from '../redux/selectors/space.selector'
import SentryScope from './sentryScope'

const mapStateToProps = state => ({
  userId: UserSelector.selectedUserId(state),
  accountId: AccountSelector.selectedAccountId(state),
  accountName: AccountSelector.selectedAccountName(state),
  spaceId: SpaceSelector.spaceId(state),
  spaceName: SpaceSelector.spaceName(state),
  isAdBlockEnabled: FlagsSelector.isAdBlockEnabled(state)
})

export default connect(
  mapStateToProps
)(SentryScope)
