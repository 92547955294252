import React from 'react'

import { Chart } from 'primereact/chart'
import styled from 'styled-components'

import { selectedAccountVar, selectedWorkspaceVar, userAuth0Var } from '../../../../graphql/cache'

import { generateInsightsPdf } from '../../util/pdf/pdf-generators'
import { pdfTheme } from '../../constants/theme'
import { ITransformedLeafItem } from '../../../product/interface/transformed-leaf-item'
import { IImpact } from '../../../model'

const DoughnutViewWrapper = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;

  .p-chart {
    width: 35rem;
    height: 35rem;
  }
`

type TPdfDoughnutComponent = {
  entity: any
  totalImpact: IImpact
  transformedLeafItems: ITransformedLeafItem[]
  afterPdfDoughnutDownload?: Function
}

export const PdfDoughnutComponent = ({
  entity,
  totalImpact,
  transformedLeafItems = [],
  afterPdfDoughnutDownload = () => {},
}: TPdfDoughnutComponent) => {
  const user = userAuth0Var() || {}
  const { account } = selectedAccountVar() || {}
  const { space } = selectedWorkspaceVar() || {}

  const maxItems = 8

  const filteredLeafItems = transformedLeafItems
    .filter((item: any) => Number(item.impactAmount) !== 0)
    .sort((a: any, b: any) => Number(b.impactAmount) - Number(a.impactAmount))

  const mapDataForDoughnut = () => {
    const data = filteredLeafItems.map((item: any) => item.impactAmount)
    const backgroundColor = filteredLeafItems.map((item :ITransformedLeafItem, index: number) => {
      if (item?.impactAmount && parseFloat(item.impactAmount) < 0) {
        return pdfTheme.colors.green200
      }

      return item && index <= maxItems - 1 ? pdfTheme.doughnutColors[index] : pdfTheme.colors.primary100
    })

    return {
      datasets: [
        {
          data,
          backgroundColor
        }
      ]
    }
  }

  const dataForDoughnut = mapDataForDoughnut()

  const cropLegendText = (text: string) => {
    const maxWidth = 84
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    if (context) {
      context.font = '10px Rubik'

      const { width } = context.measureText(text)

      if (width <= maxWidth) return text

      const firstPart = text.slice(0, (11))
      const secondPart = text.slice(11)

      let croppedString = ''

      secondPart.split('').forEach(char => {
        if (context.measureText(firstPart + croppedString).width < maxWidth) {
          croppedString += char
        }
      })
      return `${firstPart + croppedString}…`
    }
    return text
  }

  const mapDataForLegend = () => {
    const { doughnutColors = [] } = pdfTheme || {}
    const legendData: any = []

    const positiveChartItems = filteredLeafItems.filter((item :ITransformedLeafItem) => item?.impactAmount && parseFloat(item?.impactAmount) >= 0)

    const negativeChartItems = filteredLeafItems.filter((item :ITransformedLeafItem) => item?.impactAmount && parseFloat(item?.impactAmount) < 0)

    const getPercentage = (value?: ITransformedLeafItem) => {
      const { impactRatioRelativeToTotal } = value || {}
      return (impactRatioRelativeToTotal && impactRatioRelativeToTotal * 100) || 0
    }

    const othersSummary = positiveChartItems.reduce((acc, item :ITransformedLeafItem, index: number) => acc + (index > maxItems - 1 ? getPercentage(item) : 0), 0)

    const negativeImpactSummary = negativeChartItems.reduce((acc, item: ITransformedLeafItem) => acc + (getPercentage(item) || 0), 0)

    positiveChartItems
      .forEach((item: ITransformedLeafItem, index: number) => {
        const { name } = item || {}
        if (index < maxItems) {
          legendData.push({
            name: name && cropLegendText(name), perc: getPercentage(item).toFixed(2), color: doughnutColors[index]
          })
        }
      })

    if (positiveChartItems.length > maxItems) {
      legendData.push({
        name: 'Others',
        perc: othersSummary.toFixed(2),
        color: pdfTheme.colors.primary100
      })
    }

    if (negativeChartItems.length > 0) {
      legendData.push({
        name: 'Impact below 0',
        perc: negativeImpactSummary.toFixed(2),
        color: pdfTheme.colors.green200
      })
    }
    return legendData
  }

  const doughnutOptions: any = {
    cutout: '57%',
    animation: false,
    devicePixelRatio: 2,
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  const doughnutPlugins: any = [
    {
      afterRender: (doughnut: any) => {
        const data = {
          doughnut,
          user,
          space,
          account,
          entity,
          totalImpact,
          legend: mapDataForLegend()
        }
        generateInsightsPdf(data, () => {
          doughnut.destroy()
          afterPdfDoughnutDownload && afterPdfDoughnutDownload()
        })
      }
    }
  ]

  return (
    <DoughnutViewWrapper>
      { dataForDoughnut?.datasets?.length > 0 && <Chart type="doughnut" data={dataForDoughnut} options={doughnutOptions} plugins={doughnutPlugins} />}
    </DoughnutViewWrapper>
  )
}
