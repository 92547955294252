import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/pro-regular-svg-icons'

import { AddImpactDatasetContainer } from '../container/add-impact-dataset.container'
import { IReferenceProduct } from '../../model'
import { generateDescriptionContent } from '../util/content'

const ContentWrapper = styled.div`
  white-space: pre-wrap;
`

type TDetailContentComponentProps = {
  selectedReferenceProduct: IReferenceProduct,
  isDatabaseSearchViewType: boolean,
  isSidebar?: boolean
}
export const DetailContentComponent = ({
  selectedReferenceProduct,
  isDatabaseSearchViewType,
  isSidebar = false
}: TDetailContentComponentProps) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const containerRef = useRef<HTMLDivElement>(null)
  const {
    name,
    referenceProduct,
    databaseName,
    referenceUnit,
    referenceProperty,
    location,
    category,
    parentCategory,
    description
  } = selectedReferenceProduct

  const isEcoinventDatabase = databaseName?.toLowerCase()?.includes('ecoinvent')

  useEffect(() => {
    if (containerRef?.current && description) {
      generateDescriptionContent(containerRef.current, description, isEcoinventDatabase)
    }
  }, [ containerRef?.current ])

  const getDescriptionSection = (header: string, content?: string) => {
    if (!content) return null;

    return (
      <div className="flex flex-column w-full gap-2">
        <div className="block w-full text-sm font-medium">{header}</div>
        <ContentWrapper ref={containerRef} className="block w-full text-sm" />
      </div>
    );
  };

  const getSection = (header: string, content?: string) => (
    <>
      { content && (
        <div className="flex flex-column w-full gap-2">
          <div className="block w-full text-sm font-medium">{header}</div>
          <div className="block w-full text-sm text-wrap">{content}</div>
        </div>
      )}
    </>
  )

  return (
    <div className="flex flex-column w-full h-full overflow-y-auto gap-5">

      { isSidebar
        ? (
          <>
            <div className="flex w-full">
              { selectedReferenceProduct.id && <AddImpactDatasetContainer referenceProductID={selectedReferenceProduct?.id} isFromDatasetDetail /> }
            </div>

            { getSection(t('labels.geography'), location?.name) }

            { getSection(t('labels.referenceProduct'), referenceProduct) }

            { getSection(t('labels.datasetName'), name) }

            { getSection(t('labels.unit', { ns: 'common' }), referenceUnit?.name) }

            { getSection(t('labels.referenceProperty'), referenceProperty?.name) }

            { isDatabaseSearchViewType && getSection(t('labels.database'), databaseName) }

            { isDatabaseSearchViewType && getSection(t('labels.category'), category?.name) }

            { !isDatabaseSearchViewType && getSection(t('labels.compartment'), parentCategory?.name) }

            { !isDatabaseSearchViewType && getSection(t('labels.compartment', { context: 'sub' }), category?.name) }
          </>
        )
        : (
          <>
            { getDescriptionSection(t('labels.description', { ns: 'common' }), description) }

            { !description && (
              <div className="flex flex-column justify-content-center align-items-center w-full h-full" data-testid="no-datasets-search-results">
                <div className="font-semibold text-2xl w-20rem mb-4">{ t('labels.noDescription', { context: !isDatabaseSearchViewType ? 'elementaryFlow' : 'databaseSearch' }) }</div>
                <FontAwesomeIcon icon={faBook} className="text-primary-100" fontSize={96} />
              </div>
            )}
          </>
        )}

    </div>
  )
}
