import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation addInventoryItem($productID: ID!, $productAmount: String!, $inventoryItemID: ID!, $inventoryItemAmount: String!) {
    addInventoryItem(productID: $productID, productAmount: $productAmount, inventoryItemID: $inventoryItemID, inventoryItemAmount: $inventoryItemAmount){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
