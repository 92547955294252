import { gql } from '@apollo/client'

export default gql`
  query productExport($id: ID!) {
    productExport(id: $id) {
        level
        name
        description
        amount
        unit
        label
        datasetID
        datasetName
        type

        area
        areaTime
        bioticProdOcc
        bioticProdTransf
        duration
        energy
        energyAreaTime
        energyMassTime
        erosionOcc
        erosionTransf
        transport
        grossCalorific
        groundwaterOcc
        groundwaterTransf
        guestNight
        itemsLength
        length
        lengthTime
        marketValue
        mass
        massTime
        mechFiltrationOcc
        mechFiltrationTransf
        netCalorific
        normalVolume
        numItems
        personTransport
        physFiltrationOcc
        physFiltrationTransf
        radioactivity
        vehiculeTransport
        volume
        volumeLength
        volumeTime
    }
  }
`
