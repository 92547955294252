import { call, all, cancelled, delay, put, select, takeLatest, takeEvery } from 'redux-saga/effects'

import { dispatchErrors, productMutation, updateSelectedProduct } from './helpers'
import { updateLifecyclesItems, updateLifecyclesItemsSaga } from './lifecycle.saga'
import { getSpace, getSpaceSaga } from './space.saga'
import { removeConversionItem, removeConversionItemSaga } from './conversion.saga'
import { SAGA, PAGINATION } from '../../utils/const'
import { InventoryTreeKey, getInventoryNodeFromInventoryItemKey } from '../../utils/inventoryTreeKey'
import resource from '../../utils/resource/resource'
import { browserTimezone, floatToString, safeArray, returnNested } from '../../utils/tools'
import {
  setIsCreateLifecycleAction,
  setIsCreateNewInventoryItemAction,
  setIsCreatePhaseAction,
  setIsDetailsPanelOpenAction,
  setIsForcingWorkspacePanelAction,
  setIsCreateProductDialogShowedAction,
  setIsCreateFolderDialogShowedAction,
  setIsImpactSelectorDialogShowedAction
} from '../actions/flags.actions'
import {
  addProductToListOfProductsForTransportation,
  removeProductAction,
  removeProductReferenceAction,
  renameProductAction,
  setActivityProductsListAction,
  setPossibleInventoryItemsAction,
  setSelectedInventoryItemKeyAction,
  setSelectedProductAction,
  setSelectedSpaceProductsAction,
  setVehicleList,
  setFavoriteProductsAction,
  addProductToFavoritesAction,
  removeProductFromFavoritesAction,
  changeProductDescriptionAction,
  setProductImportColumnsAction,
  addTagToSelectedProductAction,
  addTagsToSelectedProductAction,
  setSpaceProductTagListAction,
  removeTagFromSelectedProductAction,
  setModulesAction,
  setModuleToProductAction,
  setSelectedSpaceFoldersAction,
  setReferencePropertiesListAction
} from '../actions/global.actions'
import { setSelectedPhaseAction, setDetailsPanelLifecycleIdAction } from '../actions/lifecycle.actions'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import { SelectedProductSelector, referencePropertiesListSelector } from '../selectors/product.selector'
import SpaceSelector from '../selectors/space.selector'
import GlobalSelector from '../selectors/global.selector'
import ProductMutationSelector from '../selectors/productMutation.selector'

export const
  GET_PRODUCT_WITH_IMPACT = 'GET_PRODUCT_WITH_IMPACT_SAGA',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT_SAGA',
  COPY_PRODUCT = 'COPY_PRODUCT_SAGA',
  SELECTED_INVENTORY_ITEM = 'SELECTED_INVENTORY_ITEM_SAGA',
  SELECTED_INVENTORY_ITEM_FOR_CREATE_PRODUCT = 'SELECTED_INVENTORY_ITEM_FOR_CREATE_PRODUCT_SAGA',
  SELECTED_INVENTORY_ITEM_FOR_REMOVE_PRODUCT = 'SELECTED_INVENTORY_ITEM_FOR_REMOVE_PRODUCT_SAGA',
  SELECTED_FLATVIEW_ITEM = 'SELECTED_FLATVIEW_ITEM_SAGA',
  GET_POSSIBLE_INVENTORY_ITEMS = 'GET_POSSIBLE_INVENTORY_ITEMS_SAGA',
  SEARCH_PRODUCTS = 'SEARCH_PRODUCTS_SAGA',
  SEARCH_FOLDERS = 'SEARCH_FOLDERS_SAGA',
  RENAME_PRODUCT = 'RENAME_PRODUCT_SAGA',
  REPLACE_LABEL = 'REPLACE_PRODUCT_SAGA',
  CHANGE_PRODUCT_UNIT = 'CHANGE_PRODUCT_UNIT_SAGA',
  CHANGE_PRODUCT_TYPE = 'CHANGE_PRODUCT_TYPE_SAGA',
  CHANGE_INVENTORY_ITEM_AMOUNT = 'CHANGE_INVENTORY_ITEM_AMOUNT_SAGA',
  CREATE_INVENTORY_ITEM = 'CREATE_INVENTORY_ITEM_SAGA',
  ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM_SAGA',
  CHANGE_PRODUCT_REFERENCE = 'CHANGE_PRODUCT_REFERENCE_SAGA',
  REMOVE_PRODUCT_REFERENCE = 'REMOVE_PRODUCT_REFERENCE_SAGA',
  ADD_PRODUCT_PROPERTY = 'ADD_PRODUCT_PROPERTY_SAGA',
  CHANGE_PRODUCT_PROPERTY = 'CHANGE_PRODUCT_PROPERTY_SAGA',
  REMOVE_PRODUCT_PROPERTY = 'REMOVE_PRODUCT_PROPERTY_SAGA',
  UPDATE_SPACE_PRODUCTS = 'UPDATE_SPACE_PRODUCTS_SAGA',
  IMPORT_V2_PRODUCTS = 'IMPORT_V2_PRODUCTS_SAGA',
  GET_PROPERTY_AMOUNT = 'GET_PROPERTY_AMOUNT_SAGA',
  CREATE_TRANSPORT_ACTIVITY = 'CREATE_TRANSPORT_ACTIVITY_SAGA',
  GET_VEHICLE_LIST = 'GET_VEHICLE_LIST_SAGA',
  GET_ACTIVITY_PRODUCTS = 'GET_ACTIVITY_PRODUCTS_SAGA',
  CREATE_PRODUCT = 'CREATE_PRODUCT_SAGA',
  CREATE_FOLDER = 'CREATE_FOLDER_SAGA',
  RENAME_FOLDER = 'RENAME_FOLDER_SAGA',
  GET_FAVORITE_PRODUCTS = 'GET_FAVORITE_PRODUCTS_SAGA',
  ADD_PRODUCT_TO_FAVORITES = 'ADD_PRODUCT_TO_FAVORITES_SAGA',
  REMOVE_PRODUCT_FROM_FAVORITES = 'REMOVE_PRODUCT_FROM_FAVORITES_SAGA',
  CHANGE_PRODUCT_DESCRIPTION = 'CHANGE_PRODUCT_DESCRIPTION_SAGA',
  SEARCH_PRODUCT_LISTS_FROM_REDUX_STATE = 'SEARCH_PRODUCT_LISTS_FROM_REDUX_STATE_SAGA',
  EXPORT_PRODUCT_IMPACT = 'EXPORT_PRODUCT_IMPACT_SAGA',
  GET_PRODUCT_IMPORT_COLUMNS = 'GET_PRODUCT_IMPORT_COLUMNS_SAGA',
  CHANGE_PRODUCT_CUSTOM_IMPACTS = 'CHANGE_PRODUCT_CUSTOM_IMPACTS_SAGA',
  CREATE_TAG = 'CREATE_TAG_SAGA',
  TAGS_SEARCH = 'TAGS_SEARCH_SAGA',
  ADD_TAG_TO_PRODUCT = 'ADD_TAG_TO_PRODUCT_SAGA',
  REMOVE_TAG_FROM_PRODUCT = 'REMOVE_TAG_FROM_PRODUCT_SAGA',
  SET_SPACE_PRODUCT_TAG_LIST = 'SET_SPACE_PRODUCT_TAG_LIST_SAGA',
  GET_MODULES = 'GET_MODULES_SAGA',
  ASSIGN_MODULE_TO_PRODUCT = 'ASSIGN_MODULE_TO_PRODUCT_SAGA',
  SELECTED_INVENTORY_ITEM_FOR_ADD_REFERENCE = 'SELECTED_INVENTORY_ITEM_FOR_ADD_REFERENCE_SAGA',
  SHOW_PRODUCT_IN_DETAILS_PANEL = 'SHOW_PRODUCT_IN_DETAILS_PANEL_SAGA',
  SELECTED_INVENTORY_ITEM_FOR_CHANGE_AMOUNT = 'SELECTED_INVENTORY_ITEM_FOR_CHANGE_AMOUNT_SAGA',
  MOVE_PRODUCT_TO_FOLDER = 'MOVE_PRODUCT_TO_FOLDER_SAGA',
  MOVE_FOLDER_TO_FOLDER = 'MOVE_FOLDER_TO_FOLDER_SAGA',
  REMOVE_ITEM_FOLDER = 'REMOVE_ITEM_FOLDER_SAGA'

export const
  getModulesSaga = () => ({
    type: GET_MODULES
  }),
  assignModuleToProductSaga = ({ productID, module, cb }) => ({
    type: ASSIGN_MODULE_TO_PRODUCT,
    productID, module, cb
  }),
  getProductWithImpactSaga = productId => ({
    type: GET_PRODUCT_WITH_IMPACT,
    productId
  }),
  setSpaceProductTagListSaga = ({ spaceID }) => ({
    type: SET_SPACE_PRODUCT_TAG_LIST,
    spaceID
  }),
  createTagSaga = ({ name, color, spaceID, productID, cb }) => ({
    type: CREATE_TAG,
    name, color, spaceID, productID, cb
  }),
  tagsSearchSaga = (spaceID, query, cb) => ({
    type: TAGS_SEARCH,
    spaceID, query, cb
  }),
  addTagToProductSaga = (tagID, productID, cb) => ({
    type: ADD_TAG_TO_PRODUCT,
    tagID, productID, cb
  }),
  removeTagFromProductSaga = (tagID, productID, spaceID, cb) => ({
    type: REMOVE_TAG_FROM_PRODUCT,
    tagID, productID, spaceID, cb
  }),
  removeProductSaga = (productId, cb) => ({
    type: REMOVE_PRODUCT,
    productId, cb
  }),
  copyProductSaga = (productId, cb) => ({
    type: COPY_PRODUCT,
    productId, cb
  }),
  getPossibleInventoryItemsSaga = productId => ({
    type: GET_POSSIBLE_INVENTORY_ITEMS,
    productId
  }),
  selectedInventoryItemSaga = (key, phase=null ) => ({
    type: SELECTED_INVENTORY_ITEM,
    key, phase
  }),
  selectedInventoryItemForCreateProductSaga = ({ key, phase = null }) => ({
    type: SELECTED_INVENTORY_ITEM_FOR_CREATE_PRODUCT,
    key, phase
  }),
  selectedInventoryItemForRemoveProductSaga = ({ key, cb } ) => ({
    type: SELECTED_INVENTORY_ITEM_FOR_REMOVE_PRODUCT,
    key, cb
  }),
  selectedFlatViewItemSaga = (productId, phaseId=null ) => ({
    type: SELECTED_FLATVIEW_ITEM,
    productId, phaseId
  }),
  updateSpaceProductsSaga = (spaceId, labels ) => ({
    type: UPDATE_SPACE_PRODUCTS,
    spaceId, labels
  }),
  searchProductsSaga = ({ spaceId, abortController, currentPage, cb, sortBy, noFavorite, noCategories, labels, folderId }) => ({
    type: SEARCH_PRODUCTS,
    spaceId, abortController, currentPage, cb, sortBy, noFavorite, noCategories, labels, folderId
  }),
  searchFoldersSaga = ({ spaceId, cb, labels, folderId }) => ({
    type: SEARCH_FOLDERS,
    spaceId, cb, labels, folderId
  }),
  renameProductSaga = ({ productMutator, productId, name, labels, currentPage, cb, withProductListUpdate }) => ({
    type: RENAME_PRODUCT,
    productMutator, productId, name, labels, currentPage, cb, withProductListUpdate
  }),
  replaceLabelSaga = ({ productId, labelToReplace, replacementLabel, currentPage, cb }) => ({
    type: REPLACE_LABEL,
    productId, labelToReplace, replacementLabel, currentPage, cb
  }),
  changeProductUnitSaga = (productMutator, productId, unit, cb) => ({
    type: CHANGE_PRODUCT_UNIT,
    productMutator, productId, unit, cb
  }),
  changeProductTypeSaga = (productMutator, productId, newType, cb) => ({
    type: CHANGE_PRODUCT_TYPE,
    productMutator, productId, newType, cb
  }),
  createInventoryItemSaga = (productMutator, productId, parentAmount, name, amount, unit, isWaste, cb) => ({
    type: CREATE_INVENTORY_ITEM,
    productMutator, productId, parentAmount, name, amount, unit, isWaste, cb
  }),
  addInventoryItemSaga = (productMutator, productId, productAmount, inventoryItemId, inventoryItemAmount, cb) => ({
    type: ADD_INVENTORY_ITEM,
    productMutator, productId, productAmount, inventoryItemId, inventoryItemAmount, cb
  }),
  changeProductReferenceSaga = (productMutator, productId, referenceProduct, callback) => ({
    type: CHANGE_PRODUCT_REFERENCE,
    productMutator, productId, referenceProduct, callback
  }),
  changeProductCustomImpactsSaga = (customImpactData, cb) => ({
    type: CHANGE_PRODUCT_CUSTOM_IMPACTS,
    customImpactData, cb
  }),
  changeInventoryItemAmountSaga = (productMutator, productId, amount, targetId, targetAmount, cb) => ({
    type: CHANGE_INVENTORY_ITEM_AMOUNT,
    productMutator, productId, amount, targetId, targetAmount, cb
  }),
  removeProductReferenceSaga = (productMutator, productId, cb) => ({
    type: REMOVE_PRODUCT_REFERENCE,
    productMutator, productId, cb
  }),
  addProductPropertySaga = ({ productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback }) => ({
    type: ADD_PRODUCT_PROPERTY,
    productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback
  }),
  changeProductPropertySaga = ({ productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback }) => ({
    type: CHANGE_PRODUCT_PROPERTY,
    productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback
  }),
  removeProductPropertySaga = (productMutator, productId, referencePropertyId, cb) => ({
    type: REMOVE_PRODUCT_PROPERTY,
    productMutator, productId, referencePropertyId, cb
  }),
  getProductPropertyAmountSaga = (productID, inventoryID, propertyID, unitID, productToTransport, key) => ({
    type: GET_PROPERTY_AMOUNT,
    productID, inventoryID, propertyID, unitID, productToTransport, key
  }),
  createTransportActivitySaga = ({ spaceID, phaseID, name, description, products, start, legs, templateID }) => ({
    type: CREATE_TRANSPORT_ACTIVITY,
    spaceID, phaseID, name, description, products, start, legs, templateID
  }),
  getVehicleListSaga = spaceID => ({
    type: GET_VEHICLE_LIST,
    spaceID
  }),
  getActivityProductsSaga = phaseID => ({
    type: GET_ACTIVITY_PRODUCTS,
    phaseID
  }),
  createProductSaga = (name, spaceID, parentID, cb, labels) => ({
    type: CREATE_PRODUCT,
    name, spaceID, parentID, cb, labels
  }),
  createFolderSaga = (name, spaceID, parentID, labels, cb) => ({
    type: CREATE_FOLDER,
    name, spaceID, parentID, labels, cb
  }),
  renameFolderSaga = (newName, folderID, cb) => ({
    type: RENAME_FOLDER,
    newName, folderID, cb
  }),
  addProductToFavoritesSaga = productId => ({
    type: ADD_PRODUCT_TO_FAVORITES,
    productId
  }),
  removeProductFromFavoritesSaga  = productId => ({
    type: REMOVE_PRODUCT_FROM_FAVORITES,
    productId
  }),
  changeProductDescriptionSaga = (productId, productDescription, cb) => ({
    type: CHANGE_PRODUCT_DESCRIPTION,
    productId, productDescription, cb
  }),
  exportProductImpactsSaga = productId => ({
    type: EXPORT_PRODUCT_IMPACT,
    productId,
  }),
  getProductImportColumnsSaga = () => ({
    type: GET_PRODUCT_IMPORT_COLUMNS
  }),
  selectedInventoryItemForAddReferenceSaga = ({ key, phase }) => ({
    type: SELECTED_INVENTORY_ITEM_FOR_ADD_REFERENCE,
    key, phase
  }),
  showProductInDetailsPanelSaga = productId => ({
    type: SHOW_PRODUCT_IN_DETAILS_PANEL,
    productId
  }),
  selectedInventoryItemForChangeAmountSaga = ({ key, phase }) => ({
    type: SELECTED_INVENTORY_ITEM_FOR_CHANGE_AMOUNT,
    key, phase
  }),

  moveProductToFolderSaga = ({ productId, folderId }) => ({
    type: MOVE_PRODUCT_TO_FOLDER,
    productId, folderId
  }),
  moveFolderToFolderSaga = ({ folderId, toFolderId }) => ({
    type: MOVE_FOLDER_TO_FOLDER,
    folderId, toFolderId
  }),
  removeItemFolderSaga = ({ folderId, cb }) => ({
    type: REMOVE_ITEM_FOLDER,
    folderId, cb
  })
/**
 * @param {{type: string, productId: string}} action
 */
export function* fetchProductWithImpact(action) {
  try {
    const resp = yield call(resource.queryByParams, 'productWithImpact', { id: action.productId })
    yield put(setSelectedProductAction({ ...resp.product, impact: returnNested(resp, 'impact') }))
    yield put(setIsForcingWorkspacePanelAction(false))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productId: string, cb: function}} action
 */
function* removeProductFn(action) {
  try {
    const selectedProductId = yield select(SelectedProductSelector.productId)
    yield call(resource.mutateByParams, 'removeProduct', { productID: action.productId })
    yield put(removeProductAction(action.productId))
    if (action.productId === selectedProductId) yield put(setIsDetailsPanelOpenAction(false))
    yield put(addSuccessNotificationAction('model.successfullyRemoved'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productId: string, cb: function}} action
 */
function* copyProductFn(action) {
  try {
    const prodCopy =  yield call(resource.mutateByParams, 'copyProduct', { productID: action.productId })
    action.cb && action.cb(prodCopy)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* selectedInventoryItem(action) {
  yield call(selectedInventoryItemHandler, action.key, action.phase)
  yield put(setIsCreateNewInventoryItemAction(false))
  yield put(setIsCreatePhaseAction(false))
}

function* selectedInventoryItemHandler(key, phase, willDetailsPanelOpen = true) {
  yield put(setSelectedInventoryItemKeyAction(key))
  if (!key) return null
  const productId = InventoryTreeKey.getProductIdByInventoryKey(key)
  // yield put(setSelectedInventoryItemKeyAction(key))
  yield call(fetchProductWithImpact, { productId })
  yield put(setDetailsPanelLifecycleIdAction(null))
  yield put(setSelectedPhaseAction(phase))
  yield put(setIsCreateLifecycleAction(false))
  if (willDetailsPanelOpen) yield put(setIsDetailsPanelOpenAction(true))
}

function* selectedInventoryItemForCreateProduct(action) {
  yield call(selectedInventoryItemHandler, action.key, action.phase)
  yield put(setIsCreateNewInventoryItemAction(true))
  yield put(setIsCreatePhaseAction(false))
}

function* selectedInventoryItemForRemoveProduct(action) {
  try {
    yield call(selectedInventoryItemHandler, action.key)
    const productMutator = yield select(ProductMutationSelector.productMutator)
    const activeInventory = yield select(ProductMutationSelector.activeInventory)
    const productId = yield select(SelectedProductSelector.productId)
    const selectedNodeFromInventory = getInventoryNodeFromInventoryItemKey(activeInventory, action.key)
    const conversionId = returnNested(selectedNodeFromInventory, 'inventoryItem', 'conversionID')
    yield call(removeConversionItem, removeConversionItemSaga(
      productMutator,
      conversionId,
      productId,
      action.cb
    ))
    yield put(setIsDetailsPanelOpenAction(false))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* selectedFlatViewItem(action) {
  if (!action.productId) return null
  yield call(fetchProductWithImpact, { productId: action.productId })
  yield put(setDetailsPanelLifecycleIdAction(null))
  yield put(setSelectedPhaseAction(action.phaseId))
  yield put(setIsCreateNewInventoryItemAction(false))
  yield put(setIsCreateLifecycleAction(false))
  yield put(setIsCreatePhaseAction(false))
  yield put(setSelectedInventoryItemKeyAction(null))
  yield put(setIsDetailsPanelOpenAction(true))
}

function* possibleInventoryItems(action) {
  yield delay(SAGA.DEBOUNCE_MS)
  let possibleItems = null
  try {
    possibleItems = yield call(resource.cancellableQueryByParams, 'possibleInventoryItems',  { productID: action.productId })
    yield put(setPossibleInventoryItemsAction(possibleItems))
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    if (yield cancelled()) {
      // TODO MOB-3533 find a way to call cancel function of abortable graphql queries
      // possibleItems && possibleItems.cancel()
    }
  }
}

/**
 * @param {{type: string, spaceId: string}} action
 */
function* searchProductsHandler({
  pageSize = PAGINATION.PAGE_SIZE,
  spaceId: spaceID,
  noCategories,
  currentPage,
  noFavorite,
  folderId,
  sortBy,
  labels,
  cb,
}) {
  yield delay(SAGA.DEBOUNCE_MS)
  try {
    const folderID = folderId ? folderId : ''
    const [ productLabel ] = safeArray(labels)
    const productFilter = yield select(GlobalSelector.productFilter)
    const { query, productTags: tags, selectedCategories: categories } = productFilter[productLabel] || {}

    const commonArgs = { spaceID, query, sortBy, tags, categories, labels, folderID }
    const searchProductsArgs = {
      ...commonArgs, currentPage, pageSize,
      categories: noCategories ? [] : categories
    }
    const favoriteProductsArgs = {
      ...commonArgs
    }

    // MOB-3470 it is supposed to fetch results when user type in product name input, but it's called once when component is mounted
    const productQueryList = [ call(resource.cancellableQueryByParams, 'products', searchProductsArgs) ]
    if (!noFavorite) {
      productQueryList.push(call(resource.cancellableQueryByParams, 'favoriteProducts', favoriteProductsArgs))
    }
    const [ productList, favoriteList ] =  yield all(productQueryList)
    yield put(setSelectedSpaceProductsAction(productList))
    if (favoriteList) {
      yield put(setFavoriteProductsAction(favoriteList))
    }

  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    if (yield cancelled()) {
      // TODO MOB-3533 find a way to call cancel function of abortable graphql queries
      // productList && productList.cancel()
      // favoriteList && favoriteList.cancel()
    } else if (cb) {
      cb()
    }
  }
}

function* searchFoldersHandler({
  spaceId: spaceID,
  folderId,
  labels,
  cb,
}) {
  try {
    const folderID = folderId ? folderId : ''
    const searchFoldersArgs = {
      spaceID,
      labels,
      folderID
    }

    const resultList = yield call(resource.queryByParams, 'listItemFolders', searchFoldersArgs)
    yield put(setSelectedSpaceFoldersAction(resultList))

    cb && cb()

  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, name: string, [cb]: function}} action
 */
function* renameProduct(action) {
  try {
    const product = yield call(action.productMutator.callMutation, 'renameProduct', { productID: action.productId, name: action.name })
    yield put(renameProductAction(product.id, product.name))

    if (action.withProductListUpdate) {
      const { id: spaceId } = yield select(SpaceSelector.selectedSpace)
      const { currentPage, labels } = action
      yield put(searchProductsSaga({ spaceId, currentPage, labels }))
    }

    yield call(updateSelectedProduct, product)
    yield put(addSuccessNotificationAction('model.product_property_modified'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{productId: string, labelToReplace: string, replacementLabel: string, currentPage: number,  [cb]: function}} action
 */
function* replaceLabel({
  productId: productID,
  labelToReplace,
  replacementLabel,
  currentPage,
  cb = () => {}
}) {
  try {
    yield call(resource.mutateByParams, 'replaceLabel', { productID, labelToReplace, replacementLabel })

    const { id: spaceId } = yield select(SpaceSelector.selectedSpace) || {}
    yield put(searchProductsSaga({ spaceId, currentPage, labels: [ labelToReplace ] }))

    yield put(addSuccessNotificationAction('model.successfullyMoved'))
    cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, unit: string, [cb]: function}} action
 */
function* changeProductUnit(action) {
  yield call(
    productMutation,
    action.productMutator,
    'changeProductUnit',
    { productID: action.productId, unit: action.unit },
    'model.product_property_modified',
    action.cb
  )
}

/**
 * @param {{type: string, productMutator: {}, productId: string, newType: string, [cb]: function}} action
 */
function* changeProductType(action) {
  yield call(
    productMutation,
    action.productMutator,
    'changeProductType',
    { productID: action.productId, type: action.newType },
    'model.product_property_modified',
    action.cb
  )
}

/**
 * @param {{type: string, productMutator: {}, productId: string, amount: string, targetId: string, targetAmount: string, [cb]: function}} action
 */
function* changeInventoryItemAmount(action) {
  try {
    const changeInventoryItemAmountArgs = {
      inventoryItemID: action.productId,
      inventoryItemAmount: action.amount,
      targetID: action.targetId,
      targetAmount: action.targetAmount
    }
    if (!action.productId) return

    yield productMutation(
      action.productMutator,
      'changeInventoryItemAmount',
      changeInventoryItemAmountArgs,
      'model.product_general_info_amount_changed',
      action.cb
    )
    yield put(setSelectedInventoryItemKeyAction(null))
    yield put(setSelectedProductAction(null))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, parentAmount: string, name: string, amount: string, unit:string, isWaste: boolean, string [cb]: function}} action
 * Updating the selected key
 * @see ProductScenarioMutations.createInventoryItem
 */
function* createInventoryItem(action) {
  try {
    const createInventoryItemArgs = {
      productID: action.productId,
      productAmount: action.parentAmount,
      name: action.name,
      value: action.amount,
      unit: action.unit,
      isWaste: action.isWaste
    }
    yield call(action.productMutator.callMutation, 'createInventoryItem', createInventoryItemArgs)
    yield put(addSuccessNotificationAction('model.product_general_info_item_added'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, productAmount: string, inventoryItemId: string, inventoryItemAmount: string, [cb]: function}} action
 * Updating the selected key
 * @see ProductScenarioMutations.addInventoryItem
 */
function* addInventoryItem(action) {
  try {
    const callMutationArgs = {
      productID: action.productId,
      productAmount: action.productAmount,
      inventoryItemID: action.inventoryItemId,
      inventoryItemAmount: action.inventoryItemAmount
    }
    yield call(action.productMutator.callMutation, 'addInventoryItem', callMutationArgs )
    yield put(addSuccessNotificationAction('model.product_general_info_item_added'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, referencePropertyId: string, conversionFactor: string, referenceUnitId: string, [skipMessage]: boolean, [cb]: function}} action
 */
function* addProductProperty(action) {
  const { productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback } = action
  const addProductPropertyArgs = {
    productID: productId,
    referencePropertyID: propertyId,
    conversionFactor,
    referenceUnitID: propertyUnitId
  }
  yield productMutation(
    productMutator,
    'addProductProperty',
    addProductPropertyArgs,
    !skipMessage && 'model.product_property_modified',
    callback
  )
}

/**
 * @param {{type: string, productMutator: {}, productId: string, referencePropertyId: string, conversionFactor: string, referenceUnitId: string, [skipMessage]: boolean, [cb]: function}} action
 */
function* changeProductProperty(action) {
  const { productMutator, productId, propertyId, conversionFactor, propertyUnitId, skipMessage, callback } = action
  const changeProductPropertyArgs = {
    productID: productId,
    referencePropertyID: propertyId,
    conversionFactor,
    referenceUnitID: propertyUnitId
  }
  yield productMutation(
    productMutator,
    'changeProductProperty',
    changeProductPropertyArgs,
    !skipMessage && 'model.product_property_modified',
    callback
  )
}

/**
 * @param {{type: string, productMutator: {}, productId: string, referencePropertyId: string, cb: function}} action
 */
function* removeProductProperty(action) {

  yield productMutation(
    action.productMutator,
    'removeProductProperty',
    { productID: action.productId, referencePropertyID: action.referencePropertyId },
    'model.product_property_removed',
    action.cb
  )
}

/**
 * @param {{type: string, productMutator:{}, productId: string, referenceProduct: {}, cb: function}} action
 */
function* changeProductReference({
  productId: productID,
  productMutator,
  referenceProduct: {
    id: referenceProductID, amount,
    referenceProperty: { id: referencePropertyId } = {},
    referenceUnit: { id: referenceUnitId } = {}
  } = {},
  callback = () => {}
}) {

  try {
    const changeProductReferenceArgs = { productID, referenceProductID }
    const product = yield call(productMutator.callMutation, 'changeProductReference', changeProductReferenceArgs)
    const hasReferenceProperty = product.productProperties.some(({ referenceProperty: { id } }) => id === referencePropertyId)

    if (!hasReferenceProperty) {
      const referencePropertiesListFromSelector = yield select(referencePropertiesListSelector)
      if (!referencePropertiesListFromSelector || referencePropertiesListFromSelector.length === 0) {
        const referencePropertiesList = yield call(resource.queryByParams, 'referenceProperties')
        yield put(setReferencePropertiesListAction(referencePropertiesList))
      }

      const hasReferenceUnit = product.productProperties.some(({ referenceUnit: { id } }) => id === referenceUnitId)

      if (!hasReferenceUnit) {
        const addProductPropertyArgs = {
          productID: product.id,
          referencePropertyID: referencePropertyId,
          conversionFactor: floatToString(amount),
          referenceUnitID: referenceUnitId
        }
        yield call(resource.mutateByParams, 'addProductProperty', addProductPropertyArgs)
        yield put(addSuccessNotificationAction('search_reference_product.product_successfully_referred'))
      } else {
        yield put(addSuccessNotificationAction('search_reference_product.product_successfully_referred'))
      }
      callback()
      yield call(updateSelectedProduct, product)
      return
    }
    yield call(updateSelectedProduct, product)
    yield put(addSuccessNotificationAction('search_reference_product.product_successfully_referred'))
    callback()
  } catch (error) {
    callback(false)
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, spaceId: string, products: []}} action
 */
function* updateSpaceProductsHandler({ spaceId, labels }) {
  try {
    yield call(getSpace, getSpaceSaga(spaceId))
    yield put(searchProductsSaga({
      spaceId,
      labels,
      currentPage: 1
    }))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* productPropertyAmountHandler(action) {
  try {
    const productPropertyAmountArgs = {
      productID: action.productID,
      inventoryID: action.inventoryID,
      propertyID: action.propertyID,
      unitID: action.unitID
    }
    const amount = yield call(resource.queryByParams, 'productPropertyAmount', productPropertyAmountArgs)
    yield put(addProductToListOfProductsForTransportation({
      ...action.productToTransport,
      transportWeightAmount: amount,
      key: action.key
    }))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* getVehicleListHandler(action) {
  try {
    const vehicleList = yield call(resource.queryByParams, 'transportProducts', { spaceID: action.spaceID })
    yield put(setVehicleList(vehicleList))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* createTransportActivityHandler(action) {
  try {
    const createTransportActivityArgs = {
      spaceID: action.spaceID,
      phaseID: action.phaseID,
      templateID: action.templateID,
      name: action.name,
      description: action.description,
      products: action.products,
      start: action.start,
      legs: action.legs
    }

    yield call(resource.mutateByParams, 'createTransportActivity', createTransportActivityArgs)
    yield call(updateLifecyclesItems, updateLifecyclesItemsSaga())
    yield put(addSuccessNotificationAction('global.transport_template_created'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, productMutator: {}, productId: string, cb: function}} action
 */
function* removeProductReference(action) {
  yield productMutation(
    action.productMutator,
    'removeProductReference',
    { productID: action.productId },
    'model.remove_impact_success',
    action.cb
  )
}

function* getActivityProductsHandler(action) {
  try {
    let activityProductsList = yield call(resource.queryByParams, 'activityProducts', { phaseID: action.phaseID })
    activityProductsList = activityProductsList.map(el => {
      el.key = `${el.phase.id}_productId_${el.product.id}`
      return el
    })
    yield put(setActivityProductsListAction(activityProductsList))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* createFolderHandler({ name, spaceID, parentID, labels, cb }) {
  try {
    const createProductArgs = { name, spaceID, parentID, labels }
    const createdProduct = yield call(resource.mutateByParams, 'createItemFolder', createProductArgs)
    yield put(setIsCreateFolderDialogShowedAction(false))
    yield put(addSuccessNotificationAction('model.folder_successfully_created'))
    cb && cb(createdProduct.id)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* renameFolderHandler({ newName, folderID, cb }) {
  try {
    yield call(resource.mutateByParams, 'renameItemFolder', { newName, folderID })
    yield put(addSuccessNotificationAction('model.folder_successfully_renamed'))
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* createProductHandler({ name, spaceID, parentID, cb, labels }) {
  try {
    const createProductArgs = { name, spaceID, folderID: parentID, labels }
    const createdProduct = yield call(resource.mutateByParams, 'createProduct', createProductArgs)
    const productWithImpact = { ...createdProduct, impact: { amount: '0', unit: 'kg CO2 eq' } }

    yield put(setSelectedProductAction(productWithImpact))
    yield put(setIsDetailsPanelOpenAction(true))
    yield put(setIsCreateProductDialogShowedAction(false))
    yield put(addSuccessNotificationAction('model.product_successfully_created'))
    cb && cb(createdProduct.id)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* addProductToFavorites(action) {
  try {
    const changeProductFavoriteStateArgs = { productID: action.productId, favorite: true }
    const product = yield call(resource.mutateByParams, 'changeProductFavoriteState', changeProductFavoriteStateArgs)
    yield put(addProductToFavoritesAction(product))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* removeProductFromFavorites(action) {
  try {
    const changeProductFavoriteStateArgs = { productID: action.productId, favorite: false }
    yield call(resource.mutateByParams, 'changeProductFavoriteState', changeProductFavoriteStateArgs)
    yield put(removeProductFromFavoritesAction(action.productId))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* moveProductToFolder(action) {
  const { productId, folderId, cb = () => {} } = action || {}
  try {
    const moveItemArgs = { productID: productId, newFolderID: folderId }
    yield call(resource.mutateByParams, 'moveItem', moveItemArgs)
    yield put(addSuccessNotificationAction('model.successfullyMoved'))
    cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* moveFolderToFolder(action) {
  const { folderId, toFolderId, cb = () => {} } = action || {}
  try {
    const moveItemFolderArgs = { folderID: folderId, toFolderID: toFolderId }
    yield call(resource.mutateByParams, 'moveItemFolder', moveItemFolderArgs)
    yield put(addSuccessNotificationAction('model.successfullyMoved'))
    cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* removeItemFolder(action) {
  const { folderId, cb = () => {}  } = action || {}
  try {
    yield call(resource.mutateByParams, 'removeItemFolder', { folderID: folderId })
    yield put(addSuccessNotificationAction('model.successfullyRemovedFolder'))
    cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* changeProductDescriptionHandler(action) {
  try {
    const changeProductDescriptionArgs = { productID: action.productId, description: action.productDescription }
    yield call(resource.mutateByParams, 'changeProductDescription',  changeProductDescriptionArgs)
    yield put(changeProductDescriptionAction(action.productId, action.productDescription))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* exportProductImpactHandler(action) {
  try {
    const requestProductExportArgs = { productID: action.productId, timezone: browserTimezone() }
    yield call(resource.mutateByParams, 'requestProductExport', requestProductExportArgs)
    yield put(addSuccessNotificationAction('model.export_impact_successfully_requested'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* getProductImportColumnsHandler() {
  try {
    const columns = yield call(resource.queryByParams, 'productImportColumns')
    yield put(setProductImportColumnsAction(columns))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* createTagHandler(action) {
  try {
    const createTagArgs = {
      name: action.name,
      color: action.color,
      spaceID: action.spaceID,
      productID: action.productID
    }
    const createdTag = yield call(resource.mutateByParams, 'createTag', createTagArgs)
    yield put(addTagToSelectedProductAction(createdTag))
    yield put(setSpaceProductTagListSaga({ spaceID: action.spaceID }))
    action.cb && action.cb()
    yield put(addSuccessNotificationAction('model.tag_successfuly_created'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* tagsSearchHandler(action) {
  yield delay(SAGA.DEBOUNCE_MS)
  let tagsList = null
  try {
    // MOB-3471 the tags filter based on this saga doesn't show the results when user start typing in tags search input
    tagsList = yield call(resource.cancellableQueryByParams, 'tags',
      { spaceID: action.spaceID, query: action.query })
    action.cb && action.cb(tagsList)
  } catch (e) {
    yield call(dispatchErrors, e)
  } finally {
    if (yield cancelled()) {
      // TODO MOB-3533 find a way to call cancel function of abortable graphql queries
      // tagsList && tagsList.cancel()
    }
  }
}

function* addTagToProductHandler(action) {
  try {
    const product = yield call(resource.mutateByParams, 'addTagToProduct',
      { tagID: action.tagID, productID: action.productID })
    yield put(addTagsToSelectedProductAction(product.tags))
    action.cb && action.cb()
    yield put(addSuccessNotificationAction('model.tag_successfuly_added'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeTagFromProductHandler(action) {
  try {
    yield call(resource.mutateByParams, 'removeTagFromProduct',
      { tagID: action.tagID, productID: action.productID })
    yield put(removeTagFromSelectedProductAction(action.tagID))
    action.cb && action.cb()
    yield put(addSuccessNotificationAction('model.tag_successfuly_removed_from_product'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* setSpaceProductTagListHandler(action) {
  try {
    const tagsList = yield call(resource.queryByParams, 'tags', { spaceID: action.spaceID, query: '' })
    yield put(setSpaceProductTagListAction(tagsList))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* getModulesHandler() {
  try {
    const modules = yield call(resource.queryByParams, 'modules')
    yield put(setModulesAction(modules))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* assignModuleToProductHandler(action) {
  try {
    yield call(resource.mutateByParams, 'assignModuleToProduct',
      { productID: action.productID, module: action.module })
    yield put(setModuleToProductAction({ code: action.module }))
    yield put(addSuccessNotificationAction('model.module_successfuly_changed'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* changeProductCustomImpactsHandler(action) {
  const changeProductCustomImpactsArgs = {
    productID: action.customImpactData.productId,
    impactMethodID: action.customImpactData.impactMethodId,
    impacts: action.customImpactData.impacts,
    excludeLT: action.customImpactData.excludeLT
  }
  try {
    yield call(action.customImpactData.productMutator.callMutation, 'changeProductCustomImpacts', changeProductCustomImpactsArgs)
    yield put(removeProductReferenceAction())
    yield put(addSuccessNotificationAction('model.impacts_successfuly_changed'))
    action.cb && action.cb()
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* selectedInventoryItemForAddReference(action) {
  yield call(selectedInventoryItemHandler, action.key, action.phase, false)
  yield put(setIsDetailsPanelOpenAction(false))
  yield put(setIsImpactSelectorDialogShowedAction(true))
}

function* selectedInventoryItemForChangeAmount(action) {
  yield call(selectedInventoryItemHandler, action.key, action.phase, false)
  yield put(setIsDetailsPanelOpenAction(false))
}

function* showProductInDetailsPanel(action) {
  yield call(fetchProductWithImpact, { productId: action.productId })
  yield put(setDetailsPanelLifecycleIdAction(null))
  yield put(setIsCreateLifecycleAction(false))
  yield put(setIsDetailsPanelOpenAction(true))
}

export default function* productSaga() {
  yield takeLatest(GET_PRODUCT_WITH_IMPACT, fetchProductWithImpact)
  yield takeLatest(REMOVE_PRODUCT, removeProductFn)
  yield takeLatest(COPY_PRODUCT, copyProductFn)
  yield takeLatest(SELECTED_INVENTORY_ITEM, selectedInventoryItem)
  yield takeLatest(SELECTED_INVENTORY_ITEM_FOR_CREATE_PRODUCT, selectedInventoryItemForCreateProduct)
  yield takeLatest(SELECTED_INVENTORY_ITEM_FOR_REMOVE_PRODUCT, selectedInventoryItemForRemoveProduct)
  yield takeLatest(SELECTED_FLATVIEW_ITEM, selectedFlatViewItem)
  yield takeEvery(GET_POSSIBLE_INVENTORY_ITEMS, possibleInventoryItems)
  yield takeLatest(SEARCH_PRODUCTS, searchProductsHandler)
  yield takeLatest(RENAME_PRODUCT, renameProduct)
  yield takeLatest(REPLACE_LABEL, replaceLabel)
  yield takeLatest(CHANGE_PRODUCT_UNIT, changeProductUnit)
  yield takeLatest(CHANGE_PRODUCT_TYPE, changeProductType)
  yield takeLatest(CHANGE_INVENTORY_ITEM_AMOUNT, changeInventoryItemAmount)
  yield takeLatest(CREATE_INVENTORY_ITEM, createInventoryItem)
  yield takeLatest(ADD_INVENTORY_ITEM, addInventoryItem)
  yield takeLatest(CHANGE_PRODUCT_REFERENCE, changeProductReference)
  yield takeLatest(REMOVE_PRODUCT_REFERENCE, removeProductReference)
  yield takeLatest(ADD_PRODUCT_PROPERTY, addProductProperty)
  yield takeLatest(CHANGE_PRODUCT_PROPERTY, changeProductProperty)
  yield takeLatest(REMOVE_PRODUCT_PROPERTY, removeProductProperty)
  yield takeLatest(UPDATE_SPACE_PRODUCTS, updateSpaceProductsHandler)
  yield takeLatest(GET_PROPERTY_AMOUNT, productPropertyAmountHandler)
  yield takeLatest(GET_VEHICLE_LIST, getVehicleListHandler)
  yield takeLatest(CREATE_TRANSPORT_ACTIVITY, createTransportActivityHandler)
  yield takeLatest(GET_ACTIVITY_PRODUCTS, getActivityProductsHandler)
  yield takeLatest(CREATE_PRODUCT, createProductHandler)
  yield takeLatest(CREATE_FOLDER, createFolderHandler)
  yield takeLatest(RENAME_FOLDER, renameFolderHandler)
  yield takeLatest(ADD_PRODUCT_TO_FAVORITES, addProductToFavorites)
  yield takeLatest(REMOVE_PRODUCT_FROM_FAVORITES, removeProductFromFavorites)
  yield takeLatest(CHANGE_PRODUCT_DESCRIPTION, changeProductDescriptionHandler)
  yield takeLatest(EXPORT_PRODUCT_IMPACT, exportProductImpactHandler)
  yield takeLatest(GET_PRODUCT_IMPORT_COLUMNS, getProductImportColumnsHandler)
  yield takeLatest(CHANGE_PRODUCT_CUSTOM_IMPACTS, changeProductCustomImpactsHandler)
  yield takeLatest(CREATE_TAG, createTagHandler)
  yield takeLatest(TAGS_SEARCH, tagsSearchHandler)
  yield takeLatest(SET_SPACE_PRODUCT_TAG_LIST, setSpaceProductTagListHandler)
  yield takeLatest(ADD_TAG_TO_PRODUCT, addTagToProductHandler)
  yield takeLatest(REMOVE_TAG_FROM_PRODUCT, removeTagFromProductHandler)
  yield takeLatest(GET_MODULES, getModulesHandler)
  yield takeLatest(ASSIGN_MODULE_TO_PRODUCT, assignModuleToProductHandler)
  yield takeLatest(SELECTED_INVENTORY_ITEM_FOR_ADD_REFERENCE, selectedInventoryItemForAddReference)
  yield takeLatest(SHOW_PRODUCT_IN_DETAILS_PANEL, showProductInDetailsPanel)
  yield takeLatest(SELECTED_INVENTORY_ITEM_FOR_CHANGE_AMOUNT, selectedInventoryItemForChangeAmount)
  yield takeLatest(SEARCH_FOLDERS, searchFoldersHandler)
  yield takeLatest(MOVE_PRODUCT_TO_FOLDER, moveProductToFolder)
  yield takeLatest(MOVE_FOLDER_TO_FOLDER, moveFolderToFolder)
  yield takeLatest(REMOVE_ITEM_FOLDER, removeItemFolder)
}
