import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { IAccount, ISpace } from '../../model'
import { SPACE_BY_SLUG } from '../../graphql/query'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'

export interface IWorkspaceResult {
  loading?: boolean
  workspace?: ISpace | null
  refetchWorkspace?: Function
  emptyWorkspace?: boolean
}

export const useWorkspaceQuery = (account: IAccount | undefined, selectedWorkspaceSlug: string): IWorkspaceResult => {
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const skip = !account?.id || account?.spaces?.length === 0 || !selectedWorkspaceSlug
  const { loading, data: workspaceData, refetch: refetchWorkspace } = useQuery(SPACE_BY_SLUG, {
    skip,
    variables: {
      slug: selectedWorkspaceSlug,
      accountID: account?.id || ''
    },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (loading && !!refetchWorkspace) {
      updateDashboard({ refetchWorkspace })
    }
  }, [ loading, refetchWorkspace ])

  if (account && account?.spaces.length > 0) {
    const { spaceBySlug } = workspaceData || {}
    return { loading, workspace: spaceBySlug as ISpace || null, refetchWorkspace }
  } if (!account?.id || account?.spaces.length === 0) {
    return { loading: false, emptyWorkspace: true }
  }

  return { loading: true }
}
