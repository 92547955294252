import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import compose from '../../../../utils/compose'
import { safeArray, normalize } from '../../../../utils/tools'
import CropLongText from '../../../helpers/cropLongText.container'

const Option = Select.Option

const ScenarioList = ({
  label,
  value,
  scenarioList,
  onChangeHandler,
  createHandler,
  t
}) => {

  const [ isCreateScenarioDisabled, setIsCreateScenarioDisabled ] = useState(false)

  useEffect(() => {
    setIsCreateScenarioDisabled(false)
  }, [ scenarioList ])

  const getScenarioOptions = scenarioList => {
    return safeArray(scenarioList).map((el, index) => <Option
      key={ index }
      value={ el.id }
      data-cy={`scenario-named-${normalize(el.name)}`}
    >
      <CropLongText tooltipText={ el.name }>
        { el.name }
      </CropLongText>
    </Option>)
  }

  const createNewScenario = () => {
    setIsCreateScenarioDisabled(true)
    createHandler()
  }

  return <React.Fragment>
    <label>{ label }</label>
    <Select
      className="scenario-search-box"
      data-cy='scenario-product-select'
      allowClear={ true }
      dropdownClassName={ 'scenario-search-box-dropdown' }
      value={ value || undefined }
      placeholder={ t('model.product_scenario_placeholder') }
      defaultActiveFirstOption={ false }
      dropdownMatchSelectWidth={ false }
      onChange={ onChangeHandler }
      getPopupContainer={ node => node.parentElement }
      dropdownRender={ options =>
        <div>
          { options }
          <Divider/>
          <Button
            disabled={isCreateScenarioDisabled}
            onMouseDown={createNewScenario}
            data-cy='create-new-scenario'
            className="button transparent"
            icon={ <PlusOutlined/> }
          >
            { t('global.create') }
          </Button>
        </div>
      }
    >{ getScenarioOptions(scenarioList) }</Select>
  </React.Fragment>
}

export default compose(
  withTranslation()
)(ScenarioList)

