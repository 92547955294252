import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis, faPenToSquare, faFolder, faTrash
} from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import ProductContext from '../../provider/context/product.context'
import { MenuAction } from '../../enum/menu-action'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'

const CardMenuContainerWrapper = styled.div`
  .p-button-outlined.p-button-plain {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--gray-300);
    box-shadow: none !important;
  }
`

export type CardMenuContainerProps = {
  id: string,
  name: string,
  isMouseOver: boolean
}
export const MenuFolderContainer = ({ id, name, isMouseOver }: CardMenuContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])

  const toast = useRef<Toast>(null)
  const { updateProduct } = useContext(ProductContext)
  const menu = useRef<Menu>(null)

  const onMenuClick = (event: any) => {
    event.stopPropagation()
    menu.current?.toggle(event)
  }

  const executeCommand = (event: any) => {
    event.originalEvent.stopPropagation()
    const { action: selectedAction } = event.item
    const selectedEntity = { id, name }
    switch (selectedAction) {
    case MenuAction.MoveFolderToFolder:
      updateProduct({ showMoveToFolderDialog: true, selectedEntity, selectedAction })
      break
    case MenuAction.EditFolder:
      updateProduct({ showCreateFolderDialog: true, selectedEntity, selectedAction })
      break
    case MenuAction.DeleteFolder:
      updateProduct({ showDeleteProductDialog: true, selectedEntity, selectedAction })
      break
    default:
      break
    }
  }

  const items = [
    {
      action: MenuAction.MoveFolderToFolder,
      label: t('actions.moveTo', { context: 'folder', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faFolder} className="text-sm mr-1" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.EditFolder,
      label: 'Edit name',
      icon: <FontAwesomeIcon icon={faPenToSquare} className="text-sm mr-1" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.DeleteFolder,
      label: t('actions.delete', { context: 'folder', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faTrash} className="text-sm mr-1" />,
      textColor: '--red-500',
      command: executeCommand,
      template: MenuItemComponent
    }
  ]

  return (
    <>
      <CardMenuContainerWrapper className="flex flex-none w-full gap-3 justify-content-end">
        <Menu model={items} popup ref={menu} id="popup_menu" className="mt-1" />

        <Button
          visible={isMouseOver}
          icon={<FontAwesomeIcon icon={faEllipsis} className="text-lg" />}
          data-testid="card-menu-more"
          data-cy="card-menu-button"
          onClick={onMenuClick}
          className="p-button-outlined p-button-plain p-button-sm p-0 border-none text-gray-200"
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Toast data-testid="card-menu-status" ref={toast} position="top-right" />
      </CardMenuContainerWrapper>
    </>
  )
}
