import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

export const TreeHeaderComponent = () => {
  const { t } = useTranslation([ 'product' ])
  const { totalImpact = null, showInventoryHeader } = useContext<TProductDetailContext>(ProductDetailContext)
  return (
    <>
      { showInventoryHeader && (
        <div data-testid="tree-header-component" className="flex w-full gap-4 pb-2">
          <div className="flex-grow-1 text-base font-medium text-gray-300">
            { t('labels.headerTree', { context: 'inventory' }) }
          </div>
          <div className="flex-none text-base font-medium text-gray-300">
            { t('labels.headerTree', { context: 'impact', unit: totalImpact?.unit }) }
          </div>
          <div className="flex-none w-10rem h-full"></div>
        </div>
      ) }
    </>
  )
}
