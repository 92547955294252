import React, { useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import { useTranslation } from 'react-i18next'
import { MenuAction } from '../../enum/menu-action'
import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { IControllerRender } from '../../../shared/interface/react-form-hook'

type CreateFolderComponentProps = {
  folderCreating?: boolean,
  showCreateFolderDialog: boolean,
  closeCreateFolderDialog: () => void,
  handleCreateFolder: (param: string) => void,
  handleRenameFolder: (param: string) => void
}
export const CreateFolderComponent = ({
  folderCreating = false,
  showCreateFolderDialog,
  closeCreateFolderDialog,
  handleCreateFolder,
  handleRenameFolder
}: CreateFolderComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const { selectedEntity, selectedAction } = useContext<TProductContext>(ProductContext)
  const isEdit = (selectedAction === MenuAction.EditFolder)
  const defaultValues = { name: selectedEntity?.name }
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })

  useEffect(() => {
    reset()
  }, [ showCreateFolderDialog, selectedEntity ])

  const onSubmit = (data: any) => {
    const { name } = data
    if (selectedAction === MenuAction.EditFolder) {
      handleRenameFolder(name)
    } else {
      handleCreateFolder(name)
    }
  }

  const onHide = () => {
    reset()
    closeCreateFolderDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="create-folder-dialog"
      header={isEdit
        ? t('actions.headerRename', { context: 'folder' })
        : t('actions.headerCreateNew', { context: 'folder' })}
      visible={showCreateFolderDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 p-0">
            <label htmlFor="name" className="text-sm">
              { t('form.placeholderCreate', { context: 'folder' }) }
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: true, minLength: 2 }}
              render={({ field, fieldState }: IControllerRender) => (
                <InputText
                  id={field.name}
                  {...field}
                  autoFocus
                  data-testid="new-folder-name"
                  data-cy="add-new-folder-input"
                  placeholder={t('form.placeholderCreate', { context: 'folder' })}
                  className={classNames('w-full', { 'p-invalid': fieldState.error })}
                />
              )}
            />
            {
              errors?.name
              && <small className="p-error"></small>
            }
          </div>

          <div className="col-12 pt-6 px-0 flex justify-content-end">
            <div className="flex-none">
              <Button
                type="submit"
                data-testid="submit-new-folder"
                data-cy="add-folder-submit"
                label={isEdit ? 'Rename' : t('labels.create')}
                iconPos="left"
                loading={folderCreating}
                className="p-button-primary justify-content-end"
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  )
}
