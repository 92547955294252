import { connect } from 'react-redux'
import AccountSelector from '../../../../redux/selectors/account.selector'
import SpaceSelector from '../../../../redux/selectors/space.selector'
import { setQueryOfProductFilterAction } from '../../../../redux/actions/global.actions'
import {
  removeLifecycleItemSaga,
  renameLifecycleItemSaga,
  setLifecycleDescriptionSaga,
  setLifecycleAmountSaga,
  setLifecycleUnitSaga,
  changeLifecycleProductSaga
} from '../../../../redux/sagas/lifecycle.saga'
import { searchProductsSaga } from '../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { returnNested } from '../../../../utils/tools'
import ChangeLifecycle from './changeLifecycle'

const mapStateToProps = (state, ownProps) => {
  const lifecycle = returnNested(ownProps, 'lifecycle')
  return {
    accountId: AccountSelector.selectedAccountId(state),
    lifecycle,
    workspaceSlug: SpaceSelector.spaceSlug(state),
    lifecycleName: returnNested(lifecycle, 'name'),
    lifecycleDescription: returnNested(lifecycle, 'description'),
    lifecycleAmount: returnNested(lifecycle, 'amount'),
    lifecycleUnit: returnNested(lifecycle, 'unit'),
    isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
    selectedSpaceId: SpaceSelector.spaceId(state),
    selectedSpaceProducts: SpaceSelector.spaceProducts(state),
  }
}

const mapDispatchToProps = {
  removeLifecycleItemSaga,
  renameLifecycleItemSaga,
  setLifecycleDescriptionSaga,
  setLifecycleAmountSaga,
  setLifecycleUnitSaga,
  searchProductsSaga,
  changeLifecycleProductSaga,
  setQueryOfProductFilterAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeLifecycle)
