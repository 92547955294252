import { returnNested } from '../../utils/tools'

const root = state => returnNested(state, 'flags')
const isSkipPhases = state => returnNested(root(state), 'isSkipPhases')
const isDetailsPanelOpen = state => returnNested(root(state), 'isDetailsPanelOpen')
const isShowProductScenarioInventory = state => returnNested(root(state), 'isShowProductScenarioInventory')
const isShowLifecycleScenarioInventory = state => returnNested(root(state), 'isShowLifecycleScenarioInventory')
const isShowScenarioInventory = state => isShowProductScenarioInventory(state) || isShowLifecycleScenarioInventory(state)
const isCreateLifecycle = state => returnNested(root(state), 'isCreateLifecycle')
const isCreatePhase = state => returnNested(root(state), 'isCreatePhase')
const isCreateNewInventoryItem = state => returnNested(root(state), 'isCreateNewInventoryItem')
const isShowInventory = state => returnNested(root(state), 'isShowInventory')
const isDataViaNetworkRequesting = state => returnNested(root(state), 'isDataViaNetworkRequesting')
const isProductFiltersInSearchMode = state => returnNested(root(state), 'isProductFiltersInSearchMode')
const isForcingWorkspacePanel = state => returnNested(root(state), 'isForcingWorkspacePanel')
const isOrderingPhases = state => returnNested(root(state), 'isOrderingPhases')
const isInventoryCompare = state => returnNested(root(state), 'isInventoryCompare')
const isSidebarOpen = state => returnNested(root(state), 'isSidebarOpen')
const isDetailPanelReadonlyV1 = state => returnNested(root(state), 'isDetailPanelReadonlyV1')
const isAdBlockEnabled = state => returnNested(root(state), 'isAdBlockEnabled')
const isShowAdBlockPopup = state => returnNested(root(state), 'isShowAdBlockPopup')
const isShowCreateProductDialog = state => returnNested(root(state), 'isShowCreateProductDialog')
const isShowCreateFolderDialog = state => returnNested(root(state), 'isShowCreateFolderDialog')
const isCreateLifecycleDialogShowed = state => returnNested(root(state), 'isCreateLifecycleDialogShowed')
const isSearchReferenceModalShowed = state => returnNested(root(state), 'isSearchReferenceModalShowed')
const isMoveItemDialogShowed = state => returnNested(root(state), 'isMoveItemDialogShowed')
const isLoadingSettingCompleted = state => returnNested(root(state), 'isLoadingSettingCompleted')

const FlagsSelector = {
  isSkipPhases,
  isDetailsPanelOpen,
  isShowProductScenarioInventory,
  isShowLifecycleScenarioInventory,
  isShowScenarioInventory,
  isCreateLifecycle,
  isCreatePhase,
  isCreateNewInventoryItem,
  isShowInventory,
  isDataViaNetworkRequesting,
  isProductFiltersInSearchMode,
  isForcingWorkspacePanel,
  isOrderingPhases,
  isInventoryCompare,
  isSidebarOpen,
  isDetailPanelReadonlyV1,
  isAdBlockEnabled,
  isShowAdBlockPopup,
  isShowCreateProductDialog,
  isShowCreateFolderDialog,
  isCreateLifecycleDialogShowed,
  isSearchReferenceModalShowed,
  isMoveItemDialogShowed,
  isLoadingSettingCompleted
}

export default FlagsSelector
