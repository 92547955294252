export const
  SET_INVENTORY_EXPANDED_KEYS = 'SET_INVENTORY_EXPANDED_KEYS',
  EXPAND_INVENTORY_NODE       = 'EXPAND_INVENTORY_NODE',
  COLLAPSE_INVENTORY_NODE     = 'COLLAPSE_INVENTORY_NODE'

export const setInventoryExpandedKeysAction = (inventoryRootId, inventoryTreeKeysMap) => ({
  type: SET_INVENTORY_EXPANDED_KEYS,
  inventoryRootId,
  inventoryTreeKeysMap
})

export const expandInventoryNodeAction = (inventoryRootId, inventoryTreeKey) => ({
  type: EXPAND_INVENTORY_NODE,
  inventoryRootId,
  inventoryTreeKey
})

export const collapseInventoryNodeAction = (inventoryRootId, inventoryTreeKey) => ({
  type: COLLAPSE_INVENTORY_NODE,
  inventoryRootId,
  inventoryTreeKey
})
