import React from 'react'
import { Upload } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SimpleCardTemplateComponent } from '../../general/simple-card-template.component'
import { LinkBoxComponent } from '../../general/link-box.component'

const ImportUploadCsvWrapper = styled.div`
  .import-file-uploader,
  .ant-upload {
    display: block;
    width: 100%;
    height: 100%;
  }
`

type ImportUploadCsvComponentProps = {
  fileUploadProps: any,
  fileUploading: boolean
}

export const ImportUploadCsvComponent = ({
  fileUploadProps,
  fileUploading
}: ImportUploadCsvComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const getUploadCardHeader = () => <span className="text-sm font-semibold p-2">{t('actions.upload_yourCsv')}</span>

  const getUploadCardBody = () => (
    <div className="w-full h-full p-2 pt-0">
      <LinkBoxComponent
        dataTestId="upload-import-link"
        loading={fileUploading}
        className="border-1 border-round-lg border-dashed p-4 py-3"
        icon={(<i className="pi pi-upload"></i>)}
        iconClassName="border-circle bg-white text-gray-500 px-2 py-1"
        title={t('actions.upload_file')}
        subtitle={t('labels.csvFile')}
      />
    </div>
  )

  return (
    <ImportUploadCsvWrapper className="w-full h-full">
      <Upload data-testid="import-file-uploader" className="import-file-uploader" {...fileUploadProps}>
        <SimpleCardTemplateComponent className="bg-gray-50 border-round-xl p-2" header={getUploadCardHeader()}>
          { getUploadCardBody() }
        </SimpleCardTemplateComponent>
      </Upload>
    </ImportUploadCsvWrapper>
  )
}
