import { gql } from '../../../__generated__'

export const CHANGE_SPACE_IMPACT_SETTINGS = gql(`
  mutation changeSpaceImpactSettings($id: ID!, $impactMethodID: ID!, $impactCategoryID: ID, $nwSetID: ID, $nwSetType: Int, $excludeLT: Boolean!, $useMethodTotal: Boolean!) {
    changeSpaceImpactSettings(id: $id, impactMethodID: $impactMethodID, impactCategoryID: $impactCategoryID, nwSetID: $nwSetID, nwSetType: $nwSetType, excludeLT: $excludeLT, useMethodTotal: $useMethodTotal) {
      id
      name
      impactMethod{
        id
        name
        description
      }
      nwSet{
        id
        type
        name
        unit
      }
      impactCategory{
        id
        impactMethodID
        name
        unit
      }
      excludeLT
      useMethodTotal
    }
  }
`)

export const CHANGE_PRODUCT_CUSTOM_IMPACTS = gql(`
  mutation changeProductCustomImpacts($productID: ID!, $impactMethodID: ID!, $impacts: [CustomImpact!]!, $excludeLT: Boolean!) {
    changeProductCustomImpacts(productID: $productID, impactMethodID: $impactMethodID, impacts: $impacts, excludeLT: $excludeLT){
      id
      name
    }
  }
`)
