import { call, put, takeLatest } from 'redux-saga/effects'
import resource from '../../utils/resource/resource'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import { dispatchErrors } from './helpers'

export const ACCOUNT_COUPON = 'ACCOUNT_COUPON_SAGA'

export const accountCouponSaga = (accountID, code, cb) => ({
  type: ACCOUNT_COUPON,
  accountID, code, cb
})

/**
 * @param {{accountID: string, code: string, cb: function}} action
 */
function* applyCoupon(action) {
  try {
    const discount = yield call(resource.mutateByParams, 'applyCouponCode', { accountID: action.accountID, code: action.code })
    yield call(action.cb, discount)
    yield put(addSuccessNotificationAction('account_settings.discount_coupon_applied'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

const accountCoupon = function* () {
  yield takeLatest(ACCOUNT_COUPON, applyCoupon)
}

export default accountCoupon
