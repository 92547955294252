import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'

const CustomImpactFormInfo = props => {
  const { selectedExcludeLT, selectedImpactMethod, t } = props
  return <React.Fragment>
    <div className="custom-impacts__info">{t('model.impact_method')}: <b>{ selectedImpactMethod.name }</b></div>
    <div className="custom-impacts__info">{t('model.exclude_lt_impacts')}: <b>{ selectedExcludeLT ? `${t('global.yes')}` : `${t('global.no')}`}</b></div>
  </React.Fragment>
}

export default compose(
  withTranslation()
)(CustomImpactFormInfo)
