import { connect } from 'react-redux'
import WorkspaceUserPermissions from './workspaceUserPermissions'
import {
  setSelectedSpaceAction,
} from '../../../../../redux/actions/global.actions'
import SpaceSelector from '../../../../../redux/selectors/space.selector'
import AccountSelector from '../../../../../redux/selectors/account.selector'
import { setAccountUsersAndRolesSaga } from '../../../../../redux/sagas/account.saga'

const mapStateToProps = state => ({
  accountId: AccountSelector.selectedAccountId(state),
  accountUsersList: AccountSelector.accountUsersList(state),
  accountRolesList: AccountSelector.accountRolesList(state),
  accountApiClientsList: AccountSelector.accountApiClientsList(state),
  spaceTypePoliciesList: SpaceSelector.spaceTypePoliciesList(state),
  selectedSpacePolicies: SpaceSelector.policies(state)
})
const mapDispatchToProps = {
  setSelectedSpaceAction,
  setAccountUsersAndRolesSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceUserPermissions)
