import React, { useContext, useEffect } from 'react'

import { ImpactDatasetLayout } from './layout/impact-dataset.layout'
import { ImpactDatasetProvider } from './provider/impact-dataset.provider'
import { IDashboardContext } from '../shared/interface/workspace-context-type'
import DashboardContext from '../dashboard/context/dashboard.context'
import { useIsMounted } from '../shared/hook/use-is-mounted'

import { Frame } from '../../component/layout'
import DetailsPanel from '../../component/model/details-panel/detailsPanel.container'
import WorkspaceDetails from '../../component/model/details-panel/workspace-details/workspaceDetails.container'

import { store } from '../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'

export const ImpactDatasetEntry = () => {
  const isMounted = useIsMounted()
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  const componentIsMounted = isMounted()

  useEffect(() => {
    if (componentIsMounted) {
      updateDashboard({ collapsedNavigation: true })
      closeOpenedDetailPanel()
    }
    return closeOpenedDetailPanel
  }, [ componentIsMounted ])

  const closeOpenedDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    store.dispatch(clearUiWhenDetailPanelCloseAction())
    store.dispatch(setIsDetailsPanelOpenAction(false))
  }

  return (
    <Frame>
      { () => (
        <ImpactDatasetProvider>
          <ImpactDatasetLayout />

          {/* // TODO : From v0.9 : To be refactored */}
          <DetailsPanel>
            <WorkspaceDetails />
          </DetailsPanel>
        </ImpactDatasetProvider>
      ) }
    </Frame>
  )
}
