import { connect } from 'react-redux'
import { setIsCreateLifecycleAction, setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import SpaceSelector from '../../../redux/selectors/space.selector'
import Inventory from './inventory'

const mapStateToProps = state => ({
  selectedSpace: SpaceSelector.selectedSpace(state),
  inventoryTree: InventoryTreeSelector.inventoryTree(state),
  flatView: LifecycleSelector.flatView(state),
  selectedInventoryTreeProduct: InventoryTreeSelector.product(state),
  comparisonFlatView: ComparisonSelector.comparisonFlatView(state),
  entityToCompare: ComparisonSelector.entityToCompare(state),
  impactUseMethodTotal: SpaceSelector.impactUseMethodTotal(state),
  isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
  isInventoryCompare: ComparisonSelector.isInventoryCompare(state)
})

const mapDispatchToProps = {
  setIsCreateLifecycle: setIsCreateLifecycleAction,
  setIsDetailsPanelOpenAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inventory)