import { gql } from '@apollo/client'

const role = gql`
  fragment roleFragment on Role {
    id
      label
      users {
        id
        name
      }
      apiClients {
        id
        name
      }
  }
`

export default role
