import React from 'react'
import styled from 'styled-components'
import { TListItem } from '../interface/list-item'

const OverviewListItemWrapper = styled.div`
  @media screen and (max-width: 600px) {
    &.xsm {
      width: 100%;
    }
  }
  @media screen and (min-width: 1600px) {
    &.xxl {
      width: 16.6666%;
    }
  }
`

type TOverviewListLayoutProps = {
  items: TListItem[]
}

export const OverviewListLayout = ({
  items
}: TOverviewListLayoutProps) => (
  <>
    {items.map(({ key, itemComponent: ItemComponent }) => (
      <OverviewListItemWrapper
        data-testid="overview-list-item"
        key={key}
        className="sm:col-6 md:col-4 lg:col-4 xl:col-3 xsm xxl p-2 flex-none h-11rem"
      >
        { ItemComponent }
      </OverviewListItemWrapper>
    ))}
  </>
)
