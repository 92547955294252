import { gql } from '@apollo/client'

export default gql`
  mutation startCheckoutSubscription($accountID: ID!, $planID: ID!, $successURL: String!, $cancelURL: String!, $defaultQuantity: String!) {
    startCheckoutSubscription(accountID: $accountID, planID: $planID, successURL: $successURL, cancelURL: $cancelURL, defaultQuantity: $defaultQuantity){
      id
      redirectUrl
    }
  }
`
