import React, { useRef, useState, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { normalize } from '../../../../utils/tools'
import { IPhase } from '../../../model'
import { isValid } from '../../../shared/util/tools'
import { PHASE_ICONS } from '../../constant/phase-icon'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

type TIconWrapperProps = {
  readonly?: boolean
}
const IconWrapper = styled.div<TIconWrapperProps>`
  background: var(--primarydark-50);

  &:hover {
    cursor: ${(props: TIconWrapperProps) => (props.readonly ? 'default' : 'pointer')};
    color: ${(props: TIconWrapperProps) => (props.readonly ? 'var(--gray-500)' : 'var(--primary-500)')};
    border: ${(props: TIconWrapperProps) => (props.readonly ? 'none' : '1px solid var(--primary-500)')};
  }

  &.selected-phase {
    background: var(--primarydark-100);
  }
`

type TPhaseIconComponentProps = {
  phase: IPhase
  changingPhaseType: boolean
  handleChangePhaseType: (param: string) => void
}
export const PhaseIconComponent = ({
  phase,
  changingPhaseType,
  handleChangePhaseType
}: TPhaseIconComponentProps) => {
  const { t } = useTranslation([ 'lifecycle', 'common' ])
  const { type = null, name } = phase
  const [ newPhaseType, setNewPhaseType ] = useState<string | null>(type)
  const { readonlyInventory = false } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const overlayPanelRef = useRef<OverlayPanel>(null)

  const phaseIndex = Object.keys(PHASE_ICONS).findIndex(key => key === type)
  const phaseSelectedIcon = isValid(phaseIndex) && Object.values(PHASE_ICONS)[phaseIndex]
  const typeIsCustom = type === 'custom'

  const preventEvent = (event?: any) => {
    event?.preventDefault()
    event?.stopPropagation()
  }

  const showPhaseTypePanel = (event: any) => {
    preventEvent(event)
    if (readonlyInventory) return

    setNewPhaseType(type)
    overlayPanelRef.current?.toggle(event)
  }

  const onPhaseTypeSelect = (event: any, type: string) => {
    preventEvent(event)
    setNewPhaseType(type)
  }

  const closePhaseSelectionPanel = (event?: any) => {
    preventEvent(event)

    overlayPanelRef.current?.hide()
  }

  const onChangePhaseType = (event: any) => {
    preventEvent(event)

    overlayPanelRef.current?.hide()
    newPhaseType && handleChangePhaseType(newPhaseType)
  }

  return (
    <>
      { (phaseSelectedIcon || typeIsCustom) && (
        <>
          <IconWrapper
            readonly={readonlyInventory}
            onClick={showPhaseTypePanel}
            data-cy={`phase-icon-${normalize(name)}`}
            data-testid="phase-select-icon"
            className="flex align-items-center justify-content-center w-2rem h-2rem border-circle"
          >
            { !typeIsCustom && phaseSelectedIcon && <FontAwesomeIcon icon={phaseSelectedIcon} className="w-2rem" /> }
            { typeIsCustom && name && <div className="flex text-lg justify-content-center align-items-center w-2rem h-2rem">{ name[0] }</div> }
          </IconWrapper>
          <OverlayPanel ref={overlayPanelRef}>
            <div data-testid="phase-change-type" data-cy={`phase-iconOpts-${normalize(name)}`} className="flex flex-column p-4 pb-1 w-14rem" onClick={preventEvent}>
              <div className="flex text-base font-medium pb-4">Change icon</div>
              <div className="flex flex-wrap w-full gap-3">
                {
                  Object.keys(PHASE_ICONS).map((key: string, index: number) => {
                    const phaseOptIcon = Object.values(PHASE_ICONS)[index]
                    return (
                      <IconWrapper
                        key={index}
                        onClick={(event: any) => onPhaseTypeSelect(event, key)}
                        data-cy={`phase-iconOpt-${normalize(key)}`}
                        data-testid={`phase-iconOpt-${normalize(key)}`}
                        className={classNames('flex align-items-center justify-content-center w-2rem h-2rem border-circle', {
                          'selected-phase': key === newPhaseType
                        })}
                      >
                        <FontAwesomeIcon icon={phaseOptIcon} />
                      </IconWrapper>
                    )
                  })
                }
              </div>
              <div className="flex w-full mt-4 h-2rem">
                <div className="flex w-6 justify-content-start">
                  <Button
                    onClick={closePhaseSelectionPanel}
                    data-testid="cancel-change-type"
                    label={t('actions.cancel', { ns: 'common' })}
                    iconPos="left"
                    loading={false}
                    className="p-button-outlined p-button-plain text-sm font-normal"
                  />
                </div>
                <div className="flex w-6 justify-content-end">
                  <Button
                    onClick={onChangePhaseType}
                    data-testid="button-change-type"
                    data-cy="button-change-type"
                    label={t('actions.save', { ns: 'common' })}
                    iconPos="left"
                    loading={changingPhaseType}
                    className="p-button-primary justify-content-end text-sm font-normal"
                  />
                </div>
              </div>
            </div>
          </OverlayPanel>
        </>
      )}
    </>
  )
}
