import React, { useContext } from 'react'

import { IDatabaseSearchResult, IElementaryFlowResult } from '../../interface/impact-dataset-result'
import { EDatabaseSearchColumn, EElementaryFlowColumn } from '../../enum/impact-dataset-column'
import { TableCellComponent } from './table-cell.component'
import { AddImpactDatasetContainer } from '../../container/add-impact-dataset.container'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { ImpactDatasetViewType } from '../../enum/impact-dataset-view-type'
import { DetailViewButtonComponent } from '../detail-view-button.component'

type TableBodyTemplateComponentProps = {
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: EDatabaseSearchColumn | EElementaryFlowColumn,
  value: IDatabaseSearchResult | IElementaryFlowResult,
  isMouseOver?: boolean
}

export const TableBodyTemplateComponent = ({
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = EDatabaseSearchColumn.DatasetName,
  value,
  isMouseOver = false
}: TableBodyTemplateComponentProps) => {
  const { selectedDatasetViewType } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const isSelectedViewType = (datasetViewType: ImpactDatasetViewType) => datasetViewType === selectedDatasetViewType

  const getDatabaseSearchBodyTemplate = () => {
    const {
      datasetName, geography, property, unit, database
    } = value as IDatabaseSearchResult

    return {
      [EDatabaseSearchColumn.DatasetName]: <TableCellComponent className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="datasetName" textContent={datasetName} />,
      [EDatabaseSearchColumn.Geography]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="datasetGeography" textContent={geography} />,
      [EDatabaseSearchColumn.Property]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="datasetUnit" textContent={`${property} (${unit})`} />,
      [EDatabaseSearchColumn.Database]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="datasetDatabase" textContent={database} />,
      [EDatabaseSearchColumn.Action]: (
        <div className="flex align-items-center">
          <DetailViewButtonComponent dataset={value} isMouseOver={isMouseOver} />
          <AddImpactDatasetContainer referenceProductID={value.id} isMouseOver={isMouseOver} />
        </div>
      )
    }
  }

  const getElementaryFlowBodyTemplate = () => {
    const {
      datasetName, property, unit, compartment, subCompartment
    } = value as IElementaryFlowResult

    return {
      [EElementaryFlowColumn.DatasetName]: <TableCellComponent className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="elementaryFlowName" textContent={datasetName} />,
      [EElementaryFlowColumn.Compartment]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="elementaryFlowCompartment" textContent={compartment} />,
      [EElementaryFlowColumn.SubCompartment]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="elementaryFlowSubCompartment" textContent={subCompartment} />,
      [EElementaryFlowColumn.Property]: <TableCellComponent enableWordBreak className="align-items-center w-full text-sm text-gray-700 m-3" data-cy="elementaryFlowUnit" textContent={`${property} (${unit})`} />,
      [EDatabaseSearchColumn.Action]: (
        <div className="flex align-items-center">
          <DetailViewButtonComponent dataset={value} isMouseOver={isMouseOver} />
          <AddImpactDatasetContainer referenceProductID={value.id} isMouseOver={isMouseOver} />
        </div>
      )
    }
  }

  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0"
      onMouseEnter={() => onRowMouseEnter(value.id)}
      onMouseLeave={() => onRowMouseLeave()}
    >
      { isSelectedViewType(ImpactDatasetViewType.DatabaseSearch) && getDatabaseSearchBodyTemplate()[column as EDatabaseSearchColumn] }
      { isSelectedViewType(ImpactDatasetViewType.ElementaryFlow) && getElementaryFlowBodyTemplate()[column as EElementaryFlowColumn] }
    </div>
  )
}
