import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'primereact/multiselect'

import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { TaskbarFilterWrapperLayout } from '../../layout/taskbar-filter-wrapper.layout'
import { ITag } from '../../../../component/model/tags-form/tagsForm.interface'

export const TaskbarFilterTagsComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const [ selectedTags, setSelectedTags ] = useState(null)
  const {
    tagOptions = [],
    updateProduct
  } = useContext<TProductContext>(ProductContext)

  const typeOptions = tagOptions.map((tagOpt: ITag) => ({
    label: tagOpt.name,
    value: tagOpt.id
  }))
  const onTagChange = (tags: any) => {
    setSelectedTags(tags)
    updateProduct({ tags, currentPage: 1 })
  }

  return (
    <>
      { tagOptions.length > 0 && (
        <TaskbarFilterWrapperLayout>
          <MultiSelect
            data-testid="select-tags"
            value={selectedTags}
            options={typeOptions}
            display="chip"
            showSelectAll={false}
            panelHeaderTemplate={() => <></>}
            className="h-2rem m-0 p-0"
            panelClassName="taskbar-filter"
            onChange={(event: any) => onTagChange(event.value)}
            placeholder={t('labels.tags')}
          />
        </TaskbarFilterWrapperLayout>
      ) }
    </>
  )
}
