import React, { useContext, useRef } from 'react'
import styled from 'styled-components'

import { Tooltip } from 'primereact/tooltip'
import DashboardContext from '../context/dashboard.context'
import { NavigationItemAttr } from '../interface/navigation-items-props'
import { useRefOverflow } from '../../shared/hook/use-ref-overflow'

const MenuDetailWrapper = styled.div`
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  height: 2.875rem;

  .nav-item-icon,
  .nav-item-label {
    height: 1.375rem;
  }
`

const MenuDetail = styled.div`
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  width: calc(100% - 5.25rem);

  .p-tooltip-arrow {
    display: none;
  }
`

const TooltipContent = styled.div`
  max-width: 20rem;
  min-width: 5rem;
  word-wrap: break-word;
  word-break: keep-all;
`

export const WorkspaceMenuItemComponent = ({
  label, icon, options, hasItems, tag, iconClassName
}: NavigationItemAttr) => {
  const iconRef = useRef(null)
  const { collapsedNavigation } = useContext(DashboardContext)
  const [ labelRef, hasLabelOverflow, labelDom ] = useRefOverflow()

  const getTooltipContent = () => (
    <TooltipContent>
      {' '}
      { label }
      {' '}
    </TooltipContent>
  )
  return (
    <MenuDetailWrapper data-testid="workspace-menuitem-wrapper" onClick={options.onClick} className="flex w-full px-3">
      { collapsedNavigation && iconClassName && <Tooltip target={`.${iconClassName}`} autoHide>{ getTooltipContent() }</Tooltip> }

      <div data-testid="workspace-menuitem-icon" ref={iconRef} className={`flex-none nav-item-icon ${iconClassName}`} data-pr-position="right">{ icon }</div>
      {!collapsedNavigation && (
        <>
          <MenuDetail className="flex-grow-1 pl-3">
            <Tooltip target={labelDom} onBeforeShow={() => hasLabelOverflow}>{ getTooltipContent() }</Tooltip>
            <div data-testid="workspace-menuitem-label" ref={labelRef} data-pr-position="right" className="w-full white-space-nowrap overflow-hidden text-overflow-ellipsis text-base nav-item-label">{ label }</div>
          </MenuDetail>
          { hasItems && <div data-testid="workspace-menuitem-more" className="flex-none pt-1"><i className="pi pi-angle-down"></i></div> }
          { tag && <div data-testid="workspace-menuitem-tag" className="flex-none">{ tag }</div>}
        </>
      )}
    </MenuDetailWrapper>
  )
}
