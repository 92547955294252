import React, { useState } from 'react'
import { SelectButton } from 'primereact/selectbutton'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'

import { SortingOrder } from '../../../../__generated__/graphql'
import { SelectButtonWrapperLayout } from '../../../shared/layout/select-button-wrapper.layout'

type SortTogglerComponentProps = {
  onViewChange?: Function,
  selectedSortOrder?: SortingOrder
}
export const SortTogglerComponent = ({
  onViewChange,
  selectedSortOrder
}: SortTogglerComponentProps) => {
  const [ selectedView, setSelectedView ] = useState(selectedSortOrder)
  const { t } = useTranslation([ 'common' ])

  // eslint-disable-next-line
  const typeOptions = Object.values(SortingOrder).map((item, index) => {
    switch (item) {
    case SortingOrder.Asc:
      return { key: index, icon: faArrowDown, value: item }
    case SortingOrder.Desc:
      return { key: index, icon: faArrowUp, value: item }
    }
  })

  const onTagChange = (selectedSortOrder: any) => {
    setSelectedView(selectedSortOrder)
    onViewChange && onViewChange(selectedSortOrder)
  }

  const viewTemplate = (option: any) => <FontAwesomeIcon data-testid={`view-option-${option.key}`} className="text-sm" icon={option.icon} />

  return (
    <SelectButtonWrapperLayout clearable>
      <div className="flex w-full align-items-center">
        <span className="font-normal text-sm mr-2 text-gray-800">{ t('labels.sortBy') }</span>
        <SelectButton
          className="h-2rem"
          value={selectedView}
          options={typeOptions}
          onChange={(event: any) => onTagChange(event.value)}
          itemTemplate={viewTemplate}
          optionLabel="value"
        />
      </div>
    </SelectButtonWrapperLayout>
  )
}
