import { connect } from 'react-redux'
import { createPhaseSaga } from '../../../../redux/sagas/lifecycle.saga'
import CreatePhase from './createPhase'

const mapDispatchToProps = {
  createPhaseSaga
}

export default connect(
  null,
  mapDispatchToProps
)(CreatePhase)
