import { gql } from '@apollo/client'

export default gql`
  query possibleInputs($processID: ID!) {
    possibleInputs(processID: $processID) {
      id
      name
    }
  }
`
