import { gql } from '@apollo/client'

export default gql`
  mutation createItemFolder($name: String!, $spaceID: ID!, $parentID: ID, $labels: [ID!]!) {
	  createItemFolder(name: $name, spaceID: $spaceID, parentID: $parentID, labels: $labels) {
      id
      name
    }
  }
`
