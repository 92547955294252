import { connect } from 'react-redux'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import SunburstGraph from './sunburstGraph'

const mapStateToProps = state => ({
  inventoryTreeSelectorProductId: InventoryTreeSelector.productId(state),
  skipPhases: FlagsSelector.isSkipPhases(state)
})

export default connect(
  mapStateToProps,
  null
)(SunburstGraph)
