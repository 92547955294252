import { gql } from '@apollo/client'
import favoriteProductFragment from '../fragment/favoriteProduct'

export default gql`
  query favoriteProducts($spaceID: ID!, $categories: [ProductCategory!], $sortBy: SortProduct, $query: String,
    $labels: [ID!], $tags: [ID!], $folderID: ID)
  {
    favoriteProducts(spaceID: $spaceID, categories: $categories, sortBy: $sortBy, query: $query,
      labels: $labels, tags: $tags, folderID: $folderID)
    {
      items {
        __typename
        ... on Folder {
          id
          name
        }
        ... on Product {
          ...favoriteProductFragment
        }
      }
      total
    }
  }
  ${favoriteProductFragment}
`
