import { connect } from 'react-redux'
import SpaceSelector from '../../../redux/selectors/space.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import { setSpaceImpactSettingsSaga } from '../../../redux/sagas/space.saga'
import { trackEventSaga } from '../../../redux/sagas/tracking.saga'
import { updateInventoryItemsSaga } from '../../../redux/sagas/inventoryTree.saga'
import { updateLifecyclesItemsSaga } from '../../../redux/sagas/lifecycle.saga'
import ChangeImpactCategory from './changeImpactCategory'

const mapStateToProps = state => ({
  selectedImpactMethod: SpaceSelector.impactMethod(state),
  selectedImpactCategory: SpaceSelector.impactCategory(state),
  selectedSpace: SpaceSelector.selectedSpace(state),
  selectedProduct: SelectedProductSelector.product(state)
})

const mapDispatchToProps = {
  setSpaceImpactSettingsSaga,
  trackEventSaga,
  updateInventoryItemsSaga,
  updateLifecyclesItemsSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeImpactCategory)
