import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar } from '@fortawesome/pro-regular-svg-icons'

import ProductDetailContext from '../provider/context/product-detail.context'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { InventoryViewType } from '../enum/inventory-view-type'
import { normalize } from '../../../utils/tools'
import { DecimalViewType } from '../../shared/enum/decimal'
import { ITransformedLeafItem } from '../interface/transformed-leaf-item'
import { DoughnutLegendItemComponent } from '../component/chart/doughnut-legend-item.component'
import { Navigation } from '../../shared/enum'

type DoughnutLegendLayoutProps = {
  maxChartItems?: number
  chartItems?: ITransformedLeafItem[]
}
export const DoughnutLegendLayout = ({
  maxChartItems = 8,
  chartItems = []
}: DoughnutLegendLayoutProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const navigate = useNavigate()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const {
    productInventory: { product = null } = {},
    totalImpact: { unit: totalImpactUnit = null } = {},
    selectedDecimalViewType = DecimalViewType.NumericValue
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const colorClassSet = [
    'bg-primary-900', 'bg-primary-800', 'bg-primary-700',
    'bg-primary-600', 'bg-primary-500', 'bg-primary-400',
    'bg-primary-300', 'bg-primary-200', 'bg-primary-100'
  ]

  const isSelectedView = (selected: DecimalViewType) => selected === selectedDecimalViewType

  const positiveChartItems = chartItems.filter((item :ITransformedLeafItem) => item?.impactAmount && parseFloat(item?.impactAmount) >= 0)

  const negativeChartItems = chartItems.filter((item :ITransformedLeafItem) => item?.impactAmount && parseFloat(item?.impactAmount) < 0)

  const getOthersSummary = () => {
    let othersSummary = 0
    positiveChartItems.forEach((item :ITransformedLeafItem, index) => {
      const impactAmount = item?.impactAmount || '0'
      const impactRatioRelativeToTotal = item?.impactRatioRelativeToTotal || 0
      if (index > maxChartItems - 1) {
        othersSummary += isSelectedView(DecimalViewType.PercentageValue) ? impactRatioRelativeToTotal : parseFloat(impactAmount)
      }
    })
    return othersSummary
  }

  const negativeImpactSummary = negativeChartItems.reduce((acc, item: ITransformedLeafItem) => {
    const impactAmount = item?.impactAmount || '0'
    const impactRatioRelativeToTotal = item?.impactRatioRelativeToTotal || 0
    return acc + (isSelectedView(DecimalViewType.PercentageValue) ? impactRatioRelativeToTotal : parseFloat(impactAmount))
  }, 0)

  const navigateToBarChart = () => {
    const { id: productId } = product || {}
    account?.id && space?.slug && productId && navigate(generatePath(Navigation.ProductObjectInventoryTabs, {
      accountId: account.id,
      workspaceSlug: space.slug,
      productId,
      activeTab: InventoryViewType.BarChart
    }))
  }

  return (
    <>
      { positiveChartItems.slice(0, maxChartItems).map((item: ITransformedLeafItem, index: number) => (
        <div
          key={`legend-doughnut-${index}`}
          data-testid={`doughnut-legend-${index}`}
          className="flex align-items-center justify-content-end w-full gap-2"
          data-cy={`legend-for-${normalize(item.name)}`}
        >
          <DoughnutLegendItemComponent
            index={index}
            name={item.name}
            colorClassName={`${colorClassSet[index]}`}
            unit={totalImpactUnit}
            value={isSelectedView(DecimalViewType.PercentageValue) ? item?.impactRatioRelativeToTotal : item?.impactAmount}
          />
        </div>
      ))}
      { positiveChartItems.length > maxChartItems
      && (
        <div data-testid="doughnut-legend-others" className="flex align-items-center justify-content-end w-full gap-2">
          <DoughnutLegendItemComponent
            index="other"
            name={t('labels.others', { ns: 'common' })}
            colorClassName={`${colorClassSet[8]}`}
            value={getOthersSummary()}
            unit={totalImpactUnit}
          />
        </div>
      )}
      { negativeChartItems.length > 0
      && (
        <div data-testid="doughnut-legend-negative" className="flex align-items-center justify-content-end w-full gap-2">
          <DoughnutLegendItemComponent
            index="negative-summary"
            name={t('labels.impactBelowZero')}
            colorClassName="bg-green-200"
            value={negativeImpactSummary}
            unit={totalImpactUnit}
          />
        </div>
      )}
      { chartItems.length > 0
      && (
        <div
          data-html2canvas-ignore
          data-testid="doughnut-legend-viewAll"
          onClick={navigateToBarChart}
          className="flex align-items-center justify-content-end w-full px-1 text-primary-500 cursor-pointer"
        >
          <div className="flex-none mr-2"><FontAwesomeIcon data-testid="doughnut-legend-viewAll-icon" icon={faChartBar} /></div>
          <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
            { t('labels.view', { context: 'allItems', ns: 'common' }) }
          </div>
        </div>
      )}
    </>
  )
}

