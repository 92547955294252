import * as Sentry from '@sentry/react'
import { all, call, spawn } from 'redux-saga/effects'
import accountSaga from './account.saga'
import accountCouponSaga from './accountCoupon.saga'
import comparisonSaga from './comparison.saga'
import conversionSaga from './conversion.saga'
import inventoryTreeSaga from './inventoryTree.saga'
import lifecycleSaga from './lifecycle.saga'
import lifecycleScenarioSaga from './lifecycleScenario.saga'
import productSaga from './product.saga'
import productScenarioInventoryTreeSaga from './productScenarioInventoryTree.saga'
import productReferenceSaga from './referenceProducts.saga'
import spaceSaga from './space.saga'
import stripeSaga from './stripe.saga'
import trackingSaga from './tracking.saga'
import activityTemplateSaga from './activityTemplate.saga'
import userSaga from './user.saga'
import { printWarning } from '../../utils/devTools'

export default function* rootSagas () {
  const sagas = [
    accountSaga,
    productSaga,
    spaceSaga,
    inventoryTreeSaga,
    productScenarioInventoryTreeSaga,
    lifecycleScenarioSaga,
    conversionSaga,
    comparisonSaga,
    accountCouponSaga,
    lifecycleSaga,
    trackingSaga,
    activityTemplateSaga,
    userSaga,
    stripeSaga,
    productReferenceSaga,
  ]

  yield all(sagas.map(saga =>
    spawn(function* () {
      while (true) {
        try {
          yield call(saga)
          break
        } catch (e) {
          if (e && e.message) {
            printWarning(e.message)
          }
          Sentry.captureException(e)
        }
      }
    }))
  )
}
