import { returnNested } from '../../utils/tools'
import memoize from 'lodash.memoize'

const getSelectedKeysFromSelectedItem = selectedInventoryItem => [ selectedInventoryItem ]

// No need for a resolver because we use the selectedInventoryItem itself to cache
const selectedKeysMemoized = memoize(getSelectedKeysFromSelectedItem)

// Globals
export const selectedInventoryItemSelector = state => returnNested(state, 'global', 'selectedInventoryItem')
export const selectedKeysSelector = state => selectedKeysMemoized(returnNested(state, 'global', 'selectedInventoryItem'))
export const inventoryRootItemIdSelector = state => returnNested(state, 'global', 'inventoryRootItemId')

// Inventory tree
const root = state => returnNested(state, 'account', 'inventory'),
  inventoryTree = state => returnNested(root(state), 'inventoryTreeView'),
  inventoryItemsToDisplay = state => returnNested(inventoryTree(state), 'inventoryItemsToDisplay'),
  inventoryItems = state => returnNested(inventoryTree(state), 'inventoryItems'),
  inventoryTreeId = state => returnNested(inventoryTree(state), 'id'),
  product = state => returnNested(inventoryTree(state), 'product'),
  totalImpact = state => returnNested(inventoryTree(state), 'totalImpact'),
  productId = state => returnNested(product(state), 'id'),
  productName = state => returnNested(product(state), 'name'),
  phase = state => returnNested(inventoryTree(state), 'phase'),
  leafInventory = state => returnNested(root(state), 'leafInventory'),
  scenarios = state => returnNested(product(state), 'scenarios'),
  inventoryRootProductTotalImpact = state => returnNested(inventoryTree(state), 'totalImpact')

// Product scenario
const productScenarioRoot = state => returnNested(root(state), 'productScenarioInventoryTree'),
  productScenarioProduct = state => returnNested(productScenarioRoot(state), 'product'),
  productScenarioProductId = state => returnNested(productScenarioProduct(state), 'id')

export const InventoryTreeSelector = {
  inventoryTree,
  inventoryTreeId,
  product,
  productId,
  productName,
  totalImpact,
  phase,
  leafInventory,
  scenarios,
  inventoryRootProductTotalImpact,
  inventoryItemsToDisplay,
  inventoryItems
}

export const ProductScenarioSelector = {
  inventoryTree: productScenarioRoot,
  productId: productScenarioProductId
}

