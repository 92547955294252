import React, { useState, useRef, useContext } from 'react'
import { MinusOutlined } from '@ant-design/icons'
import { Button, Form, Select, Divider } from 'antd'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import { VALIDATION, PRODUCT_LABEL } from '../../../../utils/const'
import { returnNested, floatToString, filterOptionByValue, normalize } from '../../../../utils/tools'
import InputWrapper from '../../../helpers/form/inputWrapper'
import SelectWrapper from '../../../helpers/form/selectWrapper'
import { DetailsPanelCard } from '../../details-panel/detailPanelCard'
import LifecycleContext from '../../../../v1/lifecycle/provider/context/lifecycle.context'
import LifecycleDetailContext from '../../../../v1/lifecycle/provider/context/lifecycle-detail.context'

const ChangeLifecycle = props => {
  const {
    changeLifecycleProductSaga,
    isReadOnly,
    lifecycle,
    lifecycleAmount,
    lifecycleDescription,
    lifecycleName,
    lifecycleUnit,
    renameLifecycleItemSaga,
    searchProductsSaga,
    selectedSpaceId,
    selectedSpaceProducts,
    setLifecycleAmountSaga,
    setLifecycleDescriptionSaga,
    setLifecycleUnitSaga,
    setQueryOfProductFilterAction,
    t
  } = props
  const { refetchLifeCycle } = useContext(LifecycleContext)
  const { refetchLifecycle: refetchLifecycleDetail } = useContext(LifecycleDetailContext)
  const { OptGroup, Option } = Select

  const formRef = useRef()
  const [ isEditName, setIsEditName ] = useState(false)
  const [ isEditDescription, setIsEditDescription ] = useState(false)
  const [ isEditAmount, setIsEditAmount ] = useState(false)
  const [ isEditUnit, setIsEditUnit ] = useState(false)
  const [ isEditLifecycleProduct, setIsEditLifecycleProduct ] = useState(false)

  const initialValues = {
    lifecycleName: lifecycleName,
    lifecycleDescription: returnNested(lifecycle, 'description'),
    lifecycleAmount: returnNested(lifecycle, 'amount'),
    lifecycleUnit: returnNested(lifecycle, 'unit'),
    lifecycleProductId: returnNested(lifecycle, 'product', 'id'),
    lifecycleProductName: returnNested(lifecycle, 'product', 'name'),
  }

  const updateCallback = () => {
    refetchLifeCycle && refetchLifeCycle()
    refetchLifecycleDetail && refetchLifecycleDetail()
  }

  const handleRenameLifecycle = () => {
    formRef.current.validateFields([ 'lifecycleName' ]).then(values => {
      if (values.lifecycleName === lifecycleName) return resetForm()
      renameLifecycleItemSaga(lifecycle.id, values.lifecycleName, updateCallback)
      updateForm()
    })
  }

  const changeLifecycleDescription = () => {
    formRef.current.validateFields([ 'lifecycleDescription' ]).then(values => {
      if (values.lifecycleDescription === lifecycleDescription) return resetForm()
      setLifecycleDescriptionSaga(lifecycle.id, values.lifecycleDescription, updateCallback)
      updateForm()
    })
  }

  const changeLifecycleAmount = () => {
    formRef.current.validateFields([ 'lifecycleAmount' ]).then(values => {
      const amount = floatToString(values.lifecycleAmount)
      if (amount === lifecycleAmount) return resetForm()
      setLifecycleAmountSaga(lifecycle.id, amount, updateCallback)
      updateForm()
    })
  }

  const changeLifecycleUnit = () => {
    formRef.current.validateFields([ 'lifecycleUnit' ]).then(values => {
      if (values.lifecycleUnit === lifecycleUnit)  return resetForm()
      setLifecycleUnitSaga(lifecycle.id, values.lifecycleUnit, updateCallback)
      updateForm()
    })
  }

  const resetForm = () => {
    formRef.current.resetFields()
    setIsEditAmount(false)
    setIsEditDescription(false)
    setIsEditName(false)
    setIsEditUnit(false)
    setIsEditLifecycleProduct(false)
  }

  const updateForm = () => {
    formRef.current.submit()
    setIsEditAmount(false)
    setIsEditDescription(false)
    setIsEditName(false)
    setIsEditUnit(false)
    setIsEditLifecycleProduct(false)
  }

  const handleProductSearch = value => {
    setQueryOfProductFilterAction(value || '')
    searchProductsSaga({
      spaceId: selectedSpaceId,
      currentPage: 1,
      noFavorite: true,
      noCategories: true,
      labels: [ PRODUCT_LABEL.PRODUCT, PRODUCT_LABEL.OBJECT ]
    })
  }

  const handleEditLifecycleProduct = () => {
    setIsEditLifecycleProduct(true)
    handleProductSearch('')
  }

  const handleSelectProduct = productId => {
    changeLifecycleProductSaga(lifecycle.id, productId, updateCallback)
    resetForm()
  }

  const handleDetachProduct = () => {
    changeLifecycleProductSaga(lifecycle.id, '', updateCallback)
    resetForm()
  }

  const getEntityOptionsByLabel = label => {
    const hasEntryLabel = entry => {
      const { labels = [] } = entry
      return labels.some(item => item.id === label)
    }
    return <OptGroup key={label} label={t('model.label', { context: label })}>
      {selectedSpaceProducts && selectedSpaceProducts
        .filter(entry => hasEntryLabel(entry))
        .map(entity => (
          <Option
            data-cy={`item-named-${normalize(entity.name)}`}
            key={ entity.id }>
            { entity.name }
          </Option>
        ))
      }
    </OptGroup>
  }

  if (!lifecycle) return null

  return <DetailsPanelCard
    title={t('model.lifecycle_details')}
    className="lifecycle-details">
    <Form
      ref={formRef}
      layout="vertical"
      className="field-container"
      hideRequiredMark
      initialValues={initialValues}
    >
      <InputWrapper
        isFieldEditable={isEditName}
        disabled={isReadOnly}
        dataCy='lifecycle-name'
        displayedText={initialValues.lifecycleName }
        editHandler={() => setIsEditName(true)}
        fieldLabel= {t('global.name')}
        fieldName= "lifecycleName"
        fieldRules= {[
          { min: VALIDATION.PRODUCT_NAME_LENGTH, message: t('validation.product_name', { name: 'Lifecycle name' }) },
          { required: true, message: t('validation.is_required', { name: 'Lifecycle name' }) }
        ]}
        onPressEnter={handleRenameLifecycle}
        onBlur={handleRenameLifecycle}
        onPressEscape={ resetForm }
      />

      <InputWrapper
        isFieldEditable={isEditDescription}
        disabled={isReadOnly}
        dataCy='lifecycle-description'
        displayedText={initialValues.lifecycleDescription }
        editHandler={() => setIsEditDescription(true)}
        fieldLabel= {t('global.description')}
        fieldName= "lifecycleDescription"
        onPressEnter={changeLifecycleDescription}
        onBlur={changeLifecycleDescription}
        onPressEscape={ resetForm }
      />

      <InputWrapper
        isFieldEditable={isEditAmount}
        disabled={isReadOnly}
        dataCy='lifecycle-amount'
        displayedText={initialValues.lifecycleAmount }
        editHandler={() => setIsEditAmount(true)}
        fieldLabel= {t('global.amount')}
        fieldName= "lifecycleAmount"
        min={0}
        type="number"
        onPressEnter={changeLifecycleAmount}
        onBlur={changeLifecycleAmount}
        onPressEscape={ resetForm }
      />

      <InputWrapper
        isFieldEditable={isEditUnit}
        disabled={isReadOnly}
        dataCy='lifecycle-unit'
        displayedText={initialValues.lifecycleUnit }
        value={initialValues.lifecycleUnit }
        editHandler={() => setIsEditUnit(true)}
        fieldLabel= {t('global.unit')}
        fieldName= "lifecycleUnit"
        rules={[
          { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Unit' }) },
          { validator: async (rule, value) => {
            if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
              throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Unit' }))
            }
          } }
        ]}
        onPressEnter={changeLifecycleUnit}
        onBlur={changeLifecycleUnit}
        onPressEscape={resetForm}
      />

      <div className='select-wrapper'>
        <SelectWrapper
          autoComplete={false}
          isFieldEditable={isEditLifecycleProduct}
          disabled={isReadOnly}
          allowClear
          showSearch
          dataCy="lifecycle-product"
          fieldLabel= {t('global.product')}
          fieldName="productId"
          editHandler={handleEditLifecycleProduct}
          displayedText={initialValues.lifecycleProductName }
          value={initialValues.lifecycleProductId }
          dropdownMatchSelectWidth={false}
          onSearch={val => handleProductSearch(val)}
          onSelect={productId => handleSelectProduct(productId)}
          filterOption={(input, option) => filterOptionByValue(input, option)}
          placeholder={t('model.search_product')}
          onPressEscape={resetForm}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <Button
                className="button transparent"
                icon={<MinusOutlined />}
                size="small" type="link"
                onClick={handleDetachProduct}
                data-cy="detach-product-button"
                disabled={!initialValues.lifecycleProductId}
              >{t('model.no_main_product')}</Button>
            </div>
          )}
        >
          {getEntityOptionsByLabel(PRODUCT_LABEL.PRODUCT)}
          {getEntityOptionsByLabel(PRODUCT_LABEL.OBJECT)}
        </SelectWrapper>
      </div>

    </Form>
  </DetailsPanelCard>
}

export default compose(
  withTranslation(),
  withRouter
)(ChangeLifecycle)
