import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ComparisonFlatView from '../../utils/comparisonFlatView'
import FlatView from '../../utils/flatView'
import resource from '../../utils/resource/resource'
import { returnNested, safeArray, browserTimezone, getArgsByAction, isEmpty } from '../../utils/tools'
import { getExpandedInventoryKeysMap } from '../../utils/treeUtils'
import { setComparisonFlatViewAction, setIsInventoryCompareAction } from '../actions/comparison.actions'
import {
  setIsCreateLifecycleAction,
  setIsCreatePhaseAction,
  setIsDetailsPanelOpenAction,
  setIsForcingWorkspacePanelAction,
  setIsOrderingPhasesAction,
  setIsShowInventoryAction,
  setIsSkipPhasesAction,
  setIsCreateLifecycleDialogShowedAction
} from '../actions/flags.actions'
import {
  setInventoryRootItemIdAction,
  setSelectedInventoryItemKeyAction,
  setSelectedProductAction
} from '../actions/global.actions'
import { setInventoryExpandedKeysAction } from '../actions/inventoryExpandedKeys.actions'
import {
  addLifecycleToInventoryAction,
  removeLifecycleFromInventoryAction,
  setInventoryLifecyclesAction
} from '../actions/inventoryTree.actions'
import {
  removeLifecycleAction,
  removeLifecycleScenarioAction,
  removePhaseAction,
  setPhaseTypeAction,
  renameLifecycleAction,
  renameLifecycleScenarioAction,
  renamePhaseAction,
  renameLifecyclesPaginatedAction,
  setDescriptionForLifecyclesPaginatedListItemAction,
  reorderPhasesAction,
  setDetailsPanelLifecycleIdAction,
  setFlatViewAction,
  setLifecycleAmountAction,
  setLifecycleDescriptionAction,
  setLifecycleScenarioAmountAction,
  setLifecycleScenarioDescriptionAction,
  setLifecycleScenarioUnitAction,
  setLifecycleUnitAction,
  setSelectedLifecycleAction,
  setSelectedPhaseAction,
  setLifecyclesPaginatedAction,
  removeLifecycleFromPaginatedListAction,
  setLifecycleProductAction,
  setLifecycleScenarioProductAction
} from '../actions/lifecycle.actions'
import { setIsLoadingLeftTreeAction } from '../actions/loading.actions'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import ComparisonSelector from '../selectors/comparison.selector'
import FlagsSelector from '../selectors/flags.selector'
import InventoryExpandedKeysSelector from '../selectors/inventoryExpandedKeys.selector'
import { LifecycleScenarioSelector, LifecycleSelector } from '../selectors/lifecycle.selector'
import { getComparisonEntity, mapCompareEntities } from './comparison.saga'
import { clearComparison, clearScenario, dispatchErrors, mapLifecycleEntity } from './helpers'
import { getLifecycleScenarioFn, getLifecycleScenarioSaga } from './lifecycleScenario.saga'
import { SORT_ORDER } from '../../utils/const'

export const
  GET_LIFECYCLE_BY_ID             = 'GET_LIFECYCLE_BY_ID_SAGA',
  LOAD_LIFECYCLE_PAGE             = 'LOAD_LIFECYCLE_PAGE_SAGA',
  CREATE_LIFECYCLE                = 'CREATE_LIFECYCLE_SAGA',
  UPDATE_LIFECYCLES_ITEMS         = 'UPDATE_LIFECYCLES_ITEMS_SAGA',
  SET_IS_LIFECYCLE_SKIP_PHASES    = 'SET_IS_LIFECYCLE_SKIP_PHASES_SAGA',
  SELECT_PHASE                    = 'SELECT_PHASE_SAGA',
  REMOVE_LIFECYCLE                = 'REMOVE_LIFECYCLE_SAGA',
  RENAME_LIFECYCLE                = 'RENAME_LIFECYCLE_SAGA',
  CREATE_PHASE                    = 'CREATE_PHASE_SAGA',
  RENAME_PHASE                    = 'RENAME_PHASE_SAGA',
  REMOVE_PHASE                    = 'REMOVE_PHASE_SAGA',
  REORDER_PHASES                  = 'MOVE_PHASE_SAGA',
  COPY_LIFECYCLE                  = 'COPY_LIFECYCLE_SAGA',
  SET_LIFECYCLE_DESCRIPTION       = 'SET_LIFECYCLE_DESCRIPTION_SAGA',
  SET_LIFECYCLE_AMOUNT            = 'SET_LIFECYCLE_AMOUNT_SAGA',
  SET_LIFECYCLE_UNIT              = 'SET_LIFECYCLE_UNIT_SAGA',
  SET_LIFECYCLE_DETAILS_PANEL     = 'SET_LIFECYCLE_DETAILS_PANEL_SAGA',
  EXPORT_LIFECYCLE_IMPACTS        = 'EXPORT_LIFECYCLE_IMPACTS_SAGA',
  CHANGE_PHASE_TYPE               = 'CHANGE_PHASE_TYPE_SAGA',
  GET_LIFECYCLES_PAGINATED        = 'GET_LIFECYCLES_PAGINATED',
  CHANGE_LIFECYCLE_PRODUCT        = 'CHANGE_LIFECYCLE_PRODUCT_SAGA'

export const
  getLifecycleByIdSaga = lifecycleId => ({
    type: GET_LIFECYCLE_BY_ID,
    lifecycleId
  }),
  getLifecyclesPaginatedSaga = ({ query, spaceID, currentPage, pageSize }) => ({
    type: GET_LIFECYCLES_PAGINATED,
    query, spaceID, currentPage, pageSize
  }),
  loadLifecycleItemSaga = params => ({
    type: LOAD_LIFECYCLE_PAGE,
    params
  }),
  updateLifecyclesItemsSaga = (closePanel = false) => ({
    type: UPDATE_LIFECYCLES_ITEMS,
    closePanel
  }),
  setIsLifecycleSkipPhasesSaga = (skipPhases, cb) => ({
    type: SET_IS_LIFECYCLE_SKIP_PHASES,
    skipPhases, cb
  }),
  createPhaseSaga = (lifecycleId, name, phaseType) => ({
    type: CREATE_PHASE,
    lifecycleId, name, phaseType
  }),
  renamePhaseSaga = (id, name) => ({
    type: RENAME_PHASE,
    id, name
  }),
  changePhaseTypeSaga = (id, phaseType) => ({
    type: CHANGE_PHASE_TYPE,
    id, phaseType
  }),
  removePhaseSaga = id => ({
    type: REMOVE_PHASE,
    id
  }),
  reorderPhaseSaga = (lifecycleId, phaseIds, oldPhaseIds) => ({
    type: REORDER_PHASES,
    lifecycleId, phaseIds, oldPhaseIds
  }),
  selectPhaseSaga = phase => ({
    type: SELECT_PHASE,
    phase
  }),
  createLifecycleItemSaga = ({ productID, workspaceID, name, description, amount, unit, phases, cb }) => ({
    type: CREATE_LIFECYCLE,
    productID, workspaceID, name, description, amount, unit, phases, cb
  }),
  removeLifecycleItemSaga = (lifecycleId, cb) => ({
    type: REMOVE_LIFECYCLE,
    lifecycleId, cb
  }),
  renameLifecycleItemSaga = (lifecycleId, name, cb) => ({
    type: RENAME_LIFECYCLE,
    lifecycleId,
    name,
    cb
  }),
  copyLifecycleSaga = (lifecycleId, cb) => ({
    type: COPY_LIFECYCLE,
    lifecycleId, cb
  }),
  setLifecycleDescriptionSaga = (lifecycleId, description, cb) => ({
    type: SET_LIFECYCLE_DESCRIPTION,
    lifecycleId,
    description,
    cb
  }),
  setLifecycleAmountSaga = (lifecycleId, amount, cb) => ({
    type: SET_LIFECYCLE_AMOUNT,
    lifecycleId,
    amount,
    cb
  }),
  setLifecycleUnitSaga = (lifecycleId, unit, cb) => ({
    type: SET_LIFECYCLE_UNIT,
    lifecycleId,
    unit,
    cb
  }),
  setLifecycleDetailsPanelSaga = lifecycleId => ({
    type: SET_LIFECYCLE_DETAILS_PANEL,
    lifecycleId
  }),
  exportLifecycleImpactsSaga = lifecycleId => ({
    type: EXPORT_LIFECYCLE_IMPACTS,
    lifecycleId,
  })
  ,
  changeLifecycleProductSaga = (lifecycleId, productId, cb) => ({
    type: CHANGE_LIFECYCLE_PRODUCT,
    lifecycleId,
    productId,
    cb
  })

function* loadFullComparison(lifecycleId, entityId, entityType) {
  yield put(setIsInventoryCompareAction(true))
  yield all([
    call(fetchLifecycleById, getLifecycleByIdSaga( lifecycleId )),
    call(getComparisonEntity, entityId, entityType)
  ])

  const lifecycle = yield select(LifecycleSelector.selectedLifecycle)
  const entity = yield select(ComparisonSelector.entityToCompare)
  yield call(mapCompareEntities, mapLifecycleEntity(lifecycle), entity)
  yield call(clearScenario)
}

function* loadOnlyLifecycle(lifecycleId) {
  yield call(fetchLifecycleById, getLifecycleByIdSaga(lifecycleId))
  const skipPhases = yield select(FlagsSelector.isSkipPhases)
  const lifecycle = yield select(LifecycleSelector.selectedLifecycle)
  const lifecycleFlatView = new FlatView(mapLifecycleEntity(lifecycle))
  const flatView = skipPhases
    ? lifecycleFlatView.flatViewSkipPhases()
    : lifecycleFlatView.flatViewWithPhases()
  yield put(setFlatViewAction(flatView))

  yield all([
    call(clearComparison),
    call(clearScenario)
  ])
}

function* loadFullLifecycleScenario(lifecycleId, lifecycleScenarioId) {
  yield put(setIsInventoryCompareAction(false))
  yield all([
    call(fetchLifecycleById, getLifecycleByIdSaga(lifecycleId)),
    call(getLifecycleScenarioFn, getLifecycleScenarioSaga(lifecycleScenarioId ))
  ])
  const lifecycle = yield select(LifecycleSelector.selectedLifecycle)
  const lifecycleScenario = yield select(LifecycleScenarioSelector.lifecycle)
  yield call(mapCompareEntities, mapLifecycleEntity(lifecycle), mapLifecycleEntity(lifecycleScenario))
}

function* reloadLifecycleScenario() {
  yield put(setIsInventoryCompareAction(false))
  const lifecycleScenarioId = yield select(LifecycleScenarioSelector.lifecycleId)
  yield call(getLifecycleScenarioFn, getLifecycleScenarioSaga(lifecycleScenarioId))
  const lifecycle = yield select(LifecycleSelector.selectedLifecycle)
  const lifecycleScenario = yield select(LifecycleScenarioSelector.lifecycle)
  yield call(mapCompareEntities, mapLifecycleEntity(lifecycle), mapLifecycleEntity(lifecycleScenario))
}

function* loadLifecycleItem(action) {
  try {
    const { lifecycleId, lifecycleScenarioId, entityId, entityType } = action.params
    if (isEmpty(lifecycleId)) return

    if (entityId && entityType) {
      yield call(loadFullComparison, lifecycleId, entityId, entityType)
    } else if (lifecycleScenarioId) {
      yield call(loadFullLifecycleScenario, lifecycleId, lifecycleScenarioId)
    } else {
      yield call(loadOnlyLifecycle, lifecycleId)
    }
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, lifecycleId: string}} action
 */
function* fetchLifecycleById(action) {
  yield put(setIsLoadingLeftTreeAction(true))
  try {
    const lifecycle = yield call(resource.queryByParams, 'lifecycle', { id: action.lifecycleId })

    yield put(setSelectedLifecycleAction(lifecycle))
    yield put(setInventoryLifecyclesAction(returnNested(lifecycle, 'product', 'lifecycles')))
    yield put(setInventoryRootItemIdAction(returnNested(lifecycle, 'product', 'id')))
    yield put(setIsShowInventoryAction(true))
    yield call(updateLifecycleExpandedState, lifecycle)
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingLeftTreeAction(false))
  }
}

export function* updateLifecycleExpandedState(lifecycle) {
  try {
    for (const phase of safeArray(lifecycle.phases)) {
      const inventory = returnNested(phase, 'inventory')
      const productId = returnNested(inventory, 'product', 'id')
      const expandedKeysMap = yield select(InventoryExpandedKeysSelector.expandedKeysMapByRootId, productId)
      yield put(setInventoryExpandedKeysAction(productId, getExpandedInventoryKeysMap(inventory, expandedKeysMap)))
    }
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* updateLifecyclesItems(action) {
  try {
    const isShowLifecycleScenarioInventory = yield select(FlagsSelector.isShowLifecycleScenarioInventory)
    const lifecycleId = yield select(LifecycleSelector.lifecycleId)
    if (isEmpty(lifecycleId)) return

    if (!isShowLifecycleScenarioInventory) {
      yield call(fetchLifecycleById, getLifecycleByIdSaga( lifecycleId ))

      const skipPhases = yield select(FlagsSelector.isSkipPhases)
      const lifecycle = yield select(LifecycleSelector.selectedLifecycle)

      const { entityId, entityType } = yield select(ComparisonSelector.entityIdAndTypeToCompare)
      if (entityId && entityType) {
        yield call(getComparisonEntity, entityId, entityType)
        const entity = yield select(ComparisonSelector.entityToCompare)
        yield call(mapCompareEntities, mapLifecycleEntity(lifecycle), entity)
      } else {
        const lifecycleFlatView = new FlatView(mapLifecycleEntity(lifecycle))
        const flatView = skipPhases
          ? lifecycleFlatView.flatViewSkipPhases()
          : lifecycleFlatView.flatViewWithPhases()
        yield put(setFlatViewAction(flatView))
      }
    } else {
      yield call(reloadLifecycleScenario)
    }

    if (action?.closePanel) {
      yield all([
        put(setSelectedInventoryItemKeyAction(null)),
        put(setSelectedProductAction(null)),
        put(setIsDetailsPanelOpenAction(false)),
        put(setSelectedPhaseAction(null))
      ])
    }
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{type: string, skipPhases: boolean}} action
 */
function* setIsLifecycleSkipPhases(action) {
  const { skipPhases, cb = () => {} } = action || {}
  try {
    yield call(setLifecycleFlatView, skipPhases)
    yield put(setIsSkipPhasesAction(skipPhases))
    cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* setLifecycleFlatView(skipPhases) {
  const isInventoryCompare = yield select(ComparisonSelector.isInventoryCompare)
  const isShowLifecycleScenarioInventory = yield select(FlagsSelector.isShowLifecycleScenarioInventory)
  const lifecycle = yield select(LifecycleSelector.selectedLifecycle)
  if (isInventoryCompare) {
    const entityA = mapLifecycleEntity(lifecycle)
    const entityB = yield select(ComparisonSelector.entityToCompare)
    const comparisonFlatView = new ComparisonFlatView(entityA, entityB)
    const flatView = skipPhases
      ? comparisonFlatView.flatViewWithoutPhases()
      : comparisonFlatView.flatViewWithPhases()
    yield put(setComparisonFlatViewAction(flatView))
  } else if (isShowLifecycleScenarioInventory) {
    const lifecycleScenario = yield select(LifecycleScenarioSelector.lifecycle)
    const entityA = mapLifecycleEntity(lifecycle)
    const entityB = mapLifecycleEntity(lifecycleScenario)
    const comparisonFlatView = new ComparisonFlatView(entityA, entityB)
    const flatView = skipPhases
      ? comparisonFlatView.flatViewWithoutPhases()
      : comparisonFlatView.flatViewWithPhases()
    yield put(setComparisonFlatViewAction(flatView))
  } else {
    const lifecycleFlatView = new FlatView(mapLifecycleEntity(lifecycle))
    const flatView = skipPhases
      ? lifecycleFlatView.flatViewSkipPhases()
      : lifecycleFlatView.flatViewWithPhases()
    yield put(setFlatViewAction(flatView))
  }
}

function* isSelectedLifecycleFn(lifecycleId) {
  const selectedLifecycleId = yield select(LifecycleSelector.lifecycleId)
  return selectedLifecycleId === lifecycleId
}

/**
 * @param {{productID: string, workspaceID: string, name: string, phases: [], [cb]: function}} action
 */
export function* createLifecycleItemHandler(action) {
  try {
    const lifecycle = yield call(resource.mutateByParams, 'createLifecycle', getArgsByAction(action))
    yield put(addLifecycleToInventoryAction(lifecycle))
    yield put(setIsCreateLifecycleAction(false))
    yield put(setIsOrderingPhasesAction(false))
    yield put(setIsCreateLifecycleDialogShowedAction(false))
    yield put(addSuccessNotificationAction('model.lifecycle_successfully_created'))
    action.cb && action.cb(returnNested(lifecycle, 'id'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* removeLifecycleItem(action) {
  try {
    yield call(resource.mutateByParams, 'removeLifecycle', { id: action.lifecycleId })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)

    isSelectedLifecycle
      ? yield put(removeLifecycleAction(action.lifecycleId))
      : yield put(removeLifecycleScenarioAction(action.lifecycleId))

    const lifecyclesPaginated = yield select(LifecycleSelector.lifecyclesPaginated)

    if (lifecyclesPaginated) {
      yield put(removeLifecycleFromPaginatedListAction(action.lifecycleId))
    }

    yield put(removeLifecycleFromInventoryAction(action.lifecycleId))
    yield put(addSuccessNotificationAction('model.lifecycle_successfully_removed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* renameLifecycleItem(action) {
  try {
    yield call(resource.mutateByParams, 'renameLifecycle', { id: action.lifecycleId, name: action.name })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)
    yield put(renameLifecyclesPaginatedAction({ lifecycleName: action.name, lifecycleId: action.lifecycleId }))
    isSelectedLifecycle
      ? yield put(renameLifecycleAction(action.name, action.lifecycleId))
      : yield put(renameLifecycleScenarioAction(action.name, action.lifecycleId))

    yield put(addSuccessNotificationAction('model.lifecycle_successfully_renamed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* renamePhaseItem(action) {
  try {
    const { id, name } = yield call(resource.mutateByParams, 'renamePhase', { id: action.id, name: action.name })
    yield put(renamePhaseAction(id, name))
    yield put(addSuccessNotificationAction('model.phase_successfully_renamed'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* createPhaseItem(action) {
  try {
    const createPhaseArgs = {
      lifecycleID: action.lifecycleId,
      name: action.name,
      type: action.phaseType
    }
    yield call(resource.mutateByParams, 'createPhase', createPhaseArgs)
    yield put(addSuccessNotificationAction('model.phase_successfully_created'))
    yield call(updateLifecyclesItems, { closePanel: true })
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* removePhaseItem(action) {
  try {
    yield call(resource.mutateByParams, 'removePhase', { id: action.id })
    yield put(removePhaseAction(action.id))
    yield call(updateLifecyclesItems)
    yield put(addSuccessNotificationAction('model.phase_successfully_removed'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* reorderPhaseItems(action) {
  const rollback = put(reorderPhasesAction(action.lifecycleId, action.oldPhaseIds))
  try {
    yield put(reorderPhasesAction(action.lifecycleId, action.phaseIds))
    const response =  yield call(resource.mutateByParams, 'reorderPhases', { lifecycleID: action.lifecycleId, phases: action.phaseIds })
    if ( response !== true) {
      yield rollback
    }
  } catch (error) {
    yield call(dispatchErrors, error)
    yield rollback
  }
}

export function* selectPhase(action) {
  yield put(setDetailsPanelLifecycleIdAction(null))
  yield put(setSelectedInventoryItemKeyAction(null))
  yield put(setSelectedProductAction(null))
  yield put(setIsDetailsPanelOpenAction(true))
  yield put(setSelectedPhaseAction(action.phase))
}

function* copyLifecycleItem(action) {
  try {
    const lifecycleCopy = yield call(resource.mutateByParams, 'copyLifecycle', { lifecycleID: action.lifecycleId })
    yield put(addSuccessNotificationAction('model.lifecycle_copied'))
    action.cb && action.cb(returnNested(lifecycleCopy, 'id'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* setLifecycleDescriptionHandler(action) {
  try {
    yield call(resource.mutateByParams, 'changeLifecycleDescription', { id: action.lifecycleId, description: action.description })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)
    isSelectedLifecycle
      ? yield put(setLifecycleDescriptionAction(action.description, action.lifecycleId))
      : yield put(setLifecycleScenarioDescriptionAction(action.description, action.lifecycleId))

    yield put(setDescriptionForLifecyclesPaginatedListItemAction({
      lifecycleDescription: action.description, lifecycleId: action.lifecycleId
    }))
    yield put(addSuccessNotificationAction('model.lifecycle_description_successfully_changed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* setLifecycleAmount(action) {
  try {
    yield call(resource.mutateByParams, 'changeLifecycleAmount', { id: action.lifecycleId, amount: action.amount })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)
    isSelectedLifecycle
      ? yield put(setLifecycleAmountAction(action.amount, action.lifecycleId))
      : yield put(setLifecycleScenarioAmountAction(action.amount, action.lifecycleId))

    yield put(addSuccessNotificationAction('model.lifecycle_amount_successfully_changed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* setLifecycleUnit(action) {
  try {
    yield call(resource.mutateByParams, 'changeLifecycleUnit', { id: action.lifecycleId, unit: action.unit })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)
    isSelectedLifecycle
      ? yield put(setLifecycleUnitAction(action.unit, action.lifecycleId))
      : yield put(setLifecycleScenarioUnitAction(action.unit, action.lifecycleId))

    yield put(addSuccessNotificationAction('model.lifecycle_unit_successfully_changed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* setLifecycleDetailsPanel(action) {
  try {
    yield put(setDetailsPanelLifecycleIdAction(action.lifecycleId))
    yield put(setSelectedProductAction(null))
    yield put(setSelectedInventoryItemKeyAction(null))
    yield put(setSelectedPhaseAction(null))
    yield put(setIsCreatePhaseAction(false))
    yield put(setIsForcingWorkspacePanelAction(false))
    yield put(setIsDetailsPanelOpenAction(true))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* exportLifecycleImpactHandler(action) {
  try {
    const timezone = browserTimezone()
    const requestLifecycleExportArgs = { lifecycleID: action.lifecycleId, timezone }
    yield call(resource.mutateByParams, 'requestLifecycleExport', requestLifecycleExportArgs)
    yield put(addSuccessNotificationAction('model.export_impact_successfully_requested'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* changePhaseTypeHandler(action) {
  try {
    yield call(resource.mutateByParams, 'changePhaseType', { id: action.id, type: action.phaseType })
    yield put(setPhaseTypeAction(action.id, action.phaseType))
    yield put(addSuccessNotificationAction('model.phase_type_successfully_changed'))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* getLifecyclesPaginatedHandler(action) {
  try {
    const queryArgs = getArgsByAction(action)
    queryArgs.sortBy = { field: 'updated', order: SORT_ORDER.DESCENDING }
    const lifecycles = yield call(resource.queryByParams, 'lifecyclesPaginated', queryArgs)
    yield put(setLifecyclesPaginatedAction(lifecycles))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* changeLifecycleProduct(action) {
  try {
    const lifecycle = yield call(resource.mutateByParams, 'changeLifecycleProduct', { id: action.lifecycleId, productID: action.productId })
    const isSelectedLifecycle = yield isSelectedLifecycleFn(action.lifecycleId)
    isSelectedLifecycle
      ? yield put(setLifecycleProductAction(lifecycle.product, action.lifecycleId))
      : yield put(setLifecycleScenarioProductAction(lifecycle.product, action.lifecycleId))

    yield put(addSuccessNotificationAction('model.lifecycle_product_successfully_changed'))
    action.cb && action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export default function* lifecycleSaga() {
  yield takeLatest(LOAD_LIFECYCLE_PAGE, loadLifecycleItem)
  yield takeLatest(GET_LIFECYCLE_BY_ID, fetchLifecycleById)
  yield takeLatest(CREATE_LIFECYCLE, createLifecycleItemHandler)
  yield takeLatest(SELECT_PHASE, selectPhase)
  yield takeLatest(UPDATE_LIFECYCLES_ITEMS, updateLifecyclesItems)
  yield takeLatest(SET_IS_LIFECYCLE_SKIP_PHASES, setIsLifecycleSkipPhases)
  yield takeLatest(REMOVE_LIFECYCLE, removeLifecycleItem)
  yield takeLatest(RENAME_LIFECYCLE, renameLifecycleItem)
  yield takeLatest(CREATE_PHASE, createPhaseItem)
  yield takeLatest(RENAME_PHASE, renamePhaseItem)
  yield takeLatest(REMOVE_PHASE, removePhaseItem)
  yield takeLatest(CHANGE_PHASE_TYPE, changePhaseTypeHandler)
  yield takeLatest(REORDER_PHASES, reorderPhaseItems)
  yield takeLatest(COPY_LIFECYCLE, copyLifecycleItem)
  yield takeLatest(SET_LIFECYCLE_DESCRIPTION, setLifecycleDescriptionHandler)
  yield takeLatest(SET_LIFECYCLE_AMOUNT, setLifecycleAmount)
  yield takeLatest(SET_LIFECYCLE_UNIT, setLifecycleUnit)
  yield takeLatest(SET_LIFECYCLE_DETAILS_PANEL, setLifecycleDetailsPanel)
  yield takeLatest(EXPORT_LIFECYCLE_IMPACTS, exportLifecycleImpactHandler)
  yield takeLatest(GET_LIFECYCLES_PAGINATED, getLifecyclesPaginatedHandler)
  yield takeLatest(CHANGE_LIFECYCLE_PRODUCT, changeLifecycleProduct)
}
