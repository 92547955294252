import { connect } from 'react-redux'
import SpaceSelector from '../../../../redux/selectors/space.selector'
import InventoryTotalImpact from './inventoryTotalImpact'

const mapStateToProps = state => {
  return {
    impactCategory: SpaceSelector.impactCategory(state),
    impactUseMethodTotal: SpaceSelector.impactUseMethodTotal(state),
  }
}

export default connect(
  mapStateToProps,
  null
)(InventoryTotalImpact)

