import { store } from '../configureStore'
import { enableNewScenarioV1Var } from '../graphql/cache'
import { updateSelectedInventoryItemKeyAction } from '../redux/actions/global.actions'
import resource from './resource/resource'
import { returnNested } from './tools'

export class ProductScenarioMutations {
  _inventoryRootId
  _inventoryNodeId
  _productId

  constructor(inventoryRootId, inventoryNodeId, productId) {
    this._inventoryRootId = inventoryRootId
    this._inventoryNodeId = inventoryNodeId
    this._productId = productId
  }

  callMutation = (mutationName, mutationArgs) => new Promise((resolve, reject) => {
    const performScenarioActionArgs = {
      action: mutationName,
      args: JSON.stringify(mutationArgs),
      inventoryNodeID: this._inventoryNodeId,
      productID: this._productId,
      scenarioProductID: this._inventoryRootId,
    }
    resource.mutateByParams('performScenarioAction', performScenarioActionArgs).then(product => {
      !enableNewScenarioV1Var() && store.dispatch(updateSelectedInventoryItemKeyAction(returnNested(product, 'id')))
      return resolve(product)
    })
      .catch(err => reject(err))
  })
}

export class ProductDetailsMutations {
  callMutation = resource.mutateByParams
}
