import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react'
import { Chart } from 'primereact/chart'
import { useTranslation } from 'react-i18next'

import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'

import { TTransformMultiLeafItem } from '../../../../shared/util/transform'
import { ComparisonEntity } from '../../../enum/entity'
import { getDecimalDisplayValue } from '../../../../shared/util/decimal'
import {
  getOrCreateTooltip, setTooltipBody, setTooltipHeader, setTooltipStyle
} from '../../../../shared/util/chart/chart-tooltip'

import { DecimalViewType } from '../../../../shared/enum'
import { StackedChartLegendLayout } from './stacked-chart-legend.component'
import { StackChartHeaderComponent } from './stacked-chart-header.component'

export const StackedChartComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const measureRef = useRef<HTMLDivElement>(null)
  const primaryEntityHeaderRef = useRef<HTMLDivElement>(null)
  const secondaryEntityHeaderRef = useRef<HTMLDivElement>(null)
  const [ stackedChartData, setStackedChartData ] = useState({})
  const {
    primaryEntityName, secondaryEntityName,
    primaryTotalImpact, secondaryTotalImpact,
    analysisMode, multiLeafItems
  } = useContext<TAnalysisContext>(AnalysisContext)

  const comparisonLeafItems = useMemo(
    () => {
      const transformedLeafItems: TTransformMultiLeafItem[] = []
      multiLeafItems?.forEach(item => {
        const { impactAmount: primaryImpactAmount } = item.primaryLeafItem || {}
        const { impactAmount: secondaryImpactAmount } = item.secondaryLeafItem || {}

        const fPrimaryImpactAmount = parseFloat(primaryImpactAmount || '')
        const fSecondaryImpactAmount = parseFloat(secondaryImpactAmount || '')

        if (fPrimaryImpactAmount !== 0 && fSecondaryImpactAmount !== 0) {
          transformedLeafItems.push(item)
        } else if (fPrimaryImpactAmount === 0 && fSecondaryImpactAmount !== 0) {
          transformedLeafItems.push({ ...item, primaryLeafItem: {} })
        } else if (fSecondaryImpactAmount === 0 && fPrimaryImpactAmount !== 0) {
          transformedLeafItems.push({ ...item, secondaryLeafItem: {} })
        }
      })

      return transformedLeafItems.sort((leafItemA, leafItemB) => {
        const { primaryLeafItem: { impactAmount: aImpactAmount } = {} } = leafItemA
        const { primaryLeafItem: { impactAmount: bImpactAmount } = {} } = leafItemB
        if (!aImpactAmount || !bImpactAmount) return 0

        const aFloatImpactAmount = parseFloat(aImpactAmount)
        const bFloatImpactAmount = parseFloat(bImpactAmount)

        if (aFloatImpactAmount === bFloatImpactAmount) return 0

        const oneIsPositive = aFloatImpactAmount > 0 || bFloatImpactAmount > 0
        if (aFloatImpactAmount < bFloatImpactAmount) {
          return oneIsPositive ? 1 : -1
        }
        return oneIsPositive ? -1 : 1
      })
    },
    [ multiLeafItems, primaryTotalImpact?.unit, secondaryTotalImpact?.unit ]
  )

  useEffect(() => {
    if (comparisonLeafItems?.length > 0 && secondaryEntityName && primaryEntityName) {
      const chartData = getChartData()
      setStackedChartData(chartData)
    }
  }, [ comparisonLeafItems, primaryEntityName, secondaryEntityName ])

  const positiveColor = {
    uniqueItem: '#c5e0f4',
    commonItem: '#0c7dd0',
    set: [
      '#053253', '#074572', '#085892',
      '#0a6ab1', '#0c7dd0', '#3a96d9',
      '#68aee2', '#97c7eb'
    ]
  }
  const negativeColor = {
    uniqueItem: '#b6e89c',
    commonItem: '#96de70'
  }
  const maxChartItems = 8

  const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context
    const tooltipElement = getOrCreateTooltip(chart)
    const table = tooltipElement.querySelector('table')

    if (tooltipElement && tooltip.opacity === 0) {
      tooltipElement.style.opacity = '0'
      return
    }

    if (table && tooltip.body) {
      const dataset = tooltip?.dataPoints[0].dataset
      const backgroundColors = dataset.backgroundColor
      const { data } = dataset

      while (table?.firstChild) {
        table.firstChild.remove()
      }
      setTooltipHeader({ table, title: dataset.label })
      data.forEach((value: any, index: number) => {
        if (value) {
          const [ coefficient, exponent ] = getDecimalDisplayValue({ value, decimalViewType: DecimalViewType.NumericValue })
          const prefixLabel = index + 1 === 1
            ? t('labels.original', { ns: 'common' })
            : t('labels.product', { context: analysisMode.toLowerCase() })
          setTooltipBody({
            table,
            totalImpactUnit: primaryTotalImpact?.unit,
            coefficient: coefficient?.toString(),
            exponent: exponent?.toString(),
            prefix: `${prefixLabel} : `,
            backgroundColor: backgroundColors[index],
            borderColor: 'white',
            colClassNames: 'white-space-nowrap'
          })
        }
      })
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
    setTooltipStyle({
      tooltipElement, context, positionX: positionX + tooltip.caretX, positionY: positionY + tooltip.caretY
    })
  }

  const stackedOptions = {
    aspectRatio: 1.3,
    maintainAspectRatio: true,
    borderColor: 'black',
    offset: false,
    layout: {
      padding: {
        top: 80, right: 20, bottom: 20, left: 50
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        events: [ 'mousemove' ]
      },
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: { display: false },
        grid: { color: 'white' },
        border: { color: 'white' }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: '#999'
        },
        grid: { color: 'white' },
        border: { color: 'white' }
      },
    },
  }

  const getBarBackgroundColor = (
    leafItem: TTransformMultiLeafItem,
    index: number,
    entity: ComparisonEntity = ComparisonEntity.Primary
  ) => {
    const item = entity === ComparisonEntity.Primary ? leafItem.primaryLeafItem : leafItem.secondaryLeafItem
    const isPositive = (item?.impactAmount && parseFloat(item?.impactAmount) > 0)
    if (!isPositive) {
      const { uniqueItem, commonItem } = negativeColor
      return leafItem.commonItem ? commonItem : uniqueItem
    }
    const {
      uniqueItem, commonItem, set = []
    } = positiveColor
    const countCommonItem = comparisonLeafItems.filter((item: TTransformMultiLeafItem) => item.commonItem).length
    if (countCommonItem >= maxChartItems && leafItem.commonItem) {
      return leafItem.commonItem ? commonItem : uniqueItem
    }

    return leafItem.commonItem && index < set.length ? set[index] : uniqueItem
  }

  const castLeafItemToChartItem = (leafItem: TTransformMultiLeafItem, index: number) => {
    const { impactAmount: primaryImpactAmount } = leafItem.primaryLeafItem || {}
    const { impactAmount: secondaryImpactAmount } = leafItem.secondaryLeafItem || {}

    const fPrimaryImpactAmount = primaryImpactAmount && parseFloat(primaryImpactAmount)
    const fSecondaryImpactAmount = secondaryImpactAmount && parseFloat(secondaryImpactAmount)

    const data = [ fPrimaryImpactAmount, fSecondaryImpactAmount ]
    const backgroundColor = [
      getBarBackgroundColor(leafItem, index, ComparisonEntity.Primary),
      getBarBackgroundColor(leafItem, index, ComparisonEntity.Secondary)
    ]

    return {
      type: 'bar',
      label: leafItem.name,
      borderWidth: 1,
      borderColor: 'white',
      borderRadius: {
        topLeft: 5,
        topRight: 5,
        bottomLeft: 5,
        bottomRight: 5
      },
      borderSkipped: false,
      minBarLength: 5,
      backgroundColor,
      data
    }
  }

  const getChartData = () => {
    const datasets = [
      ...comparisonLeafItems.filter((leafItem: TTransformMultiLeafItem) => leafItem.commonItem)
        .map((leafItem: TTransformMultiLeafItem, index: number) => castLeafItemToChartItem(leafItem, index)),
      ...comparisonLeafItems.filter((leafItem: TTransformMultiLeafItem) => !leafItem.commonItem)
        .map((leafItem: TTransformMultiLeafItem, index: number) => castLeafItemToChartItem(leafItem, index))
    ]

    return { labels: [ primaryEntityName, secondaryEntityName ], datasets }
  }

  const afterDraw = (chart: any) => {
    if (measureRef.current && primaryEntityHeaderRef?.current && secondaryEntityHeaderRef?.current) {
      const { ctx } = chart
      const meta = chart.getDatasetMeta(0)
      const barPrimaryWidth = meta.data[0]?.width

      const primaryXCoordinate = meta.data[0]?.x
      const secondaryXCoordinate = meta.data[1]?.x

      primaryEntityHeaderRef.current.style.width = `${barPrimaryWidth}px`
      primaryEntityHeaderRef.current.style.left = `${primaryXCoordinate - barPrimaryWidth / 2}px`

      secondaryEntityHeaderRef.current.style.width = `${barPrimaryWidth}px`
      secondaryEntityHeaderRef.current.style.left = `${secondaryXCoordinate - barPrimaryWidth / 2}px`

      ctx.save()
      ctx.font = '400 16px Rubik'
      ctx.fillStyle = '#999999'
      ctx.rotate(-90 * Math.PI / 180)
      ctx.fillText(t('labels.impact', { context: 'inUnit', unit: primaryTotalImpact?.unit, ns: 'common' }), -360, 35)

      ctx.restore()
    }
  }

  return (
    <div className="flex md:w-full lg:w-11 xl:w-9">
      <div data-testid="stacked-cart-wrapper" ref={measureRef} className="md:w-9 relative">
        <div ref={primaryEntityHeaderRef} className="absolute z-1">
          <StackChartHeaderComponent entity={ComparisonEntity.Primary} />
        </div>

        <div ref={secondaryEntityHeaderRef} className="absolute z-1">
          <StackChartHeaderComponent entity={ComparisonEntity.Secondary} />
        </div>

        <Chart type="bar" data={stackedChartData} options={stackedOptions} plugins={[ { afterDraw } ]} />
      </div>
      <div className="md:w-3 flex align-items-center justify-content-center flex-column">
        <StackedChartLegendLayout maxChartItems={maxChartItems} chartItems={comparisonLeafItems} />
      </div>
    </div>
  )
}
