import React from 'react'
import classnames from 'classnames'
import { Form, Input, InputNumber } from 'antd'
import { keyboardHandler, returnNested } from '../../../utils/tools'
import SingleFieldText from './singleFieldText'
const { TextArea } = Input

class InputWrapper extends React.PureComponent {
  getText = () => {
    const {
      dataCy,
      editHandler,
      disabled,
      displayedText
    } = this.props

    return (
      <SingleFieldText
        displayedText={displayedText}
        editHandler={editHandler}
        disabled={disabled}
        dataCy={ dataCy}
      />
    )
  }

  getInput = () => {
    const {
      autoFocus = true,
      dataCy,
      className,
      disabled,
      onPressEnter,
      onPressEscape,
      onBlur,
      onChange,
      value,
      min,
      max,
      type,
      placeholder,
    } = this.props
    const commonProps = {
      autoFocus,
      'data-cy': `${dataCy}-input`,
      className: classnames(`${dataCy}-input full-width`, { className }),
      disabled,
      onPressEnter,
      onBlur,
      onKeyUp: evt => this.handleEscapeKey(evt, onPressEscape),
      onChange,
      value,
      placeholder
    }

    switch (type) {
    case 'number':
      return <InputNumber {...commonProps} min={min} max={max} />
    case 'textarea':
      return <TextArea {...commonProps} rows={4} />
    default:
      return <Input {...commonProps} type={type} />
    }
  }

  handleEscapeKey = (evt, cb) => {
    keyboardHandler(returnNested(evt, 'key'), 'escape', cb)
  }

  render() {
    const {
      isFieldEditable,
      fieldLabel,
      fieldName,
      fieldRules,
      fieldNormalize,
      dependencies
    } = this.props

    return (
      <Form.Item
        className="field-container input-wrapper"
        name={fieldName}
        label={ fieldLabel }
        rules={fieldRules}
        normalize={fieldNormalize}
        dependencies={dependencies}
      >
        {isFieldEditable ? this.getInput() : this.getText()}
      </Form.Item>
    )
  }
}

export default InputWrapper
