import { string, bool } from 'yup'
import Location from 'react-app-location'
import { NAV } from '../../utils/const'
// Tab keys
export const
  // Inventory tabs
  tabTreeView = 'tab-tree-view',
  tabFlatView = 'tab-flat-view',
  tabChartView = 'tab-chart-view',
  tabSunburst = 'tab-sunburst',

  // Account tabs
  tabUsers = 'userManagement',
  tabAccount = 'accountManagement',
  tabRoles = 'roleManagement',
  tabPermissions = 'permissionManagement',
  tabApiClients = 'apiClientManagement',
  tabBilling = 'billingManagement'

// CONSTANTS
const inventoryActiveKey = {
  activeTabKey: string().oneOf([ tabTreeView, tabFlatView, tabChartView, tabSunburst ]).default(tabTreeView).required(),
}

const comparable = {
  compareEntityId: string().nullable(),
  compareEntityType: string().nullable(),
}

// COMMON FUNCTIONS
export const buildFullUrl = (location, params )  => {
  const path = location.toUrl(params)
  const port = window.location.port !== '' ? `:${window.location.port}` : ''
  return `${window.location.protocol}//${window.location.hostname}${port}${path}`
}

export const downloadFileFromUrl = filePath  => {
  const downloadUrl = buildFullUrl(RootLocation) + filePath
  window.location.assign(downloadUrl)
}

// PRODUCT LOCATIONS
export const InventoryPageLocation = new Location(
  `${ NAV.ACCOUNT }:accountId${ NAV.WORKSPACE }:workspaceSlug${ NAV.PRODUCT }:productId${ NAV.INVENTORY }`,
  {
    accountId: string().required(),
    workspaceSlug: string().required(),
    productId: string().required()
  },
  {
    productScenarioId: string(),
    ...inventoryActiveKey,
    ...comparable
  }
)

export const ProductListPageLocation = new Location(
  `${NAV.ACCOUNT}:accountId${NAV.WORKSPACE}:workspaceSlug${NAV.PRODUCTS}`,
  {
    accountId: string().required(),
    workspaceSlug: string().required()
  },
  {
    folderId: string().nullable()
  }
)

export const ObjectListPageLocation = new Location(
  `${NAV.ACCOUNT}:accountId${NAV.WORKSPACE}:workspaceSlug${NAV.OBJECTS}`,
  {
    accountId: string().required(),
    workspaceSlug: string().required()
  },
  {
    folderId: string().nullable()
  }
)

// LIFECYCLE LOCATIONS
export const LifecycleItemLocation = new Location(
  `${ NAV.ACCOUNT }:accountId${ NAV.WORKSPACE }:workspaceSlug${ NAV.LIFECYCLE }:lifecycleId${ NAV.INVENTORY }`,
  {
    accountId: string().required(),
    workspaceSlug: string().required(),
    lifecycleId: string().required()
  },
  {
    lifecycleScenarioId: string(),
    ...inventoryActiveKey,
    ...comparable
  }
)

export const LifecycleListPageLocation = new Location(
  `${NAV.ACCOUNT}:accountId${NAV.WORKSPACE}:workspaceSlug${NAV.LIFECYCLES}`,
  {
    accountId: string().required(),
    workspaceSlug: string().required()
  }
)

// ACCOUNT/USER LOCATIONS
export const AccountSettingPageLocation = new Location(
  `${NAV.ACCOUNTSETTINGS}`,
  {},
  {
    activeTabKey: string().oneOf([ tabUsers, tabAccount, tabRoles, tabPermissions, tabApiClients, tabBilling ]),
    stripe_success: bool(),
    stripe_message: string(),
    session_id: string()
  }
)

export const UserProfilePageLocation = new Location(
  `${NAV.USERPROFILE}`
)

export const AccountPageLocation = new Location(
  `${NAV.ACCOUNT}:accountId`,
  {
    accountId: string().required()
  }
)

// WORKSPACE LOCATIONS
export const WorkspacePageLocation = new Location(
  `${NAV.ACCOUNT}:accountId${NAV.WORKSPACE}:workspaceSlug`,
  {
    accountId: string().required(),
    workspaceSlug: string().required()
  }
)

export const RootLocation = new Location(
  `${NAV.ROOT}`
)

export const LogoutLocation = new Location(
  `${NAV.LOGOUT}`
)
