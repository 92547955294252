import React from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'
import { TooltipOverflowContent, useRefOverflow } from '../../../../shared/hook/use-ref-overflow'

const StackedChartLegendIcon = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
`

type StackedChartLegendItemComponentProps = {
  index: number | string,
  colorClassName: string,
  name?: string
}
export const StackedChartLegendItemComponent = ({
  index, name,
  colorClassName
}: StackedChartLegendItemComponentProps) => {
  const [ nameRef, hasNameOverflow, nameDom ] = useRefOverflow()

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      { name }
    </TooltipOverflowContent>
  )
  const iconTestIdentifier = `stacked-chart-legend-icon-${index}`
  const itemTestIdentifier = `stacked-chart-legend-item-${index}`
  return (
    <>
      <Tooltip target={nameDom} onBeforeShow={() => hasNameOverflow} position="bottom">{getTooltipContent()}</Tooltip>
      <div className="white-space-nowrap min-w-min">
        <StackedChartLegendIcon
          data-testid={iconTestIdentifier}
          data-cy={iconTestIdentifier}
          className={`doughnut-legend-icon ${colorClassName}`}
        />
      </div>
      <div
        ref={nameRef}
        data-testid={itemTestIdentifier}
        data-cy={itemTestIdentifier}
        className="flex-grow-1 py-1 white-space-nowrap overflow-hidden text-overflow-ellipsis"
      >
        { name }
      </div>
    </>
  )
}
