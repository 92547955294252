import { gql } from '@apollo/client'

export default gql`
  query leafInventoryItems($productID: ID!, $skipPhases: Boolean){
    leafInventoryItems(productID: $productID, skipPhases: $skipPhases) {
      amount
      impact {
        amount
        unit
        status
      }
      product {
        id
        name
        unit
        type
      }
      phase{
        id
        name
        order
      }
    }
  }
`
