import React, { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faTrash, faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons'

import { MenuAction } from '../../enum/menu-action'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TInventoryTreeItem } from '../../../shared/util/transform'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Navigation } from '../../../shared/enum'
import { TLifecycleDetailContext } from '../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../lifecycle/provider/context/lifecycle-detail.context'

const MenuTreeItemContainerWrapper = styled.div`
  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .btn-menu-item {
    color: var(--gray-300);
    border-color: var(--gray-300);

    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
`

type MenuTreeItemContainerProps = {
  node: TInventoryTreeItem
  onNodeClick?: Function,
  onAddSubitem?: Function
}
export const MenuTreeItemContainer = ({ node, onNodeClick, onAddSubitem = () => {} }: MenuTreeItemContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const navigate = useNavigate()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const toast = useRef<Toast>(null)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { lifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    productInventory,
    updateProductDetail,
    setLifecycleSelectedKeyAndPhase = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const menu = useRef<Menu>(null)

  const onMouseEnter = (event: any) => {
    event.stopPropagation()
    menu.current?.show(event)
  }
  const onMouseLeave = (event: any) => {
    event.stopPropagation()
    menu.current?.hide(event)
  }

  const executeCommand = (event: any) => {
    event.originalEvent.stopPropagation()
    const { action: selectedAction } = event.item
    const { key, data: { inventoryItem = null } = {} } = node
    const { product: { id = null } = {} } = inventoryItem || {}
    const backLabel = lifecycleDetail ? lifecycleDetail.name : productInventory?.product?.name
    setLifecycleSelectedKeyAndPhase(key)

    switch (selectedAction) {
    case MenuAction.DeleteTreeItem:
      updateProductDetail({ showDeleteTreeItem: true, selectedNode: node, selectedAction })
      break
    case MenuAction.OpenTreeItem:
      backLabel && addToBackToList(backLabel)
      updateProductDetail({ hasInventoryChanged: true })
      account?.id && space?.slug && id && navigate(generatePath(Navigation.ProductObjectInventory, {
        accountId: account?.id,
        workspaceSlug: space?.slug,
        productId: id
      }))
      break
    case MenuAction.OpenProductDetail:
      onNodeClick && onNodeClick({ ...event, node })
      updateProductDetail({ selectedKey: key })
      break
    default:
      break
    }
  }

  const items = [
    {
      action: MenuAction.OpenProductDetail,
      label: t('actions.open', { context: 'detailPanel', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faCircleInfo} className="mr-1" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.OpenTreeItem,
      label: t('actions.open', { context: 'item', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faArrowUpRight} className="text-sm mr-1" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.DeleteTreeItem,
      label: t('actions.delete', { context: 'item', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faTrash} className="text-sm mr-1" />,
      textColor: '--red-500',
      command: executeCommand,
      template: MenuItemComponent
    }
  ]

  return (
    <>
      <MenuTreeItemContainerWrapper className="flex flex-none w-full gap-2 justify-content-end">
        <Menu model={items} popup ref={menu} id="popup_menu" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />

        <Button
          data-testid="btn-add-subItem"
          icon="pi pi-plus"
          className="p-button-outlined btn-menu-item"
          data-cy="add-subitem-action"
          tooltip={t('actions.add', { context: 'subitem', ns: 'product' })}
          tooltipOptions={{ position: 'bottom' }}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => onAddSubitem(event)}
        />

        <Button
          icon={<FontAwesomeIcon icon={faEllipsis} className="text-lg" />}
          data-testid="card-menu-more"
          data-cy="card-menu-button"
          onClick={(event: any) => event.stopPropagation()}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="p-button-outlined btn-menu-item"
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Toast data-testid="card-menu-status" ref={toast} position="top-right" />
      </MenuTreeItemContainerWrapper>
    </>
  )
}
