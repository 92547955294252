export type VideoItem = {
  title: string,
  duration: string,
  path: string,
  thumbnail: string,
}

export const videoTutorials: VideoItem[] = [
  {
    title: 'How to model your first product',
    duration: '5:18',
    path: 'https://www.youtube.com/embed/Grj2xo-BJYc?si=CJYDkq9BbbC1byG6',
    thumbnail: '/assets/images/video-tutorials-thumbnails/creating-first-product.png',
  },
  {
    title: 'How to add impact',
    duration: '4:18',
    path: 'https://www.youtube.com/embed/5KRZlJcKBVw?si=s3Stsdtc4NO1cKEE',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-add-impact.png',
  },
  {
    title: 'How to use properties',
    duration: '5:27',
    path: 'https://www.youtube.com/embed/qRDScxN5K1A?si=6HaKX1P4cRIcjCT_',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-use-properties.png',
  },
  {
    title: 'Workspace settings',
    duration: '4:39',
    path: 'https://www.youtube.com/embed/HkMmAqms4V8?si=UgeXKteaJRUQ7Hcl',
    thumbnail: '/assets/images/video-tutorials-thumbnails/workspace-settings.png',
  },
  {
    title: 'Reusing objects',
    duration: '3:38',
    path: 'https://www.youtube.com/embed/VAl9RvULefk?si=RMblYfMu-XhevSa8',
    thumbnail: '/assets/images/video-tutorials-thumbnails/reusing-objects.png',
  },

  {
    title: 'Step-by-step guide to adding impact',
    duration: '5:38',
    path: 'https://www.youtube.com/embed/OeEY5lWNKZ8?si=OxQ42mS9EpUVCL80',
    thumbnail: '/assets/images/video-tutorials-thumbnails/adding-impact-step-by-step.png',
  },
  {
    title: 'Selecting the right impact dataset',
    duration: '4:56',
    path: 'https://www.youtube.com/embed/YiJ618xC680?si=SnRJu-lRbmLt_6br',
    thumbnail: '/assets/images/video-tutorials-thumbnails/selecting-right-impact-dataset.png',
  },
  {
    title: 'Modeling materials with multiple datasets',
    duration: '3:03',
    path: 'https://www.youtube.com/embed/Oh942A8SZNo?si=PreNScsIKBxqyHWQ',
    thumbnail: '/assets/images/video-tutorials-thumbnails/modeling-materials-with-multiple.png',
  },
  {
    title: 'How to model plastics with Ecoinvent',
    duration: '4:53',
    path: 'https://www.youtube.com/embed/FtBt4KlptZE?si=C4en6tw_WKcpx1B9',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-model-plastic.png',
  },
  {
    title: 'How to model metals with Ecoinvent',
    duration: '4:53',
    path: 'https://www.youtube.com/embed/LwZxKU6hx38?si=trcZt4P5EhhVwAo5',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-model-metals.png',
  },
  {
    title: 'Searching electricity datasets in Ecoinvent',
    duration: '5:22',
    path: 'https://www.youtube.com/embed/mOd2AH_SBj4?si=i1ITrM8EVga1TxSq',
    thumbnail: '/assets/images/video-tutorials-thumbnails/searching-electricity-datasets.png',
  },
  {
    title: 'How to select transport datasets',
    duration: '5:38',
    path: 'https://www.youtube.com/embed/b5JQ-Is-Nug?si=OjATIU5zjyDgtNrz',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-select-transport-datasets.png',
  },
  {
    title: 'How to model transport',
    duration: '4:15',
    path: 'https://www.youtube.com/embed/E0AGSlGoq-E?si=UWo5BPp1uPsOz_L4',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-model-transport.png',
  },
  {
    title: 'How to add transport to materials',
    duration: '2:47',
    path: 'https://www.youtube.com/embed/-JKfvyw5R_w?si=lxVfxQYf3NKntObI',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-add-transport.png',
  },

  {
    title: 'Adding supplier data (Custom impact)',
    duration: '5:00',
    path: 'https://www.youtube.com/embed/nOeJHDuexHE?si=qXONZt8VL6Jtw-kb',
    thumbnail: '/assets/images/video-tutorials-thumbnails/adding-supplier-data.png',
  },
  {
    title: 'Using Modules',
    duration: '4:57',
    path: 'https://www.youtube.com/embed/gFEO-Df3kjg?si=JskShmRlU699WLGq',
    thumbnail: '/assets/images/video-tutorials-thumbnails/how-to-use-modules.png',
  },
  {
    title: 'Ratio modeling with open item',
    duration: '2:57',
    path: 'https://www.youtube.com/embed/R5daucb_vKo?si=J6ZaHqMWwrQkBoIL',
    thumbnail: '/assets/images/video-tutorials-thumbnails/ratio-modeling.png',
  }
]
