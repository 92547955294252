import React from 'react'
import compose from '../../../../utils/compose'
import { withRouter } from '../../../../utils/with-router'
import { withTranslation } from 'react-i18next'
import { Space } from 'antd'
import WorkspaceDetailInformation from './workspace-detail-information/workspaceDetailInformation'
import WorkspaceImpactInformation from './workspace-impact-information/workspaceImpactInformation.container'
import WorkspaceUserPermissions from './workspace-user-permissions/workspaceUserPermissions.container'
import DetailsPanelTitle from '../detailsPanelTitle'
import { safeArray } from '../../../../utils/tools'
import { POLICY } from '../../../../utils/const'

const WorkspaceDetails = ({
  selectedSpace,
  addSpacePermission,
  removeSpacePermission,
  changeSpacePermission,
  updatePageItems,
  selectedSpacePolicies,
  renameWorkspaceSaga
}) => {

  const isSpaceManager = safeArray(selectedSpacePolicies).includes(POLICY.SPACE_MANAGEMENT)

  return (
    <div className="details-panel-wrapper workspace-details" data-cy="workspace-details">
      <DetailsPanelTitle title={selectedSpace?.name} className="workspace-title" data-cy="selected-workspace-name">
        <Space />
      </DetailsPanelTitle>
      <div className='details-panel-body'>
        <WorkspaceDetailInformation
          renameHandler={ renameWorkspaceSaga }
          selected={ selectedSpace }
          key="workspace-name" />

        <WorkspaceImpactInformation
          selectedSpace={selectedSpace}
          updatePageItems={updatePageItems}
          key="impact-settings" />

        { isSpaceManager &&
          <WorkspaceUserPermissions
            addRoleHandler={addSpacePermission}
            removeRoleHandler={removeSpacePermission}
            changeRolePermissionHandler={changeSpacePermission}
            selected={selectedSpace}
            type='space'
            key="workspace-permissions" />
        }
      </div>
    </div>
  )
}

export { WorkspaceDetails }
export default compose(
  withRouter,
  withTranslation(),
)(WorkspaceDetails)
