import { connect } from 'react-redux'
import FlagsSelector from '../../redux/selectors/flags.selector'
import GlobalSelector from '../../redux/selectors/global.selector'
import { setProductImportModalVisibilityAction } from '../../redux/actions/global.actions'
import { setSelectedLifecycleAction } from '../../redux/actions/lifecycle.actions'
import { setIsDetailsPanelOpenAction, setIsForcingWorkspacePanelAction } from '../../redux/actions/flags.actions'
import Frame from './frame'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { loadDashboardDetailV1Saga } from '../../redux/sagas/user.saga'
import { switchAccountSaga } from '../../redux/sagas/account.saga'

const mapStateToProps = state => ({
  isDataViaNetworkRequesting: FlagsSelector.isDataViaNetworkRequesting(state),
  isSidebarOpen: FlagsSelector.isSidebarOpen(state),
  isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state),
  isSearchReferenceModalShowed: FlagsSelector.isSearchReferenceModalShowed(state),
  productImportModalVisibility: GlobalSelector.productImportModalVisibility(state)
})

const mapDispatchToProps = {
  setSelectedLifecycleAction,
  setIsDetailsPanelOpenAction,
  setProductImportModalVisibilityAction,
  clearUiWhenDetailPanelCloseAction,
  setIsForcingWorkspacePanelAction,
  loadDashboardDetailV1Saga,
  switchAccountSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Frame)
