import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider, Translation } from 'react-i18next'
import { ApolloProvider } from '@apollo/client'
import Moment from 'react-moment'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from '../configureStore'
import '../style/index.scss'
import i18n from '../utils/i18n'

import SentryScope from './sentryScope.container'
import InactivityTimeout from './shared/InactivityTimeout'
import { AppRoute } from '../v1/app.route'
import { AuthenticationProvider } from '../v1/dashboard/provider/authentication.provider'
import clientWithAuth from '../v1/graphql/client-with-auth'

Moment.globalLocale = i18n.language

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <AuthenticationProvider>
        <ApolloProvider client={clientWithAuth}>
          <I18nextProvider i18n={ i18n }>
            <Translation>
              { () => (
                <div className="ecochain-ui">
                  <BrowserRouter>
                    <AppRoute />
                    <InactivityTimeout />
                    <SentryScope />
                  </BrowserRouter>
                </div>
              ) }
            </Translation>
          </I18nextProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </PersistGate>
  </Provider>
)
export default App
