import * as d3 from 'd3'
import React from 'react'
import { TDonutPhase } from '../../interface/donut-chart.type'

import { ImpactType } from '../../../shared/enum/impact'
import { DonutLegendPhaseComponent } from './donut-legend-phase.component'

type TDonutLegendComponentProps = {
  phasesDonutData?: TDonutPhase[]
}

export const DonutLegendComponent = ({ phasesDonutData = [] } : TDonutLegendComponentProps) => {
  const colorInterpolator = d3.interpolateHsl('#072b49', 'white')

  return (
    <div className="flex flex-column w-full h-full overflow-y-auto overflow-x-hidden pr-2">
      {
        phasesDonutData.map((donutPhase: TDonutPhase, index: number) => {
          const colorIndex = index / phasesDonutData.length
          const background = donutPhase.totalImpactType == ImpactType.Negative ? 'var(--green-200)' : d3.rgb(colorInterpolator(colorIndex)).formatRgb()

          return (
            <div key={index} className="flex w-full">
              <DonutLegendPhaseComponent donutPhase={donutPhase} background={background} index={index} />
            </div>
          )
        })
      }
    </div>
  )
}
