import { connect } from 'react-redux'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import PdfResults from './pdfResults'
import { LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import AccountSelector from '../../../redux/selectors/account.selector'
import SpaceSelector from '../../../redux/selectors/space.selector'
import UserSelector from '../../../redux/selectors/user.selector'
import { trackEventSaga } from '../../../redux/sagas/tracking.saga'

const mapStateToProps = state => ({
  selectedAccountName: AccountSelector.selectedAccountName(state),
  inventoryProduct: InventoryTreeSelector.product(state),
  flatView: LifecycleSelector.flatView(state),
  impactCategory: SpaceSelector.impactCategory(state),
  impactMethod: SpaceSelector.impactMethod(state),
  impactNwSet: SpaceSelector.impactNwSet(state),
  impactUseMethodTotal: SpaceSelector.impactUseMethodTotal(state),
  email: UserSelector.selectedUserEmail(state),
  lifecycle: LifecycleSelector.selectedLifecycle(state)
})

const mapDispatchToProps = {
  trackEventSaga
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PdfResults)

