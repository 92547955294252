import { connect } from 'react-redux'
import { setIsCreateNewInventoryItemAction, setIsDetailsPanelOpenAction } from '../../../../../redux/actions/flags.actions'
import {
  addInventoryItemSaga,
  changeInventoryItemAmountSaga,
  createInventoryItemSaga,
  selectedInventoryItemSaga
} from '../../../../../redux/sagas/product.saga'

import { updateLifecyclesItemsSaga } from '../../../../../redux/sagas/lifecycle.saga'
import { updateInventoryItemsSaga } from '../../../../../redux/sagas/inventoryTree.saga'
import { removeConversionItemSaga } from '../../../../../redux/sagas/conversion.saga'
import { LifecycleSelector } from '../../../../../redux/selectors/lifecycle.selector'
import { SelectedProductSelector } from '../../../../../redux/selectors/product.selector'
import ProductInventoryProperties from './productInventoryProperties'

const mapStateToProps = state => ({
  selectedPhaseInventoryTree: LifecycleSelector.selectedPhaseInventoryTree(state),
  selectedProductId: SelectedProductSelector.productId(state),
  lifecycle: LifecycleSelector.selectedLifecycle(state),
})

const mapDispatchToProps = {
  setIsCreateNewInventoryItem: setIsCreateNewInventoryItemAction,
  setIsDetailsPanelOpenAction,
  addInventoryItemSaga,
  createInventoryItemSaga,
  changeInventoryItemAmountSaga,
  removeConversionItemSaga,
  updateInventoryItemsSaga,
  updateLifecyclesItemsSaga,
  selectedInventoryItemSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInventoryProperties)
