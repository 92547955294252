import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeProductType($productID: ID!, $type: ProductType!) {
    changeProductType(productID: $productID, type: $type){
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
