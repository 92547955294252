import { Tooltip } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import React from 'react'
import classnames from 'classnames'
import PhaseType from '../lifecycle/phaseType'

function PhaseCircle(props) {
  const {
    name,
    timelineItemType,
    firstLetter,
    isSelectedPhase,
    isShowLifecycleScenarioInventory,
    phaseType
  } = props

  const phaseIndicator = <div className={ classnames('timeline-item_first-letter', { on: isSelectedPhase }) }>
    {PhaseType.icons[phaseType] ? PhaseType.icons[phaseType] : firstLetter}
  </div>

  const circleElement = isShowLifecycleScenarioInventory ?
    <Tooltip placement="top" title={name} mouseEnterDelay={1}>
      {phaseIndicator}
    </Tooltip>
    : phaseIndicator

  return timelineItemType === 'addPhase' ?
    <PlusCircleOutlined className='add-phase-icon timeline-item-head' data-cy='add-phase'/>
    : circleElement
}

export default PhaseCircle

