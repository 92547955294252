import React, { Component } from 'react'
import { roundNumber } from '../../../../utils/tools'
import * as d3 from 'd3'

export default ChartComponent => (
  class ResponsiveChart extends Component {
    constructor(props) {
      super(props)

      this.state = {
        containerWidth: null,
      }

      this.fitParentContainer = this.fitParentContainer.bind(this)
    }

    componentDidMount() {
      this.fitParentContainer()
      window.addEventListener('resize', this.fitParentContainer)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
    }

    componentDidUpdate() {
      this.fitParentContainer()
    }

    fitParentContainer() {
      const { containerWidth } = this.state
      const currentContainerWidth = this.chartContainer.getBoundingClientRect().width

      const shouldResize = containerWidth !== currentContainerWidth

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
        })
      }
    }

    mouseoverFunction(evt, d) {
      let tooltipText = `name: ${d.name}<br>
      ${d.impactTotal ? `impact: ${d3.format('.5g')(d.impactTotal)} ${d.impactUnit}` : ''}<br>
      ${d.amount ? `amount: ${roundNumber(d.amount, 2)}` : ''}`

      d3.select('.tooltip')
        .attr('class', 'tooltip')
        .style('width', 120)
        .style('left', `${(evt.layerX)}px`)
        .style('top', `${(evt.layerY - 50)}px`)
        .style('font-family', 'Rubik')
        .html(tooltipText)
        .style('opacity', 0.9)
    }

    mouseoutFunction() {
      d3.select('.tooltip').style('opacity', 0)
    }

    renderChart() {
      const parentWidth = this.state.containerWidth

      return (
        <ChartComponent {...this.props}
          parentWidth={parentWidth}
          margin={{ top: 50, right: 50, left: 100, bottom: 150 }}
          mouseoverFunction={this.mouseoverFunction}
          mouseoutFunction={this.mouseoutFunction}
        />
      )
    }

    render() {
      const { containerWidth } = this.state
      const shouldRenderChart = containerWidth !== null

      return (
        <div
          ref={el => { this.chartContainer = el }}
          className="Responsive-wrapper"
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      )
    }
  }
)
