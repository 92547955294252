import React from 'react'
import { Badge, Button } from 'antd'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import DatabaseFilter from './databaseFilter'
import { LocationFilter } from './locationFilter'
import CategoryFilter from './categoryFilter'

import compose from '../../../utils/compose'

const SearchFilter = ({
  t,
  categoryId,
  databases,
  locationQuery,
  setDatabases,
  setCategoryId,
  setLocationQuery,
  isElementaryFlow,
  referenceDatabases,
  filterCount,
  clearFilter
}) => {

  const handleDatabaseChange = database => {
    setDatabases(database)
  }

  const handleLocationChange = location => {
    setLocationQuery(location)
  }

  const handleSelectCategory = categoryId => {
    setCategoryId(categoryId)
  }

  return (<div className="search-filter">
    <div className="search-filter__header">
      <div>
        {t('global.filters')}
        <Badge className="search-filter__badge" count={filterCount} />
      </div>
      <div className="search-filter__header--clear">
        <Button type="text" onClick={clearFilter}>{t('search_reference_product.clearFilters')}</Button>
      </div>
    </div>
    <div className="search-filter__section">
      {!isElementaryFlow && (<>
        <div className="search-filter__title">
          <h3>{t('model.database')}</h3>
        </div>
        <div className="search-filter__content">
          <DatabaseFilter
            databases={databases}
            handleDatabaseChange={handleDatabaseChange}
            referenceDatabases={referenceDatabases} />
        </div>
        <div className="search-filter__title">
          <h3>{t('search_reference_product.location_filter_header')}</h3>
        </div>
        <div className="search-filter__content">
          <LocationFilter
            locationQuery={locationQuery}
            handleLocationChange={handleLocationChange}
            locationPlaceholder={t('search_reference_product.location_filter_placeholder')} />
        </div>
      </>)}
      <div className="search-filter__title">
        <h3>{t('search_reference_product.category_filter_header')}</h3>
      </div>
      <div className={classnames('search-filter__content', {
        'search-filter__content--database': !isElementaryFlow,
        'search-filter__content--elementary': isElementaryFlow,
      })}>
        <CategoryFilter
          isElementaryFlow={isElementaryFlow}
          selectedCategory={[ categoryId ]}
          handleSelectCategory={ handleSelectCategory }/>
      </div>
    </div>
  </div>)
}

export { SearchFilter }
export default compose(
  withTranslation()
)(SearchFilter)
