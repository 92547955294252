import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPieSimple } from '@fortawesome/pro-solid-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { ITransformedLeafItem } from '../../interface/transformed-leaf-item'
import { DecimalViewTogglerComponent } from '../../../shared/component/general/decimal-view-toggler.component'
import { DecimalViewType } from '../../../shared/enum/decimal'
import { DoughnutLegendLayout } from '../../layout/doughnut-legend.layout'
import { MenuDownloadDoughnutComponent } from '../menu/menu-download-doughnut.component'
import { PngHeaderTemplateComponent } from './png-header-template.component'
import { PngFooterTemplateComponent } from './png-footer-template.component'
import { MenuCompareComponent } from '../menu/menu-compare.component'
import {
  getOrCreateTooltip, removeTooltip, setTooltipBody, setTooltipHeader, setTooltipStyle
} from '../../../shared/util/chart/chart-tooltip'
import { castNumberToDisplayValue, getDecimalDisplayValue } from '../../../shared/util/decimal'
import { EmptyStateScreenLayout } from '../../../shared/layout/empty-state-screen.layout'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { InventoryViewType } from '../../enum/inventory-view-type'
import { Navigation } from '../../../shared/enum'
import { isValid } from '../../../shared/util/tools'

const DoughnutViewWrapper = styled.div`
  min-height: 25rem;

  &.doughnut-view-shadow {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }

  .p-chart {
    width: 100%;
    position: relative;
  }

  .p-chart-tooltip-animation {
    transform: translate(-50%, 0);
    transition: all .1s ease;
  }
`

export const InventoryDoughnutViewComponent = () => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    productInventory = {},
    totalImpact: { amount: totalImpactAmount, unit: totalImpactUnit = null } = {},
    transformedLeafItems = [], selectedDecimalViewType
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const navigate = useNavigate()
  const { productId } = useParams()
  const [ chartItems, setChartItems ] = useState<ITransformedLeafItem[]>([])
  const [ chartData, setChartData ] = useState<any>({ labels: [], datasets: [] })
  const [ chartOptions, setChartOptions ] = useState<any>(null)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const { product } = productInventory

  const negativeColor = '#b6e89c'
  const defaultColor = '#c5e0f4'
  const colorSet = [
    '#053253', '#074572', '#085892',
    '#0a6ab1', '#0c7dd0', '#3a96d9',
    '#68aee2', '#97c7eb'
  ]
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLDivElement>(null)
  const maxChartItems = 8

  useEffect(() => {
    defineChartItems()
  }, [ selectedDecimalViewType, transformedLeafItems ])

  useEffect(() => {
    if (chartItems.length) {
      defineChartData()

      setChartOptions({
        aspectRatio: 1.3,
        maintainAspectRatio: true,
        borderWidth: 2,
        layout: {
          padding: {
            top: 10, left: 10, bottom: 10, right: 10
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: externalTooltipHandler,
            events: [ 'mousemove' ]
          }
        }
      })
    }
  }, [ selectedDecimalViewType, chartItems ])

  const isSelectedView = (selected: DecimalViewType) => selected === selectedDecimalViewType
  const getLeafItem = (label: string) => chartItems.find((item: ITransformedLeafItem) => item.name === label)
  const getPercentage = (value?: ITransformedLeafItem) => {
    const { impactRatioRelativeToTotal } = value || {}
    return impactRatioRelativeToTotal && impactRatioRelativeToTotal * 100
  }

  const externalTooltipHandler = (context: any) => {
    const { chart, tooltip } = context
    const tooltipElement = getOrCreateTooltip(chart)
    const table = tooltipElement.querySelector('table')

    if (tooltipElement && tooltip.opacity === 0) {
      tooltipElement.style.opacity = '0'
      return
    }

    if (table && tooltip.body) {
      while (table?.firstChild) {
        table.firstChild.remove()
      }
      const { backgroundColor = '', borderColor = '' } = tooltip.labelColors[0] || {}
      const leafItem = getLeafItem(tooltip.title[0])
      const title = t('labels.unitOfEntityWithAmount', {
        amount: leafItem?.amount,
        unit: leafItem?.unit || 'unit',
        name: leafItem?.name,
        ns: 'common'
      })
      setTooltipHeader({
        table, backgroundColor, borderColor, title
      })

      const { impactRatioRelativeToTotal } = leafItem || {}
      const value = isSelectedView(DecimalViewType.PercentageValue) ? impactRatioRelativeToTotal?.toString() : leafItem?.impactAmount?.toString()
      const prefix = isSelectedView(DecimalViewType.PercentageValue) ? 'Percentage : ' : 'Impact : '
      const [ coefficient, exponent ] = getDecimalDisplayValue({ value, decimalViewType: selectedDecimalViewType })
      setTooltipBody({
        table,
        totalImpactUnit,
        selectedDecimalViewType,
        prefix,
        coefficient: coefficient?.toString(),
        exponent: exponent?.toString()
      })
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
    setTooltipStyle({
      tooltipElement, context, positionX: positionX + tooltip.caretX, positionY: positionY + tooltip.caretY
    })
  }

  const defineChartData = () => {
    const labels: any[] = chartItems.map((item :ITransformedLeafItem) => item?.name)
    const datasetBackground = chartItems.map((item :ITransformedLeafItem, index: number) => {
      if (item?.impactAmount && parseFloat(item.impactAmount) < 0) {
        return negativeColor
      }

      return item && index <= maxChartItems - 1 ? colorSet[index] : defaultColor
    })
    let datasetData: any[] = []
    if (isSelectedView(DecimalViewType.NumericValue)) {
      datasetData = chartItems.map((value: ITransformedLeafItem) => value?.impactAmount && parseFloat(value.impactAmount))
    } else {
      datasetData = chartItems.map((value: ITransformedLeafItem) => getPercentage(value))
    }
    const datasets = [ {
      borderRadius: 4,
      backgroundColor: [ ...datasetBackground ],
      hoverBackgroundColor: [ ...datasetBackground ],
      data: [ ...datasetData ]
    } ]
    setChartData({ labels, datasets })
  }

  const defineChartItems = () => {
    const sortedLeafItems = transformedLeafItems.sort((a: ITransformedLeafItem, b: ITransformedLeafItem) => {
      const { impactAmount: aImpactAmount } = a || {}
      const { impactAmount: bImpactAmount } = b || {}

      if (aImpactAmount && bImpactAmount) {
        if (parseFloat(aImpactAmount) < parseFloat(bImpactAmount)) {
          return -1
        } if (parseFloat(aImpactAmount) > parseFloat(bImpactAmount)) {
          return 1
        }
      }
      return 0
    }).reverse()

    setChartItems(sortedLeafItems)
  }

  const beforeDraw = (chart: any) => {
    const { ctx } = chart
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = '600 28px Rubik'
    const amountX = chart.getDatasetMeta(0).data[0].x
    const amountY = chart.getDatasetMeta(0).data[0].y - 20

    const [ coefficient, exponent ] = castNumberToDisplayValue({ value: totalImpactAmount?.toString() })
    if (exponent) {
      const maxTwoDecimalPlaces = Math.round((Number.parseFloat(coefficient) + Number.EPSILON) * 100) / 100
      ctx.fillText(`${maxTwoDecimalPlaces}   10`, amountX, amountY)
      ctx.fillText('.', amountX + 14, amountY - 5)
      ctx.font = '600 18px Rubik'
      ctx.fillText(`${exponent}`, amountX + 70, amountY - 10)
    } else {
      ctx.fillText(coefficient, amountX, amountY)
    }
    ctx.font = '400 20px Rubik'
    ctx.fillText(totalImpactUnit, amountX, amountY + 30)

    const canvas = ctx.canvas.getBoundingClientRect()
    ctx.canvas.onmousemove = (e:any) => {
      const canvasX = e.clientX - canvas.left
      const canvasY = e.clientY - canvas.top
      const isInAmountX = canvasX < amountX + 50 && canvasX > amountX - 50
      const isInAmountY = canvasY < amountY + 20 && canvasY > amountY - 20

      if (isInAmountX && isInAmountY) {
        const tooltipElement = getOrCreateTooltip(chart, 'doughnut-center-tooltip')
        const table = tooltipElement.querySelector('table')
        table && setTooltipBody({ table, coefficient: totalImpactAmount || '0', selectedDecimalViewType: DecimalViewType.NumericValue })
        setTooltipStyle({
          tooltipElement, context: chart, positionX: amountX, positionY: amountY + 10
        })
      } else {
        removeTooltip('doughnut-center-tooltip')
      }
    }
  }

  const productHasCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !isValid(product?.referenceProduct)
  const productHasNoItems = !product?.hasInventory
  const thereIsItemWithImpact = transformedLeafItems.some(item => item.impactAmount && Math.abs(Number(item.impactAmount)) > 0)

  if (productHasNoImpact && !thereIsItemWithImpact) {
    const goToModel = () => {
      addToBackToList(t('labels.tab', { context: 'donutChart' }))
      account?.id && space?.slug && productId && navigate(generatePath(Navigation.ProductObjectInventoryTabs, {
        accountId: account.id,
        workspaceSlug: space.slug,
        productId,
        activeTab: InventoryViewType.Model
      }))
    }

    return (
      <EmptyStateScreenLayout
        wrapperClassName="h-30rem"
        title={t('labels.emptyState', { context: productHasNoItems ? 'addItemsToShowDonutChart' : 'addImpactToShowDonutChart', ns: 'common' })}
        icon={<FontAwesomeIcon icon={faChartPieSimple} className="text-primary-100" fontSize={106} />}
        footer={<Button onClick={goToModel}>{t('actions.go_toModel', { ns: 'common' })}</Button>}
      />
    )
  }

  return (
    <DoughnutViewWrapper
      data-testid="doughnut-view-wrapper"
      ref={containerRef}
      className="flex flex-column border-round-lg bg-white w-full h-full p-6 doughnut-view-shadow doughnut-view-wrapper"
    >
      <PngHeaderTemplateComponent />
      <div data-html2canvas-ignore className="flex h-2rem text-2xl align-items-center mb-2">
        <div data-testid="doughnut-view-title" className="flex-grow-1 flex align-items-center h-full">
          { t('labels.header', { context: 'chart', unit: totalImpactUnit, ns: 'common' }) }
        </div>
        <div className="flex-none flex align-items-center gap-3">
          <MenuCompareComponent />

          <DecimalViewTogglerComponent />

          <MenuDownloadDoughnutComponent containerRef={containerRef} />
        </div>
      </div>
      <div ref={chartRef} className="flex w-full">
        <div className="md:w-9 lg:w-8 xl:w-6">
          { chartItems.length > 0 && chartOptions && (
            <>
              <Chart className="doughnut-chart" data-testid="doughnut-view-chart" type="doughnut" data={chartData} options={chartOptions} plugins={[ { beforeDraw } ]} />
            </>
          )}
        </div>
        <div className="md:w-3 lg:w-4 xl:w-6 flex align-items-center justify-content-center">
          <div className="md:w-9 lg:w-8 xl:w-8 flex flex-column">
            <DoughnutLegendLayout maxChartItems={maxChartItems} chartItems={chartItems} />
          </div>
        </div>
      </div>
      <PngFooterTemplateComponent />
    </DoughnutViewWrapper>
  )
}
