import { connect } from 'react-redux'
import SpaceSelector from '../../../redux/selectors/space.selector'
import CustomImpactFormInfo from './customImpactFormInfo'

const mapStateToProps = state => ({
  selectedImpactMethod: SpaceSelector.impactMethod(state),
  selectedExcludeLT: SpaceSelector.impactExcludeLT(state)
})

export default connect(
  mapStateToProps,
  null
)(CustomImpactFormInfo)
