export enum ProductType {
    Normal = 'normal',
    Waste = 'waste',
    Product ='product_system',
    ElementaryFow = 'elementary_flow'
}

export enum ProductCategory {
    InputProduct = 'InputProduct',
    IntermediateProduct = 'IntermediateProduct',
    EndProduct = 'EndProduct',
    Waste = 'Waste'
}

export enum ProductLabel {
    Product = 'product',
    Object = 'object'
}
