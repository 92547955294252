export const OverviewTableColumn = {
  Name: 'Name',
  LastChanged: 'LastChanged',
  Created: 'Created'
} as const
export type TOverviewTableColumn = typeof OverviewTableColumn[keyof typeof OverviewTableColumn]

export const LifecycleOverviewTableColumn = { ...OverviewTableColumn, Description: 'Description' } as const
export type TLifecycleOverviewTableColumn = typeof LifecycleOverviewTableColumn[keyof typeof LifecycleOverviewTableColumn]

export const ProductOverviewTableColumn = { ...OverviewTableColumn, TagsDescription: 'TagsDescription' } as const
export type TProductOverviewTableColumn = typeof ProductOverviewTableColumn[keyof typeof ProductOverviewTableColumn]

export const TransformedLeafItem = {
  id: 'id',
  name: 'name',
  Amount: 'amount',
  amount: 'unit',
  impactAmount: 'impactAmount',
  impactRatioRelativeToTotal: 'impactRatioRelativeToTotal',
  impactRatioRelativeToMax: 'impactRatioRelativeToMax'
}
export type ITransformedLeafItem = typeof TransformedLeafItem[keyof typeof TransformedLeafItem]

export enum TFlatViewTableColumn {
  Id = 'Id',
  Item = 'Item',
  Amount = 'Amount',
  Unit = 'Unit',
  Impact = 'Impact',
  Scientific = 'Scientific',
  Percentage = 'Percentage',
  ImpactBar = 'ImpactBar'
}

export enum TFlatViewSortBy {
  ID = 'id',
  Name = 'name',
  Amount = 'amount',
  Unit = 'unit',
  ImpactAmount = 'impactAmount',
  ImpactRatioRelativeToTotal = 'impactRatioRelativeToTotal',
  ImpactRatioRelativeToMax = 'impactRatioRelativeToMax'
}
