import React from 'react'
import { List } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { AiOutlineDown } from 'react-icons/ai'

import SearchListItem from './searchListItem'

export const SearchListResults = ({
  items,
  totalResults,
  selectedProduct,
  paginationOption,
  isElementaryFlow,
  referProductsToEachOther,
  isLoadingReferenceProducts
}) => {

  const loadingIcon = <LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin />

  return <div className='reference-result'>
    <div className="reference-result__resultHeader">
      <div className="reference-result__allResult">
        { totalResults }
      </div>
      <div className="reference-result__sort">
        Alphabetically A-Z
        <AiOutlineDown className='reference-result__downIcon' />
      </div>
    </div>
    <List
      data-cy='ref-product-search-results'
      className='reference-result__list'
      itemLayout='horizontal'
      size='large'
      loading={ isLoadingReferenceProducts && {
        spinning: true,
        indicator: loadingIcon,
      } }
      pagination={ !isLoadingReferenceProducts && paginationOption }
      rowKey={ record => record.id }
      dataSource={ items }
      renderItem={ referenceProduct => {
        return (
          <SearchListItem
            isElementaryFlow={isElementaryFlow}
            referenceProduct={referenceProduct}
            selectedProduct={selectedProduct}
            referProductsToEachOther={referProductsToEachOther} />
        )
      }}
    />
  </div>
}
