import React, { useState, createRef } from 'react'
import {
  getInventoryNodeFromInventoryItemKey,
  getTargetIdAndTargetAmountFromInventoryItemKey
} from '../../../utils/inventoryTreeKey'

import { floatToString } from '../../../utils/tools'

export const withEditableProduct = WrappedComponent => props =>{
  const formRef = createRef()
  const {
    selectedProduct,
    changeInventoryItemAmountSaga,
    selectedInventoryItem,
    inventoryTree,
    productMutator,
    updateInventoryItemsSaga,
    updateLifecyclesItemsSaga,
    lifecycle
  } = props
  const selectedNodeFromInventory = getInventoryNodeFromInventoryItemKey(inventoryTree, selectedInventoryItem)
  const [ editMode, setEditMode ] = useState(false)
  const [ amountUpdated, setAmountUpdated ] = useState(false)

  const resetChangeAmountForm = (editMode = false) => {
    formRef?.current?.resetFields()
    setEditMode(editMode)
    setAmountUpdated(false)
  }

  const changeAmountCallback = () => {
    if ( lifecycle?.id ) {
      updateLifecyclesItemsSaga()
    } else {
      updateInventoryItemsSaga()
    }
    resetChangeAmountForm()
  }

  const changeInventoryItemAmount = () => {
    const { inventoryItem: selectedNodeInventoryItem } = selectedNodeFromInventory

    formRef.current.validateFields().then(values => {
      if (selectedNodeInventoryItem?.amount === values.amount) return resetChangeAmountForm()

      const { targetId, targetAmount } = getTargetIdAndTargetAmountFromInventoryItemKey(inventoryTree, selectedInventoryItem)
      changeInventoryItemAmountSaga(
        productMutator,
        selectedProduct.id,
        floatToString(values.amount),
        targetId,
        targetAmount,
        changeAmountCallback)

      setAmountUpdated(true)
    }).catch(() => {
      setAmountUpdated(false)
    })
  }

  return (
    <WrappedComponent
      {...props}
      formRef={formRef}
      editMode={editMode}
      amountUpdated={amountUpdated}
      resetChangeAmountForm={resetChangeAmountForm}
      handleAmountChangeEvent={changeInventoryItemAmount} />
  )
}

