import { Col, Row, Tabs, Switch } from 'antd'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../utils/with-router'
import { isEmpty, returnNested, safeArray, getSunburstColorSet, getSunburstPhaseColorSet } from '../../../utils/tools'
import { EntityType, SUNBURST } from '../../../utils/const'
import {
  LifecycleItemLocation,
  tabChartView,
  tabFlatView,
  tabTreeView,
  tabSunburst
} from '../../shared/locations'
import D3Chart from '../inventory/inventory-D3/D3Chart.container'
import InventoryTotalImpact from '../inventory/inventory-total-impact/inventoryTotalImpact.container'
import ComparisonFlatInventory from './comparison-flat-inventory/comparisonFlatInventory.container'
import FlatInventory from './flat-inventory/flatInventory.container'
import LifecycleTreeWrapper from './lifecycleTreeWrapper.container'
import ChangeImpactCategory from '../change-impact-category/changeImpactCategory.container'
import SunburstGraph from '../sunburst-graph/sunburstGraph.container'
import DashboardContext from '../../../v1/dashboard/context/dashboard.context'

const TabPane = Tabs.TabPane
class Lifecycle extends React.PureComponent {
  state = {
    currentTab: '',
    tabs: {}
  }
  static contextType = DashboardContext

  constructor(props) {
    super(props)

    const { activeTabKey, t } = this.props
    const tabs = {
      [tabTreeView]: t('model.tree_view'),
      [tabFlatView]: t('model.flat_view'),
      [tabChartView]: t('model.d3_title_bar'),
      [tabSunburst]: t('model.d3_title_sunburst')
    }
    this.state = { currentTab: tabs[activeTabKey], tabs }
  }

  handleTabsOnChange = activeKey => {
    const { addToBackToList = () => {} } = this.context
    this.props.setIsOrderingPhasesAction(false)
    this.props.navigate(LifecycleItemLocation.toUrl({
      ...LifecycleItemLocation.parseLocationParams(),
      activeTabKey: activeKey,
      productId: this.props.inventoryRootItemId
    }))
    this.props.setIsDetailsPanelOpenAction(false)
    addToBackToList(this.state.currentTab)
    this.setState({ ...this.state, currentTab: this.state.tabs[activeKey] })
  }

  render() {
    const {
      activeTabKey,
      lifecycle,
      isShowLifecycleScenarioInventory,
      flatView,
      comparisonFlatView,
      selectedSpace,
      entityToCompare,
      t,
      skipPhases
    } = this.props

    const lifecycleWithImpact = lifecycle && {
      name: returnNested(lifecycle,  'name'),
      ...(returnNested(lifecycle, 'product') ? {
        amount: returnNested(lifecycle, 'amount'),
        unit: returnNested(lifecycle, 'unit'),
        impact: returnNested(lifecycle, 'inventory', 'totalImpact')
      } : {}),
      __typename: lifecycle.__typename
    }
    const comparisonRootProductWithImpact = returnNested(entityToCompare, 'name') && {
      name: returnNested(entityToCompare, 'name'),
      amount: returnNested(entityToCompare, 'amount'),
      unit: returnNested(entityToCompare, 'unit'),
      impact: returnNested(entityToCompare, 'totalImpact'),
      __typename: entityToCompare.__typename
    }
    const isComparison = !isEmpty(entityToCompare)

    // TODO MOB-2691 handle mixed entity types comparison
    const isMixedEntitiesComparison = returnNested(entityToCompare, '__typename') !== EntityType.LIFECYCLE

    const isTreeViewDisabled = isComparison && !isShowLifecycleScenarioInventory && isMixedEntitiesComparison
    const sunburstColorSetDefault = getSunburstColorSet({
      setA: returnNested(lifecycle, 'inventory', 'leafInventoryItems') || [],
      setB: returnNested(entityToCompare, 'leafInventoryItems'),
      reversed: false
    })

    const sunburstColorSetReversed = getSunburstColorSet({
      setA: returnNested(lifecycle, 'inventory', 'leafInventoryItems') || [],
      setB: returnNested(entityToCompare, 'leafInventoryItems'),
      reversed: true
    })

    const phaseColorSet = getSunburstPhaseColorSet({
      setA: returnNested(lifecycle, 'phases') || [],
      setB: returnNested(entityToCompare, 'phases'),
    })

    const SingleSunburst = () => <Col span={ isComparison ? 12 : 24 }>
      <SunburstGraph
        sunburstId="sunburst-basic"
        key={ returnNested(lifecycle, 'id') }
        leafInventory={returnNested(lifecycle, 'inventory', 'leafInventoryItems') || []}
        activeTabKey={ activeTabKey }
        rootProduct={lifecycleWithImpact}
        orderedPhases={ returnNested(lifecycle, 'phases') || [] }
        showPhasesHandler={checked => this.props.setIsLifecycleSkipPhasesSaga(!checked)}
        squareSize={ SUNBURST.FULL_SIZE }
        colorSet={ sunburstColorSetDefault }
        colorSetReversed={ sunburstColorSetReversed }
        phaseColorSet={phaseColorSet}
      />
    </Col>

    const CompareSunburst = () => <Col span={ 12 }>
      <SunburstGraph
        sunburstId="sunburst-compare"
        key={ returnNested(entityToCompare, 'id') }
        leafInventory={ returnNested(entityToCompare, 'leafInventoryItems') }
        activeTabKey={ activeTabKey }
        rootProduct={ comparisonRootProductWithImpact }
        orderedPhases={ [
          ...safeArray(returnNested(entityToCompare, 'phases'))
        ] }
        showPhasesHandler={checked => this.props.setIsLifecycleSkipPhasesSaga(!checked)}
        squareSize={ SUNBURST.FULL_SIZE }
        colorSet={ sunburstColorSetDefault }
        colorSetReversed={ sunburstColorSetReversed }
        phaseColorSet={phaseColorSet}
      />
    </Col>

    const SingleSunburstTotal = () => <Col flex="1">
      <InventoryTotalImpact
        impact={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        impactToCompare={ returnNested(entityToCompare, 'totalImpact') }
        isCarbonTranslatorShowed={!isComparison}
      />
    </Col>

    const CompareSunburstTotal = () => <Col flex="1">
      <InventoryTotalImpact
        impact={ returnNested(entityToCompare, 'totalImpact') }
        impactToCompare={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        isCarbonTranslatorShowed={false}
      />
    </Col>

    return (
      <React.Fragment>
        <Tabs
          animated={ false }
          activeKey={ activeTabKey }
          onChange={ this.handleTabsOnChange }
          className="inventory-tabs"
          data-cy="inventory-tabs"
          tabBarExtraContent={<ChangeImpactCategory/>}
        >
          <TabPane tab={this.state.tabs[tabTreeView]} key={tabTreeView} disabled={isTreeViewDisabled}>
            <LifecycleTreeWrapper />
          </TabPane>
          <TabPane tab={this.state.tabs[tabFlatView]} key={tabFlatView}>
            <Row>
              <Col span={ 24 }>
                {
                  comparisonFlatView
                    ? <ComparisonFlatInventory
                      comparisonFlatView={comparisonFlatView}
                    />
                    : <FlatInventory
                      flatView={flatView}
                    />
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={this.state.tabs[tabChartView]} key={tabChartView} disabled={isComparison || isShowLifecycleScenarioInventory}>
            <Row>
              <Col span={ 24 }>
                <D3Chart
                  rootInventoryProductWithImpact={ lifecycleWithImpact}
                  title={ returnNested(lifecycle,  'name')}
                  impactCatName={returnNested(selectedSpace, 'impactCategory', 'name')}
                  leafInventory={returnNested(lifecycle, 'inventory', 'leafInventoryItems') || []}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={this.state.tabs[tabSunburst]} key={tabSunburst}>
            <div className="switches-container">
              {
                <div className="phases-switch switch" data-cy="leaf-inventory-show-phases-switch">
                  <React.Fragment>
                    {t('model.show_phases')}
                    <Switch
                      onChange={checked => this.props.setIsLifecycleSkipPhasesSaga(!checked)}
                      size={'small'}
                      checked={!skipPhases}
                      checkedChildren={t('global.on')}
                      unCheckedChildren={t('global.off')} />
                  </React.Fragment>
                </div>
              }
            </div>
            <Row>
              { isShowLifecycleScenarioInventory && <CompareSunburst /> }
              <SingleSunburst />
              { !isShowLifecycleScenarioInventory && isComparison && <CompareSunburst /> }
            </Row>
            <Row gutter={16} type="flex">
              { isShowLifecycleScenarioInventory && <CompareSunburstTotal /> }
              <SingleSunburstTotal />
              { !isShowLifecycleScenarioInventory && isComparison && <CompareSunburstTotal /> }
            </Row>
          </TabPane>

        </Tabs>
      </React.Fragment>
    )
  }
}

export { Lifecycle }
export default compose(
  withTranslation(),
  withRouter,
)(Lifecycle)
