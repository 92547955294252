import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'
import { TooltipOverflowContent } from '../../../shared/hook/use-ref-overflow'

type TableCellComponentWrapperProps = {
  enableWordBreak: boolean
}

const TableCellWrapper = styled.div<TableCellComponentWrapperProps>`
  min-height: 34px;
  max-height: 54px;
  overflow: hidden;
  line-height: 1.125rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: ${(props: TableCellComponentWrapperProps) => (props.enableWordBreak ? 'break-all' : 'normal')};
  -webkit-line-clamp: 'none';
  -webkit-box-orient: vertical;
`

type TTableCellComponentProps = {
  className?: string,
  textContent?: string,
  enableWordBreak?: boolean
}
export const TableCellComponent = ({
  className,
  textContent,
  enableWordBreak = false
}: TTableCellComponentProps) => {
  const [ isOverflowing, setIsOverflowing ] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!textRef.current) return

    const element = textRef.current
    if (element.scrollHeight > element.clientHeight) {
      element.style.webkitLineClamp = '3'
      element.classList.remove('flex')
      setIsOverflowing(true)
    } else {
      element.classList.add('flex')
      setIsOverflowing(false)
    }
  }, [ textContent ])

  const getTooltipContent = () => (<TooltipOverflowContent> { textContent } </TooltipOverflowContent>)

  return (
    <>
      { textRef.current && <Tooltip target={textRef.current} onBeforeShow={() => isOverflowing} position="bottom">{getTooltipContent()}</Tooltip> }

      <TableCellWrapper enableWordBreak={enableWordBreak} ref={textRef} className={className}> { textContent } </TableCellWrapper>
    </>
  )
}
