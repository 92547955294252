import { gql } from '@apollo/client'
import space from '../fragment/space'

export default gql`
  query space($id: ID!) {
      space(id: $id){
          ...spaceFragment
      }
  }
  ${space}
`
