import { connect } from 'react-redux'
import { exportLifecycleImpactsSaga } from '../../../redux/sagas/lifecycle.saga'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import { LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import ProductTools from './productTools'
import { exportProductImpactsSaga } from '../../../redux/sagas/product.saga'
import { setIsLifecycleSkipPhasesSaga } from '../../../redux/sagas/lifecycle.saga'

const mapStateToProps = state => {
  return ({
    isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
    isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
    isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
    lifecycle: LifecycleSelector.selectedLifecycle(state),
    inventoryProductId: InventoryTreeSelector.productId(state),
    scenarioList: InventoryTreeSelector.scenarios(state),
    isSkipPhases: FlagsSelector.isSkipPhases(state)
  })
}

const mapDispatchToProps = {
  exportProductImpactsSaga,
  exportLifecycleImpactsSaga,
  setIsLifecycleSkipPhasesSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductTools)
