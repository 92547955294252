import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, Select, Form, Switch } from 'antd'
import compose from '../../../../utils/compose'
import { withRouter } from '../../../../utils/with-router'
import { withTranslation } from 'react-i18next'
import { VALIDATION, TRACK_EVENT_TYPE, PRODUCT_LABEL } from '../../../../utils/const'
import { LifecycleItemLocation } from '../../../shared/locations'
import { isEmpty, floatToString, filterOptionByValue, normalize } from '../../../../utils/tools'
import PhaseType from '../phaseType'
import DetailsPanelTitle from '../../details-panel/detailsPanelTitle'

const FormItem = Form.Item
const CreateLifecycle = props => {
  const {
    t,
    isMinimalisticVersion,
    setIsCreateLifecycleAction,
    cancelHandler,
    selectedSpaceId,
    createLifecycleItemSaga,
    trackEventSaga,
    accountId,
    handleModalOk,
    workspaceSlug,
    searchProductsSaga,
    setQueryOfProductFilterAction,
    selectedSpaceProducts,
    withProductSelection,
    attachedProductId
  } = props
  const navigate = useNavigate()

  const { OptGroup, Option } = Select

  const formRef = useRef()
  const [ selectedPhaseList, setSelectedPhaseList ] = useState([])
  const [ productIdForLifecycleCreation, setProductIdForLifecycleCreation ] = useState(null)
  const [ isProductAttached, setIsProductAttached ] = useState(true)

  const phaseTemplates = [
    { key: 'std5', name: 'Std 5 phases', phaseList: PhaseType.groups.std5 },
    { key: 'std3', name: 'Std 3 phases', phaseList: PhaseType.groups.std3 },
    { key: 'std2', name: 'Std 2 phases', phaseList: PhaseType.groups.std2 },
    { key: 'std7', name: 'Std 7 phases', phaseList: PhaseType.groups.std7 },
    { key: 'EN15804_full', name: 'EN15804 Full', phaseList: PhaseType.groups.EN15804_full },
    { key: 'EN15804_product_and_eol', name: 'EN15804 Product and EoL', phaseList: PhaseType.groups.EN15804_product_and_eol },
    { key: 'EN15804_product_split', name: 'EN15804 Product split', phaseList: PhaseType.groups.EN15804_product_split },
    { key: 'EN15804_full_split', name: 'EN15804 Full split', phaseList: PhaseType.groups.EN15804_full_split },
  ]

  const defaultPhaseListKey = phaseTemplates[0].key

  const initialValues = {
    name: null,
    amount: null,
    unit: null,
    productId: undefined,
    templateKey: defaultPhaseListKey
  }

  useEffect(() => {
    onProductSearch('')
    setProductIdForLifecycleCreation(attachedProductId)
  }, [])

  const handleCreateLifecycle = () => {
    handleModalOk()
    const productId = isProductAttached ? productIdForLifecycleCreation : null
    formRef.current.validateFields().then(values => {
      const selectedTemplate = values.templateKey && phaseTemplates.find(template => template.key === values.templateKey)
      const defaultPhaseList = !isEmpty(phaseTemplates) && phaseTemplates[0].phaseList
      createLifecycleItemSaga({
        productID: productId,
        workspaceID: selectedSpaceId,
        name: values.name,
        description: values.description,
        amount: floatToString(values.amount),
        unit: values.unit,
        phases: selectedTemplate.phaseList || defaultPhaseList,
        cb: lifecycleId => {
          navigate(LifecycleItemLocation.toUrl({
            accountId,
            workspaceSlug,
            lifecycleId,
          }))
        }
      })

      formRef.current.resetFields()
      trackEventSaga(TRACK_EVENT_TYPE.CREATED_LIFECYCLE)
    })
  }

  const handlePhaseTemplateSelect = key => {
    setSelectedPhaseList(phaseTemplates.find(el => el.key === key))
  }

  const getSelectedPhaseList = phaseList => {
    return <ul>
      {
        phaseList.map((phase, index) => (
          <li key={index}>{phase.name}</li>
        ))
      }
    </ul>
  }

  const onProductSearch = value => {
    clearProductSelection()
    setQueryOfProductFilterAction(value || '')
    searchProductsSaga({
      spaceId: selectedSpaceId,
      currentPage: 1,
      noFavorite: true,
      noCategories: true,
      labels: [ PRODUCT_LABEL.PRODUCT, PRODUCT_LABEL.OBJECT ]
    })
  }

  const onSelectProduct = productId => {
    setProductIdForLifecycleCreation(productId)
  }

  const cancelCreateLifecycle = () => {
    cancelHandler()
  }

  const clearProductSelection = () => {
    setIsCreateLifecycleAction(null)
  }

  const getEntityOptionsByLabel = label => {
    const hasEntryLabel = entry => {
      const { labels = [] } = entry
      return labels.some(item => item.id === label)
    }
    return <OptGroup key={label} label={t('model.label', { context: label })}>
      {selectedSpaceProducts && selectedSpaceProducts
        .filter(entry => hasEntryLabel(entry))
        .map(entity => (
          <Option
            data-cy={`item-named-${normalize(entity.name)}`}
            key={ entity.id }>
            { entity.name }
          </Option>
        ))
      }
    </OptGroup>
  }

  return <section className="create-lifecycle">
    { !isMinimalisticVersion ? <DetailsPanelTitle title={t('model.create_lifecycle')} /> : null }
    <div className="create-lifecycle_content panel-section no-collapse">
      <Form
        ref={formRef}
        onFinish={ handleCreateLifecycle }
        layout="vertical"
        hideRequiredMark
        className="field-container"
        initialValues={initialValues}
      >
        <FormItem name="name" label={ t('global.name') } rules={[
          { min: VALIDATION.PRODUCT_NAME_LENGTH, message: t('validation.product_name', { name: 'Lifecycle name' }) },
          { required: true, message: t('validation.product_name', { name: 'Lifecycle name' }) }
        ]}>
          <Input
            autoFocus={true}
            data-cy="create-lifecycle-name"
            placeholder={t('model.name', { context: 'lifecycle' })}
          />
        </FormItem>

        { !isMinimalisticVersion ? <FormItem name="description" label={ t('global.description') }>
          <Input data-cy="create-lifecycle-description" />
        </FormItem> : null }

        { !isMinimalisticVersion ? <FormItem name="amount" label={ t('global.amount') }>
          <InputNumber
            min={0}
            className="full-width"
            data-cy="create-lifecycle-amount"
          />
        </FormItem> : null }

        { !isMinimalisticVersion ?  <FormItem
          name="unit"
          label={ t('global.unit') }
          rules={[ { validator: async (rule, value) => {
            if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
              throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Unit' }))
            }
          } } ]}>
          <Input
            data-cy="create-lifecycle-unit"
          />
        </FormItem> : null }

        {
          withProductSelection ? <React.Fragment>
            <FormItem>
              <Switch
                defaultChecked
                onChange={status => setIsProductAttached(status)}
                size="small"
                data-cy="attach-product-switch"
              /> {t('model.attach_product_to_lc')}
            </FormItem>
            { isProductAttached ? <FormItem
              name="productId"
              label={ t('global.product') }
              rules={[
                { required: true, message: t('validation.lifecycle_product_name') }
              ]}>

              <Select
                showSearch
                placeholder={t('model.search_product')}
                onSelect={ productId => onSelectProduct(productId) }
                onSearch={ val => onProductSearch(val) }
                filterOption={ (inputValue, option) => filterOptionByValue(inputValue, option) }
                data-cy='new-product-name-autocomplete'
              >
                {getEntityOptionsByLabel(PRODUCT_LABEL.PRODUCT)}
                {getEntityOptionsByLabel(PRODUCT_LABEL.OBJECT)}
              </Select>
            </FormItem>
              : null}
          </React.Fragment>: null
        }

        <FormItem
          name="templateKey"
          label={ t('model.lifecycle_template') }
          rules={[ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Lifecycle template' }) } ]}
        >
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            className="full-width"
            onSelect={handlePhaseTemplateSelect}
            data-cy="create-lifecycle-phase-template">
            {phaseTemplates.map(template => <Select.Option data-cy={`template-${template.key}`} key={template.key} >{template.name}</Select.Option>)}
          </Select>
        </FormItem>

        <FormItem label={t('model.phases')}>

          {
            selectedPhaseList && selectedPhaseList.phaseList && selectedPhaseList.phaseList.length ?
              getSelectedPhaseList(selectedPhaseList.phaseList) :
              getSelectedPhaseList(phaseTemplates.find(el => el.key === defaultPhaseListKey).phaseList)
          }

        </FormItem>
        <FormItem className="create-lifecycle_content_actions">
          <Button
            className="button gutter"
            htmlType="submit"
            data-cy="create-lifecycle-with-phase-template">
            <CheckOutlined /> {t('global.create')}
          </Button>
          <Button
            className="button"
            onClick={cancelCreateLifecycle}>
            <CloseOutlined /> {t('global.cancel')}
          </Button>
        </FormItem>
      </Form>
    </div>
  </section>
}

export default compose(
  withRouter,
  withTranslation()
)(CreateLifecycle)
