import { gql } from '@apollo/client'

export default gql`
    query subscriptionProducts {
        subscriptionProducts{
            id
            name
            description
            prices{
                id
                amount
                currency
                isPayPerUser
                interval
                defaultQuantity
                lookupKey
            }
        }
    }
`
