import React from 'react'
import { withTranslation } from 'react-i18next'
import compose from '../../../../utils/compose'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Input, Select, Form } from 'antd'
import { returnNested } from '../../../../utils/tools'
import PhaseType from '../phaseType'
import { DetailsPanelCard } from '../../details-panel/detailPanelCard'

const FormItem = Form.Item

class CreatePhase extends React.Component {

  handleCreatePhase = () => {
    const { lifecycle, createPhaseSaga } = this.props
    this.formRef.current.validateFields().then(values => {
      createPhaseSaga(
        returnNested(lifecycle, 'id'),
        values.name,
        values.type
      )
    })
  }

  formRef = React.createRef()

  render() {
    const { t } = this.props

    const phaseTypesOpts = PhaseType.list.map(phaseType => {
      return <Select.Option key={ phaseType.type } value={ phaseType.type }>{ phaseType.name }</Select.Option>
    })

    return (
      <section className="create-phase">
        <DetailsPanelCard>
          <Form ref={this.formRef} onFinish={ this.handleCreatePhase } layout="vertical" hideRequiredMark className="field-container">
            <FormItem
              data-cy="phase-name-input"
              name="name"
              label={ t('global.name') }
              rules={[
                { required: true, message: t('validation.require_field', { name: 'Phase name' }) }
              ]}>
              <Input data-cy="phase-name-input"/>
            </FormItem>
            <FormItem
              name="type"
              label={ t('global.type') }
              rules={[ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Type' }) } ]}
            >
              <Select dropdownClassName="cy-new-phase-type-dropdown" data-cy='new-phase-type-input'>{phaseTypesOpts}</Select>
            </FormItem>
            <FormItem>
              <Button data-cy="add-new-phase-button" htmlType="submit" className="button gutter">
                <CheckOutlined /> { t('global.add') }
              </Button>
              <Button data-cy='add-new-tree-item-cancel'
                className="button"
                onClick={this.props.handleCancel}>
                <CloseOutlined />{t('global.cancel')}
              </Button>
            </FormItem>
          </Form>
        </DetailsPanelCard>
      </section>
    )
  }
}

export default compose(
  withTranslation()
)(CreatePhase)
