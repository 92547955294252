import React, { useContext, useEffect, useRef } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useMutation } from '@apollo/client'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBringForward, faFileCirclePlus, faRecycle, faFileCsv, faFileXmark, faArrowUpRightFromSquare, faFilePdf
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import ProductContext from '../../provider/context/product.context'
import { REQUEST_PRODUCT_EXPORT } from '../../../graphql/mutation/product'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { TProductContext } from '../../interface/product-context.type'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import { getBrowserTimezone } from '../../../shared/util/tools'
import { ExternalSources } from '../../../shared/enum'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'

const GlobalStyle = createGlobalStyle`
  .tools-menu {
    .p-submenu-header {
      padding: 0.25rem 1.3rem;
      color: var(--gray-200);
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
`

type TMenuToolsContainerProps = {
  fromComparison?: boolean
}
export const MenuToolsContainer = ({
  fromComparison = false
}: TMenuToolsContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])

  const toast = useRef<Toast>(null)
  const menu = useRef<Menu>(null)
  const [ trackEventInSegment ] = getSegmentTrack()

  const { updateProduct } = useContext<TProductContext>(ProductContext)
  const {
    productInventory = {},
    totalImpact: { amount: totalImpactAmount = '', unit: totalImpactUnit } = {},
    updateProductDetail
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const { product } = productInventory

  const [
    requestProductExport,
    {
      error: failedRequest,
      data: requestProductExportData,
      loading: requestingProductExport
    }
  ] = useMutation(REQUEST_PRODUCT_EXPORT)
  const requestedProductExport = requestProductExportData?.requestProductExport

  useEffect(() => {
    if (!requestingProductExport && (requestedProductExport || failedRequest)) {
      replaceLabelCallback()
    }
  }, [ requestingProductExport, requestedProductExport, failedRequest ])

  const replaceLabelCallback = () => {
    try {
      if (failedRequest) {
        throw failedRequest
      } else if (requestedProductExport) {
        trackEventInSegment(TrackEventType.DOWNLOADED_EXCEL_RESULTS)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'requestedProductExport', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'requestedProductExport', ns: 'product' }), error?.graphQLErrors)
    }
  }

  const isCarbonTranslatorEnabled = () => totalImpactUnit && totalImpactUnit.includes('kg CO2')

  const items = [
    {
      label: t('labels.header', { context: 'modeAndInsight' }),
      items: [
        {
          key: 0,
          disabled: fromComparison,
          label: t('actions.create', { context: 'scenario' }),
          icon: <FontAwesomeIcon data-cy="menu-item-scenario" icon={faFileCirclePlus} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateProduct({ showCreateScenarioDialog: true, selectedEntity: { id: product?.id, name: product?.name } })
          },
          template: MenuItemComponent
        },
        {
          key: 1,
          label: t('actions.compare', { context: 'product', ns: 'product' }),
          icon: <FontAwesomeIcon data-cy="menu-item-comparison" icon={faBringForward} rotation={90} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateDashboard({ showCreateComparisonDialog: true })
          },
          template: MenuItemComponent
        },
        {
          key: 2,
          disabled: fromComparison,
          label: t('actions.create', { context: 'lifecycle' }),
          icon: <FontAwesomeIcon icon={faRecycle} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateDashboard({ showCreateLifeCycleFromProduct: true })
          },
          template: MenuItemComponent
        },
        {
          key: 3,
          disabled: fromComparison,
          label: t('actions.exportStructure', { context: 'csv' }),
          icon: <FontAwesomeIcon icon={faFileCsv} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateProductDetail({ showExportProductCsv: true })
          },
          template: MenuItemComponent
        }
      ]
    },
    {
      label: t('labels.header', { context: 'output' }),
      items: [
        {
          key: 4,
          disabled: fromComparison,
          label: t('actions.download', { context: 'impactXlsx' }),
          icon: <FontAwesomeIcon icon={faFileXmark} className="text-sm mr-1 text-green-500" />,
          command: () => {
            product?.id && requestProductExport({ variables: { productID: product?.id, timezone: getBrowserTimezone() } })
          },
          template: MenuItemComponent
        },
        {
          key: 5,
          disabled: fromComparison,
          label: t('actions.download', { context: 'productFootprintPdf' }),
          icon: <FontAwesomeIcon icon={faFilePdf} className="text-sm mr-1 text-green-500" />,
          command: () => {
            trackEventInSegment(TrackEventType.DOWNLOADED_PDF_RESULTS)
            updateProductDetail({ showPdfInsightsResult: true })
            toast?.current?.show({
              severity: 'success',
              summary: t('messages.successSummary'),
              detail: t('messages.success', { context: 'downloadInsightsPdf' }),
              life: 3000
            })
          },
          template: MenuItemComponent
        },
        {
          key: 6,
          disabled: fromComparison,
          label: t('labels.carbonTranslator'),
          icon: <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-sm mr-1 text-green-500" />,
          command: () => {
            const carbonTranslatorPath = !isCarbonTranslatorEnabled() ? ExternalSources.EcoChainTranslator
              : `${ExternalSources.EcoChainTranslator}?amount=${totalImpactAmount}&unit=${totalImpactUnit?.replace('CO2 eq', '')}`
            window.open(carbonTranslatorPath)
          },
          template: MenuItemComponent
        }
      ]
    },

  ]

  return (
    <>
      <GlobalStyle />
      <Menu model={items} popup ref={menu} id="popup_menu" className="tools-menu mt-1 w-20rem" />

      <Button
        className="p-button p-component p-button-success my-1"
        label="Tools"
        icon="pi pi-chevron-down"
        iconPos="right"
        onClick={(event: any) => menu?.current?.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        data-testid="menu-tools-dropdown"
        data-cy="product-tools-button"
      />

      <Toast data-testid="request-export-status" ref={toast} position="top-right" />
    </>
  )
}
