import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { Status } from '../../../shared/enum/status'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ImpactBarComponent } from '../../../product/component/impact-bar.component'
import { store } from '../../../../configureStore'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import { setSelectedLifecycleAction } from '../../../../redux/actions/lifecycle.actions'
import { DecimalViewType } from '../../../shared/enum'
import { NotCompatibleImpactIcon } from '../../../shared/component/product/not-compatible-impact-icon'
import { isValid } from '../../../shared/util/tools'

const RootProductNameWrapper = styled.div`
  cursor: pointer;
  &:hover {
    color: var(--primary-500) !important;
  }
`

type TFooterComponent = {
  classNamePrefix?: string
}

export const ModelFooterComponent = ({
  classNamePrefix = 'tree-footer-component'
}: TFooterComponent) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    lifecycleDetail,
    lifecycleTotalImpact,
    getMaxImpactAmount = () => {},
    getImpactType = () => {},
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ productRef, hasProductOverflow, productDom ] = useRefOverflow()
  const { phases = [] } = lifecycleDetail || {}

  const maxImpactAmount = getMaxImpactAmount()
  const totalImpactRatio = lifecycleTotalImpact?.amount ? parseFloat(lifecycleTotalImpact?.amount) / maxImpactAmount : 0
  const impactPercent = `${(Math.abs(totalImpactRatio) * 100).toFixed(2)}%`
  const impactType = `${String(getImpactType())}Bar`

  const isImpactPending = () => phases?.some(phase => phase.inventory?.totalImpact?.status === Status.Pending)
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { lifecycleDetail?.name }
      {' '}
    </TooltipOverflowContent>
  )

  const openDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedLifecycleId = LifecycleSelector.lifecycleId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (lifecycleDetail?.id === selectedLifecycleId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    store.dispatch(setIsDetailsPanelOpenAction(true))
    store.dispatch(setSelectedLifecycleAction(lifecycleDetail))
    updateLifecycleDetail({ showLifecycleDetailPanelOnly: true })
  }

  const footerTitle = lifecycleDetail?.unit
    ? t('labels.unitOfEntityWithAmount', {
      ns: 'common',
      amount: lifecycleDetail?.amount || '1',
      unit: lifecycleDetail?.unit,
      name: lifecycleDetail?.name
    })
    : lifecycleDetail?.name

  return (
    <>
      <Tooltip target={productDom} onBeforeShow={() => hasProductOverflow} position="bottom">{ getTooltipContent() }</Tooltip>
      <div className="flex-none flex align-items-center justify-content-center text-primary-500 bg-primary-50 border-primary-100 border-1 border-round h-2rem w-2rem">
        <FontAwesomeIcon data-testid="tree-footer-icon" icon={faRecycle} size="lg" />
      </div>
      <RootProductNameWrapper
        ref={productRef}
        data-testid="tree-footer-name"
        data-cy="inventory-footer-name"
        onClick={openDetailPanel}
        className={`flex-grow-1 align-items-center px-3 pt-1 text-base font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis ${classNamePrefix}-name`}
      >
        {footerTitle}
      </RootProductNameWrapper>
      <div className="flex-none flex align-items-center text-base">
        { t('labels.total', { ns: 'common' }) }
        {' '}
      </div>
      <div data-cy="inventory-footer-impact" data-testid="inventory-footer-impact" className={`flex-none flex align-items-center font-medium pl-2 pr-4 text-base ${classNamePrefix}-impact`}>
        { isImpactPending() && (
          <div className="flex justify-content-center align-items-center pr-2">
            <i className="pi pi-spin pi-spinner text-lg text-primary-500" />
          </div>
        ) }
        { lifecycleTotalImpact?.status === Status.NotCompatible && (
          <NotCompatibleImpactIcon hasChild addRightSpace tooltipPosition="top" />
        )}
        { isValid(lifecycleTotalImpact?.amount) && <DecimalPointComponent value={lifecycleTotalImpact?.amount} enableTooltip decimalViewType={DecimalViewType.NumericValue} /> }
        { lifecycleTotalImpact?.unit && <>{ lifecycleTotalImpact?.unit }</> }
      </div>
      <div className="flex-none flex align-items-center justify-content-start text-gray-700 text-base w-11rem h-full pl-2">
        <div data-cy="item-impact-bar" data-testid="item-impact-bar" className="flex-none flex align-items-center justify-content-start w-10rem pr-2 line-height">
          <ImpactBarComponent nodeKey="total" impactPercent={impactPercent} impactType={impactType} inventoryKey="total" disabledTooltip />
        </div>
      </div>
    </>
  )
}
