import React from 'react'
import classnames from 'classnames'
import { PlusOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'
import { withTranslation } from 'react-i18next'

import CropLongText from '../../helpers/cropLongText'

import compose from '../../../utils/compose'
import { normalize } from '../../../utils/tools'

import { NavigateBackV1 } from './navigateBackV1'
import { matchLocation } from '../../../utils/location'
import { ObjectListPageLocation, ProductListPageLocation, LifecycleListPageLocation } from '../../shared/locations'

const PageHeader = ({
  t,
  headerType,
  headerTitle,
  isShowAddButton,
  addCustomButton,
  goToPreviousPage,
  createButtonHandler,
  onClick
}) => {

  const isV1Ui = () => {
    return matchLocation(ObjectListPageLocation) ||
    matchLocation(ProductListPageLocation) ||
    matchLocation(LifecycleListPageLocation)
  }

  const addButton = <Button
    size="large"
    type="primary"
    onClick={ createButtonHandler }
    data-cy={`add-new-${headerType}`}
    className='add-new-page-header-button add-new button primary'
    icon={<PlusOutlined />} >
    { t('model.create', { context: headerType }) }
  </Button>

  const onClickHandler = evt => {
    if (onClick) {
      evt.stopPropagation()
      onClick()
    }
  }
  return (
    <React.Fragment>
      {
        headerTitle ? <Row
          type="flex"
          align="middle"
          data-cy="page-header-container"
          className={ classnames('page-header-wrapper', { 'has-back-button': goToPreviousPage, 'v1': isV1Ui() }) }
          justify="space-between">
          <Col flex="1 0 0">
            { goToPreviousPage && <NavigateBackV1 /> }
            <CropLongText tooltipText={headerTitle} fontSize='28px'>
              <h2
                className={classnames('page-header-title', { 'clickable': onClick, 'with-back-button': goToPreviousPage })}
                onClick={ evt => onClickHandler(evt) }
                data-cy={`nav-link-named-${normalize(headerTitle)}`}
              >{ headerTitle }</h2>
            </CropLongText>
          </Col>

          <Col flex="1 1 1" className='actions align-right'>
            { addCustomButton }
            { isShowAddButton && addButton }
          </Col>
        </Row> : null
      }
    </React.Fragment>
  )
}

export default compose(
  withTranslation()
)(PageHeader)
