import { gql } from '@apollo/client'
import user from '../fragment/user'

export default gql`
  query user {
    user {
      ...userFragment
    }
  }
  ${user}
`
