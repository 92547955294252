import React from 'react'
import compose from '../../utils/compose'
import { Trans, withTranslation } from 'react-i18next'
import { PlusOutlined, DeleteOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Select, Table, Spin } from 'antd'
import { parsePolicyName, normalize, safeArray, filterOptionByValue } from '../../utils/tools'
import { RESOURCE_TYPE, TRACK_EVENT_TYPE } from '../../utils/const'

class PermissionManager extends React.Component {
  state = {
    accountPermissionsListForAddRole: null,
    isAddingNewRolePermission: false,
    selectedRoleIdToAdd: null
  }

  componentDidMount = () => {
    const { trackPageSaga, setAccountPermissionsSaga, accountId, canManageAccount } = this.props
    if (canManageAccount) setAccountPermissionsSaga(accountId)
    trackPageSaga(TRACK_EVENT_TYPE.VIEWED_PERMISSIONS_SETTINGS)
  }

  handleAssignment = (policies, permission) => {
    const { accountId } = this.props
    if (this.state.selectedRoleIdToAdd) {
      this.createNewRole(accountId, policies)
    } else if (policies.length === 0) {
      this.removeRole(permission.id)
    } else {
      this.changeRolePermission(permission.ownerId, permission.id, policies)
    }
  }

  handleRemoveRole = permissionId => {
    if (permissionId) {
      this.removeRole(permissionId)
    } else {
      this.cleanState()
    }
  }

  changeRolePermission = (ownerId, permissionId, policies) => {
    const { changeAccountPermissionSaga } = this.props
    changeAccountPermissionSaga( permissionId, ownerId, policies, this.cleanState)
  }

  createNewRole = (accountId, policies) => {
    const { addAccountPermissionSaga } = this.props
    addAccountPermissionSaga( this.state.selectedRoleIdToAdd, policies, accountId, RESOURCE_TYPE.ACCOUNT, this.cleanState)
  }

  removeRole = permissionId => {
    const { removeAccountPermissionSaga } = this.props
    if (permissionId) {
      removeAccountPermissionSaga(permissionId)
    }
  }

  getAccountPoliciesSelect = (policies, permission) => {
    const { selectedRoleIdToAdd } = this.state
    const { accountTypePoliciesList, t } = this.props
    const dataCyName = permission.key === 0 ? 'new' : normalize(permission.name)
    const policyList = accountTypePoliciesList && accountTypePoliciesList.map((policy, index) => (
      <Select.Option className={`cy-add-${normalize(policy.policy)}-to-${dataCyName}`} key={`${index}:${permission.ownerId}:${permission.id}:${policy.policy}`} value={policy.policy}><Trans>resourceType.{parsePolicyName(policy.resourceType)}</Trans> : <Trans>policy.{parsePolicyName(policy.policy)}</Trans></Select.Option>
    ))

    return <Select
      className="policies"
      data-cy={`add-roles-to-${dataCyName}`}
      defaultValue={policies}
      disabled={ !permission.ownerId && !selectedRoleIdToAdd ? true : false}
      mode="multiple"
      key={permission.ownerId}
      onChange={selectedPolicies => {
        this.handleAssignment(selectedPolicies, permission)
      }}
      placeholder={t('permission_manager.permissions_placeholder')}
    >
      {policyList}
    </Select>
  }

  getTableColumns() {
    const { t } = this.props

    return [
      { title: t('account_settings.role_name'), dataIndex: 'name', width: '30%', render: (text, record) => {
        return record.editable ? this.getAccountRolesSelect() : text
      }  },
      { title: t('account_settings.role_permissions'), dataIndex: 'policies', width: '60%', render: (policies, permission) => this.getAccountPoliciesSelect(policies, permission) },
      { title: t('account_settings.action'), align: 'center', className: 'action', key: 'action', width: '30px',  render: (policies, permission) => (
        <Button type="button" className="button icon transparent" data-permissionid={permission.id} onClick={() => this.handleRemoveRole(permission.id)}>
          {permission.id ? <DeleteOutlined /> : <CloseOutlined />}
        </Button>
      ) },
    ]
  }

  showAddPermissionFields = () => {
    this.setState({ isAddingNewRolePermission: true }, this.getMappedRolesList)
  }

  getMappedRolesList = () => {
    const { isAddingNewRolePermission } = this.state
    const { accountPermissionsList } = this.props
    const accountPermissionsListForAddRole = [ ...accountPermissionsList ]

    if (isAddingNewRolePermission) {
      accountPermissionsListForAddRole.unshift({ key: 0, id: '', ownerId: '', name: '', policies: [], editable: true })
    }
    this.setState({ accountPermissionsListForAddRole })
  }

  getAccountRolesSelect = () => {
    const { accountPermissionsListForAddRole, isAddingNewRolePermission } = this.state
    const {
      accountRolesList,
      accountUsersList,
      accountPermissionsList: currentAccountPermissionsList,
      t
    } = this.props
    const accountPermissionsList = isAddingNewRolePermission ? accountPermissionsListForAddRole :currentAccountPermissionsList
    const filteredRoleList = safeArray(accountRolesList)
      .filter(role => !safeArray(accountPermissionsList)
        .some(permission => permission.ownerId === role.id))
    const filteredUserList = safeArray(accountUsersList)
      .filter(user => !safeArray(accountPermissionsList)
        .some(permission => permission.ownerId === user.id))

    return <Select
      allowClear
      data-cy="select-new-user-role-apiclient"
      dropdownClassName="cy-list-of-users-roles-apiclient"
      filterOption={(inputValue, option) => filterOptionByValue(inputValue, option)}
      notFoundContent={t('permission_manager.all_assigned')}
      onChange={roleId => this.setState({ selectedRoleIdToAdd: roleId })}
      placeholder={t('permission_manager.owner_placeholder')}
      className="add-owner"
      showSearch>
      <Select.OptGroup label={t('permission_manager.roles')} key="roles">
        {safeArray(filteredRoleList).map(role => (<Select.Option className={`cy-role-${normalize(role.label)}`} key={role.id} value={role.id}>{role.label}</Select.Option>))}
      </Select.OptGroup>
      <Select.OptGroup label={t('permission_manager.users')} key="users">
        {safeArray(filteredUserList).map(user => (<Select.Option className={`cy-user-${normalize(user.name)}`} key={user.id} value={user.id}>{user.name}</Select.Option>))}
      </Select.OptGroup>
    </Select>
  }

  cleanState = () => {
    this.setState({ accountPermissionsListForAddRole: null, selectedRoleIdToAdd: null, isAddingNewRolePermission: false })
  }

  render() {
    const { accountPermissionsListForAddRole, isAddingNewRolePermission } = this.state
    const { accountPermissionsList, isDataViaNetworkRequesting, t } = this.props

    const accountPermissionsListDataSource = isAddingNewRolePermission ? accountPermissionsListForAddRole : accountPermissionsList

    return (
      <section className="permission-popup">
        <Spin
          spinning={isDataViaNetworkRequesting}
          indicator={<LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin/>}
        >
          {accountPermissionsListDataSource ?
            <Table
              title={() => <Button data-cy="add-permission-button" disabled={isAddingNewRolePermission} type="primary" className="button primary" onClick={this.showAddPermissionFields} icon={<PlusOutlined />}> {t('account_settings.add_permission')}</Button>}
              dataSource={safeArray(accountPermissionsListDataSource).map(permission => ({ key: permission.id, ...permission }))}
              data-cy="account-permissions-table"
              columns={this.getTableColumns()}
              className="table-container"
              pagination={false}
              rowKey={(record, index) => `${index}:${record.id}`}
            />
            : null}
        </Spin>
      </section>
    )
  }
}

export { PermissionManager }
export default compose(
  withTranslation(),
)(PermissionManager)
