import React, { useState } from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { isValid } from '../../util/tools'
import { SortingOrder } from '../../../../__generated__/graphql'

const TableHeaderComponentWrap = styled.div`

  .table-header-label {
    padding: 0.188rem 0;
  }

  &.table-sortable-column:hover {
    cursor: pointer;
    span {
      color: var(--blue-500) !important;
    }
  }

`

type TableHeaderComponentProp<T> = {
  label: string,
  sortBy?: T,
  sortDisabled?: boolean,
  selectedSortBy?: T,
  selectedSortOrder?: SortingOrder,
  onSortClick?: (param1: T, param2: SortingOrder) => void
}

export const TableHeaderComponent = <T extends string>({
  label,
  sortBy,
  sortDisabled = false,
  selectedSortBy,
  selectedSortOrder,
  onSortClick
}: TableHeaderComponentProp<T>) => {
  const [ isMouseOver, setIsMouseOver ] = useState(false)

  const showArrow = (isMouseOver || selectedSortBy === sortBy) && sortBy && !sortDisabled
  const isAsc = selectedSortOrder === SortingOrder.Asc
  const isDesc = selectedSortOrder === SortingOrder.Desc
  const sortOrderTo: SortingOrder = isAsc ? SortingOrder.Desc : SortingOrder.Asc

  return (
    <TableHeaderComponentWrap
      data-testid="table-header-wrap"
      className={classNames('flex font-normal', { 'table-sortable-column': isValid(showArrow) && showArrow })}
      onClick={() => onSortClick && sortBy && !sortDisabled && onSortClick(sortBy, sortOrderTo)}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <span data-testid="table-header-label" className="mr-1 text-gray-300 text-sm table-header-label">{ label }</span>
      {showArrow && (
        <span className="ml-1 text-gray-600">
          { isAsc && <FontAwesomeIcon data-testid="table-header-asc" className="text-sm" icon={faArrowDown} /> }
          { isDesc && <FontAwesomeIcon data-testid="table-header-desc" className="text-sm" icon={faArrowUp} /> }
        </span>
      )}
    </TableHeaderComponentWrap>
  )
}
