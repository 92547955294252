import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { notification, Tree } from 'antd'
import { withTranslation } from 'react-i18next'

import CropLongText from '../../helpers/cropLongText.container'

import compose from '../../../utils/compose'
import { notify } from '../../../utils/tools'
import resource from '../../../utils/resource/resource'
import { REFERENCE_CATEGORY_TYPES } from '../../../utils/const'

class CategoryFilter extends React.Component {
  state = {
    treeData: []
  }
  mapCategory = category => ({
    title: <CropLongText
      className="category-name"
      tooltipText={category.name}>
      { category.name }
    </CropLongText>,
    key: category.id,
    isLeaf: category.isLeaf
  })
  onSelect = selectedKeys => {
    const selectedCategoryID = (Array.isArray(selectedKeys) && selectedKeys.shift()) || ''
    this.props.handleSelectCategory(selectedCategoryID)
  }

  componentDidMount() {
    const { isElementaryFlow, t } = this.props
    resource.queryByParams('categories', { parentId: '', types: [ isElementaryFlow ? REFERENCE_CATEGORY_TYPES.ELEMENTARY_FLOW : REFERENCE_CATEGORY_TYPES.PROCESS ] })
      .then(data => {
        this.setState({
          treeData: data.map(this.mapCategory)
        })
      }).catch(({ graphQLErrors: message }) => {
        notify({ message, notification, t })
      })
  }

  onLoadData = treeNode => {
    const { isElementaryFlow } = this.props
    return new Promise(resolve => {
      resource.queryByParams('categories', { parentId: treeNode.key, types: [ isElementaryFlow ? REFERENCE_CATEGORY_TYPES.ELEMENTARY_FLOW : REFERENCE_CATEGORY_TYPES.PROCESS ]  })
        .then(data => {
          const treeData = this.updateTreeData(this.state.treeData, treeNode.key, data.map(this.mapCategory))
          this.setState({
            treeData,
          })
          resolve()
        })
    })
  }

  updateTreeData(treeData, key, children) {
    return treeData.map(node => {
      if (node.key === key) return { ...node, children }
      if (node.children) return { ...node, children: this.updateTreeData(node.children, key, children) }
      return node
    })
  }

  render() {
    return (
      <Tree
        className='search-filter__category'
        switcherIcon={<DownOutlined />}
        onSelect={ this.onSelect }
        loadData={ this.onLoadData }
        treeData={this.state.treeData}
        selectedKeys={ this.props.selectedCategory }
      />
    )
  }
}

export default compose(
  withTranslation()
)(CategoryFilter)
