import React from 'react'
import { Paginator } from 'primereact/paginator'
import { Ripple } from 'primereact/ripple'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

const PagePaginatorWrapper = styled.div`
  .p-paginator {
    background: transparent;
    padding: 0.5rem 0;
  }

  .p-paginator-prev {
    width: 4.15rem;
  }

  .p-paginator-next {
    width: 3rem;
  }

  .p-paginator-element:focus {
    box-shadow: none;
  }
`

type TPagePaginatorProps = {
  first?: number,
  pageSize?: number,
  pageLinkSize?: number,
  totalItems?: number,
  onPageChange?: (event: any) => void
}

export const PagePaginatorComponent = ({
  first = 0,
  pageSize = 0,
  pageLinkSize = 12,
  totalItems = 0,
  onPageChange = () => {}
}: TPagePaginatorProps) => {
  const paginatorTemplate = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        <span className="p-3">Previous</span>
        <Ripple />
      </button>
    ),
    NextPageLink: (options: any) => (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        <span className="p-3">Next</span>
        <Ripple />
      </button>
    ),
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0)
        || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
        const className = classNames(options.className, { 'p-disabled': true })

        return <span className={className} style={{ userSelect: 'none' }}>...</span>
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      )
    }
  }

  return (
    <PagePaginatorWrapper data-testid="page-paginator-wrapper">
      <Paginator
        first={first}
        rows={pageSize}
        pageLinkSize={pageLinkSize}
        totalRecords={totalItems}
        template={paginatorTemplate}
        onPageChange={onPageChange}
      >
        <></>
      </Paginator>
    </PagePaginatorWrapper>
  )
}
