import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuDownloadInventoryContainer } from '../../container/menu/menu-download-inventory.container'
import { MenuCompareComponent } from '../menu/menu-compare.component'
import { DecimalViewTogglerComponent } from '../../../shared/component/general/decimal-view-toggler.component'

interface TreePanelHeaderComponentProps {
  treeRef?: React.RefObject<HTMLDivElement | null>
}
export const TreePanelHeaderComponent = ({ treeRef }: TreePanelHeaderComponentProps) => {
  const { t } = useTranslation([ 'product' ])

  return (
    <div className="flex align-items-center justify-content-start w-full h-full" data-html2canvas-ignore>
      <div className="flex flex-grow-1 text-gray-700 text-2xl">
        { t('labels.headerModal') }
      </div>
      <div className="flex flex-none gap-3">
        <MenuCompareComponent />

        <DecimalViewTogglerComponent />

        <MenuDownloadInventoryContainer treeRef={treeRef} />
      </div>
    </div>
  )
}
