import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Column } from 'primereact/column'

import { DataTable } from 'primereact/datatable'
import { useTranslation } from 'react-i18next'
import { PRODUCT_IMPORT_COLUMNS } from '../../../../graphql/query'

const ImportExampleStructure = styled.div`
  .p-accordion-tab {
    border: 1px solid var(--gray-200);
    border-radius: 10px;
    padding: 0.5rem;
  }
  .p-accordion-header-link {
    padding: 0.5rem !important;
    border: none !important;
    box-shadow: none !important;
  }
  .p-accordion-content {
    padding: 0.5rem
  }

  .p-datatable-wrapper {
    border: 1px solid var(--gray-200);
    border-radius: 0.75rem;

    .p-datatable-thead > tr > th {
      background: none;
      padding: 1rem 1rem 0.5rem;
    }

    .p-datatable-tbody > tr > td {
      vertical-align: top;
      padding: 0.5rem 1rem;
    }
    .p-datatable-tbody > tr:not(:first-child) > td {
      border: 1px solid var(--gray-200);
      border-width: 1px 0 0 0;
    }
  }
`

export const ImportExampleStructureComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { loading, data: { productImportColumns = [] } = {} } = useQuery(PRODUCT_IMPORT_COLUMNS)

  const getHeaderTemplate = () => (
    <div className="flex w-full">
      <div className="flex-none w-2rem h-2rem border-circle px-2 py-1 bg-primary-500 text-white">
        <i className="pi pi-file-excel"></i>
      </div>
      <div className="flex-none text-sm font-semibold pl-2 py-1">
        { t('labels.title', { context: 'exampleFileStructure' })}
      </div>
      <div className="flex-none text-sm font-light pl-2 py-1">
        { t('labels.subtitle', { context: 'structureYourFile' })}
      </div>
    </div>
  )

  const nameBodyTemplate = (rowData: any) => (rowData?.required ? `${rowData?.name} *` : rowData?.name)

  const altNamesBodyTemplate = (rowData: any) => rowData?.altNames.join(', ')

  return (
    <ImportExampleStructure>
      <Accordion multiple activeIndex={[]}>
        <AccordionTab data-testid="import-example-structure" headerTemplate={getHeaderTemplate()}>
          {!loading && productImportColumns && (
            <DataTable value={productImportColumns} responsiveLayout="scroll">
              <Column header={t('labels.name')} body={nameBodyTemplate} />
              <Column header={t('labels.header', { context: 'altColumnName' })} body={altNamesBodyTemplate} />
              <Column field="description" header={t('labels.header', { context: 'description' })} style={{ width: '28rem' }} />
            </DataTable>
          )}
        </AccordionTab>
      </Accordion>
    </ImportExampleStructure>
  )
}
