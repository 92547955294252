import React from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

const AddNodeWrapper = styled.div`
  width: '100%';

  border: dashed 2px var(--gray-300);
  border-radius: 0.25rem;
  color: var(--gray-500);
  text-align: center;

  &.active {
    color: var(--primary-500);
  }

  :hover {
    color: var(--primary-500);
    border-color: var(--primary-500);
    cursor: pointer;
  }
`

type AddNodeWrapperProps = {
  active?: boolean,
  children?: React.ReactNode,
  onClick?: (event: any) => void,
  dataTestId?: string,
  dataCy?: string,
  className?: string
}
export const AddNodeWrapperLayout = ({
  active,
  children,
  onClick,
  dataTestId,
  dataCy,
  className = ''
}: AddNodeWrapperProps) => (
  <AddNodeWrapper
    className={classNames(`flex gap-2 justify-content-end w-full px-3 ${className}`, { active })}
    onClick={onClick}
    data-testid={dataTestId}
    data-cy={dataCy}
  >
    { children }
  </AddNodeWrapper>
)
