import React from 'react'
import { AiOutlinePlus, AiOutlineDelete, AiOutlineEdit, AiOutlineInfoCircle } from 'react-icons/ai'
import { CheckOutlined, CloseOutlined, } from '@ant-design/icons'
import { Button, Col, Collapse, notification, Row, Select, Tooltip, Form } from 'antd'
import classnames from 'classnames'
import compose from '../../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../../utils/with-router'
import { handleModal, isEmpty, normalize, notify, floatToString, returnNested, safeArray } from '../../../../../utils/tools'
import resource from '../../../../../utils/resource/resource'
import PropTypes from 'prop-types'
import { VALIDATION } from '../../../../../utils/const'
import CropLongText from '../../../../helpers/cropLongText.container'
import SelectWrapper from '../../../../helpers/form/selectWrapper'
import InputWrapper from '../../../../helpers/form/inputWrapper'
import { DetailsPanelCard } from '../../detailPanelCard'

class ProductReferenceProperties extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refUnitsList: [],
      refPropsOptionsList: [],
      refUnitsOptionsList: [],
      panelActiveKey: [],
      isAddingNewProperty: false
    }
  }

  static getDerivedStateFromProps(props) {
    const { referencePropertiesList, setReferencePropertiesList, t  } = props
    let refOptions = []
    if (isEmpty(referencePropertiesList)) {
      //TODO move this query to saga
      resource.queryByParams('referenceProperties')
        .then(referencePropertiesListFromServer => {
          setReferencePropertiesList(referencePropertiesListFromServer)
        })
        .catch(({ message }) => notify({ message, notification, t }))
    } else {
      referencePropertiesList.sort((a, b) => a.name.localeCompare(b.name))

      referencePropertiesList.forEach(el => {
        refOptions.push(<Select.Option className={`cy-property-${normalize(el.name)}`} key={el.id}>
          <CropLongText tooltipText={el.name}>{el.name}</CropLongText>
        </Select.Option>)
      })
    }

    return { refPropsOptionsList: refOptions }
  }

  setRefUnitsOptionList(selectedRefPropId) {
    const refProp = this.getPropertyFromList(selectedRefPropId)

    const unitsOptions = safeArray(returnNested(refProp, 'referenceUnits')).map(el => <Select.Option key={el.id}><span>{el.name}</span></Select.Option>)
    this.setState({ refUnitsOptionsList: unitsOptions })
  }

  getRefUnitsOptionList(refPropId) {
    const refProp = this.getPropertyFromList(refPropId)
    return safeArray(returnNested(refProp, 'referenceUnits')).map(el => <Select.Option key={el.id}>{el.name}</Select.Option>)
  }

  handleRefPropertySelect = selectedRefPropId => {
    const selectedRefProp = this.getPropertyFromList(selectedRefPropId)
    const defaultUnit = this.getUnitDefaultValue(selectedRefProp)
    this.formRef.current.setFieldsValue({ propertyUnitId: returnNested(defaultUnit, 'id') })
    this.setState({ refUnitsList: returnNested(selectedRefProp, 'referenceUnits') }, () => {
      this.setRefUnitsOptionList(selectedRefPropId)
    })
  }

  getPropertyUnitFromList = selectedRefUnitId => {
    const { refUnitsList } = this.state
    return refUnitsList.find(el => el.id === selectedRefUnitId)
  }

  getPropertyFromList = selectedRefPropId => {
    const { referencePropertiesList } = this.props
    return safeArray(referencePropertiesList).find(el => el.id === selectedRefPropId)
  }

  handleSaveProductProperty = () => {
    const { selectedProduct, changeProductPropertySaga, addProductPropertySaga, productMutator } = this.props

    this.formRef.current.validateFields().then(values => {
      const propertyUnit = this.getPropertyUnitFromList(values.propertyUnitId)
      const hasProperty = safeArray(returnNested(selectedProduct, 'productProperties'))
        .find(el => el.referenceProperty.id === values.propertyId, )
      const saga = hasProperty ? changeProductPropertySaga : addProductPropertySaga
      saga({
        productMutator,
        productId: returnNested(selectedProduct, 'id'),
        propertyId: values.propertyId,
        conversionFactor: floatToString(values.conversionFactor),
        propertyUnitId: returnNested(propertyUnit, 'id'),
        skipMessage: false,
        callback: () => {
          this.handleUpdate()
        }
      })
      this.formRef.current.resetFields()
    })
  }

  handleRemoveProductProperty(evt, referencePropertyId) {
    const { selectedProduct, productMutator, t, removeProductPropertySaga } = this.props
    evt.stopPropagation()

    handleModal({
      title: t('model.product_property_remove_title'),
      content: t('model.product_property_remove_message'),
      onOk: () => {
        removeProductPropertySaga(productMutator, selectedProduct.id, referencePropertyId, this.handleUpdate)
      }
    })
  }

  getUnitDefaultValue(chosenProperty) {
    return safeArray(returnNested(chosenProperty, 'referenceUnits')).find(el => el.conversionUnitID === el.id)
  }

  handleUpdate = () => {
    const { updatePageItems } = this.props
    this.handleResetProperty()
    updatePageItems()
  }

  filterOptionHandler(input, option) {
    const optionValue = returnNested(option, 'children', 'props', 'children')
    return optionValue && optionValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleResetProperty = () => {
    this.setState({
      isAddingNewProperty: false,
      refUnitsOptionsList: null,
      defaultValueForRefProp: null,
      panelActiveKey: null
    })
    this.formRef.current.resetFields()
  }

  handleAddProperty = () => {
    this.handleResetProperty()
    this.setState({ isAddingNewProperty: true, panelActiveKey: null })
  }

  setChosenRefProp = refPropId => {
    const isClosingEdit = refPropId === this.state.panelActiveKey
    const { setFieldsValue } = this.formRef.current
    const selectedProperty = safeArray(returnNested(this.props.selectedProduct, 'productProperties'))
      .find(el => el.referenceProperty.id === refPropId)
    this.handleResetProperty()
    this.setState({ panelActiveKey: isClosingEdit ? [] : refPropId })
    !isClosingEdit && this.handleRefPropertySelect(refPropId)
    setFieldsValue({
      propertyId: returnNested(selectedProperty, 'referenceProperty', 'id'),
      propertyUnitId: returnNested(selectedProperty, 'referenceUnit', 'id'),
      conversionFactor: returnNested(selectedProperty, 'conversionFactor')
    })
  }

  formRef = React.createRef()
  render() {
    const {
      isAddingNewProperty,
      refPropsOptionsList,
      refUnitsOptionsList,
      panelActiveKey
    } = this.state
    const {  selectedProduct, isDetailsPanelReadOnly, t } = this.props
    const isCollapsibleDisabled = isDetailsPanelReadOnly ? 'disabled' : undefined
    const isUnitListLoaded = Array.isArray(refUnitsOptionsList)
    const { productProperties = [], referenceProduct, hasInventory } = selectedProduct || {}
    const buttonActions = <div className='product-property-action'>
      <Button className="button button-right-margin" htmlType="submit" icon={<CheckOutlined />} data-cy="save-product-property-button">{t('global.save')}</Button>
      <Button className="button" onClick={this.handleResetProperty} icon={<CloseOutlined />} data-cy="cancel-product-property-button">{t('global.cancel')}</Button>
    </div>
    const propertiesListPanel = productProperties.length ? productProperties.map((property, index) => (
      <Collapse.Panel showArrow={false} collapsible={isCollapsibleDisabled} header={
        <>
          <Tooltip placement="top" title={ t('model.productPropertyFor', { productName: selectedProduct?.name, productUnit: selectedProduct?.unit }) } mouseEnterDelay={ 1 }>
            {!index && (<Row className="input-text-style">
              <Col className="property-for">
                { t('model.productPropertyFor', { productName: selectedProduct?.name, productUnit: selectedProduct?.unit }) }
              </Col>
            </Row>)}
          </Tooltip>
          <Tooltip placement="top" title={ `${ property.referenceProperty.name } ${ property.conversionFactor } ${ property.referenceUnit.name }` } mouseEnterDelay={ 1 }>
            <Row className="input-text-style property-row" data-cy={ `product-property-${ property.referenceProperty.name }` }>
              <Col className="property-name" span={ 11 }>{ property.referenceProperty.name }</Col>
              <Col className="property-amount text-right" span={ 4 }>{ property.conversionFactor }</Col>
              <Col className="property-unit" span={ 4 }>&nbsp;{ property.referenceUnit.name }</Col>
              <Col className="property-actions" span={ 5 }>
                {!isDetailsPanelReadOnly && <React.Fragment>
                  <Button
                    className="button transparent icon edit-property"
                    data-cy='edit-property'
                    onClick={ () => this.setChosenRefProp(property.referenceProperty?.id) }
                    icon={<AiOutlineEdit size={16} />}
                  />
                  {((property.referenceProperty?.id !== referenceProduct?.referenceProperty?.id) || hasInventory) ? (<Button
                    className="button transparent icon remove-property"
                    data-cy='remove-property'
                    onClick={ evt => this.handleRemoveProductProperty(evt, property.referenceProperty.id) }
                    icon={<AiOutlineDelete size={16} />}
                  />) : (<Tooltip placement='bottom' title={t('search_reference_product.referencePropertyRequired')}>
                    <Button
                      className="button transparent icon remove-property"
                      data-cy='info-property'
                      icon={<AiOutlineInfoCircle size={16} />} />
                  </Tooltip>)}
                </React.Fragment>}
              </Col>
            </Row>
          </Tooltip>
        </> }
      key={ property.referenceProperty.id }
      data-cy="product-property-item"
      className="property-item-header">
        <div className='select-wrapper'>
          <SelectWrapper
            isFieldEditable={true}
            disabled={ true }
            dataCy="product-property"
            fieldLabel= {t('global.property')}
            fieldName= "propertyId"
          >
            {refPropsOptionsList}
          </SelectWrapper>
        </div>
        <div className='select-wrapper'>
          <SelectWrapper
            isFieldEditable={true}
            showSearch={true}
            dataCy="product-property-unit"
            fieldLabel= {t('global.unit')}
            fieldName="propertyUnitId"
            fieldRules= {[
              { required: true, message: t('validation.is_required', { name: 'Property Unit' }) },
            ]}
            filterOption={(input, option) => this.filterOptionHandler(input, option)}
            onPressEscape={this.handleResetProperty}
          >
            {this.getRefUnitsOptionList(returnNested(property, 'referenceProperty', 'id'))}
          </SelectWrapper>
        </div>

        <InputWrapper
          isFieldEditable={true}
          dataCy='product-property-amount'
          fieldLabel= {t('global.amount')}
          fieldName="conversionFactor"
          fieldRules= {[
            { required: true, message: t('validation.is_required', { name: 'Property Amount' }) },
            { validator: async (rule, value) => {
              if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
                throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Property Amount' }))
              }
            } }
          ]}
          type='number'
          onPressEscape={this.handleResetProperty}
        />
        {buttonActions}
      </Collapse.Panel>)
    ) :
      <Collapse.Panel
        showArrow={false}
        collapsible={isCollapsibleDisabled}
        className='property-item-header property-item-header--empty'
        header={t('model.productPropertyEmpty')} />

    return (
      <DetailsPanelCard
        className="reference-properties"
        action={
          isEmpty(panelActiveKey) &&
          <Tooltip title={t('model.product_property_new_property')}>
            <Button
              data-cy={`add-property-button-to-${normalize(selectedProduct && selectedProduct.name)}`}
              className="add button"
              onClick={this.handleAddProperty}
              type="button"
              hidden={isDetailsPanelReadOnly}>
              <AiOutlinePlus size={20} />
            </Button>
          </Tooltip>
        }
        title={t('model.product_property_title')}>
        <Form
          ref={this.formRef}
          layout="vertical"
          hideRequiredMark
          className="field-container"
          onFinish={this.handleSaveProductProperty}
        >
          <div data-cy="properties-list">
            { (!isAddingNewProperty && propertiesListPanel) &&
              <Collapse
                activeKey={panelActiveKey}
                bordered={false}
                accordion
                className="product-reference-properties-list"
              >
                {propertiesListPanel}
              </Collapse> }
          </div>
          <div data-cy="new-property-container">
            {
              isAddingNewProperty ?
                <React.Fragment>
                  <div className='select-wrapper reference-properties__formHeader'>
                    { t('model.productPropertyFor', { productName: selectedProduct.name, productUnit: selectedProduct.unit }) }
                  </div>
                  <div className='select-wrapper'>
                    <SelectWrapper
                      isFieldEditable={true}
                      showSearch={true}
                      dataCy="product-property"
                      fieldLabel= {t('global.name')}
                      fieldName="propertyId"
                      fieldRules= {[
                        { required: true, message: t('validation.is_required', { name: 'Property' }) },
                      ]}
                      dropdownMatchSelectWidth={false}
                      onSelect={this.handleRefPropertySelect}
                      placeholder={t('model.product_property_select_property')}
                      filterOption={(input, option) => this.filterOptionHandler(input, option)}
                      dropdownStyle={{ bottom: '0px' }}
                      onPressEscape={this.handleResetProperty}
                    >
                      {refPropsOptionsList}
                    </SelectWrapper>
                    {!isUnitListLoaded &&
                      <div className='product-property-action'>
                        <Button
                          className="button"
                          onClick={this.handleResetProperty}
                          icon={<CloseOutlined />}
                          data-cy="cancel-product-property-button"
                        >
                          {t('global.cancel')}
                        </Button>
                      </div>
                    }
                  </div>

                  <div className={classnames({ hidden: !isUnitListLoaded })}>
                    <div className='select-wrapper'>
                      <SelectWrapper
                        isFieldEditable={true}
                        showSearch={true}
                        dataCy="product-property-unit"
                        fieldLabel= {t('global.unit')}
                        fieldName="propertyUnitId"
                        fieldRules= {[
                          { required: true, message: t('validation.is_required', { name: 'Property Unit' }) },
                        ]}
                        disabled={!isUnitListLoaded}
                        placeholder={t('model.product_property_select_unit')}
                        filterOption={(input, option) => this.filterOptionHandler(input, option)}
                        onPressEscape={this.handleResetProperty}
                      >
                        {refUnitsOptionsList}
                      </SelectWrapper>
                    </div>
                    <InputWrapper
                      isFieldEditable={true}
                      dataCy='product-property-amount'
                      fieldLabel= {t('global.amount')}
                      fieldName="conversionFactor"
                      fieldRules= {[
                        { required: true, message: t('validation.is_required', { name: 'Property Amount' }) },
                        { validator: async (rule, value) => {
                          if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
                            throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Property Amount' }))
                          }
                        } }
                      ]}
                      type='number'
                      onPressEscape={this.handleResetProperty}
                    />
                    {buttonActions}
                  </div>
                </React.Fragment>
                :
                <></>

            }
          </div>
        </Form>
      </DetailsPanelCard>
    )
  }
}

ProductReferenceProperties.propTypes = {
  t: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  updatePageItems: PropTypes.func.isRequired,
  productMutator: PropTypes.object.isRequired,
  isDetailsPanelReadOnly: PropTypes.bool.isRequired,
  addProductPropertySaga: PropTypes.func.isRequired,
  changeProductPropertySaga: PropTypes.func.isRequired,
  removeProductPropertySaga: PropTypes.func.isRequired,
}

export { ProductReferenceProperties }
export default compose(
  withRouter,
  withTranslation()
)(ProductReferenceProperties)
