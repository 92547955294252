import md5 from 'md5'
import { DEFAULT, VALIDATION, DEV_HOST_NAME_LIST, PHASE_COLORS_CONFIG, PHASE_COLORS, PHASE_COLORS_REVERSED, NUMBER_FORMAT } from './const'
import { Modal } from 'antd'
import lodashIsEmpty from 'lodash.isempty'
import { List } from 'immutable'
import i18n from './i18n'

export const avatar = (email, size) => `https://www.gravatar.com/avatar/${ md5(email || '') }?s=${ size }`

export const closest = (element, check) => element && (check(element) ? element : closest(element.parentNode, check))
export const browserTimezone = () => Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Europe/Amsterdam'

export const getPermissionId = (ownerId, permissions) => {
  let permissionId = null
  permissions.forEach(permission => {
    if (permission.who.id === ownerId) {
      permissionId = permission.id
    }
  })
  return permissionId
}

export const getPolicies = (ownerId, permissions) => {
  let permissionList = []
  if (permissions) {
    for (let index = 0; index < permissions.length; index++) {
      if (permissions[index].who.id === ownerId) {
        permissionList = permissions[index].how.map(how => how.id)
      }
    }
  }

  return permissionList
}

export const getProductName = (productId, process) => {
  let productName = null
  const products = process.inputs.concat(process.outputs)
  products.forEach(product => {
    if (product.id === productId) {
      productName = product.name
    }
  })
  return productName
}

export const parseGraphQLErrorsForNoSagaQueries = error => {
  const graphQlErrors = unwrapGraphQLErrors(error) || []
  return {
    message: graphQlErrors.pop()
  }
}

export const unwrapGraphQLErrors = error => {
  const graphQLErrors = returnNested(error, 'graphQLErrors')
  if (!isEmpty(graphQLErrors)) {
    return graphQLErrors
  }

  throw error // We throw errors because they will be picked by saga HOC
}

export const notify = ({ message: rawMessage, notification, t, type = 'error' }) => {
  notification.destroy()

  const messages = Array.isArray(rawMessage) ? rawMessage : [ rawMessage ]
  const getExtendedMessage = msg => {
    const {
      message: {
        extensions: { code, args } = {},
        message
      } = {}
    } = msg || {}
    const getErrorTranslation = (errorCode, args) => t([ `err.${ errorCode }`, 'err.an_error_occurred' ], args)
    return code ? getErrorTranslation(code, args) : message
  }
  messages.map(msg => {
    const textMessage = typeof(msg?.message) === 'string' && t(msg?.message)
    || typeof(msg) === 'string' && t(msg)
    const description = textMessage || getExtendedMessage(msg)
    notification[type]({
      description: description,
      duration: DEFAULT.NOTIFICATION_DURATION,
      message: t(`global.${ type }`),
      placement: DEFAULT.NOTIFICATION_POSITION,
    })
  })
}

export const parsePolicyName = name => name.split(' ').join('_')

export const roundNumber = (value, precision = 4) => {
  return parseFloat(Number.parseFloat(value).toFixed(precision)).toString()
}

export const trimSpaces = string => {
  if (
    string === null ||
    string === undefined
  ) {
    return ''
  }
  return String(string).trim().replace(VALIDATION.ONE_SPACE, ' ')
}
export const replaceSpacesWithLowDashes = string => string ? string.split(' ').join('-').toLocaleLowerCase() : ''

export const normalize = string => string ? string.replace(/[^A-Z0-9_]+/ig, '-').toLowerCase() : ''

export const getOnlyLetters = string => {
  if (string && typeof string === 'string') {
    return string.replace(/[^A-Z]+/ig, ' ').trim()
  }
  return ''
}

export const formatLifecycleName = (name, amount, unit, suffix) => {
  if (amount && unit) {
    return `${amount} ${unit} ${suffix} ${name}`
  }
  return name
}

/**
 * keyboardHandler - Handler for keyboard key events
 * @param  {String} pressedKey can be found in the event.key property
 * @param  {String} targetKey always lowercase. Some examples: enter, escape, tab, backspace or go to https://keycode.info/
 * @param  {Function} callback function to run if pressedKey = targetKey
 */
export const keyboardHandler = (pressedKey, targetKey, callback) => {
  if (pressedKey && targetKey) {
    if (pressedKey.toLowerCase() === targetKey && callback) {
      callback()
    }
  }
  return null
}
/**
 * @param value {*}
 * @returns boolean
 */
export const isEmpty = value => {
  return lodashIsEmpty(value)
}

/**
 * @param a array
 * @returns boolean
 */
export const isNotEmpty = a => Array.isArray(a) && a.length > 0

/**
 * @param {*} a
 * @returns {Array}
 */
export const safeArray = a => Array.isArray(a) ? a : []

/**
 * @example const val = returnNested({a: {b: {c: 'val'}}}, 'a', 'b', 'c') // returns 'val' if path exists
 * @param obj {{}}
 * @param level string
 * @param rest string
 * @returns {any}|undefined|string
 */
export const returnNested = (obj, level, ...rest) => {
  if (!obj) return undefined
  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, level)) return obj[level]
  return returnNested(obj[level], ...rest)
}

export const returnNestedArray = (obj, level, ...rest) => safeArray(returnNested(obj, level, ...rest))

/**
 * @param {Object} a
 * @param {Object} b
 * @returns {boolean}
 */
export const isEquivalent = (a, b) => {
  if (!a || !b) {
    return false
  }
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]

    if (a[propName] !== b[propName]) {
      return false
    }
  }
  return true
}

/**
 * @param num number
 * @param maxLength number
 * @returns boolean
 */
export const requiresScientificNotation = (num, maxLength = NUMBER_FORMAT.SCIENTIFIC_NOTATION_THRESHOLD) => {
  if (Number.parseFloat(num) === 0.0) {
    return false
  }
  const exponential = maxLength - 1
  let abs = Math.abs(Number.parseFloat(num))
  return abs > Number(`1e${ exponential }`) // 1E4 = 10000
    || abs < Number(`1e-${ exponential }`)// 1E-4 = 0.0001
}

/**
 * @param num number|string
 * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
 * @returns {{coefficient: number, exponent: number}}
 */
export const scientificNotation = (num, fractionDigits = 2) => {
  const value = Number.parseFloat(num).toExponential(fractionDigits)
  const result = {};
  [ result.coefficient, result.exponent ] = value.split('e').map(item => Number(item))
  return result
}
export const groupBy = (objectArray, property) => objectArray.reduce(function (acc, obj) {
  const key = obj[property]
  if (!acc[key]) {
    acc[key] = []
  }
  acc[key].push(obj)
  return acc
}, {})

export const handleModal = ({ modalType, title, content, onOk, onCancel, cancelText, okText, className, width }) => {
  Modal[modalType || 'confirm']({
    title,
    content,
    width,
    cancelText: cancelText || 'No',
    okText: okText || 'Yes',
    className: className || 'modal-window',
    onOk,
    onCancel,
    maskClosable: true,
    destroyOnClose: true,
    okButtonProps: {
      'data-cy': 'confirm-modal'
    },
    cancelButtonProps: {
      'data-cy': 'cancel-modal'
    },
  })
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * @param text string
 * @param maxLength number
 * @param tail string
 * @returns string
 */
export const cropText = (text, maxLength, tail='') => {
  const suffix = String(text).length > maxLength ? tail : ''
  return String(text).substring(0, maxLength) + suffix
}
/**
 * @param {array} a
 * @param {array} b
 * @returns {boolean}
 */
export const areArraysEqual = (a, b) => {
  if (a === null || b === null || a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

/**
 * @param {*} a
 * @returns {List}
 */
export const safeList = a => List.isList(a) ? a : List()

/**
 * @param {*} a
 * @returns {boolean}
 */
export const isEmptyList = a => safeList(a).isEmpty()

/**
 * @param {*} a
 * @returns {boolean}
 */
export const hasIndex = a => a > -1 ? true : false

export const floatToString = number => {
  if (number === undefined || number === null || number === '' || isNaN(number)) return ''
  return String(parseFloat(number))
}

/**
 * @param {array} array
 * @param {string} prop
 * @returns {array}
 */
export const removeDuplicatesFromArrayOfObjectsByProperty = (array, prop) => {
  return array.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export const arrayMove = (sourceArray, from, to) => {
  const newArray = [ ...sourceArray ]
  const [ removed ] = newArray.splice(from, 1)
  newArray.splice(to, 0, removed)
  return newArray
}

/**
 * workaround to flat array because in jenkins e2e tests .flat() is not supported
 * @param {array} array
 * @returns {array}
 */
export const flatArray = array => {
  return [].concat(...safeArray(array))
}

/**
 * @param milliseconds number
 * @param format string
 * @returns number
 */
export const convertMilliseconds = (milliseconds, format) => {
  var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds

  total_seconds = parseInt(Math.floor(milliseconds / 1000))
  total_minutes = parseInt(Math.floor(total_seconds / 60))
  total_hours = parseInt(Math.floor(total_minutes / 60))
  days = parseInt(Math.floor(total_hours / 24))

  seconds = parseInt(total_seconds % 60)
  minutes = parseInt(total_minutes % 60)
  hours = parseInt(total_hours % 24)

  switch (format) {
  case 's':
    return total_seconds
  case 'm':
    return total_minutes
  case 'h':
    return total_hours
  case 'd':
    return days
  default:
    return { d: days, h: hours, m: minutes, s: seconds }
  }
}

/**
 * @param {number} amount
 * @param {string} currency
 * @returns {string}
 */
export const formatCurrency = (amount, currency) => {
  return Number(amount).toLocaleString(i18n.language,
    {
      style: 'currency',
      currency: String(currency).toUpperCase(),
    })
}

/**
 * Converts json obj {a: { b: "test"}} to {"a.b": "test"}
 * @param {{}} obj
 * @param {[]} prefix
 * @param {{}} current
 * @returns {*|{}}
 */
export const flattenObject =  (obj, prefix, current) => {
  prefix = prefix || []
  current = current || {}

  // Remember kids, null is also an object!
  if (typeof (obj) === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      flattenObject(obj[key], prefix.concat(key), current)
    })
  } else {
    current[prefix.join('.')] = obj
  }
  return current
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text, fontSize) => {
  const fontToCalculate = `${fontSize ? fontSize : '14px'} Rubik, sans-serif`
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  var context = canvas.getContext('2d')
  context.font = fontToCalculate
  var metrics = context.measureText(text)
  return metrics.width
}

/**
 * Filter options in ANT Select
 *
 * @param {String} value Inserted value.
 * @param {Object} option Select option instance
 *
 */
export const filterOptionByValue = (value, option) => {
  const optionValue = returnNested(option, 'children')
  const isNotArray = optionValue && !Array.isArray(optionValue)

  return isNotArray && String(optionValue).toLowerCase().indexOf(String(value).toLowerCase()) >= 0
}

/**
 * Get environment from URL domain
 */
export const getEnvironmentFromUrl = () => {
  const hostName = window.location.hostname
  if (!hostName) return
  const subdomain = DEV_HOST_NAME_LIST.includes(hostName) ? 'development' : hostName.substring(0, hostName.indexOf('.'))
  return subdomain === 'mobius' ? 'production' : subdomain
}

/**
 * Adds environment info to page Title
 */
export const updatePageTitle = () => {
  const env = getEnvironmentFromUrl()
  if (env && env !== 'production') {
    const prefix = env.charAt(0).toUpperCase() + env.slice(1)
    document.title = `${prefix}: ${document.title}`
  }
}

/**
 * crossbrowser version of Object.values() functionality
 */
export const getObjectValues = obj => Object.keys(obj).map(key => obj[key])

/**
 *  Check if variable exists (is defined/initialized)
 */
export const isValueExist = val => {
  return typeof val !== 'undefined'
}

/**
 *  returns arguments of query by action
 */
export const getArgsByAction = (action={}) => {
  let actionArguments = {}
  for (const key of Object.keys(action)) {
    if (isValueExist(action[key]) && key !== 'type') {
      actionArguments[key] = action[key]
    }
  }
  return actionArguments
}

export const nwSetToString = nwSet => {
  return nwSet && JSON.stringify({ type: nwSet.type, id: nwSet.id })
}

const generatedPhaseColors = (mergedSetLength, reversed = false) => {
  if ( mergedSetLength > 0 ) {
    const colorSet = reversed ? PHASE_COLORS_REVERSED : PHASE_COLORS
    const colorSetsNumber = Math.ceil(mergedSetLength / PHASE_COLORS_CONFIG.colorSetLength)
    const alphaFactor = (PHASE_COLORS_CONFIG.alphaMax - PHASE_COLORS_CONFIG.alphaMin) / colorSetsNumber

    const generatedColorSet = []
    for (let i = 0; i < colorSetsNumber; i++) {
      const alpha = PHASE_COLORS_CONFIG.alphaMax - (alphaFactor * i)
      generatedColorSet.push(...colorSet.map(color => `rgba(${color}, ${alpha})`))
    }
    return generatedColorSet
  }
}

export const getSunburstColorSet = ({ setA, setB, reversed }) => {
  const mergedSet = []
  const colorIdMap = {}
  let colorIndexCounter = 0

  if (setA && setA.length) mergedSet.push(...setA.filter(el => Number(el.impact.amount) > 0))
  if (setB && setB.length) mergedSet.push(...setB.filter(el => Number(el.impact.amount) > 0))

  const phaseColors = generatedPhaseColors(mergedSet.length, reversed)
  mergedSet.forEach(el => {
    if (el.product.id && !colorIdMap[el.product.id]) {
      colorIdMap[el.product.id] = phaseColors[colorIndexCounter]
      colorIndexCounter++
    }
  })
  return colorIdMap
}

export const getSunburstPhaseColorSet = ({ setA, setB }) => {
  let colorIdMap = {}
  let colorIndexCounter = 0
  const mapA = setA ? setA.map(el => el.name) : []
  const mapB = setB ? setB.map(el => el.name) : []
  const mergedSet = [ ...new Set([ ...mapA, ...mapB ]) ]

  const phaseColors = generatedPhaseColors(mergedSet.length)
  mergedSet.forEach(phaseName => {
    if (!colorIdMap[phaseName]) {
      colorIdMap[phaseName] = phaseColors[colorIndexCounter]
      colorIndexCounter++
    }
  })

  return isObjectEmpty(colorIdMap) ? null : { ...colorIdMap }
}

const isObjectEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype

export const extractStripePriceKey = stripeKey => {
  const lastUnderscoreIndex = stripeKey.lastIndexOf('_')

  return lastUnderscoreIndex !== -1
    ? stripeKey.slice(0, lastUnderscoreIndex)
    : stripeKey
}
