import React, { useContext, useEffect, useState } from 'react'

import styled from 'styled-components'

import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import { IPhase } from '../../../model'
import { PhaseEditComponent } from './phase-edit.component'
import { normalize } from '../../../../utils/tools'

type TViewNameWrapperProps = {
  readonly?: boolean
}

const ViewNameWrapper = styled.div<TViewNameWrapperProps>`
  text-decoration: none !important;
  border: solid 1px transparent;
  line-height: 1.9;

  &:hover {
    border: ${(props: TViewNameWrapperProps) => (props.readonly ? 'solid 1px transparent' : 'solid 1px var(--gray-300)')};
  }
`

type PhaseNameComponentProps = {
  phase?: IPhase,
  handleRenamePhase: (param: string) => void
}
export const PhaseNameComponent = ({
  phase,
  handleRenamePhase
}: PhaseNameComponentProps) => {
  const { name, id } = phase || {}
  const [ textViewMode, setTextViewMode ] = useState<boolean>(true)
  const { selectedPhase, readonlyInventory = false, updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  useEffect(() => {
    const handleClickOnEverywhere = () => {
      handleCancel()
    }

    if (isEditMode()) {
      setTimeout(() => initInputWidth(), 250)
    }
    isEditMode() && window.addEventListener('click', handleClickOnEverywhere)

    return () => window.removeEventListener('click', handleClickOnEverywhere)
  }, [ textViewMode ])

  const preventEvent = (event?: any) => {
    event?.preventDefault()
    event?.stopPropagation()
  }

  const isEditMode = () => !textViewMode && selectedPhase && id === selectedPhase?.id

  const initInputWidth = () => {
    const elt = document.querySelector<HTMLInputElement>('.phase-name-input')
    if (elt && elt.scrollWidth > elt.clientWidth) {
      elt.style.width = `${elt.scrollWidth + 8}px`
    }
  }

  const setTextToEditMode = (event: any) => {
    preventEvent(event)
    if (readonlyInventory) return

    setTextViewMode(false)
    updateLifecycleDetail({ selectedPhase: phase })
  }

  const handleEditPhase = (name: string = '') => {
    handleRenamePhase(name)
    setTextViewMode(true)
  }

  const handleCancel = (event?: any) => {
    preventEvent(event)
    setTextViewMode(true)
  }

  return (
    <>
      { !isEditMode()
      && (
        <ViewNameWrapper
          readonly={readonlyInventory}
          onClick={setTextToEditMode}
          data-testid="view-phase-name"
          data-cy={`phase-named-${normalize(name)}`}
          className="text-gray-700 font-normal no-underline border-round-sm px-2 white-space-nowrap overflow-hidden text-overflow-ellipsis"
        >
          { name }
        </ViewNameWrapper>
      )}
      { isEditMode() && <PhaseEditComponent name={name} handleEditPhase={handleEditPhase} handleCancel={handleCancel} /> }
    </>
  )
}
