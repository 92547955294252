import React from 'react'
import styled from 'styled-components'
import { ModelFooterComponent } from '../component/model/model-footer.component'
import { ModelHeaderComponent } from '../component/header/header-model.component'
import { PhaseListContainer } from '../container/phase-list.container'

const ModelWrapper = styled.div`
  min-height: 33rem;

  .tree-panel-shadow {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }

  .tree-panel-footer {
    box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);
  }

  .tree-panel-footer-shadow {
    box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);
  }
`

export const ModelLayout = () => (
  <>
    <ModelWrapper className="flex flex-column border-round-lg bg-white w-full tree-panel tree-panel-shadow">
      <div className="flex-none flex p-5 pb-2 h-4rem w-full" data-html2canvas-ignore>
        <ModelHeaderComponent />
      </div>
      <div className="flex flex-column flex-grow-1 p-5 pt-3 w-full h-full">
        <PhaseListContainer />
      </div>
      <div className="flex-none flex py-3 pl-5 pr-7 h-4rem w-full tree-panel-footer tree-panel-footer-shadow">
        <ModelFooterComponent />
      </div>
    </ModelWrapper>
  </>
)
