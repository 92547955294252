import React from 'react'
import { withRouter } from '../../../../../utils/with-router'
import { Trans, withTranslation } from 'react-i18next'
import compose from '../../../../../utils/compose'
import { DeleteOutlined } from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { AiOutlinePlus } from 'react-icons/ai'
import { Button, Collapse, notification, Select, Switch, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import {
  notify,
  parsePolicyName,
  safeArray,
  normalize,
  filterOptionByValue,
  parseGraphQLErrorsForNoSagaQueries
} from '../../../../../utils/tools'
import resource from '../../../../../utils/resource/resource'
import { POLICY } from '../../../../../utils/const'
import { DetailsPanelCard } from '../../detailPanelCard'
import DashboardContext from '../../../../../v1/dashboard/context/dashboard.context'

class WorkspaceUserPermissions extends React.Component {
  static contextType = DashboardContext

  state = {
    isNewRoleSelectorShowed: false,
    newRoleFromSelected: null,
    openedPanelKey: null
  }

  componentDidMount() {
    const { setAccountUsersAndRolesSaga, accountId } = this.props
    setAccountUsersAndRolesSaga(accountId)
  }

  handleRolePermission = (permission, policies) => {
    if (!permission.id) {
      this.createNewRolePermission(permission.ownerId, policies)
    } else if (policies.length === 0) {
      this.removeRole(permission.id)
    } else {
      this.changeRolePermission(permission.ownerId, permission.id, policies)
    }
  }

  handleNewRoleSelection = (newRoleId, element) => {
    this.setState({
      newRoleFromSelected: {
        id: null,
        key: newRoleId,
        ownerId: newRoleId,
        name: element.children,
        policies: []
      }
    })
    setTimeout( () => {
      this.setState({
        isNewRoleSelectorShowed: false
      })
    }, 1)
  }

  changeRolePermission = (ownerId, permissionId, policies) => {
    const { changeRolePermissionHandler, t } = this.props
    const { refetchWorkspace } = this.context
    const changePermissionArgs = {
      permissionID: permissionId,
      permitteeID: ownerId,
      policies
    }
    resource.mutateByParams('changePermission', changePermissionArgs)
      .then(permission => {
        changeRolePermissionHandler(permission)
        refetchWorkspace()
        notify({
          type: 'success',
          message: 'model.update_user_permission_success',
          notification,
          t
        })
      })
      .catch(error => {
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
  }

  handlePermissionInheritance = () => {
    const { setSelectedSpaceAction, selected, t } = this.props
    resource.mutateByParams('togglePermissionInheritance', { resourceID: selected.id })
      .then(() => {
        resource.queryByParams('space', { id: selected.id }).then(space => {
          // refetchWorkspace()
          setSelectedSpaceAction(space)
          notify({
            type: 'success',
            message: 'model.update_user_permission_success',
            notification,
            t
          })
        })
      })
      .catch(error => {
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
  }

  createNewRolePermission = (ownerId, policies) => {
    const { addRoleHandler, type, selected, t } = this.props
    const { refetchWorkspace } = this.context
    const resourceId = selected && selected.id
    const createPermissionArgs = {
      permitteeID: ownerId,
      policies,
      resourceID: resourceId,
      resourceType: type
    }
    resource.mutateByParams('createPermission', createPermissionArgs)
      .then(createdPermission => {
        refetchWorkspace()
        addRoleHandler(createdPermission)
        this.setState({ newRoleFromSelected: null, openedPanelKey: null })
        notify({
          type: 'success',
          message: 'model.create_user_role_success',
          notification,
          t
        })
      })
      .catch(error => {
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
  }

  removeRole = permissionId => {
    const { removeRoleHandler, t } = this.props
    const { refetchWorkspace } = this.context
    if (permissionId) {
      resource.mutateByParams('removePermission', { permissionID: permissionId })
        .then(() => {
          refetchWorkspace()
          removeRoleHandler(permissionId)
          this.setState({ newRoleFromSelected: null, openedPanelKey: null })
          notify({
            type: 'success',
            message: 'model.remove_user_permission_success',
            notification,
            t
          })
        })
        .catch(error => {
          notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
        })
    } else {
      this.setState({ newRoleFromSelected: null })
    }
  }

  setNewRoleSelectorVisibility = value => {
    this.setState({ isNewRoleSelectorShowed: value })
  }

  updateOpenPanelKey = key => {
    this.setState({ openedPanelKey: key })
  }

  buildPermissionList() {
    const { newRoleFromSelected } = this.state
    const { selected } = this.props
    const permissionList =
      selected &&
      selected.permissions &&
      selected.permissions.map(permission => ({
        id: permission.id,
        key: permission.id,
        ownerId: permission.who.id,
        name: permission.who.name,
        policies: permission.how.map(how => how.id),
        inherited: permission.inherited
      }))
    if (newRoleFromSelected && permissionList)
      permissionList.push(newRoleFromSelected)
    return permissionList
  }

  render() {
    const {
      isNewRoleSelectorShowed,
      newRoleFromSelected,
      openedPanelKey
    } = this.state
    const {
      selected,
      accountRolesList,
      accountApiClientsList,
      accountUsersList,
      spaceTypePoliciesList,
      selectedSpacePolicies,
      t
    } = this.props
    const permissionsList = this.buildPermissionList()
    const { loadingPermission } = this.context

    const roleList =
      Array.isArray(accountRolesList) &&
      accountRolesList.filter(
        role =>
          permissionsList &&
          !permissionsList.find(permission => permission.ownerId === role.id)
      )
    const userList =
      Array.isArray(accountUsersList) &&
      accountUsersList.filter(
        user =>
          permissionsList &&
          !permissionsList.find(permission => permission.ownerId === user.id)
      )
    const apiClientList =
      Array.isArray(accountApiClientsList) &&
      accountApiClientsList.filter(
        apiClient =>
          permissionsList &&
          !permissionsList.find(
            permission => permission.ownerId === apiClient.id
          )
      )
    const panelActiveKey = newRoleFromSelected
      ? permissionsList && (permissionsList.length - 1).toString()
      : openedPanelKey

    const policyOptionsList =
      Array.isArray(spaceTypePoliciesList) &&
      spaceTypePoliciesList.map(policy => (
        <Select.Option key={policy.policy}>
          <Trans>policy.{parsePolicyName(policy.policy)}</Trans>
        </Select.Option>
      ))

    if (!safeArray(selectedSpacePolicies).includes(POLICY.SPACE_MANAGEMENT)) {
      return null
    }

    return (
      loadingPermission ?
        <div className='flex w-full justify-content-center'>
          <LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin />
        </div> :
        <DetailsPanelCard
          title={t('model.workspace_permissions')}
          className="workspace-user-permissions"
          action={
            !newRoleFromSelected &&
            <Tooltip title={t('model.add', { context: 'permission' })}>
              <Button
                data-cy="add-role-button"
                className="add button"
                onClick={() => this.setNewRoleSelectorVisibility(true)}
                type="button">
                <AiOutlinePlus size={20} />
              </Button>
            </Tooltip>
          }>
          <div className="inheritance-switch">
            {t('permission_manager.inherit_permissions')}:
            <Switch
              data-cy="inherit-permissions-switch"
              checked={selected.permissionsInherited}
              checkedChildren={t('global.yes')}
              unCheckedChildren={t('global.no')}
              size="small"
              onChange={this.handlePermissionInheritance}
            />
          </div>

          <Collapse
            onChange={this.updateOpenPanelKey}
            accordion
            bordered={false}
            activeKey={panelActiveKey}
          >
            {permissionsList &&
              permissionsList.map((permission, key) => (
                <Collapse.Panel
                  className={`cy-role-container-for-${normalize(
                    permission.name
                  )} role-container`}
                  header={permission.name}
                  key={key}
                >
                  <Select
                    disabled={permission.inherited}
                    className="policies"
                    data-cy={`policies-select-for-${normalize(
                      permission.name
                    )}`}
                    dropdownClassName={`policies-dropdown cy-dropdown-for-${normalize(
                      permission.name
                    )}`}
                    defaultValue={permission.policies.map(policy => policy)}
                    mode="multiple"
                    onChange={selectedPolicies => {
                      this.handleRolePermission(permission, selectedPolicies)
                    }}
                    placeholder={t('permission_manager.permissions_placeholder')}
                    dropdownMatchSelectWidth={false}
                  >
                    {policyOptionsList}
                  </Select>
                  {!permission.inherited ? (
                    <div className="remove-role-container">
                      <Button
                        data-cy="remove-role-button"
                        className="remove button"
                        onClick={() => this.removeRole(permission.id)}
                        type="button"
                      >
                        <DeleteOutlined />
                        <Trans>model.remove_permission</Trans>
                      </Button>
                    </div>
                  ) : null}
                </Collapse.Panel>
              ))}
          </Collapse>
          {isNewRoleSelectorShowed ? (
            <Select
              className="select-new-role"
              data-cy="select-new-role-select"
              dropdownClassName="new-role-dropdown"
              mode="multiple"
              filterOption={(inputValue, option) => filterOptionByValue(inputValue, option) }
              notFoundContent={t('permission_manager.all_assigned')}
              onSelect={this.handleNewRoleSelection}
              placeholder={t('permission_manager.owner_placeholder')}
              showSearch
            >
              <Select.OptGroup label={t('permission_manager.roles')} key="roles">
                {roleList &&
                  roleList.map(role => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.label}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
              <Select.OptGroup label={t('permission_manager.users')} key="users">
                {userList &&
                  userList.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
              <Select.OptGroup
                label={t('permission_manager.api_clients')}
                key="api_clients"
              >
                {apiClientList &&
                  apiClientList.map(apiClient => (
                    <Select.Option key={apiClient.id} value={apiClient.id}>
                      {apiClient.name}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            </Select>
          ) : null}
        </DetailsPanelCard>
    )
  }
}

WorkspaceUserPermissions.propTypes = {
  accountRolesList: PropTypes.array,
  accountUsersList: PropTypes.array,
  t: PropTypes.func
}

export { WorkspaceUserPermissions }
export default compose(withRouter, withTranslation())(WorkspaceUserPermissions)
