import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { selectedAccountVar } from '../../../graphql/cache'
import { Navigation } from '../../shared/enum'
import { useTrialCountdown } from '../hooks/use-trial-countdown'

import { isValid } from '../../shared/util/tools'

const TrialCounterWrapper = styled.div`
  .p-button-outlined {
    color: var(--surface-a) !important;

    :hover {
      background: var(--surface-50) !important;
    }
  }
`

export const TrialCounterComponent = () => {
  const {
    account,
    emptyAccount
  } = useReactiveVar(selectedAccountVar) || {}
  const {
    trialEnd = new Date().toISOString(),
    hasValidSubscription = false
  } = account || {}

  const { t } = useTranslation([ 'dashboard' ])
  const navigate = useNavigate()

  const [ timeLeft, context ] = useTrialCountdown(trialEnd)
  const isTrialExpired = timeLeft <= 0

  if (!isValid(account) || hasValidSubscription || emptyAccount || (!hasValidSubscription && isTrialExpired)) {
    return <></>
  }

  return (
    <TrialCounterWrapper data-testid="trial-counter-wrapper" className="w-full ml-1">
      <span data-testid="trial-counter-label" className="text-orange-500 text-xs">
        { t('labels.freeTrialExpires', {
          count: timeLeft,
          context
        })}
      </span>
      <Button
        data-testid="trial-upgrade"
        label={t('actions.upgrade')}
        onClick={() => navigate(Navigation.AccountSettingBilling)}
        className="p-button-outlined p-button-secondary p-button-sm text-xs p-0 px-2 ml-2 border-round-sm"
      />
    </TrialCounterWrapper>
  )
}
