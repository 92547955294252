import { gql } from '@apollo/client'
import subscription from '../fragment/subscription'

export default gql`
  mutation changePlan($accountID: ID!, $planID: String!) {
      changePlan(accountID: $accountID, planID: $planID){
          ...subscriptionFragment
      }
     
  }
  ${subscription}
`
