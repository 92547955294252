import { gql } from '@apollo/client'

export default gql`
  mutation changeLifecycleAmount($id: ID!, $amount: String!) {
    changeLifecycleAmount(id: $id, amount: $amount) {
      id
      name
    }
  }
`
