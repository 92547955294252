import React, { useContext, useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { INVENTORY_ITEMS } from '../../graphql/query'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { isValid } from '../../shared/util/tools'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { TreeSize } from '../../shared/enum/tree'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { Status } from '../../shared/enum/status'

type TProductModelProps = {
  children?: React.ReactNode
}
export const ProductModelContainer = ({
  children
}: TProductModelProps) => {
  const {
    updateProductDetail,
    expandedKeys,
    productInventory,
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { updateDashboard } = useContext(DashboardContext)
  const { id: productId = '' } = productInventory?.product || {}
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}

  const {
    loading: loadingInventoryItems,
    data: inventoryItemsData,
    refetch: refetchInventoryItems,
    stopPolling: stopPollingInventoryItems,
    startPolling: startPollingInventoryItems
  } = useQuery(INVENTORY_ITEMS, {
    skip: !isValid(space) || !productId,
    variables: { productID: productId },
    pollInterval: 60000,
    fetchPolicy: 'no-cache'
  })

  const { inventory } = inventoryItemsData || {}
  const { inventoryItems = null, totalImpact = null } = inventory || {}

  useEffect(() => {
    if (!loadingInventoryItems && inventoryItems) {
      let initExpandedKeys = expandedKeys ? [ ...expandedKeys ] : []
      if (inventoryItems.length < TreeSize.md && initExpandedKeys.length === 0) {
        initExpandedKeys = inventoryItems.map((item: any) => item.nodeId)
      }
      updateProductDetail({
        totalImpact,
        inventoryItems,
        loadingInventoryItems,
        refetchInventoryItems,
        expandedKeys: initExpandedKeys
      })
      updateDashboard({ refetchInventoryItems })
    }
  }, [ loadingInventoryItems, inventoryItems, refetchInventoryItems ])

  useEffect(() => {
    if (totalImpact?.status && totalImpact?.status !== Status.Pending) {
      stopPollingInventoryItems()
    } else if (totalImpact?.status === Status.Pending) {
      startPollingInventoryItems(60000)
    }
  }, [ totalImpact ])

  return (
    <>
      { loadingInventoryItems && <div className="w-full h-full"><ProgressSpinnerComponent dataTestId="loading-inventory-items" size={2} /></div> }
      { !loadingInventoryItems && inventoryItems && productInventory?.product && children }
    </>
  )
}
