import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Policy } from '../../shared/enum'
import { HAS_ACCESS } from '../../graphql/query'
import { selectedWorkspaceVar, setSelectedWorkspace } from '../../../graphql/cache'
import { ISpaceAccess } from '../../model'

type WorkspaceAccessContainerProp = {
  resourceID: string,
  children?: React.ReactNode
}

export const WorkspaceAccessContainer = ({ resourceID, children }: WorkspaceAccessContainerProp) => {
  const selectedWorkspace: ISpaceAccess | null = selectedWorkspaceVar() || {}
  const policies: Policy[] = [ Policy.SpaceManagement, Policy.SpaceRead, Policy.SpaceWrite ]
  const { loading, data } = useQuery(HAS_ACCESS, {
    variables: { resourceID, policies },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loading && data?.hasAccess) {
      setSelectedWorkspace({ ...selectedWorkspace, hasAccess: data.hasAccess as Policy[] })
    }
  }, [ loading, data ])

  return (
    <>
      { children }
    </>
  )
}
