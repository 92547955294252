import { useReactiveVar, useQuery } from '@apollo/client'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { LIFECYCLE_LEAF_ITEMS } from '../../../graphql/query'
import { isValid } from '../../util/tools'

export const useLifecycleInsightQuery = (lifecycleId: string) => {
  const { space } = useReactiveVar(selectedWorkspaceVar) || {}

  const {
    loading,
    data,
    refetch,
    networkStatus
  } = useQuery(LIFECYCLE_LEAF_ITEMS, {
    skip: !isValid(space) || !lifecycleId,
    variables: { id: lifecycleId },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  return {
    loading, data, refetch, networkStatus
  }
}
