import { useEffect } from 'react'
import { TRACK_EVENT_TYPE, NAV_EXTERNAL } from '../../utils/const'

export default function HelpCenter(props) {
  useEffect(() => {
    props.trackEventSaga(TRACK_EVENT_TYPE.VIEWED_HELP_CENTER)
    window.location = NAV_EXTERNAL.KNOWLEDGE_BASE
  }, [])
  return null
}
