import { ComparisonEntity } from '../enum/entity'

export const COMPARISON_TABLE_SORT_MAP = {
  Id: { entity: null, sortBy: 'id' },
  Item: { entity: null, sortBy: 'name' },
  PrimaryAmount: { entity: ComparisonEntity.Primary, sortBy: 'amount' },
  PrimaryUnit: { entity: ComparisonEntity.Primary, sortBy: 'unit' },
  PrimaryImpact: { entity: ComparisonEntity.Primary, sortBy: 'impactAmount' },
  PrimaryImpactBar: { entity: ComparisonEntity.Primary, sortBy: 'impactRatioRelativeToTotal' },
  SecondaryAmount: { entity: ComparisonEntity.Secondary, sortBy: 'amount' },
  SecondaryUnit: { entity: ComparisonEntity.Secondary, sortBy: 'unit' },
  SecondaryImpact: { entity: ComparisonEntity.Secondary, sortBy: 'impactAmount' },
  SecondaryImpactBar: { entity: ComparisonEntity.Secondary, sortBy: 'impactRatioRelativeToTotal' },
  ImpactChangeEffect: { entity: null, sortBy: 'impactChangeEffect' }
}

export const COMPARISON_LIFECYCLE_TABLE_SORT_MAP = {
  PhaseName: { entity: null, sortBy: 'order' },
  ...COMPARISON_TABLE_SORT_MAP
}
