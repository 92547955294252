import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'

import DashboardContext from '../context/dashboard.context'

export const TalkExpertComponent = () => {
  const { showTalkToExpert, talkExpertDataSrc, updateDashboard } = useContext(DashboardContext)
  const { t } = useTranslation([ 'dashboard' ])

  const onHide = () => {
    updateDashboard({ showTalkToExpert: false, talkExpertDataSrc: null })
  }

  return (
    <Dialog data-testid="meetings-iframe-dialog" header={t('actions.chatToOurTeam')} modal visible={showTalkToExpert} style={{ width: '50vw' }} onHide={onHide}>
      <div data-testid="meetings-iframe-container" className="meetings-iframe-container" data-src={talkExpertDataSrc}></div>
    </Dialog>
  )
}
