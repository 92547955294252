import { useReactiveVar, useQuery } from '@apollo/client'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { LEAF_INVENTORY_ITEMS } from '../../../graphql/query'
import { isValid } from '../../util/tools'

type TUseInsightQuery = {
  productID: string
  leafInventoryLoadingEnabled?: boolean
}
export const useInsightQuery = ({
  productID,
  leafInventoryLoadingEnabled = true
}: TUseInsightQuery) => {
  const { space } = useReactiveVar(selectedWorkspaceVar) || {}

  const {
    loading,
    data,
    refetch,
    networkStatus
  } = useQuery(LEAF_INVENTORY_ITEMS, {
    skip: !isValid(space) || !productID || !leafInventoryLoadingEnabled,
    variables: { productID },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  return {
    loading, data, refetch, networkStatus
  }
}
