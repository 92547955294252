import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { useTranslation } from 'react-i18next'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { FacetValue } from '../../../../__generated__/graphql'
import { ImpactDatasetViewType } from '../../enum/impact-dataset-view-type'

export const PropertyFilterListComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])
  const {
    selectedProperties = [],
    selectedFlowProperties = [],
    selectedDatasetViewType = ImpactDatasetViewType.DatabaseSearch,
    propertyList,
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const isDatabaseSearch = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
  const currentlySelectedProperties = isDatabaseSearch ? selectedProperties : selectedFlowProperties

  const updateSelectedProperties = (selectedProperties: string[] = []) => {
    const updatedProperties = isDatabaseSearch ? { selectedProperties } : { selectedFlowProperties: selectedProperties }
    updateFilterValue(updatedProperties)
  }

  const onPropertyChange = (event: any) => {
    const selectedPropertyNames = [ ...currentlySelectedProperties ]
    const selectedPropertyIndex = selectedPropertyNames.findIndex(name => name === event.value.name)

    if (event.checked) {
      selectedPropertyNames.push(event.value.name)
    } else {
      selectedPropertyNames.splice(selectedPropertyIndex, 1)
    }

    updateSelectedProperties(selectedPropertyNames)
  }

  const clearFilters = () => {
    updateSelectedProperties([])
  }

  const isChecked = (propertyName: string) => currentlySelectedProperties.includes(propertyName)
  const showCounts = currentlySelectedProperties.length === 0

  const getPropertyLabel = (property: FacetValue) => {
    const isSelected = isChecked(property.name) && currentlySelectedProperties.includes(property.name)
    return (
      <div className="flex align-items-center text-sm text-grey-500">
        <span>{property.name}</span>
        {(showCounts || isSelected) && <span className="text-xs ml-1">({property.count})</span>}
      </div>
    )
  }

  return (
    <div data-testid="property-filter-wrapper" className="flex flex-column w-full">

      <ul className="w-full">
        { propertyList?.map((property, index) => (
          <li
            data-testid={`property-item-${index}`}
            key={`property-item-${index}`}
            className="flex align-items-center gap-2 w-full py-1"
          >
            <Checkbox
              inputId={property.name}
              name="property"
              value={property}
              onChange={onPropertyChange}
              data-testid={`property-checkbox-${index}`}
              checked={isChecked(property.name)}
            />
            <label
              htmlFor={property.name}
              className="line-height-2 text-sm text-gray-800"
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
              }}
            >
              {getPropertyLabel(property)}
            </label>
          </li>
        )) }
      </ul>

      <div className="flex justify-content-between w-full border-top-1 border-gray-100 pt-3 mt-3">
        <Button
          label={t('labels.clearFilters')}
          onClick={clearFilters}
          disabled={currentlySelectedProperties.length === 0}
          className="p-button-outlined p-button-plain h-2rem"
        />
      </div>
    </div>
  )
}
