import { ADD_LIFECYCLE_TO_INVENTORY, SET_INVENTORY_LIFECYCLES, REMOVE_LIFECYCLE_FROM_INVENTORY } from '../actions/inventoryTree.actions'
import { CLEAR_INVENTORY_TREE_STATE } from '../actions/clear.actions'
import { safeArray } from '../../utils/tools'

const defaultState  = {
  lifecycles: []
}

const inventoryLifecyclesReducer = (state = defaultState, action={}) => {
  switch (action.type) {
  case SET_INVENTORY_LIFECYCLES:
    return {
      ...state,
      lifecycles: action.lifecycles
    }
  case ADD_LIFECYCLE_TO_INVENTORY:
    return {
      ...state,
      lifecycles: [ ...state.lifecycles, action.lifecycle ]
    }
  case REMOVE_LIFECYCLE_FROM_INVENTORY:{
    let inventoryLifecycleListForRemove = [ ...safeArray(state.lifecycles) ]
    inventoryLifecycleListForRemove = inventoryLifecycleListForRemove.filter(lc => lc.id !== action.lifecycleId)
    return { ...state, lifecycles: inventoryLifecycleListForRemove }
  }
  case CLEAR_INVENTORY_TREE_STATE:
    return {
      ...state,
      lifecycles: []
    }
  default:
    return state
  }
}

export default inventoryLifecyclesReducer
