import { Button, Result, Typography } from 'antd'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { NAV } from '../../../utils/const'

class AuthorizationFailedPage extends React.Component {
  render() {
    const { t } = this.props
    return <Result
      status="403"
      title="403"
      subTitle={<Typography.Paragraph>{t('err.authorization_failed_page_message')} <Button type="link" target="_blank" href="mailto:support@ecochain.com">support@ecochain.com</Button></Typography.Paragraph>}
      extra={<Button href={NAV.LOGOUT}>{t('layout.logout')}</Button>}
    />
  }
}
export default compose(
  withTranslation(),
)(AuthorizationFailedPage)
