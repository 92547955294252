import { gql } from '@apollo/client'

export default gql`
  mutation createTag($name: String!, $color: String!, $spaceID: ID!, $productID:ID) {
    createTag(name: $name, color: $color, spaceID: $spaceID, productID: $productID) {
       id
       name
       color
    }
  }
`
