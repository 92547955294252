import React from 'react'
import styled from 'styled-components'

type SelectButtonWrapperWrapperProps = {
  clearable: boolean
}

const SelectButtonWrapperWrapper = styled.div<SelectButtonWrapperWrapperProps>`
  .p-button {
    padding: 0.45rem;
    background: transparent !important;
    color: var(--gray-500) !important;
    border-color: var(--gray-100) !important;
    box-shadow: none;

    :hover {
      background: var(--primary-50) !important;
      color: var(--primary-500) !important;
      border-color: var(--primary-500) !important;
    }
  }

  .p-button.p-highlight {
    border-color: var(--gray-500) !important;
    border-right: 1px solid;

    :hover {
      cursor: ${(props: SelectButtonWrapperWrapperProps) => (props.clearable ? 'pointer' : 'default')};
      background: ${(props: SelectButtonWrapperWrapperProps) => (props.clearable ? 'var(--primary-50)' : 'transparent !important')};
      color: ${(props: SelectButtonWrapperWrapperProps) => (props.clearable ? 'var(--primary-500)' : 'var(--gray-500) !important')};
      border-color: ${(props: SelectButtonWrapperWrapperProps) => (props.clearable ? 'var(--primary-500)' : 'var(--gray-500) !important')};
    }

    + .p-button {
      border-left: none;
    }
  }
`

type SelectButtonWrapperProps = {
  children?: React.ReactNode,
  clearable?: boolean
}
export const SelectButtonWrapperLayout = ({
  children,
  clearable = false
}: SelectButtonWrapperProps) => (
  <SelectButtonWrapperWrapper clearable={clearable}>
    { children }
  </SelectButtonWrapperWrapper>
)
