import React, { useState } from 'react'

import { IProductProvider } from '../interface/product-context.type'
import ProductContext from './context/product.context'
import { ProductLabel, ViewType } from '../../shared/enum'
import { PAGINATION } from '../../../utils/const'
import { SortingOrder, ProductSortField } from '../../../__generated__/graphql'

type ProductProviderProp = {
  children?: React.ReactNode,
  cardPageSize?: number,
  tablePageSize?: number,
  selectedViewType?: ViewType,
  selectedSortBy?: ProductSortField,
  selectedSortOrder?: SortingOrder,
  label?: ProductLabel,
}
export const ProductProvider = ({
  label = ProductLabel.Product,
  tablePageSize,
  cardPageSize = PAGINATION.PAGE_SIZE,
  selectedViewType,
  selectedSortBy = ProductSortField.Name,
  selectedSortOrder = SortingOrder.Asc,
  children
}: ProductProviderProp) => {
  const defaultProductProviderValue = {
    label,
    pageSize: cardPageSize,
    tablePageSize,
    cardPageSize,
    currentPage: 1,
    previousPages: [], // TODO : To keep the page where the user has left
    totalItems: 0,
    resetList: false,
    loadingProductOverview: true,
    selectedViewType,
    selectedSortBy,
    selectedSortOrder,
    showMoveToFolderDialog: false,
    showDeleteProductDialog: false,
    allProductsDetail: {},
    allProducts: [],
    favProducts: [],
    refetchProducts: () => {},
    refetchFavProducts: () => {}
  }
  const [ product, setProduct ] = useState<IProductProvider>(defaultProductProviderValue)

  const updateProduct = (newState: IProductProvider = defaultProductProviderValue) => {
    setProduct(prevState => ({ ...prevState, ...newState }))
  }

  return (
    <ProductContext.Provider value={{ ...product, updateProduct }}>
      { children }
    </ProductContext.Provider>
  )
}
