const fontPath = `${window.location.protocol}//${window.location.host}/assets/fonts/`

export const styledComponentTheme = {
  inventoryTree: {
    background: '--primary-50',
    hoverBackground: '--primary-100',
    highlightBorder: '--green-500',
    negativeBar: '--green-400',
    negativeBarDeep: '--green-200',
    positiveBar: '--primary-500',
    positiveBarDeep: '--primary-200',
  }
}

export const pdfTheme = {
  fonts: {
    'Rubik 300-400': {
      normal: `${fontPath}Rubik-Light.ttf`,
      bold: `${fontPath}Rubik-Regular.ttf`,
    },
    'Rubik 500-700': {
      normal: `${fontPath}Rubik-Medium.ttf`,
      bold: `${fontPath}Rubik-Bold.ttf`,
    }
  },
  colors: {
    gray200: '#B6B6B6',
    green200: '#b6e89c',
    green500: '#56ca19',
    primary100: '#c5e0f4',
    primary500: '#0c7dd0',
    primaryDark500: '#072b49'
  },
  doughnutColors: [
    '#053253',
    '#074572',
    '#085892',
    '#0a6ab1',
    '#0c7dd0',
    '#3a96d9',
    '#68aee2',
    '#97c7eb'
  ]
}
