import React from 'react'
import Icon from '@ant-design/icons'
import { ICustomIconProps } from './customIcons.interface'

export const ExternalLinkIcon:React.FC<ICustomIconProps> = (props:any) => {
  return <Icon className="custom-icon-container" component={() => (
    <svg { ...props } fill="none" viewBox="0 0 24 24">
      <path  strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3"></path>
    </svg>
  )} />
}

export const LogoIcon = ( props:any ) => {
  return <Icon component={() => (
    <svg {...props} viewBox="0 0 469 225" version="1.1">
      <g transform="matrix(0.994072,0,0,0.973113,-1.86033,3.15537)">
        <g transform="matrix(4.19151,0,0,4.28179,-184.406,-194.879)">
          <path d="M104.592,52.849C104.59,52.851 103.601,53.782 103.601,53.782L109.801,59.57L110.793,58.641C114.544,55.124 119.541,53.183 124.857,53.183C130.173,53.183 134.866,55.432 138.929,58.646C141.47,60.656 147.559,66.854 147.559,71.836C147.559,76.819 142.692,81.5 138.934,85.023C131.177,92.291 118.549,92.291 110.786,85.023L89.595,65.123L83.405,70.919L104.6,90.822C115.771,101.29 134.443,101.091 145.616,90.623C151.022,85.549 156.736,78.875 156.736,71.467C156.736,64.34 150.687,57.155 145.417,52.561C139.817,47.678 132.51,44.986 124.857,44.986C117.203,44.986 110.007,47.78 104.592,52.849Z" />
        </g>
        <g transform="matrix(4.19151,0,0,4.28179,-184.406,-194.879)">
          <path d="M96.694,90.712C96.696,90.709 97.685,89.779 97.685,89.779L91.485,83.991L90.493,84.92C86.742,88.436 81.745,90.378 76.429,90.378C71.112,90.378 66.42,88.129 62.356,84.915C59.816,82.905 53.727,76.707 53.727,71.725C53.727,66.742 58.594,62.061 62.352,58.538C70.109,51.27 82.737,51.27 90.501,58.538L111.691,78.438L117.881,72.642L96.686,52.739C85.515,42.27 66.843,42.47 55.67,52.938C50.264,58.012 44.55,64.686 44.55,72.094C44.55,79.221 50.599,86.406 55.869,91C61.469,95.883 68.776,98.575 76.429,98.575C84.082,98.575 91.279,95.781 96.694,90.712Z" />
        </g>
      </g>
    </svg>)} />
}

export const TreeDotsIcon:React.FC<ICustomIconProps> = ( props:any ) => {
  return <Icon component={() => (
    <svg {...props} viewBox="0 0 24 24" fill="none" >
      <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#333E40" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#333E40" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#333E40" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )} />
}
