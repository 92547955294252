import { gql } from '@apollo/client'

export default gql`
  query accountPermissions($id: ID!){
    accountPermissions(id: $id) {
      id
      who {
        id
        name
      }
      what {
        id
      }
      how {
        id
      }
    }
  }
`
