import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../graphql/cache'

import { HideImpactBarComponent } from '../component/product/hide-impact-bar.component'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { AnalysisMode } from '../enum/analysis'
import { TLifecycleDetailContext } from '../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../lifecycle/provider/context/lifecycle-detail.context'

type TLifecycleInsightWrapperLayoutProps = {
  children?: React.ReactNode
}
export const LifecycleInsightWrapperLayout = ({
  children
}: TLifecycleInsightWrapperLayoutProps) => {
  const { selectedInventoryViewType } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    analysisMode
  } = useContext<TAnalysisContext>(AnalysisContext)

  const { t } = useTranslation([ 'analysis', 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}

  const { name: impactCategoryName = '' } = space?.impactCategory || {}
  const isFlatViewType = () => selectedInventoryViewType === InventoryViewType.FlatView
  const analysisInScenario = analysisMode === AnalysisMode.Scenario

  return (
    <div className="flex flex-column w-full border-round-lg bg-white">
      <div className="flex-none flex p-5 w-full">
        <div data-testid="table-wrapper-title" className="flex flex-grow-1 text-gray-700 text-2xl">
          {
            t('labels.panelHeader', {
              context: analysisInScenario ? 'productScenario' : 'productComparison',
              impactCategory: impactCategoryName
            })
          }
        </div>
        <div data-testid="table-wrapper-hideImpactBar" className="flex-none flex">
          {isFlatViewType() && <HideImpactBarComponent />}
        </div>
      </div>
      <div data-testid="table-wrapper-body" className="flex flex-grow-1 justify-content-center w-full p-5 pt-0 model-layout-body">
        {children}
      </div>
    </div>
  )
}
