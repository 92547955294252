import { gql } from '@apollo/client'

export default gql`
  mutation createAPIClient($name: String!, $accountID: ID!, $roleIDs: [ID!]) {
    createAPIClient(name: $name, accountID: $accountID, roleIDs: $roleIDs){
      id
      name
      clientID
      clientSecret
      roles {
          id
      }
    }
  }
`
