import { gql } from '@apollo/client'

export default gql`
    query referenceProducts($query: String!, $locationQuery: String!, $categoryId: String!, $type: String!, $databases: [String!]!,$currentPage: Int!, $pageSize: Int!, $fuzzyType: SearchType, $impactMethodId: String!) {
        referenceProducts(query: $query, locationQuery: $locationQuery, categoryId: $categoryId, type: $type, databases: $databases, currentPage: $currentPage, pageSize: $pageSize, fuzzyType: $fuzzyType, impactMethodId: $impactMethodId) {
            items{
                id
                name
                description
                databaseID
                databaseName
                type
                referenceUnit {
                    id
                    name
                }
                referenceProperty {
                    id
                    name
                }
                amount
                location {
                    name
                }
                category {
                    name
                }
                parentCategory {
                    name
                }
            }
            total
        }
    }
`
