import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import classNames from 'classnames'
import styled from 'styled-components'

import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { IProduct, IReferenceProduct } from '../../model/product'

type DetailViewButtonComponentProps = {
  dataset: any,
  isMouseOver?: boolean
}

const ButtonWrapper = styled.div`
  .detail-view-button {
    color: var(--gray-500);
    border-color: var(--gray-200);
  }
`

export const DetailViewButtonComponent = ({ dataset, isMouseOver }: DetailViewButtonComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    referenceProducts,
    selectedDatasetViewType,
    updateImpactDataset,
  } = useContext(ImpactDatasetContext)
  const { addToBackToList = () => {}, selectedEntity } = useContext(DashboardContext)
  const { datasetNavigate } = useDatasetNavigate()

  const onButtonClick = () => {
    const backLabel = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
      ? t('labels.databaseSearch', { ns: 'impact-dataset' })
      : t('labels.elementaryFlow', { ns: 'impact-dataset' })

    const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct
    const selectedReferenceProduct = currentReferenceProduct?.id === dataset.id
      ? currentReferenceProduct as IReferenceProduct
      : referenceProducts?.find(referenceProduct => referenceProduct.id === dataset.id)

    addToBackToList(backLabel)
    updateImpactDataset({ selectedReferenceProduct })
    datasetNavigate(selectedDatasetViewType, ImpactDatasetViewMode.Detail, false)
  }

  const isSelectedDataset = (selectedEntity as IProduct)?.referenceProduct?.id === dataset.id
  if (!isSelectedDataset && (!dataset || !isMouseOver)) {
    return null
  }

  return (
    <ButtonWrapper>
      <Button
        className={classNames('p-button-sm p-button-outlined h-2rem justify-content-center mr-2', {
          'detail-view-button': isSelectedDataset
        })}
        onClick={onButtonClick}
        data-cy="search-dataset-detail-view-button"
      >
        { t('labels.view') }
      </Button>
    </ButtonWrapper>
  )
}
