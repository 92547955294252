import { connect } from 'react-redux'
import RemoveAccount from './removeAccount'
import { removeAccountSaga } from '../../../redux/sagas/account.saga'
import AccountSelector from '../../../redux/selectors/account.selector'
import UserSelector from '../../../redux/selectors/user.selector'

const mapStateToProps = state => ({
  selectedAccountId: AccountSelector.selectedAccountId(state),
  listOfUserAccounts: UserSelector.userAccounts(state)
})

const mapDispatchToProps = {
  removeAccountSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveAccount)
