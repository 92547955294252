import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { InventoryTreeKey } from '../../utils/inventoryTreeKey'
import resource from '../../utils/resource/resource'
import { isEmpty, returnNested } from '../../utils/tools'
import { setEntityToCompareAction } from '../actions/comparison.actions'
import { setIsShowLifecycleScenarioInventoryAction } from '../actions/flags.actions'
import { setSelectedInventoryItemKeyAction } from '../actions/global.actions'
import { setLifecycleScenarioAction } from '../actions/lifecycle.actions'
import { setIsLoadingRightTreeAction } from '../actions/loading.actions'
import { selectedInventoryItemSelector } from '../selectors/inventoryTree.selector'
import { LifecycleScenarioSelector, LifecycleSelector } from '../selectors/lifecycle.selector'
import { dispatchErrors, mapLifecycleEntity } from './helpers'
import { updateLifecycleExpandedState } from './lifecycle.saga'
import { fetchProductWithImpact, getProductWithImpactSaga } from './product.saga'

export const
  GET_LIFECYCLE_SCENARIO = 'GET_LIFECYCLE_SCENARIO_SAGA',
  QUIT_LIFECYCLE_SCENARIO = 'QUIT_LIFECYCLE_SCENARIO_SAGA'

export const
  getLifecycleScenarioSaga = lifecycleId => ({
    type: GET_LIFECYCLE_SCENARIO,
    lifecycleId
  }),
  quitLifecycleScenarioSaga = cb => ({
    type: QUIT_LIFECYCLE_SCENARIO,
    cb
  })

/**
 * @param {{lifecycleId: string}} action
 */
export function* getLifecycleScenarioFn(action) {
  yield put(setIsLoadingRightTreeAction(true))
  yield put(setIsShowLifecycleScenarioInventoryAction(true))
  try {
    const lifecycle = yield call(resource.queryByParams, 'lifecycle', { id: action.lifecycleId })
    let effects = [
      put(setLifecycleScenarioAction(lifecycle)),
      put(setEntityToCompareAction(mapLifecycleEntity(lifecycle)))
    ]
    yield call(updateLifecycleExpandedState, lifecycle)
    yield all(effects)
  } catch (error) {
    yield call(dispatchErrors, error)
    yield put(setIsShowLifecycleScenarioInventoryAction(false))
  } finally {
    yield put(setIsLoadingRightTreeAction(false))
  }
}

// If selected item is in scenario, select the production product in lifecycle (because it can never be deleted)
function* quitLifecycleScenarioFn(action) {
  const selectedInventoryItem = yield select(selectedInventoryItemSelector)
  const selectedInventoryRootId = selectedInventoryItem && returnNested(InventoryTreeKey.createFromKey(selectedInventoryItem), 'rootId')
  const lifecycleScenarioPhase = yield select(LifecycleScenarioSelector.phaseByProductId, selectedInventoryRootId)
  if (!isEmpty(lifecycleScenarioPhase)) {
    const productionInventory = yield select(LifecycleSelector.productionInventory)
    const productionFirstInventoryNode = yield select(LifecycleSelector.productionFirstInventoryNode)
    if (isEmpty(productionInventory) || isEmpty(productionFirstInventoryNode)) {
      yield put(setIsShowLifecycleScenarioInventoryAction(false))
      action.cb && action.cb()
      return
    }
    // We cannot get impact from tree because amount can change and production product can have siblings
    const productionFirstProductId = returnNested(productionFirstInventoryNode, 'inventoryItem', 'product', 'id')
    yield call(fetchProductWithImpact, getProductWithImpactSaga(productionFirstProductId))

    const inventoryTreeKey = InventoryTreeKey.createFromInventoryNode(productionInventory, productionFirstInventoryNode).inventoryTreeKey()
    yield put(setSelectedInventoryItemKeyAction(inventoryTreeKey))

  }
  yield put(setIsShowLifecycleScenarioInventoryAction(false))
  action.cb && action.cb()
}

export default function* lifecycleScenarioSaga() {
  yield takeLatest(GET_LIFECYCLE_SCENARIO, getLifecycleScenarioFn)
  yield takeLatest(QUIT_LIFECYCLE_SCENARIO, quitLifecycleScenarioFn)
}

