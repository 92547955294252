import { gql } from '@apollo/client'

export default gql`
  query resourceTypePolicies($resourceType: ID!) {
    resourceTypePolicies(resourceType: $resourceType) {
        resourceType
        policy
    }
  }
`
