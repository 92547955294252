import { ADD_ERROR_NOTIFICATION, ADD_SUCCESS_NOTIFICATION, CLEAR_NOTIFICATIONS } from '../actions/notification.actions'

const defaultState = {
  errors: [],
  successes: [],
}

const notificationReducer = (currentState, action) => {
  const state = currentState || defaultState
  switch (action.type) {
  case ADD_ERROR_NOTIFICATION:
    return {
      ...state,
      errors: [
        ...state.errors,
        action.message
      ]
    }
  case ADD_SUCCESS_NOTIFICATION:
    return {
      ...state,
      successes: [
        ...state.successes,
        action.message
      ]
    }
  case CLEAR_NOTIFICATIONS:
    return { ...defaultState }
  default:
    return state
  }
}

export default notificationReducer
