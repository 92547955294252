import { DecimalViewType } from '../../enum/decimal'

type TTooltipProps = {
  table: HTMLTableElement,
  title?: string
}
export const getOrCreateTooltip = (chart: any, id?: string, classNames: string = '') => {
  chart.canvas.parentNode.querySelector('div')?.remove()
  const tooltipElement = document.createElement('div')
  tooltipElement.setAttribute('data-testid', 'tooltip-wrapper')
  id && tooltipElement.setAttribute('id', id)
  tooltipElement.setAttribute('class', `bg-black-alpha-70 border-round-xs text-white absolute p-chart-tooltip-animation ${classNames}`)
  const table = document.createElement('table')
  table.setAttribute('data-testid', 'tooltip-table')
  table.setAttribute('class', 'm-2')
  tooltipElement.appendChild(table)
  chart.canvas.parentNode.appendChild(tooltipElement)
  return tooltipElement
}
type TSetTooltipHeaderParam = TTooltipProps & {
  backgroundColor?: string,
  borderColor?: string
}
export const setTooltipHeader = ({
  table, title = '', backgroundColor, borderColor
}: TSetTooltipHeaderParam) => {
  const tableHead = document.createElement('thead')
  const headerRow = document.createElement('tr')
  const headerCol = document.createElement('th')
  const colorBlock = document.createElement('span')
  tableHead.setAttribute('data-testid', 'tooltip-header')
  headerRow.setAttribute('data-testid', 'tooltip-header-row')
  headerCol.setAttribute('data-testid', 'tooltip-header-col')
  headerCol.setAttribute('class', 'flex align-items-center')
  if (backgroundColor && colorBlock) {
    colorBlock.setAttribute('data-testid', 'tooltip-color-block')
    colorBlock.setAttribute('class', 'mr-2 border-white border-2 w-1rem h-1rem inline-block')
    colorBlock.style.background = backgroundColor
    colorBlock.style.borderColor = borderColor || 'none'
    headerCol.appendChild(colorBlock)
  }

  headerCol.appendChild(document.createTextNode(title))
  headerRow.appendChild(headerCol)
  tableHead.appendChild(headerRow)
  table.appendChild(tableHead)
}
type TSetTooltipBodyParam = TTooltipProps & {
  exponent?: string | null,
  coefficient?: string,
  selectedDecimalViewType?: DecimalViewType,
  totalImpactUnit?: string | null,
  prefix?: string,
  backgroundColor?: string,
  borderColor?: string,
  colClassNames?: string
}
export const setTooltipBody = ({
  table,
  exponent,
  coefficient = '',
  selectedDecimalViewType = DecimalViewType.NumericValue,
  totalImpactUnit = '',
  prefix = '',
  colClassNames = '',
  backgroundColor,
  borderColor
}: TSetTooltipBodyParam) => {
  const tableBody = document.createElement('tbody')
  const bodyRow = document.createElement('tr')
  const bodyCol = document.createElement('td')
  const colorBlock = document.createElement('span')
  tableBody.setAttribute('data-testid', 'tooltip-body')
  bodyRow.setAttribute('data-testid', 'tooltip-body-row')
  bodyCol.setAttribute('data-testid', 'tooltip-body-col')
  bodyCol.setAttribute('class', `flex align-items-center ${colClassNames}`)

  if (backgroundColor && colorBlock) {
    colorBlock.setAttribute('data-testid', 'tooltip-color-block')
    colorBlock.setAttribute('class', 'mr-2 border-white border-2 w-1rem h-1rem inline-block')
    colorBlock.style.background = backgroundColor
    colorBlock.style.borderColor = borderColor || 'none'
    bodyCol.appendChild(colorBlock)
  }
  bodyCol.appendChild(document.createTextNode(prefix))
  if (exponent) {
    const maxTwoDecimalPlaces = Math.round((Number.parseFloat(coefficient) + Number.EPSILON) * 100) / 100
    const exponentDom = document.createElement('sup')
    exponentDom.appendChild(document.createTextNode(exponent))
    bodyCol.appendChild(document.createTextNode(`${maxTwoDecimalPlaces} \u00B7 10`))
    bodyCol.appendChild(exponentDom)
  } else {
    bodyCol.appendChild(document.createTextNode(coefficient))
  }
  bodyCol.appendChild(document.createTextNode((selectedDecimalViewType === DecimalViewType.PercentageValue) ? '%' : ` ${totalImpactUnit}`))
  bodyRow.appendChild(bodyCol)
  tableBody.appendChild(bodyRow)
  table.appendChild(tableBody)
}

type TSetTooltipStyleParam = {
  context: any,
  tooltipElement: HTMLDivElement,
  positionX?: number | string,
  positionY?: number | string
}
export const setTooltipStyle = ({
  context,
  tooltipElement,
  positionX = 0,
  positionY = 0
}: TSetTooltipStyleParam) => {
  const { tooltip } = context
  tooltipElement.style.opacity = '1'
  tooltipElement.style.left = `${positionX}px`
  tooltipElement.style.top = `${positionY}px`
  tooltipElement.style.font = tooltip.options.bodyFont.string
  tooltipElement.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
}

export const removeTooltip = (id: string) => {
  const tooltipElement = document.getElementById(id)
  tooltipElement?.remove()
}
