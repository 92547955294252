import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { AppProps } from '../../shared/interface/app-props'

const GlobalStyle = createGlobalStyle`
  .taskbar-filter {
    .p-multiselect-item {
      font-size: 0.875rem;
      line-height: 1rem;
    }

    .p-checkbox,
    .p-checkbox-box {
      width: 1rem;
      height: 1rem;
    }
  }
`

const FilterWrapper = styled.div`
  .p-multiselect {
    box-shadow: none !important;
    background: transparent;
  }
  .p-multiselect-token-label,
  .pi-times-circle {
    line-height: 0.875rem !important;
    font-size: 0.875rem !important;
  }
  .p-multiselect-label {
    padding: 0 0.5rem 0 1rem !important;
  }
  .p-placeholder {
    margin: 0.5rem 0 !important;
    line-height: 1rem !important;
    font-size: 0.875rem !important;
  }
  .p-multiselect-token {
    margin: 0.28rem 0 !important;
    margin-left: 0.25rem !important;
  }
  .p-multiselect-trigger {
    width: fit-content !important;
    padding: 0 1rem 0 0.5rem !important;
  }
  .pi-chevron-down {
    font-size: 0.5rem;
  }
`

export const TaskbarFilterWrapperLayout = ({ children }: AppProps) => (
  <>
    <GlobalStyle />
    <FilterWrapper data-testid="taskbar-filter-wrapper">
      { children }
    </FilterWrapper>
  </>
)
