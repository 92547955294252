import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ComparisonFlatView from '../../utils/comparisonFlatView'
import FlatView from '../../utils/flatView'
import { InventoryTreeKey } from '../../utils/inventoryTreeKey'
import resource from '../../utils/resource/resource'
import { returnNested, isEmpty } from '../../utils/tools'
import { getExpandedInventoryKeysMap } from '../../utils/treeUtils'
import {
  setComparisonFlatViewAction,
  setIsInventoryCompareAction,
} from '../actions/comparison.actions'
import {
  setIsDetailsPanelOpenAction,
  setIsShowInventoryAction,
  setIsSkipPhasesAction,
  setIsShowProductScenarioInventoryAction
} from '../actions/flags.actions'
import {
  setInventoryRootItemIdAction,
  setInventoryRootItemNameAction,
  setSelectedInventoryItemKeyAction,
  setSelectedProductAction,
} from '../actions/global.actions'
import {
  expandInventoryNodeAction,
  collapseInventoryNodeAction,
  setInventoryExpandedKeysAction
} from '../actions/inventoryExpandedKeys.actions'
import {
  setInventoryLifecyclesAction,
  setNewInventoryTreeAction,
} from '../actions/inventoryTree.actions'
import { setSelectedPhaseAction, setFlatViewAction } from '../actions/lifecycle.actions'
import { setIsLoadingLeftTreeAction } from '../actions/loading.actions'
import ComparisonSelector from '../selectors/comparison.selector'
import InventoryExpandedKeysSelector from '../selectors/inventoryExpandedKeys.selector'
import {
  inventoryRootItemIdSelector,
  InventoryTreeSelector,
  ProductScenarioSelector,
  selectedInventoryItemSelector
} from '../selectors/inventoryTree.selector'
import { getComparisonEntity, mapCompareEntities } from './comparison.saga'
import { clearComparison, clearScenario, dispatchErrors, mapProductEntity } from './helpers'
import { getProductScenario, getProductScenarioSaga } from './productScenarioInventoryTree.saga'
import { selectedInventoryItem, selectedInventoryItemSaga } from './product.saga'

export const LOAD_INVENTORY_PAGE = 'LOAD_INVENTORY_PAGE_SAGA'
export const SET_IS_INVENTORY_SKIP_PHASES = 'SET_IS_INVENTORY_SKIP_PHASES_SAGA'
export const GET_INVENTORY_TREE = 'GET_INVENTORY_TREE_SAGA'
export const UPDATE_INVENTORY_ITEMS = 'UPDATE_INVENTORY_ITEMS_SAGA'
export const TOGGLE_INVENTORY_EXPANDED_KEY = 'TOGGLE_INVENTORY_EXPANDED_KEY_SAGA'
export const UPDATE_PRODUCT_SCENARIO = 'UPDATE_PRODUCT_SCENARIO_SAGA'
export const SELECT_INVENTORY_ROOT_ITEM = 'SELECT_INVENTORY_ROOT_ITEM_SAGA'

export const loadInventoryPageSaga = params => ({
  type: LOAD_INVENTORY_PAGE,
  params
})

export const getInventoryTreeSaga = params => ({
  type: GET_INVENTORY_TREE,
  params
})
export const setIsInventorySkipPhasesSaga = skipPhases => ({
  type: SET_IS_INVENTORY_SKIP_PHASES,
  skipPhases
})

export const updateInventoryItemsSaga = (closePanel = false) => ({
  type: UPDATE_INVENTORY_ITEMS,
  closePanel
})

export const toggleExpandedKeySaga = (inventoryRootId, key, expanded) => ({
  type: TOGGLE_INVENTORY_EXPANDED_KEY,
  inventoryRootId, key, expanded
})

export const updateProductScenarioSaga = inventoryRootId => ({
  type: UPDATE_PRODUCT_SCENARIO,
  inventoryRootId
})

export const selectInventoryRootItemSaga = () => ({
  type: SELECT_INVENTORY_ROOT_ITEM
})

function* loadFullComparison(productId, entityId, entityType) {
  yield put(setIsInventoryCompareAction(true))
  yield all([
    call(getInventoryTree, getInventoryTreeSaga({ productId })),
    call(getComparisonEntity, entityId, entityType)
  ])

  const inventory = yield select(InventoryTreeSelector.inventoryTree)
  const entity = yield select(ComparisonSelector.entityToCompare)
  yield call(mapCompareEntities, mapProductEntity(inventory), entity)
  yield call(clearScenario)
}

function* loadFullProductScenario(productId, productScenarioId) {
  yield put(setIsShowProductScenarioInventoryAction(true))
  yield all([
    call(getInventoryTree, getInventoryTreeSaga(  { productId } )),
    call(getProductScenario, getProductScenarioSaga({ productId: productScenarioId })),
  ])

  const inventory = yield select(InventoryTreeSelector.inventoryTree)
  const productScenario = yield select(ProductScenarioSelector.inventoryTree)
  yield call(mapCompareEntities, mapProductEntity(inventory), mapProductEntity(productScenario))

}

function* loadOnlyProduct(productId, shouldSelectedProductBeUpdated) {
  yield call(getInventoryTree, getInventoryTreeSaga(  { productId, shouldSelectedProductBeUpdated }))
  const inventory = yield select(InventoryTreeSelector.inventoryTree)
  const flatView = new FlatView(mapProductEntity(inventory)).flatViewSkipPhases()
  yield put(setFlatViewAction(flatView))
  yield call(clearScenario)
  yield call(clearComparison)
}

/**
 * @param productScenarioId
 * @returns {IterableIterator<any>}
 */
function* loadOnlyScenario(productScenarioId) {
  yield call(getProductScenario, getProductScenarioSaga({ productId: productScenarioId }))
  yield put(setIsShowProductScenarioInventoryAction(true))
  const inventory = yield select(InventoryTreeSelector.inventoryTree)
  const productScenario = yield select(ProductScenarioSelector.inventoryTree)
  yield call(mapCompareEntities, mapProductEntity(inventory), mapProductEntity(productScenario))
}

function* loadInventoryPage(action) {
  try {
    const { productId, shouldSelectedProductBeUpdated, productScenarioId, entityId, entityType } = action.params
    if (isEmpty(productId)) return

    if (entityId && entityType) {
      yield call(loadFullComparison, productId, entityId, entityType)
    } else if (productScenarioId) {
      yield call(loadFullProductScenario, productId, productScenarioId)
    } else {
      yield call(loadOnlyProduct, productId, shouldSelectedProductBeUpdated)
    }
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 * @param {{params: {productId: string, comparisonParams: {entityId: string, entityType: string} shouldSelectedProductBeUpdated: boolean}}} action
 */
function* getInventoryTree(action) {
  const { productId, shouldSelectedProductBeUpdated } = action.params
  yield put(setIsLoadingLeftTreeAction(true))
  try {
    const inventory = yield call(resource.queryByParams, 'inventory', { productID: productId })
    const inventoryWithTimestamp = {
      ...inventory,
      timestamp: new Date().getTime()
    }
    const expandedKeysMap = yield select(InventoryExpandedKeysSelector.expandedKeysMapByRootId, productId)
    let effects = [
      put(setNewInventoryTreeAction(inventoryWithTimestamp)),
      put(setInventoryRootItemIdAction(inventory.product.id)),
      put(setInventoryRootItemNameAction(inventory.product.name)),
      put(setInventoryLifecyclesAction(inventory.product.lifecycles)),
      put(setIsShowInventoryAction(true)),
      put(setInventoryExpandedKeysAction(productId, getExpandedInventoryKeysMap(inventory, expandedKeysMap)))
    ]

    if (shouldSelectedProductBeUpdated) {
      const productWithImpact = { ...inventory.product, impact: inventory.totalImpact }
      const key = InventoryTreeKey.createFromRootId(inventory.product.id).inventoryTreeKey()
      effects = [
        ...effects,
        put(setSelectedInventoryItemKeyAction(key)),
        put(setSelectedProductAction(productWithImpact)),
        put(setIsDetailsPanelOpenAction(true))
      ]
    }

    yield all(effects)
  } catch (error) {
    yield call(dispatchErrors, error)
  } finally {
    yield put(setIsLoadingLeftTreeAction(false))
  }
}
/**
 * @param {{type: string, skipPhases: boolean}} action
 */
function* setIsInventorySkipPhases(action) {
  try {
    yield call(setInventoryFlatView, action.skipPhases)
    yield put(setIsSkipPhasesAction(action.skipPhases))
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* setInventoryFlatView(skipPhases) {
  const isInventoryCompare = yield select(ComparisonSelector.isInventoryCompare)
  const inventory = yield select(InventoryTreeSelector.inventoryTree)
  if (isInventoryCompare) {
    const entityA = mapProductEntity(inventory)
    const entityB = yield select(ComparisonSelector.entityToCompare)
    const comparisonFlatView = new ComparisonFlatView(entityA, entityB)
    const flatView = skipPhases
      ? comparisonFlatView.flatViewWithoutPhases()
      : comparisonFlatView.flatViewWithPhases()
    yield put(setComparisonFlatViewAction(flatView))
  } else {
    const flatView = new FlatView(mapProductEntity(inventory)).flatViewSkipPhases()
    yield put(setFlatViewAction(flatView))
  }
}
export function* updateInventoryItems(action) {
  try {
    const selectedInventoryItem = yield select(selectedInventoryItemSelector)
    const inventoryRootItemId = yield select(inventoryRootItemIdSelector)
    const key = selectedInventoryItem && InventoryTreeKey.createFromKey(selectedInventoryItem)
    const inventoryRootId = key ? returnNested(key, 'rootId') : inventoryRootItemId
    if (isEmpty(inventoryRootId)) return
    if (inventoryRootId === inventoryRootItemId) {
      yield call(getInventoryTree, getInventoryTreeSaga({
        productId: inventoryRootId,
        shouldSelectedProductBeUpdated: false,
      }))
      const inventory = yield select(InventoryTreeSelector.inventoryTree)
      const { entityId, entityType } = yield select(ComparisonSelector.entityIdAndTypeToCompare)
      if (entityId && entityType) {
        yield call(getComparisonEntity, entityId, entityType)
        const entity = yield select(ComparisonSelector.entityToCompare)
        yield call(mapCompareEntities, mapProductEntity(inventory), entity)
      } else {
        const flatView = new FlatView(mapProductEntity(inventory)).flatViewSkipPhases()
        yield put(setFlatViewAction(flatView))
      }
    } else {
      yield call(loadOnlyScenario, inventoryRootId)
    }

    if (action?.closePanel) {
      yield all([
        put(setSelectedInventoryItemKeyAction(null)),
        put(setSelectedProductAction(null)),
        put(setIsDetailsPanelOpenAction(false)),
        put(setSelectedPhaseAction(null))
      ])
    }
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

function* updateProductScenario(action) {
  yield call(loadOnlyScenario, action.inventoryRootId)
}

function* toggleInventoryExpandedKey(action) {
  const { inventoryRootId, key, expanded } = action
  if (isEmpty(inventoryRootId) || !key) return null
  yield expanded
    ? put(expandInventoryNodeAction(inventoryRootId, key))
    : put(collapseInventoryNodeAction(inventoryRootId, key))
}

function* selectInventoryRootItem() {
  try {
    const productId = yield select(InventoryTreeSelector.productId)
    const inventoryRootId = InventoryTreeKey.createFromRootId(productId).inventoryTreeKey()
    yield inventoryRootId && call(selectedInventoryItem, selectedInventoryItemSaga, inventoryRootId)
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export default function* inventoryTreeSaga() {
  yield takeLatest(LOAD_INVENTORY_PAGE, loadInventoryPage)
  yield takeLatest(GET_INVENTORY_TREE, getInventoryTree)
  yield takeLatest(SET_IS_INVENTORY_SKIP_PHASES, setIsInventorySkipPhases)
  yield takeLatest(UPDATE_INVENTORY_ITEMS, updateInventoryItems)
  yield takeLatest(TOGGLE_INVENTORY_EXPANDED_KEY, toggleInventoryExpandedKey)
  yield takeLatest(UPDATE_PRODUCT_SCENARIO, updateProductScenario)
  yield takeLatest(SELECT_INVENTORY_ROOT_ITEM, selectInventoryRootItem)
}
