import { call, put, takeLatest } from 'redux-saga/effects'
import resource from '../../utils/resource/resource'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import {
  setActivityTemplatesAction,
  removeActivityTemplateAction,
  setSelectedActivityTemplateAction
} from '../actions/global.actions'
import { dispatchErrors } from './helpers'

export const UPDATE_TEMPLATE_LIST = 'UPDATE_TEMPLATE_LIST_SAGA'
export const REMOVE_ACTIVITY_TEMPLATE = 'REMOVE_ACTIVITY_TEMPLATE_SAGA'
export const GET_ACTIVITY_TEMPLATE = 'GET_ACTIVITY_TEMPLATE_SAGA'

export const updateTemplateListSaga = spaceID => ({
  type: UPDATE_TEMPLATE_LIST,
  spaceID
})

export const removeActivityTemplateSaga = templateId => ({
  type: REMOVE_ACTIVITY_TEMPLATE,
  templateId
})

export const getActivityTemplateSaga = templateId => ({
  type: GET_ACTIVITY_TEMPLATE,
  templateId
})

function* getActivityTemplateHandler(action) {
  try {
    const activityTemplateItem = yield call(resource.queryByParams, 'activityTemplate', { id: action.templateId })
    yield put(setSelectedActivityTemplateAction(activityTemplateItem))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* updateTemplateListHandler(action) {
  try {
    const activityTemplateList = yield call(resource.queryByParams, 'activityTemplates', { spaceID: action.spaceID })
    yield put(setActivityTemplatesAction(activityTemplateList))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

function* removeActivityTemplateHandler(action) {
  try {
    yield call(resource.mutateByParams, 'removeActivityTemplate', { id: action.templateId })
    yield put(removeActivityTemplateAction(action.templateId))
    yield put(addSuccessNotificationAction('model.template_successfully_removed'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export default function* activityTemplateSaga() {
  yield takeLatest(UPDATE_TEMPLATE_LIST, updateTemplateListHandler)
  yield takeLatest(REMOVE_ACTIVITY_TEMPLATE, removeActivityTemplateHandler)
  yield takeLatest(GET_ACTIVITY_TEMPLATE, getActivityTemplateHandler)
}
