import { gql } from '@apollo/client'

export default gql`
  mutation addTagToProduct($tagID: ID!, $productID:ID!) {
    addTagToProduct(tagID: $tagID, productID: $productID) {
       tags {
         id
         name
         color
       }
    }
  }
`
