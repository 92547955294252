import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import DashboardContext from '../../dashboard/context/dashboard.context'

const Title = styled.div`
  width: calc(100% - 14rem);
`
export const LifecycleHeaderComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { updateDashboard } = useContext(DashboardContext)

  const openCreateLifeCycleDialog = () => {
    updateDashboard({ showCreateLifeCycleDialog: true })
  }

  return (
    <>
      <Title data-testid="lifecycle-header-title" className="flex flex-none text-4xl font-medium text-gray-800">
        { t('labels.lifecycle', { count: 2 }) }
      </Title>
      <div data-testid="lifecycle-header-actions" className="flex flex-none w-14rem justify-content-end">
        <Button
          onClick={openCreateLifeCycleDialog}
          data-cy="add-new-lifecycle"
          data-testid="overview-lifecycles-create"
          label={t('actions.create', { context: 'lifecycle' })}
          className="p-button p-component p-button-success my-1"
        />
      </div>
    </>
  )
}
