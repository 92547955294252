import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './component/app'
import { updatePageTitle } from './utils/tools'
import { LOCAL_BUILD } from './utils/const'

import 'primereact/resources/primereact.min.css'
import './v1/assets/themes/mytheme/theme.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'

if (
  window.env &&
  window.env.SENTRY_DSN &&
  window.env.SENTRY_DSN !== 'DO_NOT_APPLY'
) {
  Sentry.init({
    environment: window.env.NAME,
    dsn: window.env.SENTRY_DSN,
    integrations: [ new BrowserTracing() ],
    tracesSampleRate: 0.5,
    release: 'mobius@' + LOCAL_BUILD,
    ignoreErrors: [
      // Ant issue
      /ResizeObserver loop/,
      // rc-virtual-list (ANT's dependence) safari related bug https://github.com/getsentry/sentry-javascript/issues/756
      'null is not an object (evaluating \'n.listRef.current.clientHeight\')',
      // To reproduce: add an existing sub-product inside process selecting it from autocomplete dropdown list
      'Cannot read property \'clientHeight\' of null',
      // Bug from apollo/client upgrade to version 3.2.1
      'Invariant Violation: 15 (see https://github.com/apollographql/invariant-packages)',
      // Content Security Policy must be ignored by URL in `Additional ignored sources`, at:
      // https://eclog.ecochain.com/settings/abfapp/projects/production/security-headers/csp/
      // Add: ....
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      // Ignore "ui.click" breadcrumbs that result from "click" event listeners being added
      // https://github.com/getsentry/sentry-javascript/issues/2125
      if (breadcrumb.category === 'ui.click' && hint && hint.event instanceof Element) {
        return null
      }
      if (breadcrumb.category === 'ui.click') {
        // Replace classname by data-cy idf not, outerHTML which is a little easier to read.
        const outerHTML = hint.event.srcElement.outerHTML
        const temp = document.createElement('div')
        temp.innerHTML = outerHTML
        if (temp.firstChild && temp.firstChild.dataset && temp.firstChild.dataset.cy) {
          breadcrumb.message = temp.firstChild.dataset.cy
        } else if (outerHTML) {
          breadcrumb.message = outerHTML
        } else {
          console.log('JUST CLASS?') // This default code seems to never run
          const elementClicked = document.querySelectorAll(breadcrumb.message)
          console.log(elementClicked.outerHTML)
        }

        breadcrumb.message = `***USER CLICKED on ${breadcrumb.message}***`
      }

      return breadcrumb
    },
  })
}

ReactDOM.render(<App />, document.getElementById('root'), updatePageTitle)

