import { connect } from 'react-redux'
import Notification from './notification'
import { clearNotificationsAction } from '../../../redux/actions/notification.actions'

const mapStateToProps = state => ({
  errors: state.notifications.errors,
  successes: state.notifications.successes,
})

const mapDispatchToProps = {
  clearNotifications: clearNotificationsAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification)
