import React, { useContext } from 'react'

import DetailsPanel from '../../../../component/model/details-panel/detailsPanel.container'
// import ProductDetails from '../../../../component/model/details-panel/product-details/productDetails.container'
// import { MenuAction } from '../../enum/menu-action'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { store } from '../../../../configureStore'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { MenuAction } from '../../../product/enum/menu-action'
import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import LifecycleDetailPanelWrapper from '../../../../component/model/lifecycle/lifecycle-detail-panel-wrapper/lifecycleDetailPanelWrapper.container'

type TProductDialogComponentProps = {
  reload: () => void
}
export const LifecycleDetailDialogComponent = ({
  reload
}: TProductDialogComponentProps) => {
  const {
    selectedAction,
    updateProductDetail
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    showLifecycleDetailPanelOnly
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const isAddedTreeItemAction = () => selectedAction === MenuAction.AddTreeItem

  const afterLifecycleDetailChange = (callbackProps: any) => {
    // TODO : From v0.9 : To be refactored
    if (!showLifecycleDetailPanelOnly) {
      if (isAddedTreeItemAction() && callbackProps?.isClosedAfterAddItem) {
        store.dispatch(clearUiWhenDetailPanelCloseAction())
        updateProductDetail({ selectedAction: null, selectedProduct: null, selectedInventoryAmountV09: null })
      }

      isAddedTreeItemAction() && trackEventInSegment(TrackEventType.CREATED_SUBITEM)
    }

    reload()
  }

  return (
    <>
      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <LifecycleDetailPanelWrapper isEnabledForV1 updatePageItems={afterLifecycleDetailChange} />
      </DetailsPanel>
    </>
  )
}
