import React from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'
import { NavigationItemProps } from '../interface/navigation-items-props'

interface MainMenuItemProps {
  readonly disabled?: boolean
}

const MainMenuItem = styled.div<MainMenuItemProps>`
  line-height: 1.25rem;
  color: var(${(props: MainMenuItemProps) => (props.disabled ? '--gray-400' : '--surface-a')});

  :hover {
    cursor: ${(props: MainMenuItemProps) => (props.disabled ? 'default' : 'pointer')};
    background: var(${(props: MainMenuItemProps) => (props.disabled ? 'none' : '--surface-50')})
  }
`

const SubMenuItem = styled.div`
  max-width: 18.75rem;
  min-width: 10rem;
`

export const NavigationItemLayout = ({ itemAttr, mainMenuItem = true, itemComponent: ItemComponent }: NavigationItemProps) => {
  if (mainMenuItem) {
    const {
      dataCy, showBorder, selected, ...attr
    } = itemAttr || {}
    return (
      <div data-testid="main-menu-item-wrapper" className={classNames('main-menu-item-wrapper w-full p-2', { 'border-y-1': showBorder })}>
        <MainMenuItem
          data-cy={dataCy}
          data-testid={dataCy}
          disabled={itemAttr?.disabled}
          className={classNames('flex w-full border-round-md main-menu-item', { 'surface-100 font-semibold': selected })}
        >
          <ItemComponent {...attr} />
        </MainMenuItem>
      </div>
    )
  }

  return (
    <SubMenuItem
      data-cy={itemAttr?.dataCy}
      data-testid={itemAttr?.dataCy ? itemAttr?.dataCy : 'sub-menu-item'}
      className={classNames('sub-menu-item flex', { 'cy-submenu-item-disabled': itemAttr?.disabled })}
    >
      <ItemComponent {...itemAttr} />
    </SubMenuItem>
  )
}
