import React, {
  useContext, useEffect, useState
} from 'react'
import { useReactiveVar, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CreateLifeCycleComponent } from '../component/lifecycle/create-lifecycle.component'
import { selectedWorkspaceVar } from '../../../graphql/cache'

import { ILifecycle, IProduct } from '../../model'
import { CREATE_LIFECYCLE } from '../../graphql/mutation'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { useEntitiesPaginatedQuery } from '../hook/query/use-entities-paginated.query'
import { transformEntitiesToOptions } from '../util/transform'
import { PAGINATION } from '../constants'
import { TrackEventType } from '../enum/track-events'
import { EntityType } from '../enum/entity-type'
import { useHandleMutationResponse } from '../hook/use-handle-mutation-response'

type TCreateLifeCycleContainerProps = {
  afterCreateLifeCycle: (param: string, param1: TrackEventType) => void
}

export const CreateLifeCycleContainer = ({
  afterCreateLifeCycle
}: TCreateLifeCycleContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const {
    createLifeCycleFromProduct = false, selectedEntity, showCreateLifeCycleDialog, updateDashboard
  } = useContext(DashboardContext)
  const [ entities, setEntities ] = useState([])
  const [
    createLifeCycle,
    {
      error: failedCreatingLifecycle,
      data: createLifeCycleData = {},
      loading: lifeCycleCreating
    }
  ] = useMutation(CREATE_LIFECYCLE)
  const lifeCycleCreated = createLifeCycleData?.createLifecycle

  const { loading: loadingEntities, entitiesData, refetchEntities } = useEntitiesPaginatedQuery({
    spaceID,
    disabled: !showCreateLifeCycleDialog,
    pageSize: PAGINATION.PAGE_SIZE,
    skipLifeCycles: true
  })

  useEffect(() => {
    if (loadingEntities || !entitiesData) return

    const productsWithoutFolders = entitiesData.products?.items.filter((product: IProduct) => (product.__typename !== EntityType.Folder)) || []
    const objectsWithoutFolders = entitiesData.objects?.items.filter((object: IProduct) => (object.__typename !== EntityType.Folder)) || []

    const groupedEntitiesList:any = []

    if (productsWithoutFolders.length > 0) {
      groupedEntitiesList.push({ label: t('labels.product', { count: 2 }), items: transformEntitiesToOptions(productsWithoutFolders) })
    }
    if (objectsWithoutFolders.length > 0) {
      groupedEntitiesList.push({ label: t('labels.object', { count: 2 }), items: transformEntitiesToOptions(objectsWithoutFolders) })
    }

    setEntities(groupedEntitiesList)
  }, [ loadingEntities, entitiesData ])

  useEffect(() => {
    if (!lifeCycleCreating && (lifeCycleCreated || failedCreatingLifecycle)) {
      const context = 'createLifecycle'
      handleResponse({
        error: failedCreatingLifecycle,
        data: lifeCycleCreated,
        successToastDetail: { label: 'messages.success', context },
        errorToastSummary: { label: 'messages.errorSummary', context },
        callback: createLifecycleCallback
      })
    }
  }, [ lifeCycleCreating, lifeCycleCreated, failedCreatingLifecycle ])

  const createLifecycleCallback = (newlyCreatedLifecycle: ILifecycle) => {
    newlyCreatedLifecycle.id && afterCreateLifeCycle(newlyCreatedLifecycle.id, TrackEventType.CREATED_LIFECYCLE)
    closeCreateLifeCycleDialog()
  }

  const closeCreateLifeCycleDialog = () => {
    updateDashboard({ createLifeCycleFromProduct: false, selectedEntity: null, showCreateLifeCycleDialog: false })
  }

  const handleCreateLifeCycle = (values: any) => {
    let variables = {
      ...values, workspaceID: spaceID, amount: '', cb: () => {}
    }
    if (createLifeCycleFromProduct) {
      variables = { ...variables, productID: selectedEntity?.id }
    }
    createLifeCycle({ variables })
  }

  const handleFilterProducts = (query: string = '') => {
    refetchEntities && refetchEntities({
      query
    })
  }

  return (
    <>
      <CreateLifeCycleComponent
        entities={entities}
        handleFilterProducts={handleFilterProducts}
        handleCreateLifeCycle={handleCreateLifeCycle}
        createLifeCycleFromProduct={createLifeCycleFromProduct}
        closeCreateLifeCycleDialog={closeCreateLifeCycleDialog}
        showCreateLifeCycleDialog={showCreateLifeCycleDialog}
      />
    </>
  )
}
