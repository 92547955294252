import { trackEventInSegment, trackPageInSegment } from '../../../utils/SegmentWrapper'
import { DEBUG } from '../../../utils/const'
import { Environment } from '../enum'
import { getEnvFromUrl } from './navigation'

type TrackEventInSegmentType = (eventName: string, eventProperties?: object) => void;
type TrackPageInSegmentType = (eventCategory: string, eventName?: string, eventProperties?: object) => void;

export const getSegmentTrack = (): [TrackEventInSegmentType, TrackPageInSegmentType] => {
  const environment = getEnvFromUrl()
  const emptyTracker = () => {}
  if (DEBUG.SEGMENT || environment !== Environment.dev) {
    return [ trackEventInSegment, trackPageInSegment ]
  }
  return [ emptyTracker, emptyTracker ]
}
