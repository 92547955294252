import { gql } from '@apollo/client'
import referenceProduct from '../fragment/referenceProduct'

export default gql`
  mutation addConversionItems($conversionID: ID!, $productIDs: [ID!]!) {
    addConversionItems(conversionID: $conversionID, productIDs: $productIDs){
      id
      process {
        id
        name
      }
      inputs {
        product {
          id
          name
          unit
          type
          referenceProduct {
            ...referenceProduct
          }
          productProperties {
            referenceProperty {
              id
              name
            }
            conversionFactor
            referenceUnit {
              id
              name
            }
          }
        }
        value
      }
      outputs {
        product {
          id
          name
          unit
          type
          referenceProduct {
            ...referenceProduct
          }
          productProperties {
            referenceProperty {
              id
              name
            }
            conversionFactor
            referenceUnit {
              id
              name
            }
          }
        }
        value
      }
    }
  }
  ${referenceProduct}
`
