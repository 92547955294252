
export enum EComparisonTableColumn {
  Id = 'Id',
  Item = 'Item',
  PrimaryAmount = 'PrimaryAmount',
  PrimaryUnit = 'PrimaryUnit',
  PrimaryImpact = 'PrimaryImpact',
  PrimaryImpactBar = 'PrimaryImpactBar',
  SecondaryAmount = 'SecondaryAmount',
  SecondaryUnit = 'SecondaryUnit',
  SecondaryImpact = 'SecondaryImpact',
  SecondaryImpactBar = 'SecondaryImpactBar',
  ImpactChangeEffect = 'ImpactChangeEffect'
}

export enum EComparisonLifecycleTableColumn {
  Id = 'Id',
  Item = 'Item',
  PhaseName = 'PhaseName',
  PrimaryAmount = 'PrimaryAmount',
  PrimaryUnit = 'PrimaryUnit',
  PrimaryImpact = 'PrimaryImpact',
  PrimaryImpactBar = 'PrimaryImpactBar',
  SecondaryAmount = 'SecondaryAmount',
  SecondaryUnit = 'SecondaryUnit',
  SecondaryImpact = 'SecondaryImpact',
  SecondaryImpactBar = 'SecondaryImpactBar',
  ImpactChangeEffect = 'ImpactChangeEffect'
}
