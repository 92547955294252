import { connect } from 'react-redux'
import { startCheckoutSetupSaga } from '../../../redux/sagas/stripe.saga'
import AccountSelector from '../../../redux/selectors/account.selector'
import SubscriptionDetails from './subscriptionDetails'

const mapStateToProps = state => ({
  selectedAccountSubscription: AccountSelector.selectedAccountSubscription(state),
  isStandardSubscription: AccountSelector.isStandardSubscription(state),
  subscriptionSourcedFrom: AccountSelector.subscriptionSourcedFrom(state)
})

const mapDispatchToProps = {
  startCheckoutSetupSaga,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetails)
