
import { Button, Card } from 'antd'
import React from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { handleModal, isEmpty } from '../../../../utils/tools'
import { TRACK_EVENT_TYPE } from '../../../../utils/const'
import DashboardContext from '../../../../v1/dashboard/context/dashboard.context'

class CancelSubscription extends React.Component {
  static contextType = DashboardContext

  handleOnCancelSubscription = () =>  {
    const {
      canManageAccount,
      canManageBilling,
      cancelSubscriptionSaga,
      trackEventSaga,
      selectedAccountID,
      setAccountUsersAndRolesSaga,
      t
    } = this.props

    const { refetchUser } = this.context

    handleModal({
      title: t('billing.cancel_subscription_title'),
      content: t('billing.confirm_cancel_subscription'),
      onOk: () => {
        if (canManageAccount || canManageBilling) {
          cancelSubscriptionSaga(selectedAccountID, () => {
            canManageAccount && setAccountUsersAndRolesSaga(selectedAccountID)
            refetchUser && refetchUser()
          })
        }
        trackEventSaga(TRACK_EVENT_TYPE.SUBSCRIPTION_CANCELED)
      }
    })
  }
  render() {
    const { selectedAccountSubscription, t } = this.props
    if (isEmpty(selectedAccountSubscription)) return null
    return (
      <Card
        type="inner"
        className="card-item card-title-big"
        title={ t('billing.cancel_subscription_title')}
        extra={<Button onClick={this.handleOnCancelSubscription} type="danger" className="button danger">{t('billing.cancel_subscription_btn_label')}</Button>}
      >
        <div>
          { t('billing.cancel_subscription_description') }
          &nbsp;
          <Moment date={ selectedAccountSubscription.currentPeriodEnd } format='DD/MM/YYYY'/>
        </div>
      </Card>
    )
  }
}

export default compose(
  withTranslation()
)(CancelSubscription)

