import { Button, Card, Table, Tag, Tooltip } from 'antd'
import { PrinterOutlined, EyeOutlined, EuroCircleOutlined } from '@ant-design/icons'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { formatCurrency, isEmpty } from '../../../utils/tools'
import { INVOICE_STATUS } from '../../../utils/const'

class InvoiceList extends React.Component {

  renderAction = record => {
    const { t } = this.props
    if (record.hostedURL === '' || record.pdfURL === '') return null // Example draft
    const viewPay = record.status === 'open'
      ? { label: t('global.pay'), icon: <EuroCircleOutlined /> }
      : { label: t('global.view'), icon: <EyeOutlined /> }

    return <React.Fragment>
      <Tooltip placement="top" title={viewPay.label} mouseEnterDelay={1}>
        <Button
          className="button transparent"
          target="_blank"
          href={ record.hostedURL }
          icon={viewPay.icon}
        />
      </Tooltip>
      <Tooltip placement="top" title={t('global.print') } mouseEnterDelay={1}>
        <Button
          className="button transparent"
          target="_blank"
          href={ record.pdfURL }
          icon={<PrinterOutlined />}
        />
      </Tooltip>
    </React.Fragment>
  }

  renderStatus = status => {
    let color = null
    switch (status) {
    case INVOICE_STATUS.PAID:
      color = 'green'
      break
    case INVOICE_STATUS.OPEN:
      color = 'orange'
      break
    case INVOICE_STATUS.DRAFT:
      color = 'gold'
      break
    case INVOICE_STATUS.VOID:
    case INVOICE_STATUS.UNCOLLECTIBLE:
      color = 'red'
      break
    default:
    }
    return <Tag color={color}>{this.props.t(`billing.invoice.status.${status}`)}</Tag>
  }

  render() {
    const { selectedAccountInvoices, t } = this.props
    if (isEmpty(selectedAccountInvoices)) return null
    const dataSource = selectedAccountInvoices.map(invoice => ({
      ...invoice,
      key: invoice.id
    }))
    const columns = [
      {
        title: t('global.created'),
        dataIndex: 'period',
        key: 'period',
        render: (value, record) =>
          <React.Fragment>
            <Moment date={ record.periodStart } format='DD/MM/YYYY'/>
          </React.Fragment>
      },
      {
        title: t('global.description'),
        dataIndex: 'description',
        key: 'description',
        render: value => String(value)
          .split('\n')
          .map(line => <div key={line}>{ line }</div>)
      },
      {
        title: t('billing.amount'),
        dataIndex: 'amountDue',
        key: 'amountDue',
        render: (value, record) => formatCurrency(value, record.currency)
      },
      {
        title: t('billing.status'),
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 100,
        render: value => this.renderStatus(value)
      },
      {
        title: t('billing.actions'),
        key: 'actions',
        align: 'right',
        render: (val, record) => this.renderAction(record)
      }
    ]

    return (
      <div className="invoices-container">
        <Card
          type="inner"
          className="card-item  card-title-big"
          title={ t('billing.invoices') }
        >
          <Table
            size='small'
            bordered={ false }
            pagination={ false }
            dataSource={ dataSource }
            columns={ columns }
          />
        </Card>
      </div>
    )
  }
}

export default compose(
  withTranslation()
)(InvoiceList)
