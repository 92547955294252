import { connect } from 'react-redux'
import ImpactSelectorDialog from './impactSelectorDialog'
import { setIsImpactSelectorDialogShowedAction } from '../../../../redux/actions/flags.actions'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'

const mapStateToProps = state => {
  return ({
    selectedProduct: SelectedProductSelector.product(state),
    isSearchReferenceModalShowed: FlagsSelector.isSearchReferenceModalShowed(state),
    isDetailsPanelReadOnly: ProductMutationSelector.isDetailsPanelReadOnly(state)
  })
}

const mapDispatchToProps = {
  setIsImpactSelectorDialogShowedAction,
  trackEventSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImpactSelectorDialog)
