import { gql } from '@apollo/client'
import space from '../fragment/space'

export default gql`
  mutation createSpace($name: String!, $accountID: ID!) {
    createSpace(name: $name, accountID: $accountID) {
       ...spaceFragment
    }
  }
  ${space}
`
