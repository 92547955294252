import React, { useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { transformLeafItems } from '../../shared/util/transform'
import { useInsightQuery } from '../../shared/hook/query/use-insight.query'
import { IProduct } from '../../model'
import { ImpactSource } from '../../shared/enum/impact'
import { ProductType } from '../../shared/enum'

type TProductInsightContainerProps = {
  productID?: string,
  children?: React.ReactNode
}
export const ProductInsightContainer = ({
  children,
  productID = ''
}: TProductInsightContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    productInventory,
    updateProductDetail,
    isLeafInventoryLoadingEnabled,
    totalImpact: productTotalImpact = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    loading: loadingLeafInventoryItems,
    data: leafInventoryItemsData,
    refetch: refetchLeafInventoryItems
  } = useInsightQuery({
    productID,
    leafInventoryLoadingEnabled: isLeafInventoryLoadingEnabled()
  })

  const { inventory } = leafInventoryItemsData || {}
  const { leafInventoryItems = null, totalImpact = null } = inventory || {}

  const getImpactItem = (product?: IProduct) => {
    const impactItemDefaultProps = {
      id: 'impact',
      impactAmount: productTotalImpact?.amount,
      impactStatus: totalImpact?.status,
      impactRatioRelativeToMax: 1,
      impactRatioRelativeToTotal: 1,
    }

    if (product?.referenceProduct) {
      const referencePropertyId = product.referenceProduct?.referenceProperty?.id
      const isElementaryFlow = product.referenceProduct?.type === ProductType.ElementaryFow
      const propertyToShow = product.productProperties?.find(
        property => property?.referenceProperty?.id === referencePropertyId
      )
      const amount = propertyToShow?.conversionFactor
      const unit = propertyToShow?.referenceUnit?.name

      return {
        ...impactItemDefaultProps,
        name: product.referenceProduct.name,
        unit,
        amount,
        impactSource: isElementaryFlow ? ImpactSource.ElementaryFlow : ImpactSource.Dataset
      }
    }
    return {
      ...impactItemDefaultProps,
      name: t('labels.customImpact'),
      unit: '',
      amount: '1',
      impactSource: ImpactSource.Custom
    }
  }

  useEffect(() => {
    if (!loadingLeafInventoryItems && leafInventoryItems && leafInventoryItems.length > 0 && totalImpact) {
      const transformedLeafItems = transformLeafItems({ leafInventoryItems, totalImpactAmount: totalImpact.amount })
      updateProductDetail({
        transformedLeafItems,
        totalImpact,
        leafInventoryItems,
        refetchLeafInventoryItems,
        hasInventoryChanged: false,
        loadingLeafInventoryItems
      })
    } else if (!productInventory?.product?.hasInventory && !loadingLeafInventoryItems && productTotalImpact) {
      const impactItem = getImpactItem(productInventory?.product)

      updateProductDetail({
        transformedLeafItems: [ impactItem ],
        loadingLeafInventoryItems
      })
    } else {
      updateProductDetail({
        loadingLeafInventoryItems
      })
    }
  }, [ loadingLeafInventoryItems ])

  return (
    <>
      { loadingLeafInventoryItems && <ProgressSpinnerComponent dataTestId="loading-product-detail" size={2} />}

      { !loadingLeafInventoryItems && children }
    </>
  )
}
