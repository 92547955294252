import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'

import { useTranslation } from 'react-i18next'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { TooltipOverflowContent, useRefOverflow } from '../../../../shared/hook/use-ref-overflow'
import { ComparisonEntity } from '../../../enum/entity'

type TTableHeaderComponentProps ={
  entity?: ComparisonEntity
}
export const TableHeaderComponent = ({
  entity = ComparisonEntity.Primary
}: TTableHeaderComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const [ primaryEntityRef, hasPrimaryEntityOverflow, primaryEntityDom ] = useRefOverflow()
  const [ secondaryEntityRef, hasSecondaryEntityOverflow, secondaryEntityDom ] = useRefOverflow()

  const {
    primaryLifecycle, secondaryLifecycle,
    primaryEntityName, secondaryEntityName,
    primaryEntityUnit, secondaryEntityUnit,
    hiddenImpactBar
  } = useContext<TAnalysisContext>(AnalysisContext)

  const isPrimary = entity === ComparisonEntity.Primary

  const primaryEntityLabel = primaryLifecycle?.amount
    ? t('labels.unitOfEntityWithAmount', {
      amount: primaryLifecycle?.amount, unit: primaryEntityUnit, name: primaryEntityName, ns: 'common'
    })
    : t('labels.unitOfEntityName', { unit: primaryEntityUnit, name: primaryEntityName, ns: 'common' })

  const secondaryEntityLabel = secondaryLifecycle?.amount
    ? t('labels.unitOfEntityWithAmount', {
      amount: secondaryLifecycle?.amount, unit: secondaryEntityUnit, name: secondaryEntityName, ns: 'common'
    })
    : t('labels.unitOfEntityName', { unit: secondaryEntityUnit, name: secondaryEntityName, ns: 'common' })

  return (
    <div className="flex w-full">
      { primaryEntityName && isPrimary && (
        <>
          <Tooltip target={primaryEntityDom} onBeforeShow={() => hasPrimaryEntityOverflow} position="bottom">
            <TooltipOverflowContent>
              { primaryEntityLabel }
            </TooltipOverflowContent>
          </Tooltip>
          <div
            ref={primaryEntityRef}
            className={classNames('flex-none font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis', {
              'w-25rem': !hiddenImpactBar,
              'w-16rem': hiddenImpactBar
            })}
          >
            { primaryEntityLabel }
          </div>
        </>
      ) }
      { secondaryEntityName && !isPrimary && (
        <>
          <Tooltip target={secondaryEntityDom} onBeforeShow={() => hasSecondaryEntityOverflow} position="bottom">
            <TooltipOverflowContent>
              { secondaryEntityLabel }
            </TooltipOverflowContent>
          </Tooltip>
          <div
            ref={secondaryEntityRef}
            className={classNames('flex-none font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis', {
              'w-25rem': !hiddenImpactBar,
              'w-16rem': hiddenImpactBar
            })}
          >
            { secondaryEntityLabel }
          </div>
        </>
      ) }
    </div>
  )
}
