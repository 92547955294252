import { gql } from '@apollo/client'
import account from '../fragment/account'

export default gql`
  query account($id: ID!){
    account(id: $id) {
      ...accountFragment
    }
  }
  ${account}
`
