import { Col, Row, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, Fragment } from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../utils/with-router'
import { returnNested, getSunburstColorSet } from '../../../utils/tools'
import { EntityType, SUNBURST } from '../../../utils/const'
import {
  InventoryPageLocation,
  tabChartView,
  tabFlatView,
  tabTreeView,
  tabSunburst
} from '../../shared/locations'
import ComparisonFlatInventory from '../lifecycle/comparison-flat-inventory/comparisonFlatInventory.container'
import FlatInventory from '../lifecycle/flat-inventory/flatInventory.container'
import D3Chart from './inventory-D3/D3Chart.container.js'
import InventoryTotalImpact from './inventory-total-impact/inventoryTotalImpact.container'
import InventoryTreeWrapper from './inventory-tree/inventoryTreeWrapper.container'
import { getFlatViewWithRootProduct } from './inventory.service'
import ChangeImpactCategory from '../change-impact-category/changeImpactCategory.container'
import SunburstGraph from '../sunburst-graph/sunburstGraph.container'

const TabPane = Tabs.TabPane

const Inventory = props => {
  const {
    selectedSpace,
    inventoryTree,
    comparisonFlatView,
    activeTabKey,
    entityToCompare,
    isInventoryCompare,
    isShowProductScenarioInventory,
    setIsDetailsPanelOpenAction,
    t,
    flatView,
    selectedInventoryTreeProduct,
    setIsCreateLifecycle
  } = props

  const navigate = useNavigate()
  useEffect(() => () => setIsCreateLifecycle(null), [])

  const handleTabsOnChange = activeKey => {
    navigate(InventoryPageLocation.toUrl({
      ...InventoryPageLocation.parseLocationParams(),
      activeTabKey: activeKey
    }))
    setIsDetailsPanelOpenAction(false)
  }

  const flatViewWithRootProduct = getFlatViewWithRootProduct(flatView, selectedInventoryTreeProduct)
  const leafInventoryItems = returnNested(inventoryTree, 'leafInventoryItems')
  const rootInventoryProductWithImpact = inventoryTree && inventoryTree.product && {
    ...inventoryTree.product,
    impact: inventoryTree.totalImpact
  }
  const comparisonRootProductWithImpact = returnNested(entityToCompare, 'name') && {
    name: returnNested(entityToCompare, 'name'),
    impact: returnNested(entityToCompare, 'totalImpact'),
    unit: returnNested(entityToCompare, 'unit')
  }

  // TODO MOB-2691 handle mixed entity types comparison
  const isTreeViewDisabled = isInventoryCompare && returnNested(entityToCompare, '__typename') !== EntityType.PRODUCT
  const leafInventoryItemsWithRootProduct = rootInventoryProductWithImpact ? [
    {
      amount: 1,
      impact: { amount: rootInventoryProductWithImpact.impact.amount, unit: rootInventoryProductWithImpact.impact.unit },
      product: { name: rootInventoryProductWithImpact.name, id: rootInventoryProductWithImpact.id } },
    ...leafInventoryItems
  ] : leafInventoryItems

  const sunburstColorSet = getSunburstColorSet({
    setA: leafInventoryItems,
    setB: returnNested(entityToCompare, 'leafInventoryItems'),
    reversed: true
  })

  const isRightInventoryShown = isInventoryCompare || isShowProductScenarioInventory

  const SingleSunburst = () => <Col span={ isRightInventoryShown ? 12 : 24 }>
    <SunburstGraph
      sunburstId="sunburst-basic"
      key={ returnNested(inventoryTree, 'product', 'id') }
      leafInventory={ leafInventoryItems }
      activeTabKey={ activeTabKey }
      rootProduct={rootInventoryProductWithImpact}
      squareSize={ SUNBURST.FULL_SIZE }
      colorSet={sunburstColorSet}
    />
  </Col>

  const CompareSunburst = () => <Col span={ 12 }>
    <SunburstGraph
      sunburstId="sunburst-compare"
      key={ returnNested(entityToCompare, 'id') }
      leafInventory={ returnNested(entityToCompare, 'leafInventoryItems') }
      activeTabKey={ activeTabKey }
      rootProduct={ comparisonRootProductWithImpact }
      squareSize={ SUNBURST.FULL_SIZE}
      colorSet={sunburstColorSet}
    />
  </Col>

  const SingleSunburstTotal = () => <Col flex="1">
    <InventoryTotalImpact
      impact={ returnNested(inventoryTree, 'totalImpact') }
      impactToCompare={ returnNested(entityToCompare, 'totalImpact') }
      isCarbonTranslatorShowed={!isRightInventoryShown}
    />
  </Col>

  const CompareSunburstTotal = () => <Col flex="1">
    <InventoryTotalImpact
      impact={ returnNested(entityToCompare, 'totalImpact') }
      impactToCompare={ returnNested(inventoryTree, 'totalImpact') }
      isCarbonTranslatorShowed={false}
    />
  </Col>

  return <React.Fragment>
    <Tabs
      animated={ false }
      activeKey={ activeTabKey }
      onChange={ handleTabsOnChange }
      className="inventory-tabs"
      data-cy="inventory-tabs"
      tabBarExtraContent={<ChangeImpactCategory/>}
    >
      <TabPane tab={t('model.tree_view')} key={tabTreeView} disabled={isTreeViewDisabled}>
        <InventoryTreeWrapper />
      </TabPane>
      <TabPane tab={t('model.flat_view')} key={tabFlatView}>
        <Row>
          <Col span={ 24 }>
            {
              isRightInventoryShown
                ? <ComparisonFlatInventory
                  title={ returnNested(inventoryTree, 'product', 'name') }
                  comparisonFlatView={comparisonFlatView}
                />
                : flatViewWithRootProduct && <Fragment>
                  <FlatInventory
                    title={ returnNested(inventoryTree, 'product', 'name') }
                    flatView={flatViewWithRootProduct}
                  />
                </Fragment>
            }
          </Col>
        </Row>
      </TabPane>
      <TabPane tab={ t('model.d3_title_bar') } key={tabChartView} disabled={isRightInventoryShown}>
        <Row>
          <Col span={ 24 }>
            <D3Chart
              rootInventoryProductWithImpact={ rootInventoryProductWithImpact}
              title={rootInventoryProductWithImpact && `1 ${rootInventoryProductWithImpact.unit ? rootInventoryProductWithImpact.unit : ' unit'} of ${rootInventoryProductWithImpact.name}`}
              impactCatName={returnNested(selectedSpace, 'impactCategory', 'name')}
              leafInventory={leafInventoryItemsWithRootProduct || []}
            />
          </Col>
        </Row>
      </TabPane>
      <TabPane tab={`${t('model.d3_title_sunburst')}`} key={tabSunburst}>
        <Row>
          { isShowProductScenarioInventory && <CompareSunburst /> }
          <SingleSunburst />
          { !isShowProductScenarioInventory && isInventoryCompare && <CompareSunburst /> }
        </Row>
        <Row gutter={16} type="flex">
          { isShowProductScenarioInventory && <CompareSunburstTotal /> }
          <SingleSunburstTotal />
          { !isShowProductScenarioInventory && isInventoryCompare && <CompareSunburstTotal /> }
        </Row>
      </TabPane>
    </Tabs>
  </React.Fragment>
}

export { Inventory }
export default compose(
  withTranslation(),
  withRouter,
)(Inventory)
