import { takeEvery, call, select } from 'redux-saga/effects'
import { trackEventInSegment, trackPageInSegment } from '../../utils/SegmentWrapper'
import { getEnvironmentFromUrl } from '../../utils/tools'
import { DEBUG } from '../../utils/const'
import FlagsSelector from '../selectors/flags.selector'
import UserSelector from '../selectors/user.selector'

export const
  TRACK_EVENT= 'TRACK_EVENT_SAGA',
  TRACK_PAGE = 'TRACK_PAGE'

export const trackEventSaga = (eventName, eventProperties, eventOptions) => ({
  type: TRACK_EVENT,
  eventName, eventProperties, eventOptions
})

export const trackPageSaga = (eventName, eventProperties, eventOptions) => ({
  type: TRACK_PAGE,
  eventName, eventProperties, eventOptions
})

function isDevelopmentEnv() {
  if (DEBUG.SEGMENT) return false
  return getEnvironmentFromUrl() === 'development'
}

function* trackEvent(action) {
  const isAdBlockEnabled = yield select(FlagsSelector.isAdBlockEnabled)
  if (isAdBlockEnabled) return
  const { eventName, eventProperties, eventOptions = {} } = action || {}
  const user = yield select(UserSelector.selectedUser)
  const options = {
    traits: {
      email: user?.email
    },
    ...eventOptions
  }
  try {
    if (yield call(isDevelopmentEnv)) return
    yield call(trackEventInSegment, eventName, eventProperties, options )
  } catch (e) {
    // Do nothing user has ad-blocker :(
    console.error(e)
  }
}
function* trackPage(action) {
  const isAdBlockEnabled = yield select(FlagsSelector.isAdBlockEnabled)
  if (isAdBlockEnabled) return
  try {
    if (yield call(isDevelopmentEnv)) return
    const { eventName, eventProperties, eventOptions = {} } = action || {}
    const user = yield select(UserSelector.selectedUser)
    const options = {
      traits: {
        email: user?.email
      },
      ...eventOptions
    }
    const eventCategory = `Page loaded: ${action.eventName}`
    yield call(trackPageInSegment, eventCategory, eventName, eventProperties, options )
  } catch (e) {
    // Do nothing user has ad-blocker :(
    console.error(e)
  }
}
export default function* trackingSaga() {
  yield takeEvery(TRACK_EVENT, trackEvent)
  yield takeEvery(TRACK_PAGE, trackPage)
}
