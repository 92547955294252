import PhaseType from '../../component/model/lifecycle/phaseType'
import { returnNested, safeArray } from '../../utils/tools'

const root = state => returnNested(state, 'lifecycle'),
  selectedLifecycle = state => returnNested(root(state), 'selectedLifecycle'),
  lifecyclesPaginated = state => returnNested(root(state), 'lifecyclesPaginated'),
  product = state => returnNested(selectedLifecycle(state), 'product'),
  productId = state => returnNested(product(state), 'id'),
  productName = state => returnNested(product(state), 'name'),
  lifecycleId = state => returnNested(selectedLifecycle(state), 'id'),
  inventory = state => returnNested(selectedLifecycle(state), 'inventory'),
  selectedLifecycleName = state => returnNested(selectedLifecycle(state), 'name'),
  selectedLifecycleDescription = state => returnNested(selectedLifecycle(state), 'description'),
  selectedLifecycleAmount = state => returnNested(selectedLifecycle(state), 'amount'),
  selectedLifecycleUnit = state => returnNested(selectedLifecycle(state), 'unit'),
  selectedPhase = state => returnNested(root(state), 'selectedPhase'),
  selectedPhaseId = state => returnNested(selectedPhase(state), 'id'),
  selectedPhaseInventoryTree = state => returnNested(selectedPhase(state), 'inventory'),
  inventoryTotalImpact = state => returnNested(inventory(state), 'totalImpact'),
  selectedLifecyclePhasesList = state => returnNested(selectedLifecycle(state), 'phases'),
  phases = state => safeArray(returnNested(selectedLifecycle(state), 'phases')),
  flatView = state => returnNested(root(state), 'flatView'),
  phaseByProductId = (state, productIdInPhase) => phases(state).find(phase => returnNested(phase, 'inventory', 'product', 'id') === productIdInPhase),
  phaseByType = (state, type) => phases(state).find(phase => returnNested(phase, 'type') === type),
  phaseInventoryByProductId = (state, productIdInPhase) => returnNested(phaseByProductId(state, productIdInPhase), 'inventory'),
  productionInventory = state => {
    const productionPhase = phaseByType(state, PhaseType.production.type)
    return returnNested(productionPhase, 'inventory')
  },
  productionProductId = state => returnNested(productionInventory(state), 'product', 'id'),
  productionFirstInventoryNode = state => returnNested(productionInventory(state), 'inventoryItems', 0),
  scenarios = state => returnNested(selectedLifecycle(state), 'scenarios'),
  detailsPanelLifecycleId = state => returnNested(root(state), 'detailsPanelLifecycleId')

// Lifecycle scenario
const lifecycleScenarioRoot = state => returnNested(root(state), 'lifecycleScenario'),
  lifecycleScenarioId = state => returnNested(lifecycleScenarioRoot(state), 'id'),
  lifecycleScenarioProduct = state => returnNested(lifecycleScenarioRoot(state), 'product'),
  lifecycleScenarioProductId = state => returnNested(lifecycleScenarioProduct(state), 'id'),
  lifecycleScenarioPhases = state => returnNested(lifecycleScenarioRoot(state), 'phases'),
  lifecycleScenarioPhaseByProductId = (state, productIdInPhase) => safeArray(lifecycleScenarioPhases(state)).find(phase => returnNested(phase, 'inventory', 'product', 'id') === productIdInPhase),
  lifecycleScenarioPhaseInventoryByProductId = (state, productIdInPhase) => returnNested(lifecycleScenarioPhaseByProductId(state, productIdInPhase), 'inventory')

export const LifecycleSelector = {
  selectedLifecycle,
  selectedLifecycleName,
  selectedLifecycleDescription,
  selectedLifecycleAmount,
  selectedLifecycleUnit,
  inventory,
  inventoryTotalImpact,
  productId,
  productName,
  selectedPhase,
  selectedPhaseId,
  selectedPhaseInventoryTree,
  lifecycleId,
  phases,
  flatView,
  phaseByProductId,
  phaseInventoryByProductId,
  productionProductId,
  productionInventory,
  productionFirstInventoryNode,
  selectedLifecyclePhasesList,
  scenarios,
  detailsPanelLifecycleId,
  lifecyclesPaginated
}

export const LifecycleScenarioSelector = {
  lifecycle: lifecycleScenarioRoot,
  lifecycleId: lifecycleScenarioId,
  productId: lifecycleScenarioProductId,
  phaseByProductId: lifecycleScenarioPhaseByProductId,
  phaseInventoryByProductId: lifecycleScenarioPhaseInventoryByProductId,
}
