export enum SubscriptionSource {
    Unspecified = 'UNSPECIFIED',
    Admin = 'ADMIN',
    Stripe = 'STRIPE',
    Mobius= 'MOBIUS'
}

export enum SubscriptionPlan {
    MobiusStarter = 'mobius_starter',
    MobiusBusiness = 'mobius_business',
    MobiusProfessional = 'mobius_professional'
}
