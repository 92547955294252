import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Layout, Modal, Image } from 'antd'
import compose from '../../../utils/compose'
import { withRouter } from '../../../utils/with-router'
import { NAV, DEFAULT } from '../../../utils/const'
import { withTranslation } from 'react-i18next'
import { useAuth0Api } from '../../../v1/shared/hook/query/use-auth0-api.query'

const PasswordResetPage = props => {
  const { t } = props
  const navigate = useNavigate()
  const params = useParams()
  const { passwordChange } = useAuth0Api()

  const handleReset = () => {
    const passwordChangeHandler = async () => {
      await passwordChange(params?.email)
      navigate(NAV.LOGOUT)
    }
    passwordChangeHandler()
  }

  return <div className="frame">
    <Layout className="model" >
      <Layout className='middle-container'>
        <Layout.Content>
          <Modal
            visible={true}
            closable={false}
            mask={false}
            className="modal-dialog modal-with-banner"
            title={<div className="modal-blue-banner">
              <Image alt="logo-ecochain" src="/assets/images/ecochain-logo-blue-bg.png" />
            </div>}
            footer={[
              <Button
                size="large"
                type="primary"
                onClick={handleReset}
                key="button-reset"
              >
                {t('entry.password_reset')}
              </Button>
            ]}
          >
            <h1>{t('model.please_reset_your_password')}</h1>
            <p>{t('model.please_reset_your_password_message', { days: DEFAULT.PASSWORD_EXPIRATION_DAYS })}</p>
          </Modal>
        </Layout.Content>
      </Layout>
    </Layout>
  </div>
}

export default compose(
  withRouter,
  withTranslation()
)(PasswordResetPage)
