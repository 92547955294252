import React, { useEffect, useState } from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { Upload, Button, Divider, Row, Col, Table, Modal, Collapse, List, Dropdown, Menu } from 'antd'
import { FileExcelOutlined, ExclamationCircleOutlined, LoadingOutlined, UploadOutlined, WarningTwoTone, CheckCircleTwoTone } from '@ant-design/icons'
import { isEmpty } from '../../../../utils/tools'
import { NAV, PRODUCT_IMPORT_EXPORT } from '../../../../utils/const'
import { downloadFileFromUrl } from '../../../shared/locations'

export const downloadSample = (menuItem = {}) => {
  const { key } = menuItem
  switch (key) {
  case PRODUCT_IMPORT_EXPORT.DOWNLOAD_T_SHIRT:
    downloadFileFromUrl(NAV.CSV_T_SHIRT)
    break
  case PRODUCT_IMPORT_EXPORT.DOWNLOAD_TEMPLATE:
    downloadFileFromUrl(NAV.CSV_TEMPLATE_DEMO)
    break
  case PRODUCT_IMPORT_EXPORT.DOWNLOAD_PRODUCTS:
    downloadFileFromUrl(NAV.CSV_LIST_PRODUCTS)
    break
  case PRODUCT_IMPORT_EXPORT.DOWNLOAD_OBJECTS:
    downloadFileFromUrl(NAV.CSV_LIST_OBJECTS)
    break
  default:
  }
}

const { confirm } = Modal

const endpoint = '/product-import'
const apiUrl = (window.env && window.env.API_URL) || 'env-vars-missing'
const action = apiUrl.split('/').slice(0, -1).join('/') + endpoint

function ProductImport(props) {
  const [ authHeader, setAuthHeader ] = useState('')
  const [ isUploading, setIsUploading ] = useState(false)
  const [ uploadReport, setUploadReport ] = useState(null)
  const {
    t,
    columns,
    productLabel,
    selectedSpaceId,
    getAuthTokenSaga,
    updateSpaceProductsSaga,
    getProductImportColumnsSaga,
    productImportModalVisibility,
  } = props

  useEffect(() => {
    !authHeader && getAuthTokenSaga(t => setAuthHeader(t ? `Bearer ${t}` : ''))
    getProductImportColumnsSaga()
  }, [])

  useEffect(() => {
    !productImportModalVisibility && setUploadReport(null)
  }, [ productImportModalVisibility ])

  const getUserConfirmation = () => {
    return new Promise((resolve, reject) => {
      confirm({
        title: t('global.warning'),
        icon: <ExclamationCircleOutlined />,
        content: t('model.import_same_products_warning'),
        onOk: () => resolve(true),
        onCancel: () => reject(false)
      })
    })

  }

  const options = {
    accept: '.csv',
    name: 'import',
    action,
    method: 'POST',
    showUploadList: false,
    data: { spaceID: selectedSpaceId },
    headers: { Authorization: authHeader },
    beforeUpload: () => getUserConfirmation(),
    onChange(info) {
      switch (info.file.status) {
      case 'uploading':
        setIsUploading(true)
        setUploadReport(null)
        break
      case 'done':
        setIsUploading(false)
        updateSpaceProductsSaga(selectedSpaceId, [ productLabel ])
        setUploadReport({
          ...info.file.response,
          message: t('model.products_import_success')
        })
        break
      case 'error':{
        setIsUploading(false)
        const message = info.file.response.rows
          ? t('model.products_import_validation_fail', { file: info.file.name })
          : t('model.products_import_failure')

        if (!info.file.response.rows) {
          info.file.response = { rows: [ { msg: info.file.response } ] }
        }

        setUploadReport({ ...info.file.response, message })
        break
      }
      default:  // required by the linter
      }
    },
  }

  const tColumns = [
    { title: t('model.products_import_column_name'), dataIndex: 'name', key: 'name' },
    { title: t('model.products_import_column_alt_names'), dataIndex: 'altNames', key: 'altNames' },
    { title: t('model.products_import_column_description'), dataIndex: 'description', key: 'description' },
  ]

  const requiredColumn = name => <span title={t('model.products_import_column_required')}>{name} *</span>

  const tData = columns => columns.map(c => ({
    ...c,
    key: c.name,
    name: c.required ? requiredColumn(c.name) : c.name,
    altNames: <small>{c.altNames.join(', ')}</small>,
  }))

  const reportRows = report => report.hasMore
    ? report.rows.concat([ { msg: '…', noIcon: true } ])
    : report.rows

  const successItems = report => [
    { msg: t('model.import_created_products'), count: report.created },
    { msg: t('model.import_updated_products'), count: report.updated },
  ]

  const successItem = item => <div>
    <CheckCircleTwoTone className="report-icon" twoToneColor="#06b856" />
    {item.msg}: {parseInt(item.count)}
  </div>

  const warningItem = item => <div>
    {!item.noIcon && <WarningTwoTone className="report-icon" twoToneColor="#fcd303"/>}
    {item.row && <span>
      {t('model.import_row')} <b>{item.row}</b>
      {item.col && <span>, {t('model.import_column')} '{item.col}'</span>}
      : </span>
    }
    {item.msg}
  </div>

  const downloadMenu = [
    { key: PRODUCT_IMPORT_EXPORT.DOWNLOAD_TEMPLATE, icon: FileExcelOutlined },
    { key: PRODUCT_IMPORT_EXPORT.DOWNLOAD_PRODUCTS, icon: FileExcelOutlined },
    { key: PRODUCT_IMPORT_EXPORT.DOWNLOAD_OBJECTS, icon: FileExcelOutlined }
  ]

  return <React.Fragment>
    <Row gutter={16}>
      <Col>
        <Upload {...options}>
          <Button
            type="primary"
            disabled={isUploading}
            icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />}
          >{t('model.select_csv_file_to_upload')}</Button>
        </Upload>
      </Col>
      <Col>
        <Dropdown.Button
          onClick={ () => downloadSample({ key: PRODUCT_IMPORT_EXPORT.DOWNLOAD_T_SHIRT })}
          overlay={(
            <Menu onClick={downloadSample}>
              {
                downloadMenu.map(({ key, icon: Icon }) => (
                  <Menu.Item key={key}>
                    <Icon /> { t('model.productsDropdownItem', { context: key }) }
                  </Menu.Item>
                ))
              }
            </Menu>
          )}
        >
          <FileExcelOutlined /> { t('model.productsDropdownItem', { context: PRODUCT_IMPORT_EXPORT.DOWNLOAD_T_SHIRT }) }
        </Dropdown.Button>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col className="table-explanation">
        {t('model.importFileStructureExplanation')}
      </Col>
    </Row>

    {!isEmpty(uploadReport) &&
      <Collapse defaultActiveKey="reportPanel" expandIconPosition="right">
        <Collapse.Panel key="reportPanel" header={uploadReport.message}>
          <React.Fragment>
            {uploadReport.success &&
              <List
                dataSource={successItems(uploadReport)}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta title={successItem(item)} />
                  </List.Item>
                )}/>
            }
            {uploadReport.rows &&
              <List
                dataSource={reportRows(uploadReport)}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta description={warningItem(item)} />
                  </List.Item>
                )}/>
            }
          </React.Fragment>
        </Collapse.Panel>
      </Collapse>
    }

    {!isEmpty(uploadReport) && !isEmpty(columns) && <Divider />}

    {!isEmpty(columns) &&
      <Collapse expandIconPosition="right">
        <Collapse.Panel key="columnsPanel" header={t('model.products_import_columns')}>
          <Table
            size="small"
            pagination={false}
            dataSource={tData(columns)}
            columns={tColumns} />
        </Collapse.Panel>
      </Collapse>
    }
  </React.Fragment>
}

export default compose(
  withTranslation()
)(ProductImport)
