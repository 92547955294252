import { returnNested } from '../../utils/tools'
import {
  ADD_INVENTORY_PRODUCT_SCENARIO,
  SET_NEW_INVENTORY_TREE,
  SET_INVENTORY_ITEMS_TO_DISPLAY
} from '../actions/inventoryTree.actions'

import {
  CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT,
  CLEAR_GLOBAL_STATE_EXCEPT_USER,
  CLEAR_INVENTORY_TREE_STATE
} from '../actions/clear.actions'

const defaultState = {
  product: null,
  inventoryItems: [],
  leafInventoryItems: [],
  totalImpact: null,
  productScenarioInventoryTree: null,
  leafInventory: [],
  inventoryItemsToDisplay: []
}

const inventoryReducer = (state = defaultState, action={}) => {
  switch (action.type) {
  case CLEAR_GLOBAL_STATE_EXCEPT_USER:
  case CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT:
    return defaultState
  case SET_NEW_INVENTORY_TREE:
    return action.inventoryTree
  case ADD_INVENTORY_PRODUCT_SCENARIO:{
    let selectedProductForAddScenario = { ...state.product }
    selectedProductForAddScenario.scenarios = selectedProductForAddScenario.scenarios || []
    selectedProductForAddScenario.scenarios = [ ...selectedProductForAddScenario.scenarios, action.scenario ]
    return { ...state, product: selectedProductForAddScenario }
  }
  case 'RENAME_PRODUCT':{
    if (!returnNested(state, 'product')) return state
    const selectedProductForRename = { ...state.product }
    selectedProductForRename.scenarios = selectedProductForRename.scenarios || []
    selectedProductForRename.scenarios = [ ...selectedProductForRename.scenarios ].map(productScenario => {
      return productScenario.id === action.productId
        ? { ...productScenario, name: action.newName }
        : productScenario
    })
    return { ...state, product: selectedProductForRename }
  }
  case 'REMOVE_PRODUCT':{
    if (!returnNested(state, 'product')) return state
    const selectedProductForProductRemove = { ...state.product }
    selectedProductForProductRemove.scenarios = selectedProductForProductRemove.scenarios || []
    selectedProductForProductRemove.scenarios =  selectedProductForProductRemove.scenarios.filter(p => p.id !== action.productId)
    return {
      ...state,
      product: selectedProductForProductRemove
    }
  }
  case SET_INVENTORY_ITEMS_TO_DISPLAY:
    return {
      ...state,
      inventoryItemsToDisplay: action.inventoryItemsToDisplay
    }
  case CLEAR_INVENTORY_TREE_STATE:
    return defaultState
  default:
    return state
  }
}

export default inventoryReducer
