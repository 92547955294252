import React, { useContext } from 'react'
import { Tag } from 'primereact/tag'
import { classNames } from 'primereact/utils'
import { Tooltip } from 'primereact/tooltip'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ComparisonEntity } from '../../../enum/entity'
import AnalysisContext from '../../../provider/context/analysis.context'
import { TAnalysisContext } from '../../../interface/analysis.context'
import { store } from '../../../../../configureStore'
import { SelectedProductSelector } from '../../../../../redux/selectors/product.selector'
import FlagsSelector from '../../../../../redux/selectors/flags.selector'
import { clearUiWhenDetailPanelCloseAction } from '../../../../../redux/actions/clear.actions'
import { showProductInDetailsPanelSaga } from '../../../../../redux/sagas/product.saga'
import { TooltipOverflowContent, useRefOverflow } from '../../../../shared/hook/use-ref-overflow'
import { setIsDetailPanelReadonlyV1Action } from '../../../../../redux/actions/flags.actions'
import { AnalysisMode } from '../../../enum/analysis'

interface TreeHeaderProps {
  readonly entity?: ComparisonEntity
}

const TreeHeader = styled.div`
  background: var(${(props: TreeHeaderProps) => (props.entity === ComparisonEntity.Primary ? '--gray-50' : '--blue-50')});

  .tree-wrapper-title {
    cursor: pointer;
    max-width: calc(100% - 8rem);
    &:hover {
      color: var(--primary-500) !important;
    }
  }
`
export const TreeHeaderComponent = ({
  entity = ComparisonEntity.Primary
}: TreeHeaderProps) => {
  const { t } = useTranslation([ 'product', 'common', 'analysis' ])
  const [ entityRef, hasEntityOverflow, entityDom ] = useRefOverflow()
  const {
    primaryEntityId,
    secondaryEntityId,
    primaryEntityName,
    secondaryEntityName,
    primaryEntityUnit,
    secondaryEntityUnit,
    analysisMode,
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext)

  const isPrimary = entity === ComparisonEntity.Primary
  const entityName = isPrimary ? primaryEntityName : secondaryEntityName
  const entityUnit = isPrimary ? primaryEntityUnit : secondaryEntityUnit
  const entityId = isPrimary ? primaryEntityId : secondaryEntityId
  const analysisInScenario = analysisMode === AnalysisMode.Scenario
  const entityLabel = t('labels.unitOfEntityName', { unit: entityUnit, name: entityName, ns: 'common' })

  const openDetailPanel = () => {
    // TODO : Added for compatibility with v0.9
    const state = store.getState()
    const selectedProductId = SelectedProductSelector.productId(state)
    const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
    if (entityId === selectedProductId && isDetailsPanelOpen) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    analysisInScenario && updateAnalysis({ scenarioSelectedKey: 'root' })
    store.dispatch(setIsDetailPanelReadonlyV1Action(analysisInScenario && isPrimary))
    store.dispatch(showProductInDetailsPanelSaga(entityId))
  }

  return (
    <TreeHeader entity={entity} className="flex w-full px-4 py-3 border-round-top-lg text-lg">

      { entityName && (
        <>
          <Tooltip target={entityDom} onBeforeShow={() => hasEntityOverflow} position="bottom">
            <TooltipOverflowContent>
              { entityLabel }
            </TooltipOverflowContent>
          </Tooltip>
          <div
            data-cy={`tree-header-${entity}-name`}
            ref={entityRef}
            data-testid="tree-wrapper-title"
            onClick={openDetailPanel}
            className="flex-none mr-2 font-medium text-base white-space-nowrap overflow-hidden text-overflow-ellipsis tree-wrapper-title"
          >
            { entityLabel }
          </div>
        </>
      ) }

      <div className="flex flex-grow-1 min-w-min">
        <Tag
          data-testid="tree-wrapper-tag"
          className={classNames('p-0 px-1 my-1 font-normal white-space-nowrap text-xs', {
            'bg-blue-100 text-primary-800 ': isPrimary,
            'bg-blue-500 text-white': !isPrimary
          })}
          value={isPrimary ? t('labels.original', { ns: 'common' }) : t('labels.analysisMode', { context: analysisMode.toLowerCase(), ns: 'analysis' })}
        />
      </div>
    </TreeHeader>
  )
}
