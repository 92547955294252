import React, { useContext } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPage } from '@fortawesome/pro-regular-svg-icons'

import { ELifecycleTableColumn } from '../../enum/table-column'
import { ILifecycleFlatLeafItem } from '../../interface/transformed-lifecycle-leaf-item'

import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { NotCompatibleImpactIcon } from '../../../shared/component/product/not-compatible-impact-icon'
import { ImpactBarComponent } from '../../../product/component/impact-bar.component'
import { isValid } from '../../../shared/util/tools'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { normalize } from '../../../../utils/tools'
import { ImpactType } from '../../../shared/enum/impact'
import { DecimalViewType } from '../../../shared/enum'
import { Status } from '../../../shared/enum/status'

const ColumnWrapper = styled.div`
  line-height: 1.25rem;
  padding: 0.75rem 1.25rem;

  &.col-impact-change {
    padding: 0.75rem 0.5rem;
  }

  &.col-primary-impactBar {
    padding-left: 0.25rem;
  }
`

type TableBodyTemplateComponentProps = {
  onRowClick?: Function,
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: ELifecycleTableColumn,
  value: ILifecycleFlatLeafItem
}

export const TableBodyTemplateComponent = ({
  onRowClick = () => {},
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = ELifecycleTableColumn.Item,
  value
}: TableBodyTemplateComponentProps) => {
  const { lifecycleTotalImpact, getMaxImpactAmount = () => {} } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const maxImpactAmount = getMaxImpactAmount()
  const totalImpactRatio = value?.impactAmount ? parseFloat(value?.impactAmount) / maxImpactAmount : 0
  const impactPercent = `${(Math.abs(totalImpactRatio) * 100).toFixed(2)}%`

  const ratioStatus = value?.impactRatioRelativeToMax && value?.impactRatioRelativeToMax > 0 ? ImpactType.Positive : ImpactType.Negative
  const impactType = `${ratioStatus}BarDeep`

  const getTooltipContent = () => (
    <div className="flex-none flex align-items-center max-w-15rem min-w-5rem white-space-nowrap">
      <DecimalPointComponent value={value?.impactAmount} decimalViewType={DecimalViewType.NumericValue} />
      { lifecycleTotalImpact?.unit }
    </div>
  )

  const getBodyTemplate = () => ({
    [ELifecycleTableColumn.Id]: (
      <div data-testid="table-body-template-id" className="flex align-items-center w-full border-round-left-lg pl-4 pr-0">
        <div className="flex-none flex align-items-center justify-content-center w-1rem h-1rem">
          <FontAwesomeIcon data-testid="table-body-template-icon" icon={faPage} className="text-base" />
        </div>
      </div>
    ),
    [ELifecycleTableColumn.PhaseName]: (
      <ColumnWrapper data-testid="table-body-template-phaseName" data-cy={`row-${normalize(value?.name)}-name`} className="flex align-items-center w-full border-round-left-lg">
        <div className="flex flex-grow-1 align-items-center text-sm line-height-2">
          { value?.phaseName }
        </div>
      </ColumnWrapper>
    ),
    [ELifecycleTableColumn.Item]: (
      <ColumnWrapper data-testid="table-body-template-item" data-cy={`row-${normalize(value?.name)}-name`} className="flex align-items-center w-full border-round-left-lg">
        <div className="flex flex-grow-1 align-items-center text-sm line-height-2">
          { value?.name }
        </div>
      </ColumnWrapper>
    ),
    [ELifecycleTableColumn.Amount]: (
      <ColumnWrapper data-testid="table-body-template-amount" data-cy={`row-${normalize(value?.name)}-amount`} className="flex align-items-center justify-content-start w-full text-sm">
        <DecimalPointComponent value={value?.amount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
      </ColumnWrapper>
    ),
    [ELifecycleTableColumn.Unit]: (
      <ColumnWrapper data-testid="table-body-template-unit" className="flex align-items-center justify-content-start w-full text-sm ">
        { value?.unit }
      </ColumnWrapper>
    ),
    [ELifecycleTableColumn.Impact]: (
      <ColumnWrapper data-testid="table-body-template-impact" data-cy={`row-${normalize(value?.name)}-impact`} className="flex align-items-center justify-content-end w-full text-sm white-space-nowrap">
        { value?.impactStatus === Status.NotCompatible ? (
          <NotCompatibleImpactIcon />
        ) : (
          <>
            <DecimalPointComponent value={value?.impactAmount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
            { lifecycleTotalImpact?.unit }
          </>
        )}
      </ColumnWrapper>
    ),
    [ELifecycleTableColumn.ImpactBar]: (
      <ColumnWrapper data-testid="table-body-template-impactBar" className="flex align-items-center justify-content-start w-full text-sm border-round-right-lg">
        { isValid(value?.impactRatioRelativeToMax) && <ImpactBarComponent nodeKey={value.id} impactPercent={impactPercent} impactType={impactType} getTooltipContent={getTooltipContent} />}
      </ColumnWrapper>
    )
  })

  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0 cursor-pointer"
      onMouseEnter={() => onRowMouseEnter(value.id)}
      onMouseLeave={() => onRowMouseLeave()}
      onClick={() => onRowClick(value.id, value.rowId)}
    >
      { getBodyTemplate()[column] }
    </div>
  )
}
