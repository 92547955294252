import React from 'react'
import * as Sentry from '@sentry/react'
import ErrorHandler from './errorHandler'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    }, () => Sentry.captureException(error))
  }

  render() {

    if (this.state.error) {
      return (
        <ErrorHandler />
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary

