import React, { MouseEvent, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Row, Col, Button, Tooltip, Modal, InputNumber, Form } from 'antd'
import { ScientificNotation } from '../scientific-notation/scientificNotation'
import { normalize, returnNested } from '../../../../utils/tools'
import { IMPACT_CALCULATION_STATUS } from '../../../../utils/const'
import compose from '../../../../utils/compose'
import FixedImpactBar from './fixedImpactBar'
import { SyncOutlined } from '@ant-design/icons'
import { AiOutlineCheck } from 'react-icons/ai'
import { IInventoryTreeItemProps } from './inventoryTreeItem.interface'
import InventoryTreeItemActions from './inventoryTreeItemActions.container'
import { useRefOverflow } from '../../../helpers/hooks/refOverflow'
import { withEditableProduct } from '../../with-editable-product/withEditableProduct'

const InventoryTreeItem = ({
  name,
  impact,
  unit,
  hasImpactSource,
  t,
  formRef,
  amount,
  maxImpactAmount,
  nodeKey,
  isReadOnly,
  areImpactsBarsShown,
  handleAmountChangeEvent,
  resetChangeAmountForm,
  isRoot,
  amountUpdated = false,
  selectedInventoryItemForAddReferenceSaga,
  selectedInventoryItemForChangeAmountSaga,
  hasInventory,
  phase,
  productId,
  amountModalPreference,
  setAmountModalPreferenceAction
}: IInventoryTreeItemProps) => {
  const dataCyTitle = 'item-named-' + normalize(name)
  const unitContainerWidth = areImpactsBarsShown ? '114px' : '100px'
  const { amount: impactAmount, status } = impact || {}
  const [ nameRef, hasNameOverflow ] = useRefOverflow()

  useEffect(() => {
    if (amountUpdated) {
      setAmountModalPreferenceAction({ ...amountModalPreference, visible: false })
    }
  }, [ amountUpdated ])

  const openAddReferenceModalHandler = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    selectedInventoryItemForAddReferenceSaga({ key: nodeKey, phase })
  }

  const openUpdateInventoryAmount = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    selectedInventoryItemForChangeAmountSaga({ key: nodeKey, phase } )
    setAmountModalPreferenceAction({ visible: true, key: nodeKey, top: event.clientY - 100, left: event.clientX })
    resetChangeAmountForm()
  }

  const saveUpdatedInventoryAmount = (e:any) => {
    if (e) e.stopPropagation()
    handleAmountChangeEvent()
  }

  const cancelUpdateInventoryAmount = () => {
    formRef.current.resetFields()
    setAmountModalPreferenceAction({ ...amountModalPreference, visible: false })
  }

  const isAmountEditable = () => {
    return (!isReadOnly && !isRoot) || (!isReadOnly && phase)
  }

  const isAmountModalVisible = () => {
    return amountModalPreference.visible && (amountModalPreference.key === nodeKey)
  }

  return (
    <Row data-cy={dataCyTitle} className="inventory-item__container">
      <Col flex="0 0 1" data-cy={`${dataCyTitle}-amount`} className="inventory-item__amount">
        { name &&
            <React.Fragment>
              <ScientificNotation
                dataCy={`${dataCyTitle}-value`}
                value={ amount }
                className={classnames('', {
                  'disabled': !isAmountEditable(),
                  'inventory-item__value': isAmountEditable(),
                  'inventory-item__value--opened': isAmountModalVisible()
                })}
                unit={ unit || t('global.unit') }
                onValueClick={ isAmountEditable() ? openUpdateInventoryAmount : null }/>

              {isAmountModalVisible() && <Modal
                mask={false}
                footer={null}
                closable={false}
                className="inventory-item__amountModal"
                style={{ top: amountModalPreference.top, left: amountModalPreference.left }}
                visible={ amountModalPreference.visible }
                onCancel={ () => cancelUpdateInventoryAmount() }
                destroyOnClose={true}
              >
                <Form
                  ref={formRef}
                  labelCol={{ span: 24 }}
                  initialValues={{ amount }}
                  onClick={evt => evt.stopPropagation()}
                >
                  <Form.Item
                    name='amount'
                    label={t('model.enter_amount')}
                    className='inventory-item__amountInputItem'
                    rules= {[
                      {
                        validator: (_: any, value: any) => {
                          if (value > 0) {
                            return Promise.resolve()
                          } else {
                            return Promise.reject(t('validation.positive_num_required'))
                          }
                        }
                      }
                    ]}>
                    <InputNumber
                      autoFocus={true}
                      className='inventory-item__amountInput'
                      onClick={(e:any) => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                      onPressEnter={saveUpdatedInventoryAmount} />
                  </Form.Item>

                  <Form.Item className='inventory-item__amountButtonItem'>
                    <Button
                      className='button primary inventory-item__amountButton'
                      onClick={(e:any) => saveUpdatedInventoryAmount(e)} >
                      <AiOutlineCheck />
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>}

              { t('global.of') }
            </React.Fragment>
        }
      </Col>

      <Tooltip title={hasNameOverflow ? name : null}>
        <Col ref={nameRef} flex="1 0 0" data-cy={ `${dataCyTitle}-name` } className="inventory-item__title">
          { name }
        </Col>
      </Tooltip>

      { !isReadOnly &&
        <Col flex="70px 0 0" data-cy={ `${dataCyTitle}-actions` } className="inventory-item__actions">
          <InventoryTreeItemActions {...{ nodeKey, isReadOnly, isRoot, name, productId }}/>
        </Col>
      }

      <Col flex={`${unitContainerWidth} 0 0`} data-cy={`item-impact-for-${normalize(name)}`} className="inventory-item__impact">
        {isReadOnly || hasInventory || hasImpactSource || isRoot ?
          <>
            { status === IMPACT_CALCULATION_STATUS.PENDING && <SyncOutlined className='impact-pending' spin /> }
            <ScientificNotation
              status
              className='text-right'
              value={ impactAmount }
            />
          </>
          :
          <Button
            type="link"
            onClick={ openAddReferenceModalHandler }
            className="inventory-item__addImpact"
            data-cy="add-reference-button"
          >{ areImpactsBarsShown ? t('model.addImpact') : t('global.add')}</Button>
        }
      </Col>

      {
        areImpactsBarsShown &&
          <Col flex="110px 0 0" className="bars">
            <FixedImpactBar impactAmount={ returnNested(impact, 'amount') } maxImpactAmount={ maxImpactAmount } name={ name }/>
          </Col>
      }
    </Row>
  )
}

export { InventoryTreeItem }
export default compose(
  withTranslation(),
  withEditableProduct
)(InventoryTreeItem)
