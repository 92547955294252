export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ViewType {
  Tabular = 'Tabular',
  Grid = 'Grid'
}

export enum ListType {
  Product = 'Product',
  Object = 'Object',
  Lifecycle = 'Lifecycle',
  Folder = 'Folder'
}
