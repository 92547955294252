import { connect } from 'react-redux'
import GlobalSelector from '../../../../../redux/selectors/global.selector'
import TransportationLegs from './transportationLegs'

const mapStateToProps = state => ({
  vehicleList: GlobalSelector.vehicleList(state)
})

export default connect(
  mapStateToProps,
  null
)(TransportationLegs)
