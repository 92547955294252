import { useReactiveVar } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { InventoryViewType } from '../../product/enum/inventory-view-type'

import { HeaderComponent } from '../component/header.component'

import { TLifecycleDetailContext } from '../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../lifecycle/provider/context/lifecycle-detail.context'
import { SplitScreenLayout } from '../../shared/layout/split-screen.layout'
import { TaskbarComponent } from '../component/lifecycle/taskbar.component'
import { ModelPanelComponent } from '../component/lifecycle/model/model-panel.component'
import { ComparisonEntity } from '../enum/entity'
import { store } from '../../../configureStore'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { FixedComponent } from '../component/fixed.component'
import { LifecycleDetailDialogComponent } from '../../lifecycle/component/dialog/lifecycle-detail-dialog.component'
import { InsightComponent } from '../component/lifecycle/insight.component'
import { UpdateProductPropertyContainer } from '../../shared/container/update-product-property.container'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { ILifecycle, IProduct } from '../../model'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { Navigation } from '../../shared/enum'
import { EntityType } from '../../shared/enum/entity-type'
import { AnalysisType } from '../enum/analysis'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'

const LifecycleWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;

  .body-wrapper {
    min-height: 28rem;
  }
`
export const LifecycleAnalysisLayout = () => {
  const navigate = useNavigate()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { t } = useTranslation([ 'product' ])
  const {
    lifecycleDetail,
    selectedInventoryViewType,
    updateLifecycleDetail,
    showLifecycleDetailPanelOnly
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const {
    showUpdatePropertyDialog = false,
    showCreateComparisonDialog = false,
    updateDashboard
  } = useContext<IDashboardContext>(DashboardContext)

  const {
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext)
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleComparison = isFeatureEnabled(Feature.LegacyLifecycleComparisonScenario) || false

  const { id: accountId = null } = account || {}
  const { slug: workspaceSlug = null } = space || {}
  const { id: lifecycleId = null } = lifecycleDetail || {}

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  useEffect(() => {
    if (!isDetailsPanelOpen) {
      updateLifecycleDetail({ selectedPhase: null, showLifecycleDetailPanelOnly: false })
    }
  }, [ isDetailsPanelOpen ])

  useEffect(() => {
    lifecycleDetail && updateDashboard({ collapsedNavigation: true })
  }, [ lifecycleDetail ])

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model
  const [ trackEventInSegment ] = getSegmentTrack()

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    if (!accountId || !workspaceSlug || !lifecycleId) return

    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    if (!enableLegacyLifecycleComparison) {
      const pathname = generatePath(Navigation.LifeCycleComparison, { accountId, workspaceSlug, lifecycleId })
      navigate(`${pathname}?activeTabKey=${InventoryViewType.Model}&type=${comparisonEntity.__typename}&id=${comparisonEntity.id}`)
      updateAnalysis({ secondaryEntityId: comparisonEntity.id })
    } else {
      const activeTabKey = comparisonEntity.__typename === EntityType.Product ? 'tab-flat-view' : 'tab-tree-view'
      navigate(`/account/${accountId}/workspace/${workspaceSlug}/lifecycle/${lifecycleId}/inventory/?`
        + `activeTabKey=${activeTabKey}&compareEntityType=${comparisonEntity.__typename}&compareEntityId=${comparisonEntity.id}`)
    }
  }

  return (
    <LifecycleWrapper
      className="relative w-full h-screen flex-column product-detail-wrapper"
    >
      { lifecycleDetail && (
        <div
          data-testid="analysis-detail-wrapper"
          className="absolute top-0 left-0 flex w-full h-full flex-column"
        >
          <div data-testid="analysis-detail-header" className="flex flex-column w-full h-8rem mb-3 px-6 py-2">
            <HeaderComponent />
          </div>
          <div data-testid="analysis-detail-taskbar" className="flex w-full h-3rem px-6 py-2">
            <TaskbarComponent />
          </div>

          <div data-testid="analysis-detail-body" className="flex w-full px-6 pt-3 flex-column body-wrapper">
            { isModelViewType() && (
              <div className="flex flex-column w-full border-round-lg bg-white">
                <div data-testid="detail-body-header" className="flex-none flex p-5 w-full text-gray-700 text-2xl">
                  { t('labels.headerModal') }
                </div>
                <div className="w-full flex-grow-1 p-5 pt-0 model-layout-body">
                  <SplitScreenLayout className="flex-wrap" firstClassName="w-full lg:w-6 lg:pr-3" secondClassName="w-full pt-3 lg:w-6 lg:pl-3 lg:pt-0">
                    <ModelPanelComponent />
                    <ModelPanelComponent entity={ComparisonEntity.Secondary} />
                  </SplitScreenLayout>
                </div>
              </div>
            ) }
            { !isModelViewType() && <InsightComponent /> }
          </div>
        </div>
      ) }

      { showLifecycleDetailPanelOnly && <LifecycleDetailDialogComponent reload={() => {}} /> }

      { showUpdatePropertyDialog && <UpdateProductPropertyContainer onUpdateCallback={updateLifecycleDetail} /> }

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} analysisType={AnalysisType.LifeCycleToLifeCycle} /> }

      { isModelViewType() && <FixedComponent /> }
    </LifecycleWrapper>
  )
}
