import { isEmpty, returnNested } from '../../utils/tools'
export const referencePropertiesListSelector = state => returnNested(state, 'global', 'referencePropertiesList')

export const referencePropertyByIdSelector = (state, referencePropertyId) => {
  const referencePropertiesList = referencePropertiesListSelector(state)
  return !isEmpty(referencePropertiesList)
    && referencePropertiesList.find(referenceProperty => referenceProperty.id === referencePropertyId)
}

const root = state => returnNested(state, 'global', 'selectedProduct'),
  productId = state => returnNested(root(state), 'id'),
  productName = state => returnNested(root(state), 'name'),
  customImpacts = state => returnNested(root(state), 'customImpacts'),
  scenarios = state => returnNested(root(state), 'scenarios')

export const SelectedProductSelector = {
  product: root,
  productId,
  productName,
  customImpacts,
  scenarios
}
