import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'

import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import ProductContext from '../provider/context/product.context'
import { TProductContext } from '../interface/product-context.type'
import { OverviewListLayout } from '../../shared/layout/overview-list.layout'
import { IProduct } from '../../model'
import { TListItem } from '../../shared/interface/list-item'
import { OverviewCardTemplateLayout, OverviewCardTemplateType } from '../../shared/layout/overview-card-template.component'
import { OverviewCreateComponent } from '../../shared/component/overview/overview-create.component'
import { EntityType } from '../../../utils/const'
import { ViewTemplateFolderComponent } from '../component/view-template/view-template-folder.component'
import { ProductLabel } from '../../shared/enum'
import { ViewTemplateProductComponent } from '../component/view-template/view-template-product.component'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { PagePaginatorComponent } from '../../shared/component/general/page-paginator.component'
import { setOverviewSetting } from '../../shared/util/overview'
import { SortingOrder, ProductSortField } from '../../../__generated__/graphql'

export const CardViewLayout = () => {
  const { t } = useTranslation([ 'common' ])
  const menu = useRef<Menu>(null)
  const {
    label = ProductLabel.Product,
    totalItems = 0,
    emptyFilterResult,
    currentPage = 1,
    loadingProductOverview,
    allProducts = [],
    favProducts = [],
    cardPageSize = 0,
    selectedSortBy = ProductSortField.Name,
    selectedSortOrder = SortingOrder.Asc,
    updateProduct
  } = useContext<TProductContext>(ProductContext)
  const { updateDashboard } = useContext(DashboardContext)
  const [ firstProduct, setFirstProduct ] = useState(0)

  useEffect(() => {
    if (currentPage === 1) {
      setFirstProduct(0)
    }
  }, [ currentPage ])

  const hasMore = () => totalItems > cardPageSize

  const openCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: true })
  }

  const getProductListItem = (items: IProduct[] = [], fav: boolean = false): TListItem[] => {
    if (items.length === 0 && !fav) {
      return [ {
        key: 0,
        itemComponent: (
          <OverviewCardTemplateLayout clickHandler={openCreateProductDialog} type={OverviewCardTemplateType.Create}>
            <OverviewCreateComponent createLabel={t('actions.createFirst', { context: label })} />
          </OverviewCardTemplateLayout>
        )
      } ]
    }

    return items.map(({
      id, name, tags, favorite, updatedAt, description, __typename
    }: any) => {
      const itemAttr = {
        id, name, tags, favorite, updatedAt, description, label
      }
      if (__typename === EntityType.FOLDER) {
        return {
          key: id,
          itemComponent: <ViewTemplateFolderComponent {...{ id, name, label }} />
        }
      }
      return {
        key: id,
        itemComponent: <ViewTemplateProductComponent {...{ ...itemAttr, prefix: fav ? 'fav' : 'all' }} />
      }
    })
  }

  const onCustomPageChange = (event: any) => {
    setFirstProduct(event.first)
    updateProduct({ currentPage: event.page + 1 })
  }

  const onSortByClick = (event: any) => {
    const { item: { value: sortByTo } } = event
    const sortByUpdated = { selectedSortBy: sortByTo }
    updateProduct(sortByUpdated)
    setOverviewSetting(sortByUpdated)
  }

  const onSortOrderClick = () => {
    const sortOrderUpdated = {
      selectedSortOrder: selectedSortOrder === SortingOrder.Asc ? SortingOrder.Desc : SortingOrder.Asc
    }
    updateProduct(sortOrderUpdated)
    setOverviewSetting(sortOrderUpdated)
  }

  const sortLabels = {
    [ProductSortField.Name]: t('labels.tableHeader', { context: `${label}Name` }),
    [ProductSortField.Updated]: t('labels.tableHeader', { context: 'updated' }),
    [ProductSortField.Created]: t('labels.tableHeader', { context: 'created' })
  }
  const sortMenuItems = Object.values(ProductSortField).map((item: ProductSortField) => ({
    label: sortLabels[item],
    value: item,
    command: onSortByClick
  }))

  const sortOrderIcon = selectedSortOrder === SortingOrder.Asc
    ? <FontAwesomeIcon className="text-sm" icon={faArrowUp} />
    : <FontAwesomeIcon className="text-sm" icon={faArrowDown} />

  return (
    <>
      { loadingProductOverview ? (
        <ProgressSpinnerComponent dataTestId="loading-products" size={2} />
      ) : (
        <>
          { favProducts.length > 0 && (
            <>
              <div data-testid="card-view-favTitle" className="w-full px-3">
                { t('labels.favorites') }
              </div>
              <div data-testid="card-view-favCards" className="flex flex-wrap w-full px-2" data-cy="favorite-list">
                <OverviewListLayout items={getProductListItem(favProducts, true)} />
              </div>
            </>
          )}
          { !emptyFilterResult ? (
            <>
              <div className="flex flex-none w-full px-3 mt-3">
                <div data-testid="card-view-allTitle" className="flex flex-grow-1">{ t('labels.all', { context: label }) }</div>
                <div data-testid="card-view-sortWrapper" className="flex w-10rem justify-content-end">
                  <Menu model={sortMenuItems} popup ref={menu} />
                  <Button
                    data-testid="card-view-sortByButton"
                    label={sortLabels[selectedSortBy]}
                    className="p-button-outlined p-button-plain text-sm border-none p-0 mr-1"
                    onClick={(event: any) => menu?.current?.toggle(event)}
                  />
                  <Button
                    data-testid="card-view-sortOrderButton"
                    onClick={onSortOrderClick}
                    icon={sortOrderIcon}
                    className="p-button-outlined p-button-plain p-button-sm p-0 border-none "
                  />
                </div>
              </div>
              <div data-testid="card-view-allCards" className="flex flex-wrap w-full px-2" data-cy="product-list">
                <OverviewListLayout items={getProductListItem(allProducts)} />
              </div>
              { hasMore() && <PagePaginatorComponent first={firstProduct} pageSize={cardPageSize} totalItems={totalItems} onPageChange={onCustomPageChange} />}
            </>
          ) : (
            <div data-testid="card-view-emptyFilter" className="flex flex-column text-center text-gray-200 text-lg w-full py-8">
              <span>{ t('labels.emptyFilterResult') }</span>
            </div>
          )}
        </>
      )}
    </>
  )
}
