import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Column } from 'primereact/column'
import { Messages } from 'primereact/messages'

import { DataTable } from 'primereact/datatable'
import { useTranslation } from 'react-i18next'
import { isValid } from '../../../util/tools'

const ImportReportWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--gray-200);
  border-radius: 10px;
  padding: 0.5rem;

  .p-datatable-wrapper {
    margin: 1rem 0.5rem;
    border: 1px solid var(--gray-200);
    border-radius: 0.75rem;

    .p-datatable-thead {
      display: none;
    }

    .p-datatable-tbody > tr > td {
      vertical-align: top;
    }
    .p-datatable-tbody > tr:not(:first-child) > td {
      border: 1px solid var(--gray-200);
      border-width: 1px 0 0 0;
    }
  }

  .p-message {
    margin: 0;
  }
`

type ImportReportComponentProps = {
  successImportReport?: any,
  failedImportReport?: any
}

export const ImportReportComponent = ({
  successImportReport,
  failedImportReport
}: ImportReportComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const messages = useRef<Messages>(null)

  useEffect(() => {
    messages.current?.clear()
    if (failedImportReport) {
      messages.current?.show({ severity: 'warn', sticky: true, summary: errorMessage(failedImportReport.fileName) })
    } else if (successImportReport) {
      messages.current?.show({ severity: 'success', sticky: true, summary: successMessage(successImportReport.fileName) })
    }
  }, [ successImportReport, failedImportReport ])

  const errorMessage = (fileName: string) => (
    <div className="w-full px-3">
      <div className="w-full text-base font-semibold">{ t('messages.warning', { context: 'failedImportFile' }) }</div>
      <div data-testid="failed-message-file" className="w-full text-sm">{ fileName }</div>
    </div>
  )

  const successMessage = (fileName: string) => (
    <div className="w-full px-3">
      <div className="w-full text-base font-semibold">{ t('messages.success', { context: 'importFile' }) }</div>
      <div data-testid="success-message-file" className="w-full text-sm">{ fileName }</div>
    </div>
  )

  const errorColumnTemplate = (rowData: any) => {
    const { extensions } = rowData
    const errorMessageArgs = extensions?.args ? extensions?.args : {}

    if (!isValid(rowData?.row)) {
      return <span data-testid="failed-import-message-only" className="text-xs">{rowData.msg}</span>
    }

    return (
      <>
        <span className="font-semibold text-xs">
          {t('labels.row')}
          {' '}
          :
          {' '}
        </span>
        <span data-testid="failed-import-row" className="text-xs">{rowData.row}</span>
        <span className="font-semibold text-xs pl-3">
          {t('labels.column')}
          {' '}
          :
          {' '}
        </span>
        <span data-testid="failed-import-col" className="text-xs">{rowData.col}</span>
        <span data-testid="failed-import-message" className="text-xs pl-3">
          { extensions?.code ? t('messages.error', { context: extensions?.code, ...errorMessageArgs }) : rowData.msg }
        </span>
      </>
    )
  }

  const successColumnTemplate = (rowData: any) => (
    <>
      { rowData.label === 'created' && (
        <span data-testid="success-import-created" className="font-semibold text-xs">
          {t('labels.created')}
          {' '}
          :
          {' '}
          { rowData.value }
        </span>
      ) }
      { rowData.label === 'updated' && (
        <span data-testid="success-import-updated" className="font-semibold text-xs">
          {t('labels.updated')}
          {' '}
          :
          {' '}
          { rowData.value }
        </span>
      ) }
    </>
  )

  return (
    <ImportReportWrapper>
      <div className="w-full p-2">
        <Messages ref={messages} />
      </div>

      { failedImportReport && (
        <>
          <DataTable data-testid="failed-import-report" value={failedImportReport.rows} responsiveLayout="scroll">
            <Column body={errorColumnTemplate} />
          </DataTable>
        </>
      )}

      { successImportReport && (
        <>
          <DataTable
            data-testid="success-import-report"
            value={[
              { label: 'created', value: successImportReport.created },
              { label: 'updated', value: successImportReport.updated }
            ]}
            responsiveLayout="scroll"
          >
            <Column body={successColumnTemplate} />
          </DataTable>
        </>
      )}
    </ImportReportWrapper>
  )
}
