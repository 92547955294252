import { connect } from 'react-redux'
import {
  setIsCreateLifecycleAction,
  setIsCreateNewInventoryItemAction,
  setIsCreatePhaseAction
} from '../../../../redux/actions/flags.actions'
import { setSelectedPhaseAction } from '../../../../redux/actions/lifecycle.actions'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { removePhaseSaga } from '../../../../redux/sagas/lifecycle.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'
import { returnNested, safeArray } from '../../../../utils/tools'
import PhaseDetails from './phaseDetails'

// Returns true if the selected phase is from selected lifecycle (left side) or is production
const getIsDetailsPanelReadOnly = state => {
  const lifecyclePhases = safeArray(LifecycleSelector.phases(state))
  const selectedPhase = LifecycleSelector.selectedPhase(state)
  const isShowLifecycleScenarioInventory = FlagsSelector.isShowLifecycleScenarioInventory(state)
  const isLifecyclePhase = lifecyclePhases.find(phase => phase.id === returnNested(selectedPhase, 'id')) !== undefined
  const isScenarioReadOnly = isShowLifecycleScenarioInventory && isLifecyclePhase
  const isInventoryCompare = ComparisonSelector.isInventoryCompare(state)
  const isComparisonReadOnly = isInventoryCompare && !isLifecyclePhase
  return isScenarioReadOnly || isComparisonReadOnly
}

const mapStateToProps = (state, ownProps) => ({
  selectedPhase: LifecycleSelector.selectedPhase(state),
  selectedLifecycle: returnNested(ownProps, 'lifecycle'),
  isCreateNewInventoryItem: FlagsSelector.isCreateNewInventoryItem(state),
  isCreatePhase: FlagsSelector.isCreatePhase(state),
  isDetailsPanelReadOnly: getIsDetailsPanelReadOnly(state),

})

const mapDispatchToProps = {
  setSelectedPhase: setSelectedPhaseAction,
  setIsCreateNewInventoryItem: setIsCreateNewInventoryItemAction,
  clearUiWhenDetailPanelCloseAction: clearUiWhenDetailPanelCloseAction,
  removePhaseSaga,
  setIsCreatePhaseAction,
  setIsCreateLifecycleAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhaseDetails)
