import { Button, Card } from 'antd'
import React from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { SUBSCRIPTION_STATUS, TRACK_EVENT_TYPE } from '../../../../utils/const'
import { isEmpty, returnNested } from '../../../../utils/tools'
import { AccountSettingPageLocation, buildFullUrl } from '../../../shared/locations'

class PaymentMethod extends React.Component {
  handleUpdatePaymentMethod = () => {
    this.props.startCheckoutSetupSaga(
      this.props.selectedAccountID,
      buildFullUrl(AccountSettingPageLocation, {
        activeTabKey: 'billingManagement',
        stripe_success: true,
        stripe_message: 'billing.checkout_success'
      }),
      buildFullUrl(AccountSettingPageLocation, {
        activeTabKey: 'billingManagement',
        stripe_success: false,
        stripe_message: 'checkout_failure'
      })
    )
    this.props.trackEventSaga(TRACK_EVENT_TYPE.ADDED_PAYMENT)
  }
  render() {
    const { subscription, paymentMethod, t } = this.props

    if (isEmpty(paymentMethod)) return null
    return (
      <div className="payment-details">
        <Card type="inner"
          className="card-item card-title-big"
          title={t('billing.billing_details')}
          extra={(
            subscription.status !== SUBSCRIPTION_STATUS.CANCELED &&
          <Button onClick={this.handleUpdatePaymentMethod}>{t('billing.update_payment_method')}</Button>
          ) }
        >
          <div className="payment-method">
            <div data-cy="change-credit-card">
              {t('billing.card_number_title')} {t('billing.card_number', { lastFour: returnNested(paymentMethod, 'card', 'lastFour') })}
              <span className="expiration">{t('billing.expiring_on_title')} {t('billing.expiring_on', {
                expMonth: returnNested(paymentMethod, 'card', 'expMonth'),
                expYear: returnNested(paymentMethod, 'card', 'expYear')
              })}</span>

            </div>
          </div>
        </Card>
      </div>
    )
  }
}

export default compose(
  withTranslation()
)(PaymentMethod)
