import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Tooltip } from 'antd'

import { useRefOverflow } from '../../helpers/hooks/refOverflow'
import { ArrowLeftOutlined } from '@ant-design/icons'
import DashboardContext from '../../../v1/dashboard/context/dashboard.context'
import { useTranslation } from 'react-i18next'
import { useIsMounted } from '../../../v1/shared/hook/use-is-mounted'

export const NavigateBackV1 = () => {
  const { t } = useTranslation([ 'common', 'translation' ])
  const navigate = useNavigate()
  const location = useLocation()
  const isMounted = useIsMounted()
  const [ backRef, hasBackOverflow ] = useRefOverflow()
  const [ backTo, setBackTo ] = useState<string | undefined | null>(null)
  const { backToList = [], removeFromBackToList = () => {} } = useContext(DashboardContext)
  const { state = null } = location || {}
  const { from = null } = state || {}

  useEffect(() => {
    const backTo = backToList.length > 0 ? backToList[backToList.length - 1] : from
    isMounted() && setBackTo(backTo)
  }, [ backToList, from ])

  const handleNavigation = () => {
    navigate(-1)
    removeFromBackToList()
  }

  const backLabel = backTo ? t('labels.backTo', { backTo }) : t('labels.back')
  return (<Tooltip title={hasBackOverflow ? backLabel : null}>
    <Button
      type="default"
      data-cy="go-back-button"
      onClick={handleNavigation}
      className='button transparent back-navigation-button'>
      <ArrowLeftOutlined className="back-arrow" />
      <span ref={backRef} className="back-text"> { backLabel } </span>
    </Button>
  </Tooltip>)
}
