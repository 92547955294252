import { gql } from '@apollo/client'
import minimalInventory from './minimalInventory'

export default gql`
  fragment phaseFragment on Phase {
      id
      name
      type
      inventory{
          ...minimalInventoryFragment
      }
      activities {
        id
        spaceID
        phaseID
        template {
          id
          name
          description
        }
        products {
          inventoryID
          productID
        }
      }
  }
  ${minimalInventory}
`
