import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ModelFooterComponent } from './model-footer.component'

type TFixedSectionProps = {
  collapsedNavigation: boolean
}

const TreeFixedSection = styled.div<TFixedSectionProps>`
  width: ${(props: TFixedSectionProps) => (props.collapsedNavigation ? 'calc(100% - 5rem)' : 'calc(100% - 15.5rem)')};
  right: 0.5rem;
`

export const ModelFixedSectionComponent = () => {
  const { collapsedNavigation = false } = useContext<IDashboardContext>(DashboardContext)
  const {
    selectedInventoryViewType = InventoryViewType.Model
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ showFixedFooter, setShowFixedFooter ] = useState<boolean>(false)
  useEffect(() => {
    const modalBodyDOM = document.querySelector('.lifecycle-detail-wrapper')

    const updateHeaderFooterStyle = () => {
      if (modalBodyDOM) {
        const newFixedFooterValue = modalBodyDOM.scrollHeight > modalBodyDOM.clientHeight
        isModelViewType() && setShowFixedFooter(newFixedFooterValue)
      }
    }
    updateHeaderFooterStyle()

    if (modalBodyDOM) {
      modalBodyDOM.addEventListener('scroll', updateHeaderFooterStyle)
      window.addEventListener('resize', updateHeaderFooterStyle)
    }

    return () => {
      setShowFixedFooter(false)
      window.removeEventListener('scroll', updateHeaderFooterStyle)
      window.removeEventListener('resize', updateHeaderFooterStyle)
    }
  })

  const isModelViewType = () => selectedInventoryViewType === InventoryViewType.Model

  return (
    <>
      { isModelViewType() && showFixedFooter
      && (
        <TreeFixedSection
          data-testid="footer-fixed-section"
          className="fixed bottom-0 h-4rem px-6"
          collapsedNavigation={collapsedNavigation}
          data-html2canvas-ignore
        >
          <div className="flex w-full h-full py-3 pl-5 pr-7 bg-white border-top-1 border-gray-100">
            <ModelFooterComponent classNamePrefix="ap-tree-footer-fixed" />
          </div>
        </TreeFixedSection>
      ) }
    </>
  )
}
