import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { REMOVE_PHASE } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../../shared/util/error'
import { DeletePhaseComponent } from '../component/delete-phase.component'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

type TDeletePhaseContainerProps = {
  afterDeletePhase: () => void
}

export const DeletePhaseContainer = ({
  afterDeletePhase
}: TDeletePhaseContainerProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const toast = useRef<Toast>(null)
  const {
    showDeletePhaseDialog = false,
    selectedPhase,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const [
    removePhase,
    {
      error: failedRemoving,
      data: removedPhaseData,
      loading: removingPhase
    }
  ] = useMutation(REMOVE_PHASE)

  useEffect(() => {
    if (!removingPhase && (removedPhaseData?.removePhase || failedRemoving)) removePhaseCallback()
  }, [ removingPhase, removedPhaseData, failedRemoving ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const closeDeletePhaseDialog = () => {
    updateLifecycleDetail({ showDeletePhaseDialog: false, selectedPhase: null })
  }

  const handleDeletePhase = () => {
    selectedPhase?.id && removePhase({ variables: { id: selectedPhase?.id } })
  }

  const removePhaseCallback = () => {
    try {
      if (failedRemoving) {
        throw failedRemoving
      } else if (removedPhaseData?.removePhase) {
        trackEventInSegment(TrackEventType.DELETED_PHASE)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.removedPhase', { context: 'success', ns: 'lifecycle' }),
          life: 200
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.removedPhase', { context: 'error', ns: 'lifecycle' }), error?.graphQLErrors, t)
    }
  }

  return (
    <>
      <Toast data-testid="delete-phase-status" ref={toast} position="top-right" onHide={afterDeletePhase} />

      <DeletePhaseComponent
        handleDeletePhase={handleDeletePhase}
        closeDeletePhaseDialog={closeDeletePhaseDialog}
        showDeletePhaseDialog={showDeletePhaseDialog}
      />

    </>
  )
}
