import React from 'react'
import classnames from 'classnames'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import CropLongText from '../cropLongText.container'
class SingleFieldText extends React.PureComponent {

  render() {
    const {
      disabled,
      displayedText,
      dataCy,
      editHandler
    } = this.props

    return (
      <Row type="flex" align="middle" className={classnames('field-text', { disabled })}>
        <Col span={ 22 }>
          <CropLongText
            tooltipText={displayedText}
            className="displayed-value"
            dataCy={`displayed-value-${dataCy}`} >
            {displayedText || <span className="insert-value-text">-</span>}
          </CropLongText>
        </Col>

        <Col span={ 2 } className="actions text-right">
          {editHandler ?
            <Button
              data-cy={`edit-${dataCy}`}
              onClick={editHandler}
              hidden={ disabled }
              className={classnames('button transparent icon edit-field', { disabled })}
              type="button"
            >
              <EditOutlined />
            </Button>
            : null
          }
        </Col>

      </Row>
    )
  }
}

export default SingleFieldText
