import { connect } from 'react-redux'
import { withRouter } from '../../../../utils/with-router'
import {
  setComparisonEntitiesAction,
  setComparisonFlatViewAction,
  setEntityToCompareAction,
  setIsInventoryCompareAction,
} from '../../../../redux/actions/comparison.actions'
import {
  setIsCreateLifecycleAction,
  setIsCreatePhaseAction,
  setIsSkipPhasesAction,
  setIsForcingWorkspacePanelAction,
  setIsOrderingPhasesAction,
  setIsShowLifecycleScenarioInventoryAction,
  setIsDetailsPanelOpenAction
} from '../../../../redux/actions/flags.actions'

import {
  setFlatViewAction,
  setLifecycleScenarioAction,
  setSelectedLifecycleAction,
  setSelectedPhaseAction
} from '../../../../redux/actions/lifecycle.actions'
import {
  setInventoryLifecyclesAction
} from '../../../../redux/actions/inventoryTree.actions'
import {
  setSelectedProductAction,
  setSelectedInventoryItemKeyAction
} from '../../../../redux/actions/global.actions'
import {
  getLifecycleByIdSaga,
  loadLifecycleItemSaga,
  updateLifecyclesItemsSaga
} from '../../../../redux/sagas/lifecycle.saga'
import { getSpaceBySlugWithProductsSaga } from '../../../../redux/sagas/space.saga'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'
import AccountSelector from '../../../../redux/selectors/account.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import SpaceSelector from '../../../../redux/selectors/space.selector'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'

import { returnNested } from '../../../../utils/tools'
import { LifecycleItemLocation } from '../../../shared/locations'
import LifecycleItem from './lifecycleItem'

const mapStateToProps = (state, ownProps) => {
  let params = LifecycleItemLocation.parseLocationParams(ownProps.location)
  return ({
    lifecycleId: returnNested(params, 'lifecycleId'),
    activeTabKey: returnNested(params, 'activeTabKey'),
    workspaceSlug: returnNested(params, 'workspaceSlug'),
    lifecycleScenarioId: returnNested(params, 'lifecycleScenarioId'),
    compareEntityId: returnNested(params, 'compareEntityId'),
    compareEntityType: returnNested(params, 'compareEntityType'),
    selectedAccountId: AccountSelector.selectedAccountId(state),
    isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
    selectedLifecycleName: LifecycleSelector.selectedLifecycleName(state),
    selectedLifecycleAmount: LifecycleSelector.selectedLifecycleAmount(state),
    selectedLifecycleUnit: LifecycleSelector.selectedLifecycleUnit(state),
    impactCategory: SpaceSelector.impactCategory(state),
    impactCategoryId: SpaceSelector.impactCategoryId(state),
    lifecycleProductName: LifecycleSelector.productName(state),
    isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
    inventoryTotalImpactStatus: returnNested(LifecycleSelector.inventoryTotalImpact(state), 'status'),
    productMutator: ProductMutationSelector.productMutator(state),
    selectedProductId: SelectedProductSelector.productId(state),
    excludeLT: SpaceSelector.impactExcludeLT(state),
    useMethodTotal: SpaceSelector.impactUseMethodTotal(state),
    nwSetId: SpaceSelector.impactNwSet(state),
  })
}

const mapDispatchToProps = {
  loadLifecycleItemSaga,
  getLifecycleByIdSaga,
  getSpaceBySlugWithProductsSaga,
  setComparisonEntitiesAction,
  setComparisonFlatViewAction,
  setFlatViewAction,
  setEntityToCompareAction,
  setIsCreateLifecycleAction,
  setIsCreatePhaseAction,
  setIsInventoryCompareAction,
  setSelectedLifecycleAction,
  setSelectedPhaseAction,
  updateLifecyclesItemsSaga,
  setIsSkipPhasesAction,
  setIsForcingWorkspacePanelAction,
  setIsOrderingPhasesAction,
  setInventoryLifecyclesAction,
  setLifecycleScenarioAction,
  setIsShowLifecycleScenarioInventoryAction,
  setSelectedProductAction,
  trackEventSaga,
  setIsDetailsPanelOpenAction,
  setSelectedInventoryItemKeyAction
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleItem))
