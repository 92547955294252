import React, { useContext } from 'react'
import { Avatar } from 'primereact/avatar'
import styled from 'styled-components'

import DashboardContext from '../context/dashboard.context'
import { NavigationItemAttr } from '../interface/navigation-items-props'

const MenuWrapper = styled.div`
  height: 3rem;
`

const MenuDetail = styled.div`
  width: calc(100% - 3rem);
`

export const AccountItemComponent = ({ label, data, options }: NavigationItemAttr) => {
  const { collapsedNavigation } = useContext(DashboardContext)
  const { user, userAuth0 = {} } = data || {}
  const { metadata: { firstName = null, lastName = null } = {} } = userAuth0
  const username = firstName && lastName ? `${firstName} ${lastName}`.trim() : user?.name

  return (
    <MenuWrapper onClick={options.onClick} className="flex w-full p-2">
      <div data-testid="account-user-logo" className="flex-none h-full px-1">
        <Avatar image={userAuth0?.picture} className="surface-100 overflow-hidden w-2rem h-2rem" size="large" />
      </div>
      {!collapsedNavigation && (
        <>
          <MenuDetail className="flex-none ml-2 h-full">
            <div data-testid="current_account_label" data-cy="current_account_label" className="w-full text-sm line-height-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">{ label }</div>
            <div data-testid="user_menu_label" data-cy="user_menu_label" className="w-full text-xs text-gray-200 white-space-nowrap overflow-hidden text-overflow-ellipsis">{ username }</div>
          </MenuDetail>
        </>
      )}
    </MenuWrapper>
  )
}
