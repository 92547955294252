import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { ProductTemplate } from '../../../../__generated__/graphql'

type AddProductFromTemplateComponentProps = {
  productTemplateCreating?: boolean,
  activeTemplate: any,
  closeDialog: () => void,
  onTemplateAdding: (templateKey:ProductTemplate) => void
}
export const AddProductFromTemplateComponent = ({
  productTemplateCreating = false,
  activeTemplate,
  closeDialog,
  onTemplateAdding
}: AddProductFromTemplateComponentProps) => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const { key, name, industry } = activeTemplate || {}

  const generateInfoMessage = () => (
    <div className="flex">
      <div className="pr-3 pt-1">
        <FontAwesomeIcon icon={faCircleInfo} className="text-3xl" />
      </div>
      <div className="flex flex-column">
        <div className="font-bold">{t('labels.noteOfUse')}</div>
        <div className="font-normal pt-2">{t('labels.noteOfUse', { context: 'message' })}</div>
      </div>
    </div>
  )

  return (
    <Dialog
      data-testid="add-product-from-template"
      header={t('labels.productTemplateModal', { context: 'title' })}
      visible={!!activeTemplate}
      draggable={false}
      dismissableMask
      onHide={closeDialog}
      style={{ maxWidth: '58rem' }}
    >
      <div className="w-full pb-6">
        <div data-testid="add-product-from-template-message" className="col-12 p-0">
          <div className="pb-3">
            {t('labels.productTemplates', { context: 'description' })}
          </div>
          <div>
            <span className="font-bold">
              {t('labels.name', { ns: 'common' })}
              :
              {' '}
            </span>
            {t('labels.productTemplateName', { context: name })}
          </div>
          <div className="pb-3">
            <span className="font-bold">
              {t('labels.industry', { ns: 'common' })}
              :
              {' '}
            </span>
            {t('labels.industry', { context: industry, ns: 'common' })}
          </div>
          <div className="pb-3">
            <span className="font-bold">
              {t('labels.description', { ns: 'common' })}
              :
              {' '}
            </span>
            {t('labels.productTemplateDescription', { context: name })}
          </div>
        </div>
        <Message severity="info" content={generateInfoMessage()} />
        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={() => onTemplateAdding(key)}
            data-testid="add-product-from-template-button"
            label={t('actions.use', { ns: 'common' })}
            iconPos="left"
            loading={productTemplateCreating}
            className="p-button-info justify-content-end"
          />
        </div>
      </div>
    </Dialog>

  )
}
