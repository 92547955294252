import React, { useContext } from 'react'
import { Sidebar } from 'primereact/sidebar'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'

export const SidebarComponent = () => {
  const { closeSidebar = () => {}, openedSidebar, sidebarComponent: SidebarComponent = null } = useContext<IDashboardContext>(DashboardContext)

  return (
    <Sidebar
      className="w-21rem"
      data-testid="dashboard-sidebar-component"
      visible={openedSidebar}
      position="right"
      modal={false}
      showCloseIcon={false}
      onHide={() => closeSidebar()}
    >
      { SidebarComponent }
    </Sidebar>
  )
}
