import React from 'react'
import { AppProps } from '../../shared/interface/app-props'
import FeatureContext from '../context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { userAuth0Var } from '../../../graphql/cache'
import { getEnvFromUrl } from '../../shared/util/navigation'
import { Environment } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'

export const FeatureProvider = ({ children }: AppProps) => {
  const { appMetadata: { roles = [] } = {} } = userAuth0Var() || {}
  const currentEnv = getEnvFromUrl()
  const features: Feature[] = roles.filter((role: any) => Object.values(Feature).includes(role))

  const isEnvEnabled = (env?: Environment) => isValid(currentEnv) && isValid(env) && currentEnv === env

  const isFeatureEnabled = (feature?: Feature, env?: Environment) => {
    if (feature) {
      const featureExist = features.includes(feature)
      if (isValid(currentEnv) && isValid(env)) {
        return currentEnv === env && featureExist
      }
      return featureExist
    }

    return false
  }

  return (
    <FeatureContext.Provider value={{ features, isEnvEnabled, isFeatureEnabled }}>
      { children }
    </FeatureContext.Provider>
  )
}

