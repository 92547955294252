export const
  SET_IS_CREATE_LIFECYCLE   = 'SET_IS_CREATE_LIFECYCLE',
  SET_IS_CREATE_PHASE       = 'SET_IS_CREATE_PHASE',
  SET_IS_SKIP_PHASES        = 'SET_IS_SKIP_PHASES',
  SET_IS_FORCING_WORKSPACE_PANEL = 'SET_IS_FORCING_WORKSPACE_PANEL',
  SET_IS_ORDERING_PHASES    = 'SET_IS_ORDERING_PHASES',
  SET_IS_SIDEBAR_OPEN       = 'SET_IS_SIDEBAR_OPEN',
  SET_IS_DETAIL_PANEL_READONLY_V1 = 'SET_IS_DETAIL_PANEL_READONLY_V1',
  SET_IS_ADBLOCK_ENABLED    = 'SET_IS_ADBLOCK_ENABLED',
  SET_IS_SHOW_ADBLOCK_POPUP = 'SET_IS_SHOW_ADBLOCK_POPUP',
  SET_IS_SHOW_LIFECYCLE_SCENARIO_INVENTORY = 'SET_IS_SHOW_LIFECYCLE_SCENARIO_INVENTORY',
  SET_IS_CREATE_PRODUCT_DIALOG_SHOWED = 'SET_IS_CREATE_PRODUCT_DIALOG_SHOWED',
  SET_IS_CREATE_FOLDER_DIALOG_SHOWED = 'SET_IS_CREATE_FOLDER_DIALOG_SHOWED',
  SET_IS_CREATE_LIFECYCLE_DIALOG_SHOWED = 'SET_IS_CREATE_LIFECYCLE_DIALOG_SHOWED',
  SET_IS_IMPACT_SELECTOR_MODAL_SHOWED = 'SET_IS_IMPACT_SELECTOR_MODAL_SHOWED',
  SET_IS_MOVE_ITEM_DIALOG_SHOWED = 'SET_IS_MOVE_ITEM_DIALOG_SHOWED',
  SET_IS_LOADING_SETTING_COMPLETED = 'SET_IS_LOADING_SETTING_COMPLETED'

export const setIsDetailsPanelOpenAction = isDetailsPanelOpen => ({
  type: 'SET_IS_DETAILS_PANEL_OPEN',
  isDetailsPanelOpen: isDetailsPanelOpen
})

export const setIsCreateNewInventoryItemAction = status => ({
  type: 'SET_IS_CREATE_NEW_INVENTORY_ITEM',
  status
})

export const setIsShowInventoryAction = status => ({
  type: 'SET_IS_SHOW_INVENTORY',
  status
})

export const setIsDataViaNetworkRequesting = status => ({
  type: 'SET_IS_DATA_VIA_NETWORK_REQUESTING',
  status
})

export const setIsSkipPhasesAction = status => ({
  type: SET_IS_SKIP_PHASES,
  status
})

export const setIsShowProductScenarioInventoryAction = status => ({
  type: 'SET_IS_SHOW_PRODUCT_SCENARIO_INVENTORY',
  status
})
export const setIsShowLifecycleScenarioInventoryAction = status => ({
  type: SET_IS_SHOW_LIFECYCLE_SCENARIO_INVENTORY,
  status
})

export const setIsProductFiltersInSearchModeAction = status => ({
  type: 'SET_IS_PRODUCT_FILTERS_IN_SEARCH_MODE',
  status
})

export const setIsCreateLifecycleAction = status => ({
  type: SET_IS_CREATE_LIFECYCLE,
  status
})

export const setIsCreatePhaseAction = status => ({
  type: SET_IS_CREATE_PHASE,
  status
})

export const setIsForcingWorkspacePanelAction = status => ({
  type: SET_IS_FORCING_WORKSPACE_PANEL,
  status
})

export const setIsOrderingPhasesAction = status => ({
  type: SET_IS_ORDERING_PHASES,
  status
})

export const setIsSidebarOpenAction = status => ({
  type: SET_IS_SIDEBAR_OPEN,
  status
})
export const setIsDetailPanelReadonlyV1Action = status => ({
  type: SET_IS_DETAIL_PANEL_READONLY_V1,
  status
})

export const setIsAdBlockEnabledAction = status => ({
  type: SET_IS_ADBLOCK_ENABLED,
  status
})

export const setIsShowAdBlockPopupAction = status => ({
  type: SET_IS_SHOW_ADBLOCK_POPUP,
  status
})

export const setIsCreateProductDialogShowedAction = status => ({
  type: SET_IS_CREATE_PRODUCT_DIALOG_SHOWED,
  status
})

export const setIsCreateFolderDialogShowedAction = status => ({
  type: SET_IS_CREATE_FOLDER_DIALOG_SHOWED,
  status
})

export const setIsCreateLifecycleDialogShowedAction = status => ({
  type: SET_IS_CREATE_LIFECYCLE_DIALOG_SHOWED,
  status
})

export const setIsImpactSelectorDialogShowedAction = status => ({
  type: SET_IS_IMPACT_SELECTOR_MODAL_SHOWED,
  status
})

export const setIsMoveItemDialogShowedAction = status => ({
  type: SET_IS_MOVE_ITEM_DIALOG_SHOWED,
  status
})

export const setIsLoadingSettingCompletedAction = status => ({
  type: SET_IS_LOADING_SETTING_COMPLETED,
  status
})
