import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SplitScreenLayout } from '../../shared/layout/split-screen.layout'
import { RecentLifeCycleHeaderComponent } from '../component/recent-lifeCycle-header.component'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { useLifeCycleOverview } from '../../shared/hook/query/use-lifeCycle-overview'
// import { ILifecycle } from '../../model'

import { CreateLifeCycleContainer } from '../../shared/container/create-lifecycle.container'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { OverviewCardTemplateLayout, OverviewCardTemplateType } from '../../shared/layout/overview-card-template.component'
import { OverviewCreateComponent } from '../../shared/component/overview/overview-create.component'
import { OverviewViewComponent } from '../../shared/component/overview/overview-view.component'
import { RecentListLayout } from '../layout/recent-list.layout'
import { useWindowSize } from '../../shared/hook/use-window-size'
import { ScreenSizes } from '../../shared/enum/screen'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TListItem } from '../../shared/interface/list-item'
import { ListType, Navigation } from '../../shared/enum'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'

export const RecentLifeCyclesContainer = () => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { loading, lifeCycleData } = useLifeCycleOverview({ spaceID: space?.id, pageSize: 6 }) || {}
  const { addToBackToList = () => {}, updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const [ trackEventInSegment ] = getSegmentTrack()
  const navigate = useNavigate()
  const [ windowWidth ] = useWindowSize()
  const { lifecyclesPaginated: { items = [] } = {} } = lifeCycleData || {}
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false

  const openCreateLifeCycleDialog = () => {
    updateDashboard({ showCreateLifeCycleDialog: true })
  }

  const afterCreateLifeCycle = (id: string, trackEventType: TrackEventType) => {
    trackEventInSegment(trackEventType)
    navigateToLifeCycleDetail(id)
  }

  const navigateToLifeCycle = () => {
    trackEventInSegment(TrackEventType.VIEWED_LIFECYCLE_OVERVIEW)
    account?.id && space?.slug && navigate(generatePath(Navigation.LifeCycleOverview, {
      accountId: account.id,
      workspaceSlug: space.slug
    }))
  }

  const navigateToLifeCycleDetail = (id: string) => {
    trackEventInSegment(TrackEventType.VIEWED_LIFECYCLE)
    addToBackToList(t('labels.home', { ns: 'common' }))
    account?.id && space?.slug
      && navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/${!enableLegacyLifecycleInventory ? 'detail' : 'inventory'}/`)
  }

  const createLifeCycleTemplate = (createLabel: string) => (
    <OverviewCardTemplateLayout clickHandler={openCreateLifeCycleDialog} type={OverviewCardTemplateType.Create}>
      <OverviewCreateComponent createLabel={createLabel} />
    </OverviewCardTemplateLayout>
  )

  const viewLifeCycleTemplate = ({
    id = '', name = '', tags = [], updatedAt = '', description = ''
  } = {}) => (
    <OverviewCardTemplateLayout
      listType={ListType.Lifecycle}
      key={id}
      clickHandler={() => navigateToLifeCycleDetail(id)}
    >
      <OverviewViewComponent
        id={id}
        icon={<FontAwesomeIcon icon={faRecycle} className="text-xl" />}
        textLabel={name}
        tags={tags}
        description={description}
        updatedAt={updatedAt}
      />
    </OverviewCardTemplateLayout>
  )

  const getDisplayItems = (): TListItem[] => {
    if (items.length === 0) {
      return [ { key: 'create-first-lifeCycle', itemComponent: createLifeCycleTemplate(t('actions.create', { context: 'firstLifeCycle' })) } ]
    }

    let count = 2
    if (windowWidth > ScreenSizes.xxxl) {
      count = 6
    } else if (windowWidth > ScreenSizes.xxl) {
      count = 4
    } else if (windowWidth > ScreenSizes.xl) {
      count = 3
    }

    const displayItems: TListItem[] = items
      .filter((_: any, index: number) => index < count)
      .map((item: any) => ({ key: item.id, itemComponent: viewLifeCycleTemplate(item) }))

    const returnValue = displayItems.length < count ? [
      ...displayItems,
      { key: 'create-another-lifeCycle', itemComponent: createLifeCycleTemplate(t('actions.create', { context: 'anotherLifeCycle' })) }
    ] : displayItems

    return returnValue
  }

  return (
    <>
      <SplitScreenLayout
        className="flex-column"
        firstClassName="flex flex-none w-full h-2rem px-4"
        secondClassName="flex-grow-1 w-full"
      >
        <RecentLifeCycleHeaderComponent
          navigateToLifeCycle={navigateToLifeCycle}
          openCreateLifeCycleDialog={openCreateLifeCycleDialog}
        />
        {
          loading ? (
            <ProgressSpinnerComponent dataTestId="loading-workspace-entry" size={2} />
          ) : (
            <div className="flex w-full h-full p-3 pb-4 overflow-hidden">
              <RecentListLayout items={getDisplayItems()} />
            </div>
          )
        }
      </SplitScreenLayout>
      <CreateLifeCycleContainer afterCreateLifeCycle={afterCreateLifeCycle} />
    </>
  )
}
