import { connect } from 'react-redux'
import { addInventoryProductScenarioAction, } from '../../../../redux/actions/inventoryTree.actions'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { InventoryTreeSelector, selectedKeysSelector } from '../../../../redux/selectors/inventoryTree.selector'
import LoadingSelector from '../../../../redux/selectors/loading.selector'
import { selectedInventoryItemSaga } from '../../../../redux/sagas/product.saga'
import InventoryTreeWrapper from './inventoryTreeWrapper'

const mapStateToProps = state => ({
  inventoryTree: InventoryTreeSelector.inventoryTree(state),
  productScenarioInventoryTree: state.account.inventory.productScenarioInventoryTree,
  entityToCompare: ComparisonSelector.entityToCompare(state),
  isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
  isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
  isLoadingLeftTree: LoadingSelector.isLoadingLeftTree(state),
  isLoadingRightTree: LoadingSelector.isLoadingRightTree(state),
  selectedKeys: selectedKeysSelector(state)
})

const mapDispatchToProps = {
  addInventoryProductScenario: addInventoryProductScenarioAction,
  selectedInventoryItemSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryTreeWrapper)

