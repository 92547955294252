import React from 'react'
import classnames from 'classnames'
import { Form, Radio } from 'antd'
import SingleFieldText from './singleFieldText'
const { Group } = Radio
class RadioGroupWrapper extends React.PureComponent {

  getText = () => {
    const {
      dataCy,
      editHandler,
      disabled,
      displayedText
    } = this.props

    return (
      <SingleFieldText
        displayedText={displayedText}
        editHandler={editHandler}
        disabled={disabled}
        dataCy={ dataCy}
      />
    )
  }

  getRadioGroup = () => {
    const {
      children,
      autoFocus = true,
      dataCy,
      className,
      disabled,
      onChange,
      value,
      defaultValue
    } = this.props

    return (
      <Group onChange={onChange}
        autoFocus={autoFocus}
        className={classnames(`${dataCy}-radio-group`, { className })}
        data-cy={`${dataCy}-radio-group`}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </Group>
    )
  }

  render() {
    const {
      isFieldEditable,
      fieldLabel,
      fieldName,
      fieldRules
    } = this.props

    return (
      <Form.Item className="field-container" name={fieldName} label={ fieldLabel } rules={fieldRules}>
        {isFieldEditable ? this.getRadioGroup() : this.getText()}
      </Form.Item>
    )
  }
}

export default RadioGroupWrapper
