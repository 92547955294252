export enum EDatabaseSearchColumn {
  DatasetName = 'datasetName',
  Geography = 'geography',
  Property = 'property',
  Database = 'database',
  Action = 'action'
}

export enum EElementaryFlowColumn {
  DatasetName = 'datasetName',
  Compartment = 'compartment',
  SubCompartment = 'subCompartment',
  Property = 'property',
  Action = 'action'
}
