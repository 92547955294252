import { all, call, put, select } from 'redux-saga/effects'
import { EntityType } from '../../utils/const'
import resource from '../../utils/resource/resource'
import { isEmpty, unwrapGraphQLErrors, returnNested, safeArray } from '../../utils/tools'
import { getExpandedInventoryKeysMap } from '../../utils/treeUtils'
import {
  setComparisonFlatViewAction,
  setEntityToCompareAction,
  setIsInventoryCompareAction
} from '../actions/comparison.actions'
import {
  setIsShowLifecycleScenarioInventoryAction,
  setIsShowProductScenarioInventoryAction
} from '../actions/flags.actions'
import { setInventoryExpandedKeysAction } from '../actions/inventoryExpandedKeys.actions'
import { setProductScenarioInventoryTreeAction } from '../actions/inventoryTree.actions'
import { setLifecycleScenarioAction } from '../actions/lifecycle.actions'
import { addErrorNotificationAction, addSuccessNotificationAction } from '../actions/notification.actions'
import InventoryExpandedKeysSelector from '../selectors/inventoryExpandedKeys.selector'
import { updateLifecycleExpandedState } from './lifecycle.saga'
import { getProductWithImpactSaga } from './product.saga'

export const hasParams = params => safeArray(params).every(item => typeof item != 'undefined')
export const fnCall = (func, params) => call(func, ...params)

/**
 * @param {productMutator} productMutator
 * @param {string} mutationName
 * @param {object} args
 * @param {string} message
 * @param {function} cb
 */
export function* productMutation(productMutator, mutationName, args, message, cb) {
  try {
    const product = yield call(productMutator.callMutation, mutationName, args)
    if (!isEmpty(message)) {
      yield put(addSuccessNotificationAction(message))
    }
    yield call(updateSelectedProduct, product)
    cb && cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

export function* updateSelectedProduct(product) {
  const productId = returnNested(product, 'id')
  if (productId) {
    yield put(getProductWithImpactSaga(productId))
  }
}

export function* dispatchErrors(error) {
  yield all(unwrapGraphQLErrors(error)
    .map(errorItem =>
      put(addErrorNotificationAction(errorItem))
    ))
}

export const mapProductEntity = inventory => ({
  id: returnNested(inventory, 'product', 'id'),
  name: returnNested(inventory, 'product', 'name'),
  unit: returnNested(inventory, 'product', 'unit'),
  __typename: EntityType.PRODUCT,
  product: returnNested(inventory, 'product'),
  totalImpact: returnNested(inventory, 'totalImpact'),
  inventoryItems: returnNested(inventory, 'inventoryItems'),
  leafInventoryItems: returnNested(inventory, 'leafInventoryItems')
})

export const mapLifecycleEntity = lifecycle => ({
  id: returnNested(lifecycle, 'id'),
  name: returnNested(lifecycle, 'name'),
  amount: returnNested(lifecycle, 'amount'),
  unit: returnNested(lifecycle, 'unit'),
  phases: returnNested(lifecycle, 'phases'),
  __typename: EntityType.LIFECYCLE,
  totalImpact: returnNested(lifecycle, 'inventory', 'totalImpact'),
  leafInventoryItems: returnNested(lifecycle, 'inventory', 'leafInventoryItems')
})

/**
 * @param id
 * @return {{id: string, name: string, __typename: string, totalImpact: {}, leafInventoryItems: [{}] }}
 */
export function* getProductEntity(id) {
  const inventory = yield call(resource.queryByParams, 'inventory', { productID: id })
  const expandedKeysMap = yield select(InventoryExpandedKeysSelector.expandedKeysMapByRootId, id)
  yield put(setInventoryExpandedKeysAction(id, getExpandedInventoryKeysMap(inventory, expandedKeysMap)))
  return mapProductEntity(inventory)
}

/**
 * @param id
 * @return {{id: string, name: string, __typename: string, totalImpact: {}, leafInventoryItems: [{}] }}
 */
export function* getLifecycleEntity(lifecycleId) {
  const lifecycle = yield call(resource.queryByParams, 'lifecycle', { id: lifecycleId })
  yield call(updateLifecycleExpandedState, lifecycle)
  return mapLifecycleEntity(lifecycle)
}

export function* clearScenario() {
  yield put(setProductScenarioInventoryTreeAction(null))
  yield put(setLifecycleScenarioAction(null))
  yield put(setIsShowLifecycleScenarioInventoryAction(false))
  yield put(setIsShowProductScenarioInventoryAction(false))
  yield put(setIsShowLifecycleScenarioInventoryAction(false))
}

export function* clearComparison() {
  yield put(setComparisonFlatViewAction(null))
  yield put(setEntityToCompareAction(null))
  yield put(setIsInventoryCompareAction(false))
}
