import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'primereact/multiselect'

import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { ProductCategory } from '../../../shared/enum'
import { TaskbarFilterWrapperLayout } from '../../layout/taskbar-filter-wrapper.layout'

export const TaskbarFilterTypeComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const [ selectedCategories, setSelectedCategories ] = useState(null)
  const {
    updateProduct
  } = useContext<TProductContext>(ProductContext)

  const typeOptions = Object.values(ProductCategory).map(item => ({
    label: t('labels.productCategory', { context: item }),
    value: item
  }))
  const onCategoryChange = (category: any) => {
    setSelectedCategories(category)
    updateProduct({ categories: [ ...category ], currentPage: 1 })
  }

  return (
    <TaskbarFilterWrapperLayout>
      <MultiSelect
        data-testid="select-category"
        data-cy="product-filter-select"
        value={selectedCategories}
        options={typeOptions}
        display="chip"
        showSelectAll={false}
        panelHeaderTemplate={() => <></>}
        className="h-2rem m-0 p-0"
        panelClassName="taskbar-filter cy-product-filter-dropdown"
        onChange={(event: any) => onCategoryChange(event.value)}
        placeholder={t('labels.type')}
      />
    </TaskbarFilterWrapperLayout>
  )
}
