import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PRODUCT_WITH_IMPACT } from '../../../graphql/query'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct } from '../../model'

type TDatasetsCoreContainerProps = {
  children?: React.ReactNode
}
export const DatasetsCoreContainer = ({ children }: TDatasetsCoreContainerProps) => {
  const { inventoryItemId } = useParams()
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    skip: !inventoryItemId,
    variables: { id: inventoryItemId || '' },
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      const { product = null } = productWithImpactData?.productWithImpact || {}
      updateDashboard({ selectedEntity: product as IProduct })
    }
  }, [ loadingProductWithImpact, productWithImpactData ])

  return (
    <>
      { children }
    </>
  )
}
