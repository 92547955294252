import { connect } from 'react-redux'
import { setIsOrderingPhasesAction } from '../../../redux/actions/flags.actions'
import { setIsLifecycleSkipPhasesSaga } from '../../../redux/sagas/lifecycle.saga'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { inventoryRootItemIdSelector } from '../../../redux/selectors/inventoryTree.selector'
import { LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import SpaceSelector from '../../../redux/selectors/space.selector'
import { setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'
import Lifecycle from './lifecycle'

const mapStateToProps = state => ({
  lifecycle: LifecycleSelector.selectedLifecycle(state),
  isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
  flatView: LifecycleSelector.flatView(state),
  comparisonFlatView: ComparisonSelector.comparisonFlatView(state),
  selectedSpace: SpaceSelector.selectedSpace(state),
  entityToCompare: ComparisonSelector.entityToCompare(state),
  inventoryRootItemId: inventoryRootItemIdSelector(state),
  selectedInventoryTreeProduct: InventoryTreeSelector.product(state),
  skipPhases: FlagsSelector.isSkipPhases(state)
})

const mapDispatchToProps = {
  setIsLifecycleSkipPhasesSaga,
  setIsOrderingPhasesAction,
  setIsDetailsPanelOpenAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lifecycle)
