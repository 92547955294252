import { connect } from 'react-redux'
import FlatInventory from './flatInventory'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { InventoryTreeSelector } from '../../../../redux/selectors/inventoryTree.selector'
import { setIsLifecycleSkipPhasesSaga } from '../../../../redux/sagas/lifecycle.saga'
import { selectedFlatViewItemSaga } from '../../../../redux/sagas/product.saga'

const  mapStateToProps = state => ({
  skipPhases: FlagsSelector.isSkipPhases(state),
  inventoryTreeSelectorProductId: InventoryTreeSelector.productId(state),
})

const mapDispatchToProps = {
  setIsLifecycleSkipPhasesSaga,
  selectedFlatViewItemSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlatInventory)
