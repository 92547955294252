import { returnNested } from '../../utils/tools'
import { SET_PRODUCT_SCENARIO_INVENTORY_TREE } from '../actions/inventoryTree.actions'
import { CLEAR_INVENTORY_TREE_STATE } from '../actions/clear.actions'

const productScenarioInventoryReducer = (state=null, action={}) => {
  switch (action.type) {
  case SET_PRODUCT_SCENARIO_INVENTORY_TREE:
    return action.productScenario
  case 'REMOVE_PRODUCT':
    if (!returnNested(state, 'product')) return state
    return state.product.id === action.productId
      ? null
      : state
  case CLEAR_INVENTORY_TREE_STATE:
    return null
  default:
    return state
  }
}

export default productScenarioInventoryReducer
