import { gql } from '../../../__generated__'

export const ENTITIES_PAGINATED = gql(`
  query entitiesPaginated(
    $query: String,
    $spaceID: ID!,
    $pageSize: Int,
    $skipLifeCycles: Boolean!,
    $skipProducts: Boolean!,
    $sortProduct: SortProduct,
    $sortLifeCycle: SortLifeCycle
  ) {
    lifecycles: lifecyclesPaginated(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      sortBy: $sortLifeCycle
    ) @skip(if: $skipLifeCycles) {
      items {
        id
        name
      }
    }
    products(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      labels: ["product"],
      sortBy: $sortProduct
    ) @skip(if: $skipProducts) {
      items {
        ... on Product {
          id
          name
        }
      }
    }
    objects: products(
      query: $query,
      spaceID: $spaceID,
      pageSize: $pageSize,
      labels: ["object"],
      sortBy: $sortProduct
    ) @skip(if: $skipProducts) {
      items {
        ... on Product {
          id
          name
        }
      }
    }
  }
`)
