import React from 'react'
import compose from '../../../../../utils/compose'
import { Form } from 'antd'
import AddInventoryItem from '../../add-inventory-item/addInventoryItem.container'
import { returnNested } from '../../../../../utils/tools'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../../utils/with-router'
import InputWrapper from '../../../../helpers/form/inputWrapper'
import {
  getInventoryNodeFromInventoryItemKey,
  InventoryTreeKey
} from '../../../../../utils/inventoryTreeKey'
import { withEditableProduct } from '../../../with-editable-product/withEditableProduct'

import { LifecycleItemLocation } from '../../../../shared/locations'
import { matchLocation } from '../../../../../utils/location'

class ProductInventoryProperties extends React.Component {
  constructor(props) {
    super(props)
    this.updatePageItemsHandler = this.updatePageItemsHandler.bind(this)
    this.state = {
      selectedNodeFromInventory: null
    }
  }

  static getDerivedStateFromProps(props) {
    const { inventoryTree, selectedInventoryItem } = props
    const selectedNodeFromInventory = getInventoryNodeFromInventoryItemKey(inventoryTree, selectedInventoryItem)

    return { selectedNodeFromInventory }
  }

  handleAddNewTreeItem = () => {
    const { setIsCreateNewInventoryItem, resetChangeAmountForm } = this.props

    resetChangeAmountForm()
    setIsCreateNewInventoryItem(true)
  }

  updatePageItemsHandler = (addAnotherItem = false) => {
    const { updateInventoryItemsSaga, updateLifecyclesItemsSaga, isEnabledForV1, updatePageItems } = this.props
    const closePanel = !addAnotherItem

    if ( matchLocation(LifecycleItemLocation, true) ) {
      updateLifecyclesItemsSaga(closePanel)
    } else {
      if (isEnabledForV1) {
        updatePageItems({ isClosedAfterAddItem: closePanel })
      } else {
        updateInventoryItemsSaga(closePanel)
      }
    }
    if ( closePanel ) {
      this.cancelCreateNewItem()
    }
  }

  cancelCreateNewItem = () => {
    const { setIsCreateNewInventoryItem } = this.props
    setIsCreateNewInventoryItem(false)
  }

  editAmountHandler = () => {
    const { resetChangeAmountForm, formRef } = this.props

    resetChangeAmountForm(true)
    formRef.current.setFieldsValue({ amount: this.getSelectedProductAmount() })
  }

  getSelectedProductAmount = () => {
    const { selectedProduct, inventoryTree } = this.props
    return selectedProduct && inventoryTree && returnNested(this.state.selectedNodeFromInventory, 'inventoryItem', 'amount')
  }

  render() {
    const {
      t,
      formRef,
      editMode,
      inventoryTree,
      productMutator,
      selectedProductId,
      selectedInventoryItem,
      resetChangeAmountForm,
      isDetailsPanelReadOnly,
      handleAmountChangeEvent,
      isCreateNewInventoryItem,
      isEnabledForV1,
      setIsCreateNewInventoryItem
    } = this.props
    const isSelectedProductRoot = selectedInventoryItem && InventoryTreeKey.createFromKey(selectedInventoryItem).isRoot()
    const amountValue = isSelectedProductRoot ? '1' : this.getSelectedProductAmount()

    return (
      <React.Fragment>
        {isCreateNewInventoryItem ?
          <React.Fragment>
            <AddInventoryItem
              isEnabledForV1={isEnabledForV1}
              inventoryTree={ inventoryTree }
              productMutator={ productMutator }
              selectedProductId={ selectedProductId }
              updatePageItems={ this.updatePageItemsHandler }
              handleCancel={ () => {
                setIsCreateNewInventoryItem(false)
              } }
            />
          </React.Fragment>
          :
          <React.Fragment>
            <Form
              ref={formRef}
              initialValues={{ amount: amountValue }}
              className="field-container"
              layout="vertical"
              hideRequiredMark
            >
              <InputWrapper
                isFieldEditable={editMode}
                dataCy='product-amount-input'
                disabled={isSelectedProductRoot || isDetailsPanelReadOnly}
                displayedText={amountValue}
                editHandler={this.editAmountHandler}
                min={0}
                fieldLabel={t('global.amount')}
                fieldName="amount"
                fieldRules= {[
                  { required: true, message: t('validation.is_required', { name: t('model.impact_method_label') }) }
                ]}
                type="number"
                onBlur={handleAmountChangeEvent}
                onPressEnter={handleAmountChangeEvent}
                onPressEscape={resetChangeAmountForm}
                value={amountValue}
              />
            </Form>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export { ProductInventoryProperties }
export default compose(
  withRouter,
  withTranslation(),
  withEditableProduct
)(ProductInventoryProperties)
