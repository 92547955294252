import React, { useContext } from 'react'

import styled from 'styled-components'
import { Button } from 'primereact/button'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCircleNodes, faPenField } from '@fortawesome/pro-regular-svg-icons'
import { ExternalSources } from '../../shared/enum'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'

interface DefaultLandingBackgroundProps {
  readonly isDatabaseSearch?: boolean
}

const DefaultLandingWrapper = styled.div`
  height: calc(100vh - 18rem);
`
const DefaultLandingBackground = styled.div<DefaultLandingBackgroundProps>`
  background-image: url(${(props: DefaultLandingBackgroundProps) => (props.isDatabaseSearch ? '/assets/images/database-search-landing.svg' : '/assets/images/elementary-flow-landing.svg')});
  background-position: center;
`

export const DefaultLandingComponent = () => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const [ trackEventInSegment ] = getSegmentTrack()

  const {
    selectedDatasetViewType
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { datasetNavigate } = useDatasetNavigate()

  const handleTabChange = (viewType: ImpactDatasetViewType) => {
    if (selectedDatasetViewType !== viewType) {
      datasetNavigate(viewType)
    }
  }

  const isSelectedViewType = (viewType: ImpactDatasetViewType) => viewType === selectedDatasetViewType

  const openHelpCenter = () => {
    trackEventInSegment(TrackEventType.VIEWED_HELP_CENTER_LINK_2)
    window.open(ExternalSources.HelpCenterAddingImpact)
  }

  return (
    <DefaultLandingWrapper className="flex flex-column  h-30rem justify-content-center align-items-center w-full mt-5">
      <DefaultLandingBackground
        isDatabaseSearch={isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)}
        className="w-full h-20rem bg-no-repeat"
      />
      <div className="flex justify-content-center text-2xl font-semibold text-gray-700">
        { t('labels.defaultLanding', { context: 'title' }) }
      </div>

      <div className="flex justify-content-center text-base font-medium pt-4 text-gray-500">
        { t('labels.defaultLanding', { context: 'subtitle' }) }
      </div>

      <div className="flex justify-content-center text-base font-medium p-0 text-gray-500">
        { isSelectedViewType(ImpactDatasetViewType.DatabaseSearch)
        && (
          <Button
            className="p-button-text p-button-plain text-sm p-0"
            onClick={() => handleTabChange(ImpactDatasetViewType.ElementaryFlow)}
          >
            <FontAwesomeIcon icon={faCircleNodes} />
            <span className="ml-1">{ t('labels.elementaryFlow') }</span>
          </Button>
        )}
        { isSelectedViewType(ImpactDatasetViewType.ElementaryFlow)
        && (
          <Button
            className="p-button-text p-button-plain text-sm p-0"
            onClick={() => handleTabChange(ImpactDatasetViewType.DatabaseSearch)}
          >
            <FontAwesomeIcon icon={faDatabase} />
            <span className="ml-1">{ t('labels.databaseSearch') }</span>
          </Button>
        )}
        <Button
          className="p-button-text p-button-plain text-sm p-0 ml-3"
          onClick={() => handleTabChange(ImpactDatasetViewType.CustomImpact)}
        >
          <FontAwesomeIcon icon={faPenField} />
          <span className="ml-1">{ t('labels.customImpact') }</span>
        </Button>
      </div>

      <div className="flex justify-content-center align-items-center text-sm font-normal pt-4 text-gray-500">
        { t('labels.defaultLanding', { context: 'moreInfo' }) }
        <Button
          className="p-button-text p-button-plain text-sm p-0 ml-1 text-blue-500"
          onClick={openHelpCenter}
          label={t('labels.helpCenter', { ns: 'common' })}
        />
      </div>
    </DefaultLandingWrapper>
  )
}
