import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { DecimalViewType } from '../../../shared/enum'

interface TreeItemAmountProps {
  readonly mouseOverNode?: boolean
}
const TreeItemAmountComponentWrapper = styled.div<TreeItemAmountProps>`

  .item-amount-view {
    padding: 0 0.25rem;
    height: 1.563rem;
    border: ${(props: TreeItemAmountProps) => (props.mouseOverNode ? '1px solid var(--gray-500)' : 'none')};
    margin: ${(props: TreeItemAmountProps) => (props.mouseOverNode ? '0' : '0 2px 0 0')};
    cursor: ${(props: TreeItemAmountProps) => (props.mouseOverNode ? 'pointer' : 'default')};
  }

  .item-amount-input {
    padding: 0 0.5rem;
    width: 4rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--primary-500);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .btn-menu-item {
    color: var(--gray-300);
    border-color: var(--gray-300);

    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
`

export type TTreeItemAmountComponentProps = {
  amount: any
  nodeKey: string
  mouseOverNode?: boolean
  onChangeAmount: (param: string) => void
}

export const TreeItemAmountComponent = ({
  amount = '',
  nodeKey,
  mouseOverNode = false,
  onChangeAmount
}: TTreeItemAmountComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const [ inputAmount, setInputAmount ] = useState(amount)
  const [ inputDisabled ] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const {
    editAmountMode,
    selectedInventoryKey,
    updateProductDetail,
    readonlyInventory = false,
    selectedDecimalViewType = DecimalViewType.NumericValue,
    setLifecycleSelectedKeyAndPhase = () => {},
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const isRoot = () => nodeKey === 'node:root'
  useEffect(() => {
    function handleClickOnEverywhere() {
      onCancelChangeAmount()
    }

    if (editAmountMode) {
      setTimeout(() => adjustInputWidth(), 250)
    }

    isInEditMode() && window.addEventListener('click', handleClickOnEverywhere)

    return () => window.removeEventListener('click', handleClickOnEverywhere)
  }, [ editAmountMode, selectedInventoryKey ])

  const preventEventPropagation = (event: any) => {
    event.stopPropagation()
  }

  const saveAmount = (event: any) => {
    preventEventPropagation(event)

    onChangeAmount(inputAmount)
    updateProductDetail({ editAmountMode: false, selectedInventoryKey: null })
  }

  const onChangeMode = (event: any) => {
    preventEventPropagation(event)

    if (!readonlyInventory) {
      setInputAmount(amount)
      setLifecycleSelectedKeyAndPhase(nodeKey)
      updateProductDetail({ editAmountMode: true, selectedInventoryKey: nodeKey })
    }
  }

  const onCancelChangeAmount = (event?: any) => {
    event && preventEventPropagation(event)

    updateProductDetail({ editAmountMode: false, selectedInventoryKey: null })
  }

  const isInEditMode = () => selectedInventoryKey && selectedInventoryKey === nodeKey && editAmountMode && !isRoot() && !readonlyInventory

  const adjustInputWidth = () => {
    if (inputRef.current) {
      const elt = inputRef.current
      if (elt.scrollWidth > elt.clientWidth) {
        elt.style.width = `${elt.scrollWidth + 8}px`
      }
    }
  }

  const onValueChange = (event: any) => {
    setInputAmount(event.target.value)
    adjustInputWidth()
  }

  const onKeyDown = (event: any) => {
    const elt = inputRef.current!
    const isNumber = new RegExp(/^\d+$/).test(event.key)

    if (event.key === '-') {
      event.preventDefault()
    }
    if (event.key === 'Escape') {
      onCancelChangeAmount()
    }
    if (event.key === 'Enter') {
      saveAmount(event)
    }
    if (event.key === 'Backspace' && elt.clientWidth > 65) {
      elt.style.width = `${elt.scrollWidth - 8}px`
    } else if (isNumber && event.target.value.length > 16) {
      event.preventDefault()
    }
  }

  const isPercentage = selectedDecimalViewType === DecimalViewType.PercentageValue

  return (
    <TreeItemAmountComponentWrapper mouseOverNode={mouseOverNode} className="w-full h-full">
      { !isInEditMode() && (
        <div
          onClick={(event: any) => onChangeMode(event)}
          className={classNames('border-round-sm flex align-items-center', {
            'item-amount-view': !isRoot() && !readonlyInventory
          })}
          data-testid="tree-item-amount-view"
          data-cy="item-amount"
        >
          <DecimalPointComponent
            value={amount}
            enableTooltip
            decimalViewType={isPercentage ? DecimalViewType.NumericValue : selectedDecimalViewType}
          />
        </div>
      )}
      { isInEditMode() && (
        <div data-testid="tree-item-amount-edit" className="flex justify-content-center">
          <InputText
            autoFocus
            ref={inputRef}
            min={0}
            lang="en-IN"
            type="number"
            value={inputAmount}
            onChange={onValueChange}
            onClick={preventEventPropagation}
            onKeyDown={onKeyDown}
            data-testid="tree-item-amount-input"
            className="item-amount-input"
            disabled={inputDisabled}
            tooltip={t('actions.edit', { context: 'amount' })}
            tooltipOptions={{ position: 'bottom' }}
          />

          <Button
            icon="pi pi-times"
            type="button"
            data-testid="tree-item-amount-cancel"
            tooltip={t('actions.cancel', { ns: 'common' })}
            tooltipOptions={{ position: 'bottom' }}
            className="p-button-outlined btn-menu-item ml-2"
            onClick={onCancelChangeAmount}
          />

          <Button
            icon="pi pi-check"
            type="submit"
            data-testid="tree-item-amount-submit"
            tooltip={t('actions.save', { ns: 'common' })}
            tooltipOptions={{ position: 'bottom' }}
            className="p-button-outlined btn-menu-item ml-2 mr-1"
            onClick={saveAmount}
          />
        </div>
      )}
    </TreeItemAmountComponentWrapper>
  )
}
