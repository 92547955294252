export const
  SET_IS_LOADING_REFERENCE_PRODUCTS    = 'SET_IS_LOADING_REFERENCE_PRODUCTS',
  SET_IS_LOADING_SPACE_IMPACT_METHODS  = 'SET_IS_LOADING_SPACE_IMPACT_METHODS',
  SET_IS_LOADING_SPACE_IMPACT_SETTINGS = 'SET_IS_LOADING_SPACE_IMPACT_SETTINGS',
  SET_IS_LOADING_LEFT_TREE             = 'SET_IS_LOADING_LEFT_TREE',
  SET_IS_LOADING_RIGHT_TREE            = 'SET_IS_LOADING_RIGHT_TREE'

export const
  setIsLoadingReferenceProductsAction   = status => ({
    type: SET_IS_LOADING_REFERENCE_PRODUCTS,
    status
  }),
  setIsLoadingSpaceImpactMethodsAction  = status => ({
    type: SET_IS_LOADING_SPACE_IMPACT_METHODS,
    status
  }),
  setIsSpaceImpactSettingsLoadingAction = status => ({
    type: SET_IS_LOADING_SPACE_IMPACT_SETTINGS,
    status
  }),
  setIsLoadingLeftTreeAction            = status => ({
    type: SET_IS_LOADING_LEFT_TREE,
    status
  }),
  setIsLoadingRightTreeAction           = status => ({
    type: SET_IS_LOADING_RIGHT_TREE,
    status
  })

