import { gql } from '@apollo/client'
import lifecycle from '../fragment/lifecycle'

export default gql`
  mutation copyLifecycle($lifecycleID: ID!) {
    copyLifecycle(lifecycleID: $lifecycleID) {
      ...lifecycleFragment
    }
  }
  ${lifecycle}
`
