import { connect } from 'react-redux'
import { copyProductSaga } from '../../../../redux/sagas/product.saga'
import { quitProductScenarioSaga } from '../../../../redux/sagas/productScenarioInventoryTree.saga'
import { trackEventSaga, } from '../../../../redux/sagas/tracking.saga'
import { addInventoryProductScenarioAction } from '../../../../redux/actions/inventoryTree.actions'
import AccountSelector from '../../../../redux/selectors/account.selector'
import {
  ProductScenarioSelector
} from '../../../../redux/selectors/inventoryTree.selector'
import ProductScenarioDropdown from './productScenarioDropdown'

const mapStateToProps = state => ({
  accountId: AccountSelector.selectedAccountId(state),
  productScenarioId: ProductScenarioSelector.productId(state),
})

const mapDispatchToProps = {
  copyProductSaga,
  trackEventSaga,
  quitProductScenarioSaga,
  addInventoryProductScenarioAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductScenarioDropdown)
