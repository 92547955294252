import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'

import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { isValid } from '../../../shared/util/tools'

const TaskbarFilterQueryWrapper = styled.div`
  .pi-search {
    left: 0.75rem !important;
    top: 1.18rem;
    font-size: 0.75rem !important;
    color: var(--gray-200) !important;
  }

  .p-inputtext::placeholder {
    color: var(--gray-200);
    opacity: 1;
  }

  .p-inputtext:-ms-input-placeholder {
    color: var(--gray-200);
  }
`

export const TaskbarFilterQueryComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const [ queryString, setQueryString ] = useState('')
  const {
    label,
    updateProduct,
    currentFolder
  } = useContext<TProductContext>(ProductContext)
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      updateProduct({ query: queryString, currentPage: 1 })
    }, 250)
    return () => clearTimeout(delayInputTimeoutId)
  }, [ queryString, 250 ])
  const { name: currentFolderName = null } = currentFolder || {}

  const getPlaceholderText = () => {
    if (isValid(currentFolderName)) {
      return t('form.placeholderSearch', { context: 'folder', folderName: currentFolderName })
    }

    return t('form.placeholderSearch', { context: label })
  }

  return (
    <TaskbarFilterQueryWrapper>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          className="h-2rem text-sm"
          data-testid="search-by-query"
          data-cy="product-search-box"
          onChange={(event: any) => setQueryString(event.target.value)}
          placeholder={getPlaceholderText()}
        />
      </span>
    </TaskbarFilterQueryWrapper>
  )
}
