import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeProductProperty($productID: ID!, $referencePropertyID: ID!, $conversionFactor: String!, $referenceUnitID: ID!) {
    changeProductProperty(productID: $productID, referencePropertyID: $referencePropertyID, conversionFactor: $conversionFactor, referenceUnitID: $referenceUnitID){
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
