import React, { useState, useEffect } from 'react'
import { Tree } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { InventoryTreeKey } from '../../../../utils/inventoryTreeKey'
import { isEmpty, returnNested, safeArray } from '../../../../utils/tools'
import { inventoryItemsToTree } from '../../../../utils/treeUtils'
import InventoryTreeItem from './inventoryTreeItem.container'
import InventoryTreeHeader from './inventoryTreeHeader'

const InventoryTree = props => {
  const {
    isLeftTree,
    inventoryTree,
    selectedKeys,
    expandedKeys,
    isDetailsPanelOpen,
    selectedInventoryItemSaga,
    toggleExpandedKeySaga,
    isReadOnly,
    areImpactsBarsShown,
    clearUiWhenDetailPanelCloseAction,
    amountModalPreference,
    setIsDetailsPanelOpenAction
  } = props

  if (isEmpty(returnNested(inventoryTree, 'product'))) return null
  const [ selectedNodeKeys, setSelectedNodeKeys ] = useState([])
  const [ treeData, setTreeData ] = useState([])

  useEffect(() => { setTreeData(getTreeData()) }, [ areImpactsBarsShown, selectedNodeKeys, inventoryTree ])

  const getMaxImpactAmount = inventoryTree => {
    let maxImpactAmount = Math.abs(returnNested(inventoryTree, 'totalImpact', 'amount'))
    const inventoryItems = returnNested(inventoryTree, 'inventoryItems')

    if (isEmpty(inventoryItems)) return maxImpactAmount

    const impacts = inventoryItems && Array.from(inventoryItems, el => Math.abs(returnNested(el, 'inventoryItem', 'impact', 'amount')))
    if (impacts) maxImpactAmount = Math.max(...impacts, Math.abs(returnNested(inventoryTree, 'totalImpact', 'amount')))
    return maxImpactAmount
  }

  //getFilteredProducts IS OPTIMISED VERSION OF TREE RENDERING MIGHT BE UNCOMENTED IN FUTURE BY REQUEST
  // const getFilteredProducts = inventoryItems => {
  //   return inventoryItems.filter(inventoryItem => {
  //     let expandedKeysNodeIdList = []
  //     expandedKeys.forEach(nodeItem => {
  //       let currentNodeId = JSON.parse(nodeItem).currentNodeId
  //       currentNodeId && expandedKeysNodeIdList.push(getRootNodeOfInventoryItem(currentNodeId))
  //     })

  //     const rootNode = getRootNodeOfInventoryItem(inventoryItem.nodeId)

  //     return inventoryItem.parentNode === '' ||
  //       isInventoryItemWithChildNodes(inventoryItem) ||
  //       (expandedKeysNodeIdList && expandedKeysNodeIdList.includes(rootNode))
  //   })
  // }

  const getInventoryItems = () => {
    //getFilteredProducts IS OPTIMISED VERSION OF TREE RENDERING MIGHT BE UNCOMENTED IN FUTURE BY REQUEST
    // return getFilteredProducts(safeArray(inventoryTree.inventoryItems))
    return safeArray(inventoryTree.inventoryItems)
  }

  const parseChildrenTreeItems = (inventoryTree, nodeChildrenList, maxImpactAmount) => {
    let inventoryNodeList = []
    for (let inventoryNode of nodeChildrenList) {
      const nodeKey = InventoryTreeKey.createFromInventoryNode(inventoryTree, inventoryNode).inventoryTreeKey()
      const product = returnNested(inventoryNode, 'inventoryItem', 'product')
      const inventoryItemProps = {
        amount: inventoryNode.inventoryItem.amount,
        name: returnNested(product, 'name'),
        impact: returnNested(inventoryNode, 'inventoryItem', 'impact'),
        maxImpactAmount,
        unit: returnNested(product, 'unit'),
        customImpacts: returnNested(product, 'customImpacts'),
        hasImpactSource: returnNested(product, 'referenceProduct') || !isEmpty(returnNested(product, 'customImpacts')),
        nodeKey,
        isLeftTree,
        isReadOnly,
        areImpactsBarsShown,
        hasInventory: returnNested(product, 'hasInventory'),
        productId: product && product.id
      }
      inventoryNodeList.push(
        {
          title: <span className="inventory-tree-item-wrapper">
            <InventoryTreeItem { ...inventoryItemProps } />
          </span>,
          key: nodeKey,
          selectable: true,
          className: 'inventory-item',
          isRoot: false,
          'data-cy': `tree-item-named-${ inventoryNode.inventoryItem.product.name.replace(/\s/g, '-').toLowerCase() }`,
          'data-cy-node': inventoryNode.nodeId,
          children: inventoryNode.children.length > 0
            ? parseChildrenTreeItems(inventoryTree, inventoryNode.children, maxImpactAmount)
            : []
        },
      )
    }
    return inventoryNodeList
  }

  const getTreeData = () => {
    const maxImpactAmount = getMaxImpactAmount(inventoryTree)
    const treeItems = inventoryItemsToTree(getInventoryItems())
    const children = parseChildrenTreeItems(inventoryTree, treeItems, maxImpactAmount)
    const nodeKey = InventoryTreeKey.createFromRootId(inventoryTree.product.id).inventoryTreeKey()

    return [ {
      selectable: true,
      key: nodeKey,
      className: 'inventory-item inventory-root-item',
      'data-cy': 'root-tree-item',
      title: <InventoryTreeItem
        amount={ '1' }
        name={ inventoryTree.product.name }
        unit={ inventoryTree.product.unit }
        impact={ inventoryTree.totalImpact }
        maxImpactAmount={ maxImpactAmount }
        status={ inventoryTree.totalImpact.status }
        nodeKey={nodeKey}
        isLeftTree={isLeftTree}
        isReadOnly={isReadOnly}
        areImpactsBarsShown={areImpactsBarsShown}
        isRoot={true}
        productId={inventoryTree.product.id}
      />,
      children
    } ]
  }

  const onItemTreeSelected = key => {

    if ( amountModalPreference?.visible ) {
      setIsDetailsPanelOpenAction(false)
      return
    }

    if ( !key[0] ) {
      clearUiWhenDetailPanelCloseAction()
      return
    }

    selectedInventoryItemSaga(key[0])
  }

  const onExpandTreeItem = (expandedKeys, options) => {
    const incomingNodeKey = options.node['data-cy-node'] && options.node['data-cy-node'].substring(0, 6)
    if (incomingNodeKey && selectedNodeKeys && !selectedNodeKeys.includes(incomingNodeKey)) {
      setSelectedNodeKeys([ ...selectedNodeKeys, incomingNodeKey ])
    }
    toggleExpandedKeySaga(
      returnNested(inventoryTree, 'product', 'id'),
      returnNested(options, 'node', 'key'),
      options.expanded
    )
  }

  const impactUnit = returnNested(inventoryTree, 'totalImpact', 'unit')

  return <React.Fragment>
    <InventoryTreeHeader impactUnit={impactUnit} areImpactsBarsShown={areImpactsBarsShown} />
    <Tree
      onSelect={ onItemTreeSelected }
      className="inventory-tree"
      selectedKeys={ isDetailsPanelOpen ? selectedKeys : []}
      expandedKeys={ [ ...expandedKeys ] }
      onExpand={ onExpandTreeItem }
      treeData={ treeData }
      showLine
      switcherIcon={<CaretDownOutlined className="tree-switcher-icon" />}
    />
  </React.Fragment>
}

export { InventoryTree }
export default compose(
  withTranslation(),
)(InventoryTree)
