import React, { useContext, useEffect, useRef } from 'react'
import { createGlobalStyle } from 'styled-components'
import { useMutation } from '@apollo/client'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBringForward, faFileCirclePlus, faRecycle, faFilePdf, faFileXmark
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import { displayGraphqlErrors } from '../../../shared/util/error'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'

import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { getBrowserTimezone } from '../../../shared/util/tools'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { REQUEST_LIFECYCLE_EXPORT } from '../../../graphql/mutation'

const GlobalStyle = createGlobalStyle`
  .tools-menu {
    .p-submenu-header {
      padding: 0.25rem 1.3rem;
      color: var(--gray-200);
      font-size: 0.75rem;
      font-weight: normal;
    }
  }
`

type TMenuToolsContainerProps = {
  fromComparison?: boolean
}
export const MenuToolsContainer = ({
  fromComparison = false
}: TMenuToolsContainerProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle', 'product' ])

  const toast = useRef<Toast>(null)
  const menu = useRef<Menu>(null)
  const [ trackEventInSegment ] = getSegmentTrack()

  const {
    lifecycleDetail = {},
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  const [
    requestLifecycleExport,
    {
      error: failedRequest,
      data: requestLifecycleExportData,
      loading: requestingLifecycleExport
    }
  ] = useMutation(REQUEST_LIFECYCLE_EXPORT)
  const requestedLifecycleExport = requestLifecycleExportData?.requestLifecycleExport

  useEffect(() => {
    if (!requestingLifecycleExport && (requestedLifecycleExport || failedRequest)) {
      replaceLabelCallback()
    }
  }, [ requestingLifecycleExport, requestedLifecycleExport, failedRequest ])

  const replaceLabelCallback = () => {
    try {
      if (failedRequest) {
        throw failedRequest
      } else if (requestedLifecycleExport) {
        trackEventInSegment(TrackEventType.DOWNLOADED_EXCEL_RESULTS)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.requestedLifecycleExport', { context: 'success', ns: 'lifecycle' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.requestedLifecycleExport', { context: 'error', ns: 'lifecycle' }), error?.graphQLErrors)
    }
  }

  const items = [
    {
      label: t('labels.header', { context: 'modeAndInsight' }),
      items: [
        {
          key: 0,
          disabled: fromComparison,
          label: t('actions.create', { context: 'scenario' }),
          icon: <FontAwesomeIcon data-cy="menu-item-scenario" icon={faFileCirclePlus} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateLifecycleDetail({ showCreateScenarioDialog: true })
          },
          template: MenuItemComponent
        },
        {
          key: 1,
          label: t('actions.compare', { context: 'lifecycle', ns: 'lifecycle' }),
          icon: <FontAwesomeIcon data-cy="menu-item-comparison" icon={faBringForward} rotation={90} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateDashboard({ showCreateComparisonDialog: true })
          },
          template: MenuItemComponent
        },
        {
          key: 2,
          disabled: fromComparison,
          label: t('actions.create', { context: 'lifecycle' }),
          icon: <FontAwesomeIcon icon={faRecycle} className="text-sm mr-1 text-green-500" />,
          command: () => {
            updateDashboard({ showCreateLifeCycleFromProduct: true })
          },
          template: MenuItemComponent
        }
      ]
    },
    {
      label: t('labels.header', { context: 'output' }),
      items: [
        {
          key: 4,
          disabled: fromComparison,
          label: t('actions.download', { context: 'impactXlsx' }),
          icon: <FontAwesomeIcon icon={faFileXmark} className="text-sm mr-1 text-green-500" />,
          command: () => {
            lifecycleDetail?.id && requestLifecycleExport({ variables: { lifecycleID: lifecycleDetail?.id, timezone: getBrowserTimezone() } })
          },
          template: MenuItemComponent
        },
        {
          key: 5,
          disabled: fromComparison,
          label: t('actions.download', { context: 'productFootprintPdf' }),
          icon: <FontAwesomeIcon icon={faFilePdf} className="text-sm mr-1 text-green-500" />,
          command: () => {
            trackEventInSegment(TrackEventType.DOWNLOADED_PDF_RESULTS)
            updateLifecycleDetail({ showPdfInsightsResult: true })
            toast?.current?.show({
              severity: 'success',
              summary: t('messages.successSummary'),
              detail: t('messages.success', { context: 'downloadInsightsPdf' }),
              life: 3000
            })
          },
          template: MenuItemComponent
        }
      ]
    },

  ]

  return (
    <>
      <GlobalStyle />
      <Menu model={items} popup ref={menu} id="popup_menu" className="tools-menu mt-1 w-20rem" />

      <Button
        className="p-button p-component p-button-success my-1"
        label="Tools"
        icon="pi pi-chevron-down"
        iconPos="right"
        onClick={(event: any) => menu?.current?.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        data-testid="menu-tools-dropdown"
        data-cy="product-tools-button"
      />

      <Toast data-testid="request-export-status" ref={toast} position="top-right" />
    </>
  )
}
