import React from 'react'
import { useTranslation } from 'react-i18next'

export const EmailVerificationBanner = () => {
  const { t } = useTranslation([ 'dashboard', 'common' ])

  return (
    <div className="flex justify-content-center w-full">
      <div data-testid="import-warning-summary" className="text-base font-semibold py-1 line-height-2">
        { t('messages.infoSummary', { context: 'emailVerification' }) }
      </div>
      <div data-testid="import-warning-detail" className="text-sm px-3 line-height-4">
        { t('messages.infoDetail', { context: 'emailVerification' }) }
      </div>
    </div>
  )
}
