import { connect } from 'react-redux'
import { setIsCreateNewInventoryItemAction } from '../../../../redux/actions/flags.actions'
import GlobalSelector from '../../../../redux/selectors/global.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import {
  addInventoryItemSaga,
  createInventoryItemSaga,
  getPossibleInventoryItemsSaga
} from '../../../../redux/sagas/product.saga'
import { setPossibleInventoryItemsAction, clearListOfProductsForTransportation } from '../../../../redux/actions/global.actions'
import { selectedInventoryItemSelector } from '../../../../redux/selectors/inventoryTree.selector'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'

import AddInventoryItem from './addInventoryItem'

const mapStateToProps = state => ({
  possibleInventoryItems: GlobalSelector.possibleInventoryItems(state),
  selectedInventoryItem: selectedInventoryItemSelector(state),
  selectedLifecycle: LifecycleSelector.selectedLifecycle(state),
})

const mapDispatchToProps = {
  clearListOfProductsForTransportation,
  setPossibleInventoryItemsAction,
  getPossibleInventoryItemsSaga,
  addInventoryItemSaga,
  createInventoryItemSaga,
  trackEventSaga,
  setIsCreateNewInventoryItemAction,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInventoryItem)

