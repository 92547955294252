import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation createInventoryItem($productID: ID!, $productAmount: String!, $name: String!, $value: String!, $unit: String, $isWaste: Boolean!) {
    createInventoryItem(productID: $productID, productAmount: $productAmount, name: $name, value: $value, unit: $unit, isWaste: $isWaste){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
