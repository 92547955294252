import React, { useContext, useEffect } from 'react'

import { useReactiveVar } from '@apollo/client'

import { ProductInsightContainer } from '../../container/product-insight.container'

import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { ProductInsightWrapperLayout } from '../../layout/product-insight-wrapper.layout'
import { TableComponent } from './table/table.component'
import { StackedChartComponent } from './chart/stacked-chart.component'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'
import ImpactSelectorDialog from '../../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'
import { store } from '../../../../configureStore'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'

export const InsightComponent = () => {
  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const productMutator = ProductMutationSelector.productMutator(state)
  const selectedProductId = SelectedProductSelector.productId(state)

  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { selectedInventoryViewType } = useContext<TProductDetailContext>(ProductDetailContext)
  const { primaryLeafItemsRefetch, secondaryLeafItemsRefetch } = useContext<TAnalysisContext>(AnalysisContext)
  const isInsightViewType = (viewType: InventoryViewType) => viewType === selectedInventoryViewType
  useEffect(() => {
    if (space) {
      reloadLeafItems()
    }
  }, [ space ])

  const reloadLeafItems = () => {
    primaryLeafItemsRefetch && primaryLeafItemsRefetch()
    secondaryLeafItemsRefetch && secondaryLeafItemsRefetch()
  }

  return (
    <>
      <ProductInsightContainer>
        { isInsightViewType(InventoryViewType.FlatView) && (
          <ProductInsightWrapperLayout>
            <TableComponent />
          </ProductInsightWrapperLayout>
        ) }

        { isInsightViewType(InventoryViewType.StackedChart) && (
          <ProductInsightWrapperLayout>
            <StackedChartComponent />
          </ProductInsightWrapperLayout>
        )}

        {/* // TODO : From v0.9 : To be refactored */}
        { selectedProductId && <ImpactSelectorDialog productMutator={productMutator} updatePageItems={reloadLeafItems} /> }
      </ProductInsightContainer>
    </>
  )
}
