import {
  SET_IS_CREATE_LIFECYCLE,
  SET_IS_CREATE_PHASE,
  SET_IS_SKIP_PHASES,
  SET_IS_ORDERING_PHASES,
  SET_IS_FORCING_WORKSPACE_PANEL,
  SET_IS_SIDEBAR_OPEN,
  SET_IS_ADBLOCK_ENABLED,
  SET_IS_SHOW_ADBLOCK_POPUP,
  SET_IS_SHOW_LIFECYCLE_SCENARIO_INVENTORY,
  SET_IS_CREATE_PRODUCT_DIALOG_SHOWED,
  SET_IS_CREATE_LIFECYCLE_DIALOG_SHOWED,
  SET_IS_IMPACT_SELECTOR_MODAL_SHOWED,
  SET_IS_CREATE_FOLDER_DIALOG_SHOWED,
  SET_IS_MOVE_ITEM_DIALOG_SHOWED,
  SET_IS_LOADING_SETTING_COMPLETED,
  SET_IS_DETAIL_PANEL_READONLY_V1
} from '../actions/flags.actions'
import { CLEAR_FLAGS_STATE, CLEAR_INVENTORY_TREE_STATE, CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE } from '../actions/clear.actions'

const defaultFlagsState = {
  isDetailsPanelOpen: false,
  isCreateNewInventoryItem: false,
  isShowInventory: false,
  isDataViaNetworkRequesting: false,
  isInventoryCompare: false,
  isShowProductScenarioInventory: false,
  isShowLifecycleScenarioInventory: false,
  isProductFiltersInSearchMode: false,
  isCreateLifecycle: false,
  isCreatePhase: false,
  isSkipPhases: false,
  setIsForcingWorkspacePanel: false,
  isOrderingPhases: false,
  isSidebarOpen: true,
  isAdBlockEnabled: false,
  isShowAdBlockPopup: true,
  isShowCreateProductDialog: false,
  isShowCreateFolderDialog: false,
  isCreateLifecycleDialogShowed: false,
  isSearchReferenceModalShowed: false,
  isMoveItemDialogShowed: false,
  isLoadingSettingCompleted: false,
  isForcingWorkspacePanel: false
}

const flagsReducer = ( state = defaultFlagsState, action = {} ) => {
  switch ( action.type ) {
  case CLEAR_FLAGS_STATE:
    return {
      ...defaultFlagsState,
      isAdBlockEnabled: state.isAdBlockEnabled,
      isShowAdBlockPopup: state.isShowAdBlockPopup
    }
  case 'SET_IS_DETAILS_PANEL_OPEN':
    return { ...state, isDetailsPanelOpen: action.isDetailsPanelOpen }
  case 'SET_IS_CREATE_NEW_INVENTORY_ITEM':
    return { ...state, isCreateNewInventoryItem: action.status }
  case 'SET_IS_SHOW_INVENTORY':
    return { ...state, isShowInventory: action.status }
  case 'SET_IS_DATA_VIA_NETWORK_REQUESTING':
    return { ...state, isDataViaNetworkRequesting: action.status }
  case 'SET_IS_SHOW_PRODUCT_SCENARIO_INVENTORY':
    return { ...state, isShowProductScenarioInventory: action.status }
  case SET_IS_SHOW_LIFECYCLE_SCENARIO_INVENTORY:
    return { ...state, isShowLifecycleScenarioInventory: action.status }
  case 'SET_IS_PRODUCT_FILTERS_IN_SEARCH_MODE':
    return { ...state, isProductFiltersInSearchMode: action.status }
  case SET_IS_CREATE_LIFECYCLE:
    return { ...state, isCreateLifecycle: action.status }
  case SET_IS_SKIP_PHASES:
    return { ...state, isSkipPhases: action.status }
  case SET_IS_CREATE_PHASE:
    return { ...state, isCreatePhase: action.status }
  case SET_IS_FORCING_WORKSPACE_PANEL:
    return { ...state, isForcingWorkspacePanel: action.status }
  case SET_IS_ORDERING_PHASES:
    return { ...state, isOrderingPhases: action.status }
  case SET_IS_SIDEBAR_OPEN:
    return { ...state, isSidebarOpen: action.status }
  case SET_IS_DETAIL_PANEL_READONLY_V1:
    return { ...state, isDetailPanelReadonlyV1: action.status }
  case SET_IS_ADBLOCK_ENABLED:
    return { ...state, isAdBlockEnabled: action.status }
  case SET_IS_SHOW_ADBLOCK_POPUP:
    return { ...state, isShowAdBlockPopup: action.status }
  case SET_IS_CREATE_PRODUCT_DIALOG_SHOWED:
    return { ...state, isShowCreateProductDialog: action.status }
  case SET_IS_CREATE_FOLDER_DIALOG_SHOWED:
    return { ...state, isShowCreateFolderDialog: action.status }
  case SET_IS_MOVE_ITEM_DIALOG_SHOWED:
    return { ...state, isMoveItemDialogShowed: action.status }
  case SET_IS_LOADING_SETTING_COMPLETED:
    return { ...state, isLoadingSettingCompleted: action.status }
  case SET_IS_CREATE_LIFECYCLE_DIALOG_SHOWED:
    return { ...state, isCreateLifecycleDialogShowed: action.status }
  case SET_IS_IMPACT_SELECTOR_MODAL_SHOWED:
    return { ...state, isSearchReferenceModalShowed: action.status }
  case CLEAR_INVENTORY_TREE_STATE:
    return {
      ...state,
      isShowProductScenarioInventory: false,
      isCreateNewInventoryItem: false,
      isCreateLifecycle: false,
      isDetailsPanelOpen: false
    }
  case CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE:
    return {
      ...state,
      isCreateNewInventoryItem: false,
      isCreateLifecycle: false,
      isDetailsPanelOpen: false,
      isForcingWorkspacePanel: false
    }
  default:
    return state
  }
}

export default flagsReducer
