import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { SEARCH_DATASETS, SEARCH_ELEMENTARY_FLOWS } from '../../graphql/query/reference'
import { ReferenceProductType } from '../enum/reference-product'
import {
  SearchType, FilterDatasetType, Facet,
  FilterElementaryFlowType
} from '../../../__generated__/graphql'
import { PAGINATION } from '../../../utils/const'
import { getFacetsLists, updateFacetsListsCounts } from '../util/facets'

type TImpactDatasetContainerProps = {
  type: ReferenceProductType
  impactMethodId: string
  children?: React.ReactNode
}
export const ImpactDatasetContainer = ({
  type,
  impactMethodId,
  children
}: TImpactDatasetContainerProps) => {
  const {
    query = '',
    selectedDatabases = [],
    selectedLocations = [],
    selectedProperties = [],
    selectedFlowProperties = [],
    selectedSortBy,
    selectedSortOrder,
    categoryFilterItems = [],
    currentPage = 1,
    databaseList,
    locationList,
    propertyList,
    updateImpactDataset,
    makeInitDatasetsCall,
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  let categoryId = ''
  if (categoryFilterItems.length > 1) {
    categoryId = categoryFilterItems[categoryFilterItems.length - 1].parentCategory?.id || ''
  }

  const handleFetchedData = (dataResults: any) => {
    if (makeInitDatasetsCall) {
      const { facets = [] } = dataResults || {}
      const facetsLists = getFacetsLists(facets as Facet[])
      updateImpactDataset({
        ...facetsLists,
        makeInitDatasetsCall: false
      })
    } else {
      const hasActiveColumnFilter = selectedLocations.length > 0 || selectedDatabases.length > 0 || selectedProperties.length > 0
      const hasActiveCategoryFilter = categoryFilterItems.length > 1 || !!categoryFilterItems[0]?.selectedCategory
      const sideOrTopFilterEnabled = hasActiveCategoryFilter || hasActiveColumnFilter
      const searchOrFilterEnabled = sideOrTopFilterEnabled || !!query
      const items = dataResults?.items || []
      const totalItems = dataResults?.pagination?.totalResults || 0

      const updatedFacetsLists = updateFacetsListsCounts(dataResults?.facets, databaseList, locationList, propertyList)
      updateImpactDataset({
        ...updatedFacetsLists,
        referenceProducts: searchOrFilterEnabled ? items : [],
        totalItems: searchOrFilterEnabled ? totalItems : 0
      })
    }
  }

  const initVariables = {
    query: '',
    impactMethodId,
    categoryId: '',
    pagination: {
      current: 1,
      size: PAGINATION.PAGE_SIZE,
    },
    searchType: SearchType.Exact,
    properties: [],
  }

  const sharedVariables = {
    query,
    impactMethodId,
    categoryId,
    pagination: {
      current: currentPage,
      size: PAGINATION.PAGE_SIZE,
    },
    searchType: SearchType.Exact,
  }

  const getDatasetProperties = () => {
    const datasetProperties = [
      {
        name: FilterDatasetType.Database,
        values: selectedDatabases
      },
      {
        name: FilterDatasetType.Location,
        values: selectedLocations
      },
      {
        name: FilterDatasetType.FlowProperty,
        values: selectedProperties
      }
    ]
    return datasetProperties
  }

  const getSortBy = () => {
    if (!selectedSortOrder || !selectedSortBy) {
      return
    }

    return { field: selectedSortBy, order: selectedSortOrder }
  }

  const datasetVariables = {
    ...sharedVariables,
    properties: getDatasetProperties(),
    sortBy: getSortBy(),
  }
  const {
    loading: loadingSearchDatasets,
    data: searchDatasetsData,
  } = useQuery(SEARCH_DATASETS, {
    skip: type !== ReferenceProductType.ProductSystem,
    variables: makeInitDatasetsCall ? initVariables : datasetVariables,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingSearchDatasets && searchDatasetsData) {
      handleFetchedData(searchDatasetsData?.searchDatasets)
    }
  }, [ loadingSearchDatasets, searchDatasetsData ])

  const elelmentaryFlowVariables = {
    ...sharedVariables,
    properties: [
      {
        name: FilterElementaryFlowType.FlowProperty,
        values: selectedFlowProperties
      }
    ],
    sortBy: getSortBy(),
  }

  const {
    loading: loadingElementaryFlows,
    data: searchElementaryFlowsData
  } = useQuery(SEARCH_ELEMENTARY_FLOWS, {
    skip: type !== ReferenceProductType.ElementaryFlow,
    variables: makeInitDatasetsCall ? initVariables : elelmentaryFlowVariables,
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!loadingElementaryFlows && searchElementaryFlowsData) {
      handleFetchedData(searchElementaryFlowsData?.searchElementaryFlows)
    }
  }, [ loadingElementaryFlows, searchDatasetsData ])

  return (
    <>
      { children }
    </>
  )
}
