import { connect } from 'react-redux'
import { getComparisonEntitiesSaga } from '../../../../redux/sagas/comparison.saga'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'
import { inventoryRootItemIdSelector } from '../../../../redux/selectors/inventoryTree.selector'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'
import AccountSelector from '../../../../redux/selectors/account.selector'
import CompareDropdown from './compareDropdown'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'

const mapStateToProps = state => {
  return ({
    accountId: AccountSelector.selectedAccountId(state),
    comparisonEntries: ComparisonSelector.entities(state),
    entityToCompare: ComparisonSelector.entityToCompare(state),
    isComparisonEntriesLoading: ComparisonSelector.isComparisonEntriesLoading(state),
    inventoryRootItemId: inventoryRootItemIdSelector(state),
    selectedProductId: SelectedProductSelector.productId(state),
    selectedProduct: SelectedProductSelector.product(state),
    selectedLifecycleId: LifecycleSelector.lifecycleId(state),
    selectedLifecycle: LifecycleSelector.selectedLifecycle(state)
  })
}

const mapDispatchToProps = {
  getComparisonEntitiesSaga,
  trackEventSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareDropdown)
