import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

type TRecentLifeCycleHeaderComponentProps = {
  navigateToLifeCycle: () => void
  openCreateLifeCycleDialog: () => void
}
export const RecentLifeCycleHeaderComponent = ({
  navigateToLifeCycle,
  openCreateLifeCycleDialog
}: TRecentLifeCycleHeaderComponentProps) => {
  const { t } = useTranslation([ 'workspace', 'common' ])

  return (
    <>
      <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
        <h2
          className="text-2xl text-gray-800 font-semibold line-height-2 p-0"
          data-testid="recent-lifeCycles-header"
        >
          { t('labels.recent', { context: 'lifeCycles' }) }
        </h2>
      </div>
      <div className="flex-none">
        <Button onClick={navigateToLifeCycle} data-testid="recent-lifeCycles-viewAll" label={t('labels.view', { context: 'all', ns: 'common' })} className="p-button-plain p-button-text text-sm h-2rem mx-1" />
        <Button onClick={openCreateLifeCycleDialog} data-cy="create-new-lifecycle" data-testid="recent-lifeCycles-create" icon="pi pi-plus" className="p-button-outlined p-button-plain w-2rem h-2rem ml-1" />
      </div>
    </>
  )
}
