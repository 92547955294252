import React, { useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { Checkbox } from 'primereact/checkbox'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'

import { useTranslation } from 'react-i18next'
import { EComparison } from '../../enum/comparison'

const FlatViewFilter = styled.div`
  width: fit-content;

  .max-value{
    border-radius: 0.125rem;
    border: 1px solid #fff;
    padding: 0.125rem 0.5rem;

    &:hover {
      cursor: pointer;
      border: 1px solid var(--gray-100);
    }
  }

  .item-amount-input {
    padding: 0 0.5rem;
    text-align: center;
    font-size: 12px;
    width: 2rem;
    height: 1.25rem;
  }

  form {
    font-size: 0.75rem;
  }

  .p-dropdown-label.p-inputtext {
    padding: 0;
    height: 1.2rem;
    width: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--gray-500);
  }

  .p-dropdown-trigger {
    width: 1rem;
  }

  .p-dropdown-trigger-icon {
    font-size: 0.5rem;
  }

  .p-checkbox-box {
    width: 1rem;
    height: 1rem;
  }
`

const GlobalStyle = createGlobalStyle`
  .p-dropdown-items {
    padding: 1rem !important;

    .p-dropdown-item {
      padding: 0.5rem !important;
    }
  }
`

const INPUT_FILTER_DEFAULT_VALUE = 1
const INPUT_FILTER_MAX_VALUE = 99

type TFlatViewFilterProps = {
  enableFilter: boolean,
  setEnableFilter: Function,
  inputFilterAmount: number,
  setInputFilterAmount: Function,
  filterComparison: EComparison,
  setFilterComparison: Function
}

export const FlatViewFilterComponent = ({
  enableFilter,
  inputFilterAmount,
  setEnableFilter,
  setInputFilterAmount,
  filterComparison,
  setFilterComparison
}: TFlatViewFilterProps) => {
  const { t } = useTranslation([ 'product' ])
  const [ editFilterAmountMode, setEditFilterAmountMode ] = useState<boolean>(false)

  const filterComparisonList = [
    { value: EComparison.LessThan, label: '<', textLabel: t('labels.optionFilter', { context: 'smaller' }) },
    { value: EComparison.GreaterThan, label: '>', textLabel: t('labels.optionFilter', { context: 'larger' }) }
  ]

  const onInputAmountBlur = (e: any) => {
    const { value } = e.target
    if (!value) {
      setInputFilterAmount(INPUT_FILTER_DEFAULT_VALUE)
      setEnableFilter(false)
    }
    setEditFilterAmountMode(false)
  }

  const onInputChange = (event: any) => {
    const value = event.value > INPUT_FILTER_MAX_VALUE ? INPUT_FILTER_MAX_VALUE : event.value
    setInputFilterAmount(value)
  }

  const onComparisonChange = (e: any) => {
    setFilterComparison(e.value)

    setEnableFilter(true)
  }

  const onViewAmountClick = () => {
    setEditFilterAmountMode(true)
    setEnableFilter(true)
  }

  const filterOptionTemplate = (option: any) => (
    <div className="flex flex-none align-items-center w-full gap-2 text-sm">
      <div className="flex flex-none align-items-center">{ option.label }</div>
      <div className="flex flex-grow-1 align-items-center text-sm">{ option.textLabel }</div>
    </div>
  )

  return (
    <>
      <GlobalStyle />
      <FlatViewFilter className="flex align-items-center h-2rem px-2 border-gray-100 border-1 border-round-sm text-gray-500">
        <div className="flex flex-none align-items-center gap-1">
          <Checkbox data-testid="flat-view-enable-filter" className="mt-1" inputId="enable-filter" onChange={() => setEnableFilter(!enableFilter)} checked={enableFilter} />
          <div className="flex text-sm font-normal text-gray-500 line-height-1">
            { t('labels.display') }
          </div>
        </div>
        <div className="flex flex-none align-items-center gap-1 pl-2 h-full">
          <div className="flex text-sm">
            <Dropdown data-testid="flat-view-select-comparison" value={filterComparison} options={filterComparisonList} onChange={onComparisonChange} itemTemplate={filterOptionTemplate} />
          </div>
          { !editFilterAmountMode && (
            <div
              data-testid="flat-view-amount_viewMode"
              className="flex text-sm px-2 max-value line-height-1 w-2rem text-center"
              onClick={onViewAmountClick}
            >
              { inputFilterAmount }
            </div>
          )}
          { editFilterAmountMode && (
            <div data-testid="flat-view-amount_editMode" className="flex flex-none align-items-center justify-content-center">
              <InputNumber
                autoFocus
                data-testid="flat-view-input"
                inputClassName="item-amount-input"
                value={inputFilterAmount}
                onBlur={onInputAmountBlur}
                onChange={onInputChange}
                mode="decimal"
                max={INPUT_FILTER_MAX_VALUE}
                size={2}
              />
            </div>
          ) }
          <div data-testid="flat-view-percent" className="flex text-sm">%</div>
        </div>
      </FlatViewFilter>
    </>
  )
}
