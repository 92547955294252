import React, { useContext } from 'react'
import styled from 'styled-components'

import { Checkbox } from 'primereact/checkbox'

import { useTranslation } from 'react-i18next'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'

const HideImpactBarWrapper = styled.div`
  width: fit-content;

  .p-checkbox-box {
    width: 1rem;
    height: 1rem;
  }
`

export const HideImpactBarComponent = () => {
  const { hiddenImpactBar = false, updateAnalysis } = useContext<TAnalysisContext>(AnalysisContext)
  const { t } = useTranslation([ 'analysis' ])

  return (
    <HideImpactBarWrapper className="flex align-items-center h-2rem px-2 border-gray-100 border-1 border-round-sm text-gray-500">
      <div className="flex flex-none align-items-center gap-1">
        <Checkbox
          data-testid="hide-impact-bar-filter"
          data-cy="hide-impact-bar-filter"
          className="mt-1"
          inputId="hide-impact-bar"
          onChange={() => updateAnalysis({ hiddenImpactBar: !hiddenImpactBar })}
          checked={hiddenImpactBar}
        />
        <div className="flex text-sm font-normal text-gray-500 line-height-1">
          { t('labels.hide', { context: 'impactBar' }) }
        </div>
      </div>
    </HideImpactBarWrapper>
  )
}
