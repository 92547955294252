import { Typography, Tag } from 'antd'
import React from 'react'
import classnames from 'classnames'
import CropLongText from '../../../helpers/cropLongText'

const { Paragraph } = Typography

const InventoryNameLabel  = props => {
  const {
    className,
    'data-cy': dataCy,
    onClick,
    text,
    tag,
    isPrimaryTag
  } = props

  return <div
    data-cy={dataCy}
    onClick={onClick}
    className={`inventory-name-label__container ${className || ''}`}>
    <Paragraph
      strong
      className="inventory-name-label"
    >
      <CropLongText
        tooltipText={text}
        className={ classnames('inventory-name-label__text', { 'inventory-name-label__text--with-tag': tag }) }
      >
        {text}
      </CropLongText>
      {tag && <Tag
        className={ classnames('inventory-name-label__tag', { 'inventory-name-label__tag--primary': isPrimaryTag }) }
      >
        {tag}
      </Tag>}
    </Paragraph>
  </div>
}

export default InventoryNameLabel
