import { gql } from '@apollo/client'

export default gql`
  mutation changePermission($permissionID: ID!, $permitteeID: ID!, $policies: [ID!]) {
    changePermission(permissionID: $permissionID, permitteeID: $permitteeID, policies: $policies) {
      id
      who{
        id
        name
      }
      what{
        id
        type
      }
      how{
        id
      }
    }
  }
`
