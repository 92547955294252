import React, { Fragment } from 'react'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { Button, Tooltip } from 'antd'
import AddInventoryItem from '../add-inventory-item/addInventoryItem.container'
import ChangePhase from '../../lifecycle/change-phase/changePhase.container'
import CreatePhase from '../../lifecycle/create-phase/createPhase.container'
import { isEmpty, returnNested, handleModal } from '../../../../utils/tools'
import { ProductDetailsMutations } from '../../../../utils/ProductMutator'
import { withRouter } from '../../../../utils/with-router'
import DetailsPanelTitle from '../detailsPanelTitle'
import { DetailsPanelCard } from '../detailPanelCard'

const PhaseDetails = props => {
  const {
    setIsCreateNewInventoryItem,
    setIsCreatePhaseAction,
    setIsCreateLifecycleAction,
    clearUiWhenDetailPanelCloseAction,
    updatePageItems,
    selectedPhase,
    removePhaseSaga,
    lifecycle,
    isCreateNewInventoryItem,
    isCreatePhase,
    isDetailsPanelReadOnly,
    t
  } = props

  const updatePageItemsHook = (showCreateNewInventory = false) => {
    setIsCreateNewInventoryItem(showCreateNewInventory)
    setIsCreatePhaseAction(false)
    setIsCreateLifecycleAction(false)
    updatePageItems()
    if (!showCreateNewInventory) {
      clearUiWhenDetailPanelCloseAction()
    }
  }

  const handleRemovePhase = () => {
    handleModal({
      title: t('model.confirmRemoveTitle', { context: 'phase', name: selectedPhase.name }),
      content: t('model.confirmRemoveMsg', { context: 'phase', name: selectedPhase.name }),
      onOk: () => {
        removePhaseSaga(returnNested(selectedPhase, 'id'))
      }
    })
  }

  const getContent = () => {
    if (isCreateNewInventoryItem) {
      return <Fragment>
        <DetailsPanelTitle
          title={`${returnNested(selectedPhase, 'name')} ${t('global.phase')}`}
          className="phase-title"
          data-cy="selected-phase-name"
        />
        <div className='details-panel-body'>
          <div className='panel-section no-collapse'>
            <AddInventoryItem
              inventoryTree={ selectedPhase.inventory }
              isPhaseSelected={selectedPhase ? true : false}
              productMutator={ new ProductDetailsMutations() }
              selectedProductId={ returnNested(selectedPhase, 'inventory', 'product', 'id') }
              updatePageItems={ updatePageItemsHook }
              handleCancel={ () => {
                setIsCreateNewInventoryItem(false)
              } }
            />
          </div>
        </div>
      </Fragment>
    } else if (isCreatePhase) {
      return <Fragment>
        <DetailsPanelTitle title={t('model.create_phase')} />
        <div className='details-panel-body'>
          <CreatePhase
            lifecycle={ lifecycle }
            handleCancel={ () => { updatePageItemsHook() } }
          />
        </div>
      </Fragment>
    }
    return (
      <div className="details-panel-wrapper">
        <DetailsPanelTitle title={`${returnNested(selectedPhase, 'name')} ${t('global.phase')}`} className="phase-title" data-cy="selected-phase-name" />
        <div className='details-panel-body'>
          <DetailsPanelCard
            title={t('model.phase_details')}
            action={
              !isDetailsPanelReadOnly &&
              <React.Fragment>
                <Tooltip placement="left" title={t('model.addSubItem', { context: 'phase' })}>
                  <Button
                    data-cy="add-new-tree-item"
                    className="button gutter"
                    onClick={ () => {
                      setIsCreateNewInventoryItem(true)
                    } }
                  >
                    <AiOutlinePlus size={20} />
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title={t('model.remove', { context: 'phase' })}>
                  <Button
                    data-cy="delete-phase-button"
                    className="delete button"
                    onClick={ handleRemovePhase }>
                    <AiOutlineDelete size={20} />
                  </Button>
                </Tooltip>
              </React.Fragment>
            }>
            <ChangePhase
              isReadOnly={isDetailsPanelReadOnly}
              key={returnNested(selectedPhase, 'id')}
            />
          </DetailsPanelCard>
        </div>
      </div>
    )

  }

  if ( isEmpty(selectedPhase) && !isCreatePhase) return null
  return (
    <div className="details-panel-wrapper">
      { getContent() }
    </div>
  )

}

export default compose(
  withTranslation(),
  withRouter
)(PhaseDetails)
