import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation renameProduct($productID: ID!, $name: String!) {
    renameProduct(productID: $productID, name: $name) {
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
