import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation removeProductReference($productID: ID!) {
    removeProductReference(productID: $productID){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
