import { gql } from '../../../__generated__'

export const USER = gql(`
  query user {
    user {
      id
      name
      email
      intercomUserHash
      accounts {
        id
        name
        hasValidSubscription
        subscriptionId
        subscriptionStatus,
        mayTrialBeStarted,
        subscriptionSourcedFrom,
        v2ApiCredentials {
          key
          secret
        }
        usage {
            type
            count
            limit
        }
        trialStart
        trialEnd
        isTrialing
        createdAt
        spaces {
          id
          name
          slug
        }
        subscription {
          startAt
          cancelAt
          canceledAt
          plan {
            name
            lookupKey
          }
        }
      }
    }
  }
`)

