import { gql } from '@apollo/client'

export default gql`
  mutation addUser($accountID: ID!, $email: String!, $name: String!, $roleIDs: [String!]!) {
    addUser(accountID: $accountID, email: $email, name: $name, roleIDs: $roleIDs){
      id
      name
      email
      roles(accountID: $accountID){
        id
      }
    }
  }
`
