import { gql } from '@apollo/client'

export default gql`
  fragment invoiceFragment on Invoice {
      id
      amountDue
      amountPaid
      amountTotal
      description
      status
      currency
      hostedURL
      pdfURL
      periodStart
      periodEnd
  }
`
