import React from 'react'
import styled from 'styled-components'
import { normalize } from '../../../../utils/tools'

type MenuItemWrapperProps = {
  readonly textColor?: string,
  readonly disabled?: boolean
}

const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  color: var(${(props: any) => (props.disabled ? '--gray-300' : props.textColor)});

  :hover {
    background: ${(props: any) => (props.disabled ? 'none' : 'var(--gray-50)')};
    cursor: ${(props: any) => (props.disabled ? 'default' : 'pointer')};
  }
`

export const MenuItemComponent = (item: any, options:any) => {
  const {
    disabled = false, icon, label, textColor = '--gray-500'
  } = item
  return (
    <MenuItemWrapper disabled={disabled} textColor={textColor} onClick={options.onClick} className="flex flex-none w-full p-2 menu-item-wrapper">
      { icon && <div data-testid="header-item-icon" className="flex align-items-center justify-content-center w-1rem mr-2">{ icon }</div>}
      <div data-testid="header-item-label" data-cy={normalize(label)} className="flex flex-grow-1 text-sm line-height-1">{ label }</div>
    </MenuItemWrapper>
  )
}
