import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeProductUnit($productID: ID!, $unit: String!) {
    changeProductUnit(productID: $productID, unit: $unit){
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
