import moment from 'moment'

export const castToJoinedAlphaNumericString = (value?: string | null, joinedBy = '-') => (value ? value.replace(/[^A-Z0-9_]+/ig, joinedBy) : '')

export const isValid = (value?: any): boolean => value !== undefined && value !== null

export const parseJsonString = (value?: any) => {
  try {
    if (!isValid(value)) {
      return false
    }
    return JSON.parse(value)
  } catch (error) {
    return false
  }
}

export const getBrowserTimezone = () => (Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'Europe/Amsterdam')

type TGetDownloadFilename = {
  value: string,
  extension: string,
  prefix?: string,
  addDate?: boolean
}
export const getDownloadFilename = ({
  value = '',
  extension = '',
  prefix = '',
  addDate = true
}: TGetDownloadFilename) => {
  const filenameDateTime = addDate ? `_${moment().format('DD-MM-YYYY HH:mm')}` : ''
  const filenamePrefix = prefix || ''
  const maxLength = 255 - (filenameDateTime + filenamePrefix).length
  const safeFilename = value.replace(/[/\\?%*:|"<>]/g, '-').slice(0, maxLength)
  return `${filenamePrefix + safeFilename + filenameDateTime}.${extension}`
}
