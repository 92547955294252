import { gql } from '@apollo/client'

const space = gql`
  fragment spaceFragment on Space {
      id
      name
      slug
      permissions {
          id
          who {
              id
              name
          }
          what {
              id
          }
          how {
              id
          }
          inherited
      }
      permissionsInherited
      impactMethod{
        id
        name
        impactMethodSetID
        description
      }
      nwSet{
        id
        type
        name
        unit
      }
      impactCategory{
        id
        impactMethodID
        name
        unit
      }
      excludeLT
      useMethodTotal
  }
`

export default space
