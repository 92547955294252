import { gql } from '@apollo/client'

export default gql`
  mutation startCheckoutSetup($accountID: ID!, $successURL: String!, $cancelURL: String!) {
    startCheckoutSetup(accountID: $accountID, successURL: $successURL, cancelURL: $cancelURL){
      id
      redirectUrl
    }
  }
`
