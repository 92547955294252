import React, { useContext } from 'react'
import { PdfDoughnutComponent } from '../../../shared/component/general/pdf-doughnut.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

export const ProductPdfDoughnutComponent = () => {
  const {
    productInventory: { product = {} } = {},
    totalImpact = {},
    transformedLeafItems = [],
    updateProductDetail,
    showPdfInsightsResult,
    loadingLeafInventoryItems
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const entity = {
    name: product.name,
    unit: product.unit,
    description: product.description
  }

  return (
    <>
      {
        !loadingLeafInventoryItems && showPdfInsightsResult && (
          <PdfDoughnutComponent
            entity={entity}
            totalImpact={totalImpact}
            transformedLeafItems={transformedLeafItems}
            afterPdfDoughnutDownload={() => updateProductDetail({ showPdfInsightsResult: false })}
          />
        )
      }
    </>
  )
}
