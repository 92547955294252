import React, { Component } from 'react'
import { Switch, Table } from 'antd'
import classnames from 'classnames'
import { EntityType } from '../../../../utils/const'

import { isEmpty, normalize, returnNested } from '../../../../utils/tools'
import InventoryTotalImpact from '../../inventory/inventory-total-impact/inventoryTotalImpact.container'
import { ScientificNotation } from '../../inventory/scientific-notation/scientificNotation'
import ImpactBar from '../impactBar'
import { withTranslation } from 'react-i18next'
import compose from '../../../../utils/compose'

class FlatInventory extends Component {
  state = {
    selectedRowKeys: []
  }

  getColumns  = (impactUnit, showPercentages, skipPhases) => {
    const { t } = this.props
    const columns =  [ {
      title: 'Name',
      dataIndex: 'productName',
      className: 'product-name cy-product-name',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: 'Amount',
      className: 'product-amount cy-product-amount',
      dataIndex: 'productAmount',
      align: 'right',
      sorter: (a, b) => a.productAmount - b.productAmount,
      render: value => <ScientificNotation value={ value }/>
    },
    {
      className: 'product-unit cy-product-unit',
      dataIndex: 'productUnit',
      align: 'left',
    },
    {
      className: 'impact-amount cy-impact-amount',
      dataIndex: 'impactAmount',
      title: t('model.impact_in_unit', { unit: impactUnit }),
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.impactAmount - b.impactAmount,
      render: value => <ScientificNotation value={ value }/>
    },
    {
      title: 'Percentage %',
      dataIndex: 'impactPercentageFromRoot',
      className: 'impact-percentage cy-impact-percentage',
      align: 'right',
      sorter: (a, b) => Math.abs(a.impactAmount) - Math.abs(b.impactAmount),
      render: value => <ScientificNotation value={ value } precision={ 1 } unit="%"/>
    },
    {
      width: '30%',
      className: 'impact-bar cy-impact-bar',
      align: 'right',
      dataIndex: 'relativeImpactPercentage',
      sorter: showPercentages ? (a, b) => Math.abs(a.impactAmount) - Math.abs(b.impactAmount) : null,
      render: (value, record) => {
        const tooltip = <ScientificNotation value={ record.impactAmount } unit={ record.impactUnit }/>
        return <ImpactBar tooltip={ tooltip } percent={ value }/>
      }
    } ]

    if (!skipPhases) {
      columns.splice( 0, 0, {
        title: 'Phase',
        dataIndex: 'phaseName',
        className: 'phase-name cy-phase-name',
        sorter: (a, b) => a.phaseOrder - b.phaseOrder,
      } )
    }
    return columns
  }

  handleShowPhases = checked => {
    const { flatView } = this.props
    const isLifecycle = returnNested(flatView, '__typename') === EntityType.LIFECYCLE
    isLifecycle && this.props.setIsLifecycleSkipPhasesSaga(!checked)
  }

  handleSelectRow = product => {
    const { selectedFlatViewItemSaga } = this.props
    selectedFlatViewItemSaga(product.productId)
    const rowIndexKey = product.productId + product.phaseId
    this.setState({ selectedRowKeys: [ rowIndexKey ] })
  }

  render() {
    const { flatView, skipPhases, t } = this.props
    const { selectedRowKeys } = this.state
    if (isEmpty(flatView)) return null
    const showPhasesSwitch = returnNested(flatView, '__typename') === EntityType.LIFECYCLE
    const rowSelection = {
      type: 'radio',
      selectedRowKeys,
      onChange: record => {
        this.setSelectedProduct(record.productId, record.phaseId)
      },
      columnWidth: 0,
      renderCell: () => '',
    }

    let flatViewItemsWithNoRootItem = (flatView.items && flatView.items.length > 1)  ?
      flatView.items.filter(el => el.productId !== this.props.inventoryTreeSelectorProductId) : flatView.items

    return (
      <React.Fragment>
        <div className="leaf-inventory-container">
          <div className={classnames('leaf-inventory-table', { percentages: true })}>
            <Table
              data-cy="leaf-inventory-table"
              dataSource={flatViewItemsWithNoRootItem}
              pagination={false}
              columns={this.getColumns(returnNested(flatView, 'totalImpact', 'unit'), false, skipPhases)}
              rowClassName={record => `cy-row-for-${normalize(record.productName)}`}
              rowKey={record => record.productId + record.phaseId}
              size="small"
              bordered={true}
              rowSelection={rowSelection}
              onRow={record => {
                return {
                  onClick: () => { this.handleSelectRow(record) }
                }
              }}
            />
          </div>
          <div className="switches-container">
            { showPhasesSwitch &&
            <div className="phases-switch switch" data-cy="leaf-inventory-show-phases-switch">
              { t('model.show_phases') } <Switch
                onChange={ this.handleShowPhases }
                size={ 'small' }
                checked={ !skipPhases }
                checkedChildren={ t('global.on') }
                unCheckedChildren={ t('global.off') }
              />
            </div>
            }
          </div>
        </div>
        <div>
          <InventoryTotalImpact
            impact={ returnNested(flatView, 'totalImpact') }
            isCarbonTranslatorShowed={false}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation()
)(FlatInventory)
