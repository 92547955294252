import { useReactiveVar } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'

import { store } from '../../../configureStore'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { ILifecycle, IProduct } from '../../model'
import { CreateProductLifeCycleContainer } from '../../shared/container/create-product-lifecycle.container'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { EntityType } from '../../shared/enum/entity-type'
import { TrackEventType } from '../../shared/enum/track-events'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import { CreateScenarioContainer } from '../container/create-scenario.container'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'

import { LifecycleDetailLayout } from '../layout/lifecycle-detail.layout'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { LifecycleDetailDialogComponent } from './dialog/lifecycle-detail-dialog.component'
import { CreateLifeCycleContainer } from '../../shared/container/create-lifecycle.container'
import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { getSegmentTrack } from '../../shared/util/segment'
import { UpdateProductPropertyContainer } from '../../shared/container/update-product-property.container'
import { Navigation } from '../../shared/enum'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { AnalysisType } from '../../analysis/enum/analysis'

export const LifecycleDetailComponent = () => {
  const navigate = useNavigate()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const {
    showCreateComparisonDialog,
    showCreateLifeCycleFromProduct,
    showCreateLifeCycleDialog,
    showUpdatePropertyDialog = false,
    addToBackToList = () => {}
  } = useContext<IDashboardContext>(DashboardContext)

  const {
    lifecycleDetail = {},
    showCreateScenarioDialog = false,
    updateLifecycleDetail,
    refetchLifecycle,
    showLifecycleDetailPanelOnly
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false
  const enableLegacyLifecycleComparison = isFeatureEnabled(Feature.LegacyLifecycleComparisonScenario) || false

  const { id: accountId = null } = account || {}
  const { slug: workspaceSlug = null } = space || {}
  const { id: lifecycleId = null, name: lifecycleName = null } = lifecycleDetail || {}

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  const isForcingWorkspacePanel = FlagsSelector.isForcingWorkspacePanel(state)
  useEffect(() => {
    if (!isDetailsPanelOpen) {
      updateLifecycleDetail({ selectedPhase: null, showLifecycleDetailPanelOnly: false })
    }
  }, [ isDetailsPanelOpen ])

  const [ trackEventInSegment ] = getSegmentTrack()

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    if (!accountId || !workspaceSlug || !lifecycleId) return

    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    if (!enableLegacyLifecycleComparison) {
      const pathname = generatePath(Navigation.LifeCycleComparison, { accountId, workspaceSlug, lifecycleId })
      navigate({ pathname, search: `activeTabKey=${InventoryViewType.Model}&type=${comparisonEntity.__typename}&id=${comparisonEntity.id}` })
    } else {
      const activeTabKey = comparisonEntity.__typename === EntityType.Product ? 'tab-flat-view' : 'tab-tree-view'
      navigate(`/account/${accountId}/workspace/${workspaceSlug}/lifecycle/${lifecycleId}/inventory/?`
        + `activeTabKey=${activeTabKey}&compareEntityType=${comparisonEntity.__typename}&compareEntityId=${comparisonEntity.id}`)
    }
  }

  const afterCreateScenario = () => {
    trackEventInSegment(TrackEventType.CREATED_LIFECYCLE_SCENARIO)
  }

  const openDetailAfterCreate = (id: string) => {
    trackEventInSegment(TrackEventType.VIEWED_LIFECYCLE)
    lifecycleName && addToBackToList(lifecycleName)
    account?.id && space?.slug
      && navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/${!enableLegacyLifecycleInventory ? 'detail' : 'inventory'}/`)
  }

  const reloadLifecycleDetail = () => {
    refetchLifecycle && refetchLifecycle()
    updateLifecycleDetail({ selectedPhase: null, showLifecycleDetailPanelOnly: false })

    store.dispatch(setIsDetailsPanelOpenAction(false))
  }

  return (
    <>
      <LifecycleDetailLayout />

      {/* // TODO : From v0.9 : To be refactored */}
      { (showLifecycleDetailPanelOnly || isForcingWorkspacePanel)
      && <LifecycleDetailDialogComponent reload={reloadLifecycleDetail} /> }

      { showCreateLifeCycleDialog && <CreateLifeCycleContainer afterCreateLifeCycle={openDetailAfterCreate} /> }

      { showCreateLifeCycleFromProduct && <CreateProductLifeCycleContainer product={lifecycleDetail?.product} /> }

      { showCreateScenarioDialog
      && (
        <CreateScenarioContainer
          selectedLifecycle={lifecycleDetail}
          updateLifecycle={updateLifecycleDetail}
          showCreateScenarioDialog={showCreateScenarioDialog}
          afterCreateScenario={afterCreateScenario}
        />
      )}

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} analysisType={AnalysisType.LifeCycleToLifeCycle} /> }

      {showUpdatePropertyDialog && <UpdateProductPropertyContainer onUpdateCallback={updateLifecycleDetail} />}

    </>
  )
}
