import { gql } from '@apollo/client'

export default gql`
  query nwSets($methodID: ID!){
    nwSets(methodID: $methodID) {
      id
      type
      name
      unit
    }
  }
`
