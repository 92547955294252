import React from 'react'
import { Form, Select } from 'antd'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import { returnNested } from '../../../../utils/tools'
import InputWrapper from '../../../helpers/form/inputWrapper'
import PhaseType from '../../lifecycle/phaseType'

class ChangePhase extends React.PureComponent {
  state = {
    isFieldEditable: false
  }
  handleRenamePhase = () => {
    const { selectedPhase, renamePhaseSaga } = this.props
    const { getFieldValue, validateFields } = this.formRef.current

    if (getFieldValue('phaseName') === returnNested(selectedPhase, 'name')) return this.resetFormFields()

    validateFields().then(values => {
      renamePhaseSaga(returnNested(selectedPhase, 'id'), values.phaseName)
      this.resetFormFields()
    })
  }

  resetFormFields = () => {
    this.formRef.current.resetFields()
    this.setState({ isFieldEditable: false })
  }

  formRef = React.createRef()

  handleChangePhaseType = phaseType => {
    const { selectedPhase, changePhaseTypeSaga } = this.props
    changePhaseTypeSaga(returnNested(selectedPhase, 'id'), phaseType)
  }

  render() {
    const { isFieldEditable } = this.state
    const { selectedPhase, isReadOnly, t } = this.props

    if (!selectedPhase) return null

    return <React.Fragment>
      <Form
        ref={this.formRef}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          phaseName: selectedPhase.name,
          phaseType: selectedPhase.type
        }}
      >
        <InputWrapper
          isFieldEditable={isFieldEditable}
          disabled={isReadOnly}
          dataCy='phase-name'
          displayedText={selectedPhase.name }
          fieldName="phaseName"
          fieldLabel={t('global.name')}
          fieldRules={[
            { required: true, message: t('validation.require_field', { name: 'Phase name' }) }
          ]}
          onPressEnter={this.handleRenamePhase}
          onBlur={this.handleRenamePhase}
          editHandler={() => this.setState({ isFieldEditable: true })}
        />

        <Form.Item
          name="phaseType"
          label={ t('global.type') }
          rules={ [ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Type' }) } ] }
        >
          <Select data-cy='change-phase-type-select-box' onChange={this.handleChangePhaseType}>
            {
              PhaseType.list.map((phase, index) =>
                <Select.Option
                  key={ index }
                  value={ phase.type }>
                  {phase.name}
                </Select.Option>)
            }
          </Select>
        </Form.Item>
      </Form>
    </React.Fragment>
  }
}

export default compose(
  withTranslation(),
  withRouter
)(ChangePhase)
