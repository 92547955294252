export const SET_POSSIBLE_INVENTORY_ITEMS = 'SET_POSSIBLE_INVENTORY_ITEMS'

export const
  RENAME_PRODUCT = 'RENAME_PRODUCT',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT',
  ADD_ACCOUNT_TO_USER = 'ADD_ACCOUNT_TO_USER',
  SET_ACCOUNT_TRIAL_INFO = 'SET_ACCOUNT_TRIAL_INFO',
  SET_ACCOUNT_CUSTOMER = 'SET_ACCOUNT_CUSTOMER',
  SET_FAVORITE_PRODUCTS = 'SET_FAVORITE_PRODUCTS',
  SET_PRODUCT_LABEL = 'SET_PRODUCT_LABEL',
  ADD_PRODUCT_TO_FAVORITE = 'ADD_PRODUCT_TO_FAVORITE',
  REMOVE_PRODUCT_FROM_FAVORITE = 'REMOVE_PRODUCT_FROM_FAVORITE',
  SET_PRODUCT_IMPORT_MODAL_VISIBILITY = 'SET_PRODUCT_IMPORT_MODAL_VISIBILITY',
  SET_PRODUCT_IMPORT_COLUMNS = 'SET_PRODUCT_IMPORT_COLUMNS',
  REMOVE_PRODUCT_REFERENCE = 'REMOVE_PRODUCT_REFERENCE',
  SET_PRODUCT_LIST_SORT_TYPE = 'SET_PRODUCT_LIST_SORT_TYPE',
  ADD_TAG_TO_SELECTED_PRODUCT = 'ADD_TAG_TO_SELECTED_PRODUCT',
  ADD_TAGS_TO_SELECTED_PRODUCT = 'ADD_TAGS_TO_SELECTED_PRODUCT',
  SET_SPACE_PRODUCT_TAG_LIST = 'SET_SPACE_PRODUCT_TAG_LIST',
  SET_PRODUCT_TAGS_TO_PRODUCT_FILTER = 'SET_PRODUCT_TAGS_TO_PRODUCT_FILTER',
  REMOVE_TAG_FROM_SELECTED_PRODUCT = 'REMOVE_TAG_FROM_SELECTED_PRODUCT',
  SET_MODULES = 'SET_MODULES',
  SET_MODULE_TO_PRODUCT = 'SET_MODULE_TO_PRODUCT_ACTION',
  SET_VERSION_NUMBER = 'SET_VERSION_NUMBER',
  SET_AMOUNT_MODAL_PREFERENCE = 'SET_AMOUNT_MODAL_PREFERENCE',
  SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER',
  SET_IMPACT_METHODS = 'SET_IMPACT_METHODS',
  SET_IMPACT_CATEGORIES = 'SET_IMPACT_CATEGORIES',
  SET_NW_SETS = 'SET_NW_SETS'

export const setVersionNumberAction = versionNumber => ({
  type: SET_VERSION_NUMBER,
  versionNumber
})

export const setModuleToProductAction = module => ({
  type: SET_MODULE_TO_PRODUCT,
  module
})

export const setModulesAction = modules => ({
  type: SET_MODULES,
  modules
})

export const removeTagFromSelectedProductAction = tagId => ({
  type: REMOVE_TAG_FROM_SELECTED_PRODUCT,
  tagId
})

export const setSpaceProductTagListAction = spaceProductTagList => ({
  type: SET_SPACE_PRODUCT_TAG_LIST,
  spaceProductTagList
})

export const changeSpaceImpactSettingsAction = (impactMethod, nwSet, impactCategory, excludeLT, useMethodTotal) => ({
  type: 'CHANGE_SPACE_IMPACT_SETTINGS',
  impactMethod, nwSet, impactCategory, excludeLT, useMethodTotal
})

export const addTagToSelectedProductAction = productTag => ({
  type: ADD_TAG_TO_SELECTED_PRODUCT,
  productTag
})

export const addTagsToSelectedProductAction = productTagList => ({
  type: ADD_TAGS_TO_SELECTED_PRODUCT,
  productTagList
})

export const setProductListSortTypeAction = productListSortType => ({
  type: SET_PRODUCT_LIST_SORT_TYPE,
  productListSortType
})

export const setSelectedFolderAction = folder => ({
  type: SET_SELECTED_FOLDER,
  folder
})

export const setFullSpaceProductListAction = list => ({
  type: 'SET_FULL_SPACE_PRODUCT_LIST', list
})

export const setWorkspaceActivePanelsAction = workspaceActivePanels => ({
  type: 'SET_SPACE_ACTIVE_PANELS',
  workspaceActivePanels: workspaceActivePanels
})

export const setSelectedSpaceAction = space => ({
  type: 'SET_SELECTED_SPACE',
  space
})

export const setSelectedSpaceProductsAction = products => ({
  type: 'SET_SELECTED_SPACE_PRODUCTS',
  products
})

export const setSelectedSpaceFoldersAction = folders => ({
  type: 'SET_SELECTED_SPACE_FOLDERS',
  folders
})

export const setSelectedSpaceProcessesAction = processes => ({
  type: 'SET_SELECTED_SPACE_PROCESSES',
  processes
})

export const setSelectedProductAction = product => ({
  type: 'SET_SELECTED_PRODUCT',
  product: product
})

export const renameProductAction = (productId, newName) => ({
  type: RENAME_PRODUCT,
  productId,
  newName
})

export const setSelectedProcessAction = process => ({
  type: 'SET_SELECTED_PROCESS',
  process
})

export const removeProductReferenceAction = () => ({
  type: REMOVE_PRODUCT_REFERENCE
})

export const setSelectedSubProcessAction = process => ({
  type: 'SET_SELECTED_SUB_PROCESS',
  process
})

export const setProcessActivePanelsAction = panels => ({
  type: 'SET_PROCESS_ACTIVE_PANELS',
  panels
})

export const addProcessAction = process => ({
  type: 'ADD_PROCESS',
  process: process
})

export const replaceProcessOfSelectedProcessAction = process => ({
  type: 'REPLACE_PROCESS_OF_SELECTED_PROCESS',
  process
})

export const replaceProcessOfInternalSelectedProcessAction = process => ({
  type: 'REPLACE_PROCESS_OF_INTERNAL_SELECTED_PROCESS',
  process
})

export const setSelectedSubProcessNameAction = (id, name, slug) => ({
  type: 'SET_SELECTED_SUB_PROCESS_NAME',
  id,
  name,
  slug
})

export const changeProductsReferenceAction = referenceProduct => ({
  type: 'CHANGE_PRODUCT_REFERENCE',
  referenceProduct
})

export const setCurrentUserAction = user => ({
  type: 'SET_USER',
  user
})

export const addAccountToUser = account => ({
  type: ADD_ACCOUNT_TO_USER,
  account
})

export const setSelectedAccountTrialInfoAction = (trialStart, trialEnd, isTrialing, createdAt) => ({
  type: SET_ACCOUNT_TRIAL_INFO,
  trialStart, trialEnd, isTrialing, createdAt
})

export const setSelectedAccountCustomerAction = customer => ({
  type: SET_ACCOUNT_CUSTOMER,
  customer
})

export const setSelectedAccountAction = account => ({
  type: 'SET_SELECTED_ACCOUNT',
  account
})

export const addSpaceAction = space => ({
  type: 'ADD_SPACE',
  space
})

export const deleteSpaceAction = spaceId => ({
  type: 'DELETE_SPACE',
  spaceId
})

export const setSpaceNameAction = (name, slug) => ({
  type: 'SET_SPACE_NAME',
  name,
  slug
})

export const setSelectedInventoryItemKeyAction = key => ({
  type: 'SET_SELECTED_INVENTORY_ITEM_KEY',
  key
})

export const updateSelectedInventoryItemKeyAction = productId => ({
  type: 'UPDATE_SELECTED_INVENTORY_ITEM_KEY',
  productId
})

export const addRootProcessAction = process => ({
  type: 'ADD_ROOT_PROCESS',
  process
})

export const setInventoryRootItemIdAction = inventoryRootItemId => ({
  type: 'SET_INVENTORY_ROOT_ITEM_ID',
  inventoryRootItemId
})

export const setInventoryRootItemNameAction = inventoryRootItemName => ({
  type: 'SET_INVENTORY_ROOT_ITEM_NAME',
  inventoryRootItemName
})

export const setReferencePropertiesListAction = referencePropertiesList => ({
  type: 'SET_REFERENCE_PROPERTIES_LIST',
  referencePropertiesList
})

export const removeRootProcessAction = processId => ({
  type: 'REMOVE_ROOT_PROCESS',
  processId
})

export const setPossibleProductListAction = (productType, productList) => ({
  type: 'SET_POSSIBLE_PRODUCT_LIST',
  productType: productType,
  productList: productList
})

export const removeProductAction = productId => ({
  type: REMOVE_PRODUCT,
  productId
})

export const setCategoriesToProductFilterAction = categories => ({
  type: 'SET_CATEGORIES_TO_PRODUCT_FILTER',
  categories
})

export const setProductTagsToProductFilterAction = productTags => ({
  type: SET_PRODUCT_TAGS_TO_PRODUCT_FILTER,
  productTags
})

export const setQueryOfProductFilterAction = query => ({
  type: 'SET_QUERY_OF_PRODUCT_FILTER',
  query
})

export const clearProductFiltersAction = () => ({
  type: 'CLEAR_PRODUCT_FILTERS'
})

export const setSidebarExpandedKeysAction = keys => ({
  type: 'SET_SIDEBAR_EXPANDED_KEYS',
  keys
})

export const setPossibleInventoryItemsAction = items => ({
  type: SET_POSSIBLE_INVENTORY_ITEMS,
  items
})

export const addSpacePermissionAction = spacePermission => ({
  type: 'ADD_SPACE_PERMISSION',
  spacePermission: spacePermission
})

export const removeSpacePermissionAction = spacePermissionId => ({
  type: 'REMOVE_SPACE_ROLE',
  spacePermissionId: spacePermissionId
})

export const changeSpacePermissionAction = spacePermission => ({
  type: 'CHANGE_SPACE_PERMISSION',
  spacePermission: spacePermission
})

export const addAccountUserAction = accountUser => ({
  type: 'ADD_ACCOUNT_USER',
  accountUser
})

export const removeAccountUserAction = accountUserId => ({
  type: 'REMOVE_ACCOUNT_USER',
  accountUserId
})

export const removeInvitationAction = invitationEmail => ({
  type: 'REMOVE_INVITATION',
  invitationEmail
})

export const addInvitationRoleToUserAction = (email, role) => ({
  type: 'ADD_INVITATION_ROLE_TO_USER',
  email, role
})

export const removeInvitationRoleFromUserAction = (email, roleId) => ({
  type: 'REMOVE_INVITATION_ROLE_FROM_USER',
  email, roleId
})

export const setAccountPoliciesAction = policies => ({
  type: 'SET_ACCOUNT_POLICIES',
  policies
})

export const setSpacePoliciesAction = policies => ({
  type: 'SET_SPACE_POLICIES',
  policies
})

export const addProductToListOfProductsForTransportation = product => ({
  type: 'ADD_PRODUCT_TO_LIST_OF_PRODUCTS_FOR_TRANSPORTATION',
  product
})

export const clearListOfProductsForTransportation = () => ({
  type: 'CLEAR_LIST_OF_PRODUCTS_FOR_TRANSPORTATION'
})

export const removeProductFromListOfProductsForTransportation = key => ({
  type: 'REMOVE_PRODUCT_FROM_LIST_OF_PRODUCTS_FOR_TRANSPORTATION',
  key
})

export const setVehicleList = vehicleList => ({
  type: 'SET_TRANSPORTATION_PRODUCTS',
  vehicleList
})

export const setActivityTemplatesAction = activityTemplates => ({
  type: 'SET_ACTIVITY_TEMPLATES',
  activityTemplates
})

export const setActivityProductsListAction = activityProductList => ({
  type: 'SET_ACTIVITY_PRODUCT_LIST',
  activityProductList
})

export const removeActivityTemplateAction = templateId => ({
  type: 'REMOVE_ACTIVITY_TEMPLATE',
  templateId
})

export const setUserProfileAction = userMetadata => ({
  type: 'SET_USER_PROFILE',
  userMetadata
})

export const setSelectedActivityTemplateAction = template => ({
  type: 'SET_SELECTED_ACTIVITY_TEMPLATE',
  template
})

export const setAccountSubscriptionAction = subscription => ({
  type: 'SET_ACCOUNT_SUBSCRIPTION',
  subscription
})
export const setAccountInvoicesAction = invoices => ({
  type: 'SET_ACCOUNT_INVOICES',
  invoices
})

export const setSpaceResourceTypePoliciesAction = resourceType => ({
  type: 'SET_SPACE_RESOURCE_TYPE_POLICIES_LIST',
  resourceType
})

export const setImpactMethodSetsAction = impactMethodSetsList => ({
  type: 'SET_IMPACT_METHOD_SETS',
  impactMethodSetsList
})

export const setCurrentProductPageNumberAction = pageNumber => ({
  type: 'SET_CURRENT_PRODUCT_PAGE_NUMBER',
  pageNumber
})

export const setCurrentProcessPageNumberAction = pageNumber => ({
  type: 'SET_CURRENT_PROCESS_PAGE_NUMBER',
  pageNumber
})

export const setCurrentLifecyclePageNumberAction = pageNumber => ({
  type: 'SET_CURRENT_LIFECYCLE_PAGE_NUMBER',
  pageNumber
})

export const setSubscriptionProductsAction = subscriptionProducts => ({
  type: 'SET_SUBSCRIPTION_PRODUCTS',
  subscriptionProducts
})

export const setProductLabelAction = label => ({
  type: SET_PRODUCT_LABEL,
  label
})

export const setFavoriteProductsAction = products => ({
  type: SET_FAVORITE_PRODUCTS,
  products
})

export const changeProductDescriptionAction = (productId, description) => ({
  type: 'CHANGE_PRODUCT_DESCRIPTION',
  productId, description
})

export const addProductToFavoritesAction = product => ({
  type: ADD_PRODUCT_TO_FAVORITE,
  product
})

export const removeProductFromFavoritesAction = productId => ({
  type: REMOVE_PRODUCT_FROM_FAVORITE,
  productId
})

export const setProductImportModalVisibilityAction = visible => ({
  type: SET_PRODUCT_IMPORT_MODAL_VISIBILITY,
  visible
})

export const setProductImportColumnsAction = columns => ({
  type: SET_PRODUCT_IMPORT_COLUMNS,
  columns
})

export const setAmountModalPreferenceAction = amountModalPreference => ({
  type: SET_AMOUNT_MODAL_PREFERENCE,
  amountModalPreference
})

export const setImpactMethodsAction = impactMethods => ({
  type: SET_IMPACT_METHODS,
  impactMethods
})

export const setImpactCategoriesAction = impactCategories => ({
  type: SET_IMPACT_CATEGORIES,
  impactCategories
})

export const setNwSetsAction = nwSets => ({
  type: SET_NW_SETS,
  nwSets
})