import { gql } from '@apollo/client'

const user = gql`
  fragment userFragment on User {
    id
    name
    email
    intercomUserHash
    accounts {
      id
      name
      hasValidSubscription
      subscriptionId
      subscriptionStatus
      v2ApiCredentials {
        key
        secret
      }
      usage {
          type
          count
          limit
      }
      trialStart
      trialEnd
      isTrialing
      createdAt
      spaces {
        id
        name
        slug
      }
    }
  }
`

export default user
