import { DecimalViewType } from '../../shared/enum'
import { ImpactType } from '../../shared/enum/impact'
import { setTooltipBody, setTooltipHeader } from '../../shared/util/chart/chart-tooltip'

export const removeDonutChartTooltipDisplay = () => {
  const tooltipDOM = document.querySelector<HTMLDivElement>('.donut-chart-tooltip')
  if (tooltipDOM) {
    const tableDOM = document.querySelector<HTMLTableElement>('table')
    tableDOM?.remove()

    tooltipDOM.style.visibility = 'hidden'
    tooltipDOM.style.left = '0'
    tooltipDOM.style.top = '0'
  }
}

type TSetTooltipDisplayProps = {
  x: number,
  y: number,
  visibility: 'visible' | 'hidden',
  sliceColor: string,
  title?: string,
  impactType?: ImpactType,
  totalImpactUnit?: string,
  exponent?: number | string,
  coefficient?: number | string
}

export const setDonutChartTooltipDisplay = ({
  x,
  y,
  visibility,
  sliceColor,
  title,
  impactType,
  totalImpactUnit,
  exponent,
  coefficient
}: TSetTooltipDisplayProps) => {
  const tooltipDOM = document.querySelector<HTMLDivElement>('.donut-chart-tooltip')

  if (tooltipDOM) {
    const sign = impactType === ImpactType.Negative ? '-' : ''
    const tableDOM = document.querySelector<HTMLTableElement>('table')
    tableDOM?.remove()

    tooltipDOM.style.visibility = visibility
    tooltipDOM.style.left = `${x - 220}px`
    tooltipDOM.style.top = `${y + 30}px`

    const table = document.createElement('table')
    table.setAttribute('data-testid', 'tooltip-table')
    table.setAttribute('class', 'm-2')
    tooltipDOM.appendChild(table)

    setTooltipHeader({ table, backgroundColor: sliceColor, title })
    setTooltipBody({
      table,
      totalImpactUnit,
      prefix: `Impact : ${sign}`,
      exponent: exponent?.toString(),
      coefficient: coefficient?.toString(),
      selectedDecimalViewType: DecimalViewType.NumericValue,
    })
  }
}
