import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'
import { NavigationItemAttr } from '../interface/navigation-items-props'
import { castToJoinedAlphaNumericString } from '../../shared/util/tools'

interface MenuItemProps {
  readonly header?: boolean,
  readonly selected?: string | boolean
}

const MenuItem = styled.div<MenuItemProps>`
  border-radius: 0.25rem;
  height: 2.375rem;
  overflow: hidden;
  font-weight: ${(props: MenuItemProps) => (props.selected ? '600' : '400')};
  background: ${(props: MenuItemProps) => (props.selected ? 'var(--blue-50) !important' : 'var(--surface-a)')};

  :hover {
    cursor: ${(props: MenuItemProps) => (props.header ? 'default' : 'pointer')};
    background: var(${(props: MenuItemProps) => (props.header ? '--surface-a' : '--bluegray-50')});
  }
`

const ScrollableMenuItem = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const SubmenuItem = ({
  icon,
  label,
  data,
  selected = false,
  hasItems,
  changelog,
  options,
  scrollable,
  disabled,
  header = false,
  callback = () => {}
}: NavigationItemAttr) => {
  if (scrollable) {
    return (
      <ScrollableMenuItem className="w-full pr-1">
        {
          data.map((item: any, index: number) => {
            const { id, label, dataCyTag } = item || {}
            const itemIsSelected = selected === id
            const dataCy = `${dataCyTag}-${castToJoinedAlphaNumericString(label).toLowerCase()}`
            return (
              <MenuItem
                key={`submenu-item-${index}`}
                data-cy={dataCy}
                data-testid={dataCy}
                selected={itemIsSelected}
                onClick={() => {
                  if (itemIsSelected) return
                  callback(id)
                }}
                className={classNames('flex w-full p-2 text-sm', {
                  'cursor-auto': itemIsSelected,
                  'text-color': !disabled,
                  'text-gray-400': disabled
                })}
              >
                <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">{ label }</div>
              </MenuItem>
            )
          })
        }
      </ScrollableMenuItem>
    )
  }
  return (
    <MenuItem
      data-testid="styled-submenu-item"
      header={header}
      onClick={options?.onClick}
      selected={selected!}
      className={classNames('flex w-full p-2 text-sm', {
        'text-black-alpha-20': (!disabled && header),
        'text-color': (!disabled && !header && !changelog),
        'text-gray-400': disabled
      })}
    >
      {icon && !changelog && <div data-testid="styled-submenu-icon" className="flex-none pr-2">{ icon }</div>}
      <div
        data-testid="styled-submenu-label"
        className={classNames('flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis', {
          'text-primary-500 text-center text-xs': (!disabled && changelog),
        })}
      >
        { changelog && <span className="text-sm mr-2">{ icon }</span> }
        { label }
      </div>
      {hasItems && <div data-testid="styled-submenu-more" className="flex-none"><FiChevronRight /></div>}
    </MenuItem>
  )
}
