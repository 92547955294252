import React from 'react'
import classnames from 'classnames'
import { Tooltip } from 'antd'

function ImpactBar({ percent, label, className, tooltip, reversed } ) {
  const barClassName = !isNaN(percent) && (percent < 0 ? 'positiveBarInTable' : 'negativeBarInTable')
  const barDirectionClassName = reversed ? `${barClassName}--reversed` : ''
  const barDOM = <div
    data-cy-percent={`percent-amount-${percent || 0}`}
    className={classnames('bar-item', barClassName, className, barDirectionClassName, { outside: percent < 10 })}
    style={{ width: `${Math.abs(percent)}%` }}
  >
    <span className="label">{label}</span>
  </div>
  const barWithTooltip = <Tooltip title={tooltip} placement="top" mouseEnterDelay={1}>{barDOM}</Tooltip>
  return tooltip ? barWithTooltip : barDOM
}

export default ImpactBar
