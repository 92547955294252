import React, { useContext, useRef } from 'react'

import { classNames } from 'primereact/utils'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowUp, faCircleArrowDown } from '@fortawesome/pro-solid-svg-icons'

import { useTranslation } from 'react-i18next'
import { ComparisonEntity } from '../../../enum/entity'

import AnalysisContext from '../../../provider/context/analysis.context'
import { TAnalysisContext } from '../../../interface/analysis.context'
import { TooltipOverflowContent } from '../../../../shared/hook/use-ref-overflow'
import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component'
import { ImpactType } from '../../../../shared/enum/impact'
import { DecimalViewType } from '../../../../shared/enum'

interface TreeHeaderProps {
  readonly entity?: ComparisonEntity
}

export const StackChartHeaderComponent = ({
  entity = ComparisonEntity.Primary
}: TreeHeaderProps) => {
  const { t } = useTranslation([ 'analysis', 'common' ])
  const entityNameRef = useRef<HTMLDivElement>(null)
  const {
    primaryEntityName,
    secondaryEntityName,
    primaryEntityUnit,
    secondaryEntityUnit,
    primaryTotalImpact,
    secondaryTotalImpact,
    getImpactChangeRatio = () => {},
    getImpactEffect = () => {}
  } = useContext<TAnalysisContext>(AnalysisContext)

  const isPrimary = entity === ComparisonEntity.Primary
  const totalImpact = isPrimary ? primaryTotalImpact : secondaryTotalImpact
  const { amount: totalImpactAmount, unit: totalImpactUnit = null } = totalImpact || {}

  const impactEffect = getImpactEffect(isPrimary)
  const impactChangeRatio = getImpactChangeRatio(isPrimary)
  const changeIsPositive = impactEffect === ImpactType.Positive

  const primaryEntityLabel = t('labels.unitOfEntityName', { ns: 'common', unit: primaryEntityUnit, name: primaryEntityName })
  const secondaryEntityLabel = t('labels.unitOfEntityName', { ns: 'common', unit: secondaryEntityUnit, name: secondaryEntityName })
  const tooltipLabel = isPrimary ? primaryEntityLabel : secondaryEntityLabel
  const entityName = isPrimary ? primaryEntityName : secondaryEntityName
  const impactChangePercent = Math.round(parseFloat(impactChangeRatio) * 100)

  return (
    <div className="flex flex-column">

      { entityName && (
        <>
          <Tooltip target={entityNameRef?.current || ''} position="bottom">
            <TooltipOverflowContent>
              <div className="w-full font-medium">{ tooltipLabel }</div>
              { !isPrimary && (
                <div className="w-full pt-1">
                  {
                    impactChangePercent !== 0
                      ? t('labels.impactChange', {
                        percent: Math.abs(impactChangePercent),
                        change: changeIsPositive ? 'higher' : 'lower',
                        secondaryProduct: primaryEntityName
                      })
                      : t('labels.impactChange', {
                        context: 'same', primaryEntityName, secondaryEntityName
                      })
                  }
                </div>
              )}
            </TooltipOverflowContent>
          </Tooltip>
          <div ref={entityNameRef} data-testid="stacked-product-name" className="flex-none font-normal text-base text-center white-space-nowrap overflow-hidden text-overflow-ellipsis">
            { entityName }
          </div>
        </>
      ) }

      { totalImpactAmount && totalImpactUnit && (
        <div
          data-testid="stacked-impact-amount"
          className="flex flex-grow-1 align-items-center justify-content-center text-base white-space-nowrap text-overflow-ellipsis h-1rem"
        >
          { totalImpactAmount && <span className="font-medium"><DecimalPointComponent value={totalImpactAmount} enableTooltip /></span> }
          { totalImpactUnit && <>{ totalImpactUnit }</>}
        </div>
      ) }

      <div className="flex flex-none text-base justify-content-center">
        { !isPrimary && impactChangeRatio !== 0 && (
          <div className="flex-none flex align-items-center justify-content-end text-gray-700 text-base h-full gap-1">
            <div
              data-testid="stacked-impact-change"
              className={classNames('flex-none font-medium flex align-items-center white-space-nowrap', {
                'text-red-500': changeIsPositive,
                'text-green-500': !changeIsPositive
              })}
            >
              { impactChangeRatio > 0 && <>+</>}
              <DecimalPointComponent value={impactChangeRatio} enableTooltip decimalViewType={DecimalViewType.PercentageValue} />
            </div>
            <FontAwesomeIcon
              data-testid="stacked-impact-icon"
              icon={changeIsPositive ? faCircleArrowUp : faCircleArrowDown}
              className={classNames('flex-none align-items-center pr-2', {
                'text-red-500': changeIsPositive,
                'text-green-500': !changeIsPositive
              })}
            />
          </div>
        ) }

      </div>
    </div>
  )
}
