import { useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { userAuth0Var, userVar } from '../../../graphql/cache'
import { IUser } from '../../model'
import { USER } from '../../graphql/query'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'
import { useAuth0Api } from '../../shared/hook/query/use-auth0-api.query'

export interface IUseUserResult {
    loading: boolean
    userData: IUser
    refetchUser: Function
}

export const useUserQuery = (): IUseUserResult => {
  const userAuth0 = userAuth0Var()
  const [ retchedAuth0User, setRetchedAuth0User ] = useState<boolean>(false)
  const { refetchAuth0User } = useAuth0Api()
  const { loading: loadingUser, data: userData, refetch: refetchUser } = useQuery(USER, { skip: !retchedAuth0User, fetchPolicy: 'no-cache' })
  const [ loadingAuth0Detail, setLoadingAuth0Detail ] = useState<boolean>(true)

  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const loading = loadingAuth0Detail || loadingUser
  useEffect(() => {
    const loadAuth0UserDetail = async () => {
      if (!userAuth0) {
        const successfullyRefetch = await refetchAuth0User()
        setRetchedAuth0User(successfullyRefetch)
      }

      const accounts = userData?.user?.accounts || []
      userData?.user && userVar({ ...userData.user, accounts })
      setLoadingAuth0Detail(false)
    }
    if (!loadingUser && userData) {
      updateDashboard({ refetchUser })
    }
    loadAuth0UserDetail()
  }, [ loadingUser, userData ])

  return { loading, userData: userData?.user as IUser, refetchUser }
}
