export const EcochainLogoImage = `<svg version="1.1"
  id="svg51"
  width="518.84827"
  height="71.45108"
  viewBox="0 0 518.84827 71.451081"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:svg="http://www.w3.org/2000/svg">
  <g
    id="g59"
    transform="matrix(1.3333333,0,0,-1.3333333,-59.401065,130.68548)">
    <g
        id="g61"
        transform="scale(0.1)">
      <path
          d="m 1045.92,901.516 c -0.02,-0.028 -9.91,-9.328 -9.91,-9.328 l 62,-57.883 9.92,9.293 c 37.51,35.164 87.48,54.578 140.64,54.578 53.17,0 100.09,-22.492 140.73,-54.633 25.4,-20.098 86.29,-82.074 86.29,-131.898 0,-49.825 -48.67,-96.645 -86.25,-131.875 -77.57,-72.676 -203.85,-72.676 -281.48,0 L 895.953,778.777 834.051,720.813 1046,521.781 c 111.71,-104.679 298.43,-102.691 410.16,1.996 54.06,50.735 111.2,117.477 111.2,191.559 0,71.269 -60.49,143.125 -113.19,189.062 -56,48.825 -129.07,75.743 -205.6,75.743 -76.53,0 -148.5,-27.942 -202.65,-78.625"
          style="fill:#5ac134;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path63" />
      <path
          d="m 966.941,522.883 c 0.028,0.027 9.914,9.332 9.914,9.332 l -62,57.879 -9.917,-9.289 c -37.512,-35.164 -87.481,-54.578 -140.641,-54.578 -53.176,0 -100.098,22.492 -140.727,54.632 -25.41,20.098 -86.293,82.075 -86.293,131.899 0,49.824 48.664,96.64 86.25,131.871 77.563,72.676 203.848,72.676 281.485,0 l 211.898,-199.004 61.9,57.961 -211.943,199.031 C 855.156,1007.3 668.43,1005.31 556.707,900.625 502.641,849.887 445.508,783.145 445.508,709.066 c 0,-71.269 60.488,-143.125 113.183,-189.062 56.008,-48.824 129.075,-75.746 205.606,-75.746 76.531,0 148.496,27.941 202.644,78.625"
          style="fill:#5ac134;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path65" />
      <path
          d="m 2096.78,554.555 v -70.293 h -279.19 v 444.261 h 272.74 V 858.23 H 1895.61 V 738.313 h 170.23 V 666.738 H 1895.61 V 554.555 h 201.17"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path67" />
      <path
          d="m 2134.28,635.145 c 0,95.433 76.71,156.679 163.78,156.679 52.22,0 94.77,-22.574 122.49,-58.019 l -53.51,-41.922 c -15.47,18.691 -41.27,30.305 -67.7,30.305 -52.22,0 -89.64,-36.102 -89.64,-87.043 0,-50.286 37.42,-87.04 89.64,-87.04 26.43,0 52.23,11.586 67.7,30.305 l 53.51,-41.922 c -27.72,-34.816 -70.27,-58.019 -122.49,-58.019 -87.07,0 -163.78,61.246 -163.78,156.676"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path69" />
      <path
          d="m 2820.59,635.145 c 0,95.433 76.71,156.679 163.78,156.679 52.22,0 94.77,-22.574 122.49,-58.019 l -53.51,-41.922 c -15.47,18.691 -41.27,30.305 -67.7,30.305 -52.22,0 -89.64,-36.102 -89.64,-87.043 0,-50.286 37.42,-87.04 89.64,-87.04 26.43,0 52.23,11.586 67.7,30.305 l 53.51,-41.922 c -27.72,-34.816 -70.27,-58.019 -122.49,-58.019 -87.07,0 -163.78,61.246 -163.78,156.676"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path71" />
      <path
          d="m 2692.09,635.801 c 0,51.57 -38.67,86.387 -85.11,86.387 -46.43,0 -84.47,-34.817 -84.47,-86.387 0,-52.879 38.04,-88.352 84.47,-88.352 46.44,0 85.11,35.473 85.11,88.352 z m -245.01,0 c 0,95.406 74.15,156.023 159.9,156.023 85.76,0 160.56,-60.617 160.56,-156.023 0,-95.43 -74.8,-157.988 -160.56,-157.988 -85.75,0 -159.9,62.558 -159.9,157.988"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path73" />
      <path
          d="M 3443.62,653.211 V 484.262 h -74.79 v 161.843 c 0,47.715 -29.03,72.231 -62.53,72.231 -34.19,0 -76.74,-18.723 -76.74,-76.738 V 484.262 h -74.8 v 462.324 h 74.8 V 739.598 c 14.81,36.101 63.18,52.226 93.49,52.226 77.37,0 120.57,-50.941 120.57,-138.613"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path75" />
      <path
          d="m 3744.84,635.801 c 0,52.855 -43.86,86.387 -87.04,86.387 -46.43,0 -84.47,-36.102 -84.47,-86.387 0,-50.313 38.04,-87.696 84.47,-87.696 45.78,0 87.04,34.817 87.04,87.696 z m 76.09,149.574 V 484.262 h -74.8 v 40.613 c -20.64,-32.223 -63.19,-47.062 -99.94,-47.062 -79.31,0 -148.32,61.246 -148.32,157.332 0,96.718 68.35,156.679 147.66,156.679 38.04,0 79.96,-14.812 100.6,-46.433 v 39.984 h 74.8"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path77" />
      <path
          d="m 3897.09,785.375 h 74.8 V 484.262 h -74.8 z m -11.61,106.391 c 0,26.453 22.57,45.777 49,45.777 27.08,0 48.37,-19.324 48.37,-45.777 0,-25.801 -21.29,-45.778 -48.37,-45.778 -26.43,0 -49,19.977 -49,45.778"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path79" />
      <path
          d="M 4336.87,653.211 V 484.262 h -74.8 v 161.843 c 0,47.715 -29.02,72.231 -62.55,72.231 -34.17,0 -76.72,-18.723 -76.72,-76.738 V 484.262 H 4048 v 301.113 h 74.8 v -47.719 c 15.47,37.414 62.53,54.168 93.5,54.168 77.36,0 120.57,-50.941 120.57,-138.613"
          style="fill:#172a45;fill-opacity:1;fill-rule:nonzero;stroke:none"
          id="path81" />
    </g>
  </g>
</svg>
`

export const LightBlueBackgroundImage = `<svg width="220" height="595">
  <rect width="220" height="595" style="fill:rgba(243, 249, 253, 1)" />
</svg>
`

export const DoughnutWhiteCircleImage = `<svg height="280" width="280">
  <circle cx="140" cy="140" r="140" fill="#ffffff" />
</svg>
`

export const LegendBulletImage = `<svg height="10" width="10">
  <circle cx="5" cy="5" r="4" fill="#ffffff" />
</svg>
`
