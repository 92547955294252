import { returnNested } from '../../utils/tools'

export const selectedSpaceSelector = state => returnNested(state, 'global', 'selectedSpace')

const
  root = state => returnNested(state, 'global', 'selectedSpace'),
  spaceProducts = state => returnNested(root(state), 'products', 'items'),
  spaceFolders = state => returnNested(root(state), 'folders'),
  spaceLifecycles = state => returnNested(root(state), 'lifecycles'),
  totalProducts = state => returnNested(root(state), 'products', 'total'),
  currentFolder = state => returnNested(root(state), 'products', 'currentFolder'),
  spaceProcesses = state => returnNested(root(state), 'processes', 'items'),
  totalProcesses = state => returnNested(root(state), 'processes', 'total'),
  spaceId = state => returnNested(root(state), 'id'),
  spaceName = state => returnNested(root(state), 'name'),
  spaceSlug = state => returnNested(root(state), 'slug'),
  impactMethod = state => returnNested(root(state), 'impactMethod'),
  impactMethodId = state => returnNested(root(state), 'impactMethod', 'id'),
  impactNwSet = state => returnNested(root(state), 'nwSet'),
  impactCategory = state => returnNested(root(state), 'impactCategory'),
  impactCategoryId = state => returnNested(root(state), 'impactCategory', 'id'),
  impactUseMethodTotal = state => returnNested(root(state), 'useMethodTotal'),
  impactExcludeLT = state => returnNested(root(state), 'excludeLT'),
  //TODO global selectors should not be a part of space selector
  workspaceActivePanels = state => returnNested(state, 'global', 'workspaceActivePanels'),
  policies = state => returnNested(state, 'global', 'resourcePolicies', 'selectedSpace'),
  spaceTypePoliciesList = state => returnNested(state, 'global', 'resourceTypePoliciesLists', 'space'),
  currentProductPageNumber = state => returnNested(state, 'global', 'currentProductPageNumber'),
  currentProcessPageNumber = state => returnNested(state, 'global', 'currentProcessPageNumber'),
  spaceFavoriteProducts  = state => returnNested(state, 'global', 'favoriteProducts', 'items'),
  fullSpaceProductList  = state => returnNested(state, 'global', 'fullSpaceProductList'),
  spaceProductTagList  = state => returnNested(state, 'global', 'spaceProductTagList'),
  selectedDatabases  = state => returnNested(state, 'global', 'selectedDatabases')

const SpaceSelector = {
  selectedSpace: root,
  spaceId,
  spaceName,
  spaceSlug,
  impactMethod,
  impactMethodId,
  impactCategory,
  impactCategoryId,
  impactNwSet,
  impactUseMethodTotal,
  impactExcludeLT,
  policies,
  workspaceActivePanels,
  spaceProducts,
  spaceLifecycles,
  totalProducts,
  spaceProcesses,
  totalProcesses,
  spaceTypePoliciesList,
  currentProductPageNumber,
  currentProcessPageNumber,
  spaceFavoriteProducts,
  fullSpaceProductList,
  spaceProductTagList,
  selectedDatabases,
  currentFolder,
  spaceFolders
}

export default SpaceSelector
