import { gql } from '@apollo/client'
import referenceProduct from '../fragment/referenceProduct'

export default gql`
  mutation changeConversionItemValue($conversionID: ID!, $productID: ID!, $value: String!) {
    changeConversionItemValue(conversionID: $conversionID, productID: $productID, value: $value){
      id
      process {
        id
        name
      }
      inputs {
        product {
          id
          name
          unit
          type
          referenceProduct{
            ...referenceProduct
          }
          productProperties {
            referenceProperty {
              id
              name
            }
            conversionFactor
            referenceUnit {
              id
              name
            }
          }
        }
        value
      }
      outputs {
        product {
          id
          name
          unit
          type
          referenceProduct{
            ...referenceProduct
          }
          productProperties {
            referenceProperty {
              id
              name
            }
            conversionFactor
            referenceUnit {
              id
              name
            }
          }
        }
        value
      }
    }
  }
  ${referenceProduct}
`
