import { connect } from 'react-redux'
import InventoryTreeItem from './inventoryTreeItem'
import {
  selectedInventoryItemForAddReferenceSaga,
  selectedInventoryItemForChangeAmountSaga,
  changeInventoryItemAmountSaga
} from '../../../../redux/sagas/product.saga'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import GlobalSelector from '../../../../redux/selectors/global.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { updateInventoryItemsSaga } from '../../../../redux/sagas/inventoryTree.saga'
import { updateLifecyclesItemsSaga } from '../../../../redux/sagas/lifecycle.saga'
import { setAmountModalPreferenceAction } from '../../../../redux/actions/global.actions'

const mapStateToProps = state => ({
  selectedProduct: SelectedProductSelector.product(state),
  inventoryTree: ProductMutationSelector.activeInventory(state),
  productMutator: ProductMutationSelector.productMutator(state),
  selectedInventoryItem: GlobalSelector.selectedInventoryItem(state),
  lifecycle: LifecycleSelector.selectedLifecycle(state),
  isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state),
  amountModalPreference: GlobalSelector.amountModalPreference(state)
})

const mapDispatchToProps = {
  selectedInventoryItemForAddReferenceSaga,
  selectedInventoryItemForChangeAmountSaga,
  changeInventoryItemAmountSaga,
  updateInventoryItemsSaga,
  updateLifecyclesItemsSaga,
  setAmountModalPreferenceAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryTreeItem)
