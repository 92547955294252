import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { AiOutlineDown } from 'react-icons/ai'
import { Dropdown, Checkbox, Menu, Button, Tooltip } from 'antd'

import compose from '../../../utils/compose'
import { useRefOverflow } from '../../helpers/hooks/refOverflow'

const DatabaseFilter = ({
  t,
  databases = [],
  handleDatabaseChange,
  referenceDatabases = []
}) => {
  const [ visible, setVisible ] = useState(false)
  const [ databaseRef, hasDatabaseOverflow ] = useRefOverflow()

  const getSelectDatabaseLabel = () => {
    if ( databases.length === 0 ) {
      return t('model.selectDatabase')
    } else if ( databases.length === 1 ) {
      const [ selectedDatabase ] = databases
      const selected = referenceDatabases.find(db => selectedDatabase.id === db.id )

      return selected?.name
    }
    return t('model.selectedDatabases', { selected: databases.length })
  }

  return (

    <Checkbox.Group
      className="search-filter__checkboxGroup"
      value={databases.map(database => database.id)}
      onChange={handleDatabaseChange}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}>
      <Dropdown
        className='search-filter__dropdown'
        overlayClassName='search-filter__dropdownOverlay'
        getPopupContainer={node => node.parentElement}
        visible={visible}
        overlay={(
          <Menu>
            {
              referenceDatabases?.map(el => (
                <Menu.Item key={el.id}>
                  <Checkbox value={el.id}>{ el.name }</Checkbox>
                </Menu.Item>
              ))
            }
          </Menu>
        )}
      >
        <Button>
          <Tooltip placement="top" title={hasDatabaseOverflow ? getSelectDatabaseLabel() : null}>
            <span ref={databaseRef} className="search-filter__selected">{ getSelectDatabaseLabel() }</span>
          </Tooltip>
          <AiOutlineDown />
        </Button>
      </Dropdown>
    </Checkbox.Group>
  )
}

export { DatabaseFilter }
export default compose(
  withTranslation()
)(DatabaseFilter)
