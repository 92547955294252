import { connect } from 'react-redux'
import { InventoryTreeSelector } from '../../../../redux/selectors/inventoryTree.selector'
import ExportProductCsv from './exportProductCsv'

const mapStateToProps = state => {
  return ({
    inventoryProductId: InventoryTreeSelector.productId(state),
    inventoryProductName: InventoryTreeSelector.productName(state)
  })
}

export default connect(
  mapStateToProps
)(ExportProductCsv)
