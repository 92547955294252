import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

export const TooltipOverflowContent = styled.div`
  max-width: 20rem;
  min-width: 5rem;
  word-wrap: break-word;
  word-break: keep-all;
`

export const useRefOverflow = (callback?: Function): [React.Ref<HTMLDivElement> | undefined, boolean, HTMLDivElement | string] => {
  const [ hasOverflow, setHasOverflow ] = useState(false)
  const [ domElement, setDomElement ] = useState('')

  const ref = useCallback((node: any) => {
    const trigger = () => {
      setTimeout(() => {
        const { scrollWidth, clientWidth } = node
        setHasOverflow(scrollWidth > clientWidth)

        if (callback) callback(hasOverflow)
      })
    }

    if (node) {
      setDomElement(node)
      node.addEventListener('mouseenter', trigger)
      setTimeout(() => {
        trigger()
      }, 1000)
    }
  }, [])

  return [ ref, hasOverflow, domElement ]
}
