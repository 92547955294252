import { gql } from '@apollo/client'

export default gql`
  query impactMethods($setID: ID!){
    impactMethods(setID: $setID) {
      id
      name
      description
    }
  }
`
