import { gql } from '@apollo/client'

export default gql`
  query possibleOutputs($processID: ID!) {
    possibleOutputs(processID: $processID) {
      id
      name
    }
  }
`
