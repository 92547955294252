import { useReactiveVar } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import ImpactSelectorDialog from '../../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'
import { store } from '../../../../configureStore'
import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { setIsCreateNewInventoryItemAction, setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import { setSelectedProductAction } from '../../../../redux/actions/global.actions'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'

import { IPhase } from '../../../model'
import { TreeComponent } from '../../../product/component/tree/tree.component'
import { DeleteTreeItemContainer } from '../../../product/container/dialog/delete-tree-item.container'
import { MenuAction } from '../../../product/enum/menu-action'
import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import { TrackEventType } from '../../../shared/enum/track-events'
import { useTransformTree } from '../../../shared/hook/use-transform-tree'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { LifecycleDetailDialogComponent } from '../dialog/lifecycle-detail-dialog.component'
import { PhaseAddSubItemComponent } from './phase-add-sub-item.component'

const PhaseInventoryWrapper = styled.div`
  .p-tree {
    padding: 0 !important;
  }
`
type TPhaseInventoryComponentProps = {
  lifecyclePhase: IPhase
}
export const PhaseInventoryComponent = ({
  lifecyclePhase
}: TPhaseInventoryComponentProps) => {
  const {
    productInventory: { product } = {},
    totalImpact = null,
    inventoryItems = [],
    expandedKeys = [],
    updateProductDetail,
    refetchInventory,
    refetchInventoryItems,
    selectedProduct,
    selectedAction,
    selectedInventoryAmountV09
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const {
    selectedPhase,
    refetchLifecycle,
    readonlyInventory,
    showLifecycleDetailPanelOnly,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { impactCategory } = space || {}

  useEffect(() => {
    if (impactCategory?.id) {
      reloadInventoryItems()
    }
  }, [ impactCategory?.id ])

  const transformedTree = useTransformTree({
    expandedKeys,
    inventoryItems,
    rootInventoryItem: { inventoryItem: { amount: 1, impact: { ...totalImpact }, product } }
  })
  const { children: inventoryTree = [] } = transformedTree[0] || {}

  useEffect(() => {
    if (lifecyclePhase) {
      updateProductDetail({ lifecyclePhase })
      updateLifecycleDetail({ updateLifecycleProduct: updateProductDetail })
    }
  }, [ lifecyclePhase ])

  useEffect(() => {
    if (selectedAction === MenuAction.AddTreeItem) {
      addTreeItemHandler()
    }
  }, [ selectedAction, selectedProduct ])

  const [ trackEventInSegment ] = getSegmentTrack()
  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const productMutator = ProductMutationSelector.productMutator(state)
  const selectedProductId = SelectedProductSelector.productId(state)
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)

  const addTreeItemHandler = () => {
    updateProductDetail({ selectedAction, selectedProduct, selectedInventoryAmountV09 })
    store.dispatch(setSelectedProductAction(selectedProduct))
    store.dispatch(setIsCreateNewInventoryItemAction(true))
    store.dispatch(setIsDetailsPanelOpenAction(true))
  }

  const afterDeleteTreeItem = () => {
    reloadInventoryItems()
    updateProductDetail({ hasInventoryChanged: true })
    trackEventInSegment(TrackEventType.DELETED_SUBITEM)
  }

  const afterImpactChange = () => {
    reloadInventoryItems()
    selectedProductId && isDetailsPanelOpen
      && store.dispatch(showProductInDetailsPanelSaga(selectedProductId))
  }

  const reloadInventoryItems = () => {
    refetchLifecycle && refetchLifecycle()
    refetchInventory && refetchInventory()
    refetchInventoryItems && refetchInventoryItems()
    updateProductDetail({ hasInventoryChanged: true })
  }

  return (
    <>
      <PhaseInventoryWrapper className="flex flex-column flex-grow-1 w-full">
        { inventoryTree.length !== 0 && <TreeComponent inventoryTree={inventoryTree} /> }

        { !readonlyInventory && (
          <div data-cy="add-phase-subitem" className="flex-none w-full p-1" data-html2canvas-ignore>
            <PhaseAddSubItemComponent phase={lifecyclePhase} />
          </div>
        )}

        { readonlyInventory && inventoryTree.length === 0 && (
          <div className="flex-none w-full p-3 text-basis text-gray-300">
            No items added
          </div>
        )}
      </PhaseInventoryWrapper>

      <DeleteTreeItemContainer afterDeleteTreeItem={afterDeleteTreeItem} />

      {/* // TODO : From v0.9 : To be refactored */}
      { selectedProductId && <ImpactSelectorDialog productMutator={productMutator} updatePageItems={afterImpactChange} /> }

      { selectedPhase?.id === lifecyclePhase.id && !showLifecycleDetailPanelOnly && <LifecycleDetailDialogComponent reload={reloadInventoryItems} /> }
    </>
  )
}
