import { connect } from 'react-redux'
import FlagsSelector from '../../redux/selectors/flags.selector'

import CropLongText from './cropLongText'

const mapStateToProps = state => ({
  isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state)
})

export default connect(
  mapStateToProps,
  null
)(CropLongText)
