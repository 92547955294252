import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeProductFavoriteState($productID: ID!, $favorite: Boolean!) {
    changeProductFavoriteState(productID: $productID, favorite: $favorite){
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
