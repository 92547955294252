import { gql } from '../../../__generated__'

export const SPACE = gql(`
  query space($id: ID!) {
      space(id: $id){
          ...spaceFragment
      }
  }
`)

export const SPACE_BY_SLUG = gql(`
  query spaceBySlug($slug: String!, $accountID: String!) {
    spaceBySlug(slug: $slug, accountID: $accountID){
          ...spaceDetailFragment
      }
  }
`)

export const SPACE_PERMISSION = gql(`
  query spacePermission($slug: String!, $accountID: String!) {
    spaceBySlug(slug: $slug, accountID: $accountID){
          ...spacePermissionFragment
      }
  }
`)
