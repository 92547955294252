import React, {
  useEffect, useContext, useState, useRef
} from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { update as intercomUpdate } from '@intercom/messenger-js-sdk';

import { Toast } from 'primereact/toast'

import {
  currentWorkspaceSlugVar,
  selectedAccountVar,
  setSelectedAccount,
  setSelectedWorkspace,
  userAuth0Var,
  userVar
} from '../../../graphql/cache'
import { AccountNavigationComponent } from '../component/account-navigation'
import DashboardContext from '../context/dashboard.context'
import { AddAccountComponent } from '../component/add-account'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import { useAccountQuery } from '../hooks/account-query'
import { TrackEventType } from '../../shared/enum/track-events'
import { EmptyAccountSidebarComponent } from '../component/empty-account-sidebar'
import { CREATE_ACCOUNT } from '../../graphql/mutation'
import { Navigation } from '../../shared/enum'
import { getSegmentTrack } from '../../shared/util/segment'
import { useAuth0Api } from '../../shared/hook/query/use-auth0-api.query'

export const AccountNavContainer = () => {
  const { t } = useTranslation([ 'dashboard', 'common' ])
  const user = userVar()
  const toast = useRef<Toast>(null)
  const navigate = useNavigate()
  const { openTalkToExpert = () => {}, refetchUser } = useContext<IDashboardContext>(DashboardContext)
  const [
    createAccount,
    {
      error: failedCreating,
      data: createAccountData = {},
      loading: accountCreating
    }
  ] = useMutation(CREATE_ACCOUNT)
  const [ switchAccount, setSwitchAccount ] = useState<boolean>(false)
  const [ showAddAccountDialog, setShowAddAccountDialog ] = useState<boolean>(false)
  const [ showEmptyAccountLoader, setShowEmptyAccountLoader ] = useState<boolean>(false)
  const {
    loading,
    account, hasAccess, emptyAccount
  } = useAccountQuery(user)
  const { updateAuth0UserMetadata } = useAuth0Api()
  const [ trackEventInSegment ] = getSegmentTrack()
  const { createAccount: accountCreated = null } = createAccountData || {}
  const { hasValidSubscription = false } = account || {}

  useEffect(() => {
    if (!loading && ((account && hasAccess) || emptyAccount)) {
      !emptyAccount && account ? setSelectedAccount({ account, hasAccess, loading }) : setSelectedAccount({ loading, emptyAccount })

      if (emptyAccount) {
        openAddAccountDialog()
      } else if (account) {
        intercomUpdate({
          mobius_trial_is_active: account.isTrialing,
          mobius_subscription_plan: account.subscription?.plan?.name,
          mobius_account_id: account.id,
          mobius_subscription_status: account.subscriptionStatus
        })
      }
    }
  }, [ loading, account, hasAccess, emptyAccount ])

  useEffect(() => {
    createAccountCallback()
  }, [ accountCreating, accountCreated, failedCreating ])

  useEffect(() => {
    if (emptyAccount && accountCreating) {
      setShowEmptyAccountLoader(true)
    }
  }, [ emptyAccount, accountCreating ])

  const createAccountCallback = () => {
    if (!accountCreating && (accountCreated || failedCreating)) {
      if (failedCreating) {
        toast?.current?.show({
          severity: 'error',
          summary: t('messages.errorSummary', { ns: 'common' }),
          detail: t('messages.errorDetail', { context: 'createAccount' }),
          life: 3000
        })
      } else if (accountCreated) {
        const { accounts = [] } = user || {}
        if (accounts.length === 0) {
          trackEventInSegment(TrackEventType.FIRST_ACCOUNT)
        }
        refetchUser && refetchUser()
        if (switchAccount) {
          handleAccountSwitch(accountCreated?.id)
        }

        trackEventInSegment(TrackEventType.CREATED_ACCOUNT)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary', { ns: 'common' }),
          detail: t('messages.successDetail', { context: 'createAccount' }),
          life: 3000
        })
      }
      closeAddAccountDialog()
    }
  }

  const handleTalkToExpert = async () => {
    trackEventInSegment(hasValidSubscription ? TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_PAID : TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_TRIAL)
    openTalkToExpert()
  }

  const handleAccountSwitch = (id: string) => {
    const { account: currentAccount = null } = selectedAccountVar() || {}
    if (currentAccount?.id !== id) {
      trackEventInSegment(TrackEventType.SWITCH_ACCOUNT)
      currentWorkspaceSlugVar(null)
      setSelectedAccount({ loading: true })
      setSelectedWorkspace({ loading: true })
      !showEmptyAccountLoader && navigate(generatePath(Navigation.AccountDetail, { accountId: id }))
    }
  }

  const updateUserMetadata = async (values: Partial<handleAccountCreationParam>) => {
    const userAuth0Prev = userAuth0Var()
    const metadata = { ...userAuth0Prev?.metadata, ...values }
    const metadataResponse = await updateAuth0UserMetadata(metadata)
    const { user_metadata = {} } = metadataResponse || {}
    userAuth0Var({ ...userAuth0Prev, metadata: user_metadata })
  }

  type handleAccountCreationParam = {
    switchAccount: boolean,
    name: string,
    firstName?: string,
    lastName?: string,
    jobFunction?: string,
    signupCompleted?: boolean
  }
  const handleAccountCreation = async (newAccount: handleAccountCreationParam) => {
    const {
      switchAccount,
      name,
      firstName,
      lastName,
      jobFunction
    } = newAccount
    trackEventInSegment(TrackEventType.CLICKED_CREATE_ACCOUNT)

    if (emptyAccount) {
      await updateUserMetadata({
        firstName, lastName, jobFunction, name, signupCompleted: true
      })
    }

    setSwitchAccount(switchAccount)
    createAccount({ variables: { name } })

    emptyAccount && closeAddAccountDialog()
  }

  const openAddAccountDialog = () => {
    setShowAddAccountDialog(true)
  }

  const closeAddAccountDialog = () => {
    setShowAddAccountDialog(false)
  }

  return (
    <>
      { loading && (
        <div
          data-testid="account-container-loading"
          className="w-full px-8 py-4"
        >
          <i className="pi pi-spin pi-spinner text-bluegray-50"></i>
        </div>
      )}
      { !loading && (account || emptyAccount) && (
        <div data-testid="with-account-container">
          <AccountNavigationComponent
            emptyAccount={emptyAccount}
            openAddAccountDialog={openAddAccountDialog}
            handleTalkToExpert={handleTalkToExpert}
            handleAccountSwitch={handleAccountSwitch}
          />

          <AddAccountComponent
            emptyAccount={emptyAccount}
            showAddAccountDialog={showAddAccountDialog}
            accountCreating={accountCreating}
            closeAddAccountDialog={closeAddAccountDialog}
            handleAccountCreation={handleAccountCreation}
          />

          <Toast ref={toast} position="top-right" />
        </div>
      )}

      <EmptyAccountSidebarComponent
        showEmptyAccountLoader={showEmptyAccountLoader}
        setShowEmptyAccountLoader={setShowEmptyAccountLoader}
        accountCreating={accountCreating}
        accountCreated={accountCreated}
      />
    </>
  )
}
