import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Switch, Button, Tooltip } from 'antd'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { DetailsPanelCard } from '../../details-panel/detailPanelCard'

const PhasesManagement = ({
  setIsCreateNewInventoryItemAction,
  setIsOrderingPhasesAction,
  setIsCreatePhaseAction,
  setSelectedPhaseAction,
  isOrderingPhases,
  isReadOnly,
  t
}) => {

  const orderingPhasesToggle = () => {
    setIsOrderingPhasesAction(!isOrderingPhases)
  }

  const handleAddNewPhase = () => {
    setIsCreatePhaseAction(true)
    setIsCreateNewInventoryItemAction(false)
    setSelectedPhaseAction(null)
  }

  return (
    <DetailsPanelCard
      title={t('model.lifecycle_phases_title')}
      className="lifecycle-phases"
      action={
        !isReadOnly &&
        <Tooltip title={t('model.create', { context: 'phase' })}>
          <Button
            data-cy="add-new-phase"
            className="button"
            onClick={ handleAddNewPhase} >
            <AiOutlinePlus size={20} />
          </Button>
        </Tooltip>
      }>
      <div className="order-phases">
        {t('model.order_phases')} <Switch
          onChange={ orderingPhasesToggle }
          size={ 'small' }
          data-cy="order-phases-switch"
          checked={ isOrderingPhases }
          checkedChildren={ t('global.on') }
          unCheckedChildren={ t('global.off') }
        />
      </div>
    </DetailsPanelCard>
  )
}

export { PhasesManagement }
export default compose(
  withTranslation(),
)(PhasesManagement)
