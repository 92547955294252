import React, { useState, useRef } from 'react'
import { ChromePicker } from 'react-color'
import { Button, Form, AutoComplete, Tooltip } from 'antd'
import { ITagsFormProps, ITag, IOptionTag, IColor } from './tagsForm.interface'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { AiOutlinePlus }  from 'react-icons/ai'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'
import { DEFAULT_TAG_COLOR } from '../../../utils/const'
import { normalize } from '../../../utils/tools'
import ColoredTag from '../colored-tag/coloredTag'
import { DetailsPanelCard } from '../details-panel/detailPanelCard'

const FormItem = Form.Item

const TagsForm = (props:ITagsFormProps) => {
  const {
    createTagSaga,
    spaceID,
    productID,
    isDetailsPanelReadOnly = false,
    selectedProduct,
    t,
    tagsSearchSaga,
    addTagToProductSaga,
    removeTagFromProductSaga,
    setSpaceProductTagListAction,
    productFilterSelectedTags,
    updatePageItems = () => {},
    setProductTagsToProductFilterAction
  } = props

  const [ tagColor, setTagColor ] = useState<string>(DEFAULT_TAG_COLOR)
  const [ tagName, setTagName ] = useState<string>('')
  const [ isShowTagsForm, setIsShowTagsForm ] = useState(false)
  const formRef = useRef<FormInstance>(null)
  const [ tagsOptions, setTagsOptions ] = useState<{ value: string }[]>([])
  const [ isLoading, setIsLoading ] = useState(false)

  const handleSetColor = (color:IColor) => {
    setTagColor(color.hex)
  }

  const handleCreateTag = () => {
    formRef.current !== null && formRef.current.validateFields().then((values: any) => {
      createTagSaga({
        name: values.tag_name,
        color: tagColor,
        spaceID,
        productID,
        cb: () => {
          updatePageItems()
        }
      })
      setIsShowTagsForm(false)
    })
  }

  const handleAddTagToProduct = (tagID:string) => {
    addTagToProductSaga(tagID, productID, () => {
      updatePageItems()
    })
    setIsShowTagsForm(false)
  }

  const getFilteredTagsOptionList = (tagsList:ITag[]) => {
    const filteredTagsOptionList = tagsList.map((el:ITag) => {
      return { label: el.name, value: el.id, id: el.id }
    })
      .filter((optionalTag:IOptionTag) =>
        !selectedProduct.tags.find(productTag => productTag.id === optionalTag.id))
    return filteredTagsOptionList
  }

  const handleTagsSearch = () => {
    formRef.current !== null && formRef.current.validateFields().then((values: any) => {
      tagsSearchSaga(spaceID, values.tag_name ? values.tag_name : '', tagsList => {
        tagsList && tagsList.length ?
          setTagsOptions(getFilteredTagsOptionList(tagsList)) :
          setTagsOptions([])
      })
    })
  }

  const handleRemoveTagFromProduct = (tagID:string) => {
    const filtered = productFilterSelectedTags.filter(el => el !== tagID)
    setIsLoading(true)
    setProductTagsToProductFilterAction(filtered)
    removeTagFromProductSaga(tagID, selectedProduct.id, spaceID, () => {
      tagsSearchSaga(spaceID, '', tagsList => setSpaceProductTagListAction(tagsList))
      updatePageItems()
      setIsLoading(false)
    })
  }

  return <DetailsPanelCard
    className="product-tags"
    title={t('model.tags')}
    action={
      <Tooltip title={t('model.add_tag')}>
        <Button
          data-cy={`add-tag-button-${normalize(selectedProduct.name)}`}
          data-testid="add-tag-button"
          className="add button add-tag-button"
          onClick={() => {
            setIsShowTagsForm(true)
            setTagName('')
          }}
          hidden={ isDetailsPanelReadOnly }>
          <AiOutlinePlus size={20} />
        </Button>
      </Tooltip>
    }>
    <div className="tags-form_tags tags-list--left" data-cy="form-tags-list">
      {
        selectedProduct && selectedProduct.tags && selectedProduct.tags.length ?
          selectedProduct.tags.map(tag =>
            <ColoredTag
              tag={tag}
              isClosable={ !isDetailsPanelReadOnly }
              onClose={() => handleRemoveTagFromProduct(tag.id)}
              isLoading={isLoading}
              key={tag.id}/>
          ) : !isShowTagsForm && <div className="tags-form_tags__empty-text">{ t('model.tagPlaceholder') }</div>
      }
    </div>
    {
      isShowTagsForm && <div>
        <Form
          ref={formRef}
          className="field-container"
          layout="vertical"
          hideRequiredMark
          onFinish={ handleCreateTag }
        >
          <FormItem name="tag_name" label='Add tag'>
            <AutoComplete
              className="cy-add-tag-autocomplite"
              data-cy="tag-name-input"
              options={ tagsOptions }
              onSelect={ handleAddTagToProduct }
              onSearch={ handleTagsSearch }
              onChange={ (name: any) => setTagName(name) }
              onClick={ handleTagsSearch }
              placeholder={t('model.tagPlaceholder')}
            />
          </FormItem>
          <FormItem name="color" label='Select color'>
            <div className="tag-color_wrapper">
              <ChromePicker
                disableAlpha={true}
                onChange={ (e:any) => handleSetColor(e) }
                color={ tagColor } />
            </div>
          </FormItem>
          <div className='tags-action'>
            <Button
              data-cy="submit-create-tag-form"
              disabled={ !tagName }
              className="add button save-tag-button"
              htmlType="submit"
              icon={<CheckOutlined />}>{t('global.save')}</Button>
            <Button className="add button" icon={<CloseOutlined />} onClick={() => setIsShowTagsForm(false)}>{t('global.cancel')}</Button>
          </div>
        </Form>
      </div>
    }

  </DetailsPanelCard>
}

export { TagsForm }
export default compose(
  withTranslation()
)(TagsForm)
