import * as React from 'react'
import * as d3 from 'd3'

import { Arc } from 'd3-shape'
import { useTranslation } from 'react-i18next'
import { getDecimalDisplayValue } from '../../../shared/util/decimal'
import { DecimalViewType } from '../../../shared/enum'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { TDonutItem } from '../../interface/donut-chart.type'
import { removeDonutChartTooltipDisplay, setDonutChartTooltipDisplay } from '../../util/chart'
import { ImpactType } from '../../../shared/enum/impact'

type TDonutItemProps = {
  radius: number,
  slice: any,
  sliceColor: string,
  children?: TDonutItem[],
  onPhaseItemHover: Function
}
export const DonutPhaseItemComponent = ({
  radius,
  slice,
  sliceColor,
  children = [],
  onPhaseItemHover = () => {}
}: TDonutItemProps) => {
  const { t } = useTranslation([ 'common' ])
  const { lifecycleTotalImpact } = React.useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const totalImpactUnit = lifecycleTotalImpact?.unit

  const pie = d3.pie<TDonutItem>()
    .sort((a, b) => d3.ascending(a.order, b.order))
    .value(d => d.impactAmount)
    .startAngle(slice.startAngle)
    .endAngle(slice.endAngle)

  const arc = d3.arc<any, Arc<any, TDonutItem>>()
    .innerRadius(radius * 1.01)
    .outerRadius(radius * 1.5)
    .padAngle(0.007)
    .cornerRadius(3)

  const onMouseOver = (event: any, index: number) => {
    onPhaseItemHover(true)
    const inventoryItem = children[index]
    const [ coefficient, exponent ] = getDecimalDisplayValue({
      value: inventoryItem.impactAmount,
      decimalViewType: DecimalViewType.NumericValue
    })
    setDonutChartTooltipDisplay({
      x: event.clientX,
      y: event.clientY,
      visibility: 'visible',
      title: t('labels.unitOfEntityWithAmount', {
        amount: inventoryItem.data?.amount,
        unit: inventoryItem.data?.product?.unit || 'unit',
        name: inventoryItem.name
      }),
      sliceColor,
      totalImpactUnit,
      impactType: inventoryItem.impactType,
      coefficient,
      exponent
    })
  }

  const onMouseOut = () => {
    onPhaseItemHover(false)
    removeDonutChartTooltipDisplay()
  }

  return (
    <>
      {
        pie(children).map((outerSlice: any, index: number) => {
          const backgrounColor = children[index].impactType == ImpactType.Negative ? 'var(--green-200)' : sliceColor
          return (
            <path
              key={index}
              fill={backgrounColor}
              d={arc(outerSlice) || undefined}
              onMouseOut={onMouseOut}
              onMouseOver={(event: any) => onMouseOver(event, index)}
            />
          )
        })
      }
    </>
  )
}
