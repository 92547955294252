import { connect } from 'react-redux'
import { withRouter } from '../../utils/with-router'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import {
  setAccountInvoicesAction,
  setAccountSubscriptionAction,
  setSubscriptionProductsAction
} from '../../redux/actions/global.actions'
import { addErrorNotificationAction, addSuccessNotificationAction } from '../../redux/actions/notification.actions'
import { loadAccountSettingsSaga, setAccountInvoicesSaga, setAccountUsersAndRolesSaga } from '../../redux/sagas/account.saga'
import { getSubscriptionProductsSaga } from '../../redux/sagas/stripe.saga'
import PoliciesSelector from '../../redux/selectors/policies.selector'

import { returnNested } from '../../utils/tools'
import { AccountSettingPageLocation } from '../shared/locations'
import settings from './settings'

const mapStateToProps = (state, ownProps) => {
  const params = AccountSettingPageLocation.parseLocationParams(ownProps.location)
  return ({
    activeTabKey: returnNested(params, 'activeTabKey'),
    stripeSuccess: returnNested(params, 'stripe_success'),
    stripeMessage: returnNested(params, 'stripe_message'),
    sessionId: returnNested(params, 'session_id'),

    accountId: returnNested(state, 'global', 'selectedAccount', 'id'),
    accountName: returnNested(state, 'global', 'selectedAccount', 'name'),
    accountPermissionsList: returnNested(state, 'global', 'selectedAccount', 'accountPermissions'),
    accountUsersList: returnNested(state, 'global', 'selectedAccount', 'accountUsers'),
    accountRolesList: returnNested(state, 'global', 'selectedAccount', 'accountRoles'),
    canManageBilling: PoliciesSelector.canManageBilling(state),
    canManageAccount: PoliciesSelector.canManageAccount(state)
  })
}

const mapDispatchToProps = {
  setIsDetailsPanelOpenAction,
  addSuccessNotificationAction,
  addErrorNotificationAction,
  setSubscriptionProductsAction,
  setAccountSubscriptionAction,
  getSubscriptionProductsSaga,
  setAccountInvoicesSaga,
  setAccountInvoicesAction,
  loadAccountSettingsSaga,
  setAccountUsersAndRolesSaga
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(settings))

