import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react'
import styled from 'styled-components'
import { Chart } from 'primereact/chart'
import { useTranslation } from 'react-i18next'

import { castNumberToDisplayValue } from '../../../shared/util/decimal'
import { transformToLifecycleFlatLeafItems } from '../../../shared/util/transform-lifecycle'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ITransformedLeafItem } from '../../../product/interface/transformed-leaf-item'

const BarViewWrapper = styled.div`
  min-height: 20rem;

  .p-chart {
    width: 100%;
    position: relative;
  }
`

export const BarComponent = () => {
  const { transformedLifecycleLeafItems = [], lifecycleTotalImpact } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { t } = useTranslation([ 'common' ])
  const transformedFlatLeafItems = useMemo(
    () => transformToLifecycleFlatLeafItems(transformedLifecycleLeafItems, false) as ITransformedLeafItem[],
    [ transformedLifecycleLeafItems ]
  )
  const [ aspectRatio, setAspectRatio ] = useState<number>(0)

  const measureRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const barChartItems: ITransformedLeafItem[] = useMemo(() => {
    const mergedList = transformedFlatLeafItems.reduce((list: ITransformedLeafItem[], transformedFlatLeafItem) => {
      const existingItemIndex = list.findIndex(item => item.id && item.id === transformedFlatLeafItem.id)

      if (existingItemIndex >= 0) {
        const existingItem = list[existingItemIndex]
        const fExistingItemImpact = !existingItem.impactAmount ? 0 : parseFloat(existingItem.impactAmount)
        const fTransformedItemImpact = !transformedFlatLeafItem.impactAmount ? 0 : parseFloat(transformedFlatLeafItem.impactAmount)
        list.splice(existingItemIndex, 1, { ...existingItem, impactAmount: `${fExistingItemImpact + fTransformedItemImpact}` })
      } else {
        list.push(transformedFlatLeafItem)
      }

      return list
    }, [])
    const sortedLeafItems = mergedList.sort((a: ITransformedLeafItem, b: ITransformedLeafItem) => {
      const { impactAmount: aImpactAmount } = a || {}
      const { impactAmount: bImpactAmount } = b || {}

      if (aImpactAmount && bImpactAmount) {
        if (parseFloat(aImpactAmount) < parseFloat(bImpactAmount)) {
          return 1
        } if (parseFloat(aImpactAmount) > parseFloat(bImpactAmount)) {
          return -1
        }
      }

      return 0
    })

    return sortedLeafItems
  }, [ transformedFlatLeafItems ])

  const barChartData = useMemo(() => {
    if (barChartItems.length) {
      const labels = barChartItems.map((value: any) => value.id)
      const datasetBackground = barChartItems.map((value: any) => (value.impactAmount < 0 ? '#b6e89c' : '#97c7eb'))
      const datasetHoverBackground = barChartItems.map((value: any) => (value.impactAmount < 0 ? '#96de70' : '#68aee2'))
      const datasetData = barChartItems.map((value: any) => parseFloat(value.impactAmount))
      const datasets = [ {
        borderRadius: 4,
        maxBarThickness: 30,
        minBarLength: 1,
        backgroundColor: [ ...datasetBackground ],
        hoverBackgroundColor: [ ...datasetHoverBackground ],
        data: [ ...datasetData ]
      } ]
      return { labels, datasets }
    }
    return { labels: [], datasets: [] }
  }, [ barChartItems ])

  useEffect(() => {
    if (containerRef?.current && barChartItems.length) {
      const container = document.querySelector('.bar-view-wrapper')
      const containerMinHeight = container && parseInt(getComputedStyle(container).minHeight)

      const heightRatio = containerMinHeight && containerMinHeight / (barChartItems.length * 40)
      if (!heightRatio) return setAspectRatio(0)
      setAspectRatio(heightRatio < 1.5 ? heightRatio : 1.5)
    }
  }, [ containerRef?.current, barChartItems.length ])

  const getLeafItem = (id: string) => barChartItems.find((item: any) => item.id === id)
  const getMaxImpactAmount = () => Math.max(...barChartItems.map((item: any) => item?.impactAmount || 0))

  const getNumberDisplay = (value: any) => {
    const [ coefficient, exponential ] = castNumberToDisplayValue({ value: value.toString() })
    const maxTwoDecimalPlaces = Math.round((Number.parseFloat(coefficient) + Number.EPSILON) * 100) / 100
    if (exponential) {
      return `${maxTwoDecimalPlaces}e${exponential}`
    }
    return maxTwoDecimalPlaces
  }

  const horizontalOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio,
    animation: false,
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: measureRef.current?.clientWidth ? measureRef.current.clientWidth + 20 : undefined
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltipItem: any) => {
            const { amount, name, unit } = getLeafItem(tooltipItem?.label) || {}
            const unitToShow = unit || 'unit'
            const value = t('labels.unitOfEntityWithAmount', { amount, unit: unitToShow, name })
            const productNameMultiline: string[] = []
            let i = 0
            while (i < value.length) {
              productNameMultiline.push(value.substring(i, i + 40))
              i += 40
            }

            return productNameMultiline
          },
          footer: (tooltipItem: any) => {
            const value = getLeafItem(tooltipItem[0]?.label)
            const impactAmountText = value?.impactAmount && getNumberDisplay(value?.impactAmount)
            return impactAmountText || impactAmountText === 0 ? `Impact : ${impactAmountText} ${lifecycleTotalImpact?.unit}` : ''
          }
        }
      }
    },
    scales: {
      x: {
        max: getMaxImpactAmount(),
        ticks: {
          color: '#657c8e',
          beginAtZero: true,
          callback(value: any) {
            return getNumberDisplay(value)
          }
        },
        grid: { color: '#ebedef' }
      },
      y: {
        ticks: { display: false },
        grid: { color: '#ebedef' }
      }
    }
  }

  const beforeDraw = (chart: any) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  }

  const afterDraw = (chart: any) => {
    if (measureRef.current) {
      const { ctx, scales: { y } } = chart

      ctx.save()
      const { clientWidth } = measureRef.current
      y.ticks.forEach((tick: any) => {
        ctx.font = '500 14px sans-serif'
        ctx.fillStyle = '#657c8e'
        const label = getLeafItem(tick.label)?.name
        let i = label?.length || 0
        let displayText = label
        let postfix = ''
        let measuredTextWidth = ctx.measureText(displayText).width
        while (measuredTextWidth > clientWidth - 10 && i > 0) {
          if (i === label?.length) {
            postfix = '...'
          }
          displayText = label?.substr(0, i - 1)
          measuredTextWidth = ctx.measureText(displayText).width
          i--
        }
        ctx.fillText(`${displayText}${postfix}`, clientWidth - measuredTextWidth + 10, y.getPixelForValue(tick.value) + 5)
      })
      ctx.restore()
    }
  }

  return (
    <BarViewWrapper ref={containerRef} className="flex flex-column w-full h-full bar-view-wrapper">
      <div ref={measureRef} className="md:col-3 lg:col-3 xl:col-2 h-1rem white-space-nowrap overflow-hidden text-overflow-clip measure-text"></div>
      { barChartItems.length > 0 && (
        <div className="flex w-full">
          <Chart
            id="product-bar-chart"
            data-testid="product-bar-chart"
            type="bar"
            data={barChartData}
            options={horizontalOptions}
            plugins={[ { afterDraw, beforeDraw } ]}
          />
        </div>
      )}
    </BarViewWrapper>
  )
}
