import React from 'react'
import { BlockOutlined, DownOutlined, FileSyncOutlined, HistoryOutlined, ExportOutlined, DownloadOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Modal, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { withRouter } from '../../../utils/with-router'
import compose from '../../../utils/compose'
import { matchLocation } from '../../../utils/location'
import { LifecycleItemLocation } from '../../shared/locations'

import { isEmpty, returnNested } from '../../../utils/tools'
import LifecycleDropdown from './lifecycle-dropdown/lifecycleDropdown.container'
import ExportProductCsv from './export-product/exportProductCsv.container'
import ComparisonSelectorDialog from '../../helpers/dialogs/comparisonSelectorDialog/comparisonSelectorDialog'
import ScenarioSelectorDialog from '../../helpers/dialogs/scenarioSelectorDialog/scenarioSelectorDialog'
import PdfResults from '../../shared/pdfResults/pdfResults.container'
class ProductTools extends React.Component {
  state = {
    isSelectingScenario: false,
    isSelectingComparison: false,
    isSelectingLifecycle: false,
    isSelectingExportImpacts: false,
    isSelectingExportProductCsv: false,
    isDownloadingPdf: false
  }

  selectProductTool = selectedTool => {
    const { isSkipPhases, lifecycle, setIsLifecycleSkipPhasesSaga } = this.props || {}
    const toolKey = returnNested(selectedTool, 'key')
    if (toolKey === 'download-results-pdf') {
      if (lifecycle && !isSkipPhases) {
        setIsLifecycleSkipPhasesSaga(true, () => this.setState({ isDownloadingPdf: true }))
        return
      }
      this.setState({ isDownloadingPdf: true })
    }
    this.setState({
      isSelectingScenario: toolKey === 'scenario',
      isSelectingComparison: toolKey === 'comparison',
      isSelectingLifecycle: toolKey === 'lifecycle',
      isSelectingExportImpacts: toolKey === 'export-impacts',
      isSelectingExportProductCsv: toolKey === 'export-product-csv'
    })
  }

  closeProductToolModal = () => {
    this.setState({
      isSelectingScenario: false,
      isSelectingComparison: false,
      isSelectingLifecycle: false,
      isSelectingExportImpacts: false,
      isSelectingExportProductCsv: false,
      isDownloadingPdf: false
    })
  }

  handleExportImpacts = () => {
    const { lifecycle, inventoryProductId, exportProductImpactsSaga, exportLifecycleImpactsSaga } = this.props
    isEmpty(lifecycle)
      ? exportProductImpactsSaga(inventoryProductId)
      : exportLifecycleImpactsSaga(lifecycle.id)
  }

  render() {
    const { isShowProductScenarioInventory, isShowLifecycleScenarioInventory, isInventoryCompare, lifecycle, inventoryProductId, scenarioList, t } = this.props
    const { isSelectingScenario, isSelectingComparison, isSelectingLifecycle, isSelectingExportProductCsv, isDownloadingPdf } = this.state
    const isScenarioInputDisabled = isInventoryCompare && !isShowProductScenarioInventory && !isShowLifecycleScenarioInventory
    const isComparisonInputDisabled = isShowProductScenarioInventory || isShowLifecycleScenarioInventory
    const isImpactExportDisabled =  isShowProductScenarioInventory || isShowLifecycleScenarioInventory || isInventoryCompare ||  isSelectingScenario || isInventoryCompare
    const isExportProductCsvDisabled = isImpactExportDisabled || !isEmpty(lifecycle)
    const isLifecycle = matchLocation(LifecycleItemLocation, true)
    const menu = (
      <Menu onClick={this.selectProductTool}>
        <Menu.Item data-cy="menu-item-scenario"
          className={classnames('item', { disabled: isScenarioInputDisabled })}
          key="scenario"
          disabled={isScenarioInputDisabled}
          data-testid="scenario-menu-item">
          <FileSyncOutlined className="icon" />
          <div className="text">
            <span className="title">{t('model.scenario')}</span>
            <span className="description">{t('model.product_scenario_description')}</span>
          </div>
        </Menu.Item>
        <Menu.Item data-cy="menu-item-comparison"
          className={classnames('item', { disabled: isComparisonInputDisabled })}
          key="comparison" disabled={isComparisonInputDisabled}
          data-testid="comparison-menu-item">
          <BlockOutlined className="icon" />
          <div className="text">
            <span className="title">{t('model.comparison')}</span>
            <span className="description">{t('model.product_comparison_description')}</span>
          </div>
        </Menu.Item>
        <Menu.Item
          data-cy="menu-item-lifecycle"
          className="item"
          key="lifecycle"
          data-testid="lifecycle-menu-item">
          <HistoryOutlined className="icon" />
          <div className="text">
            <span className="title">{t('model.lifecycle')}</span>
            <span className="description">{t('model.lifecycle_description')}</span>
          </div>
        </Menu.Item>
        <Menu.Item data-cy="menu-item-export-product-csv"
          className={classnames('item', { disabled: isExportProductCsvDisabled })}
          key="export-product-csv"
          disabled={isExportProductCsvDisabled}
          data-testid="export-product-menu-item"
        >
          <DownloadOutlined className="icon"/>
          <div className="text">
            <span className="title">{t('model.export_product_csv')}</span>
            <span className="description">{t('model.export_product_csv_description')}</span>
          </div>
        </Menu.Item>
        <Menu.Item data-cy="menu-item-export-impacts" onClick={()=> { this.handleExportImpacts() }}
          className={classnames('item', { disabled: isImpactExportDisabled })}
          key="export-impacts"
          disabled={isImpactExportDisabled}
          data-testid="export-impact-menu-item"
        >
          <ExportOutlined className="icon"/>
          <div className="text">
            <span className="title">{t('model.export_impacts')}</span>
            <span className="description">{t('model.export_impacts_description')}</span>
          </div>
        </Menu.Item>
        <Menu.Item data-cy="menu-item-download-results-pdf"
          className={classnames('item', { disabled: isComparisonInputDisabled })}
          key="download-results-pdf"
          disabled={isComparisonInputDisabled}
          data-testid="download-results-pdf-menu-item"
        >
          <FontAwesomeIcon className="icon" icon={faFilePdf} />
          <div className="text">
            <span className="title">{t('model.downloadInsights', { context: 'title' } )}</span>
            <span className="description">{t('model.downloadInsights', { context: 'description' } )}</span>
          </div>
        </Menu.Item>
      </Menu>
    )
    return (
      <React.Fragment>
        <Dropdown
          className="product-tools-button"
          data-cy="product-tools-button"
          overlayClassName="page-header-dropdown product-tools-dropdown"
          overlay={menu}
          trigger={[ 'click' ]}
        >
          <Button size="large" data-cy="product-tools-button" className="button primary" data-testid="product-tools-button">
            {t('model.tools')} <DownOutlined />
          </Button>
        </Dropdown>
        <ScenarioSelectorDialog
          entityId={inventoryProductId}
          isLifecycle={ isLifecycle }
          isSelectingScenario={isSelectingScenario}
          closeScenarioModal={this.closeProductToolModal}
          scenarioList={scenarioList}
        />
        <ComparisonSelectorDialog
          isSelectingComparison={isSelectingComparison}
          closeComparisonModal={this.closeProductToolModal}
        />
        <Modal
          title={t('model.lifecycle')}
          visible={isSelectingLifecycle}
          onCancel={this.closeProductToolModal}
          footer={null}
        >
          <LifecycleDropdown closeProductToolModal={this.closeProductToolModal} />
        </Modal>
        <Modal
          title={t('model.export_product_csv')}
          visible={isSelectingExportProductCsv}
          onCancel={this.closeProductToolModal}
          footer={null}
        >
          <ExportProductCsv closeProductToolModal={this.closeProductToolModal}/>
        </Modal>

        {isDownloadingPdf && <PdfResults onAfterDownload={this.closeProductToolModal} /> }
      </React.Fragment>
    )
  }
}
export { ProductTools }
export default compose(
  withTranslation(),
  withRouter
)(ProductTools)
