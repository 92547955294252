import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Frame } from '../../component/layout'
import { AnalysisMode, AnalysisType } from './enum/analysis'

import { useUrlQuery } from '../shared/hook/use-url-query'
import { styledComponentTheme } from '../shared/constants/theme'

import { enableNewScenarioV1Var } from '../../graphql/cache'
import { AnalysisProvider } from './provider/analysis.provider'
import { LifecycleDetailProvider } from '../lifecycle/provider/lifecycle-detail.provider'
import { LifecycleDetailContainer } from '../lifecycle/container/lifecycle-detail.container'
import { LifecycleAnalysisLayout } from './layout/lifecycle-analysis.layout'
import { store } from '../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { setIsDetailPanelReadonlyV1Action, setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { useIsMounted } from '../shared/hook/use-is-mounted'

type TProductAnalysisEntryProps = {
  analysisMode?: AnalysisMode
}
export const LifecycleAnalysisEntry = ({
  analysisMode = AnalysisMode.Comparison
}: TProductAnalysisEntryProps) => {
  const urlQuery = useUrlQuery()
  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted()) {
      // TODO : Added for compatibility with v0.9
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }

    return () => {
      store.dispatch(setIsDetailPanelReadonlyV1Action(false))
    }
  }, [ isMounted ])

  const { lifecycleId: primaryEntityId } = useParams() || {}
  const secondaryEntityId = urlQuery.get('id') || ''
  const secondaryEntityType = urlQuery.get('type') || ''
  const analysisInScenario = analysisMode === AnalysisMode.Scenario
  enableNewScenarioV1Var(analysisInScenario)

  return (
    <AnalysisProvider {...{
      primaryEntityId,
      secondaryEntityId,
      secondaryEntityType,
      analysisMode,
      analysisType: AnalysisType.LifeCycleToLifeCycle
    }}
    >
      <LifecycleDetailProvider
        readonlyInventory
        noInventoryImpactBar
        showInventoryHeader={false}
        analysisMode={analysisMode}
      >
        <ThemeProvider theme={styledComponentTheme}>
          <Frame>
            { () => primaryEntityId && secondaryEntityId
            && (
              <LifecycleDetailContainer lifecycleId={primaryEntityId}>
                <LifecycleAnalysisLayout />
              </LifecycleDetailContainer>
            ) }
          </Frame>
        </ThemeProvider>
      </LifecycleDetailProvider>
    </AnalysisProvider>
  )
}
