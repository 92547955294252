import { returnNested } from '../../utils/tools'

const getCurrentUserId = state => returnNested(state, 'global', 'user', 'id')

const root = state => returnNested(state, 'settings')
const currentUserSettings = state => returnNested(root(state), 'userSettings', getCurrentUserId(state))
const lastSelectedAccountId = state => returnNested(currentUserSettings(state), 'lastSelectedAccountId')
const lastSelectedSpaceId = state => returnNested(currentUserSettings(state), 'lastSelectedSpaceId')
const layoutViewType = state => returnNested(currentUserSettings(state), 'layoutViewType')

const SettingsSelector = {
  lastSelectedAccountId,
  lastSelectedSpaceId,
  layoutViewType
}

export default SettingsSelector
