import { gql } from '@apollo/client'

export default gql`
  query accountInvitedUsers($id: ID!){
    account(id: $id) {
      invitedUsers {
        name
        email
        roles {
          id
          name
          label
        }
      }
    }
  }
`
