import { connect } from 'react-redux'
import { InventoryTree } from './inventoryTree'
import InventoryExpandedKeysSelector from '../../../../redux/selectors/inventoryExpandedKeys.selector'
import { returnNested } from '../../../../utils/tools'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { toggleExpandedKeySaga } from '../../../../redux/sagas/inventoryTree.saga'
import { selectedInventoryItemSaga } from '../../../../redux/sagas/product.saga'
import { selectedKeysSelector } from '../../../../redux/selectors/inventoryTree.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import GlobalSelector from '../../../../redux/selectors/global.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'

const mapStateToProps = (state, ownProps) => {
  const rootId = returnNested(ownProps, 'inventoryTree', 'product', 'id')

  return {
    selectedKeys: selectedKeysSelector(state),
    isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state),
    expandedKeys: InventoryExpandedKeysSelector.expandedKeysByRootId(state, rootId),
    selectedProductId: SelectedProductSelector.productId(state),
    amountModalPreference: GlobalSelector.amountModalPreference(state)
  }
}

const mapDispatchToProps = {
  toggleExpandedKeySaga,
  selectedInventoryItemSaga,
  clearUiWhenDetailPanelCloseAction,
  setIsDetailsPanelOpenAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryTree)

