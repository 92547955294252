import { gql } from '@apollo/client'
import role from '../fragment/role'
/*    Bug : Access to the resource is forbidden GraphQL error. Access to the resource is forbidden
      apiClients {
        id
        name
      }

*/
export default gql`
   query accountRoles($id: ID!){
    accountRoles(id: $id){
      ...roleFragment
    }
  }
  ${role}
`
