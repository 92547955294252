import { returnNested, safeArray } from './tools'

/**
 * @param collection array
 * @param action array
 * @returns {Object}
 */
export const getUpdatedListByActionParams = (collection, action) => {
  const updatedCollection = collection && safeArray(collection).map(el => {
    if (el.id === action.id) {
      el.name = action.name
      el.slug = action.slug
    }
    return el
  })
  return updatedCollection
}

/**
 * @param accountPermission object
 * @returns object
 */
export const mapAccountPermission = accountPermission => {
  const mappedAccountPermission = {
    id: accountPermission.id,
    key: accountPermission.id,
    ownerId: accountPermission.who && accountPermission.who.id,
    name: accountPermission.who && accountPermission.who.name,
    policies: safeArray(accountPermission.how).map(how => how.id),
  }

  return mappedAccountPermission
}

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action={}) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
