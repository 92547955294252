import { gql } from '@apollo/client'

export default gql`
  query referenceDatabases($methodId: ID!) {
    referenceDatabases(methodId: $methodId) {
        id
        name
        version
    }
  }
`
