import React, { useContext } from 'react'

import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { ComparisonEntity } from '../../../enum/entity'

import { LifecycleDetailProvider } from '../../../../lifecycle/provider/lifecycle-detail.provider'
import { LifecycleDetailContainer } from '../../../../lifecycle/container/lifecycle-detail.container'
import { LifecycleWrapperLayout } from '../../../layout/lifecycle-wrapper.layout'
import { AnalysisMode } from '../../../enum/analysis'

type TModelPanelComponentProps = {
  entity?: ComparisonEntity
}
export const ModelPanelComponent = ({
  entity = ComparisonEntity.Primary
}: TModelPanelComponentProps) => {
  const {
    analysisMode,
    secondaryEntityId = ''
  } = useContext<TAnalysisContext>(AnalysisContext)

  const isPrimary = entity === ComparisonEntity.Primary
  const isComparison = analysisMode === AnalysisMode.Comparison

  return (
    <>
      { !isPrimary && secondaryEntityId
        ? (
          <LifecycleDetailProvider
            readonlyInventory={isComparison}
            showInventoryHeader={false}
            analysisMode={analysisMode}
            treeIsSecondary
            noInventoryImpactBar
          >
            <LifecycleDetailContainer lifecycleId={secondaryEntityId}>
              <LifecycleWrapperLayout entity={entity} />
            </LifecycleDetailContainer>
          </LifecycleDetailProvider>
        )
        : <LifecycleWrapperLayout />}
    </>
  )
}
