import React from 'react'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, notification, Select, Table, Tooltip } from 'antd'
import { notify, handleModal, normalize, safeArray, filterOptionByValue, parseGraphQLErrorsForNoSagaQueries } from '../../utils/tools'
import resource from '../../utils/resource/resource'
import { TRACK_EVENT_TYPE } from '../../utils/const'
import DashboardContext from '../../v1/dashboard/context/dashboard.context'

class RoleManagement extends React.Component {
  static contextType = DashboardContext
  state = {
    accountRolesListForAddRole: null,
    newAccountRoleName: null,
  }

  componentDidMount() {
    this.props.trackPageSaga(TRACK_EVENT_TYPE.VIEWED_ROLES_SETTINGS)
  }

  handleAdd = () => {
    const { accountRolesList } = this.props
    const newRole = {
      label: '',
      key: '',
      id: '',
      users: [],
      editable: true,
    }
    const accountRolesListForAddRole = [ newRole, ...safeArray(accountRolesList) ]
    this.setState({ accountRolesListForAddRole })
  }

  handleCancel = () => {
    this.setState({ accountRolesListForAddRole: null })
  }

  handleRoleAdd = () => {
    const { addAccountRole, t, accountId } = this.props
    const { refetchWorkspace } = this.context
    const { newAccountRoleName } = this.state
    if (!newAccountRoleName) return
    resource.mutateByParams('createRole', { label: newAccountRoleName, accountID: accountId })
      .then(addedRole => {
        refetchWorkspace()
        addAccountRole(addedRole)
        this.setState({ accountRolesListForAddRole: null, newAccountRoleName: null })
        notify({ type: 'success', message: 'account_settings.role_created_success', notification, t })
      }).catch(error => {
        this.setState({ accountRolesListForAddRole: null })
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
    this.setState({ newAccountRoleName: null })
  }

  handleRoleDelete = accountRoleId => {
    const { removeAccountRole, t } = this.props
    const { refetchWorkspace } = this.context
    handleModal({
      title: t('account_settings.delete_role'),
      content: t('account_settings.confirm_delete_role'),
      onOk: () => {
        resource.mutateByParams('removeRole', { id: accountRoleId })
          .then(() => {
            refetchWorkspace()
            removeAccountRole(accountRoleId)
            notify({ type: 'success', message: 'account_settings.role_deleted_success', notification, t })
          }).catch(error => {
            notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
          })
      }
    })

  }

  handleAssignUserToRole = ({ userId, roleId }) => {
    const {
      accountApiClientsList,
      accountUsersList,
      addAccountUserToRole,
      addAccountApiClientToRole,
      t
    } = this.props

    const isApiClient = this.isApiClient(userId)
    const { refetchWorkspace } = this.context
    const action = isApiClient
      ? { name: 'assignApiClientToRole', args: { roleID: roleId, apiClientID: userId } }
      : { name: 'assignUserToRole', args: { roleID: roleId, userID: userId } }
    const updateAction = isApiClient ? addAccountApiClientToRole : addAccountUserToRole
    const user = isApiClient ? accountApiClientsList && accountApiClientsList.find(el => el.id === userId) : accountUsersList && accountUsersList.find(el => el.id === userId)

    resource.mutateByParams( action.name, action.args)
      .then(() => {
        refetchWorkspace()
        updateAction(roleId, userId, user.name)
      }).catch(error => {
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
  }

  handleUnassignUserFromRole = ({ userId, roleId }) => {
    const {
      removeAccountUserFromRole,
      removeAccountApiClientFromRole,
      t
    } = this.props

    const { refetchWorkspace } = this.context
    const isApiClient = this.isApiClient(userId)
    const action = isApiClient
      ? { name: 'unassignApiClientFromRole', args: { roleID: roleId, apiClientID: userId } }
      : { name: 'unassignUserFromRole', args: { roleID: roleId, userID: userId } }
    const updateAction = isApiClient ? removeAccountApiClientFromRole : removeAccountUserFromRole

    resource.mutateByParams(action.name, action.args)
      .then(() => {
        refetchWorkspace()
        updateAction(roleId, userId)
      }).catch(error => {
        notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
      })
  }

  handleNewRoleName = evt => {
    this.setState({ newAccountRoleName: evt.target.value })
  }

  isApiClient = userId => {
    const { accountApiClientsList } = this.props
    return (accountApiClientsList && accountApiClientsList.find(apiClient => apiClient.id === userId)) ? true : false
  }

  getTableColumns = () => {
    const { accountApiClientsList, accountUsersList, t } = this.props
    const { newAccountRoleName } = this.state
    return [
      { title: t('account_settings.role_name'), dataIndex: 'label', key: 'name', render: (text, role) => {
        return role.editable ?
          <Input data-cy="new-role-input" onPressEnter={this.handleRoleAdd} onChange={this.handleNewRoleName} value={ newAccountRoleName } placeholder={t('account_settings.role_name')} id="newRoleName" />
          :
          <span data-cy="role-row" id={role.id}>{text}</span>
      }
      },
      { title: t('account_settings.assign_users'), dataIndex: 'label', key: 'user', render: (text, role) => {
        const usersMap = role.users && role.users.map(user => user.id)
        const apiClientsMap = role.apiClients && role.apiClients.map(apiClient => apiClient.id)
        return (
          role.id && <Select
            value={usersMap.concat(apiClientsMap)}
            mode='multiple'
            data-cy={`users-for-role-${normalize(text)}`}
            filterOption={(inputValue, option) => filterOptionByValue(inputValue, option)}
            style={{ width: '100%' }}
            showSearch
            placeholder={t('account_settings.assign_users')}
            onSelect={value => this.handleAssignUserToRole({ userId: value, roleId: role.id })}
            onDeselect={value => this.handleUnassignUserFromRole({ userId: value, roleId: role.id })}
            dropdownClassName={`cy-dropdown-of-${normalize(text)}`}
          >
            <Select.OptGroup label={ t('permission_manager.users') } key="users">
              { accountUsersList && accountUsersList.map(user => (
                <Select.Option key={ user.id } value={ user.id }>{ user.name }</Select.Option>))
              }
            </Select.OptGroup>
            <Select.OptGroup label={ t('permission_manager.api_clients') } key="api_clients">
              { accountApiClientsList && accountApiClientsList.map(apiClient => (
                <Select.Option key={ apiClient.id } value={ apiClient.id }>{ apiClient.name }</Select.Option>))
              }
            </Select.OptGroup>
          </Select>
        )
      } },
      { title: t('account_settings.action'), align: 'center', className: 'action', key: 'action', width: '30px', render: (text, role) => {
        if (role.editable) {
          return (
            <React.Fragment>
              <Button
                data-cy="save-current-role-button"
                type="button"
                className="button icon transparent"
                disabled={!newAccountRoleName}
                onClick={this.handleRoleAdd}>
                <CheckOutlined />
              </Button>
              <Button data-cy="delete-current-role-button" type="button" className="button icon transparent" onClick={this.handleCancel}><CloseOutlined /></Button>
            </React.Fragment>
          )
        } else {
          const dataCyRole = 'button-delete-role-named-' + (!role || !role.label ? '' : role.label.replace(/\s/g, '-').toLowerCase())
          return (
            <Tooltip title={t('account_settings.delete_role')} placement="bottom" mouseEnterDelay={1}>
              <Button data-cy={dataCyRole} type="button" onClick={() => this.handleRoleDelete(role.id)} className="button icon transparent"><DeleteOutlined /></Button>
            </Tooltip>
          )
        }
      } }
    ]
  }

  render() {
    const {  accountRolesList: currentAccountRolesList, t } = this.props
    const { accountRolesListForAddRole } = this.state
    const isAddingRole = accountRolesListForAddRole ? true : false
    const accountRolesList = accountRolesListForAddRole || currentAccountRolesList
    const roleList = accountRolesList && accountRolesList.map(role => ({ key: role.id, ...role }))

    return (
      <React.Fragment>

        <Table
          title={() => <Button data-cy="add-new-role-button" onClick={this.handleAdd} type="primary" className="button primary" disabled={isAddingRole} icon={<PlusOutlined />}>{t('account_settings.add_role')}</Button>}
          rowKey={(record, index) => `${index}:${record.id}`}
          dataSource={roleList}
          className="table-container"
          pagination={ false }
          columns={this.getTableColumns()}
        />
      </React.Fragment>
    )
  }
}
export { RoleManagement }
export default compose(
  withTranslation(),
)(RoleManagement)
