import { gql } from '@apollo/client'
import minimalProduct from './minimalProduct'

export default gql`
    fragment minimalInventoryFragment on Inventory {
        product {
            ...minimalProductFragment
        }
        inventoryItems {
            nodeId
            parentNode
            inventoryItem{
                amount
                conversionID
                impact {
                    amount
                    unit
                    status
                }
                product {
                    ...minimalProductFragment
                    referenceProduct {
                      id
                    }
                    customImpacts {
                      amount
                    }
                }
            }
        }
        totalImpact {
            amount
            unit
            status
        }
    }
  ${minimalProduct}
`
