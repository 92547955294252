import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { selectedWorkspaceVar } from '../../../../graphql/cache'

export const PngHeaderTemplateComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const {
    productInventory: { product: { name: productName = null } = {} } = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { space: { impactCategory = {} } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  return (
    <div data-testid="png-header-wrapper" className="hidden w-full pb-6 png-header-template text-2xl font-normal png-export-wrapper">
      { impactCategory?.name
        ? t('labels.header', { context: 'pngExport', productName, impactCategoryName: impactCategory.name })
        : t('labels.header', { context: 'pngExportWithoutImpact', productName })}
    </div>
  )
}
