import { connect } from 'react-redux'
import {
  setIsCreateLifecycleAction,
  setIsCreatePhaseAction,
  setIsDetailsPanelOpenAction,
} from '../../../../redux/actions/flags.actions'
import { InventoryLifecycleSelector } from '../../../../redux/selectors/inventoryLifecycles.selector'
import { setSelectedProductAction } from '../../../../redux/actions/global.actions'
import { setSelectedPhaseAction } from '../../../../redux/actions/lifecycle.actions'
import { inventoryRootItemIdSelector } from '../../../../redux/selectors/inventoryTree.selector'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import AccountSelector from '../../../../redux/selectors/account.selector'
import LifecycleDropdown from './lifecycleDropdown'

const mapStateToProps = state => {
  return ({
    accountId: AccountSelector.selectedAccountId(state),
    lifecycles: InventoryLifecycleSelector.lifecycles(state),
    lifecycle: LifecycleSelector.selectedLifecycle(state),
    inventoryRootItemId: inventoryRootItemIdSelector(state)
  })
}

const mapDispatchToProps = {
  setIsCreateLifecycleAction,
  setIsDetailsPanelOpenAction,
  setIsCreatePhaseAction,
  setSelectedPhaseAction,
  setSelectedProductAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleDropdown)
