import { gql } from '@apollo/client'

export default gql`
  query impactCategories($methodID: ID!){
    impactCategories(methodID: $methodID) {
      id
      impactMethodID
      name
      unit
    }
  }
`
