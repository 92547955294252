export enum ImpactType {
  Positive = 'positive',
  Negative = 'negative'
}

export enum ImpactSource {
  Dataset = 'dataset',
  ElementaryFlow = 'elementaryFlow',
  Custom = 'custom'
}
