import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'

import { ProductProvider } from './provider/product.provider'

import { Frame } from '../../component/layout'
import { ProductOverviewContainer } from './container/product-overview.container'

import { useWindowSize } from '../shared/hook/use-window-size'
import { getOverviewPageSize } from '../shared/util/screen'
import { store } from '../../configureStore'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { getOverviewSetting } from '../shared/util/overview'
import { ProductLabel } from '../shared/enum'
import { setProductLabelAction } from '../../redux/actions/global.actions'
import { IDashboardContext } from '../shared/interface/workspace-context-type'
import DashboardContext from '../dashboard/context/dashboard.context'
import { useIsMounted } from '../shared/hook/use-is-mounted'
import { ProductSortField } from '../../__generated__/graphql'
import { selectedWorkspaceVar } from '../../graphql/cache'

export const ObjectOverviewEntry = () => {
  const isMounted = useIsMounted()
  const [ screenWidth, screenHeight ] = useWindowSize()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { cardPageSize, tablePageSize } = getOverviewPageSize(screenWidth, screenHeight)
  const { selectedViewType, selectedSortBy, selectedSortOrder } = getOverviewSetting()
  const { clearBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    if (isMounted()) {
      clearBackToList()
      // TODO : Added for compatibility with v0.9
      store.dispatch(setProductLabelAction(ProductLabel.Object))
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }
  }, [ isMounted ])

  return (
    <>
      { screenWidth
      && (
        <ProductProvider
          label={ProductLabel.Object}
          cardPageSize={cardPageSize}
          tablePageSize={tablePageSize}
          selectedViewType={selectedViewType}
          selectedSortBy={selectedSortBy as ProductSortField}
          selectedSortOrder={selectedSortOrder}
        >
          <Frame>
            { () => (
              <>
                { space && <ProductOverviewContainer />}
              </>
            ) }
          </Frame>
        </ProductProvider>
      )}
    </>
  )
}
