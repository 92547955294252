import { gql } from '@apollo/client'

export default gql`
  query categories($parentId: String!, $types: [String!]!) {
    categories(parentId: $parentId, types: $types) {
        id
        name
        description
        isLeaf
    }
  }
`
