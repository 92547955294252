import React from 'react'
import * as Sentry from '@sentry/react'

class SentryScope extends React.Component {

  componentDidMount() {
    this.setSentryScope()
  }

  componentDidUpdate(prevProps) {
    const { accountId, spaceId, isAdBlockEnabled } = this.props
    if (
      accountId !== prevProps.accountId ||
      spaceId !== prevProps.spaceId ||
      isAdBlockEnabled !== prevProps.isAdBlockEnabled
    ) {
      this.setSentryScope()
    }
  }

  setSentryScope = () => {
    const { userId, accountId, accountName, spaceId, spaceName, isAdBlockEnabled } = this.props
    Sentry.configureScope(function(scope) {
      scope.setUser({ 'id': userId })
      scope.setExtras({
        'account_id': accountId,
        'account_name': accountName,
        'workspace_id': spaceId,
        'workspace_name': spaceName,
        'adblock_enabled': isAdBlockEnabled
      })
    })
  }

  render() {
    return null
  }
}

export default SentryScope

