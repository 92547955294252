import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withRouter } from '../../../../../utils/with-router'
import compose from '../../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { Form } from 'antd'
import { VALIDATION } from '../../../../../utils/const'
import InputWrapper from '../../../../helpers/form/inputWrapper'
import { DetailsPanelCard } from '../../detailPanelCard'
import { selectedAccountVar, currentWorkspaceSlugVar } from '../../../../../graphql/cache'

const WorkspaceDetailInformation = ({
  renameHandler,
  selected,
  t
}) => {
  const { account = null } = selectedAccountVar() || {}
  const navigate = useNavigate()
  const formRef = React.createRef()
  const [ isEditName, setIsEditName ] = useState(false)
  const { id, name } = selected || {}

  const saveChange = () => {
    const { validateFields, getFieldValue } = formRef.current

    if (getFieldValue('name') === name) {
      setIsEditName(false)
      return
    }

    validateFields([ 'name' ]).then(values => {
      renameHandler(id, values.name, newSpace => {
        setIsEditName(false)
        const { slug = null } = newSpace || {}
        currentWorkspaceSlugVar(slug)
        slug && navigate(`/account/${account?.id}/workspace/${slug}`)
      })
    })
  }

  const cancelChange = () => {
    formRef.current.resetFields()
    setIsEditName(false)
  }

  return (<DetailsPanelCard
    title={t('model.workspace_details')}
    className="workspace-impact-information" >
    <Form
      ref={formRef}
      layout="vertical"
      hideRequiredMark
      className="field-container"
      initialValues={{ name }}>
      <InputWrapper
        isFieldEditable={isEditName}
        dataCy="name"
        displayedText={ name }
        editHandler={() => setIsEditName(true)}
        fieldLabel= {t('global.name')}
        fieldName="name"
        fieldRules= {[
          { min: VALIDATION.GENERAL_NAME_LENGTH, message: t('validation.general_name', { name: 'Name' }) },
          { validator: async (rule, value) => {
            if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
              throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Name' }))
            }
          } },
          { required: true, message: t('validation.is_required', { name: 'Name' }) }
        ]}
        onPressEnter={ saveChange }
        onBlur={ saveChange }
        onPressEscape={ cancelChange }
      />
    </Form>
  </DetailsPanelCard>)

}

export { WorkspaceDetailInformation }
export default compose(
  withRouter,
  withTranslation(),
)(WorkspaceDetailInformation)
