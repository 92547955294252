import { LoadingOutlined } from '@ant-design/icons'
import { Select, Spin } from 'antd'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import compose from '../../../../utils/compose'
import { EntityType, PRODUCT_LABEL } from '../../../../utils/const'
import { matchLocation } from '../../../../utils/location'
import { returnNested, safeArray, normalize } from '../../../../utils/tools'
import { InventoryPageLocation, LifecycleItemLocation, ProductListPageLocation, tabTreeView, tabFlatView, ObjectListPageLocation, LifecycleListPageLocation } from '../../../shared/locations'
import { TRACK_EVENT_TYPE } from '../../../../utils/const'
import DashboardContext from '../../../../v1/dashboard/context/dashboard.context'

const { OptGroup, Option } = Select

class CompareDropdown extends PureComponent {
  static contextType = DashboardContext
  constructor(props) {
    super(props)
    this.fetchProductComparisonList = this.fetchProductComparisonList.bind(this)
    this.handleSetComparison = this.handleSetComparison.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  fetchProductComparisonList(value) {
    this.props.getComparisonEntitiesSaga(value)
  }

  componentDidMount() {
    this.fetchProductComparisonList('')
  }

  handleSetComparison (value) {
    const {
      comparisonEntries,
      closeProductToolModal,
      navigate,
      trackEventSaga,
      accountId,
      selectedProductId,
      selectedProduct,
      selectedLifecycleId,
      selectedLifecycle
    } = this.props
    const entry = comparisonEntries.find(el => el.id === value)
    const { addToBackToList = () => {} } = this.context
    const compareParams = {
      compareEntityId: entry.id,
      compareEntityType: entry.__typename,
    }

    closeProductToolModal()
    trackEventSaga(TRACK_EVENT_TYPE.OPENED_COMPARISON)

    const productLocationSource = (
      ( matchLocation(ProductListPageLocation) && ProductListPageLocation ) ||
      ( matchLocation(ObjectListPageLocation) && ObjectListPageLocation ) ||
      ( matchLocation(InventoryPageLocation) && InventoryPageLocation )
    )

    const lifecycleLocationSource = (
      ( matchLocation(LifecycleListPageLocation) && LifecycleListPageLocation ) ||
      ( matchLocation(LifecycleItemLocation) && LifecycleItemLocation )
    )

    if (productLocationSource) {
      selectedProduct?.name && addToBackToList(selectedProduct.name)
      navigate(InventoryPageLocation.toUrl({
        accountId,
        productId: selectedProductId,
        ...productLocationSource.parseLocationParams(),
        ...compareParams,
        activeTabKey: entry.__typename === EntityType.PRODUCT ? tabTreeView : tabFlatView
      }))
      return
    }

    if (lifecycleLocationSource) {
      selectedLifecycle?.name && addToBackToList(selectedLifecycle.name)
      navigate(LifecycleItemLocation.toUrl({
        accountId,
        lifecycleId: selectedLifecycleId,
        ...lifecycleLocationSource.parseLocationParams(),
        ...compareParams,
        activeTabKey: entry.__typename === EntityType.LIFECYCLE ? tabTreeView : tabFlatView
      }))
      return
    }
  }

  handleOnChange(value) {
    const { closeProductToolModal, navigate, inventoryRootItemId, accountId } = this.props
    if (value) return // Do nothing handleSetComparison handles non-empty value
    closeProductToolModal()
    matchLocation(InventoryPageLocation)
      ? navigate(InventoryPageLocation.toUrl({
        ...InventoryPageLocation.parseLocationParams(),
        accountId,
        compareEntityId: undefined,
        compareEntityType: undefined,
      }))
      : navigate(LifecycleItemLocation.toUrl({
        ...LifecycleItemLocation.parseLocationParams(),
        accountId,
        productId: inventoryRootItemId,
        compareEntityId: undefined,
        compareEntityType: undefined,
      }))

  }

  getOptions() {
    const { comparisonEntries, t } = this.props
    const currentEntityId = 1

    const mapOption = entity => (
      <Option
        data-cy={`product-to-compare-named-${normalize(entity.name)}`}
        disabled={ entity.id === currentEntityId }
        key={ entity.id }>
        { entity.name }
      </Option>
    )

    const getOptionsByLabel = (comparisonEntries = [], label) => {
      const hasEntryLabel = entry => {
        const { labels = [] } = entry
        return labels.some(item => item.id === label)
      }
      return <OptGroup key={label} label={t('model.label', { context: label })}>
        {comparisonEntries
          .filter(entry => hasEntryLabel(entry))
          .map(mapOption)
        }
      </OptGroup>
    }

    const groupProducts = getOptionsByLabel(comparisonEntries, PRODUCT_LABEL.PRODUCT)
    const groupObjects = getOptionsByLabel(comparisonEntries, PRODUCT_LABEL.OBJECT)

    const groupLifecycles = <OptGroup key="lifecycles" label={t('model.lifecycles')}>
      { safeArray(comparisonEntries).filter(entry => entry.__typename === EntityType.LIFECYCLE).map(mapOption) }
    </OptGroup>

    return { groupProducts, groupObjects, groupLifecycles }
  }

  render() {
    const { entityToCompare, isComparisonEntriesLoading, t } = this.props
    const { groupProducts, groupObjects, groupLifecycles } = this.getOptions()
    return (
      <React.Fragment>
        <label>{t('model.product_compare_title')}</label>
        <Select
          showSearch={true}
          className="comparison-search-box"
          value={returnNested(entityToCompare, 'id')}
          data-cy='compare-product-select'
          allowClear={true}
          dropdownClassName='comparison-search-box-dropdown'
          placeholder={t('model.compare_placeholder')}
          defaultActiveFirstOption={false}
          dropdownMatchSelectWidth={false}
          filterOption={false}
          onChange={this.handleOnChange}
          onSearch={this.fetchProductComparisonList}
          onSelect={this.handleSetComparison}
          getPopupContainer={node => node.parentElement}
          notFoundContent={ isComparisonEntriesLoading
            ? <Spin indicator={<LoadingOutlined className='default-loader-color' style={{ fontSize: 24 }} spin />} />
            : <i>{t('model.comparison_search_placeholder')}</i>
          }
        >
          {groupProducts}
          {groupObjects}
          {groupLifecycles}
        </Select>
      </React.Fragment>
    )
  }
}

CompareDropdown.propTypes = {}

export default compose(
  withTranslation(),
  withRouter
)(CompareDropdown)
