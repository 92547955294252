import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Card, Form } from 'antd'
import classnames from 'classnames'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { CUSTOMER_TYPE } from '../../../utils/const'
import { isEmpty, returnNested } from '../../../utils/tools'
import CustomerFields from './customerFields'

class Customer extends React.Component {
  state = {
    isFormEditable: false,
  }
  formRef = React.createRef()
  handleSubmit = () => {
    const { selectedAccountId, updateBillingCustomerSaga } = this.props
    this.formRef.current.validateFields().then(values => {
      updateBillingCustomerSaga({
        accountId: selectedAccountId,
        name: values.name,
        email: values.email,
        phone: values.phone,
        country: values.country,
        line1: values.line1,
        line2: values.line2,
        postalCode: values.postalCode,
        city: values.city,
        state: values.state,
        vat: values.vat,
        cb: () => {
          this.formRef.current.resetFields()
          this.setState({
            isFormEditable: false
          })
        }
      })
    })
  }
  onChangeHandler = () => {
    this.setState({
      isFormEditable: true,
    })
  }
  onClickCancel = () => {
    this.formRef.current.resetFields()
    this.setState({
      isFormEditable: false,
    })
  }

  render() {
    const { selectedAccountCustomer, t } = this.props
    const { isFormEditable } = this.state
    return (
      <div className="customer-details">
        <Card
          type="inner"
          className="card-item card-title-big"
          title={t('billing.customer')}
          extra={
            // TODO maybe a switch
          // <React.Fragment>
          //   {t('global.edit')}
          //   <Switch
          //     checkedChildren={t('global.on')}
          //     unCheckedChildren={t('global.off')}
          //     onClick={this.onChangeHandler}
          //     checked={isFormEditable}
          //   />
          // </React.Fragment>

            <Button
              data-cy="button-edit-impact-settings"
              className="button"
              htmlType="button"
              onClick={this.onChangeHandler}
              disabled={isFormEditable}
            >{t('global.change')}</Button>
          }
        >
          <Form
            ref={this.formRef}
            onFinish={ this.handleSubmit }
            layout="vertical"
            hideRequiredMark
            className={classnames('field-container', { 'editable-field': isFormEditable })}
            initialValues={{
              name: returnNested(selectedAccountCustomer, 'name'),
              email: returnNested(selectedAccountCustomer, 'email'),
              phone: returnNested(selectedAccountCustomer, 'phone'),
              country: returnNested(selectedAccountCustomer, 'address', 'country'),
              line1: returnNested(selectedAccountCustomer, 'address', 'line1'),
              line2: returnNested(selectedAccountCustomer, 'address', 'line2'),
              postalCode: returnNested(selectedAccountCustomer, 'address', 'postalCode'),
              city: returnNested(selectedAccountCustomer, 'address', 'city'),
              state: returnNested(selectedAccountCustomer, 'address', 'state'),
              customerType: isEmpty(returnNested(selectedAccountCustomer, 'vat')) ? CUSTOMER_TYPE.PRIVATE : CUSTOMER_TYPE.BUSINESS,
              vat: returnNested(selectedAccountCustomer, 'vat'),
            }}>
            <CustomerFields customer={selectedAccountCustomer} isFormEditable={isFormEditable}/>
            {
              isFormEditable && <Form.Item>
                <React.Fragment>
                  <Button type="button" htmlType="submit" className="button button-right-margin" icon={<CheckOutlined />}>{t('global.save')}</Button>
                  <Button className="button" type="button" onClick={this.onClickCancel} ><CloseOutlined /> {t('global.cancel')}</Button>
                </React.Fragment>
              </Form.Item>

            }
          </Form>
        </Card>
      </div>
    )
  }
}

export default compose(
  withTranslation()
)(Customer)
