import { Button, Divider, notification } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import { CSVLink } from 'react-csv'
import compose from '../../../../utils/compose'
import { notify } from '../../../../utils/tools'
import { EXPORT_PRODUCT_CSV_HEADERS } from '../../../../utils/const'
import resource from '../../../../utils/resource/resource'

class ExportProductCsv extends React.Component {
  constructor(props) {
    super(props)
    this.csvLinkRef = React.createRef()
    this.state = {
      productExportData: []
    }
  }

  handleExportProductCsv = () => {
    const { inventoryProductId, closeProductToolModal, t } = this.props

    resource.queryByParams('productExport', { id: inventoryProductId })
      .then(data => {
        this.setState({ productExportData: data }, () => {
          const csvLinkButton = this.csvLinkRef.current.link
          if (csvLinkButton) csvLinkButton.click()
          closeProductToolModal()
        })
      }).catch(({ graphQLErrors: message }) => {
        notify({ message, notification, t })
      })
  }

  getCSVHeaders = () => {
    const { t } = this.props

    return EXPORT_PRODUCT_CSV_HEADERS.map(el => {
      return {
        key: el.queryKey,
        label: t(`product_csv_headers.${el.translationKey}`)
      }
    })
  }

  render() {
    const { inventoryProductName, t } = this.props
    const { productExportData } = this.state
    const CSVHeaders = this.getCSVHeaders()

    return (
      <React.Fragment>
        {t('model.export_product_csv_ready', { name: inventoryProductName })}
        <Divider />
        <div className="text-center"><Button onClick={this.handleExportProductCsv} type="primary">{t('model.export_product_csv')}</Button></div>
        <CSVLink
          ref={this.csvLinkRef}
          data={productExportData}
          filename={inventoryProductName + '_export.csv'}
          headers={CSVHeaders}
          style={{ display: 'hide' }}
          separator={';'}
        />
      </React.Fragment>
    )
  }
}
export { ExportProductCsv }
export default compose(
  withTranslation(),
  withRouter
)(ExportProductCsv)
