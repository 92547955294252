import { gql } from '@apollo/client'

export default gql`
  fragment customerFragment on SubscriptionCustomer {
      id
      name
      email
      address {
          city
          country
          line1
          line2
          postalCode
          state
      }
      phone
      currency
      vat
      isComplete
  }
`
