import { connect } from 'react-redux'
import { withRouter } from '../../../utils/with-router'
import { setIsSkipPhasesAction } from '../../../redux/actions/flags.actions'
import { loadInventoryPageSaga, updateInventoryItemsSaga } from '../../../redux/sagas/inventoryTree.saga'
import { getSpaceBySlugWithProductsSaga } from '../../../redux/sagas/space.saga'
import { trackPageSaga } from '../../../redux/sagas/tracking.saga'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import SpaceSelector from '../../../redux/selectors/space.selector'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import { InventoryTreeSelector } from '../../../redux/selectors/inventoryTree.selector'
import ProductMutationSelector from '../../../redux/selectors/productMutation.selector'
import { returnNested } from '../../../utils/tools'
import { InventoryPageLocation } from '../../shared/locations'
import InventoryPage from './inventoryPage'
import GlobalSelector from '../../../redux/selectors/global.selector'
import AccountSelector from '../../../redux/selectors/account.selector'
import { clearInventoryTreeStateAction } from '../../../redux/actions/clear.actions'

const mapStateToProps = (state, ownProps) => {
  const params = InventoryPageLocation.parseLocationParams(ownProps.location)
  return ({
    productId: returnNested(params, 'productId'),
    productScenarioId: returnNested(params, 'productScenarioId'),
    compareEntityId: returnNested(params, 'compareEntityId'),
    compareEntityType: returnNested(params, 'compareEntityType'),
    activeTabKey: returnNested(params, 'activeTabKey'),
    workspaceSlug: returnNested(params, 'workspaceSlug'),
    selectedAccountId: AccountSelector.selectedAccountId(state),
    inventoryRootItemName: GlobalSelector.inventoryRootItemName(state),
    productLabel: GlobalSelector.productLabel(state),
    isCreateLifecycle: FlagsSelector.isCreateLifecycle(state),
    selectedProductId: SelectedProductSelector.productId(state),
    selectedSpaceId: SpaceSelector.spaceId(state),
    isShowProductScenarioInventory: FlagsSelector.isShowProductScenarioInventory(state),
    isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
    inventoryProductName: InventoryTreeSelector.productName(state),
    impactCategoryId: SpaceSelector.impactCategoryId(state),
    inventoryTotalImpactStatus: returnNested(InventoryTreeSelector.inventoryRootProductTotalImpact(state), 'status'),
    productMutator: ProductMutationSelector.productMutator(state),
    excludeLT: SpaceSelector.impactExcludeLT(state),
    useMethodTotal: SpaceSelector.impactUseMethodTotal(state),
    nwSetId: SpaceSelector.impactNwSet(state)
  })
}

const mapDispatchToProps = {
  clearInventoryTreeStateAction,
  loadInventoryPageSaga,
  getSpaceBySlugWithProductsSaga,
  updateInventoryItemsSaga,
  setIsSkipPhasesAction,
  trackPageSaga
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryPage))

