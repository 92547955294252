import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation createProduct($name: String!, $spaceID: ID!, $folderID: ID, $labels: [ID!]!, $unit: String) {
	  createProduct(name: $name, spaceID: $spaceID, labels: $labels, folderID: $folderID, unit: $unit) {
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
