import { returnNested } from '../../utils/tools'

const root = state => returnNested(state, 'loading')
const isLoadingReferenceProducts = state => returnNested(root(state), 'isLoadingReferenceProducts')
const isLoadingSpaceImpactMethods = state => returnNested(root(state), 'isLoadingSpaceImpactMethods')
const isLoadingSpaceImpactSettings = state => returnNested(root(state), 'isLoadingSpaceImpactSettings')
const isLoadingLeftTree = state => returnNested(root(state), 'isLoadingLeftTree')
const isLoadingRightTree = state => returnNested(root(state), 'isLoadingRightTree')

const LoadingSelector = {
  isLoadingReferenceProducts,
  isLoadingSpaceImpactMethods,
  isLoadingSpaceImpactSettings,
  isLoadingLeftTree,
  isLoadingRightTree,
}

export default LoadingSelector
