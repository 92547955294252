import React, { useState, useEffect } from 'react'
import { Modal, Tabs, Tooltip } from 'antd'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCircleNodes, faPenField, IconDefinition } from '@fortawesome/pro-regular-svg-icons'

import SearchReferenceProduct from '../../../search-reference-product/searchReferenceProduct.container'
import { IImpactSelectorDialogProps } from './impactSelectorDialog.interface'
import CustomImpactForm from '../../../model/custom-impact-form/customImpactForm.container'
import { PRODUCT_TYPE, TRACK_EVENT_TYPE } from '../../../../utils/const'
import { useRefOverflow } from '../../../helpers/hooks/refOverflow'
import { IProduct } from '../../../../v1/model'

const getInitActiveTabName = (selectedProduct: IProduct) => {
  const thereIsSomeCustomImpact = selectedProduct?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)

  if (!selectedProduct?.referenceProduct && thereIsSomeCustomImpact) {
    return 'customImpacts'
  }

  if (selectedProduct?.referenceProduct?.type === 'elementary_flow') {
    return 'elementaryFlowsSearch'
  }

  return 'databaseSearch'
}

const ImpactSelectorDialog = ({
  isSearchReferenceModalShowed,
  setIsImpactSelectorDialogShowedAction,
  selectedProduct,
  productMutator,
  updatePageItems,
  trackEventSaga,
  t
}: IImpactSelectorDialogProps) => {
  const [ titleRef, hasTitleOverflow ] = useRefOverflow()
  const [ query, setQuery ] = useState('')
  const [ fuzzy, setFuzzy ] = useState('EXACT')
  const [ pageTitleLabel, setPageTitleLabel ] = useState(getInitActiveTabName(selectedProduct))

  useEffect(() => {
    const modalBodyDOM = document.querySelector('.impactSelectorDialog .ant-modal-body')

    const updateHeaderStyle = () => {
      if ( modalBodyDOM && modalBodyDOM.scrollTop > 0 ) {
        modalBodyDOM?.classList.add('impactSelectorDialog--scrolled')
      } else {
        modalBodyDOM?.classList.remove('impactSelectorDialog--scrolled')
      }
    }

    if (modalBodyDOM) {
      modalBodyDOM.addEventListener('scroll', updateHeaderStyle)
    }

    return () => {
      window.removeEventListener('scroll', updateHeaderStyle)
    }
  }, [])

  useEffect(() => {
    if (isSearchReferenceModalShowed) {
      setPageTitleLabel(getInitActiveTabName(selectedProduct))
    }
  }, [ isSearchReferenceModalShowed ])

  const mapEventsByKey = new Map([
    [ 'databaseSearch', TRACK_EVENT_TYPE.VIEWED_REFERENCE_SEARCH ],
    [ 'elementaryFlowsSearch', TRACK_EVENT_TYPE.VIEWED_ELEMENTARY_FLOWS_SEARCH ],
    [ 'customImpacts', TRACK_EVENT_TYPE.VIEWED_CUSTOM_IMPACT_FORM ]
  ])

  const tabKeys = mapEventsByKey.keys()

  const tabSelectorHandler = (tabKey: string) => {
    setPageTitleLabel(tabKey)
    trackEventSaga(mapEventsByKey.get(tabKey))
  }

  const getTabHeader = (icon: IconDefinition, label: string): React.ReactNode => {
    return (
      <div className="impactSelectorDialog__tab">
        <div className="impactSelectorDialog__icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="impactSelectorDialog__label">{ label }</div>
      </div>
    )
  }

  const onModalClose = () => {
    setQuery('')
    setPageTitleLabel('databaseSearch')
    setIsImpactSelectorDialogShowedAction(false)
  }

  return <Modal
    className="full-sized-modal impactSelectorDialog"
    title={
      <Tooltip placement="top" title={hasTitleOverflow ?
        t('model.impactSelectorTitle', { context: pageTitleLabel, productName: selectedProduct?.name })
        : null}>
        <h1 ref={titleRef} data-cy="search-reference-title" className='impactSelectorDialog__title'>
          { t('model.impactSelectorTitle', { context: pageTitleLabel, productName: selectedProduct?.name })}
        </h1>
      </Tooltip>
    }
    style={ { top: 0 } }
    visible={ isSearchReferenceModalShowed }
    onCancel={ onModalClose }
    footer={ null }>
    <Tabs
      activeKey={pageTitleLabel}
      onChange={tabKey => tabSelectorHandler(tabKey)}
      data-cy="impact-selector-tabs"
      className="impactSelectorDialog__tabs"
      destroyInactiveTabPane={true}
    >
      <Tabs.TabPane
        key={tabKeys.next().value}
        data-cy="database-search-tabpane"
        tab={getTabHeader(faDatabase, t('model.databaseSearch'))}
      >
        <SearchReferenceProduct
          {...{
            productMutator,
            updatePageItems,
            productType: PRODUCT_TYPE.PRODUCT,
            query,
            setQuery,
            fuzzy,
            setFuzzy
          }}/>
      </Tabs.TabPane>
      <Tabs.TabPane
        key={tabKeys.next().value}
        data-cy="elementary-flows-search-tabpane"
        tab={getTabHeader(faCircleNodes, t('model.elementaryFlows'))}
      >
        <SearchReferenceProduct
          {...{
            productMutator,
            updatePageItems,
            productType: PRODUCT_TYPE.ELEMENTARY_FLOW,
            query,
            setQuery
          }}/>
      </Tabs.TabPane>
      <Tabs.TabPane
        key={tabKeys.next().value}
        data-cy="custom-impact-tabpane"
        tab={getTabHeader(faPenField, t('model.customImpact'))}
      >
        <CustomImpactForm {...{
          productMutator,
          updatePageItems,
          closeModal: onModalClose,
          className: 'impactSelectorDialog__customImpacts'
        }}/>
      </Tabs.TabPane>
    </Tabs>
  </Modal>
}

export { ImpactSelectorDialog }
export default compose(
  withTranslation()
)(ImpactSelectorDialog)
