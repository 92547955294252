import { connect } from 'react-redux'

import SearchReferenceProduct from './searchReferenceProduct'

import { searchReferenceProductsSaga, getReferenceDatabasesSaga, storeSearchTermSaga } from '../../redux/sagas/referenceProducts.saga'
import { changeProductReferenceSaga } from '../../redux/sagas/product.saga'
import { trackEventSaga } from '../../redux/sagas/tracking.saga'
import ReferenceProductsSelector from '../../redux/selectors/referenceProducts.selector'
import SpaceSelector from '../../redux/selectors/space.selector'
import { setIsLoadingReferenceProductsAction } from '../../redux/actions/loading.actions'
import { setIsImpactSelectorDialogShowedAction } from '../../redux/actions/flags.actions'
import LoadingSelector from '../../redux/selectors/loading.selector'
import { SelectedProductSelector } from '../../redux/selectors/product.selector'
import { setReferencePageSizeAction } from '../../redux/actions/referenceProducts.actions'

const mapStateToProps = state => ({
  selectedProduct: SelectedProductSelector.product(state),
  selectedDatabases: SpaceSelector.selectedDatabases(state),
  referenceProductsResults: ReferenceProductsSelector.results(state),
  referenceDatabases: ReferenceProductsSelector.databases(state),
  referencePageSize: ReferenceProductsSelector.referencePageSize(state),
  isLoadingReferenceProducts: LoadingSelector.isLoadingReferenceProducts(state),
})

const mapDispatchToProps = {
  searchReferenceProductsSaga,
  getReferenceDatabasesSaga,
  storeSearchTermSaga,
  setIsLoadingReferenceProductsAction,
  setIsImpactSelectorDialogShowedAction,
  setReferencePageSizeAction,
  changeProductReferenceSaga,
  trackEventSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchReferenceProduct)
