import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

type DeleteProductComponentProps = {
  removingConversionItem?: boolean,
  showDeleteTreeItem: boolean,
  closeDeleteTreeItemDialog: () => void,
  handleDeleteTreeItem: () => void
}
export const DeleteTreeItemComponent = ({
  removingConversionItem = false,
  showDeleteTreeItem,
  closeDeleteTreeItemDialog,
  handleDeleteTreeItem
}: DeleteProductComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { selectedNode } = useContext<TProductDetailContext>(ProductDetailContext)

  const { data: { inventoryItem = null } = {} } = selectedNode || {}
  const { product } = inventoryItem || {}

  const onHide = () => {
    closeDeleteTreeItemDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="remove-tree-tem-dialog"
      header={t('labels.headerRemove', { context: 'treeItem', name: product?.name })}
      visible={showDeleteTreeItem}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div data-testid="delete-tree-item-message" className="col-12 p-0">
          { t('messages.warning', { context: 'removeTreeItem', name: product?.name }) }
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product"
            label={t('actions.cancel', { ns: 'common' })}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleDeleteTreeItem}
            data-testid="button-delete-product"
            data-cy="button-delete-product"
            label={t('form.label', { context: 'delete', ns: 'common' })}
            iconPos="left"
            loading={removingConversionItem}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
