import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'

export type DeleteLifecycleComponentProps = {
  showDeleteLifecycleDialog: boolean,
  closeDeleteLifecycleDialog: () => void,
  handleDeleteLifecycle: () => void
}
export const DeleteLifecycleComponent = ({
  showDeleteLifecycleDialog,
  closeDeleteLifecycleDialog,
  handleDeleteLifecycle,
}: DeleteLifecycleComponentProps) => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const { selectedLifecycle } = useContext<TLifecycleContext>(LifecycleContext)

  const onHide = () => {
    closeDeleteLifecycleDialog()
  }

  return (
    <Dialog
      className="w-30rem"
      data-testid="delete-lifecycle-dialog"
      header="Delete lifecycle"
      visible={showDeleteLifecycleDialog}
      draggable={false}
      dismissableMask
      onHide={onHide}
    >
      <div className="w-full pb-6">
        <div className="col-12 p-0">
          { t('messages.removedLifecycle', { context: 'warning', ns: 'lifecycle', name: selectedLifecycle?.name })}
        </div>

        <div className="col-12 pt-6 px-0 flex justify-content-end gap-2">
          <Button
            onClick={onHide}
            data-testid="cancel-delete-product"
            label={t('actions.cancel')}
            iconPos="left"
            loading={false}
            className="p-button-outlined p-button-plain justify-content-end"
          />

          <Button
            onClick={handleDeleteLifecycle}
            data-testid="button-delete-product"
            label={t('form.label', { context: 'delete' })}
            iconPos="left"
            loading={false}
            className="p-button-danger justify-content-end"
          />
        </div>
      </div>
    </Dialog>
  )
}
