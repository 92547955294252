import { isEmpty, returnNested } from './tools'
import { printWarning } from './devTools'

/**
 * @property {string} rootId
 * @property {string} productId
 * @property {string} parentNodeId
 */
export class InventoryTreeKey {
  rootId = undefined
  productId = undefined
  parentNodeId = undefined

  /**
   * @param {{
   *   rootId: String
   *   productId: String,
   *   parentNodeId: String,
   *   targetId: String,
   *   targetAmount: String
   * }} params
   */
  constructor(params) {
    if (!params) {
      printWarning('You are initializing InventoryTreeKey with empty params, args %o', params)
      return
    }
    if (!params.rootId) {
      printWarning('You are initializing InventoryTreeKey without rootId, args %o', params)
    }
    if (!params.productId) {
      printWarning('You are initializing InventoryTreeKey without productId, args %o', params)
    }
    if (params.productId !== params.rootId && !params.parentNodeId) {
      printWarning('You are initializing a child InventoryTreeKey without parentNodeId, args %o', params)
    }

    if (!params.parentNodeId && params.productId !== params.rootId ) {
      params.parentNodeId = 'root'
    }

    this.rootId = params.rootId
    this.productId = params.productId
    this.parentNodeId = params.parentNodeId
    this.currentNodeId = params.currentNodeId
  }

  inventoryTreeKey = () => this._toJson()
  static getProductIdByInventoryKey = key => {
    const params = this._fromJson(key)
    return params && params.productId
  }

  /**
   * @param {string} rootId
   * @returns {InventoryTreeKey}
   */
  static createFromRootId = rootId => new this.prototype.constructor({
    rootId,
    productId: rootId,
  })
  static createFromKey = key => this._fromJson(key)
  static createFromInventoryNode = (inventoryTree, inventoryNode) => {
    const rootId = returnNested(inventoryTree, 'product', 'id'),
      productId = returnNested(inventoryNode, 'inventoryItem', 'product', 'id'),
      parentNode =  returnNested(inventoryNode, 'parentNode'),
      currentNodeId =  returnNested(inventoryNode, 'nodeId')

    let parentNodeId
    if (rootId === productId) {
      parentNodeId = null
    } else if (!parentNode) {
      parentNodeId = 'root'
    } else {
      parentNodeId = parentNode
    }
    return new this.prototype.constructor({
      rootId,
      productId,
      parentNodeId,
      currentNodeId
    })
  }

  isRoot = () => this.rootId === this.productId
  isEqual = obj => {
    return this.rootId === obj.rootId &&
      this.productId === obj.productId &&
      this.parentNodeId === obj.parentNodeId &&
      this.currentNodeId === obj.currentNodeId
  }

  /**
   * @param jsonString
   * @returns {this}|null
   */
  static _fromJson = jsonString => {
    let params
    try {
      params = JSON.parse(jsonString)
    } catch (e) {
      printWarning('Could not parse %s as json object', jsonString)
      return null
    }
    return new this.prototype.constructor(params)
  }

  /**
   * @returns {string}
   */
  _toJson = () => JSON.stringify({ ...this })
}

/**
 * @param inventoryTree
 * @param inventoryItemKey
 * @returns {{}}
 */
export const getInventoryNodeFromInventoryItemKey = (inventoryTree, inventoryItemKey) => {
  if (!inventoryTree || !inventoryItemKey) {
    return null
  }
  const inventoryTreeKey = InventoryTreeKey.createFromKey(inventoryItemKey)
  if (inventoryTreeKey.isRoot()) {
    return null
  }
  if (isEmpty(inventoryTree.inventoryItems)) {
    return null
  }
  const inventoryNode = inventoryTree.inventoryItems.find(el => InventoryTreeKey.createFromInventoryNode(inventoryTree, el).isEqual(inventoryTreeKey))
  if (inventoryNode === undefined) {
    return null
  }

  return inventoryNode
}

/**
 * @param inventoryTree
 * @param inventoryItemKey
 * @returns {{targetId: String|null, targetAmount: String|null}}
 */
export const getTargetIdAndTargetAmountFromInventoryItemKey = (inventoryTree, inventoryItemKey) => {
  const emptyResult = { targetId: null, targetAmount: '0' }
  if (!inventoryTree || !inventoryItemKey || isEmpty(inventoryTree.inventoryItems)) {
    return emptyResult
  }
  const inventoryTreeKey = InventoryTreeKey.createFromKey(inventoryItemKey)
  if (!inventoryTreeKey) {
    return emptyResult
  }
  if (inventoryTreeKey.isRoot()) {
    return emptyResult // We are not supposed to change amount for inventory root
  }
  if (inventoryTreeKey.parentNodeId === 'root') {
    return {
      targetId: inventoryTree.product.id,
      targetAmount: '1',
    }
  }

  const parentNode = inventoryTree.inventoryItems.find(el => el.nodeId === inventoryTreeKey.parentNodeId)
  if (!parentNode) {
    printWarning('Inventory key initialized as not having the parent node as root but could not find the node "%s" in tree %o', inventoryTreeKey.parentNodeId, inventoryTree.inventoryItems)
    return null
  }
  return {
    targetId: parentNode.inventoryItem.product.id,
    targetAmount: String(parentNode.inventoryItem.amount),
  }
}
