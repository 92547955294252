import { gql } from '@apollo/client'

export default gql`
  mutation createTransportActivity(
    $phaseID: ID!,
    $spaceID: ID!,
    $templateID: ID,
    $products: [ProductCtxInput!]!,
    $name: String!,
    $description: String!,
    $start: String!,
    $legs: [TransportActivityLegInput!]!
  ) {
    createTransportActivity(
      phaseID: $phaseID,
      spaceID: $spaceID,
      templateID: $templateID
      products: $products,
      name: $name,
      description: $description,
      start: $start,
      legs: $legs
    ) {
      id
    }
  }
`
