import { connect } from 'react-redux'
import AccountSelector from '../../../redux/selectors/account.selector'

import Usage from './usage'

const mapStateToProps = state => ({
  selectedAccountSubscription: AccountSelector.selectedAccountSubscription(state),
  selectedAccountUsage: AccountSelector.selectedAccountUsage(state),
  isStandardSubscription: AccountSelector.isStandardSubscription(state),
  selectedAccountPaidUsersCount: AccountSelector.selectedAccountPaidUsersCount(state),
  isTrialing: AccountSelector.isTrialing(state),
  isTrialExpired: AccountSelector.isTrialExpired(state),
  hasValidSubscription: AccountSelector.hasValidSubscription(state),
})

export default connect(
  mapStateToProps,
  null
)(Usage)
