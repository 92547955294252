import { gql } from '../../../__generated__'

export const TAGS = gql(`
  query tags($spaceID: ID!, $query: String) {
    tags(spaceID: $spaceID, query: $query){
      id
      name
      color
    }
  }
`)
