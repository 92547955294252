import { returnNested } from '../../utils/tools'

const root = state => returnNested(state, 'global')
const possibleInventoryItems = state => returnNested(root(state), 'possibleInventoryItems')
const sidebarExpandedKeys = state => returnNested(root(state), 'sidebarExpandedKeys')
const listOfProductsForTransportation = state => returnNested(root(state), 'listOfProductsForTransportation')
const vehicleList = state => returnNested(root(state), 'vehicleList')
const activityProductList = state => returnNested(root(state), 'activityProductList')
const activityTemplates = state => returnNested(root(state), 'activityTemplates')
const selectedActivityTemplate = state => returnNested(root(state), 'selectedActivityTemplate')
const selectedActivityTemplateId = state => returnNested(selectedActivityTemplate(state), 'id')
const subscriptionProducts = state => returnNested(root(state), 'subscriptionProducts')
const productLabel = state => returnNested(root(state), 'productLabel')
const productFilter = state => returnNested(root(state), 'productFilter')
const selectedInventoryItem = state => returnNested(root(state), 'selectedInventoryItem')
const productFilterQuery = state => returnNested(productFilter(state), productLabel(state), 'query')
const productFilterSelectedTags = state => returnNested(productFilter(state), productLabel(state), 'productTags')
const productFilterSelectedCategories = state => returnNested(productFilter(state), productLabel(state), 'selectedCategories')
const productImportModalVisibility = state => returnNested(root(state), 'productImportModalVisibility')
const productImportColumns = state => returnNested(root(state), 'productImportColumns')
const impactMethodSetsList = state => returnNested(root(state), 'impactMethodSetsList')
const currentLifecyclePageNumber = state => returnNested(root(state), 'currentLifecyclePageNumber')
const inventoryRootItemId = state => returnNested(root(state), 'inventoryRootItemId')
const productListSortType  = state => returnNested(root(state), 'productListSortType')
const modules  = state => returnNested(root(state), 'modules')
const inventoryRootItemName = state => returnNested(root(state), 'inventoryRootItemName')
const versionNumber = state => returnNested(root(state), 'versionNumber')
const amountModalPreference = state => returnNested(root(state), 'amountModalPreference')
const selectedFolder = state => returnNested(root(state), 'selectedFolder')
const impactMethods = state => returnNested(root(state), 'impactMethods')
const impactCategories = state => returnNested(root(state), 'impactCategories')
const nwSets = state => returnNested(root(state), 'nwSets')

const GlobalSelector = {
  possibleInventoryItems,
  sidebarExpandedKeys,
  listOfProductsForTransportation,
  vehicleList,
  activityProductList,
  activityTemplates,
  selectedActivityTemplate,
  selectedActivityTemplateId,
  subscriptionProducts,
  productFilter,
  selectedInventoryItem,
  productFilterQuery,
  productFilterSelectedTags,
  productFilterSelectedCategories,
  productImportModalVisibility,
  productImportColumns,
  impactMethodSetsList,
  currentLifecyclePageNumber,
  inventoryRootItemId,
  productListSortType,
  productLabel,
  modules,
  inventoryRootItemName,
  versionNumber,
  amountModalPreference,
  selectedFolder,
  impactCategories,
  impactMethods,
  nwSets
}

export default GlobalSelector
