import { connect } from 'react-redux'
import UserSelector from '../../redux/selectors/user.selector'
import FlagsSelector from '../../redux/selectors/flags.selector'

import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { setUserProfileAction } from '../../redux/actions/global.actions'
import { addSuccessNotificationAction } from '../../redux/actions/notification.actions'
import { trackPageSaga } from '../../redux/sagas/tracking.saga'

import UserProfile from './userProfile'

const mapStateToProps = state => ({
  selectedUser: UserSelector.selectedUser(state),
  isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state),
})

const mapDispatchToProps = {
  setIsDetailsPanelOpenAction,
  setUserProfileAction,
  addSuccessNotificationAction,
  trackPageSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile)
