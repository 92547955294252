import { CarOutlined, TagOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Form, Input, InputNumber, Modal, Select, Radio } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import compose from '../../../../utils/compose'
import { PRODUCT_LABEL, PRODUCT_TYPE, TRACK_EVENT_TYPE, VALIDATION } from '../../../../utils/const'
import { getInventoryNodeFromInventoryItemKey } from '../../../../utils/inventoryTreeKey'
import { isEmpty, returnNested, safeArray } from '../../../../utils/tools'
import CropLongText from '../../../helpers/cropLongText.container'
import TransportActivityTemplate from '../../activity-templates/transport/transportActivityTemplate.container'
import { DetailsPanelCard } from '../detailPanelCard'
import ProductDetailContext from '../../../../v1/product/provider/context/product-detail.context'

const FormItem = Form.Item
const templates = [
  { name: 'global.standard', value: 'standard', icon: <TagOutlined/>, modal: false },
  { name: 'model.transport_template', value: 'transport', icon: <CarOutlined/>, modal: true }
]

class AddInventoryItem extends Component {
  static contextType = ProductDetailContext
  state = {
    newItemId: null,
    activityTemplateModalVisibility: false,
    selectedTemplateValue: null,
    // We use this internal state because BE is slow on this query
    filteredProducts: [],
    query: '',
    addAnotherItem: false
  }

  constructor(props) {
    super(props)
    this.handleSearchProductName = this.handleSearchProductName.bind(this)
    this.handlePossibleItemSelected = this.handlePossibleItemSelected.bind(this)
    this.handleCancelTransportTemplate = this.handleCancelTransportTemplate.bind(this)
  }

  componentDidMount() {
    this.props.getPossibleInventoryItemsSaga(this.props.selectedProductId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedProductId !== this.props.selectedProductId) {
      this.props.setPossibleInventoryItemsAction([])
      this.props.getPossibleInventoryItemsSaga(this.props.selectedProductId)
    } else if (
      prevProps.possibleInventoryItems !== this.props.possibleInventoryItems ||
      prevState.query !== this.state.query
    ) {
      this.setState({
        filteredProducts: this.filterProducts(this.props.possibleInventoryItems, this.state.query)
      })
    }

  }

  componentWillUnmount() {
    this.props.setPossibleInventoryItemsAction([])
  }

  handleAddInventoryItem(newItemId, values, productAmount) {
    const { isEnabledForV1, selectedProductId, productMutator, addInventoryItemSaga, trackEventSaga } = this.props
    const { selectedInventoryAmountV09, selectedProduct } = this.context
    const amount = !isEnabledForV1 ? productAmount : selectedInventoryAmountV09?.toString()
    const productId = !isEnabledForV1 ? selectedProductId : selectedProduct?.id
    addInventoryItemSaga(
      productMutator,
      productId,
      amount,
      newItemId,
      String(values.amount),
      this.updatePageItemsHandler,
      !this.state.addAnotherItem
    )
    this.formRef.current.resetFields()
    trackEventSaga(TRACK_EVENT_TYPE.ADDED_SUBITEM)
  }

  handleCreateInventoryItem(values, productAmount) {
    const { isEnabledForV1, selectedProductId, productMutator, createInventoryItemSaga, trackEventSaga } = this.props
    const { selectedInventoryAmountV09, selectedProduct } = this.context
    const amount = !isEnabledForV1 ? productAmount : selectedInventoryAmountV09?.toString()
    const productId = !isEnabledForV1 ? selectedProductId : selectedProduct?.id
    createInventoryItemSaga(
      productMutator,
      productId,
      amount,
      values.name,
      String(values.amount),
      values.unit,
      values.type === PRODUCT_TYPE.WASTE,
      () => {
        trackEventSaga(TRACK_EVENT_TYPE.CREATED_SUBITEM)
        this.updatePageItemsHandler()
      },
      !this.state.addAnotherItem
    )
    this.formRef.current.resetFields()
  }

  updatePageItemsHandler = () => {
    const { addAnotherItem } = this.state
    this.props.updatePageItems(addAnotherItem)
    this.setState({
      newItemId: null
    })
  }

  handleSubmit = (addAnotherItem = false) => {
    const { newItemId } = this.state
    const { inventoryTree, selectedInventoryItem } = this.props
    const selectedNodeFromInventory = getInventoryNodeFromInventoryItemKey(inventoryTree, selectedInventoryItem)
    const productAmount = selectedNodeFromInventory ? String(selectedNodeFromInventory.inventoryItem.amount) : '1'
    this.setState({ ...this.state, addAnotherItem })
    this.formRef.current.validateFields().then(values => {
      newItemId
        ? this.handleAddInventoryItem(newItemId, values, productAmount)
        : this.handleCreateInventoryItem(values, productAmount)
    })
  }

  filterProducts = (products, query) => {
    if (query === '') {
      return safeArray(products)
    }
    return safeArray(products).filter(el => el.name.toLowerCase().includes(String(query).toLowerCase()))
  }

  handleSearchProductName(query) {
    const { filteredProducts } = this.state
    const existingProduct = safeArray(filteredProducts).find(el => el.name.toLowerCase() === query.toLowerCase())

    if (existingProduct) return this.fillProductProperties(existingProduct)

    this.setState({
      newItemId: null,
      query: query,
    })
  }

  fillProductProperties = selectedItem => {
    this.formRef.current.setFieldsValue({
      unit: returnNested(selectedItem, 'unit'),
      type: returnNested(selectedItem, 'type') || PRODUCT_TYPE.NORMAL
    })

    this.setState({
      newItemId: returnNested(selectedItem, 'id'),
      newItemName: returnNested(selectedItem, 'name'),
    }, this.formRef.current.setFieldsValue({ name: returnNested(selectedItem, 'name') })
    )
  }

  handlePossibleItemSelected(value) {
    const { possibleInventoryItems } = this.props

    const selectedItem = safeArray(possibleInventoryItems).find(el => el.id === value)
    if (isEmpty(selectedItem)) return
    this.fillProductProperties(selectedItem)
  }

  handleCancelTransportTemplate() {
    this.props.clearListOfProductsForTransportation()
    this.setState({ selectedTemplateValue: null, activityTemplateModalVisibility: false })
  }

  handleTemplateSelection = value => {
    const selectedTemplate = templates.find(el => el.value === value)

    this.setState({
      selectedTemplateValue: returnNested(selectedTemplate, 'value'),
      activityTemplateModalVisibility: selectedTemplate
    })
  }

  possibleInventoryItemsOptions = () => {
    const { filteredProducts = [] } = this.state
    const { t } = this.props
    const options = []
    const getOptionsByLabel = label => {
      const hasEntryLabel = entry => {
        const { labels = [] } = entry
        return labels.some(item => item.id === label)
      }
      return filteredProducts
        .filter(entry => hasEntryLabel(entry))
        .map(entry => ({
          value: entry.id,
          label: <CropLongText tooltipText={ entry.name }>{ entry.name }</CropLongText>
        }))
    }
    const productOptions = getOptionsByLabel(PRODUCT_LABEL.PRODUCT)
    const objectOptions = getOptionsByLabel(PRODUCT_LABEL.OBJECT)

    if (!isEmpty(productOptions)) options.push({
      label: t('model.label', { context: PRODUCT_LABEL.PRODUCT }),
      options: productOptions
    })

    if (!isEmpty(objectOptions)) options.push({
      label: t('model.label', { context: PRODUCT_LABEL.OBJECT }),
      options: objectOptions
    })

    return options
  }

  formRef = React.createRef()

  render() {
    const { t } = this.props
    const {
      newItemId,
      activityTemplateModalVisibility,
      selectedTemplateValue,
    } = this.state
    const itemSelected = !!newItemId

    // TODO: Remove this to show transport template section
    const disablePickTemplateSection = true
    return (
      <>
        {
          this.props.selectedLifecycle ?
            <DetailsPanelCard>
              <p>{ t('model.pick_template') }</p>
              <Select
                data-cy="select-template"
                dropdownClassName="cy-select-template-dropdown"
                className="select-template"
                placeholder="Select template"
                value={ selectedTemplateValue || returnNested(templates, 0, 'value') }
                onChange={ this.handleTemplateSelection }
                disabled={disablePickTemplateSection}
              >
                { templates.map(template =>
                  <Select.Option key={ template.value } data-cy={ `template-type-${ template.value }` }>
                    { template.icon } { t(template.name) }
                  </Select.Option>
                ) }
              </Select>
            </DetailsPanelCard>
            : null
        }
        { !activityTemplateModalVisibility ?
          <div>
            <Form
              ref={ this.formRef }
              onFinish={ () => this.handleSubmit() }
              className="field-container"
              layout="vertical"
              hideRequiredMark
              initialValues={ { type: PRODUCT_TYPE.NORMAL } }
            >
              <DetailsPanelCard
                title={t('model.open_details_panel')}>
                <FormItem
                  name="name"
                  label={ t('global.name') }
                  rules={ [
                    { min: VALIDATION.PRODUCT_NAME_LENGTH, message: t('validation.product_name', { name: 'Product name' }) },
                    { required: true, message: t('validation.is_required', { name: 'Product name' }) }
                  ] }
                >
                  <AutoComplete
                    showSearch
                    autoFocus
                    allowClear
                    placeholder={t('global.addNewOrSearch')}
                    data-cy='new-product-name-autocomplete'
                    onSearch={ this.handleSearchProductName }
                    onSelect={ this.handlePossibleItemSelected }
                    options={this.possibleInventoryItemsOptions()}
                  />
                </FormItem>
                <FormItem
                  name="unit"
                  label={ t('global.unit') }
                  rules={ [
                    {
                      validator: async (rule, value) => {
                        if (value && !VALIDATION.ALPHANUMERIC.test(value)) {
                          throw new Error(t('err.VALUE_MUST_CONTAINS_ALPHANUMERIC', { name: 'Unit' }))
                        }
                      }
                    }
                  ] }
                >
                  <Input
                    placeholder={ t('global.unit') }
                    data-cy='new-product-unit-input'
                    disabled={ itemSelected }
                  />
                </FormItem>

                <p className='form-comp-desc'>{t('global.chooseUnitDescription')}</p>

                <Form.Item
                  name="type"
                  label={ t('global.type') }
                  rules={ [ { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Type' }) } ] }
                >
                  <Radio.Group
                    disabled={ itemSelected }
                  >
                    <Radio className="form-product-type" value={ PRODUCT_TYPE.NORMAL } data-cy='new-product-type-normal'>{ t('model.product_type_normal') }</Radio>
                    <Radio className="form-product-type" value={ PRODUCT_TYPE.WASTE }  data-cy='new-product-type-waste'>{ t('model.product_type_waste') }</Radio>
                  </Radio.Group>
                </Form.Item>
              </DetailsPanelCard>
              <DetailsPanelCard
                title={t('model.inventory')}>
                <FormItem
                  name="amount"
                  label={ t('global.amount') }
                  rules={ [
                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: 'Amount' }) },
                  ] }
                >
                  <InputNumber
                    className="full-width"
                    min={ 0 }
                    placeholder={ t('global.amount') }
                    data-cy='new-product-amount-input'
                  />
                </FormItem>

                <p className='form-comp-desc'>{t('global.amountDescription')}</p>

                <FormItem className='form-action'>
                  <Button
                    type="primary"
                    data-cy="create-new-tree-item"
                    htmlType="submit"
                    className="button gutter"
                  >
                    {t('model.add_item')}
                  </Button>
                  <Button data-cy='add-new-tree-item-add-another'
                    className="button"
                    onClick={ () => this.handleSubmit(true) }>
                    { t('global.addAnother') }
                  </Button>
                </FormItem>
              </DetailsPanelCard>
            </Form>

          </div>
          :
          <Modal
            key={ returnNested(this.props.selectedInventoryItem, 'id') }
            className="full-sized-modal grey-modal"
            title={ <h1 className='title'>{ t('model.transport') }</h1> }
            style={ { top: 0 } }
            visible={ true }
            onCancel={ this.handleCancelTransportTemplate }
            footer={ null }>
            <TransportActivityTemplate handleCancelModal={ this.handleCancelTransportTemplate } handleCancelOfCreationOfNewInventoryItem={ this.props.handleCancel }/>
          </Modal> }
      </>
    )
  }
}

AddInventoryItem.propTypes = {
  t: PropTypes.func.isRequired,

  inventoryTree: PropTypes.object,
  selectedInventoryItem: PropTypes.string,
  selectedProductId: PropTypes.string.isRequired,
  updatePageItems: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  productMutator: PropTypes.object.isRequired,
  addInventoryItemSaga: PropTypes.func.isRequired,
  createInventoryItemSaga: PropTypes.func.isRequired,
}

export default compose(
  withTranslation()
)(AddInventoryItem)
