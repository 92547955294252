import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { TabMenu } from 'primereact/tabmenu'

import { useTranslation } from 'react-i18next'
import { useUrlQuery } from '../../../shared/hook/use-url-query'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'

import { TrackEventType } from '../../../shared/enum/track-events'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import AnalysisContext from '../../provider/context/analysis.context'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { ChangeImpactCategoryContainer } from '../../../product/container/change-impact-category.container'
import { TAnalysisContext } from '../../interface/analysis.context'
import { getSegmentTrack } from '../../../shared/util/segment'
import { AnalysisMode } from '../../enum/analysis'
import { store } from '../../../../configureStore'
import { setIsDetailPanelReadonlyV1Action } from '../../../../redux/actions/flags.actions'

export const TaskbarComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const urlQuery = useUrlQuery()
  const navigate = useNavigate()
  const { productId } = useParams()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { secondaryEntityId, secondaryEntityType, analysisMode } = useContext<TAnalysisContext>(AnalysisContext)
  const { selectedInventoryViewType, updateProductDetail } = useContext<TProductDetailContext>(ProductDetailContext)
  const activeTabKey: InventoryViewType = urlQuery.get('activeTabKey') as InventoryViewType
  const [ trackEventInSegment ] = getSegmentTrack()
  const comparisonMode = analysisMode === AnalysisMode.Comparison

  useEffect(() => {
    if (activeTabKey) {
      const TabEventTypes: TrackEventType[] = [
        TrackEventType.VIEWED_MODEL,
        TrackEventType.VIEWED_PRODUCT_TABLE,
        TrackEventType.VIEWED_PRODUCT_STACKED_CHART
      ]
      trackEventInSegment(TabEventTypes[getActiveIndex(activeTabKey)])
      updateProductDetail({ selectedInventoryViewType: activeTabKey })
    } else {
      updateProductDetail({ selectedInventoryViewType: InventoryViewType.Model })
    }
    if (!comparisonMode && activeTabKey && activeTabKey !== InventoryViewType.Model) {
      store.dispatch(setIsDetailPanelReadonlyV1Action(true))
    }
  }, [ activeTabKey ])

  const items = [
    { label: t('labels.tab', { context: 'model' }), className: 'cy-tab-model', activeTab: InventoryViewType.Model },
    { label: t('labels.tab', { context: 'table' }), className: 'cy-tab-table', activeTab: InventoryViewType.FlatView },
    { label: t('labels.tab', { context: 'stackedChart' }), className: 'cy-tab-stacked', activeTab: InventoryViewType.StackedChart }
  ]

  const getActiveIndex = (selectedViewType?: InventoryViewType) => items.findIndex(viewType => viewType.activeTab === selectedViewType) || 0

  const handleTabChange = (event: any) => {
    const { value: { activeTab } } = event || {}
    if (selectedInventoryViewType !== activeTab && account?.id && space?.slug && productId) {
      const commonPath = `/account/${account.id}/workspace/${space.slug}/product/${productId}`
      const path = comparisonMode
        ? `${commonPath}/compare/?activeTabKey=${activeTab}&type=${secondaryEntityType}&id=${secondaryEntityId}`
        : `${commonPath}/scenario/?activeTabKey=${activeTab}&id=${secondaryEntityId}`

      const activeIndex = getActiveIndex(activeTab)
      addToBackToList(items[activeIndex].label)
      navigate(path)
    }
  }

  return (
    <div className="flex align-items-center justify-content-start w-full">
      <div className="flex flex-grow-1">
        <TabMenu model={items} activeIndex={getActiveIndex(selectedInventoryViewType)} onTabChange={(event: any) => handleTabChange(event)} />
      </div>
      <div className="flex flex-none h-full mb-1">
        <ChangeImpactCategoryContainer />
      </div>
    </div>
  )
}
