import { connect } from 'react-redux'
import DetailsPanel from './detailsPanel'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { clearUiWhenDetailPanelCloseAction } from '../../../redux/actions/clear.actions'

const mapStateToProps = state => {
  return ({
    isForcingWorkspacePanel: FlagsSelector.isForcingWorkspacePanel(state),
    isDetailsPanelOpen: FlagsSelector.isDetailsPanelOpen(state)
  })
}

const mapDispatchToProps = {
  clearUiWhenDetailPanelCloseAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsPanel)
