import React from 'react'
import styled from 'styled-components'

const TableWrapperInventory = styled.div`
  .p-datatable {
    width: 100%;

    .p-column-header-content{
      
    }
    .p-datatable-header {
      background: var(--primarydark-50);
      border-bottom: 1px solid var(--primarydark-100);
      padding: 0.5rem 1.25rem;
    }

    table {
      border-spacing: 0 !important;
      border-collapse: separate !important;

      .p-datatable-thead {
        th {
          height: 2.5rem;
          background: var(--primarydark-50);
          border-bottom: 1px solid var(--primarydark-100);
          padding: 0.25rem 1.25rem;
        }

        .p-column-title {
          width: 100%;
        }
      }

      .p-datatable-tbody > tr > td {
        border-top: 1px solid var(--primarydark-100);
        padding: 0;
      }

      tr.p-selectable-row {
        outline: none !important;
      }

      tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
        background: transparent !important;
        color: var(--primary-500) !important;
        box-shadow: none !important;
      }

      tr.p-highlight {
        background: none;
        color: var(--gray-700);

        td {
          border: 1px solid var(--green-500);
          border-right: none;
          border-left: none;
        }

        td:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          border-left: 1px solid var(--green-500);
        }

        td:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-right: 1px solid var(--green-500);
        }
      }

      tr.p-highlight + tr {
        td {
          border: none;
        }
      }

    }

    .p-datatable-tfoot,
    .p-datatable-footer {
      background: var(--surface-a);
      padding: 0.75rem 1.25rem;
      border-top: 1px solid var(--primarydark-100);

      .tag-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      .product-name {
        padding: 0.1rem 1rem 0;

        :hover {
          color: var(--primary-500);
        }
      }
    }
  }
`

type TTableWrapperInventoryProps = {
  children?: React.ReactNode
}
export const TableWrapperInventoryComponent = ({
  children
}: TTableWrapperInventoryProps) => (
  <TableWrapperInventory data-testid="table-wrapper" className="flex w-full">
    { children }
  </TableWrapperInventory>
)
