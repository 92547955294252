import { connect } from 'react-redux'
import { cancelSubscriptionSaga } from '../../../../redux/sagas/stripe.saga'
import AccountSelector from '../../../../redux/selectors/account.selector'
import PoliciesSelector from '../../../../redux/selectors/policies.selector'
import { trackEventSaga } from '../../../../redux/sagas/tracking.saga'
import CancelSubscription from './cancelSubscription'
import { setAccountUsersAndRolesSaga } from '../../../../redux/sagas/account.saga'

const mapStateToProps = state => ({
  selectedAccountID: AccountSelector.selectedAccountId(state),
  canManageAccount: PoliciesSelector.canManageAccount(state),
  canManageBilling: PoliciesSelector.canManageBilling(state)
})

const mapDispatchToProps = {
  cancelSubscriptionSaga,
  setAccountUsersAndRolesSaga,
  trackEventSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelSubscription)

