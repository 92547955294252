import React, { useContext, useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { isValid } from '../../shared/util/tools'
import { LIFECYCLE_DETAIL } from '../../graphql/query'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'

import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { setSelectedLifecycleAction } from '../../../redux/actions/lifecycle.actions'
import { store } from '../../../configureStore'

type TLifecycleDetailProps = {
  children?: React.ReactNode
  lifecycleId: string
}
export const LifecycleDetailContainer = ({
  children,
  lifecycleId = ''
}: TLifecycleDetailProps) => {
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const {
    loading: loadingLifecycle,
    data: lifecycleData,
    refetch: refetchLifecycle
  } = useQuery(LIFECYCLE_DETAIL, {
    skip: !isValid(space),
    variables: { id: lifecycleId },
    fetchPolicy: 'no-cache'
  })

  const { lifecycle: lifecycleDetail = null } = lifecycleData || {}

  const getLifecycleTotalImpact = () => {
    const { phases = [] } = lifecycleDetail || {}
    if (phases.length === 0) return

    const unit = phases[0].inventory?.totalImpact.unit
    const status = phases[0].inventory?.totalImpact.status

    const totalAmount = phases.reduce((acc, phase) => {
      const amount = phase.inventory?.totalImpact?.amount
      return acc + (amount ? parseFloat(amount) : 0)
    }, 0)

    return { amount: totalAmount, unit, status }
  }

  useEffect(() => {
    if (!loadingLifecycle && lifecycleDetail) {
      const lifecycleTotalImpact = getLifecycleTotalImpact()
      updateLifecycleDetail({
        lifecycleDetail, loadingLifecycle, lifecycleTotalImpact, refetchLifecycle
      })
      // TODO : From v0.9 : To be refactored
      store.dispatch(setSelectedLifecycleAction(lifecycleDetail))
    }

    return () => {
      store.dispatch(setSelectedLifecycleAction(null))
    }
  }, [ loadingLifecycle, lifecycleDetail, refetchLifecycle ])

  return (
    <>
      { loadingLifecycle && <ProgressSpinnerComponent dataTestId="loading-lifecycle-detail" size={2} /> }

      { !loadingLifecycle && children }

    </>
  )
}
