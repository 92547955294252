import { gql } from '@apollo/client'

export default gql`
  query accountUsers($id: ID!){
    accountUsers(id: $id){
      id
      email
      name
      picture
      roles(accountID: $id){
        id
      }
    }
  }
`
