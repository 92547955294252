import { gql } from '@apollo/client'

export default gql`
  mutation changeLifecycleDescription($id: ID!, $description: String!) {
    changeLifecycleDescription(id: $id, description: $description) {
      id
      name
    }
  }
`
