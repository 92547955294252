import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next'
import { IControllerRender } from '../../../shared/interface/react-form-hook'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'

const AddInputWrapper = styled.div`
  .p-inputtext:enabled:focus {
    box-shadow: none !important;
  }

  .p-inputtext::placeholder {
    color: var(--gray-200);
    opacity: 1;
  }
`

type TPhaseAddComponent = {
  handleAddPhase: (param: any) => void
}
export const PhaseAddComponent = ({
  handleAddPhase
}: TPhaseAddComponent) => {
  const defaultValues = { name: '' }
  const { t } = useTranslation([ 'lifecycle' ])
  const {
    control, formState: { errors }, handleSubmit, reset
  } = useForm({ defaultValues })
  const {
    enableAddingPhase,
    updateLifecycleDetail
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const onKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      closeForm()
    }
  }

  const onBlur = (event: any) => {
    const { value } = event.target
    if (!value) {
      closeForm()
    }
  }

  const closeForm = () => {
    reset()
    updateLifecycleDetail({ enableAddingPhase: false })
  }

  return (
    <>
      { enableAddingPhase
      && (
        <div className="flex gap-1 px-2">
          <div className="flex align-items-center justify-content-center w-2rem h-2rem">
            <FontAwesomeIcon className="text-gray-100" icon={faPlay} />
          </div>
          <AddInputWrapper className="flex flex-grow-1 w-full h-2rem">
            <form onSubmit={handleSubmit(handleAddPhase)} className="w-full">
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }: IControllerRender) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    data-cy="new-phase-input"
                    data-testid="new-phase-workspace"
                    placeholder={t('form.newPhase', { context: 'placeholder' })}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    className={classNames('w-full h-full border-0', { 'p-invalid': fieldState.error })}
                  />
                )}
              />
              {
                errors?.name && <small className="p-error">{ errors?.name.message }</small>
              }
            </form>
          </AddInputWrapper>
        </div>
      )}
    </>
  )
}
