import React, { useEffect } from 'react'
import styled from 'styled-components'

import { WorkspaceHeaderComponent } from '../component/workspace-header.component'
import { RecentProductsContainer } from '../container/recent-products.container'
import { RecentLifeCyclesContainer } from '../container/recent-life-cycles.container'
import { ProductTemplatesContainer } from '../container/product-templates.container'
import { VideoTutorialsComponent } from '../component/video-tutorials.component'

import { WorkspaceHelpStartComponent } from '../component/workspace-help-start.component'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const WorkspaceHomeWrapper = styled.div`
  background: var(--primarydark-50);
  overflow: overlay;
`

const RecentContentWrapper = styled.div`
  width: calc(100% - 23rem);
`

export const WorkspaceHomeLayout = () => {
  const [ , trackPageInSegment ] = getSegmentTrack()

  useEffect(() => {
    trackPageInSegment(`Page loaded: ${TrackEventType.VIEWED_WORKSPACE_WELCOME}`, TrackEventType.VIEWED_WORKSPACE_WELCOME)
  }, [])
  return (
    <WorkspaceHomeWrapper className="flex w-full h-screen flex-column">

      <div className="flex flex-none w-full h-6rem" data-cy="page-header-container">
        <WorkspaceHeaderComponent />
      </div>

      <div className="flex w-full h-full px-3">
        <RecentContentWrapper className="flex flex-none py-2 flex-column">
          <div className="flex-none w-full mb-5">
            <ProductTemplatesContainer />
          </div>
          <div className="flex-none h-14rem mb-1 recent-product-container">
            <RecentProductsContainer />
          </div>
          <div className="flex-none h-14rem mb-1">
            <RecentLifeCyclesContainer />
          </div>
        </RecentContentWrapper>

        <div className="flex flex-none flex-column flex pl-3 pr-4 py-2 w-23rem with-banner-gap">
          <div className="flex-none w-full mb-2">
            <WorkspaceHelpStartComponent />
          </div>
          <div className="flex-none w-full mt-2">
            <VideoTutorialsComponent />
          </div>
        </div>

      </div>

    </WorkspaceHomeWrapper>
  )
}
