import { Col, Row } from 'antd'
import React from 'react'
import classnames from 'classnames'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { returnNested } from '../../../../utils/tools'
import LoadingWrapper from '../../../shared/loadingWrapper'
import InventoryTotalImpact from '../inventory-total-impact/inventoryTotalImpact.container'
import InventoryTree from './inventoryTree.container'
import { getInventoryKeys } from '../../../../utils/treeUtils'
import InventoryNameLabel from '../inventory-name-label/inventoryNameLabel'

const InventoryTreeWrapper = props => {
  const {
    inventoryTree,
    productScenarioInventoryTree,
    isShowProductScenarioInventory,
    isInventoryCompare,
    entityToCompare,
    isLoadingLeftTree,
    isLoadingRightTree,
    selectedInventoryItemSaga,
    selectedKeys,
    t
  } = props

  const isInventoryTreeExists = inventoryTree && inventoryTree.product
  const isProductScenarioInventoryTreeExists = productScenarioInventoryTree && productScenarioInventoryTree.product
  const shouldShowProductScenario = isShowProductScenarioInventory && isProductScenarioInventoryTreeExists
  const shouldShowProductComparison = !shouldShowProductScenario && isInventoryCompare
  const isLeftTreeShown = shouldShowProductScenario || isInventoryCompare
  const rightTreeImpact= shouldShowProductScenario ? productScenarioInventoryTree : isInventoryCompare && entityToCompare
  const flexGridClass = isLeftTreeShown ? 'double-tree' : 'single-tree'

  const onProductNameClick = inventory => {
    const inventoryKeys = getInventoryKeys(inventory)
    selectedInventoryItemSaga(inventoryKeys[0])
  }

  const getIsRootSelected = inventory => {
    const inventoryKeys = getInventoryKeys(inventory)
    return selectedKeys && selectedKeys.includes(inventoryKeys[0])
  }

  const SingleTree = () => <Col
    data-cy="inventory-tree-container"
    className={`inventory-tree-container ${shouldShowProductScenario && 'inventory-padding-left'} ${shouldShowProductComparison && 'inventory-padding-right'} ${flexGridClass}`}
  >
    { isInventoryTreeExists &&
    <LoadingWrapper loading={ isLoadingLeftTree }>
      {isLeftTreeShown && <InventoryNameLabel
        onClick={ () => onProductNameClick(inventoryTree) }
        tag={shouldShowProductScenario && t('model.labelOriginal')}
        text={returnNested(inventoryTree, 'product', 'name')}
        className={ classnames({ active: getIsRootSelected(inventoryTree) }) }
      />}
      <InventoryTree
        inventoryTree={ inventoryTree }
        isReadOnly={isLeftTreeShown}
        areImpactsBarsShown={!isLeftTreeShown}
      />
    </LoadingWrapper>
    }
  </Col>

  const ScenarioTree = () => <Col
    data-cy="comparison-inventory-tree-container"
    className={`comparison-inventory-tree-container inventory-padding-right ${flexGridClass}`}
  >
    <LoadingWrapper loading={ isLoadingRightTree }>
      <InventoryNameLabel
        onClick={ () => onProductNameClick(entityToCompare) }
        tag={shouldShowProductScenario && t('model.labelScenario')}
        isPrimaryTag={true}
        text={returnNested(entityToCompare, 'product', 'name')}
        className={ classnames({ active: getIsRootSelected(entityToCompare) }) }
      />
      <InventoryTree
        inventoryTree={ productScenarioInventoryTree }
        isLeftTree={true}
        isReadOnly={false}
        areImpactsBarsShown={false}
      />
    </LoadingWrapper>
  </Col>

  const ComparisonTree = () => <Col
    data-cy="comparison-inventory-tree-container"
    className={`comparison-inventory-tree-container inventory-padding-left ${flexGridClass}`}
  >
    <LoadingWrapper loading={ isLoadingRightTree }>
      <InventoryNameLabel
        onClick={ () => onProductNameClick(entityToCompare) }
        tag={shouldShowProductScenario && t('model.labelScenario')}
        isPrimaryTag={true}
        text={returnNested(entityToCompare, 'product', 'name')}
        className={ classnames({ active: getIsRootSelected(entityToCompare) }) }
      />
      <InventoryTree
        inventoryTree={ entityToCompare }
        key={ returnNested(entityToCompare, 'product', 'id') }
        isReadOnly={true}
        areImpactsBarsShown={false}
      />
    </LoadingWrapper>
  </Col>

  const SingleTreeTotal = () => <Col data-cy="total-impact-container" className={flexGridClass}>
    { isInventoryTreeExists ?
      <InventoryTotalImpact
        impact={ returnNested(inventoryTree, 'totalImpact') }
        impactToCompare={ returnNested(rightTreeImpact, 'totalImpact') }
        isCarbonTranslatorShowed={!shouldShowProductScenario && !isInventoryCompare}
      />
      :
      null
    }
  </Col>

  const ScenarioTreeTotal = () => <Col data-cy="scenario-total-impact-container" className={flexGridClass}>
    <InventoryTotalImpact
      impact={ returnNested(productScenarioInventoryTree, 'totalImpact') }
      impactToCompare={ returnNested(inventoryTree, 'totalImpact') }
      isCarbonTranslatorShowed={false}
    />
  </Col>

  const ComparisonTreeTotal = () => <Col data-cy="comparison-total-impact-container" className={flexGridClass}>
    <InventoryTotalImpact
      impact={ returnNested(entityToCompare, 'totalImpact') }
      impactToCompare={ returnNested(inventoryTree, 'totalImpact') }
      isCarbonTranslatorShowed={false}
    />
  </Col>

  return <>
    <Row type="flex" onClick={evt => evt.stopPropagation()}>
      { shouldShowProductScenario && <ScenarioTree /> }
      <SingleTree />
      { shouldShowProductComparison && <ComparisonTree /> }
    </Row>
    <Row gutter={ 16 } type="flex">
      { shouldShowProductScenario && <ScenarioTreeTotal /> }
      <SingleTreeTotal />
      { shouldShowProductComparison && <ComparisonTreeTotal /> }
    </Row>
  </>
}

export { InventoryTreeWrapper }
export default compose(
  withTranslation(),
)(InventoryTreeWrapper)
