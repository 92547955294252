import React from 'react'
import { Row, Col } from 'antd'
import CropLongText from '../../helpers/cropLongText.container'
import { IDetailsPanelTitleProps } from './detailsPanelTitle.interface'

const DetailsPanelTitle: React.FC<IDetailsPanelTitleProps> = props => {
  const {
    title,
    className,
    'data-cy': dataCy,
    children
  } = props
  return (
    <Row className="details-panel-title">
      <Col span={24}>
        {title &&
          <h2 className={`detail-panel-name ${className}`}  data-cy={dataCy}>
            <CropLongText {...{
              fontSize: '21px',
              tooltipText: title
            }}>
              {title}
            </CropLongText>
          </h2>
        }
        {children ?
          <div className="action-toolbar">
            {children}
          </div>
          : null }
      </Col>
    </Row>
  )
}

export default DetailsPanelTitle
