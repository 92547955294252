import React, { useRef, useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Form, Button, Input } from 'antd'
import { returnNested, nwSetToString } from '../../../utils/tools'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import CustomImpactFormInfo from './customImpactFormInfo.container'
import { impactCategoriesVar } from '../../../graphql/cache'

const FormItem = Form.Item

const CustomImpactForm = props => {
  const {
    t,
    selectedImpactMethodId,
    selectedSpace,
    changeProductCustomImpactsSaga,
    selectedProductCustomImpacts,
    selectedProductId,
    closeModal,
    updatePageItems,
    selectedExcludeLT,
    setSpaceImpactSettingsSaga,
    selectedImpactCategoryId,
    selectedProduct,
    productMutator,
  } = props

  useEffect(() => {
    prepareFormData()
  }, [ selectedProduct ])

  const [ impactCategoriesList, setImpactCategoriesList ] = useState([])
  const [ workspaceSettings, setWorkspaceSettings ] = useState([])
  const customImpactFormRef = useRef()
  const impactCategories = useReactiveVar(impactCategoriesVar)

  const getImpacts = values => {
    let impactList = []
    for (let key in impactCategoriesList) {
      let impactCategoryId = impactCategoriesList[key].id
      impactList.push({
        amount: String(values[impactCategoryId]),
        categoryID: impactCategoryId
      })
    }
    return impactList
  }

  const handleSubmitCustomImpacts = () => {
    customImpactFormRef.current.validateFields().then(values => {
      changeProductCustomImpactsSaga({
        productMutator,
        productId: selectedProductId,
        impactMethodId: workspaceSettings.impactMethod,
        impacts: getImpacts(values),
        excludeLT: workspaceSettings.excludeLT
      }, () => {
        closeModal()
        //TODO ideally is not to call this saga here, but without this call impacts are not updated if account is new
        setSpaceImpactSettingsSaga({
          spaceId: selectedSpace.id,
          impactMethodId: selectedImpactMethodId,
          impactCategoryId: selectedImpactCategoryId,
          nwSetId: returnNested(selectedSpace, 'nwSet', 'id'),
          nwSetType: returnNested(selectedSpace, 'nwSet', 'type'),
          excludeLT: selectedSpace.excludeLT,
          useMethodTotal: selectedSpace.useMethodTotal,
          selectedProduct,
          cb: () => updatePageItems()
        })
      })
    })
  }

  const prepareFormData = () => {
    const selectedImpactMethodId = returnNested(selectedSpace, 'impactMethod', 'id')
    const selectedImpactCategoryId = returnNested(selectedSpace, 'impactCategory', 'id')

    setWorkspaceSettings({
      impactMethodSets: returnNested(selectedSpace, 'impactMethod', 'impactMethodSetID'),
      impactMethod: selectedImpactMethodId,
      impactNwSet: nwSetToString(returnNested(selectedSpace, 'nwSet')),
      impactCategory: selectedImpactCategoryId,
      useMethodTotal: returnNested(selectedSpace, 'useMethodTotal'),
      excludeLT: selectedExcludeLT
    })

    setImpactCategoriesList(impactCategories)
    customImpactFormRef.current.setFieldsValue(getInitialValues(impactCategories))
  }

  const getCustomImpactInitValue = (selectedProductCustomImpacts, impactData) => {
    if (!selectedProductCustomImpacts || selectedProductCustomImpacts?.length === 0) return 0
    let initAmount
    selectedProductCustomImpacts.forEach(productImpact => {
      if (productImpact.impactCategoryID === impactData.id &&
        productImpact.impactMethodID === impactData.impactMethodID &&
        productImpact.excludeLT === selectedExcludeLT) {
        initAmount = productImpact.amount
      }
    })
    return initAmount
  }

  const getInitialValues = impactCategoriesData => {
    if (!impactCategoriesData) return
    let initValues = {}
    impactCategoriesData.forEach(el => {
      initValues[el.id] = getCustomImpactInitValue(selectedProductCustomImpacts, el)
    })

    return initValues
  }

  return (
    <div className="custom-impacts">
      <div className="custom-impacts__header">
        <CustomImpactFormInfo/>
      </div>
      <Form
        ref={ customImpactFormRef }
        layout="vertical"
        className="field-container"
        onFinish={ handleSubmitCustomImpacts }>
        <div className="custom-impacts__form">
          {
            impactCategoriesList && impactCategoriesList.map((el, index) => {
              return <FormItem
                data-cy={`custom_impact_input_${index}`}
                key={el.id}
                name={el.id}
                initialValue="0"
                rules={[
                  { required: true, message: t('validation.require_field', { name: 'Impact category value' }) }
                ]}>
                <Input
                  name={el.id}
                  addonBefore={el.name}
                  addonAfter={el.unit}
                  type="number"/>
              </FormItem>
            })
          }
        </div>
        <div className="custom-impacts__save">
          <Button className='button' htmlType="reset" data-cy="reset_manual_impacts_form">{t('global.reset')}</Button>
          <Button className='button primary' htmlType="submit" data-cy="save_manual_impacts_form">{t('global.save')}</Button>
        </div>
      </Form>
    </div>
  )
}

export default compose(
  withTranslation()
)(CustomImpactForm)
