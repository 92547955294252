import { useState, useEffect } from 'react'

import { getHoursDifference } from '../../shared/util/date'

export const useTrialCountdown = (trialEnd: string): [ number, string ] => {
  const [ hoursLeft, setHoursLeft ] = useState(0)

  useEffect(() => {
    const initHoursLeft = getHoursDifference(new Date(trialEnd), new Date())
    setHoursLeft(initHoursLeft)

    const interval = setInterval(() => {
      const trialExpirationHoursLeft = getHoursDifference(
        new Date(trialEnd),
        new Date()
      )
      setHoursLeft(trialExpirationHoursLeft)
    }, 1000 * 60 * 5) // once in 5 minutes

    return () => clearInterval(interval)
  }, [ trialEnd ])

  const showHours = hoursLeft <= 24
  const context = showHours ? 'hours' : 'days'
  const timeLeft = showHours ? hoursLeft : Math.ceil(hoursLeft / 24)

  return [ timeLeft, context ]
}
