/* eslint-disable no-cond-assign */
const findUrlIndices = (text: string): { url: string, start: number, end: number }[] => {
  let match
  const urlPattern = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  const urlIndices = []
  while ((match = urlPattern.exec(text)) !== null) {
    const start = match.index
    const end = start + match[0].length
    urlIndices.push({ url: match[0], start, end })
  }

  return urlIndices
}
/* eslint-disable no-cond-assign */

const getTextNode = (text:string, start: number, end?: number) => {
  const subText = text.substring(start, end)
  const subTextSpan = document.createElement('span')
  subTextSpan.appendChild(document.createTextNode(subText))
  return subTextSpan
}

const getLinkNode = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  link.textContent = url
  link.target = '_blank'

  return link
}

export const generateDescriptionContent = (container: HTMLDivElement, description: string = '', isEcoinventDatabase: boolean = false) => {
  let text = description
  if (isEcoinventDatabase) {
    text = description.replace(/;(?!\s)/g, '\n')
  }

  const urlIndices = findUrlIndices(text)

  if (urlIndices.length === 0) {
    container.appendChild(getTextNode(text, 0))
  }

  urlIndices.forEach((item, index) => {
    let textStart = 0
    if (index > 0) {
      textStart = urlIndices[index - 1].end
    }

    container.appendChild(getTextNode(text, textStart, item.start))
    container.appendChild(getLinkNode(item.url))

    if (index === urlIndices.length - 1) {
      container.appendChild(getTextNode(text, item.end))
    }
  })
}
