import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation copyProduct($productID: ID!) {
    copyProduct(productID: $productID) {
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
