import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { Tooltip } from 'primereact/tooltip'

import { NumberPrecision, ScientificNotationThreshold } from '../../enum'
import { getDecimalDisplayValue } from '../../util/decimal'
import { DecimalViewType } from '../../enum/decimal'

export type TDecimalPointComponentProps = {
  value?: any
  threshold?: ScientificNotationThreshold
  decimalPlaces?: NumberPrecision
  enableTooltip?: boolean
  decimalViewType?: DecimalViewType
}
export const DecimalPointComponent = ({
  value,
  threshold,
  decimalPlaces,
  decimalViewType,
  enableTooltip = false
}: TDecimalPointComponentProps) => {
  const decimalRef = useRef<HTMLDivElement>(null)
  const [ displayTooltip, setDisplayTooltip ] = useState<boolean>(false)
  useEffect(() => {
    if (decimalRef?.current && enableTooltip) {
      setDisplayTooltip(true)
    }
  }, [ decimalRef?.current ])

  const [ coefficient, exponent ] = useMemo(
    () => getDecimalDisplayValue({
      value, threshold, decimalPlaces, decimalViewType
    }),
    [ value, threshold, decimalPlaces, decimalViewType ]
  )

  const parsedValue = !value ? 0.0 : Number.parseFloat(value)
  const isPercentage = decimalViewType === DecimalViewType.PercentageValue
  const tooltipContent = isPercentage ? parsedValue * 100 : value
  return (
    <>
      { displayTooltip
        && (
          <Tooltip
            data-testid="decimal-display-value-tooltip"
            className="max-w-15rem"
            target={decimalRef.current || ''}
            showDelay={1000}
            autoHide={false}
            position="bottom"
          >
            <div onClick={(event:any) => event.stopPropagation()}>{ tooltipContent }</div>
          </Tooltip>
        )}
      <div
        ref={decimalRef}
        data-testid="decimal-display-value"
        className="white-space-nowrap overflow-hidden text-overflow-ellipsis mr-1"
      >
        { exponent ? (
          <>
            { coefficient }
            {' '}
            &middot; 10
            <sup>{ exponent }</sup>
          </>
        ) : <>{ coefficient }</> }
        { isPercentage && <>%</>}
      </div>
    </>
  )
}
