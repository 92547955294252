import Immutable from 'immutable'
import {
  SET_INVENTORY_EXPANDED_KEYS,
  EXPAND_INVENTORY_NODE,
  COLLAPSE_INVENTORY_NODE
} from '../actions/inventoryExpandedKeys.actions'

// Holds a structure that looks like this:
// {
//   [INVENTORY_ROOT_ID]: {
//     [NODE_KEY]: true,
//     [NODE_KEY]: false,
//   }
// }
// Notice that i am using keys only without labels for performance reasons,
// we need expanded/collapsed state also for big inventories

const inventoryTreeExpandedKeysReducer = (state = {}, action = {}) => {
  switch (action.type) {
  case 'CLEAR_GLOBAL_STATE_EXCEPT_USER':
  case 'CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT':
  case 'CLEAR_TEST':
    return {}
  case SET_INVENTORY_EXPANDED_KEYS:
    return { ...state, [action.inventoryRootId]: action.inventoryTreeKeysMap }
  case EXPAND_INVENTORY_NODE:
    return Immutable
      .fromJS(state)
      .mergeDeep( Immutable.Map({
        [action.inventoryRootId]: {
          [action.inventoryTreeKey]: true
        }
      }))
      .toJS()
  case COLLAPSE_INVENTORY_NODE:
    return Immutable
      .fromJS(state)
      .mergeDeep( Immutable.Map({
        [action.inventoryRootId]: {
          [action.inventoryTreeKey]: false
        }
      }))
      .toJS()
  default:
    return state
  }
}

export default inventoryTreeExpandedKeysReducer

