import React, { useState, useEffect, useContext } from 'react'

import { Panel } from 'primereact/panel'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'
import { IInventory, IPhase } from '../../../model'
import { ProductModelContainer } from '../../../product/container/product-model.container'
import { ProductDetailProvider } from '../../../product/provider/product-detail.provider'
import { HeaderPhaseContainer } from '../../container/header-phase.container'
import { PhaseInventoryComponent } from './phase-inventory.component'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { isValid } from '../../../shared/util/tools'
import { normalize } from '../../../../utils/tools'

interface IPhaseItemWrapperProps {
  readonly draggingEnabled?: boolean
  readonly dragging?: boolean
}

const getColor = (draggingEnabled: boolean = false, dragging: boolean = false, defaultColor: string = 'var(--gray-200)') => {
  if (dragging) return 'var(--green-500)'
  if (draggingEnabled) return 'var(--gray-400)'
  return defaultColor
}
const PhaseItemWrapper = styled.div<IPhaseItemWrapperProps>`
  &.deleting-phase-panel {
    border-color: var(--red-500) !important;
    min-height: 3rem;
    height: unset;
  }

  &.dragging-phase-panel {
    border: 1px solid;
    border-color: ${({ draggingEnabled, dragging }: IPhaseItemWrapperProps) => getColor(draggingEnabled, dragging)} !important;
    min-height: 3rem;
    height: unset;

    .p-toggleable-content {
      transform: none !important;
    }
  }


  .p-panel {
    width: 100%;
  }
  .p-panel-content {
    padding: 0;
    cursor: default;
  }

  .side-line {
    width: 0.125rem;
  }
  .side-line-footer {
    height: 2px;
  }
`

type TPhaseItemComponentProps = {
  phase?: IPhase,
  currentItemIsBeingDrag?: boolean
}
export const PhaseItemComponent = ({ phase, currentItemIsBeingDrag = false }: TPhaseItemComponentProps) => {
  const { totalImpact, product: phaseProduct } = phase?.inventory || {}

  const productInventory: IInventory = { product: phaseProduct, totalImpact }
  const [ panelCollapsed, setPanelCollapsed ] = useState<boolean>(true)
  const [ draggingEnabled, setDraggingEnabled ] = useState<boolean>(false)
  const {
    enabledDragPhaseId,
    enableAddingPhase,
    enabledDeletePhaseId,
    expandedPhases = [],
    readonlyInventory,
    noInventoryImpactBar,
    analysisMode,
    treeIsSecondary
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  useEffect(() => {
    setPanelCollapsed(!phaseIsInExpandedPhases)
    phase?.id && setDraggingEnabled(enabledDragPhaseId === phase?.id)
  }, [ expandedPhases, enabledDragPhaseId ])

  const phaseIsInExpandedPhases = expandedPhases.some(collapsedPhase => collapsedPhase.id === phase?.id)
  const enabledDeletingPhase = isValid(phase?.id) && enabledDeletePhaseId == phase?.id

  return (
    <PhaseItemWrapper
      data-cy={`phase-item-${normalize(phase?.name)}`}
      dragging={currentItemIsBeingDrag}
      draggingEnabled={(isValid(phase?.id) && enabledDragPhaseId === phase?.id)}
      className={classNames('flex w-full bg-white border-1 border-round-md p-2 relative', {
        'border-gray-100': !currentItemIsBeingDrag && phase,
        'dragging-phase-panel': currentItemIsBeingDrag || draggingEnabled,
        'border-green-500': enableAddingPhase && !phase,
        'deleting-phase-panel': enabledDeletingPhase
      })}
    >
      <Panel
        toggleable
        collapsed={panelCollapsed}
        transitionOptions={{ disabled: true, addEndListener: () => {} }}
        onToggle={(e: any) => setPanelCollapsed(e.value)}
        headerTemplate={(options: any) => (
          <HeaderPhaseContainer {...{
            phase, options, currentItemIsBeingDrag, setPanelCollapsed
          }}
          />
        )}
      >
        { !panelCollapsed && (
          <div className="flex pl-2 pr-4">
            <div className="flex flex-column flex-none w-2rem justify-content-center align-items-center">
              <div className="flex-none border-left-1 border-gray-100 side-line flex-grow-1"></div>
              <div className="flex-none border-top-1 border-gray-100 w-1rem side-line-footer"></div>
            </div>
            <div className="flex-grow-1 overflow-x-auto">
              { phase && (
                <>
                  <ProductDetailProvider
                    noInventoryImpactBar={noInventoryImpactBar}
                    readonlyInventory={readonlyInventory}
                    productInventory={productInventory}
                    analysisMode={analysisMode}
                    treeIsSecondary={treeIsSecondary}
                    showInventoryHeader={false}
                  >
                    <ProductModelContainer>
                      <PhaseInventoryComponent lifecyclePhase={phase} />
                    </ProductModelContainer>
                  </ProductDetailProvider>
                </>
              ) }
            </div>
          </div>
        )}
      </Panel>
    </PhaseItemWrapper>
  )
}
