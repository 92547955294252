import React, { useContext } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import { FiSettings } from 'react-icons/fi'
import { useReactiveVar } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { useRefOverflow } from '../../shared/hook/use-ref-overflow'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

const TitleWrapper = styled.div`
  width: calc(100% - 23rem);
`
export const WorkspaceHeaderComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { space } = useReactiveVar(selectedWorkspaceVar) || {}
  const [ titleRef, doesTitleOverflows ] = useRefOverflow()
  const { openWorkspaceSetting } = useContext<IDashboardContext>(DashboardContext)

  // TODO: This should be temporary fix until we make the new sidebar with prime react
  // const { openSidebar } = useContext<IDashboardContext>(DashboardContext)
  const handleWorkspaceSetting = () => {
    openWorkspaceSetting && openWorkspaceSetting(space)
  }

  return (
    <>
      <TitleWrapper className="flex flex-none">
        {space && (
          <>
            <Tooltip target=".workspace-header-title" onBeforeShow={() => doesTitleOverflows}>
              <div className="w-20rem">{ space.name }</div>
            </Tooltip>
            <div
              ref={titleRef}
              data-pr-position="bottom"
              data-testid="workspace-header-name"
              className="w-full text-gray-500 font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis workspace-header-title px-5 pt-6 mx-2"
            >
              { space.name }
            </div>
          </>
        )}
      </TitleWrapper>
      <div className="flex-none w-23rem">
        <div className="flex justify-content-end px-5 pt-6 mx-2">
          <Button
            onClick={handleWorkspaceSetting}
            data-testid="workspace-setting"
            data-cy="welcome-workspace-settings-button"
            className="p-button-text p-button-plain text-sm p-0"
          >
            <span className="mr-1">{ t('labels.settings', { context: 'workspace', ns: 'common' }) }</span>
            <FiSettings size={16} />
          </Button>
        </div>
      </div>
    </>
  )
}
