import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

const LoadingWrapper = props => {
  const indicator = <LoadingOutlined data-cy="loading-something" className='default-loader-color' style={ { fontSize: 24 } } spin/>
  return <Spin indicator={ indicator } spinning={props.loading}>
    { props.children }
  </Spin>
}
export default LoadingWrapper
