import { Col, Row } from 'antd'
import { DragOutlined } from '@ant-design/icons'
import React from 'react'
import compose from '../../../utils/compose'
import { Draggable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../utils/with-router'
import classnames from 'classnames'
import { replaceSpacesWithLowDashes } from '../../../utils/tools'
import PhaseCircle from './phaseCircle'

class PhaseTimelineItem extends React.Component {

  render() {
    const {
      name,
      content,
      className,
      currentPhaseId,
      isSelectedPhase,
      handleClick,
      currentPhaseIndex,
      isOrderingPhases,
      isShowLifecycleScenarioInventory,
      isInventoryCompare,
      timelineItemType,
      firstLetter,
      phaseType
    } = this.props
    const isShowExtraColumns = !isInventoryCompare && !isShowLifecycleScenarioInventory

    return (
      <Draggable isDragDisabled={!isOrderingPhases} key={ currentPhaseId } draggableId={ currentPhaseId } index={ currentPhaseIndex }>
        { (provided, snapshot) => (
          <div
            className={classnames('timeline-container', { 'lifecycle-scenario': isShowLifecycleScenarioInventory })}
            ref={ provided.innerRef }
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Row
              className={ classnames({ isDragging: snapshot.isDragging }, 'timeline-item-wrapper', className ? className : '')}
              type="flex"
              data-cy={ `phase-named-${ replaceSpacesWithLowDashes(name) }` }
            >
              <Col
                onClick={ () => handleClick(currentPhaseId) }
                className={ classnames('phase-name-with-first-letter', {
                  on: isSelectedPhase,
                  'phase-name-with-first-letter--sm': !isShowExtraColumns
                }) }
              >
                <Row className='timeline-item-title'>
                  {isShowExtraColumns &&
                  <Col className='timeline-item timeline-item_name'>
                    <span className='timeline-item_name-content'>{ name }</span>
                  </Col>
                  }
                  <Col
                    className='timeline-item timeline-item-line'
                  >
                    <PhaseCircle
                      name={name}
                      timelineItemType={timelineItemType}
                      firstLetter={firstLetter}
                      isSelectedPhase={isSelectedPhase}
                      isShowLifecycleScenarioInventory={isSelectedPhase}
                      phaseType={phaseType}
                    />
                    <div className='timeline-item-tail'/>
                  </Col>
                </Row>

              </Col>

              <Col className='timeline-item timeline-item_content'>
                {
                  isOrderingPhases ?
                    <div className="text-right">
                      <DragOutlined data-cy="drag-phase-icon" className="drag-action-icon" />
                    </div>
                    :
                    content ? content : null
                }
              </Col>
            </Row>
          </div>
        ) }
      </Draggable>
    )
  }
}

export default compose(
  withRouter,
  withTranslation(),
)(PhaseTimelineItem)

