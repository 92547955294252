import React from 'react'

import { NavigationLayout } from '../layout/navigation.layout'
import { ContentLayout } from '../layout/content.layout'

import { IUseUserResult, useUserQuery } from '../hooks/user-query'
import LoadingPage from '../../../component/shared/loadingPage'
import { FeatureProvider } from '../provider/feature.provider'
import BrowserCompatibility from '../../../component/browserCompatibility'

export const DashboardContainer = () => {
  const { loading }: IUseUserResult = useUserQuery()
  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <FeatureProvider>
          <BrowserCompatibility>
            <div data-testid="dashboard-container" className="flex col-12 h-full p-0 overflow-hidden">
              <NavigationLayout />
              <ContentLayout />
            </div>
          </BrowserCompatibility>
        </FeatureProvider>
      )}
    </>
  )
}
