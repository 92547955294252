import React, { useContext, useRef } from 'react'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import html2canvas from 'html2canvas'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownToBracket, faFileChartPie, faImage, faAngleDown
} from '@fortawesome/pro-regular-svg-icons'

import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { getDownloadFilename, isValid } from '../../../shared/util/tools'

interface MenuDownloadDoughnutComponentProps {
  containerRef?: React.RefObject<HTMLDivElement | null>
}

export const MenuDownloadDoughnutComponent = ({ containerRef }: MenuDownloadDoughnutComponentProps) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const {
    productInventory: { product = null } = {},
    updateProductDetail
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const menu = useRef<Menu>(null)
  const [ trackEventInSegment ] = getSegmentTrack()

  const updateLegendItemsWithEllipsis = (cloneElement: Document) => {
    const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D
    ctx.font = '400 14px sans-serif'
    const legendItems = cloneElement.querySelectorAll('[data-testid^="doughnut-legend-item"]')
    const ellipsis = '...'
    const ellipsisWidth = ctx?.measureText(ellipsis).width || 0

    legendItems.forEach(item => {
      const elementWidth = item.getBoundingClientRect().width
      let itemText = item?.textContent || ''

      let textWidth = ctx?.measureText(itemText).width || 0

      if (textWidth < elementWidth) {
        return itemText
      }
      let { length } = itemText
      while (textWidth >= elementWidth - ellipsisWidth && length > 0) {
        length--
        itemText = itemText.substring(0, length)
        textWidth = ctx?.measureText(itemText).width || 0
      }
      item.textContent = itemText + ellipsis
    })
  }

  const downloadChart = async () => {
    if (containerRef?.current) {
      trackEventInSegment(TrackEventType.DOWNLOADED_IMAGE_DONUT_CHART)
      const canvas = await html2canvas(containerRef?.current, {
        onclone: (cloneElement: Document) => {
          const updateClassList = (domElement?: Element | null, add?: string | null, remove?: string | null,) => {
            remove && domElement?.classList.remove(remove)
            add && domElement?.classList.add(add)
          }
          updateClassList(cloneElement.querySelector('.png-footer-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.png-header-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.doughnut-view-wrapper'), 'bg-white', 'doughnut-view-shadow')
          updateClassList(cloneElement.querySelector('.doughnut-view-wrapper'), 'png-export-wrapper')
          updateLegendItemsWithEllipsis(cloneElement)
        },
        backgroundColor: 'white',
        removeContainer: true,
        allowTaint: true,
        useCORS: true
      })
      const image = canvas.toDataURL('image/png', 1.0)
      const a = document.createElement('a')
      a.href = image
      a.download = getDownloadFilename({ value: `Donut chart for ${product?.name}`, extension: 'png' })
      a.click()
    }
  }

  const downloadActionList = [
    {
      label: t('actions.download', { context: 'pdf' }),
      icon: <FontAwesomeIcon icon={faFileChartPie} className="text-sm mr-1" />,
      command: () => {
        updateProductDetail({ showPdfInsightsResult: true })
        trackEventInSegment(TrackEventType.DOWNLOADED_PDF_RESULTS)
      },
      template: MenuItemComponent
    },
    {
      label: t('actions.download', { context: 'image', ns: 'common' }),
      icon: <FontAwesomeIcon icon={faImage} className="text-sm mr-1" />,
      command: downloadChart,
      template: MenuItemComponent
    }
  ]

  const isDownloadEnabled = () => isValid(product?.referenceProduct) || product?.hasInventory

  return (
    <>
      <Menu model={downloadActionList} popup ref={menu} id="popup_menu" className="mt-1 w-auto" />

      { !isDownloadEnabled() && <Tooltip className="w-15rem" target=".disabled-doughnut-download" position="bottom" disabled={isDownloadEnabled()} /> }

      <div className="flex disabled-doughnut-download" data-pr-tooltip={t('messages.description', { context: 'addItemsOrImpact', ns: 'product' })}>
        <Button
          className="p-button p-component p-button-outlined p-button-plain text-sm h-2rem px-2"
          icon={<FontAwesomeIcon icon={faArrowDownToBracket} className="mr-2" />}
          onClick={(event: any) => menu?.current?.toggle(event)}
          label={t('actions.download', { context: 'chart', ns: 'common' })}
          aria-controls="popup_menu"
          aria-haspopup
          data-testid="menu-download-graph"
          disabled={!isDownloadEnabled()}
        >
          <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
        </Button>
      </div>

    </>
  )
}

