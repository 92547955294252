import { PAGINATION } from '../../utils/const'
import { createReducer } from '../../utils/reducerTools'
import { CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT, CLEAR_GLOBAL_STATE_EXCEPT_USER } from '../actions/clear.actions'
import { SET_REFERENCE_PRODUCTS_RESULT, SET_REFERENCE_DATABASES, SET_REFERENCE_PAGE_SIZE } from '../actions/referenceProducts.actions'

const defaultReferenceProductsState = {
  results: {
    items: [],
    total: 0
  },
  referencePageSize: PAGINATION.PAGE_SIZE
}

const referenceProductsReducer =  createReducer(defaultReferenceProductsState, {
  [CLEAR_GLOBAL_STATE_EXCEPT_USER]: () => ({ ...defaultReferenceProductsState }),
  [CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT]: () => ({ ...defaultReferenceProductsState }),
  [SET_REFERENCE_PRODUCTS_RESULT]: (state, action) => ({ ...state, results: action.results }),
  [SET_REFERENCE_DATABASES]: (state, action) => ({ ...state, referenceDatabases: action.databases }),
  [SET_REFERENCE_PAGE_SIZE]: (state, action) => ({ ...state, referencePageSize: action.referencePageSize })
})

export default referenceProductsReducer
