import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import classnames from 'classnames'
import React, { useState, useEffect } from 'react'
import compose from '../../../utils/compose'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { withTranslation } from 'react-i18next'
import { arrayMove, isEmpty, returnNested, safeArray, normalize } from '../../../utils/tools'
import { calculateMaxImpactAmount } from './lifecycleUtils'
import PhaseInventoryTree from './phaseInventoryTree.container'
import PhaseTimelineItem from './phaseTimelineItem'

const LifecycleTreeView  = props => {
  const {
    isLeftTree,
    lifecycle,
    selectPhaseSaga,
    setIsCreateNewInventoryItemAction,
    impactCategory,
    selectedInventoryItem,
    isReadOnly,
    setIsCreatePhaseAction,
    reorderPhaseSaga,
    selectedPhaseId,
    isOrderingPhases,
    isShowLifecycleScenarioInventory,
    isInventoryCompare,
    areImpactsBarsShown,
    t
  } = props

  const [ maxImpactAmount, setMaxImpactAmount ] = useState(0)

  useEffect(() => {
    function calculateMaxImpactAmountHook() {
      setMaxImpactAmount(calculateMaxImpactAmount(lifecycle))
    }
    calculateMaxImpactAmountHook()
  }, [ lifecycle ])

  const addProductToPhase = phase => {
    setIsCreateNewInventoryItemAction(true)
    selectPhaseSaga(phase)
  }

  const phaseInventoryTree = (phase, maxImpactAmount, currentPhaseIndex) => {
    return (
      <React.Fragment>
        { safeArray(returnNested(phase, 'inventory', 'inventoryItems')).length === 0 &&
        <div className="phase-row">
          {
            !isReadOnly ?
              <React.Fragment>
                <Button
                  data-cy="add-phase-subitem"
                  onClick={ () => addProductToPhase(phase) }
                  className="add-product-btn button transparent"
                  icon={<PlusOutlined />}
                >{ t('model.add_child') }</Button>
              </React.Fragment>
              : <div data-cy="phase-empty-container" className="button transparent" />
          }
        </div>
        }
        <PhaseInventoryTree
          isLeftTree={ isLeftTree }
          phase={ phase }
          maxImpactAmount={ maxImpactAmount }
          impactCategory={ impactCategory }
          selectedInventoryItem={ selectedInventoryItem }
          key={ phase.id }
          isReadOnly={isReadOnly}
          areImpactsBarsShown={areImpactsBarsShown}
          currentPhaseIndex={currentPhaseIndex}
        />

      </React.Fragment>
    )
  }

  const handleSelectPhase = phaseId => {
    const phases = returnNested(lifecycle, 'phases')

    selectPhaseSaga(phases.find(el => el.id === phaseId))
    setIsCreateNewInventoryItemAction(false)
    setIsCreatePhaseAction(false)
  }

  const onDragEnd = ({ source, destination }) => {
    if ((!destination || !source) || (source.index === destination.index)) {
      return
    }
    const phaseIds = returnNested(lifecycle, 'phases').map(phase => phase.id)
    const orderedPhaseIds = arrayMove(phaseIds, source.index, destination.index)

    reorderPhaseSaga(
      returnNested(lifecycle, 'id'),
      orderedPhaseIds,
      phaseIds
    )
  }

  if (isEmpty(lifecycle)) return null

  return <React.Fragment>
    <Row
      data-cy={`lifecycle-named-${normalize(lifecycle.name)}-container`}
      className={ classnames('lifecycle', { 'lifecycle--drag-enabled': isOrderingPhases } ) }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={lifecycle.id}>
          {(provided, snapshot) => {
            return (
              <Col span={ 24 } className={classnames({ isDraggingOver: snapshot.isDraggingOver }, 'phase-list')}>
                <div
                  ref={provided.innerRef}
                  className={classnames({ 'drag-enabled': isOrderingPhases })}
                  {...provided.droppableProps }
                >
                  {
                    !isEmpty(lifecycle.phases) &&
                    <React.Fragment>
                      { lifecycle.phases.map((phase, index) => (
                        <PhaseTimelineItem
                          phaseType={phase.type}
                          handleClick={handleSelectPhase}
                          firstLetter={ phase.name[0] }
                          name={ phase.name }
                          content={ phaseInventoryTree(phase, maxImpactAmount, index) }
                          key={ phase.id }
                          currentPhaseIndex={ index }
                          currentPhaseId={ phase.id}
                          isSelectedPhase={ phase.id === selectedPhaseId }
                          isOrderingPhases={isOrderingPhases}
                          isShowLifecycleScenarioInventory={isShowLifecycleScenarioInventory}
                          isInventoryCompare={isInventoryCompare}
                        />
                      ))
                      }
                      {provided.placeholder}
                    </React.Fragment>
                  }
                  {provided.placeholder}
                </div>
              </Col>
            )
          }}
        </Droppable>
      </DragDropContext>
    </Row>
  </React.Fragment>
}

export { LifecycleTreeView }
export default compose(
  withTranslation()
)(LifecycleTreeView)
