import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

import { ILifecycleProvider } from '../interface/lifecycle-context.type'

import LifecycleDetailContext from './context/lifecycle-detail.context'
import { ILifecycleDetailProvider } from '../interface/lifecycle-detail-context.type'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { TFeatureContext } from '../../dashboard/interface/feature-context.type'
import FeatureContext from '../../dashboard/context/feature.context'
import { Feature } from '../../shared/enum/feature'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'

import { ImpactType } from '../../shared/enum/impact'
import { AnalysisMode } from '../../analysis/enum/analysis'

type LifecycleDetailProviderProp = {
  analysisMode?: AnalysisMode
  showInventoryHeader?: boolean
  treeIsSecondary?: boolean
  readonlyInventory?: boolean
  noInventoryImpactBar?: boolean
  children?: React.ReactNode
}
export const LifecycleDetailProvider = ({
  analysisMode,
  treeIsSecondary = false,
  noInventoryImpactBar = false,
  showInventoryHeader = true,
  readonlyInventory = false,
  children
}: LifecycleDetailProviderProp) => {
  const [ lifecycleDetail, setLifecycleDetail ] = useState<ILifecycleDetailProvider>({
    analysisMode,
    noInventoryImpactBar,
    treeIsSecondary,
    showInventoryHeader,
    readonlyInventory,
    expandedPhases: []
  })

  const { t } = useTranslation([ 'common' ])
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const navigate = useNavigate()
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const updateLifecycleDetail = (newState: ILifecycleProvider = {}) => {
    setLifecycleDetail(prevState => ({ ...prevState, ...newState }))
  }

  const getMaxImpactAmount = () => {
    const phases = lifecycleDetail?.lifecycleDetail?.phases || []
    const lifecycleTotalImpact = lifecycleDetail.lifecycleTotalImpact || {}
    const totalImpactAmounts: number[] = phases.map(phase => Math.abs(parseFloat(phase.inventory?.totalImpact?.amount || '0')))
    const maximumImpactAmounts: number[] = phases.map(phase => Math.abs(parseFloat(phase.inventory?.maximumImpact?.amount || '0')))

    const fTotalImpactAmount = parseFloat(lifecycleTotalImpact.amount || '')
    const maxImpactAmount = Math.abs(fTotalImpactAmount)

    if (totalImpactAmounts.length === 0) return maxImpactAmount

    return Math.max(
      ...totalImpactAmounts,
      ...maximumImpactAmounts,
      maxImpactAmount
    )
  }

  const getImpactType = () => {
    const { lifecycleTotalImpact: { amount: totalImpactAmount = '' } = {} } = lifecycleDetail || {}
    const fTotalImpactAmount = totalImpactAmount && parseFloat(totalImpactAmount)

    if (!fTotalImpactAmount || fTotalImpactAmount === 0 || Number.isNaN(fTotalImpactAmount)) {
      return ImpactType.Negative
    }

    const maxImpactAmount = getMaxImpactAmount()
    const ratio = fTotalImpactAmount / maxImpactAmount
    const impactType = ratio > 0 ? ImpactType.Positive : ImpactType.Negative
    return impactType
  }

  const goToInventoryTab = (tab: string) => {
    addToBackToList(t('labels.tab', { context: tab }))
    const id = lifecycleDetail?.lifecycleDetail?.id
    if (!enableLegacyLifecycleInventory) {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/detail`)
    } else {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/inventory`)
    }
  }

  return (
    <LifecycleDetailContext.Provider value={{
      ...lifecycleDetail,
      updateLifecycleDetail,
      getMaxImpactAmount,
      getImpactType,
      goToInventoryTab
    }}
    >
      { children }
    </LifecycleDetailContext.Provider>
  )
}
