import { useQuery } from '@apollo/client'
import { PAGINATION } from '../../constants'
import { ProductLabel } from '../../enum'
import { isValid } from '../../util/tools'
import { ENTITIES_PAGINATED } from '../../../graphql/query/entitiesPaginated'
import {
  SortLifeCycle, LifeCycleSortField, SortingOrder, SortProduct, ProductSortField
} from '../../../../__generated__/graphql'

export type TEntitiesPaginatedQueryProps = {
  spaceID?: any
  query?: string
  pageSize?: number
  labels?: ProductLabel[],
  disabled?: boolean,
  skipLifeCycles?: boolean
  skipProducts?: boolean
  sortProduct?: SortProduct,
  sortLifeCycle?: SortLifeCycle
}

export const useEntitiesPaginatedQuery = ({
  query = '',
  spaceID,
  pageSize = PAGINATION.PAGE_SIZE,
  disabled = false,
  skipLifeCycles = false,
  skipProducts = false,
  sortProduct = { field: ProductSortField.Name, order: SortingOrder.Asc },
  sortLifeCycle = { field: LifeCycleSortField.Name, order: SortingOrder.Asc }
}: TEntitiesPaginatedQueryProps) => {
  // TODO : Investigate why skip is not working here
  const {
    loading: loadingEntities,
    data: entitiesData,
    refetch: refetchEntities,
    networkStatus: entitiesNetworkStatus
  } = useQuery(ENTITIES_PAGINATED, {
    skip: !isValid(spaceID) || disabled,
    variables: {
      spaceID, query, pageSize, skipLifeCycles, skipProducts, sortProduct, sortLifeCycle
    },
    fetchPolicy: 'no-cache'
  })

  return {
    loading: loadingEntities,
    entitiesData,
    refetchEntities,
    entitiesNetworkStatus
  }
}
