import React from 'react'

import { GettingStatedChecklistItem } from '../../../shared/enum/sign-up-experience'
import { GettingStartedChecklistItemComponent } from './getting-started-checklist-item.component'

export const GettingStartedChecklistComponent = () => {
  const gettingStartedChecklistItems: GettingStatedChecklistItem[] = [
    GettingStatedChecklistItem.CreateProductBlueprint,
    GettingStatedChecklistItem.ModelYourProduct,
    GettingStatedChecklistItem.GetFootprintCheck,
    GettingStatedChecklistItem.ShareYourFootprint
  ]

  return (
    <div className="flex flex-column w-full mb-3">
      {
        gettingStartedChecklistItems.map((
          gettingStartedChecklistItem: GettingStatedChecklistItem,
          index: number
        ) => (
          <div data-testid="getting-started-item-wrapper" className="flex flex-column w-full mt-3" key={`getting-started-checklist-${index}`}>
            <GettingStartedChecklistItemComponent {...{ gettingStartedChecklistItem, step: index + 1 }} />
          </div>
        ))
      }
    </div>
  )
}
