import {
  SET_LAST_SELECTED_ACCOUNT_ID,
  SET_LAST_SELECTED_SPACE_ID,
  SET_LAYOUT_VIEW_TYPE
} from '../actions/settings.actions'

const defaultSettingsState = {
  userSettings: []
}

const getUpdatedUserSettingsState = ( initialState, action, updatedProperty ) => {
  const updatedState = { ...initialState }
  if (Object.prototype.hasOwnProperty.call(updatedState, action.selectedUserId)) {
    updatedState[action.selectedUserId][updatedProperty] = action[updatedProperty]
  } else {
    updatedState[action.selectedUserId] = { updatedProperty: action[updatedProperty] }
  }
  return updatedState
}

const settingsReducer = (state = defaultSettingsState, action={}) => {
  switch (action.type) {
  case SET_LAST_SELECTED_ACCOUNT_ID:{
    let userSettingsForUpdateLastSelectedAccount = getUpdatedUserSettingsState(state.userSettings, action, 'lastSelectedAccountId')
    return { ...state, userSettings: userSettingsForUpdateLastSelectedAccount }
  }
  case SET_LAST_SELECTED_SPACE_ID:{
    let userSettingsForUpdateLastSelectedSpace = getUpdatedUserSettingsState(state.userSettings, action, 'lastSelectedSpaceId')
    return { ...state, userSettings: userSettingsForUpdateLastSelectedSpace }
  }
  case SET_LAYOUT_VIEW_TYPE:{
    let userSettingsForUpdateLayoutViewType = getUpdatedUserSettingsState(state.userSettings, action, 'layoutViewType')
    return { ...state, userSettings: userSettingsForUpdateLayoutViewType }
  }
  default:
    return state
  }
}
export default settingsReducer
