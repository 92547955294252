import { connect } from 'react-redux'
import {
  setWorkspaceActivePanelsAction,
  setSpaceNameAction,
  addSpacePermissionAction,
  removeSpacePermissionAction,
  changeSpacePermissionAction
} from '../../../../redux/actions/global.actions'
import { renameWorkspaceSaga } from '../../../../redux/sagas/space.saga'
import WorkspaceDetails from './workspaceDetails'
import SpaceSelector from '../../../../redux/selectors/space.selector'

const mapStateToProps = state => ({
  workspaceActivePanels: SpaceSelector.workspaceActivePanels(state),
  selectedSpace: SpaceSelector.selectedSpace(state),
  selectedSpacePolicies: SpaceSelector.policies(state)
})

const mapDispatchToProps = {
  setWorkspaceActivePanels: setWorkspaceActivePanelsAction,
  addSpacePermission: addSpacePermissionAction,
  removeSpacePermission: removeSpacePermissionAction,
  changeSpacePermission: changeSpacePermissionAction,
  setSpaceName: setSpaceNameAction,
  renameWorkspaceSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceDetails)
