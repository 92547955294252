import { gql } from '@apollo/client'

export default gql`
  query lifecycles($query: String, $spaceID: ID!) {
    lifecycles(query: $query, spaceID: $spaceID){
        id
        name
    }
  }
`
