import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  query product($id: ID!){
    product(id: $id) {
      ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
