import { useEffect, useState } from 'react'

export const useWindowSize = () => {
  const [ windowSize, setWindowSize ] = useState<number[]>([])
  useEffect(() => {
    function handleResize() {
      setWindowSize([ window.innerWidth, window.innerHeight ])
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
