import React, { useContext } from 'react'
import { TaskbarToggleViewComponent } from '../../shared/component/general/taskbar-toggle-view.component'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { ViewType } from '../../shared/enum'
import { setOverviewSetting } from '../../shared/util/overview'

export const LifecycleTaskbarComponent = () => {
  const {
    selectedViewType,
    updateLifecycle
  } = useContext<TLifecycleContext>(LifecycleContext)

  const onViewChange = (selectedViewType: ViewType) => {
    updateLifecycle({ selectedViewType })
    setOverviewSetting({ selectedViewType })
  }

  return (
    <div className="flex w-full h-full">
      <div data-testid="lifecycle-taskbar-filter" className="flex flex-grow-1 h-2rem"></div>
      <div data-testid="lifecycle-taskbar-actions" className="flex flex-none w-13rem h-2rem justify-content-end gap-3">
        <TaskbarToggleViewComponent onViewChange={onViewChange} selectedViewType={selectedViewType} />
      </div>
    </div>
  )
}
