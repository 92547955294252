import React, { useEffect } from 'react'
import { Modal, Form, InputNumber, Button, Select } from 'antd'
import { withTranslation } from 'react-i18next'

import compose from '../../../../utils/compose'

const ReferencePropertyDialog = ({
  t,
  visible,
  handleSave,
  handleCancel,
  selectedProduct,
  referencePropertiesList = []
}) => {
  const [ form ] = Form.useForm()

  const { name, referenceProduct, productProperties = [], unit } = selectedProduct
  const productProperty = productProperties.find(property => property.referenceProperty.id === referenceProduct?.referenceProperty?.id)
  const { referenceUnits = [] } = referencePropertiesList.find( property => property.id === productProperty?.referenceProperty?.id) || {}

  useEffect(() => {
    form.resetFields()
  }, [ productProperties ])

  const onFinish = values => {
    const { conversionFactor, unit } = values
    const referenceUnit = referenceUnits.find(refUnit => refUnit.id === unit)
    handleSave({ ...productProperty, conversionFactor, referenceUnit })
  }

  return (
    <Modal
      header={null}
      footer={null}
      visible={ visible && productProperty }
      destroyOnClose={true}
      onCancel={ handleCancel }
      forceRender
      className='reference-property-dialog'>
      <div className='reference-property-dialog__wrap'>
        <h3 className='reference-property-dialog__title'>{ t('search_reference_product.referencePropertyTitle') }</h3>
        <p className='reference-property-dialog__info'>{ t('search_reference_product.referencePropertyModalInfo', { propertyName: productProperty?.referenceProperty?.name }) }</p>
        <p className='reference-property-dialog__info'>{t('search_reference_product.referencePropertyAmountLabel', { propertyName: productProperty?.referenceProperty?.name, productUnit: unit, productName: name })}</p>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          onFinish={ onFinish }
          initialValues={{ conversionFactor: productProperty?.conversionFactor, unit: productProperty?.referenceUnit.id }}>

          <Form.Item
            name='conversionFactor'
            className='reference-property-dialog__formItem'
            label={t('global.amount')}
            rules={[ { required: true, message: t('validation.is_required', { name: 'Property Unit' }) } ]}>
            <InputNumber placeholder={t('search_reference_product.referencePropertyAmountPlaceholder', { unit: productProperty?.referenceUnit?.name })} />
          </Form.Item>
          <Form.Item
            name='unit'
            label={t('global.unit')}
            rules={[ { required: true, message: t('validation.is_required', { name: 'Property Unit' }) } ]}
            className='reference-property-dialog__formItem'>
            <Select style={{ width: 91 }} >
              { referenceUnits?.map( ({ id, name }) => (<Select.Option key={id}>{name}</Select.Option>)) }
            </Select>
          </Form.Item>

          <Form.Item
            className='reference-property-dialog__formItem reference-property-dialog__formItem--full'>
            <Button className='button primary' htmlType='submit' data-cy='update-reference-property' >
              {t('global.save')}
            </Button>
          </Form.Item>
        </Form>
        <div className='clear-fix'></div>
      </div>
    </Modal>
  )
}

export { ReferencePropertyDialog }
export default compose(
  withTranslation()
)(ReferencePropertyDialog)
