import { gql } from '@apollo/client'

export default gql`
  mutation changeLifecycleUnit($id: ID!, $unit: String!) {
    changeLifecycleUnit(id: $id, unit: $unit) {
      id
      name
    }
  }
`
