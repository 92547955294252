import { getEnvironmentFromUrl } from './tools'

export const DEFAULT = {
  NOTIFICATION_DURATION: 6,
  NOTIFICATION_POSITION: 'bottomLeft',
  AVATAR_SIZE: 70,
  DELETE: 'DELETE',
  REMOVE: 'REMOVE',
  PASSWORD_EXPIRATION_DAYS: 60
}

export const LOCAL_BUILD = process.env.REACT_APP_VERSION || 'npm_local_build'

export const SAGA = {
  DEBOUNCE_MS: 200
}
export const LARGE_INVENTORY_AMOUNT = 50

export const CONTACTS = {
  SALES: 'support@www.ecochain.com.hs-inbox.com'
}

export const DEBUG = {
  SEGMENT: false
}

export const ImpactEffect = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NONE: 'none'
}

export const EntityType = {
  PRODUCT: 'Product',
  LIFECYCLE: 'Lifecycle',
  FOLDER: 'Folder'
}

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'accessToken',
}

export const HUBSPOT_MEETING = {
  SCRIPT_URL: 'static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
  CALENDAR_SCHEDULE_URL: 'https://meetings-eu1.hubspot.com/ecochain-helpdesk?embed=true',
}

const NAV_ROOT = '/'
export const NAV = {
  ACCOUNTSETTINGS: `${NAV_ROOT}accountsettings`,
  FIRSTACCOUNT: `${NAV_ROOT}firstaccount`,
  INVITATION: `${NAV_ROOT}invitation`,
  MODEL: `${NAV_ROOT}`,
  RESETPASSWORD: `${NAV_ROOT}resetpassword`,
  LOGOUT: `${NAV_ROOT}logout`,
  CALLBACK: `${NAV_ROOT}callback`,
  ROOT: NAV_ROOT,
  USERPROFILE: `${NAV_ROOT}profile`,
  ACCOUNT: `${NAV_ROOT}account/`,
  WORKSPACE: `${NAV_ROOT}workspace/`,
  LIFECYCLE: `${NAV_ROOT}lifecycle/`,
  LIFECYCLES: `${NAV_ROOT}lifecycles/`,
  INVENTORY: `${NAV_ROOT}inventory/`,
  TEMPLATES: `${NAV_ROOT}templates/`,
  PRODUCTS: `${NAV_ROOT}products/`,
  OBJECTS: `${NAV_ROOT}objects/`,
  PRODUCT: `${NAV_ROOT}product/`,
  PRODUCT_SCENARIO: `${NAV_ROOT}product-scenario/`,
  PROCESSES: `${NAV_ROOT}processes/`,
  PROCESS: `${NAV_ROOT}process/`,
  AUTHORIZATION_FAILED: `${NAV_ROOT}authorization-failed/`,
  PASSWORD_RESET: `${NAV_ROOT}password-reset/`,
  TEST_ERROR: `${NAV_ROOT}fe-error`,
  HELP_CENTER: `${NAV_ROOT}help-center`,
  CHANGELOG: `${NAV_ROOT}changelog`,
  CSV_T_SHIRT: `${NAV_ROOT}importexamples/t-shirt.csv`,
  CSV_LIST_OBJECTS: `${NAV_ROOT}importexamples/list-objects.csv`,
  CSV_LIST_PRODUCTS: `${NAV_ROOT}importexamples/list-products.csv`,
  CSV_TEMPLATE_DEMO: `${NAV_ROOT}importexamples/template-demo.csv`,
  SIGNUP: `${NAV_ROOT}signup`,
  DASHBOARD: `${NAV_ROOT}dashboard`
}

const NAV_KNOWLEDGE_BASE = 'https://helpcenter.ecochain.com/'
export const NAV_EXTERNAL = {
  KNOWLEDGE_BASE: NAV_KNOWLEDGE_BASE,
  TWO_FACTOR_LINK: `${NAV_KNOWLEDGE_BASE}2-factor-authentication`,
  CHANGELOG: `${NAV_KNOWLEDGE_BASE}mobius-ecodesign-product-footprints#changelog-mobius`,
  HELP_PRODUCT: `${NAV_KNOWLEDGE_BASE}ecodesign/step-1-how-to-model-a-product-in-mobius`,
  HELP_LIFECYCLE: `${NAV_KNOWLEDGE_BASE}step-2-how-to-create-a-products-life-cycle`,
  CARBON_TRANSLATOR: 'https://translator.ecochain.com',
  TALK_TO_SALES: 'https://ecochain.com/company/talk-to-sales/',
  PRICE_COMPARISON: 'https://ecochain.com/mobius/pricing-comparison/'
}

export const POLICY = {
  ACCOUNT_MANAGEMENT: 'account management',
  ACCOUNT_WRITE: 'account write',
  ACCOUNT_READ: 'account read',
  SPACE_MANAGEMENT: 'space management',
  SPACE_WRITE: 'space write',
  SPACE_READ: 'space read',
  BILLING_MANAGEMENT: 'billing management'
}

export const RESOURCE_TYPE = {
  ACCOUNT: 'account',
  WORKSPACE: 'space',
}

export const VALIDATION = {
  ALPHANUMERIC: /^[\w\-\s.]+$/i,
  ACCOUNT_NAME_LENGTH: 3,
  ONE_SPACE: /\s\s+/g,
  PASSWORD_LENGTH: 8,
  PRODUCT_NAME_LENGTH: 2,
  USER_NAME_LENGTH: 2,
  API_CLIENT_NAME_LENGTH: 2,
  WORKSPACE_NAME_LENGTH: 2,
  GENERAL_NAME_LENGTH: 2
}

export const STYLING = {
  COLOR_PRIMARY: '#56ca19',
  COLOR_SECONDARY: '#072b49',
  COLOR_TERTIARY: '#ff6010',
  COLOR_QUARERNARY: '#f22f2c',

  COLOR_OFFSET1: '#eee',
  COLOR_OFFSET2: '#3a3a42',
  COLOR_OFFSET3: '#fff',
  COLOR_OFFSET4: '#f8f8f8',
  COLOR_OFFSET5: '#ccc',
  COLOR_OFFSET6: '#929599',
}

export const APPLICATION_MODE = {
  DEV: 'development',
  PROD: 'production',
  BETA: 'beta',
  ALPHA: 'alpha'
}

export const PRODUCT_TYPE = {
  NORMAL: 'normal',
  WASTE: 'waste',
  PRODUCT: 'product_system',
  ELEMENTARY_FLOW: 'elementary_flow'
}

export const TRACK_EVENT_TYPE = {
  // IDENTIFY EXTRA PROPERTIES
  API_SOURCE: 'Mobius API first_account',

  // EVENTS
  LOGOUT: 'logout',
  FIRST_ACCOUNT: 'first_account',
  CREATED_ACCOUNT: 'created_account',
  CREATED_WORKSPACE: 'created_workspace',
  DELETED_WORKSPACE: 'deleted_workspace',
  CHANGED_IMPACT_SETTINGS: 'changed_impact_settings',
  INVITED_USER: 'invited_user',
  REMOVED_USER: 'removed_users',
  CREATED_PRODUCT: 'created_product',
  CREATED_FOLDER: 'created_folder',
  CREATED_SUBITEM: 'created_subitem',
  ADDED_SUBITEM: 'added_subitem',
  ADDED_DATASET: 'added_dataset',
  CREATED_PRODUCT_SCENARIO: 'created_product_scenario',
  CREATED_LIFECYCLE_SCENARIO: 'created_lifecycle_scenario',
  OPENED_PRODUCT_SCENARIO: 'opened_product_scenario',
  OPENED_COMPARISON: 'opened_comparison',
  CREATED_LIFECYCLE: 'create_lifecycle',
  CREATED_ROOT_PROCESS: 'create_root_process',
  OPENED_ROOT_PROCESS: 'opened_root_process',
  CREATED_SUB_PROCESS: 'created_sub_process',
  CREATED_INPUT_PRODUCT: 'created_input_product',
  CREATED_OUTPUT_PRODUCT: 'created_output_product',
  VIEWED_PRODUCT: 'viewed_product',
  VIEWED_LIFECYCLE: 'viewed_lifecycle',
  ADDED_PAYMENT: 'added_payment',
  SUBSCRIPTION_PURCHASED: 'subscription_purchased',
  SUBSCRIPTION_CANCELED: 'subscription_canceled',
  SUBSCRIPTION_CHANGED: 'subscription_changed',
  VIEWED_HELP_CENTER: 'viewed_help_center',
  VIEWED_CHANGELOG: 'viewed_changelog',
  COPIED_LIFECYCLE: 'copied_lifecycle',
  DOWNLOADED_PDF_RESULTS: 'downloaded_pdf_results',

  CREATED_PRODUCT_FOLDER: 'created_product_folder',
  CREATED_OBJECT_FOLDER: 'created_object_folder',
  MOVED_PRODUCT_TO_FOLDER: 'moved_product_to_folder',
  MOVED_OBJECT_TO_FOLDER: 'moved_object_to_folder',
  MOVED_OBJECT_TO_PRODUCT: 'moved_object_to_product',
  MOVED_PRODUCT_TO_OBJECT: 'moved_product_to_object',

  // EVENT VIEWS
  VIEWED_TREEVIEW: 'viewed_treeview',
  VIEWED_FLATVIEW: 'viewed_flatview',
  VIEWED_BARCHART: 'viewed_barchart',
  VIEWED_SUNBURST: 'viewed_sunburst',
  VIEWED_REFERENCE_SEARCH: 'viewed_reference_search',
  VIEWED_ELEMENTARY_FLOWS_SEARCH: 'viewed_elementary_flows_search',
  VIEWED_CUSTOM_IMPACT_FORM: 'viewed_custom_impact_form',

  // PAGE VIEWS
  VIEWED_WORKSPACE: 'viewed_workspace',
  VIEWED_PRODUCTS_OVERVIEW: 'viewed_products_overview',
  VIEWED_OBJECTS_OVERVIEW: 'viewed_objects_overview',
  VIEWED_PROCESSES_OVERVIEW: 'viewed_processes_overview',
  VIEWED_USERS_SETTINGS: 'viewed_users_settings',
  VIEWED_ROLES_SETTINGS: 'viewed_roles_settings',
  VIEWED_PERMISSIONS_SETTINGS: 'viewed_permissions_settings',
  VIEWED_API_CLIENTS_SETTINGS: 'viewed_api_clients_settings',
  VIEWED_BILLING_SETTINGS: 'viewed_billing_settings',
  VIEWED_USER_PROFILE: 'viewed_user_profile',
  VIEWED_ACCOUNT_OVERVIEW: 'viewed_account_overview',
  VIEWED_LIFECYCLE_OVERVIEW: 'viewed_lifecycle_overview',
  VIEWED_PRODUCT_OVERVIEW: 'viewed_product_overview',
  VIEWED_WORKSPACE_WELCOME: 'viewed_workspace_welcome',
}

export const NWSET_TYPE = {
  NONE: null,
  NORMALIZED: 0,
  WEIGHTED: 1,
  NORM_WEIGHT: 2
}

export const PAGINATION = {
  PAGE_SIZE: 20
}

// Source: https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export const SUBSCRIPTION_STATUS = {
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
  UNPAID: 'unpaid',
}

export const ACCOUNT_ROLES = {
  ADMIN: 'Account admin'
}

export const USAGE_TYPE = {
  ECOINVENT: 'ecoinvent'
}

// Source: https://stripe.com/docs/billing/invoices/workflow#workflow-overview
export const INVOICE_STATUS = {
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void'
}
export const CUSTOMER_TYPE = {
  PRIVATE: 'private',
  BUSINESS: 'business',
}

export const JOB_FUNCTIONS = [
  'consultant',
  'student',
  'product_design',
  'c_level',
  'csr_sustainability',
  'engineering_maintenance',
  'finance_reporting',
  'process_management_operations',
  'procurement_supply_chain_management',
  'quality_assurance_qhse',
  'rd_product_development',
  'sales_marketing',
  'other'
]

export const COMPANY_TYPES = [
  'manufacturer',
  'brand',
  'consultant',
  'student',
  'service_provider',
  'other'
]

export const IMPACT_CALCULATION_STATUS = {
  OK: 'ok',
  PENDING: 'pending'
}

export const TIME = {
  ONE_MINUTE: 1000 * 60,
  FIVE_MINUTES: 1000 * 60 * 5,
  TEN_MINUTES: 1000 * 60 * 10,
  ONE_HOUR: 1000 * 60 * 60,
}

export const D3 = {
  KEY: 0,
  VALUES: 1
}

export const CATEGORIES = [
  'EndProduct',
  'InputProduct',
  'IntermediateProduct',
  'Waste'
]

export const REFERENCE_CATEGORY_TYPES = {
  PROCESS: 'process',
  ELEMENTARY_FLOW: 'flow'
}

export const DEFAULT_DEMO_PRODUCT_TYPE = [
  'EndProduct'
]

export const GET_DEFAULT_DEMO_SPACE_NAME = () => {
  const environment = getEnvironmentFromUrl()
  return environment === 'development' ? 'Dev demo' : 'T-shirt Demo'
}

export const DEV_HOST_NAME_LIST = [ 'localhost', 'abfapp_dev_server', 'abfapp_front_end' ]

export const LAZY_INVENTORY = {
  MAX_NUMBER_OF_ITEMS_TO_DISPLAY: 200,
  AMOUNT_OF_ITEMS_TO_LOAD_PER_STEP: 100,
  SCROLL_LISTENER_DEBOUNCE_DURATION: 350
}

export const ECOCHAIN_REQUEST_DEMO_URL = 'https://ecochain.com/demo/'

export const VIEW_TYPES = {
  CARD: 'card',
  LIST: 'list'
}

export const COMPONENT_TYPES = {
  PRODUCT: 'product',
  PROCESS: 'process',
  LIFECYCLE: 'lifecycle',
  TEMPLATE: 'template'
}

export const PRODUCT_LABEL = {
  PRODUCT: 'product',
  OBJECT: 'object'
}

export const SORT_ORDER = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
}

export const EXPORT_PRODUCT_CSV_HEADERS = [
  { queryKey: 'level', translationKey: 'level' },
  { queryKey: 'name', translationKey: 'name' },
  { queryKey: 'description', translationKey: 'description' },
  { queryKey: 'amount', translationKey: 'amount' },
  { queryKey: 'unit', translationKey: 'unit' },
  { queryKey: 'label', translationKey: 'label' },
  { queryKey: 'datasetName', translationKey: 'dataset_name' },
  { queryKey: 'datasetID', translationKey: 'dataset_id' },
  { queryKey: 'type', translationKey: 'type' },
  { queryKey: 'area', translationKey: 'area' },
  { queryKey: 'areaTime', translationKey: 'area_time' },
  { queryKey: 'bioticProdOcc', translationKey: 'biotic_prod_occ' },
  { queryKey: 'bioticProdTransf', translationKey: 'biotic_prod_transf' },
  { queryKey: 'duration', translationKey: 'duration' },
  { queryKey: 'energy', translationKey: 'energy' },
  { queryKey: 'energyAreaTime', translationKey: 'energy_area_time' },
  { queryKey: 'energyMassTime', translationKey: 'energy_mass_time' },
  { queryKey: 'erosionOcc', translationKey: 'erosion_occ' },
  { queryKey: 'erosionTransf', translationKey: 'erosion_transf' },
  { queryKey: 'transport', translationKey: 'transport' },
  { queryKey: 'grossCalorific', translationKey: 'gross_calorific' },
  { queryKey: 'groundwaterOcc', translationKey: 'groundwater_occ' },
  { queryKey: 'groundwaterTransf', translationKey: 'groundwater_transf' },
  { queryKey: 'guestNight', translationKey: 'guest_night' },
  { queryKey: 'itemsLength', translationKey: 'items_length' },
  { queryKey: 'length', translationKey: 'length' },
  { queryKey: 'lengthTime', translationKey: 'length_time' },
  { queryKey: 'marketValue', translationKey: 'market_value' },
  { queryKey: 'mass', translationKey: 'mass' },
  { queryKey: 'massTime', translationKey: 'mass_time' },
  { queryKey: 'mechFiltrationOcc', translationKey: 'mech_filtration_occ' },
  { queryKey: 'mechFiltrationTransf', translationKey: 'mech_filtration_transf' },
  { queryKey: 'netCalorific', translationKey: 'net_calorific' },
  { queryKey: 'normalVolume', translationKey: 'normal_volume' },
  { queryKey: 'numItems', translationKey: 'num_items' },
  { queryKey: 'personTransport', translationKey: 'person_transport' },
  { queryKey: 'physFiltrationOcc', translationKey: 'phys_filtration_occ' },
  { queryKey: 'physFiltrationTransf', translationKey: 'phys_filtration_transf' },
  { queryKey: 'radioactivity', translationKey: 'radioactivity' },
  { queryKey: 'vehiculeTransport', translationKey: 'vehicule_transport' },
  { queryKey: 'volume', translationKey: 'volume' },
  { queryKey: 'volumeLength', translationKey: 'volume_length' },
  { queryKey: 'volumeTime', translationKey: 'volume_time' },
]

export const TIME_FORMATS = {
  DDMMYY: 'DD-MM-YY'
}

export const DEFAULT_TAG_COLOR = '#002AFF'
export const DEFAULT_TRANSPARENCY_LVL = '1A'

export const PHASE_COLORS_CONFIG = {
  alphaMax: 0.6,
  alphaMin: 0.3,
  colorSetLength: 19
}

export const PHASE_COLORS = [
  '86, 202, 25', // green
  '255, 96, 16', // orange
  '255, 191, 0', // dark yellow
  '242, 47, 44', // red
  '7, 43, 73', // blue whale
  '153, 102, 204', // purple
  '251, 206, 177', // light orange
  '127, 255, 212', // light green
  '229, 43, 80', // pink/red
  '0, 116, 217', // blue
  '127, 219, 255', // aqua
  '57, 204, 204', // teal
  '61, 153, 112', // Olive
  '1, 255, 112', // lime
  '255, 220, 0', // yellow
  '255, 65, 54', // orange/red
  '133, 20, 75', // brown
  '240, 18, 190', // fuschia
  '21, 13, 186', // Dark blue
]
export const PHASE_COLORS_REVERSED = [
  '21, 13, 186', // Dark blue
  '240, 18, 190', // fuschia
  '133, 20, 75', // brown
  '255, 65, 54', // orange/red
  '255, 220, 0', // yellow
  '1, 255, 112', // lime
  '61, 153, 112', // Olive
  '57, 204, 204', // teal
  '127, 219, 255', // aqua
  '0, 116, 217', // blue
  '229, 43, 80', // pink/red
  '127, 255, 212', // light green
  '251, 206, 177', // light orange
  '153, 102, 204', // purple
  '7, 43, 73', // blue whale
  '242, 47, 44', // red
  '255, 191, 0', // dark yellow
  '255, 96, 16', // orange
  '86, 202, 25', // green
]

export const SUNBURST = {
  FULL_SIZE: 500,
  MIN_SIZE: 350
}

export const NUMBER_FORMAT = {
  SCIENTIFIC_NOTATION_THRESHOLD: 3,
  PRECISION: 2
}

export const PRODUCT_IMPORT_EXPORT = {
  IMPORT: 'import',
  DOWNLOAD_T_SHIRT: 't-shirt',
  DOWNLOAD_TEMPLATE: 'template',
  DOWNLOAD_PRODUCTS: 'products',
  DOWNLOAD_OBJECTS: 'objects',
}

export const SUGGESTION_LIMIT = {
  NUMBER_OF_WORDS: 4,
  MAX_LAST_WORD_LENGTH: 6
}
