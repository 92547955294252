import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { withRouter } from '../../../../utils/with-router'
import { Row, Col, Button, Dropdown, Menu, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { handleModal } from '../../../../utils/tools'
import { IInventoryTreeItemActionsProps } from './inventoryTreeItemActions.interface'
import { TreeDotsIcon } from '../../../shared/customIcons'
import { useUrlQuery } from '../../../../v1/shared/hook/use-url-query'
import { isValid } from '../../../../v1/shared/util/tools'
import { IDashboardContext } from '../../../../v1/shared/interface/workspace-context-type'
import DashboardContext from '../../../../v1/dashboard/context/dashboard.context'

const InventoryTreeItemActions:React.FC<IInventoryTreeItemActionsProps> = props => {
  const {
    nodeKey,
    isRoot,
    name,
    lifecycleId,
    selectedLifecycleName,
    isShowProductScenarioInventory,
    selectedInventoryItemForCreateProductSaga,
    selectedInventoryItemForRemoveProductSaga,
    updateLifecyclesItemsSaga,
    updateInventoryItemsSaga,
    selectInventoryRootItemSaga,
    t,
    productId,
    selectedPhase,
    selectedSpaceSlug,
    accountId
  } = props

  const navigate = useNavigate()
  const urlQuery = useUrlQuery()
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  // const productScenarioId = urlQuery.get('productScenarioId')
  const lifecycleScenarioId = urlQuery.get('lifecycleScenarioId')

  const handleCreateNewInventoryItem = (evt:any) => {
    evt.preventDefault()
    evt.stopPropagation()
    selectedInventoryItemForCreateProductSaga({ key: nodeKey, phase: selectedPhase })
  }

  const handleRemoveInventoryItem = (params:any) => {
    params.domEvent.stopPropagation()
    const modalArgs = {
      title: t('model.remove_inventory_item', { name }),
      content: t('model.remove_inventory_item_confirm', { name }),
      onOk: () => {
        selectedInventoryItemForRemoveProductSaga({
          key: nodeKey,
          cb: () => {
            if (lifecycleId) {
              updateLifecyclesItemsSaga()
              return
            }
            updateInventoryItemsSaga()
            !isShowProductScenarioInventory && selectInventoryRootItemSaga()
          }
        })
      },
      modalType: null,
      onCancel: () => {},
      cancelText: null,
      okText: null,
      className: null,
      width: null
    }
    handleModal(modalArgs)
  }

  const getPrevState = () => {
    if ( lifecycleId && !isValid(lifecycleScenarioId) && selectedLifecycleName ) {
      return selectedLifecycleName
    }

    return t('model.scenario')
  }

  const handleOpenItem = () => {
    const prevState = getPrevState()
    addToBackToList(prevState)
    navigate(`/account/${accountId}/workspace/${selectedSpaceSlug}/product/${productId}/inventory/`)
  }

  const menu = (
    <Menu>
      <Menu.Item
        className="menu-item"
        key="remove_item"
        onClick={(params:any) => handleRemoveInventoryItem(params)}
        data-cy="remove-item-action"
      >
        {t('global.remove')}
      </Menu.Item>
      <Menu.Item
        className="menu-item"
        key="open_item"
        onClick={handleOpenItem}
        data-cy="open-item-action"
      >
        {t('model.openItem')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Row data-cy="actions" className="inventory-item__actionsWrapper">
      <Col>
        <Tooltip
          placement="top"
          title={t('global.add_new_item')}
          mouseEnterDelay={1}
        >
          <Button
            data-cy="add-subitem-action"
            size="small"
            icon={<PlusOutlined />}
            onClick={ evt => handleCreateNewInventoryItem(evt) }
          />
        </Tooltip>
      </Col>
      <Col onClick={evt => evt.stopPropagation()}>
        { !isRoot || lifecycleId ?
          <Dropdown.Button
            className="inventory-item__actionsMenu"
            overlayClassName="menu-item-dropdown"
            size="small"
            type="text"
            overlay={menu}
            getPopupContainer={node => node.parentElement as HTMLElement}
            data-cy="dropdown-action"
            icon={<TreeDotsIcon />}
          />
          : null
        }
      </Col>
    </Row>
  )
}

export { InventoryTreeItemActions }
export default compose(
  withRouter,
  withTranslation(),
)(InventoryTreeItemActions)
