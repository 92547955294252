import React from 'react'
import { normalize } from '../../../../utils/tools'
import { Tooltip } from 'antd'

const MAX_BAR_WIDTH = 160

const impactRatio = (impactAmount, maxImpactAmount) => {
  const fImpactAmount = parseFloat(impactAmount)
  const fMaxImpactAmount = parseFloat(maxImpactAmount)
  if (fMaxImpactAmount === 0 || Number.isNaN(fMaxImpactAmount)) return 0
  if (Number.isNaN(fImpactAmount)) return 0
  return fImpactAmount / fMaxImpactAmount
}

export default function FixedImpactBar (props) {
  const { impactAmount, maxImpactAmount, name } = props
  const ratio = impactRatio(impactAmount, maxImpactAmount)
  const barClassName = ratio > 0 ? 'negativeBarInTree' : 'positiveBarInTree'
  const absRatio = Math.abs(ratio)
  const dataCyImpact = 'item-bar-for-' + normalize(name)

  const w = `${ absRatio * MAX_BAR_WIDTH }px`
  const ratioPercent = `${ (absRatio * 100).toFixed(2) }%`
  return (
    <div className="impact-bar-container">
      <Tooltip placement="top" title={ ratioPercent } >
        <div data-cy={ dataCyImpact } data-cy-ratio={ ratio } className={ barClassName } style={{ width: w }}/>
      </Tooltip>
    </div>
  )
}
