import React from 'react'
import { useTranslation } from 'react-i18next'

import { ISpace } from '../../model/space'

type CustomImpactInfoComponentProps = {
  space: ISpace
}

export const CustomImpactInfoComponent = ({ space }: CustomImpactInfoComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const { impactMethod, excludeLT } = space

  return (
    <>
      <div className="pr-4 pb-4">
        {t('labels.impact_method')}
        :
        <b>{ impactMethod?.name }</b>
      </div>
      <div className="pr-4 pb-4">
        {t('labels.exclude_ltImpacts')}
        :
        <b>{ excludeLT ? t('labels.yes') : t('labels.no')}</b>
      </div>
    </>
  )
}
