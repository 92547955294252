import React, { useRef, useContext, useEffect } from 'react'
import { Toast } from 'primereact/toast'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../context/dashboard.context'

export const NotificationComponent = () => {
  const notifyRef = useRef<Toast>(null)
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    if (notifyRef.current) {
      updateDashboard({ notifyToastPrime: notifyRef.current })
    }
  }, [ notifyRef ])

  return (
    <>
      <Toast data-testid="notification-toast-message" ref={notifyRef} position="top-right" />
    </>
  )
}
