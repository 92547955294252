import { useCallback, useEffect, useRef } from 'react'

export const useIsMounted = () => {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    return () => void (isMountedRef.current = false)
  }, [])

  return isMounted
}
