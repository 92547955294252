import { gql } from '@apollo/client'
import referenceProduct from './referenceProduct'

export default gql`
  fragment extendedProductFragment on Product {
    id
    name
    unit
    type
    description
    favorite
    createdAt
    updatedAt
    tags {
      id
      name
      color
    }
    referenceProduct {
      ...referenceProduct
    }
    productProperties {
      referenceProperty {
        id
        name
      }
      conversionFactor
      referenceUnit {
        id
        name
      }
    }
    labels{
      id
    }
    folder{
      id
      name
    }
  }
  ${referenceProduct}
`
