import React, {
  useContext, useEffect, useState, useRef, useCallback
} from 'react'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'
import { transformToDonutChartData } from '../../../../shared/util/transform-lifecycle'
import { TAnalysisContext } from '../../../interface/analysis.context'
import AnalysisContext from '../../../provider/context/analysis.context'
import { TDonutPhase } from '../../../../lifecycle/interface/donut-chart.type'
import { ComparisonEntity } from '../../../enum/entity'
import { DonutLegendComponent } from '../../../../lifecycle/component/chart/donut-legend.component'
import { DonutChartComponent } from '../../../../lifecycle/component/chart/donut-chart.component'
import { IImpact } from '../../../../model'

const DoughnutViewWrapper = styled.div`
  min-height: 25rem;
`

type TDonutPanelComponentProps = {
  entity?: ComparisonEntity
}
export const DonutPanelComponent = ({
  entity = ComparisonEntity.Primary
}: TDonutPanelComponentProps) => {
  const isPrimary = entity === ComparisonEntity.Primary
  const containerRef = useRef<HTMLDivElement>(null)
  const {
    primaryLifecycle,
    secondaryLifecycle,
    primaryTotalImpact,
    secondaryTotalImpact
  } = useContext<TAnalysisContext>(AnalysisContext)
  const [ phasesDonutData, setPhasesDonutData ] = useState<TDonutPhase[]>([]);
  const [ lifecycleTotalImpact, setLifecycleTotalImpact ] = useState<IImpact | undefined>();
  const [ donutWidthAndHeight, setDonutWidthAndHeight ] = useState<number>(500);

  const lifecycle = isPrimary ? primaryLifecycle : secondaryLifecycle
  const chartRef = useCallback(node => {
    if (node !== null) {
      setDonutWidthAndHeight(node.getBoundingClientRect().width)
    }
  }, [])

  useEffect(
    () => {
      const lifecycleTotalImpact = isPrimary ? primaryTotalImpact : secondaryTotalImpact
      if (lifecycle) {
        const donutChartData = transformToDonutChartData(lifecycle?.phases)
        setLifecycleTotalImpact(lifecycleTotalImpact)
        setPhasesDonutData(donutChartData)
      }
    },
    [ primaryLifecycle, secondaryLifecycle ]
  )

  return (
    <DoughnutViewWrapper
      data-testid="doughnut-view-wrapper"
      ref={containerRef}
      className="flex flex-column w-full h-full bar-view-wrapper"
    >
      <div className={classNames('flex w-full', { 'justify-content-end': isPrimary, 'justify-content-start': !isPrimary })}>
        <div ref={chartRef} className="md:w-9 lg:w-8 text-center text-xl">
          { lifecycle?.name }
        </div>
      </div>
      <div className={classNames('flex w-full', { 'justify-content-end': isPrimary, 'justify-content-start': !isPrimary })}>
        <div ref={chartRef} className="md:w-9 lg:w-8">
          <DonutChartComponent phasesDonutData={phasesDonutData} donutWidthAndHeight={donutWidthAndHeight} lifecycleTotalImpact={lifecycleTotalImpact} />
        </div>
      </div>
      <div className={classNames('flex w-full', { 'justify-content-end': isPrimary, 'justify-content-start': !isPrimary })}>
        <div className="md:w-9 lg:w-8 h-20rem">
          <DonutLegendComponent phasesDonutData={phasesDonutData} />
        </div>
      </div>
    </DoughnutViewWrapper>
  )
}
