import { connect } from 'react-redux'
import { updateBillingCustomerSaga } from '../../../redux/sagas/stripe.saga'
import AccountSelector from '../../../redux/selectors/account.selector'
import Customer from './customer'

const mapStateToProps = state => ({
  selectedAccountId: AccountSelector.selectedAccountId(state),
  selectedAccountCustomer: AccountSelector.selectedAccountCustomer(state),
})

const mapDispatchToProps = {
  updateBillingCustomerSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer)
