import { connect } from 'react-redux'
import {
  changeSubscriptionProductSaga,
  startCheckoutSubscriptionSaga,
  updateBillingCustomerSaga
} from '../../../redux/sagas/stripe.saga'
import AccountSelector from '../../../redux/selectors/account.selector'
import GlobalSelector from '../../../redux/selectors/global.selector'
import SubscriptionChoice from './subscriptionChoice'

const mapStateToProps = state => ({
  selectedAccountId: AccountSelector.selectedAccountId(state),
  selectedAccountName: AccountSelector.selectedAccountName(state),
  selectedAccount: AccountSelector.selectedAccount(state),
  selectedAccountSubscription: AccountSelector.selectedAccountSubscription(state),
  subscriptionProducts: GlobalSelector.subscriptionProducts(state)
})

const mapDispatchToProps = {
  startCheckoutSubscriptionSaga,
  changeSubscriptionProductSaga,
  updateBillingCustomerSaga,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionChoice)
