import React from 'react'

import { useTranslation } from 'react-i18next'
import { TTransformMultiLeafItem } from '../../../../shared/util/transform'
import { StackedChartLegendItemComponent } from './stacked-chart-legend-item.component'

type StackedChartLegendLayoutProps = {
  maxChartItems?: number
  chartItems?: TTransformMultiLeafItem[]
}
export const StackedChartLegendLayout = ({
  maxChartItems = 8,
  chartItems = []
}: StackedChartLegendLayoutProps) => {
  const { t } = useTranslation([ 'analysis' ])
  const colorClassSet = [
    'bg-primary-900', 'bg-primary-800', 'bg-primary-700',
    'bg-primary-600', 'bg-primary-500', 'bg-primary-400',
    'bg-primary-300', 'bg-primary-200', 'bg-primary-100'
  ]

  const commonItems = chartItems.filter(item => item.commonItem)
  const isNegativeItemExist = (item: TTransformMultiLeafItem) => (
    (parseFloat(item.primaryLeafItem?.impactAmount || '0') < 0)
    || (parseFloat(item.secondaryLeafItem?.impactAmount || '0') < 0)
  )

  const positiveCommonItems = commonItems.filter((item : TTransformMultiLeafItem) => item.commonItem && !isNegativeItemExist(item))

  const negativeCommonItems = commonItems.filter((item : TTransformMultiLeafItem) => item.commonItem && isNegativeItemExist(item))

  const itemsOnlyInSingleProduct = chartItems.filter((item : TTransformMultiLeafItem) => !item.commonItem && !isNegativeItemExist(item))

  const negativeItemsOnlyInSingleProduct = chartItems.filter((item : TTransformMultiLeafItem) => !item.commonItem && isNegativeItemExist(item))

  return (
    <>
      { commonItems.length < maxChartItems && positiveCommonItems.map((item: TTransformMultiLeafItem, index: number) => (
        <div
          key={`legend-stacked-${index}`}
          data-testid={`stacked-legend-${index}`}
          className="flex align-items-center justify-content-end w-full gap-2"
        >
          <StackedChartLegendItemComponent
            index={index}
            name={item.name}
            colorClassName={`${colorClassSet[index]}`}
          />
        </div>
      ))}

      { commonItems.length >= maxChartItems && (
        <div
          data-testid="stacked-legend-common-positive"
          className="flex align-items-center justify-content-end w-full gap-2"
        >
          <StackedChartLegendItemComponent
            index="common-positive"
            name={t('labels.itemsCommon', { context: 'positive' })}
            colorClassName="bg-primary-500"
          />
        </div>
      )}

      { itemsOnlyInSingleProduct.length > 0 && (
        <div
          data-testid="stacked-legend-not-common-positive"
          className="flex align-items-center justify-content-end w-full gap-2"
        >
          <StackedChartLegendItemComponent
            index="not-common-positive"
            name={t('labels.itemsNotCommon', { context: 'positive' })}
            colorClassName="bg-primary-100"
          />
        </div>
      )}

      { negativeCommonItems.length > 0 && (
        <div
          data-testid="stacked-legend-common-negative"
          className="flex align-items-center justify-content-end w-full gap-2"
        >
          <StackedChartLegendItemComponent
            index="common-negative"
            name={t('labels.itemsCommon', { context: 'negative' })}
            colorClassName="bg-green-300"
          />
        </div>
      )}

      { negativeItemsOnlyInSingleProduct.length > 0 && (
        <div
          data-testid="stacked-legend-not-common-negative"
          className="flex align-items-center justify-content-end w-full gap-2"
        >
          <StackedChartLegendItemComponent
            index="not-common-negative"
            name={t('labels.itemsNotCommon', { context: 'negative' })}
            colorClassName="bg-green-100"
          />
        </div>
      )}
    </>
  )
}

