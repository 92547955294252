import React, { useContext, useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { isValid } from '../../shared/util/tools'
import ProductDetailContext from '../provider/context/product-detail.context'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import { INVENTORY_ROOT_PRODUCT } from '../../graphql/query'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'

type TProductDetailProps = {
  children?: React.ReactNode
  productId?: string
}
export const ProductDetailContainer = ({
  children,
  productId = ''
}: TProductDetailProps) => {
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { updateProductDetail } = useContext<TProductDetailContext>(ProductDetailContext)

  const {
    loading: loadingInventory,
    data: inventoryData,
    refetch: refetchInventory
  } = useQuery(INVENTORY_ROOT_PRODUCT, {
    skip: !isValid(space) || !productId,
    variables: { productID: productId },
    fetchPolicy: 'no-cache'
  })

  const { inventory: productInventory = null } = inventoryData || {}

  useEffect(() => {
    if (!loadingInventory && productInventory) {
      updateProductDetail({ productInventory, loadingInventory, refetchInventory })
    }
  }, [ loadingInventory, productInventory, refetchInventory ])

  return (
    <>
      { loadingInventory && <ProgressSpinnerComponent dataTestId="loading-product-detail" size={2} /> }

      { !loadingInventory && children }

    </>
  )
}
