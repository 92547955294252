import { Col, Row } from 'antd'
import React from 'react'
import classnames from 'classnames'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { isEmpty, returnNested, formatLifecycleName, normalize } from '../../../utils/tools'
import LoadingWrapper from '../../shared/loadingWrapper'
import InventoryTotalImpact from '../inventory/inventory-total-impact/inventoryTotalImpact.container'
import LifecycleTreeView from './lifecycleTreeView.container'
import InventoryNameLabel from '../inventory/inventory-name-label/inventoryNameLabel'

class LifecycleTreeWrapper extends React.PureComponent {

  onLifecycleNameClick = lifecycleId => {
    this.props.setLifecycleDetailsPanelSaga(lifecycleId)
  }

  render() {
    const {
      lifecycle,
      lifecycleScenario,
      isShowLifecycleScenarioInventory,
      isInventoryCompare,
      entityToCompare,
      isLoadingLeftTree,
      isLoadingRightTree,
      detailsPanelLifecycleId,
      t
    } = this.props
    const shouldShowLifecycleScenario = isShowLifecycleScenarioInventory && !isEmpty(lifecycleScenario)
    const shouldShowLifecycleComparison = !shouldShowLifecycleScenario && isInventoryCompare
    const isShowingSecondTree = shouldShowLifecycleScenario || isInventoryCompare
    const rightTreeImpact= shouldShowLifecycleScenario ? returnNested(lifecycleScenario, 'inventory') : isInventoryCompare && entityToCompare
    const flexGridClass = (isShowLifecycleScenarioInventory || isInventoryCompare) ? 'double-tree' : 'single-tree'
    const rightTree = lifecycleScenario || entityToCompare

    const SingleTree = () => <Col className={flexGridClass} xl={isShowingSecondTree ? 12 : 24}>
      {lifecycle && <LoadingWrapper loading={ isLoadingLeftTree }>
        { isShowingSecondTree && lifecycle && <InventoryNameLabel
          onClick={() => this.onLifecycleNameClick(lifecycle.id)}
          data-cy={`lifecycle-named-${normalize(lifecycle.name)}-title`}
          tag={shouldShowLifecycleScenario && t('model.labelOriginal')}
          text={formatLifecycleName(lifecycle.name, lifecycle.amount, lifecycle.unit, t('global.of'))}
          className={ classnames('lifecycle-scenario-title', { active: detailsPanelLifecycleId === lifecycle.id }) }
        />
        }
        <LifecycleTreeView
          lifecycle={lifecycle}
          isReadOnly={isShowingSecondTree}
          areImpactsBarsShown={!isShowingSecondTree}
        />
      </LoadingWrapper>
      }
    </Col>

    const ScenarioTree = () => <>
      {rightTree && <Col className={flexGridClass} xl={12}>
        <LoadingWrapper loading={ isLoadingRightTree }>
          <InventoryNameLabel
            onClick={() => this.onLifecycleNameClick(rightTree.id)}
            data-cy={`lifecycle-named-${normalize(rightTree.name)}-title`}
            tag={shouldShowLifecycleScenario && t('model.labelScenario')}
            isPrimaryTag={true}
            text={formatLifecycleName(rightTree.name, rightTree.amount, rightTree.unit, t('global.of'))}
            className={ classnames('lifecycle-scenario-title', { active: detailsPanelLifecycleId === rightTree.id }) }
          />
          <LifecycleTreeView
            isLeftTree={true}
            lifecycle={lifecycleScenario}
            isReadOnly={false}
            areImpactsBarsShown={false}
          />
        </LoadingWrapper>
      </Col>}
    </>

    const ComparisonTree = () => <>
      {rightTree && <Col className={flexGridClass} xl={12}>
        <LoadingWrapper loading={ isLoadingRightTree }>
          <InventoryNameLabel
            onClick={() => this.onLifecycleNameClick(rightTree.id)}
            data-cy={`lifecycle-named-${normalize(rightTree.name)}-title`}
            tag={shouldShowLifecycleScenario && t('model.labelScenario')}
            isPrimaryTag={true}
            text={formatLifecycleName(rightTree.name, rightTree.amount, rightTree.unit, t('global.of'))}
            className={ classnames('lifecycle-scenario-title', { active: detailsPanelLifecycleId === rightTree.id }) }
          />
          <LifecycleTreeView
            lifecycle={entityToCompare}
            isReadOnly={true}
            areImpactsBarsShown={false}
          />
        </LoadingWrapper>
      </Col>}
    </>

    const SingleTreeTotal = () => <Col className={flexGridClass} data-cy="lifecycle-total-impact">
      <InventoryTotalImpact
        impact={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        impactToCompare={ returnNested(rightTreeImpact, 'totalImpact') }
        isCarbonTranslatorShowed={!isShowingSecondTree}
      />
    </Col>

    const ScenarioTreeTotal = () => <Col className={flexGridClass} data-cy="lifecycle-scenario-total-impact">
      <InventoryTotalImpact
        impact={ returnNested(rightTreeImpact, 'totalImpact') }
        impactToCompare={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        isCarbonTranslatorShowed={false}
      />
    </Col>

    const ComparisonTreeTotal = () => <Col className={flexGridClass} data-cy="lifecycle-comparison-total-impact">
      <InventoryTotalImpact
        impact={ returnNested(rightTreeImpact, 'totalImpact') }
        impactToCompare={ returnNested(lifecycle, 'inventory', 'totalImpact') }
        isCarbonTranslatorShowed={false}
      />
    </Col>

    return <>
      <Row gutter={16} type="flex" onClick={evt => evt.stopPropagation()}>
        { shouldShowLifecycleScenario && <ScenarioTree /> }
        <SingleTree />
        { shouldShowLifecycleComparison && <ComparisonTree /> }
      </Row>
      <Row gutter={16} type="flex">
        { shouldShowLifecycleScenario && <ScenarioTreeTotal /> }
        <SingleTreeTotal />
        { shouldShowLifecycleComparison && <ComparisonTreeTotal /> }
      </Row>
    </>
  }
}

export default compose(
  withTranslation()
)(LifecycleTreeWrapper)
