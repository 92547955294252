import React from 'react'
import { classNames } from 'primereact/utils'

export type SplitScreenLayoutProps = {
  children: React.ReactNode[],
  firstClassName?: string,
  secondClassName?: string,
  className?: string
}

export const SplitScreenLayout = ({
  children = [],
  firstClassName,
  secondClassName,
  className = ''
}: SplitScreenLayoutProps) => {
  const [ FirstComponent, SecondComponent ] = children
  return (
    <div data-testid="split-screen-wrapper" className={classNames(`flex w-full h-full ${className}`)}>
      <div data-testid="split-screen-first" className={firstClassName}>
        { FirstComponent }
      </div>
      <div data-testid="split-screen-second" className={secondClassName}>
        { SecondComponent }
      </div>
    </div>
  )
}
