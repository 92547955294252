import React, { useContext } from 'react'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

import DetailsPanel from '../../../../component/model/details-panel/detailsPanel.container'
import ProductDetails from '../../../../component/model/details-panel/product-details/productDetails.container'
import { MenuAction } from '../../enum/menu-action'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import { store } from '../../../../configureStore'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'

type TProductDialogComponentProps = {
  reloadInventoryItems: () => void
}
export const ProductDialogComponent = ({
  reloadInventoryItems
}: TProductDialogComponentProps) => {
  const {
    selectedAction,
    updateProductDetail
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const isAddedTreeItemAction = () => selectedAction === MenuAction.AddTreeItem

  const afterUpdateProductDetail = (callbackProps: any) => {
    // TODO : From v0.9 : To be refactored
    if (isAddedTreeItemAction() && callbackProps?.isClosedAfterAddItem) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      updateProductDetail({ selectedAction: null, selectedProduct: null, selectedInventoryAmountV09: null })
    }

    reloadInventoryItems()
    isAddedTreeItemAction() && trackEventInSegment(TrackEventType.CREATED_SUBITEM)
  }

  return (
    <>
      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <ProductDetails isEnabledForV1 updatePageItems={afterUpdateProductDetail} />
      </DetailsPanel>
    </>
  )
}
