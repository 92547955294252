import { Button, Modal } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import compose from '../../../utils/compose'
import { IModalWindowProps } from './modalWindow.interface'

const ModalWindow:React.FC<IModalWindowProps> = props => {
  const {
    isDataViaNetworkRequesting,
    cancelHandler,
    okHandler,
    okText,
    cancelText,
    isShowModal,
    title,
    className,
    forceRender,
    maskClosable,
    destroyOnClose,
    closable,
    width,
    content,
    buttonSize,
    okType,
    cancelType,
    noFooter
  } = props

  const getFooter = () => {
    if (noFooter || (!cancelHandler && !okHandler)) return null
    return <React.Fragment>
      {
        cancelHandler &&
        <Button onClick={ cancelHandler }
          data-cy="cancel-modal"
          className="button"
          size={buttonSize || 'middle'}
          loading={isDataViaNetworkRequesting}
          type={cancelType || 'default'}
        >{ cancelText }</Button>
      }

      {
        okHandler
          ? <Button
            onClick={ okHandler }
            data-cy="confirm-modal"
            loading={isDataViaNetworkRequesting}
            size={buttonSize || 'middle'}
            type={okType || 'primary'}
            className="button primary">{ okText }</Button>
          : null
      }
    </React.Fragment>
  }

  return <Modal
    visible={ isShowModal }
    className={ `modal-dialog ${className}` }
    forceRender={ forceRender }
    maskClosable={ maskClosable }
    destroyOnClose={destroyOnClose}
    closable={ closable }
    width={ width }
    footer={ getFooter() }
    onCancel={ () => cancelHandler && cancelHandler() }
  >
    {title ? <h1>{ title }</h1> : null}
    <div className="content">{ content() }</div>
  </Modal>
}

export default compose(
  withTranslation()
)(ModalWindow)

