import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
  .p-datatable table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.5rem !important;

    .p-datatable-thead > tr > th {
      padding: 0 1rem;
      background: transparent;
    }

    .p-datatable-tbody {
      td {
        cursor: pointer;
        padding: 0;
        height: 2.75rem;
      }

      tr:hover {
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
      }
    }

    .p-datatable-emptymessage {

      td {
        border-radius: 0.5rem;
        padding: 0 0.5rem;
        text-align: center;
        font-size: 0.75rem;
      }
    }
  }

  .bg-primarydark-50 {
    background: var(--primarydark-50);
  }

  .bg-primarydark-400 {
    background: var(--primarydark-400);
  }

  .text-primarydark-400  {
    color: var(--primarydark-400);
  }
`

type TableWrapperComponentProps = {
  children?: React.ReactNode
}
export const TableWrapperComponent = ({
  children
}: TableWrapperComponentProps) => (
  <TableWrapper data-testid="table-wrapper" className="col-12 p-0">
    { children }
  </TableWrapper>
)
