import { connect } from 'react-redux'
import { setReferencePropertiesListAction } from '../../../../../redux/actions/global.actions'
import ProductReferenceProperties from './productReferenceProperties'
import {
  addProductPropertySaga,
  changeProductPropertySaga,
  removeProductPropertySaga
} from '../../../../../redux/sagas/product.saga'

const mapStateToProps = state => ({
  referencePropertiesList: state.global.referencePropertiesList,
})

const mapDispatchToProps ={
  setReferencePropertiesList: setReferencePropertiesListAction,
  addProductPropertySaga,
  changeProductPropertySaga,
  removeProductPropertySaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductReferenceProperties)
