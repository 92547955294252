import { gql } from '@apollo/client'

export default gql`
  query productImportColumns {
    productImportColumns {
      name
      altNames
      description
      required
    }
  }
`
