import React from 'react'
import { Button, Tooltip } from 'antd'
import compose from '../../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { ScientificNotation } from '../scientific-notation/scientificNotation'
import { isEmpty, returnNested } from '../../../../utils/tools'
import { IMPACT_CALCULATION_STATUS, NAV_EXTERNAL } from '../../../../utils/const'
import { SyncOutlined } from '@ant-design/icons'
import { IInventoryTotalImpactProps } from './inventoryTotalImpact.interface'
import { ExternalLinkIcon } from '../../../shared/customIcons'
import ImpactArrow from '../../../helpers/display/impactArrow/impactArrow'
import { getImpactEffect, getImpactPercentage } from '../../../../utils/math'

const InventoryTotalImpact:React.FC<IInventoryTotalImpactProps> = props => {
  const {
    impact,
    impactCategory,
    impactToCompare,
    impactUseMethodTotal,
    isCarbonTranslatorShowed,
    t
  } = props

  const isCarbonTranslatorDisabled = () => {
    const impactUnit = returnNested(impact, 'unit')
    const isUnitKgCO2 = impactUnit && impactUnit.includes('kg CO2')
    return !isUnitKgCO2
  }

  if (isEmpty(impact) ) return null

  return (
    <React.Fragment>
      <div className="inventory-impact-result">
        <span data-cy="inventory-impact-result-name" className="name">{impactUseMethodTotal ? t('model.single_score') : returnNested(impactCategory, 'name')} </span>
        <span data-cy="inventory-impact-result-amount" className="amount">
          {impact.status === IMPACT_CALCULATION_STATUS.PENDING && <SyncOutlined className='impact-pending' spin /> }
          <ScientificNotation value={ returnNested(impact, 'amount') }/>
        </span>
        <span data-cy="inventory-impact-result-unit" className="unit"> { returnNested(impact, 'unit') }
        </span>
        { impactToCompare && <span className="total-impact-percentage">
          <ImpactArrow
            impactEffect={getImpactEffect(impactToCompare.amount, impact.amount)}
            percentage={getImpactPercentage(impact.amount, impactToCompare.amount)}
          />
        </span>}
      </div>
      { isCarbonTranslatorShowed ? <div className="inventory-carbon-translator text-center">
        <Tooltip placement="top" title={isCarbonTranslatorDisabled() && t('model.carbon_translator_disabled_tooltip')}>
          <Button
            disabled={ isCarbonTranslatorDisabled() }
            size="large"
            href={`${NAV_EXTERNAL.CARBON_TRANSLATOR}?amount=${returnNested(impact, 'amount')}&unit=kg`}
            target="_blank"
            className="button"
          >
            {t('model.carbon_translator')} <ExternalLinkIcon />
          </Button>
        </Tooltip>
        <p>{t('model.carbon_translator_description')}</p>
      </div>
        : null }
    </React.Fragment>
  )
}

export default compose(
  withTranslation()
)(InventoryTotalImpact)
