import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Paragraph } = Typography
export const WorkspaceDefault = () => {
  const { t } = useTranslation()
  // TODO: This Component has to be Redone with new template and prime react component.
  return (
    <Row>
      <Col span={24} className="middle-panel">
        <div data-testid="account-page-wrapper" className="account-page">
          <div data-testid="account-page-title" className="title" data-cy="account-page-title">{t('account_page.title')}</div>
          <div className="content-container">
            <div className="subtitle">
              <Paragraph data-testid="account-page-subtitle-I">{t('account_page.subtitle')}</Paragraph>
              <Paragraph className="question"><strong data-testid="account-page-subtitle-II">{t('account_page.subtitle_title')}</strong></Paragraph>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}
