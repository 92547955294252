import { gql } from '../../../__generated__'

export const CREATE_ITEM_FOLDER = gql(`
  mutation createItemFolder($name: String!, $spaceID: ID!, $parentID: ID, $labels: [ID!]!) {
    createItemFolder(name: $name, spaceID: $spaceID, parentID: $parentID, labels: $labels) {
      id
      name
    }
  }
`)

export const RENAME_ITEM_FOLDER = gql(`
  mutation renameItemFolder($folderID: ID!, $newName: String!) {
    renameItemFolder(folderID: $folderID, newName: $newName) {
      id
      name
    }
  }
`)

export const REMOVE_ITEM_FOLDER = gql(`
  mutation removeItemFolder($folderID: ID!) {
    removeItemFolder(folderID: $folderID)
  }
`)

export const MOVE_ITEM = gql(`
  mutation moveItem($productID: ID!, $newFolderID: ID!) {
    moveItem(productID: $productID, newFolderID: $newFolderID){
      id
    }
  }
`)

export const MOVE_FOLDER = gql(`
  mutation moveItemFolder($folderID: ID!, $toFolderID: ID!) {
    moveItemFolder(folderID: $folderID, toFolderID: $toFolderID){
      id
    }
  }
`)
