import { gql } from '@apollo/client'

export default gql`
  mutation createPermission($permitteeID: ID!, $policies: [ID!]!, $resourceID: ID!, $resourceType: String!) {
    createPermission(permitteeID: $permitteeID, policies: $policies, resourceID: $resourceID, resourceType: $resourceType) {
      id
      who{
        id
        name
      }
      what{
        id
        type
      }
      how{
        id
      }
    }
  }
`
