import { gql } from '@apollo/client'
import account from '../fragment/account'

export default gql`
  mutation createAccount($name: String!) {
    createAccount(name: $name) {
      ...accountFragment
    }
  }
  ${account}
`
