import React from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { AddNodeWrapperLayout } from '../../product/layout/add-node-wrapper.layout'

type AddPhaseComponentProps = {
  handleAddSubItem?: (param: any) => void
}
export const AddPhaseContainer = ({
  handleAddSubItem = () => {}
}: AddPhaseComponentProps) => {
  const { t } = useTranslation([ 'lifecycle' ])

  return (
    <>
      <AddNodeWrapperLayout className="mx-0 mt-3 p-2" onClick={handleAddSubItem} dataCy="add-item-from-button" dataTestId="add-item-node-link">
        <div className="flex-none flex align-items-center justify-content-center">
          <FontAwesomeIcon data-testid="add-item-node-icon" icon={faPlus} className="text-base" />
        </div>
        <div data-cy="add-new-phase" data-testid="add-item-node-text" className="flex-grow-1 flex align-items-center text-base justify-content-start line-height-4">
          { t('actions.add', { context: 'phase' }) }
        </div>
      </AddNodeWrapperLayout>
    </>
  )
}

