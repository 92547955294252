import { connect } from 'react-redux'
import ComparisonSelector from '../../../redux/selectors/comparison.selector'
import FlagsSelector from '../../../redux/selectors/flags.selector'
import { LifecycleScenarioSelector, LifecycleSelector } from '../../../redux/selectors/lifecycle.selector'
import LoadingSelector from '../../../redux/selectors/loading.selector'
import { setLifecycleDetailsPanelSaga } from '../../../redux/sagas/lifecycle.saga'
import LifecycleTreeWrapper from './lifecycleTreeWrapper'

const mapStateToProps = state => ({
  lifecycle: LifecycleSelector.selectedLifecycle(state),
  lifecycleScenario: LifecycleScenarioSelector.lifecycle(state),
  isShowLifecycleScenarioInventory: FlagsSelector.isShowLifecycleScenarioInventory(state),
  isInventoryCompare: ComparisonSelector.isInventoryCompare(state),
  entityToCompare: ComparisonSelector.entityToCompare(state),
  isLoadingLeftTree: LoadingSelector.isLoadingLeftTree(state),
  isLoadingRightTree: LoadingSelector.isLoadingRightTree(state),
  detailsPanelLifecycleId: LifecycleSelector.detailsPanelLifecycleId(state),
})

const mapDispatchToProps = {
  setLifecycleDetailsPanelSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleTreeWrapper)
