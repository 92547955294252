import React, { useRef, useEffect, useState } from 'react'
import { notification } from 'antd'
import { withTranslation } from 'react-i18next'
import { Chart } from 'primereact/chart'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment'
import { notify, requiresScientificNotation, roundNumber, scientificNotation } from '../../../utils/tools'
import { NUMBER_FORMAT, TRACK_EVENT_TYPE } from '../../../utils/const'
import compose from '../../../utils/compose'
import { FONTS } from './assets'
import { getDocDefinition } from './docDefinition'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const TEXT_MAX_LENGTH = {
  ACCOUNT_NAME: 50,
  PRODUCT_NAME: 60,
  PRODUCT_DESCRIPTION: 280,
  MATERIAL_NAME: 11,
  MATERIAL_WIDTH: 84
}

const PdfResults = props => {

  const {
    flatView,
    inventoryProduct,
    selectedAccountName,
    impactCategory,
    impactMethod,
    impactNwSet,
    impactUseMethodTotal,
    email,
    lifecycle,
    onAfterDownload,
    trackEventSaga,
    t
  } = props

  const chartRef = useRef()
  const [ mappedPdfData, setMappedPdfData ] = useState(null)

  useEffect(() => {
    notify({ type: 'success', message: 'model.pdfDownloadStartedMessage', notification, t })
    mapImpactData()
    trackEventSaga(TRACK_EVENT_TYPE.DOWNLOADED_PDF_RESULTS)
  }, [])

  const documentStyle = getComputedStyle(document.documentElement)
  const pdfColorSet = {
    gray200: documentStyle.getPropertyValue('--gray-200').trim(),
    green500: documentStyle.getPropertyValue('--green-500').trim(),
    primary500: documentStyle.getPropertyValue('--primary-500').trim(),
    primaryDark500: documentStyle.getPropertyValue('--primarydark-500').trim(),
  }
  const graphColorSet = [
    documentStyle.getPropertyValue('--primary-900').trim(),
    documentStyle.getPropertyValue('--primary-700').trim(),
    documentStyle.getPropertyValue('--primary-500').trim(),
    documentStyle.getPropertyValue('--primary-300').trim(),
    documentStyle.getPropertyValue('--primary-100').trim(),
    documentStyle.getPropertyValue('--primary-50').trim()
  ]

  const chartOptions = {
    cutout: '57%',
    animation: false,
    devicePixelRatio: 2,
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  }

  const chartPlugins = [
    {
      afterRender: chart => {
        const data = mapDataForDocDefinition(chart)
        const fileName = generateFilename()
        pdfMake.createPdf(getDocDefinition(data, { colors: pdfColorSet }), null, FONTS).download(fileName, onAfterDownload)
      }
    }
  ]

  const generateFilename = () => {
    const entityName = lifecycle ? lifecycle.name : inventoryProduct.name
    const prefix = 'Impact insights_'
    const dateTime = '_' + moment().format('DD-MM-YYYY HH:mm:ss')
    const name = entityName.replace(/[/\\?%*:|"<>]/g, '-').slice(0, 255 - (prefix + dateTime).length )
    return prefix + name + dateTime + '.pdf'
  }

  const cropString = ( value, threshold) => {
    return value.length > threshold ? value.slice(0, threshold) + '…' : value
  }

  const formatScientificNotation = value => {
    const isSn = requiresScientificNotation(value)
    const valueSn = scientificNotation(value)
    return isSn ? {
      value: `${valueSn.coefficient} · 10 ${valueSn.exponent}`,
      ...valueSn
    } : {
      value: roundNumber(value, NUMBER_FORMAT.PRECISION)
    }
  }

  const mapDataForDocDefinition = chart => {
    const entityName = lifecycle ? lifecycle.name : inventoryProduct.name
    const entityUnit = lifecycle ? lifecycle.unit : inventoryProduct.unit
    const entityDescription = lifecycle ? lifecycle.description : inventoryProduct.description
    const entityAmount = lifecycle?.amount || '1'

    const displayedImpactCategory = impactUseMethodTotal ? t('model.single_score') : impactCategory?.name
    const impactCategoryWithNw = impactNwSet ? `${displayedImpactCategory} - ${impactNwSet?.name}` : displayedImpactCategory

    return {
      accountName: selectedAccountName,
      accountNameCropped: cropString(selectedAccountName, TEXT_MAX_LENGTH.ACCOUNT_NAME),
      amount: entityAmount,
      date: moment().format('DD-MM-YYYY'),
      legendData: mappedPdfData?.legendData,
      doughnut: chart.toBase64Image(),
      name: cropString(entityName, TEXT_MAX_LENGTH.PRODUCT_NAME),
      unit: entityUnit,
      description: cropString(entityDescription, TEXT_MAX_LENGTH.PRODUCT_DESCRIPTION),
      totalImpact: {
        amount: formatScientificNotation(parseFloat(flatView.totalImpact.amount)),
        unit: flatView.totalImpact.unit || t('global.noUnit')
      },
      impactCategory: impactCategoryWithNw,
      impactMethod: impactMethod?.name,
      email
    }
  }

  const cropLegendText = text => {
    const maxWidth = TEXT_MAX_LENGTH.MATERIAL_WIDTH
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    context.font = '10px Rubik'

    const width = context.measureText(text).width

    if (width <= maxWidth) return text

    const firstPart = text.slice(0, (TEXT_MAX_LENGTH.MATERIAL_NAME))
    const secondPart = text.slice(TEXT_MAX_LENGTH.MATERIAL_NAME)

    let croppedString = ''

    secondPart.split('').forEach(char => {
      if ( context.measureText(firstPart + croppedString).width < maxWidth ) {
        croppedString += char
      }
    })

    return firstPart + croppedString + '…'
  }

  const mapImpactData = () => {
    const legendData = []
    const chartData = []

    const entityId = lifecycle ? lifecycle.id : inventoryProduct.id
    const items = flatView?.items || []

    const top5Items = items
      .filter(item => item.productId !== entityId && item.impactAmount !== 0)
      .sort((a, b) => b.impactAmount - a.impactAmount)
      .slice(0, 5)

    const othersSum = items
      .filter(item => !top5Items.includes(item))
      .reduce((sum, item) => sum + item.impactAmount, 0)

    top5Items.forEach((item, index) => {
      const perc = item.impactPercentageFromRoot.toFixed(1)
      chartData.push(item.impactAmount)
      legendData.push({
        name: cropLegendText(item.productName),
        perc,
        color: graphColorSet[index]
      })
    })

    if (othersSum > 0) {
      chartData.push(othersSum)
      const first5Perc = top5Items.reduce((sum, item) => sum + parseFloat(item.impactPercentageFromRoot.toFixed(1)), 0)
      legendData.push({
        name: t('global.other'),
        perc: Math.abs((100 - first5Perc)).toFixed(1),
        color: graphColorSet[graphColorSet.length - 1]
      })
    }

    setMappedPdfData({ legendData, chartData: {
      datasets: [
        {
          data: chartData,
          backgroundColor: graphColorSet
        }
      ]
    } })
  }

  return <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
    { mappedPdfData && <Chart
      ref={chartRef}
      type="doughnut"
      data={mappedPdfData.chartData}
      options={chartOptions}
      plugins={chartPlugins}
      height="562px"
      width="562px"
    /> }
  </div>
}

export default compose(
  withTranslation()
)(PdfResults)
