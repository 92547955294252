import { gql } from '@apollo/client'

export default gql`
  mutation changeLifecycleProduct($id: ID!, $productID: ID!) {
    changeLifecycleProduct(id: $id, productID: $productID) {
      id
      product{
          id
          name
          lifecycles{
              id
              name
              slug
          }
      }
    }
  }
`
