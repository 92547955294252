export const getImpactBarAttr = ({
  impactAmount,
  totalImpactAmount,
  maxImpactAmount,
}: {
  impactAmount: string,
  totalImpactAmount: string,
  maxImpactAmount: number,
}) => {
  const fImpactAmount = parseFloat(impactAmount)
  const fTotalImpactAmount = parseFloat(totalImpactAmount)

  if (fTotalImpactAmount === 0 || Number.isNaN(fTotalImpactAmount) || Number.isNaN(fImpactAmount)) {
    return { impactType: 'negative', impactPercent: 0 }
  }

  const ratio = fImpactAmount / maxImpactAmount
  const ratioStatus = ratio > 0 ? 'positiveBar' : 'negativeBar'

  return {
    impactType: ratioStatus,
    impactRatioRelativeToTotal: fImpactAmount / fTotalImpactAmount,
    impactPercent: `${(Math.abs(ratio) * 100).toFixed(2)}%`
  }
}

export const getSelectedDatabasesStorageItem = () => {
  const selectedDatabases = localStorage.getItem('SELECTED_DATABASES')
  if (selectedDatabases) {
    const parsedSelectedDatabases = JSON.parse(selectedDatabases)
    // If the selected databases are stored as an array of objects, return the name of the object
    // Otherwise, return the string
    // This was added because previously it was stored as an array of objects and now we changed it to an array of strings
    return parsedSelectedDatabases.map((database: string | { name: string }) => {
      const isString = typeof database === 'string'
      return isString ? database : database.name
    })
  }
  return []
}

export const setSelectedDatabasesStorageItem = (selectedDatabases: string[]) => {
  localStorage.setItem('SELECTED_DATABASES', JSON.stringify(selectedDatabases))
}
