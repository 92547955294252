import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { AiOutlineTag } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { update as intercomUpdate } from '@intercom/messenger-js-sdk';
import { useProductOverviewQuery } from '../../shared/hook/query/use-product-overview.query'
import { SplitScreenLayout } from '../../shared/layout/split-screen.layout'
import { RecentProductHeaderComponent } from '../component/recent-product-header.component'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { ImportProductContainer } from '../../shared/container/import-product.container'
import { CreateProductContainer } from '../../shared/container/create-product.container'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { OverviewCardTemplateLayout, OverviewCardTemplateType } from '../../shared/layout/overview-card-template.component'
import { OverviewCreateComponent } from '../../shared/component/overview/overview-create.component'
import { OverviewViewComponent } from '../../shared/component/overview/overview-view.component'
import { RecentListLayout } from '../layout/recent-list.layout'
import { ScreenSizes } from '../../shared/enum/screen'
import { useWindowSize } from '../../shared/hook/use-window-size'
import { ProgressSpinnerComponent } from '../../shared/component/general/progress-spinner.component'
import { EntityType } from '../../shared/enum/entity-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TListItem } from '../../shared/interface/list-item'
import { ListType, Navigation } from '../../shared/enum'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import { TWorkspaceContext } from '../interface/workspace-context.type'
import WorkspaceContext from '../provider/context/workspace.context'

export const RecentProductsContainer = () => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { loading, productsData, refetchProducts } = useProductOverviewQuery({
    spaceID: space?.id, loadProductOnly: true, folderID: null, pageSize: 6
  })
  const { addToBackToList = () => {}, updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const { updateWorkspace } = useContext<TWorkspaceContext>(WorkspaceContext)

  const navigate = useNavigate()
  const [ trackEventInSegment ] = getSegmentTrack()
  const [ windowWidth ] = useWindowSize()
  const { products: { items = [] } = {} } = productsData || {}

  useEffect(() => {
    refetchProducts && updateWorkspace({ refetchRecentProducts: refetchProducts })
  }, [ refetchProducts ])

  useEffect(() => {
    const firstRecentProductId = items && items.length > 0 ? items[0].id : null
    if (space) {
      intercomUpdate({
        mobius_workspace_id: space?.id,
        mobius_product_id: firstRecentProductId
      })
    }
  }, [ space, items ])

  const openImportProductDialog = () => {
    updateDashboard({ showImportProductDialog: true })
  }

  const openCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: true })
  }

  const navigateToProducts = () => {
    trackEventInSegment(TrackEventType.VIEWED_PRODUCTS_OVERVIEW)
    account?.id && space?.slug && navigate(generatePath(Navigation.ProductOverview, {
      accountId: account.id,
      workspaceSlug: space.slug
    }))
  }

  const navigateToProductDetail = (id: string) => {
    trackEventInSegment(TrackEventType.VIEWED_PRODUCT)
    addToBackToList(t('labels.home', { ns: 'common' }))
    account?.id && space?.slug && navigate(generatePath(Navigation.ProductObjectInventory, {
      accountId: account.id,
      workspaceSlug: space.slug,
      productId: id
    }))
  }

  const createProductTemplate = (createLabel: string) => (
    <OverviewCardTemplateLayout clickHandler={openCreateProductDialog} type={OverviewCardTemplateType.Create}>
      <OverviewCreateComponent createLabel={createLabel} />
    </OverviewCardTemplateLayout>
  )

  const viewProductTemplate = ({
    id = '', name = '', tags = [], updatedAt = '', description = ''
  } = {}) => (
    <OverviewCardTemplateLayout
      listType={ListType.Product}
      key={id}
      clickHandler={() => navigateToProductDetail(id)}
    >
      <OverviewViewComponent
        id={id}
        icon={<AiOutlineTag size={20} />}
        textLabel={name}
        description={description}
        tags={tags}
        updatedAt={updatedAt}
      />
    </OverviewCardTemplateLayout>
  )

  const getDisplayItems = (): TListItem[] => {
    if (items.length === 0) {
      return [ { key: 'create-first-product', itemComponent: createProductTemplate(t('actions.create', { context: 'firstProduct' })) } ]
    }

    let count = 2
    if (windowWidth > ScreenSizes.xxxl) {
      count = 6
    } else if (windowWidth > ScreenSizes.xxl) {
      count = 4
    } else if (windowWidth > ScreenSizes.xl) {
      count = 3
    }

    const displayItems: TListItem[] = items
      .filter((item: any) => item.__typename !== EntityType.Folder)
      .filter((_: any, index: number) => index < count)
      .map((item: any) => ({ key: item.id, itemComponent: viewProductTemplate(item) }))

    const returnValue = displayItems.length < count ? [
      ...displayItems,
      { key: 'create-another-product', itemComponent: createProductTemplate(t('actions.create', { context: 'anotherProduct' })) }
    ] : displayItems

    return returnValue
  }

  const afterCreateProduct = () => {
    trackEventInSegment(TrackEventType.CREATED_PRODUCT)
    refetchProducts && refetchProducts()
  }

  const afterImportProduct = () => {
    trackEventInSegment(TrackEventType.IMPORTED_PRODUCT)
    refetchProducts && refetchProducts()
  }

  return (
    <>
      <SplitScreenLayout
        className="flex-column"
        firstClassName="flex flex-none w-full h-2rem px-4"
        secondClassName="flex-grow-1 w-full"
      >
        <RecentProductHeaderComponent
          navigateToProducts={navigateToProducts}
          openCreateProductDialog={openCreateProductDialog}
          openImportProductDialog={openImportProductDialog}
        />
        {
          loading ? (
            <ProgressSpinnerComponent dataTestId="loading-workspace-entry" size={2} />
          ) : (
            <div className="flex w-full h-full p-3 pb-4 overflow-hidden">
              <RecentListLayout items={getDisplayItems()} />
            </div>
          )
        }
      </SplitScreenLayout>

      <ImportProductContainer
        afterImportProduct={afterImportProduct}
      />

      <CreateProductContainer
        afterCreateProduct={afterCreateProduct}
      />
    </>
  )
}
