import {
  faRocketLaunch,
  faCube,
  faTruck,
  faUser,
  faArrowRotateRight,
  faBallPile,
  faGear,
  faWrench
} from '@fortawesome/pro-regular-svg-icons'

export const PHASE_ICONS = {
  production: faRocketLaunch,
  packaging: faCube,
  transport: faTruck,
  use: faUser,
  eol: faArrowRotateRight,
  raw_materials: faBallPile,
  processing: faGear,
  maintenance: faWrench
}
