import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { ComparisonEntity } from '../enum/entity'

import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'

import { TLifecycleDetailContext } from '../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../lifecycle/provider/context/lifecycle-detail.context'
import { PhaseListContainer } from '../../lifecycle/container/phase-list.container'
import { ModelFooterComponent } from '../component/lifecycle/model/model-footer.component'
import { ModelHeaderComponent } from '../component/lifecycle/model/model-header.component'
import { store } from '../../../configureStore'
import { setSelectedLifecycleAction } from '../../../redux/actions/lifecycle.actions'

const TreeWrapper = styled.div`
  min-height: 100%;
  box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.1);
`

export type LifecycleWrapperLayoutProps = {
  entity?: ComparisonEntity
}
export const LifecycleWrapperLayout = ({
  entity = ComparisonEntity.Primary,
}: LifecycleWrapperLayoutProps) => {
  const {
    lifecycleDetail,
    lifecycleTotalImpact = null,
    updateLifecycleDetail,
    updateLifecycleProduct,
    selectedKey,
    selectedPhase
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const scenarioProductID = selectedPhase?.inventory?.product?.id
  const {
    updateAnalysis,
    primaryUpdateDetail = () => {},
    secondaryUpdateLifecycleProduct = () => {},
    primaryUpdateLifecycleProduct = () => {}
  } = useContext<TAnalysisContext>(AnalysisContext)
  const isPrimary = entity === ComparisonEntity.Primary

  useEffect(() => {
    if (!lifecycleTotalImpact || !lifecycleDetail) return

    const defaultAnalysis = isPrimary ? {
      entity,
      primaryTotalImpact: lifecycleTotalImpact,
      primaryEntityName: lifecycleDetail?.name,
      primaryEntityUnit: lifecycleDetail?.unit,
      primaryUpdateDetail: updateLifecycleDetail,
      primaryUpdateLifecycleProduct: updateLifecycleProduct
    } : {
      entity,
      scenarioProductID,
      secondaryTotalImpact: lifecycleTotalImpact,
      secondaryEntityName: lifecycleDetail?.name,
      secondaryEntityUnit: lifecycleDetail?.unit,
      secondaryUpdateDetail: updateLifecycleDetail,
      secondaryUpdateLifecycleProduct: updateLifecycleProduct
    }

    updateAnalysis(defaultAnalysis)

    return () => {
      updateAnalysis({ primaryExpandedKey: [], secondaryExpandedKey: [] })
    }
  }, [ lifecycleTotalImpact, lifecycleDetail, scenarioProductID ])

  useEffect(() => {
    if (selectedKey) {
      store.dispatch(setSelectedLifecycleAction(null))
      primaryUpdateDetail({ showLifecycleDetailPanelOnly: false })
      updateAnalysis({ scenarioSelectedKey: selectedKey })
    }

    isPrimary
      ? secondaryUpdateLifecycleProduct({ selectedKey: null, selectedProduct: null })
      : primaryUpdateLifecycleProduct({ selectedKey: null, selectedProduct: null })
  }, [ selectedKey ])

  const dataCy = isPrimary ? 'primary-tree-container' : 'secondary-tree-container'
  return (
    <>
      { lifecycleDetail && (
        <TreeWrapper className="flex w-full border-round-lg text-gray-500 flex-column shadow-2 mb-5">
          <div className="flex w-full">
            <ModelHeaderComponent entity={entity} />
          </div>
          <div data-cy={dataCy} data-testid="tree-wrapper-body" className="bg-white flex-grow-1 border-top-1 border-gray-100 p-3">
            <PhaseListContainer entity={entity} />
          </div>
          <div data-testid="tree-wrapper-footer" className="flex w-full h-3rem px-5 py-3 border-round-bottom-lg border-top-1 border-gray-100 tree-wrapper-footer">
            <ModelFooterComponent entity={entity} />
          </div>
        </TreeWrapper>
      )}

    </>
  )
}
