import { gql } from '@apollo/client'

export default gql`
  fragment favoriteProductFragment on Product {
    id
    name
    unit
    type
    description
    favorite
    createdAt
    updatedAt
    tags {
      id
      name
      color
    }
    labels{
      id
    }
    folder{
      id
      name
    }
  }
`
