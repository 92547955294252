import React, { useContext, useRef, useState } from 'react'
import { classNames } from 'primereact/utils'

import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'

import {
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown, faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { createGlobalStyle } from 'styled-components'
import { OverlayPanel } from 'primereact/overlaypanel'

import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { DatasetSortField, FacetValue, SortingOrder } from '../../../../__generated__/graphql'
import { SortTogglerComponent } from './sort-toggler.component'

const GlobalStyle = createGlobalStyle`
  .p-inputtext {
    border-color: var(--gray-100) !important;
  }
  .p-inputtext::placeholder {
    color: var(--gray-300);
    opacity: 1; /* Firefox */
  }
  .p-inputtext:focus {
    box-shadow: none !important;
  }

  .geography-filter-panel {
    max-width: 17rem;

    .p-dropdown-item {
      white-space: normal;
    }
  }
`

type TGeographyFilterComponentProps = {
  label?: string,
}

export const GeographyFilterComponent = ({
  label = '',
}: TGeographyFilterComponentProps) => {
  const {
    selectedLocations = [],
    locationList,
    updateFilterValue,
    selectedSortBy,
    selectedSortOrder
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const overlayPanel = useRef<OverlayPanel>(null)
  const [ searchValue, setSearchValue ] = useState<string>('')

  const filterByLocation = (locationName: string) => {
    if (locationName) {
      updateFilterValue({ selectedLocations: [ locationName ] })
    } else {
      updateFilterValue({ selectedLocations: [] })
    }
    overlayPanel.current?.hide()
  }

  const onLocationChange = (event: any) => {
    setSearchValue('')
    filterByLocation(event.value?.name)
  }

  const selectedLocation = locationList?.find((location: FacetValue) => location.name === selectedLocations[0])

  const FilterTemplate = (options: any) => {
    const { filterOptions } = options

    const clearFilter = () => {
      setSearchValue('');
      filterOptions.reset();
    }

    const onFilterChange = (event: any) => {
      setSearchValue(event.target.value);
      filterOptions.filter(event);
    }

    const onKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        const vislbleOptions = options.props.visibleOptions
        if (vislbleOptions.length > 0) {
          filterByLocation(vislbleOptions[0].name)
          setSearchValue('')
        }
      }
    }

    return (
      <div className="flex align-items-center">
        <div className="p-inputgroup">
          <InputText
            data-testid="filter-location-input"
            autoFocus
            value={searchValue}
            onChange={onFilterChange}
            className="w-full border-right-none text-sm px-2"
            placeholder=""
            onKeyDown={onKeyDown}
          />
          <span className="p-inputgroup-addon border-left-none bg-white pl-2 pr-0 min-w-min">
            { searchValue && <FontAwesomeIcon data-testid="clear-search-fields" icon={faXmark} className="text-basis text-gray-300 cursor-pointer" onClick={clearFilter} /> }
          </span>
          <span className="p-inputgroup-addon border-left-none bg-white px-2 min-w-min">
            <i className="pi pi-search text-sm" />
          </span>
        </div>
      </div>
    )
  }

  const ItemTemplate = (item: FacetValue) => {
    const showCount = selectedLocations.length === 0 || selectedLocations.includes(item.name)
    return (
      <div className="text-sm text-grey-500">
        <span>{ item.name }</span>
        { showCount && <span className="ml-1 text-xs">({ item.count })</span> }
      </div>
    )
  }

  const isSortByLocation = selectedSortBy === DatasetSortField.Location

  const handleSortingChange = (sortOrder: any) => {
    if (!sortOrder) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
    } else {
      updateFilterValue({ selectedSortBy: DatasetSortField.Location, selectedSortOrder: sortOrder })
    }

    overlayPanel?.current?.hide()
  }

  return (
    <>
      <GlobalStyle />

      <OverlayPanel ref={overlayPanel}>
        <div className="flex flex-column w-full">
          <div className="flex w-full pb-3 line-height-1 text-sm font-medium border-bottom-1 border-gray-100">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
            <div className="flex flex-grow-1 justify-content-end">
              <SortTogglerComponent
                onViewChange={handleSortingChange}
                selectedSortOrder={selectedSortBy === DatasetSortField.Location ? selectedSortOrder : undefined}
              />
            </div>
          </div>
          <div className="flex align-items-center pt-3 w-17rem">
            <Dropdown
              filter
              filterTemplate={FilterTemplate}
              itemTemplate={ItemTemplate}
              showClear={selectedLocations.length > 0}
              value={selectedLocation}
              placeholder="Select a location"
              data-testid="geography-filter-dropdown"
              data-cy="geography-filter-dropdown"
              className="w-full"
              panelClassName="geography-filter-panel"
              onChange={onLocationChange}
              options={locationList}
              optionLabel="name"
              emptyFilterMessage="no results found"
            />
          </div>
        </div>
      </OverlayPanel>

      <span
        data-testid="table-header-geography-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {
          'text-primary-500': selectedLocation || isSortByLocation
        })}
      >
        { label }
        { !isSortByLocation
          ? <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          : (
            <>
              { selectedSortOrder === SortingOrder.Asc && <FontAwesomeIcon data-testid="property-filter-asc" className="ml-1 text-sm" icon={faArrowDown} /> }
              { selectedSortOrder === SortingOrder.Desc && <FontAwesomeIcon data-testid="property-filter-desc" className="ml-1 text-sm" icon={faArrowUp} /> }
            </>
          )}
      </span>
    </>
  )
}
