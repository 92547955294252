import { returnNested } from './tools'
import { matchPath } from 'react-router-dom'
import { AccountSettingPageLocation, UserProfilePageLocation, LogoutLocation } from '../component/shared/locations'

export const matchLocation = (locationRoute, end = false) => {
  const matchResponse =  matchPath(
    { path: locationRoute.path, end },
    returnNested(window, 'location', 'pathname')
  )
  return matchResponse ? true : false
}

export const isLocationAccessibleWithoutSubscription = ()  => {
  const isOnSettingsPage = matchLocation(AccountSettingPageLocation)
  const isOnUserProfilePage = matchLocation(UserProfilePageLocation)
  const isLogOut = matchLocation(LogoutLocation)
  return  isOnSettingsPage || isOnUserProfilePage || isLogOut
}
