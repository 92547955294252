import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

type AuthenticationProviderProps = {
  children: React.ReactNode
}

declare global {
  interface Window { env: any; }
}

const {
  AUTH_DOMAIN = 'no-env',
  AUTH_AUDIENCE = 'no-env',
  AUTH_CLIENTID = 'no-env',
  AUTH_REDIRECTURI = 'no-env',
  AUTH_RETURNTOURI = 'no-env'
} = window.env || {}

const scopes = [
  'read:current_user',
  'create:current_user_metadata',
  'update:current_user_metadata',
  'delete:current_user_metadata',
  'openid profile email' // TODO FIX SCOPE openid profile email
]

export const AuthenticationProvider = ({
  children
}:AuthenticationProviderProps) => {
  const getQueryParams = (paramName: string) => {
    const queryParams: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string)
    })
    return queryParams[paramName]
  }

  const marketingParams = {
    utm_campaign: getQueryParams('utm_campaign'),
    utm_source: getQueryParams('utm_source'),
    utm_medium: getQueryParams('utm_medium'),
    utm_term: getQueryParams('utm_term'),
    utm_content: getQueryParams('utm_content'),
    gclid: getQueryParams('gclid'),
    _gl: getQueryParams('_gl')
  }

  return (
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENTID}
      authorizationParams={{
        redirect_uri: AUTH_REDIRECTURI,
        returnTo: AUTH_RETURNTOURI,
        audience: AUTH_AUDIENCE,
        scope: scopes.join(' '),
        ...marketingParams,
      }}
    >
      {children}
    </Auth0Provider>
  )
}
