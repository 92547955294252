import React, { useContext } from 'react'
import styled from 'styled-components'
import { useReactiveVar } from '@apollo/client'
import { useNavigate, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Navigation, ProductLabel } from '../../../shared/enum'
import ProductContext from '../../provider/context/product.context'
import { isValid } from '../../../shared/util/tools'

import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'

const Title = styled.div`
  width: calc(100% - 16rem)
`
export const HeaderComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const navigate = useNavigate()
  const { updateDashboard } = useContext(DashboardContext)
  const { label, currentFolder = null, updateProduct } = useContext(ProductContext)
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const { id: parentFolderID = null, name: parentFolderName = null } = currentFolder?.parent || {}
  const { name: currentFolderName = null } = currentFolder || {}
  const textLabel = label === ProductLabel.Product ? t('labels.product', { count: 2 }) : t('labels.object')

  const openImportProductDialog = () => {
    updateDashboard({ showImportProductDialog: true })
  }

  const openCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: true })
  }

  const getPrevNavLabel = () => {
    if (isValid(parentFolderName)) {
      return parentFolderName
    }

    return textLabel
  }

  const getTitleText = () => {
    if (isValid(currentFolderName)) {
      return currentFolderName
    }

    return textLabel
  }

  const navigateToPrev = () => {
    const pathname: Navigation = label === ProductLabel.Product
      ? Navigation.ProductOverview : Navigation.ObjectOverview
    let path = account?.id && space?.slug && generatePath(pathname, {
      accountId: account.id,
      workspaceSlug: space.slug
    })
    if (isValid(parentFolderName)) {
      path += `/?folderId=${parentFolderID}`
    }

    path && navigate(path)
    updateProduct({ currentPage: 1 })
  }

  return (
    <div className="flex flex-column w-full">
      { isValid(currentFolder) && (
        <div
          className="flex flex-none w-full pb-3"
          onClick={navigateToPrev}
        >
          <div className="flex flex-none cursor-pointer" data-cy="go-back-button">
            <div className="flex flex-none mr-2">
              <FontAwesomeIcon icon={faArrowLeft} size="xl" />
            </div>
            <div className="flex flex-none">
              { getPrevNavLabel() }
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-none w-full h-3rem">
        <Title data-testid="product-header-title" data-cy="page-header-title" className="flex flex-none text-4xl font-medium text-gray-800">
          { getTitleText() }
        </Title>
        <div data-testid="product-header-actions" className="flex flex-none w-16rem justify-content-end">

          <Button
            data-testid="overview-products-import"
            onClick={openImportProductDialog}
            label={t('labels.import')}
            className="p-button-outlined p-button-plain my-1 mr-4"
            aria-controls="popup_menu"
            aria-haspopup
          />

          <Button
            onClick={openCreateProductDialog}
            data-cy="add-new-product"
            data-testid="overview-products-create"
            label={t('actions.create', { context: label })}
            className="p-button p-component p-button-success my-1"
          />
        </div>
      </div>
    </div>
  )
}
