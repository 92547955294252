import { gql } from '../../../__generated__'

export const START_TRIAL = gql(`
  mutation startTrial($accountID: ID!) {
    startTrial(accountID: $accountID)
  }
`)

export const CREATE_ACCOUNT = gql(`
  mutation createAccount($name: String!) {
    createAccount(name: $name) {
      id
      name
    }
  }
`)
