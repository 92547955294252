import { gql } from '@apollo/client'

export default gql`
  fragment templateFragment on ActivityTemplate {
    id
    spaceID
    type
    name
    description
    createdBy {
      id
      name
    }
    createdAt
    updatedAt
  }
`
