import React from 'react'

type TFixedHeaderComponentProps = {
  label?: string
}
export const FixedHeaderComponent = ({
  label
}: TFixedHeaderComponentProps) => (
  <div
    data-testid="header-fixed-section"
    className="hidden w-full lg:block lg:absolute z-3 top-0"
  >

    <div className="flex w-full h-full bg-white border-bottom-1 border-gray-100 px-5 py-3">
      <div data-testid="fixed-header-label" className="flex-grow-1 text-base font-medium text-gray-300">
        { label }
      </div>
      <div className="flex w-18rem">
        {/* TODO : Search by query component */}
      </div>
    </div>
  </div>
)
