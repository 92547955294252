import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tag } from 'primereact/tag'
import styled from 'styled-components'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'

import { GettingStatedChecklistItem } from '../../../shared/enum/sign-up-experience'
import { useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Navigation } from '../../../shared/enum'

const TitleWrapper = styled.div`
  width: calc(100% - 11rem);
`
type TGettingStartedPanelHeaderComponentProps = {
  step: number,
  options?: any,
  gettingStartedChecklistItem: GettingStatedChecklistItem,
  setPanelCollapsed: (param: boolean) => void
}
export const GettingStartedPanelHeaderComponent = ({
  step,
  gettingStartedChecklistItem,
  options = {},
  setPanelCollapsed
}: TGettingStartedPanelHeaderComponentProps) => {
  const { t } = useTranslation([ 'workspace' ])
  const [ titleRef, doesTitleOverflows ] = useRefOverflow()
  const { collapsed = true } = options
  const {
    isTrialPineappleUser = () => false
  } = useContext<IDashboardContext>(DashboardContext)
  const navigate = useNavigate()

  const isChecklistItem = (item: GettingStatedChecklistItem) => (gettingStartedChecklistItem === item)

  const triggerCheckList = () => {
    navigate(Navigation.Root, { state: { isFromPineappleTrailStart: true } })
  }

  return (
    <div className="flex align-items-center w-full gap-3 p-3">
      <Tooltip target={`.getting-started-title-${step}`} onBeforeShow={() => doesTitleOverflows}>
        <div className="w-20rem">{t('labels.gettingStarted', { context: gettingStartedChecklistItem })}</div>
      </Tooltip>
      <div
        className="flex align-items-center justify-content-center h-2rem cursor-pointer"
        onClick={() => setPanelCollapsed(!collapsed)}
      >
        <FontAwesomeIcon data-testid="toggle-expand" className="text-gray-300" icon={faPlay} rotation={!collapsed ? 90 : undefined} />
      </div>
      <div data-testid="getting-started-step" className="flex align-items-center justify-content-center w-2rem h-2rem border-circle text-base step-background">
        {step}
      </div>
      <TitleWrapper onClick={() => setPanelCollapsed(!collapsed)} data-testid="getting-started-label" ref={titleRef} data-pr-position="bottom" className={`text-base cursor-pointer text-gray-700 white-space-nowrap overflow-hidden text-overflow-ellipsis getting-started-title-${step}`}>
        {t('labels.gettingStarted', { context: gettingStartedChecklistItem })}
      </TitleWrapper>
      { isTrialPineappleUser() && (
        <div className="flex align-items-top justify-content-end w-9rem">
          { !isChecklistItem(GettingStatedChecklistItem.ModelYourProduct)
            && (
              <Tag data-testid="getting-started-subscribe-tag" className="h-2rem text-sm p-2 font-light bg-gray-50 text-gray-200" severity="success" value={t('actions.gettingStarted', { context: 'subscriptionOnly' })} />
            ) }
          { isChecklistItem(GettingStatedChecklistItem.ModelYourProduct)
            && (
              <Button className="p-button-primary text-sm h-2rem" onClick={triggerCheckList}>
                {t('actions.gettingStarted', { context: 'startModeling' })}
              </Button>
            ) }
        </div>
      )}
    </div>
  )
}
