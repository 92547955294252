import React from 'react'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'

const LinkBoxType = [
  { type: 'default', background: { regular: 'transparent', hover: '--surface-a' }, iconBackground: { regular: 'transparent', hover: '--surface-a' } },
  { type: 'primary-1', background: { regular: '--primary-600', hover: '--primary-500' }, iconBackground: { regular: '--primary-700', hover: '--primary-600' } },
  { type: 'primary-2', background: { regular: '--primary-300', hover: '--primary-200' }, iconBackground: { regular: '--primary-400', hover: '--primary-300' } },
  { type: 'primary-3', background: { regular: '--primary-700', hover: '--primary-600' }, iconBackground: { regular: '--primary-800', hover: '--primary-700' } },
  { type: 'primary-4', background: { regular: '--primary-500', hover: '--primary-400' }, iconBackground: { regular: '--primary-600', hover: '--primary-500' } }
]

interface LinkBoxWrapperProps {
  readonly type: string;
}

const getLinkBoxType = (type: string) => LinkBoxType.find(item => item.type === type)

const LinkBoxWrapper = styled.div<LinkBoxWrapperProps>`
  .link-box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--surface-a);
  }

  .link-box-subtitle {
    font-size: 0.625rem;
    padding-top: 0.125rem
  }

  .icon-padding-1 {
    padding: 0.313rem 0.563rem;
  }

  .icon-padding-2 {
    padding: 0.438rem;
  }

  &:hover {
    cursor: pointer
  }

  &.link-box-wrapper {
    background: var(${(props: LinkBoxWrapperProps) => getLinkBoxType(props.type)?.background.regular});

    .link-box-icon {
      color: var(${(props: LinkBoxWrapperProps) => (props.type === 'default' ? '--gray-700' : '--primary-50')});
      background: var(${(props: LinkBoxWrapperProps) => getLinkBoxType(props.type)?.iconBackground.regular});
    }

    .link-box-title {
      color: var(${(props: LinkBoxWrapperProps) => (props.type === 'default' ? '--gray-500' : '--surface-a')});
      line-height: 1.4em;
    }

    .link-box-subtitle {
      color: var(${(props: LinkBoxWrapperProps) => (props.type === 'default' ? '--gray-700' : '--primary-100')});
    }
  }


  &.link-box-wrapper:hover {
    background: var(${(props: LinkBoxWrapperProps) => getLinkBoxType(props.type)?.background.hover});
    box-shadow: ${(props: LinkBoxWrapperProps) => (props.type === 'default' ? '0px 5px 25px rgba(0, 0, 0, 0.05)' : 'none')};

    .link-box-icon {
      background: var(${(props: LinkBoxWrapperProps) => getLinkBoxType(props.type)?.iconBackground.regular});
    }
  }
`

export type TLinkBoxComponentProps = {
  type?: string,
  className?: string,
  icon?: React.ReactNode,
  iconClassName?: string,
  title?: string,
  subtitle?: string,
  onClick?: Function,
  loading?: boolean,
  dataTestId?: string
}

export const LinkBoxComponent = ({
  type = 'default',
  title,
  subtitle,
  className,
  icon,
  iconClassName,
  onClick = () => {},
  loading = false,
  dataTestId = ''
}: TLinkBoxComponentProps) => {
  const handleClickEvent = () => {
    if (!loading) {
      onClick()
    }
  }
  return (
    <LinkBoxWrapper type={type} data-testid={dataTestId} onClick={handleClickEvent} className={`flex align-items-center w-full h-full link-box-wrapper ${className}`}>
      { loading && (
        <div data-testid="link-box-loading" className="link-box-loading w-full h-full px-5 py-1">
          <ProgressSpinner className="w-2rem h-2rem" />
        </div>
      )}
      { !loading && (
        <>
          {icon && (
            <div className="flex-none pr-2">
              <div className={`w-2rem h-2rem link-box-icon ${iconClassName}`}>
                { icon }
              </div>
            </div>
          )}
          <div className="flex-grow-1">
            { title && <div className="w-full line-height-1 link-box-subtitle">{ title }</div> }
            { subtitle && <div className="w-full text-sm link-box-title">{ subtitle }</div> }
          </div>
        </>
      )}
    </LinkBoxWrapper>
  )
}
