import React from 'react'
import styled from 'styled-components'
import { TListItem } from '../../shared/interface/list-item'

const RecentListItemWrapper = styled.div`
  @media screen and (min-width: 1600px) {
    &.xxl {
      width: 25%;
    }
  }
  @media screen and (min-width: 1800px) {
    &.xxxl {
      width: 16.6666%;
    }
  }
`

type TRecentListLayoutProps = {
  items: TListItem[]
}

export const RecentListLayout = ({
  items
}: TRecentListLayoutProps) => (
  <>
    {items.map(({ key, itemComponent: ItemComponent }) => (
      <RecentListItemWrapper
        key={key}
        className="sm:col-12 md:col-6 lg:col-6 xl:col-4 xxl xxxl h-full p-0 px-2 flex-none"
      >
        { ItemComponent }
      </RecentListItemWrapper>
    ))}
  </>
)
