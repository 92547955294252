import React from 'react'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { withAuth0 } from '@auth0/auth0-react'
import { withUseAuth0Api } from '../../v1/shared/hook/component/withUseAuth0Api'
import { CheckOutlined } from '@ant-design/icons'
import { Row, Col, Avatar, Card, Input, Button, Divider, Select, Form, Tooltip, Switch } from 'antd'
import { returnNested, handleModal } from '../../utils/tools'
import { DEFAULT } from '../../utils/const'
import { TRACK_EVENT_TYPE, COMPANY_TYPES, NAV_EXTERNAL } from '../../utils/const'
import { countries } from '../../utils/countries'
import { validatePhoneByCountry } from '../../utils/validation'
import { Frame } from '../layout'
import PageHeader from '../model/page-header/page-header'
import WorkspaceDetails from '../model/details-panel/workspace-details/workspaceDetails.container'
import { QuestionCircleOutlined } from '@ant-design/icons'
import DetailsPanel from '../model/details-panel/detailsPanel.container'
import { userAuth0Var } from '../../graphql/cache'
import { JobFunction } from '../../v1/shared/enum/job-function'

class UserProfile extends React.Component {

  componentDidMount() {
    const { setIsDetailsPanelOpenAction, trackPageSaga } = this.props
    setIsDetailsPanelOpenAction(false)
    trackPageSaga(TRACK_EVENT_TYPE.VIEWED_USER_PROFILE)
  }

  handleSubmit = () => {
    const { AUTH_RETURNTOURI = 'no-env' } = window.env || {}
    const { logout } = this.props.auth0
    const { useAuth0ApiHook, setUserProfileAction, addSuccessNotificationAction, t } = this.props
    this.formRef.current.validateFields().then(async values => {

      const userAuth0Prev = userAuth0Var()
      const metadata = { ...userAuth0Prev.metadata, ...values }
      const metadataResponse = await useAuth0ApiHook?.updateAuth0UserMetadata(metadata)|| {}
      setUserProfileAction(metadataResponse)
      addSuccessNotificationAction('model.profile_successfully_updated')
      const { user_metadata={} } = metadataResponse || {}
      userAuth0Var({ ...userAuth0Prev, metadata: user_metadata })
      if (values && values.use_mfa) {
        handleModal({
          content: t('model.two_factor_login_redirect'),
          onOk: () => logout({
            logoutParams: {
              returnTo: AUTH_RETURNTOURI,
            }
          }),
          onCancel: () => {},
        })
      }
    })
  }

  formRef = React.createRef()

  render() {
    const { selectedUser, t } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const jobFunctionOptions = Object.values(JobFunction).map(name => <Select.Option value={name} key={ name }>{t(`user_profile.job_function_list.${name}`)}</Select.Option>)
    const companyTypeOptions = COMPANY_TYPES.map(name => <Select.Option value={name} key={ name }>{t(`user_profile.company_type_list.${name}`)}</Select.Option>)
    const countryOptions = countries.map(country => <Select.Option value={country.code} key={ country.code }>{country.name}</Select.Option>)

    return (
      <Frame>
        {() => (
          <React.Fragment>
            <Row>
              <Col span={24} className='middle-panel'>
                <PageHeader headerTitle={`${t('user_profile.title')}`}/>
                <div className="user-profile">
                  {selectedUser ?
                    <Row >
                      <Col span={16}>
                        <Card className="user-profile-content">
                          <Row type="flex" justify="start" >
                            <Col span={4} className="text-center">
                              <Avatar size={DEFAULT.AVATAR_SIZE} src={returnNested(selectedUser, 'picture')} />
                            </Col>
                            <Col span={20}>
                              <Form
                                {...formItemLayout}
                                onFinish={this.handleSubmit}
                                hideRequiredMark
                                ref={this.formRef}
                                initialValues={{
                                  firstName: returnNested(selectedUser, 'metadata', 'firstName'),
                                  lastName: returnNested(selectedUser, 'metadata', 'lastName'),
                                  companyType: returnNested(selectedUser, 'metadata', 'companyType'),
                                  jobFunction: returnNested(selectedUser, 'metadata', 'jobFunction'),
                                  country: returnNested(selectedUser, 'metadata', 'country'),
                                  phone: returnNested(selectedUser, 'metadata', 'phone'),
                                  use_mfa: returnNested(selectedUser, 'metadata', 'use_mfa') || false,
                                }}
                              >
                                <Form.Item
                                  label={t('user_profile.first_name')}
                                  name="firstName"
                                  rules={[
                                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: t('user_profile.first_name') }) }
                                  ]}
                                >
                                  <Input data-cy="first-name-input" />
                                </Form.Item>
                                <Form.Item
                                  label={t('user_profile.last_name')}
                                  name="lastName"
                                  rules={[
                                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: t('user_profile.last_name') }) }
                                  ]}
                                >
                                  <Input data-cy="last-name-input" />
                                </Form.Item>
                                <Form.Item
                                  label={t('user_profile.country')}
                                  name="country"
                                  rules={[
                                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: t('user_profile.country') }) },
                                  ]}
                                >
                                  <Select
                                    placeholder={t('user_profile.country_placeholder')}
                                    data-cy="country-select"
                                    autoComplete="chrome-off"
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    showSearch
                                  >
                                    { countryOptions }
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label={t('user_profile.phone')}
                                  name="phone"
                                  dependencies={[ 'country' ]}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const phoneValidator = validatePhoneByCountry(value, getFieldValue('country'))
                                        if (phoneValidator.isValid) {
                                          return Promise.resolve()
                                        }
                                        return Promise.reject(t(phoneValidator.errMessage, { name: t('user_profile.phone') }) )
                                      },
                                    }),
                                  ]}
                                >
                                  <Input
                                    data-cy="last-name-input"
                                    placeholder={t('user_profile.phone_placeholder')}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={t('user_profile.company_type')}
                                  name="companyType"
                                  rules={[
                                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: t('user_profile.company_type') }) }
                                  ]}
                                >
                                  <Select
                                    placeholder={t('user_profile.company_type_placeholder')}
                                    data-cy="job-position-select"
                                  >
                                    { companyTypeOptions }
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label={t('user_profile.job_function')}
                                  name="jobFunction"
                                  rules={[
                                    { required: true, message: t('err.VALUE_IS_EMPTY', { name: t('user_profile.job_function') }) }
                                  ]}
                                >
                                  <Select
                                    placeholder={t('user_profile.job_function_placeholder')}
                                    data-cy="job-position-select"
                                  >
                                    {jobFunctionOptions}
                                  </Select>
                                </Form.Item>
                                <Form.Item label={t('entry.email')}>
                                  <span data-cy="email-label" className="ant-form-text">{selectedUser.email}</span>
                                </Form.Item>
                                <Form.Item
                                  label={<span>{t('model.two_factor_authentication')}
                                    <Tooltip placement='top' title={<span>{t('model.two_factor_authentication_explanation')}
                                      <a href={NAV_EXTERNAL.TWO_FACTOR_LINK} target="blank"
                                        className="two-factor-authentication-link">
                                        {t('model.read_more_here')}
                                      </a>
                                    </span>} className='two-factor-authentication-question'><QuestionCircleOutlined />
                                    </ Tooltip></span>}
                                  name="use_mfa"
                                  valuePropName="checked">
                                  <Switch defaultChecked onChange={() => {}} />
                                </Form.Item>

                                <Divider />
                                <div className="text-right">
                                  <Button
                                    htmlType="submit"
                                    data-cy="save-user-profile"
                                    type="primary"
                                    icon={<CheckOutlined />}
                                  >
                                    { t('global.save') }
                                  </Button>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    : null
                  }
                </div>
              </Col>
            </Row>
            <DetailsPanel>
              <WorkspaceDetails />
            </DetailsPanel>
          </React.Fragment>
        )}
      </Frame>
    )
  }
}

export default compose(
  withTranslation(),
  withAuth0,
  withUseAuth0Api
)(UserProfile)
