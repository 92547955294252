import { Button, Card } from 'antd'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { handleModal, returnNested, safeArray } from '../../../utils/tools'
import { NAV } from '../../../utils/const'

function LeaveAccount(props) {
  const {
    t,
    leaveAccountSaga,
    selectedAccountId,
    listOfUserAccounts
  } = props
  const navigate = useNavigate()

  const handleOkLeaveAccountModal = () => {
    const filteredAccountList = safeArray(listOfUserAccounts).filter(el => el.id !== selectedAccountId)
    const nextAccountId = returnNested(filteredAccountList, 0, 'id')
    leaveAccountSaga(selectedAccountId, nextAccountId, navigate(nextAccountId ? `${NAV.ACCOUNT}${nextAccountId}` : `${NAV.ROOT}`))
  }

  const leaveAccountHandler = () => {
    handleModal({
      title: t('account_settings.leave_account'),
      content: t('account_settings.leave_account_confirmation'),
      onOk: handleOkLeaveAccountModal
    })
  }

  return (
    <Card
      type="inner"
      className="card-item card-title-big leave-account leave-account-card"
      title={ t('account_settings.leave_account')}
      extra={<Button data-cy="leave-account-button" onClick={ leaveAccountHandler } type="danger" className="button danger">
        {t('account_settings.leave_account')}
      </Button>}
    >
      <div>{ t('account_settings.leaving_account_description') }</div>
    </Card>
  )
}

export default compose(
  withTranslation()
)(LeaveAccount)
