export const
  ADD_ERROR_NOTIFICATION = 'ADD_ERROR_NOTIFICATION',
  ADD_SUCCESS_NOTIFICATION = 'ADD_SUCCESS_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

export const addErrorNotificationAction = message => ({
  type: ADD_ERROR_NOTIFICATION,
  message
})
export const addSuccessNotificationAction = message => ({
  type: ADD_SUCCESS_NOTIFICATION,
  message
})
export const clearNotificationsAction = () => ({
  type: CLEAR_NOTIFICATIONS,
})
