import { connect } from 'react-redux'
import AccountSelector from '../../redux/selectors/account.selector'
import UserSelector from '../../redux/selectors/user.selector'
import { renameAccountSaga, leaveAccountSaga, loadAccountSettingsSaga } from '../../redux/sagas/account.saga'
import { trackPageSaga } from '../../redux/sagas/tracking.saga'
import FlagsSelector from '../../redux/selectors/flags.selector'
import AccountManagement from './accountManagement'

const mapStateToProps = state => ({
  selectedAccountId: AccountSelector.selectedAccountId(state),
  selectedAccountName: AccountSelector.selectedAccountName(state),
  accountRolesList: AccountSelector.accountRolesList(state),
  selectedUserId: UserSelector.selectedUserId(state),
  isLoadingSettingCompleted: FlagsSelector.isLoadingSettingCompleted(state)
})

const mapDispatchToProps = {
  renameAccountSaga,
  trackPageSaga,
  leaveAccountSaga,
  loadAccountSettingsSaga
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountManagement)
