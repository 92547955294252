import { Facet, FacetValue, FilterDatasetType } from '../../../__generated__/graphql'
import { TImpactDatasetContext } from '../interface/impact-dataset.context'

type CountsDictionary = {
  [key: string]: number
}
export const getFacetsCountsDictionary = (facet: Facet): CountsDictionary => facet.values.reduce((acc, value) => ({
  ...acc,
  [value.name]: value.count
}), {})

export const getFacetsLists = (facets: Facet[] = []) => {
  const facetsLists: Partial<TImpactDatasetContext> = {}

  facets.forEach(facet => {
    switch (facet.name) {
    case FilterDatasetType.Database:
      facetsLists.databaseList = facet.values
      break
    case FilterDatasetType.FlowProperty:
      facetsLists.propertyList = facet.values
      break
    case FilterDatasetType.Location: {
      const sorted = [ ...facet.values ].sort((a, b) => a.name.localeCompare(b.name))
      facetsLists.locationList = sorted
      break
    }
    }
  })

  return facetsLists
}

const updateCountsInList = (list: FacetValue[], newCounts: CountsDictionary) => list.map(item => ({
  ...item,
  count: newCounts[item.name] || 0
}))

export const updateFacetsListsCounts = (
  facets: Facet[] = [],
  databaseList: FacetValue[] = [],
  locationList: FacetValue[] = [],
  propertyList: FacetValue[] = []
) => {
  const facetsLists: Partial<TImpactDatasetContext> = {}

  facets.forEach(facet => {
    const newCounts = getFacetsCountsDictionary(facet)
    if (facet.name === FilterDatasetType.Database) {
      facetsLists.databaseList = updateCountsInList(databaseList, newCounts)
    }
    if (facet.name === FilterDatasetType.Location) {
      facetsLists.locationList = updateCountsInList(locationList, newCounts)
    }
    if (facet.name === FilterDatasetType.FlowProperty) {
      const countList = updateCountsInList(propertyList, newCounts)
      facetsLists.propertyList = countList
    }
  })
  return facetsLists
}
