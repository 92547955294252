export enum MenuAction {
  MoveProductToFolder = 'MoveProductToFolder',
  MoveProductToObjects = 'MoveProductToObjects',
  CompareProducts = 'CompareProducts',
  OpenProductDetail = 'OpenProductDetail',
  CreateScenario = 'CreateScenario',
  AddToFavorite = 'AddToFavorite',
  DeleteProduct = 'DeleteProduct',
  MoveFolderToFolder = 'MoveFolderToFolder',
  EditFolder = 'EditFolder',
  DeleteFolder = 'DeleteFolder',
  MoveObjectToProduct = 'MoveObjectToProduct',
  DeleteTreeItem = 'DeleteTreeItem',
  OpenTreeItem = 'OpenTreeItem',
  AddTreeItem = 'AddTreeItem'
}
