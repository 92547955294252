import { ImpactEffect } from './const'

export const percentage = (amount, whole) => {
  const fAmount = parseFloat(amount)
  const fWhole = parseFloat(whole)
  if (Number.isNaN(fAmount) || Number.isNaN(fWhole)) return undefined
  if (fWhole === 0) return undefined
  return fAmount / fWhole * 100
}

export const maxByProp = (list, prop, strict = true) => {
  if (!Array.isArray(list) || list.length === 0) return null
  let amounts = list.map(item => parseFloat(item[prop]))
  if (strict && amounts.includes(NaN)) { return null }
  amounts = amounts.filter(amount => !Number.isNaN(amount))
  return amounts.length === 0
    ? null
    : Math.max(...amounts)
}

export const getImpactEffect = (impactA, impactB) => {
  const amountA = parseFloat(impactA)
  const amountB = parseFloat(impactB)
  if (
    (isNaN(amountA) || isNaN(amountB)) ||
    amountA === amountB
  ) {
    return ImpactEffect.NONE
  }
  return amountA > amountB
    ? ImpactEffect.POSITIVE
    : ImpactEffect.NEGATIVE
}

export const getImpactPercentage = (impactAmountA, impactAmountB) => {
  const finalValue = parseFloat(impactAmountA)
  const startingValue = parseFloat(impactAmountB)
  const percentage = ((finalValue - startingValue) / startingValue ) * 100
  if (isNaN(percentage)) return undefined
  if (Math.abs(percentage) < 1) return parseFloat(percentage.toFixed(2))
  return Math.round(percentage)
}
