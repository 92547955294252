import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Avatar, Button, Form, notification, Select, Table, Tooltip, Spin } from 'antd'
import React from 'react'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { TRACK_EVENT_TYPE, VALIDATION } from '../../utils/const'
import resource from '../../utils/resource/resource'
import { handleModal, normalize, notify, returnNested, safeArray, filterOptionByValue, parseGraphQLErrorsForNoSagaQueries } from '../../utils/tools'
import { ClockCircleOutlined } from '@ant-design/icons'
import InputWrapper from '../helpers/form/inputWrapper'
import SelectWrapper from '../helpers/form/selectWrapper'

class UserManagement extends React.Component {
  state = {
    newInviteRoles: [],
    isAddingNewAccountUser: false,
    newUserName: null,
    newUserEmail: null,
    mappedAccountUsersList: []
  }

  componentDidMount() {
    this.handleUpdateUsersLists()
    this.props.trackPageSaga(TRACK_EVENT_TYPE.VIEWED_USERS_SETTINGS)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.accountUsersList && this.props.accountUsersList) {
      this.handleUpdateUsersLists()
    }
    if (prevProps.invitedUsersList !== this.props.invitedUsersList) {
      this.handleUpdateUsersLists()
    }
  }

  updateInvitedUsersList() {
    const { invitedUsersList } = this.props
    this.setState({ invitedUsersList })
  }

  handleAdd = () => {
    this.setState({ isAddingNewAccountUser: true }, this.handleUpdateUsersLists)
  }

  getInvitedUser(name, email, rolesIdList) {
    return {
      isInvited: true,
      name,
      email,
      roles: this.props.accountRolesList.filter(role => rolesIdList.includes(role.id))
    }
  }

  handleAddUser = () => {
    const { inviteUserSaga, trackEventSaga, accountId } = this.props

    this.formRef.current.validateFields([ 'newUserName', 'newUserEmail', 'newUserRoles' ]).then(values => {
      inviteUserSaga(accountId, values.newUserEmail.toLowerCase(), values.newUserName, values.newUserRoles, this.handleCancel )
      this.formRef.current.resetFields()
    }).catch( ()=>{} ) // It should be caught by ANT Form promise but it doesn't happen when fields are specified in validateFields
    trackEventSaga(TRACK_EVENT_TYPE.INVITED_USER)
  }

  handleUpdateUsersLists() {
    this.updateMappedAccountUsersList()
    this.updateInvitedUsersList()
  }

  handleCancel = () => {
    this.formRef.current.resetFields([ 'newUserName', 'newUserEmail', 'newUserRoles' ])
    this.setState({ isAddingNewAccountUser: false }, this.handleUpdateUsersLists)
  }

  handleInviteRoles = roleId => {
    this.setState({ newInviteRoles: roleId })
  }

  handleUnAssignRole = (roleId, user) => {
    const { removeAccountUserFromRole, t, accountId, removeInvitationRoleSaga } = this.props
    if (user.isInvited) {
      removeInvitationRoleSaga(accountId, user.email, roleId, () => {
        this.handleUpdateUsersLists()
      })
    } else {
      !user.editable && resource.mutateByParams('unassignUserFromRole', { roleID: roleId, userID: returnNested(user, 'id') })
        .then(() => {
          removeAccountUserFromRole(roleId, user.id)
          this.handleUpdateUsersLists()
        }).catch(error => {
          notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t })
        })
    }
  }

  handleAssignRole = (roleId, user) => {
    const { addAccountUserToRole, t, addInvitationRoleSaga, accountId } = this.props
    const selectedRole = this.props.accountRolesList.find(role => role.id === roleId)
    if (user.isInvited) {
      addInvitationRoleSaga(accountId, user.email, selectedRole, () => {
        this.handleUpdateUsersLists()
      })
    } else {
      !user.editable && resource.mutateByParams('assignUserToRole', { roleID: roleId, userID: returnNested(user, 'id') })
        .then(() => {
          addAccountUserToRole(roleId, user.id, user.name)
          this.handleUpdateUsersLists()
        }).catch(error => notify({ message: parseGraphQLErrorsForNoSagaQueries(error), notification, t }))
    }

  }

  removeInvitedUser(userEmail) {
    const { removeInvitationSaga, accountId, t } = this.props
    handleModal({
      title: t('account_settings.remove_user'),
      content: t('account_settings.confirm_remove_user'),
      onOk: () => {
        removeInvitationSaga(accountId, userEmail, () => {
          this.handleUpdateUsersLists()
        })
      }
    })
  }

  removeRegularUser(userId) {
    const { removeUserFromAccountSaga, accountId, trackEventSaga, t } = this.props
    handleModal({
      title: t('account_settings.remove_user'),
      content: t('account_settings.confirm_remove_user'),
      onOk: () => {
        removeUserFromAccountSaga(accountId, userId, () => {
          this.handleUpdateUsersLists()
        })
        trackEventSaga(TRACK_EVENT_TYPE.REMOVED_USER)
      }
    })

  }

  handleUserRemove = user => {
    if (user.isInvited) {
      this.removeInvitedUser(user.email)
    } else {
      this.removeRegularUser(user.id)
    }
  }

  getUsersTableColumns = () => {
    const { accountRolesList, t } = this.props
    const roleList = accountRolesList && accountRolesList.map(role =>
      (<Select.Option key={role.id} value={role.id}>{role.label}</Select.Option>))
    const userColumns = [
      { title: '', dataIndex: 'email', key: 'avatar', width: '30px', render: (text, record) => {
        if (!record.editable) {
          return returnNested(record, 'isInvited') ?
            <ClockCircleOutlined  className='invited-user-icon'/> :
            <Avatar className="user-avatar" size={25} src={returnNested(record, 'picture')}/>
        } else {
          return null
        }
      } },
      { title: t('account_settings.user_name'), dataIndex: 'name', key: 'name', render: (text, record) => {
        return record.editable ?
          <InputWrapper
            isFieldEditable={true}
            autoFocus={true}
            dataCy='new_user_name'
            placeholder={t('account_settings.user_name')}
            editHandler={this.handleNewUserName}
            fieldName= "newUserName"
            fieldRules= {[
              { min: VALIDATION.USER_NAME_LENGTH, message: t('validation.user_name_length', { length: VALIDATION.USER_NAME_LENGTH }) },
              { required: true, message: t('validation.is_required', { name: t('user_profile.user_name') }) }
            ]}
            onPressEscape={ this.handleCancel }
          />
          : <span data-cy="user-row" id={record.id}>{text}</span>
      } },
      { title: t('account_settings.email'), dataIndex: 'email', key: 'email', render: (text, record) => {
        return record.editable ?
          <InputWrapper
            isFieldEditable={true}
            autoFocus={false}
            dataCy='new_email'
            placeholder={t('account_settings.email')}
            editHandler={this.handleNewUserEmail}
            fieldName= "newUserEmail"
            fieldRules= {[
              { type: 'email', message: t('validation.invalid_email') },
              { required: true, message: t('validation.is_required', { name: t('user_profile.user_name') }) }
            ]}
            onPressEscape={ this.handleCancel }
          />
          :
          text
      } },
      { title: t('account_settings.roles'), key: 'roles', dataIndex: 'roles', width: '40%', render: (roles, user) => {
        const userValue = safeArray(returnNested(user, 'roles')).map(el => el.id)
        return (
          accountRolesList &&
          <div className='select-wrapper'><SelectWrapper
            autoFocus={false}
            mode='multiple'
            isFieldEditable={true}
            fieldName={user.editable ? 'newUserRoles' : `userRoles${normalize(user.name)}`}
            fieldInitialValue={user.editable ? [] : userValue}
            fieldRules= {[
              { required: true, message: t('validation.missing_roles') }
            ]}
            showSearch
            placeholder={t('account_settings.roles')}
            filterOption={(inputValue, option) => filterOptionByValue(inputValue, option)}
            dataCy={user.editable ? 'roles_selector_on' : 'roles_selector_off'}
            dropdownMatchSelectWidth={false}
            getPopupContainer={ node => node.parentElement }
            onSelect={roleId => this.handleAssignRole(roleId, user)}
            onDeselect={roleId => this.handleUnAssignRole(roleId, user)}
          >
            {roleList}
          </SelectWrapper></div>
        )
      } },
      { title: t('account_settings.action'), align: 'center', className: 'action', key: 'action', width: '30px', render: (text, user) => {
        return user.editable ?
          <React.Fragment>
            <Button type="button" className="button icon transparent" onClick={this.handleAddUser} data-cy="add_user"><CheckOutlined /></Button>
            <Button type="button" className="button icon transparent" onClick={this.handleCancel}><CloseOutlined /></Button>
          </React.Fragment>
          :
          <Tooltip title={t('account_settings.remove_user')} placement="bottom">
            <Button type="button"
              onClick={() => this.handleUserRemove(user)}
              className="button icon transparent"
              data-cy={`remove-user-${normalize(user.name)}`}
            ><DeleteOutlined /></Button>
          </Tooltip>
      } },
      { title: t('account_settings.status'), dataIndex: 'isInvitedUser', key: 'isInvitedUser', width: '30px', render: (text, user) => {
        return user.isInvited ? t('account_settings.invitation_pending') : t('account_settings.has_site_access')
      } },
    ]
    return userColumns
  }

  updateMappedAccountUsersList = () => {
    const { accountUsersList } = this.props
    const { isAddingNewAccountUser } = this.state
    const mappedAccountUsersList = safeArray(accountUsersList).map(user => {
      user.key = user.id
      return user
    })

    if (isAddingNewAccountUser) {
      mappedAccountUsersList.unshift({ key: 0, id: '', email: '', name: '', expires: '', roles: [], editable: true })
    }

    this.setState({ mappedAccountUsersList })
  }

  getInviteUserButton() {
    return <Button
      disabled={this.state.isAddingNewAccountUser}
      type="primary"
      className="button primary"
      onClick={this.handleAdd}
      icon={<PlusOutlined />}>
      {this.props.t('account_settings.invite')}
    </Button>
  }

  mapUserListWithIsInvitedFlag(list, isInvited) {
    return list.map(el => {
      el.isInvited = isInvited
      return el
    })
  }

  getOverallDataUserList() {
    const { mappedAccountUsersList, invitedUsersList } = this.state
    return [
      ...this.mapUserListWithIsInvitedFlag(safeArray(mappedAccountUsersList), false),
      ...this.mapUserListWithIsInvitedFlag(safeArray(invitedUsersList), true)
    ]
  }

  formRef = React.createRef()

  render() {
    const overallDataUserList = this.getOverallDataUserList()
    return (
      <div className="user-management">
        <Spin
          spinning={this.props.isDataViaNetworkRequesting}
          indicator={<LoadingOutlined className='default-loader-color' style={ { fontSize: 24 } } spin/>}
        >
          {overallDataUserList &&
          <Form
            ref={this.formRef}
            layout="horizontal"
            hideRequiredMark
            className="field-container"
          >
            <Table
              title={() => this.getInviteUserButton()}
              rowKey={(record, index) => `${index}:${record.id}`}
              dataSource={overallDataUserList}
              className="table-container"
              pagination={ false }
              columns={this.getUsersTableColumns()}
              data-cy="user-table"
            />
          </Form>}
        </Spin>
      </div>
    )
  }
}

export { UserManagement }
export default compose(
  withTranslation(),
)(UserManagement)
