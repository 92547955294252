import React, { useContext } from 'react'
import { ITransformedLeafItem } from '../../../product/interface/transformed-leaf-item'
import { PdfDoughnutComponent } from '../../../shared/component/general/pdf-doughnut.component'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'

export const LifecyclePdfDoughnutComponent = () => {
  const {
    lifecycleDetail: { name, unit, description } = {},
    lifecycleTotalImpact = {},
    transformedLifecycleLeafItems = [],
    updateLifecycleDetail,
    showPdfInsightsResult,
    loadingLifecycleLeafItems
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)

  const entity = {
    name, unit, description
  }

  const transformedLeafItems = transformedLifecycleLeafItems.reduce((list, item) => {
    const items = item.transformedLeafItems || []
    return list.concat(items)
  }, [] as ITransformedLeafItem[])

  return (
    <>
      { !loadingLifecycleLeafItems && showPdfInsightsResult && (
        <PdfDoughnutComponent
          entity={entity}
          totalImpact={lifecycleTotalImpact}
          transformedLeafItems={transformedLeafItems}
          afterPdfDoughnutDownload={() => updateLifecycleDetail({ showPdfInsightsResult: false })}
        />
      )}
    </>
  )
}
