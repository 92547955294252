import { fromJS } from 'immutable'
import { createReducer } from '../../utils/reducerTools'
import { isEmpty, returnNested, safeArray } from '../../utils/tools'
import {
  REMOVE_LIFECYCLE,
  REMOVE_PHASE,
  RENAME_LIFECYCLE,
  RENAME_PHASE,
  SET_PHASE_TYPE,
  REORDER_PHASES,
  SET_FLAT_VIEW,
  SET_SELECTED_LIFECYCLE,
  SET_SELECTED_PHASE,
  SET_LIFECYCLE_DESCRIPTION,
  SET_LIFECYCLE_AMOUNT,
  SET_LIFECYCLE_UNIT,
  SET_DETAILS_PANEL_LIFECYCLE_ID,
  SET_LIFECYCLE_SCENARIO,
  RENAME_LIFECYCLE_SCENARIO,
  RENAME_LIFECYCLES_PAGINATED,
  SET_DESCRIPTION_FOR_LIFECYCLES_PAGINATED_ITEM,
  SET_LIFECYCLE_SCENARIO_DESCRIPTION,
  SET_LIFECYCLE_SCENARIO_AMOUNT,
  SET_LIFECYCLE_SCENARIO_UNIT,
  SET_LIFECYCLES_PAGINATED,
  REMOVE_LIFECYCLES_PAGINATED,
  SET_LIFECYCLE_PRODUCT,
  SET_LIFECYCLE_SCENARIO_PRODUCT
} from '../actions/lifecycle.actions'
import {
  CLEAR_GLOBAL_STATE_EXCEPT_USER,
  CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT,
  CLEAR_INVENTORY_TREE_STATE,
  CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE
} from '../actions/clear.actions'

const defaultLifecycleState = {
  selectedLifecycle: null,
  lifecycleScenario: null,
  selectedPhase: null,
  flatView: null,
  detailsPanelLifecycleId: null,
  lifecyclesPaginated: null
}

const renameLifecycle = (lifecycle, action) => ({
  ...lifecycle,
  name: action.lifecycleName,
  product: {
    ...lifecycle.product,
    lifecycles: {
      ...safeArray(returnNested(lifecycle, 'product', 'lifecycles')).map(el => {
        if (el.id === action.lifecycleId) {
          el.name = action.lifecycleName
        }
        return el
      })
    }
  }
})
const setLifecycleDescription = (lifecycle, action) => ({
  ...lifecycle,
  description: action.lifecycleDescription
})
const setLifecycleAmount = (lifecycle, action) => ({
  ...lifecycle,
  amount: action.lifecycleAmount
})
const setLifecycleUnit = (lifecycle, action) => ({
  ...lifecycle,
  unit: action.lifecycleUnit
})
const setLifecycleProduct = (lifecycle, action) => ({
  ...lifecycle,
  product: action.lifecycleProduct
})

const renamePhaseInLifecycle = (lifecycle, action) => {
  if (isEmpty(lifecycle)) return null
  return ({
    ...lifecycle,
    phases: safeArray(returnNested(lifecycle, 'phases'))
      .map(phase => phase.id === action.id
        ? { ...phase, name: action.name }
        : phase
      )
  })
}
const changePhaseTypeInLifecycle = (lifecycle, action) => {
  if (isEmpty(lifecycle)) return null
  return ({
    ...lifecycle,
    phases: safeArray(returnNested(lifecycle, 'phases'))
      .map(phase => phase.id === action.id
        ? { ...phase, type: action.phaseType }
        : phase
      )
  })
}
const removePhaseInLifecycle = (lifecycle, action) => {
  if (isEmpty(lifecycle)) return null
  return ({
    ...lifecycle,
    phases: safeArray(returnNested(lifecycle, 'phases'))
      .filter(phase => phase.id !== action.id)
  })
}
const reorderPhasesInLifecycle = (lifecycle, action) => {
  if (isEmpty(lifecycle)) return null
  const safePhases = safeArray(returnNested(lifecycle, 'phases'))
  const reorderedPhases = []
  for (const phaseId of safeArray(action.phaseIds)) {
    const foundPhaseInLifecycle = safePhases.find(phase => phase.id === phaseId)
    if (foundPhaseInLifecycle) {
      reorderedPhases.push(foundPhaseInLifecycle)
    }
  }

  return {
    ...lifecycle,
    phases: safePhases.length === reorderedPhases.length ? reorderedPhases : safePhases
  }
}
const renameLifecyclePaginated = (lifecyclesPaginated, action) => {
  const lifecyclesPaginatedItemsForUpdate = safeArray(returnNested(lifecyclesPaginated, 'items'))

  return {
    ...lifecyclesPaginated,
    items: lifecyclesPaginatedItemsForUpdate.map(el => {
      if (el.id === action.lifecycleId) {
        el.name = action.lifecycleName
      }
      return el
    })
  }
}

const setDescriptionForPaginatedItem = (lifecyclesPaginated, action) => {
  return {
    ...lifecyclesPaginated,
    items: safeArray(returnNested(lifecyclesPaginated, 'items')).map(el => {
      if (el.id === action.lifecycleId) {
        el.description = action.lifecycleDescription
      }
      return el
    })
  }
}

const removeLifecyclePaginated = (lifecyclesPaginated, action) => {
  const lifecyclesPaginatedItemsForUpdate = safeArray(returnNested(lifecyclesPaginated, 'items'))
  return {
    ...lifecyclesPaginated,
    items: lifecyclesPaginatedItemsForUpdate.filter(el => el.id !== action.id)
  }
}

const lifecycleReducer = createReducer(defaultLifecycleState, {
  [CLEAR_GLOBAL_STATE_EXCEPT_USER]: () => ({ ...defaultLifecycleState }),
  [CLEAR_GLOBAL_STATE_EXCEPT_ACCOUNT]: () => ({ ...defaultLifecycleState }),
  [SET_LIFECYCLES_PAGINATED]: (state, action) => ({ ...state, lifecyclesPaginated: action.lifecyclesPaginated }),
  [REMOVE_LIFECYCLES_PAGINATED]: (state, action) => ({
    ...state,
    lifecyclesPaginated: removeLifecyclePaginated(state.lifecyclesPaginated, action)
  }),
  [SET_SELECTED_LIFECYCLE]: (state, action) => ({ ...state, selectedLifecycle: action.lifecycle }),
  [SET_LIFECYCLE_SCENARIO]: (state, action) => ({ ...state, lifecycleScenario: action.lifecycle }),
  [SET_SELECTED_PHASE]: (state, action) => ({ ...state, selectedPhase: action.phase }),
  [SET_FLAT_VIEW]: (state, action) => ({ ...state, flatView: action.flatView }),
  [SET_DETAILS_PANEL_LIFECYCLE_ID]: (state, action) => ({ ...state, detailsPanelLifecycleId: action.lifecycleId }),
  [RENAME_LIFECYCLE]: (state, action) => ({
    ...state,
    selectedLifecycle: renameLifecycle(state.selectedLifecycle, action)
  }),
  [RENAME_LIFECYCLE_SCENARIO]: (state, action) => ({
    ...state,
    lifecycleScenario: renameLifecycle(state.lifecycleScenario, action)
  }),
  [RENAME_LIFECYCLES_PAGINATED]: (state, action) => ({
    ...state,
    lifecyclesPaginated: renameLifecyclePaginated(state.lifecyclesPaginated, action)
  }),
  [SET_DESCRIPTION_FOR_LIFECYCLES_PAGINATED_ITEM]: (state, action) => ({
    ...state,
    lifecyclesPaginated: setDescriptionForPaginatedItem(state.lifecyclesPaginated, action)
  }),
  [RENAME_PHASE]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: renamePhaseInLifecycle(state.selectedLifecycle, action),
      lifecycleScenario: renamePhaseInLifecycle(state.lifecycleScenario, action),
      selectedPhase: {
        ...state.selectedPhase,
        name: action.name
      }
    }
  },
  [SET_PHASE_TYPE]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: changePhaseTypeInLifecycle(state.selectedLifecycle, action),
      lifecycleScenario: changePhaseTypeInLifecycle(state.lifecycleScenario, action),
      selectedPhase: {
        ...state.selectedPhase,
        type: action.phaseType
      }
    }
  },
  [REMOVE_PHASE]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: removePhaseInLifecycle(state.selectedLifecycle, action),
      lifecycleScenario: removePhaseInLifecycle(state.lifecycleScenario, action),
      selectedPhase: null
    }
  },
  [REORDER_PHASES]: (state, action) => {
    const oldFlatView = fromJS(state).get('flatView')
    const newFlatView = oldFlatView && oldFlatView.update('items', items => {
      return items.map(item => {
        return item.set('phaseOrder', safeArray(action.phaseIds).findIndex(phaseId => phaseId === item.get('phaseId')))
      })
    })

    return {
      ...state,
      selectedLifecycle: reorderPhasesInLifecycle(state.selectedLifecycle, action),
      lifecycleScenario: reorderPhasesInLifecycle(state.lifecycleScenario, action),
      flatView: newFlatView ? newFlatView.toJS() : null
    }
  },
  [REMOVE_LIFECYCLE]: (state, action) => {
    return returnNested(state.selectedLifecycle, 'id') === action.id
      ? {
        ...state,
        selectedLifecycle: null,
        selectedPhase: null
      }
      : state
  },
  [SET_LIFECYCLE_DESCRIPTION]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: setLifecycleDescription(state.selectedLifecycle, action),
    }
  },
  [SET_LIFECYCLE_SCENARIO_DESCRIPTION]: (state, action) => {
    return {
      ...state,
      lifecycleScenario: setLifecycleDescription(state.lifecycleScenario, action),
    }
  },
  [SET_LIFECYCLE_AMOUNT]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: setLifecycleAmount(state.selectedLifecycle, action),
    }
  },
  [SET_LIFECYCLE_SCENARIO_AMOUNT]: (state, action) => {
    return {
      ...state,
      lifecycleScenario: setLifecycleAmount(state.lifecycleScenario, action),
    }
  },
  [SET_LIFECYCLE_UNIT]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: setLifecycleUnit(state.selectedLifecycle, action),
    }
  },
  [SET_LIFECYCLE_SCENARIO_UNIT]: (state, action) => {
    return {
      ...state,
      lifecycleScenario: setLifecycleUnit(state.lifecycleScenario, action),
    }
  },
  [SET_LIFECYCLE_PRODUCT]: (state, action) => {
    return {
      ...state,
      selectedLifecycle: setLifecycleProduct(state.selectedLifecycle, action),
    }
  },
  [SET_LIFECYCLE_SCENARIO_PRODUCT]: (state, action) => {
    return {
      ...state,
      lifecycleScenario: setLifecycleProduct(state.lifecycleScenario, action),
    }
  },
  [CLEAR_INVENTORY_TREE_STATE]: state => (state),
  [CLEAR_UI_WHEN_DETAIL_PANEL_CLOSE]: state => ({
    ...state,
    selectedPhase: null,
    detailsPanelLifecycleId: null
  })
})
export default lifecycleReducer

