import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { AnalysisProvider } from './provider/analysis.provider'
import { Frame } from '../../component/layout'
import { AnalysisMode, AnalysisType } from './enum/analysis'

import { useUrlQuery } from '../shared/hook/use-url-query'
import { ProductAnalysisLayout } from './layout/product-analysis.layout'
import { ProductDetailContainer } from '../product/container/product-detail.container'
import { ProductDetailProvider } from '../product/provider/product-detail.provider'
import { styledComponentTheme } from '../shared/constants/theme'

import { enableNewScenarioV1Var } from '../../graphql/cache'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { store } from '../../configureStore'
import { setIsDetailPanelReadonlyV1Action, setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'
import { useIsMounted } from '../shared/hook/use-is-mounted'

type TProductAnalysisEntryProps = {
  analysisMode?: AnalysisMode
}
export const ProductAnalysisEntry = ({
  analysisMode = AnalysisMode.Comparison
}: TProductAnalysisEntryProps) => {
  const urlQuery = useUrlQuery()
  const isMounted = useIsMounted()
  const { productId: primaryEntityId } = useParams() || {}
  const analysisInScenario = analysisMode === AnalysisMode.Scenario

  useEffect(() => {
    if (isMounted()) {
      // TODO : Added for compatibility with v0.9
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }

    return () => {
      store.dispatch(setIsDetailPanelReadonlyV1Action(false))
    }
  }, [ isMounted ])

  const secondaryEntityId = urlQuery.get('id') || ''
  const secondaryEntityType = urlQuery.get('type') || ''

  enableNewScenarioV1Var(analysisInScenario)

  return (
    <AnalysisProvider {...{
      primaryEntityId,
      secondaryEntityId,
      secondaryEntityType,
      analysisMode,
      analysisType: AnalysisType.ProductToProduct
    }}
    >
      <ProductDetailProvider
        readonlyInventory
        noInventoryImpactBar
        showInventoryHeader={false}
        analysisMode={analysisMode}
      >
        <ThemeProvider theme={styledComponentTheme}>
          <Frame>
            { () => primaryEntityId && secondaryEntityId
        && (
          <ProductDetailContainer productId={primaryEntityId}>
            <ProductAnalysisLayout />
          </ProductDetailContainer>
        ) }
          </Frame>
        </ThemeProvider>
      </ProductDetailProvider>
    </AnalysisProvider>
  )
}
