import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'primereact/image'

export const PngFooterTemplateComponent = () => {
  const { t } = useTranslation([ 'common' ])

  return (
    <div data-testid="png-footer-wrapper" className="hidden w-full pt-6 png-footer-template h-5rem bg-white png-export-wrapper">
      <div className="flex flex-none justify-content-start align-items-center mr-2">
        <Image src="/assets/images/ecochain_main_logo.svg" alt="Ecochain logo" width="120" />
      </div>
      <div className="flex flex-grow-1 flex-column text-lg font-semibold">
        <div className="flex w-full justify-content-end text-xs text-right line-height-1 font-normal">
          { t('labels.footerLabel', { context: 'pngExport' }) }
        </div>
        <div className="flex w-full justify-content-end text-xs text-right text-green-500 line-height-1 font-normal">
          ecochain.com/mobius
        </div>
      </div>
    </div>
  )
}
