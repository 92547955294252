import React, { useContext, useRef } from 'react'
import styled from 'styled-components'

import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { TreeComponent } from '../component/tree/tree.component'
import { TreeFooterComponent } from '../component/tree/tree-footer.component'
import { AddSubItemNodeComponent } from '../component/tree/tree-add-subitem-node.component'
import { TreePanelHeaderComponent } from '../component/tree/tree-panel-header.component'
import { PngFooterTemplateComponent } from '../component/chart/png-footer-template.component'
import { PngHeaderTemplateComponent } from '../component/chart/png-header-template.component'
import { AddImpactNodeComponent } from '../component/tree/tree-add-impact-node.component'
import { useTransformTree } from '../../shared/hook/use-transform-tree'
import { ProductLabel } from '../../shared/enum'

const TreeWrapper = styled.div`
  .tree-panel-shadow {
    box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);
  }

  .tree-panel-footer {
    box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);
  }

  .tree-panel-footer-shadow {
    box-shadow: 0px -2px 25px 0px rgba(0, 0, 0, 0.05);
  }
`

const TreeWrapperLayout = () => {
  const {
    productInventory: { product } = {},
    totalImpact = null,
    inventoryItems = [],
    expandedKeys = []
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const inventoryTree = useTransformTree({
    expandedKeys,
    inventoryItems,
    rootInventoryItem: { inventoryItem: { amount: 1, impact: { ...totalImpact }, product } }
  })
  const treeRef = useRef<HTMLDivElement>(null)

  const thereIsSomeCustomImpact = product?.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const thereIsDirectProductImpact = !!product?.referenceProduct || thereIsSomeCustomImpact
  const thereAreSomeItems = inventoryItems?.length > 0
  const showImpact = !thereAreSomeItems && thereIsDirectProductImpact

  const impactItem = {
    key: 'node:0',
    expanded: true,
    children: [],
    data: {
      ancestors: [],
      inventoryItem: {
        amount: 1,
        impact: totalImpact,
        product,
      }
    }
  }

  const [ productLabel ] = product?.labels || []
  const isObject = productLabel?.id === ProductLabel.Object

  return (
    <>
      <TreeWrapper className="flex flex-column border-round-lg bg-white w-full tree-panel tree-panel-shadow" ref={treeRef}>
        <PngHeaderTemplateComponent />
        <div className="flex-none flex p-5 pb-2 h-4rem w-full" data-html2canvas-ignore>
          { product && <TreePanelHeaderComponent treeRef={treeRef} /> }
        </div>
        <div className="flex flex-column flex-grow-1 w-full h-full">
          <TreeComponent
            inventoryTree={showImpact ? [ impactItem ] : inventoryTree[0].children}
            showImpact={showImpact}
          />
          <div className="flex-none w-full mb-5 px-5" data-html2canvas-ignore>
            {(!thereIsDirectProductImpact || thereAreSomeItems)
            && <div className="flex w-full p-1"><AddSubItemNodeComponent /></div>}
            {isObject && !thereAreSomeItems && !thereIsDirectProductImpact && (
              <>
                <div className="m-1 ml-2 text-base text-gray-500">or</div>
                <AddImpactNodeComponent />
              </>
            )}
          </div>
        </div>
        <div className="flex-none flex py-3 px-5 h-4rem w-full tree-panel-footer tree-panel-footer-shadow">
          { product && <TreeFooterComponent classNamePrefix="ap-tree-footer-static" />}
        </div>
        <PngFooterTemplateComponent />
      </TreeWrapper>
    </>
  )
}

export default TreeWrapperLayout
