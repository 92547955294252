import { gql } from '@apollo/client'
import extendedProduct from '../fragment/extendedProduct'

export default gql`
  mutation changeInventoryItemAmount($inventoryItemID: ID!, $inventoryItemAmount: String!, $targetID: String!, $targetAmount: String!) {
    changeInventoryItemAmount(inventoryItemID: $inventoryItemID, inventoryItemAmount: $inventoryItemAmount, targetID: $targetID, targetAmount: $targetAmount){
        ...extendedProductFragment
    }
  }
  ${extendedProduct}
`
