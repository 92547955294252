import { libphonePhoneByCountyValidator } from './libphoneValidators'
/**
 * Validate phone number format by country.
 *
 * @param {String} phone phone number.
 * @param {String} country country code.
 *
 */
export const validatePhoneByCountry = (phone, country) => {
  if (!phone) return { isValid: false, errMessage: 'err.VALUE_IS_EMPTY' }
  if (!country) return { isValid: false, errMessage: 'validation.select_country' }
  if (!libphonePhoneByCountyValidator(phone, country)) return { isValid: false, errMessage: 'validation.invalid_phone' }
  return { isValid: true, errMessage: null }
}
