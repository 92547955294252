import React from 'react'
import compose from '../../../utils/compose'
import { withTranslation } from 'react-i18next'
import { SUBSCRIPTION_STATUS } from '../../../utils/const'
import { isEmpty, returnNested } from '../../../utils/tools'
// import Coupon from '../coupon.container'
import Customer from './customer.container'
import InvoiceList from './invoiceList.container'
import CancelSubscription from './subscription-details/cancelSubscription.container'
import PaymentMethod from './subscription-details/paymentMethod.container'
import SubscriptionProductDetails from './subscription-details/subscriptionProductDetails.container'
import Usage from './usage.container'

class SubscriptionDetails extends React.Component {
  render() {
    const { selectedAccountSubscription, isStandardSubscription } = this.props
    const hasSubscription = !isEmpty(selectedAccountSubscription)
    const paymentMethod = returnNested(selectedAccountSubscription, 'paymentMethod')
    return <div className="billing">
      {
        hasSubscription && isStandardSubscription &&
        <SubscriptionProductDetails/>
      }
      <Usage />
      {
        hasSubscription && isStandardSubscription &&
        <PaymentMethod paymentMethod={paymentMethod} />
      }
      {
        // hasSubscription && isStandardSubscription &&
        // <Coupon subscription={selectedAccountSubscription} />
      }
      {
        hasSubscription && isStandardSubscription &&
        <Customer />
      }
      {
        hasSubscription && isStandardSubscription &&
        <InvoiceList />
      }

      {
        hasSubscription && isStandardSubscription &&
        selectedAccountSubscription.status !== SUBSCRIPTION_STATUS.CANCELED &&
        <CancelSubscription selectedAccountSubscription={selectedAccountSubscription} />
      }
    </div>
  }
}

export default compose(
  withTranslation()
)(SubscriptionDetails)
