import React, { useContext } from 'react'
import { Tooltip } from 'primereact/tooltip'
import styled from 'styled-components'

import { DecimalPointComponent } from '../../../shared/component/general/decimal-point.component'
import { TooltipOverflowContent, useRefOverflow } from '../../../shared/hook/use-ref-overflow'
import { DecimalViewType } from '../../../shared/enum'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

const UnitWrapper = styled.div`
  width: 5.3rem;
`

const DoughnutLegendIcon = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
`

type DoughnutLegendItemComponentProps = {
  index: number | string,
  colorClassName: string,
  name?: string,
  value?: string | number | null,
  unit?: string | null
}
export const DoughnutLegendItemComponent = ({
  index, value, name, unit,
  colorClassName
}: DoughnutLegendItemComponentProps) => {
  const [ nameRef, hasNameOverflow, nameDom ] = useRefOverflow()
  const {
    selectedDecimalViewType = DecimalViewType.NumericValue
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { name }
      {' '}
    </TooltipOverflowContent>
  )
  return (
    <>
      <Tooltip target={nameDom} onBeforeShow={() => hasNameOverflow} position="bottom">{getTooltipContent()}</Tooltip>
      <div className="white-space-nowrap min-w-min">
        <DoughnutLegendIcon data-testid={`doughnut-legend-icon-${index}`} className={`doughnut-legend-icon ${colorClassName}`} />
      </div>
      <div ref={nameRef} data-testid={`doughnut-legend-item-${index}`} className="flex-grow-1 py-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
        { name }
      </div>
      <UnitWrapper data-testid={`doughnut-legend-impact-${index}`} className="flex-none py-1 flex white-space-nowrap justify-content-end" data-cy="item-impact">
        <DecimalPointComponent value={value} enableTooltip decimalViewType={selectedDecimalViewType} />
      </UnitWrapper>
      <div data-testid={`doughnut-legend-unit-${index}`} className="white-space-nowrap min-w-min">
        { selectedDecimalViewType !== DecimalViewType.PercentageValue && <>{ unit }</> }
      </div>
    </>
  )
}
