import { createContext } from 'react'
import { TProductContext } from '../../interface/product-context.type'
import { ProductLabel } from '../../../shared/enum'

const ProductContext = createContext<TProductContext>({
  label: ProductLabel.Product,
  showCreateFolderDialog: false,
  showDeleteProductDialog: false,
  showCreateComparisonDialog: false,
  showCreateScenarioDialog: false,
  currentFolder: null,
  allProductsDetail: {},
  currentPage: 1,
  totalItems: 0,
  updateProduct: () => {},
  refetchProducts: () => {},
  refetchFavProducts: () => {}
})

export default ProductContext
