import { useRef, useEffect } from 'react'
import { TIME, NAV } from '../../utils/const'

const InactivityTimeout = () => {

  const timer = useRef(null)

  useEffect(() => {
    refreshInactivityTimeout()
    const pageBody = document.querySelector('body')
    pageBody.addEventListener('click', refreshInactivityTimeout)
    return () => pageBody.removeEventListener('click', clearTimeout(timer.current))
  }, [])

  const refreshInactivityTimeout = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      window.location = NAV.LOGOUT
    }, TIME.ONE_HOUR)
  }

  return null
}

export default InactivityTimeout
