import { returnNested } from '../../utils/tools'

const root = state => returnNested(state, 'referenceProducts'),
  results = state => returnNested(root(state), 'results'),
  databases = state => returnNested(root(state), 'referenceDatabases'),
  referencePageSize = state => returnNested(root(state), 'referencePageSize')

const ReferenceProductsSelector = {
  results,
  databases,
  referencePageSize
}

export default ReferenceProductsSelector
