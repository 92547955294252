import { returnNested, safeArray } from '../../utils/tools'
import { EntityType } from '../../utils/const'
import PhaseType from '../../component/model/lifecycle/phaseType'

const root = state => returnNested(state, 'comparison')

const entities = state => returnNested(root(state),  'entities')
const entityToCompare = state => returnNested(root(state),  'entityToCompare')
const entityIdAndTypeToCompare = state => ({
  entityId: returnNested(entityToCompare(state), 'id'),
  entityType: returnNested(entityToCompare(state), '__typename')
})
const comparisonFlatView = state => returnNested(root(state), 'comparisonFlatView')
const inventoryItems = state => returnNested(entityToCompare(state), 'inventoryItems')
const isComparisonEntriesLoading = state => returnNested(root(state), 'isComparisonEntriesLoading')

const leafInventoryItems = state => returnNested(root(state),  'leafInventoryItems')
const isInventoryCompare = state => returnNested(root(state), 'isInventoryCompare')

const findById = (state, productId) => entities(state).find(product => product.id === productId)

const productionProductId = state => {
  const productionPhase = safeArray(returnNested(entityToCompare(state), 'phases'))
    .find(phase => returnNested(phase, 'type') === PhaseType.production.type)
  return returnNested(productionPhase, 'inventory', 'product', 'id')
}

const isPhaseSelected = (state, phaseId) => {
  return safeArray(returnNested(entityToCompare(state), 'phases'))
    .some(phase => returnNested(phase, 'id') === phaseId)
}

const comparisonProductId = state => {
  const { entityId, entityType } = entityIdAndTypeToCompare(state)
  const productId = entityType === EntityType.LIFECYCLE
    ? productionProductId(state)
    : entityId

  return productId
}

const ComparisonSelector = {
  entities,
  entityToCompare,
  entityIdAndTypeToCompare,
  root,
  comparisonFlatView,
  inventoryItems,
  isComparisonEntriesLoading,
  leafInventoryItems,
  isInventoryCompare,
  findById,
  productionProductId,
  isPhaseSelected,
  comparisonProductId
}

export default ComparisonSelector
