import { connect } from 'react-redux'
import { withRouter } from '../../../../utils/with-router'
import LifecycleDetailPanelWrapper from './lifecycleDetailPanelWrapper'
import { LifecycleSelector } from '../../../../redux/selectors/lifecycle.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { LifecycleScenarioSelector } from '../../../../redux/selectors/lifecycle.selector'
import ComparisonSelector from '../../../../redux/selectors/comparison.selector'

const mapStateToProps = state => ({
  selectedPhaseId: LifecycleSelector.selectedPhaseId(state),
  selectedProductId: SelectedProductSelector.productId(state),
  isCreatePhase: FlagsSelector.isCreatePhase(state),
  isForcingWorkspacePanel: FlagsSelector.isForcingWorkspacePanel(state),
  detailsPanelLifecycleId: LifecycleSelector.detailsPanelLifecycleId(state),
  selectedLifecycle: LifecycleSelector.selectedLifecycle(state),
  lifecycleScenario: LifecycleScenarioSelector.lifecycle(state),
  entityToCompare: ComparisonSelector.entityToCompare(state),
})

export default withRouter(connect(
  mapStateToProps,
  null
)(LifecycleDetailPanelWrapper))