import { connect } from 'react-redux'
import LoadingSelector from '../../../../../redux/selectors/loading.selector'
import SpaceSelector from '../../../../../redux/selectors/space.selector'
import { SelectedProductSelector } from '../../../../../redux/selectors/product.selector'
import GlobalSelector from '../../../../../redux/selectors/global.selector'
import {
  setSpaceImpactSettingsSaga,
  getImpactListsSaga,
  getImpactMethodSetsSaga,
  getImpactCategoriesSaga,
  getImpactMethodsSaga,
  getNwSetsSaga,
  getImpactMethodDatabasesSaga,
  getImpactMethodListSaga
} from '../../../../../redux/sagas/space.saga'
import WorkspaceImpactInformation from './workspaceImpactInformation'
import { trackEventSaga } from '../../../../../redux/sagas/tracking.saga'
import { setImpactCategoriesAction, setNwSetsAction } from '../../../../../redux/actions/global.actions'
import ReferenceProductsSelector from '../../../../../redux/selectors/referenceProducts.selector'
import { setReferenceDatabasesAction } from '../../../../../redux/actions/referenceProducts.actions'

const mapStateToProps = state => ({
  impactMethodSetsList: GlobalSelector.impactMethodSetsList(state),
  spaceId: SpaceSelector.spaceId(state),
  selectedSpace: SpaceSelector.selectedSpace(state),
  selectedProduct: SelectedProductSelector.product(state),
  spaceImpactCategory: SpaceSelector.impactCategory(state),
  isLoadingSpaceImpactMethods: LoadingSelector.isLoadingSpaceImpactMethods(state),
  isLoadingSpaceImpactSettings: LoadingSelector.isLoadingSpaceImpactSettings(state),
  impactMethods: GlobalSelector.impactMethods(state),
  impactCategories: GlobalSelector.impactCategories(state),
  nwSets: GlobalSelector.nwSets(state),
  referenceDatabases: ReferenceProductsSelector.databases(state)
})

const mapDispatchToProps = {
  setSpaceImpactSettingsSaga,
  getImpactListsSaga,
  getImpactMethodListSaga,
  getImpactMethodSetsSaga,
  getImpactCategoriesSaga,
  getImpactMethodsSaga,
  getImpactMethodDatabasesSaga,
  getNwSetsSaga,
  trackEventSaga,
  setImpactCategoriesAction,
  setNwSetsAction,
  setReferenceDatabasesAction
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceImpactInformation)
