import { gql } from '@apollo/client'

export default gql`
  query tags($spaceID: ID!, $query: String) {
    tags(spaceID: $spaceID, query: $query){
      id
      name
      color
    }
  }
`
