import { connect } from 'react-redux'
import ProductMutationSelector from '../../../../redux/selectors/productMutation.selector'
import ProductDetails from './productDetails'
import GlobalSelector from '../../../../redux/selectors/global.selector'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { SelectedProductSelector } from '../../../../redux/selectors/product.selector'

const mapStateToProps = state => {
  const activeInventory = ProductMutationSelector.activeInventory(state)

  return ({
    selectedProduct: SelectedProductSelector.product(state),
    inventoryRootItemId: GlobalSelector.inventoryRootItemId(state),
    activeInventory: activeInventory,
    productMutator: ProductMutationSelector.productMutator(state),
    isDetailsPanelReadOnly: ProductMutationSelector.isDetailsPanelReadOnly(state),
    selectedInventoryItem: GlobalSelector.selectedInventoryItem(state),
    isCreateNewInventoryItem: FlagsSelector.isCreateNewInventoryItem(state),
    isDetailPanelReadonlyV1: FlagsSelector.isDetailPanelReadonlyV1(state),
  })
}

export default connect(
  mapStateToProps,
  null
)(ProductDetails)
