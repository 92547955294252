import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Select, Divider, Button } from 'antd'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import compose from '../../../../utils/compose'
import { InventoryPageLocation, LifecycleItemLocation } from '../../../shared/locations'
import CropLongText from '../../../helpers/cropLongText.container'
import { returnNested, replaceSpacesWithLowDashes, safeArray } from '../../../../utils/tools'
import CreateLifecycle from '../../lifecycle/create-lifecycle/createLifecycle.container'
import ModalWindow from '../../../model/modalWindow/modalWindow.container'

const LifecycleDropdown  = props => {
  const {
    t,
    lifecycle,
    lifecycles,
    inventoryRootItemId,
    accountId,
    closeProductToolModal
  } = props

  const navigate = useNavigate()
  const [ isCreatingLifecycle, setIsCreatingLifecycle ] = useState(false)

  const selectLifecycleHandler = lifecycleId => {
    const urlParams = lifecycle ? LifecycleItemLocation.parseLocationParams() : InventoryPageLocation.parseLocationParams()
    const productId = returnNested(lifecycle, 'product', 'id')

    if (lifecycleId) {
      navigate(LifecycleItemLocation.toUrl({
        workspaceSlug: returnNested(urlParams, 'workspaceSlug'),
        activeTabKey: returnNested(urlParams, 'activeTabKey'),
        accountId,
        lifecycleId,
        productId: inventoryRootItemId || productId
      }))
    }
    closeProductToolModal()
  }

  const handleCreateLifecycle = () => {
    setIsCreatingLifecycle(true)
  }

  const getLifecycleList = () => {
    const lifecycleListOptions = safeArray(lifecycles).map(ls => (
      <Select.Option key={ ls.id } data-cy={replaceSpacesWithLowDashes(ls.name)}>
        <CropLongText
          tooltipText={ls.name}>
          {ls.name}
        </CropLongText>
      </Select.Option>
    ))
    return lifecycleListOptions
  }

  const handleModalOk = () => {
    setIsCreatingLifecycle(false)
    closeProductToolModal()
  }

  const lifecycleListOptions = getLifecycleList()

  return (
    <React.Fragment>
      <label>{t('model.lifecycle_description')}</label>
      <Select
        className="lifecycle-search-box"
        data-cy="lifecycle-list-select"
        placeholder={t('model.lifecycle_placeholder')}
        onChange={selectLifecycleHandler}
        getPopupContainer={node => node.parentElement}
        dropdownRender={options =>
          <div>
            {options}
            <Divider />
            <Button
              onMouseDown={handleCreateLifecycle}
              data-cy='create-lifecycle'
              className="button transparent"
              icon={<PlusOutlined />}>
              {t('global.create')}
            </Button>
          </div>
        }>
        { lifecycleListOptions }
      </Select>
      <ModalWindow
        cancelHandler={ handleModalOk }
        isShowModal={ isCreatingLifecycle }
        title={<span data-ac="create-lifecycle-title">{t('model.create_lifecycle')}</span>}
        content={ () =>
          <CreateLifecycle
            withProductSelection={ false }
            cancelHandler={ handleModalOk }
            isMinimalisticVersion={ true }
            handleModalOk={handleModalOk}
            attachedProductId={inventoryRootItemId}
          />
        }
      />
    </React.Fragment>
  )
}

export default compose(
  withTranslation(),
  withRouter
)(LifecycleDropdown)
