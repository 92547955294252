import { connect } from 'react-redux'
import permissionManager from './permissionManager'
import {
  addAccountPermissionSaga,
  removeAccountPermissionSaga,
  changeAccountPermissionSaga,
  setAccountPermissionsSaga
} from '../../redux/sagas/account.saga'
import { trackPageSaga } from '../../redux/sagas/tracking.saga'

import AccountSelector from '../../redux/selectors/account.selector'
import PoliciesSelector from '../../redux/selectors/policies.selector'
import FlagsSelector from '../../redux/selectors/flags.selector'

const mapStateToProps = state => ({
  accountTypePoliciesList: AccountSelector.accountTypePoliciesList(state),
  accountPermissionsList: AccountSelector.accountPermissionsList(state),
  canManageAccount: PoliciesSelector.canManageAccount(state),
  isDataViaNetworkRequesting: FlagsSelector.isDataViaNetworkRequesting(state)
})

const mapDispatchToProps = {
  addAccountPermissionSaga,
  removeAccountPermissionSaga,
  changeAccountPermissionSaga,
  setAccountPermissionsSaga,
  trackPageSaga,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(permissionManager)
