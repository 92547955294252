import React, { useContext, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-regular-svg-icons'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { useTranslation } from 'react-i18next'
import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { ICurrentFolder, TFolder } from '../../../model'
import { isValid } from '../../../shared/util/tools'
import { TableWrapperComponent } from '../../../shared/component/general/table-wrapper.component'
import { normalize } from '../../../../utils/tools'

const GlobalStyle = createGlobalStyle`
  .w-45rem {
    width: 45rem;
  }
`

const MoveToFolderComponentWrapper = styled.div`
  .p-button-outlined.p-button-plain {
    box-shadow: none;
  }

  .p-datatable {
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    background: var(--primarydark-50);
  }

  .p-datatable-thead {
    display: none !important;
  }
`

const ProductName = styled.div`
  width: calc(100% - 2.125rem)
`

const ProductIcon = styled.div`
  background: var(--primarydark-400);
`

export type MoveToFolderComponentProps = {
  showMoveToFolderDialog: boolean
  currentFolder: ICurrentFolder | null
  currentModalFolder: ICurrentFolder | null
  folders: TFolder[]
  closeMoveToFolderDialog: () => void
  openFolder: Function
  moveTo: Function
}
export const MoveToFolderComponent = ({
  folders,
  currentFolder,
  currentModalFolder,
  showMoveToFolderDialog,
  closeMoveToFolderDialog,
  openFolder,
  moveTo
}: MoveToFolderComponentProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { selectedEntity } = useContext<TProductContext>(ProductContext)
  const [ rowMouseOverId, setRowMouseOverId ] = useState<string>('')
  const { parent = null } = currentModalFolder || {}

  const onHide = () => {
    closeMoveToFolderDialog()
  }

  const nameTemplate = ({ id = '', name }: TFolder = {}) => (
    <div
      className="flex p-2"
      onMouseEnter={() => setRowMouseOverId(id)}
      onMouseLeave={() => setRowMouseOverId('')}
    >
      <ProductIcon className="flex align-items-center justify-content-center w-2rem h-2rem border-round-md text-white m-1">
        <FontAwesomeIcon data-testid={`move-folder-option-icon-${id}`} icon={faFolder} />
      </ProductIcon>
      <ProductName data-testid={`move-folder-option-name-${id}`} className="surface-overlay white-space-nowrap overflow-hidden text-overflow-ellipsis p-2 text-sm">
        { name }
      </ProductName>
    </div>
  )

  const handleMoveToEvent = (event: any, item: TFolder) => {
    event.stopPropagation()
    moveTo(item)
  }

  const moveTemplate = (rowData: TFolder) => {
    const show = rowData?.id === rowMouseOverId
    return (
      <div
        className="flex w-full h-full justify-content-end align-content-center p-2"
        data-cy={`folder-named-${normalize(rowData?.name)}`}
        onMouseEnter={() => setRowMouseOverId(rowData?.id || '')}
        onMouseLeave={() => setRowMouseOverId('')}
      >
        { show
        && (
          <Button
            label="Move here"
            onClick={(event: any) => handleMoveToEvent(event, rowData)}
            data-cy="move-to-folder-button"
            data-testid={`move-folder-here-${rowData?.id}`}
            className="p-button-link text-xs px-1 text-primary-500"
          />
        )}
      </div>
    )
  }

  const handleRowClick = (event: any) => {
    event.originalEvent.stopPropagation()
    openFolder(event.data)
  }

  return (
    <>
      <GlobalStyle />
      <Dialog
        className="w-45rem"
        data-testid="move-to-folder-dialog"
        header={t('labels.headerMoveTo', { entityName: selectedEntity?.name, ns: 'product' })}
        visible={showMoveToFolderDialog}
        draggable={false}
        dismissableMask
        onHide={onHide}
      >
        <MoveToFolderComponentWrapper className="flex flex-column w-full pb-6 gap-2">
          <div className="flex justify-content-start h-2rem">
            <div className="flex justify-content-start flex-grow-1">
              { !currentModalFolder && t('labels.overview') }
              { currentModalFolder && (
                <>
                  <div className="flex align-item-center justify-content-start">
                    <Button
                      data-testid="move-folder-parent"
                      onClick={() => openFolder(parent)}
                      label={isValid(parent) ? parent?.name : t('labels.overview')}
                      className="p-button-outlined p-button-plain text-sm border-none p-0"
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                  </div>
                  <div className="flex align-content-center p-2">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                  <div className="flex align-content-center justify-content-start">
                    <Button
                      data-testid="move-folder-current"
                      disabled
                      label={currentModalFolder?.name}
                      className="p-button-outlined p-button-plain text-sm border-none p-0"
                      aria-controls="popup_menu"
                      aria-haspopup
                    />
                  </div>
                </>
              ) }
            </div>
            <div className="flex w-8rem h-full justify-content-end">
              { currentFolder && (
                <Button
                  data-testid="move-folder-overview"
                  data-cy="move-to-overview-button"
                  onClick={() => moveTo(null)}
                  label={t('actions.moveTo', { context: 'overview', ns: 'product' })}
                  className="p-button-link text-sm px-1 text-primary-500"
                />
              )}
            </div>
          </div>
          <div className="flex w-full flex-column gap-2 h-25rem overflow-auto p-0">
            { folders.length === 0 && <div className="flex flex-column text-center text-gray-200 text-lg w-full py-8">{ t('messages.description', { context: 'noFoldersYet', ns: 'product' }) }</div>}
            { folders.length !== 0 && (
              <TableWrapperComponent data-testid="move-folder-table">
                <DataTable
                  value={folders}
                  emptyMessage=""
                  onRowClick={handleRowClick}
                >
                  <Column className="border-round-left-lg" field="name" header={<></>} body={nameTemplate} />
                  <Column className="border-round-right-lg" field="move" header={<></>} style={{ width: '100px' }} body={moveTemplate} />
                </DataTable>
              </TableWrapperComponent>
            )}
          </div>
        </MoveToFolderComponentWrapper>
      </Dialog>
    </>
  )
}
