import { gql } from '@apollo/client'

export default gql`
  query phasesByName($name: String!, $spaceID: String!){
      phasesByName(name: $name, spaceID: $spaceID) {
      id
      name
      parents(limit: 1) {
        id
        name
      }
    }
  }
`
