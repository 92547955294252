import { gql } from '@apollo/client'

export default gql`
  query transportProducts($spaceID: ID!) {
    transportProducts(spaceID: $spaceID){
      id
      name
    }
  }
`
