import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { TooltipOverflowContent } from '../../../shared/hook/use-ref-overflow'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { useIsMounted } from '../../../shared/hook/use-is-mounted'
import { BackLinkComponent } from '../../../shared/component/general/back-link.component'

export const HeaderDetailBackComponent = () => {
  const { t } = useTranslation([ 'common', 'lifecycle' ])
  const navigate = useNavigate()
  const location = useLocation()
  const isMounted = useIsMounted()
  const [ backTo, setBackTo ] = useState<string | undefined | null>(null)
  const { backToList = [], removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)

  const { state = null } = location || {}
  const { from = null } = state || {}
  useEffect(() => {
    const backTo = backToList.length > 0 ? backToList[backToList.length - 1] : from
    isMounted() && setBackTo(backTo)
  }, [ backToList, from ])

  const handleBack = () => {
    navigate(-1)
    removeFromBackToList()
  }

  const backLabel = backTo ? t('labels.backTo', { backTo }) : t('labels.backTo', { backTo: t('labels.lifecycle', { count: 2 }) })
  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      { backLabel }
      {' '}
    </TooltipOverflowContent>
  )
  return <BackLinkComponent handleBack={handleBack} tooltipContent={getTooltipContent} backButtonLabel={backLabel} />
}
