import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { isEmpty, returnNested } from '../../utils/tools'
import { addSuccessNotificationAction } from '../actions/notification.actions'
import { setSelectedAccount, setSelectedAccountSaga } from './account.saga'
import SettingsSelector from '../selectors/settings.selector'
import { dispatchErrors } from './helpers'
import { getVersionNumber } from '../../utils/nginx'
import { userAuth0Var, userVar, impactCategoriesVar } from '../../graphql/cache'
import { setSpacePoliciesSaga, getImpactCategoriesForInventory, getImpactCategoriesForInventorySaga } from './space.saga'
import {
  setAccountPoliciesAction,
  setSelectedAccountAction,
  setSelectedSpaceAction,
  setCurrentUserAction,
  setVersionNumberAction
} from '../actions/global.actions'
import AccountSelector from '../selectors/account.selector'
import GlobalSelector from '../selectors/global.selector'
import SpaceSelector from '../selectors/space.selector'
import { isValid } from '../../v1/shared/util/tools'

export const
  LOAD_DASHBOARD_DETAIL_V1 = 'LOAD_DASHBOARD_DETAIL_V1',
  GET_USER = 'GET_USER_SAGA',
  CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE_SAGA'

export const getUserSaga = (loadFirstAccount = false, cb = ()=>{}) => ({
  type: GET_USER,
  loadFirstAccount, cb
})

export const changeUserProfileSaga = (userMetadata, cb) => ({
  type: CHANGE_USER_PROFILE,
  userMetadata, cb
})

export const loadDashboardDetailV1Saga = ({ user, selectedAccount, selectedWorkspace }, cb = () => {}) => ({
  type: LOAD_DASHBOARD_DETAIL_V1,
  user, selectedAccount, selectedWorkspace, cb
})

export function* loadDashboardDetailV1(action) {
  try {
    const userFromAuth = userAuth0Var()
    const impactCategoriesFromCache = impactCategoriesVar()
    const { user, selectedAccount, selectedWorkspace } = action
    const { hasAccess: policies = [], account } = selectedAccount || {}
    const { space = null, hasAccess: spaceHasAccess = null } = selectedWorkspace || {}
    const reduxSelectedAccount = yield select(AccountSelector.selectedAccount)
    const reduxSelectedSpace = yield select(SpaceSelector.selectedSpace)
    const reduxVersionNumber = yield select(GlobalSelector.versionNumber)
    const spaceTypePoliciesList = yield select(SpaceSelector.spaceTypePoliciesList)
    const isAccountInRedux = account && reduxSelectedAccount && reduxSelectedAccount?.id === account?.id
    const isSpaceInRedux = space && reduxSelectedSpace && reduxSelectedSpace?.id === space?.id

    const {
      subscription: accountSubscriptionStatus,
      ...accountWithoutSubscription
    } = account || {}
    yield put(setCurrentUserAction({
      id: returnNested(user, 'id'),
      name: returnNested(user, 'name'),
      email: returnNested(user, 'email'),
      accounts: returnNested(user, 'accounts'),
      picture: returnNested(userFromAuth, 'picture'),
      metadata: returnNested(userFromAuth, 'metadata'),
      createdDate: new Date(returnNested(userFromAuth, 'createdDate'))
    }))

    yield put(setAccountPoliciesAction(policies))
    yield put(setSelectedAccountAction({
      ...(isAccountInRedux ? reduxSelectedAccount : {}),
      ...accountWithoutSubscription,
      subscription: {
        ...(isAccountInRedux && reduxSelectedAccount?.subscription ? reduxSelectedAccount?.subscription : {}),
        ...(accountSubscriptionStatus ? accountSubscriptionStatus : {})
      }
    }))

    if ( space ) {
      yield put(setSelectedSpaceAction({
        ...(isSpaceInRedux ? reduxSelectedSpace : {}),
        ...space
      }))
      const hasSameImpactCategories = impactCategoriesFromCache?.some( category => category.id === space?.impactCategory?.id)
      if (space.impactMethod && !hasSameImpactCategories ) {
        yield call(getImpactCategoriesForInventory, getImpactCategoriesForInventorySaga(space.impactMethod.id))
      }
    }

    if ( spaceHasAccess ) {
      if ( !isValid(spaceTypePoliciesList) || spaceTypePoliciesList.length === 0) {
        yield put(setSpacePoliciesSaga())
      }
    }

    if ( reduxVersionNumber === 0 ) {
      const versionResponse = yield call(getVersionNumber)
      yield put(setVersionNumberAction(versionResponse.version))
    } else {
      yield put(setVersionNumberAction(reduxVersionNumber))
    }

    action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}

/**
 *  @param {{loadFirstAccount: bool, cb: function}} action
 */
export function* getUser(action) {

  try {
    const [ versionResponse ] = yield all([ call(getVersionNumber) ])

    const userFromAuth = userAuth0Var()
    const userFromBackend = userVar()

    yield put(setVersionNumberAction(versionResponse.version))
    yield put(setCurrentUserAction({
      id: returnNested(userFromBackend, 'id'),
      name: returnNested(userFromBackend, 'name'),
      email: returnNested(userFromBackend, 'email'),
      accounts: returnNested(userFromBackend, 'accounts'),
      picture: returnNested(userFromAuth, 'picture'),
      metadata: returnNested(userFromAuth, 'metadata'),
      createdDate: new Date(returnNested(userFromAuth, 'createdDate'))
    }))
    const accounts = returnNested(userFromBackend, 'accounts')
    if (!isEmpty(accounts)) {
      const lastSelectedAccountId = yield select(SettingsSelector.lastSelectedAccountId)
      const isSelectingLastUsedAccount = !action.loadFirstAccount && accounts.some(account => account.id === lastSelectedAccountId)
      const accountToSelect = isSelectingLastUsedAccount ? lastSelectedAccountId : returnNested(userFromBackend, 'accounts', 0, 'id')

      yield call(setSelectedAccount, setSelectedAccountSaga(accountToSelect))
    }
    action.cb()
  } catch (error) {
    yield call(dispatchErrors, error)
  }
}
/**
 * @param {{userMetadata: object}} action
 */
function* changeUserProfile(action) {
  try {
    action.cb && action.cb()
    yield put(addSuccessNotificationAction('model.profile_successfully_updated'))
  } catch (e) {
    yield call(dispatchErrors, e)
  }
}

export default function* userSaga() {
  yield takeLatest(GET_USER, getUser)
  yield takeLatest(CHANGE_USER_PROFILE, changeUserProfile)
  yield takeLatest(LOAD_DASHBOARD_DETAIL_V1, loadDashboardDetailV1)
}

