export enum ExportColumn {
  level= 'level',
  name = 'name',
  description = 'description',
  amount= 'amount',
  unit = 'unit',
  label = 'label',
  datasetID = 'datasetID',
  datasetName = 'datasetName',
  type = 'type',
  area = 'area',
  areaTime = 'areaTime',
  bioticProdOcc = 'bioticProdOcc',
  bioticProdTransf = 'bioticProdTransf',
  duration = 'duration',
  energy = 'energy',
  energyAreaTime = 'energyAreaTime',
  energyMassTime = 'energyMassTime',
  erosionOcc = 'erosionOcc',
  erosionTransf = 'erosionTransf',
  transport = 'transport',
  grossCalorific = 'grossCalorific',
  groundwaterOcc = 'groundwaterOcc',
  groundwaterTransf = 'groundwaterTransf',
  guestNight = 'guestNight',
  itemsLength = 'itemsLength',
  length = 'length',
  lengthTime = 'lengthTime',
  marketValue = 'marketValue',
  mass = 'mass',
  massTime = 'massTime',
  mechFiltrationOcc = 'mechFiltrationOcc',
  mechFiltrationTransf = 'mechFiltrationTransf',
  netCalorific = 'netCalorific',
  normalVolume = 'normalVolume',
  numItems = 'numItems',
  personTransport = 'personTransport',
  physFiltrationOcc = 'physFiltrationOcc',
  physFiltrationTransf = 'physFiltrationTransf',
  radioactivity = 'radioactivity',
  vehiculeTransport = 'vehiculeTransport',
  volume = 'volume',
  volumeLength = 'volumeLength',
  volumeTime = 'volumeTime'
}
