
import { useMemo } from 'react'
import { transformItemsToTree } from '../util/transform'
import { IInventoryItemNode } from '../../model'

type TUseTransformTree = {
  inventoryItems: IInventoryItemNode[],
  rootInventoryItem: IInventoryItemNode,
  expandedKeys: string[]
}
export const useTransformTree = ({
  inventoryItems,
  rootInventoryItem,
  expandedKeys
}: TUseTransformTree) => {
  const clonedInventoryItems = [ ...inventoryItems ]
  const { inventoryTree } = useMemo(
    () => transformItemsToTree({ inventoryItems: clonedInventoryItems, rootInventoryItem, expandedKeys }),
    [ inventoryItems, expandedKeys ]
  )

  return inventoryTree
}
