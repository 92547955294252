import { gql } from '@apollo/client'

// After this request we always load the product with impact so we load just the essentials
export default gql`
  mutation performLifecycleScenarioAction($action: String!, $args: String!, $lifecycleID: ID!, $scenarioLifecycleID: ID!, $rootInventoryItemID: ID!, $inventoryNodeID: ID! ) {
      performLifecycleScenarioAction(
          action: $action,
          args: $args,
          lifecycleID: $lifecycleID,
          scenarioLifecycleID: $scenarioLifecycleID,
          rootInventoryItemID: $rootInventoryItemID
          inventoryNodeID: $inventoryNodeID
      ){
        id
        unit
        productProperties {
            referenceProperty {
                id
                name
            }
            conversionFactor
            referenceUnit {
                id
                name
            }
        }
    }
  }
`
