import React, { useState } from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { SortOrder, TFlatViewSortBy } from '../../../shared/enum'
import { isValid } from '../../../shared/util/tools'

const FlatViewHeaderComponentWrap = styled.div`

  .table-header-label {
    padding: 0.188rem 0;
  }

  &.table-sortable-column:hover {
    cursor: pointer;
    span {
      color: var(--blue-500) !important;
    }
  }

`

type FlatViewHeaderComponentProp = {
  label: string,
  sortBy?: TFlatViewSortBy,
  sortDisabled?: boolean,
  alignRight?: boolean,
  selectedSortBy?: TFlatViewSortBy,
  selectedSortOrder?: SortOrder,
  onSortClick?: (param1: TFlatViewSortBy, param2: SortOrder) => void
}

export const FlatViewHeaderComponent = ({
  label,
  sortBy,
  sortDisabled = false,
  selectedSortBy,
  alignRight = true,
  selectedSortOrder,
  onSortClick
}: FlatViewHeaderComponentProp) => {
  const [ isMouseOver, setIsMouseOver ] = useState(false)

  const showArrow = (isMouseOver || selectedSortBy === sortBy) && sortBy && !sortDisabled
  const isAsc = selectedSortOrder === SortOrder.Asc
  const isDesc = selectedSortOrder === SortOrder.Desc
  const sortOrderTo: SortOrder = isAsc ? SortOrder.Desc : SortOrder.Asc

  return (
    <FlatViewHeaderComponentWrap
      data-testid="table-header-wrap"
      className={classNames('flex font-normal gap-1', { 'table-sortable-column': isValid(showArrow) && showArrow, 'justify-content-end': alignRight })}
      onClick={() => onSortClick && sortBy && !sortDisabled && onSortClick(sortBy, sortOrderTo)}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <span data-testid="table-header-label" className="text-gray-700 font-medium text-sm table-header-label">{ label }</span>
      {showArrow && (
        <span data-html2canvas-ignore className="text-gray-600">
          { isAsc && <FontAwesomeIcon data-testid="table-header-asc" className="text-sm" icon={faArrowDown} /> }
          { isDesc && <FontAwesomeIcon data-testid="table-header-desc" className="text-sm" icon={faArrowUp} /> }
        </span>
      )}
    </FlatViewHeaderComponentWrap>
  )
}
