import { PAGINATION } from '../../../utils/const'
import { ScreenSizes } from '../enum/screen'

export const getOverviewPageSize = (screenWidth: number, screenHeight: number) => {
  const cardHeight = 176
  const rowHeight = 56
  const numberOfLines = Math.round(screenHeight / cardHeight)
  const tablePageSize = Math.round(screenHeight / rowHeight) || PAGINATION.PAGE_SIZE

  let cardPageSize = PAGINATION.PAGE_SIZE
  if (screenWidth >= ScreenSizes.xxl) {
    cardPageSize = numberOfLines * 6
  } else if (screenWidth >= ScreenSizes.lg) {
    cardPageSize = (numberOfLines) * 4
  } else if (screenWidth >= ScreenSizes.md) {
    cardPageSize = (numberOfLines) * 3
  }
  return {
    cardPageSize,
    tablePageSize: tablePageSize < PAGINATION.PAGE_SIZE ? PAGINATION.PAGE_SIZE : tablePageSize
  }
}
