import { gql } from '@apollo/client'

export default gql`
  mutation renameItemFolder($folderID: ID!, $newName: String!) {
	  renameItemFolder(folderID: $folderID, newName: $newName) {
      id
      name
    }
  }
`
