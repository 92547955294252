import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from '../../../../utils/with-router'
import compose from '../../../../utils/compose'
import { TRACK_EVENT_TYPE } from '../../../../utils/const'
import { LifecycleItemLocation, LifecycleListPageLocation } from '../../../shared/locations'
import ScenarioList from './scenarioList'
import { matchLocation } from '../../../../utils/location'
import DashboardContext from '../../../../v1/dashboard/context/dashboard.context'

class LifecycleScenarioDropdown extends PureComponent {
  static contextType = DashboardContext

  createLifecycleScenario = () => {
    const { lifecycleId, copyLifecycleSaga, trackEventSaga } = this.props
    copyLifecycleSaga(lifecycleId, copyId => {
      this.loadScenario(copyId)
      trackEventSaga(TRACK_EVENT_TYPE.CREATED_LIFECYCLE_SCENARIO)
    })
  }

  /**
   * @param lifecycleScenarioId Can also be null when user clicks clear
   */
  onChangeScenarioHandler = lifecycleScenarioId => {
    lifecycleScenarioId
      ? this.loadScenario(lifecycleScenarioId)
      : this.quitScenario()
  }

  getLifecycleLocationSource = () => (
    (matchLocation(LifecycleItemLocation) && LifecycleItemLocation) ||
    (matchLocation(LifecycleListPageLocation) && LifecycleListPageLocation)
  )

  loadScenario(lifecycleId) {
    const { navigate, successCb, trackEventSaga, selectedLifecycle, entityId } = this.props
    const { addToBackToList = () => {} } = this.context

    const lifecycleLocationSource = this.getLifecycleLocationSource()
    selectedLifecycle?.name && addToBackToList(selectedLifecycle?.name)

    navigate(LifecycleItemLocation.toUrl({
      lifecycleId: entityId,
      ...lifecycleLocationSource.parseLocationParams(),
      lifecycleScenarioId: lifecycleId,
      compareEntityId: undefined,
      compareEntityType: undefined
    }))
    successCb()
    trackEventSaga(TRACK_EVENT_TYPE.OPENED_PRODUCT_SCENARIO)
  }

  quitScenario = () => {
    const { quitLifecycleScenarioSaga, navigate, successCb } = this.props

    quitLifecycleScenarioSaga(() => {
      const lifecycleLocationSource = this.getLifecycleLocationSource()
      navigate(LifecycleItemLocation.toUrl({
        ...lifecycleLocationSource.parseLocationParams(),
        lifecycleScenarioId: undefined,
        compareEntityId: undefined,
        compareEntityType: undefined
      }))
      successCb()
    })
  }

  render() {
    const { lifecycleScenarioId, scenarioList, t } = this.props
    return <ScenarioList
      label={ t('model.lifecycle_scenario_description') }
      value = { lifecycleScenarioId }
      scenarioList={ scenarioList }
      onChangeHandler = { this.onChangeScenarioHandler }
      createHandler={ this.createLifecycleScenario }
    />
  }
}

LifecycleScenarioDropdown.propTypes = {
  successCb: PropTypes.func.isRequired
}

export default compose(
  withRouter,
  withTranslation()
)(LifecycleScenarioDropdown)

