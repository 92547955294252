export enum SignUpExperience {
  Carbon = 'carbon',
  Regular = 'regular'
}

export enum GettingStatedChecklistItem {
  CreateProductBlueprint = 'CreateProductBlueprint',
  ModelYourProduct = 'ModelYourProduct',
  GetFootprintCheck = 'GetFootprintCheck',
  ShareYourFootprint = 'ShareYourFootprint'
}
