import { gql } from '@apollo/client'

export default gql`
  fragment minimalProductFragment on Product {
    id
    name
    unit
    description
    hasInventory
    referenceProduct {
      type
    }
  }
`
