import { returnNested } from '../../../utils/tools'
import {
  RocketOutlined,
  TeamOutlined,
  CarOutlined,
  RedoOutlined,
  InboxOutlined,
  GoldOutlined,
  SettingOutlined,
  ToolOutlined
} from '@ant-design/icons'
import React from 'react'

const
  PHASE_TYPE_CUSTOM = { type: 'custom', name: 'Custom' },
  PHASE_TYPE_PRODUCTION = { type: 'production', name: 'Production' },
  PHASE_TYPE_TRANSPORT = { type: 'transport', name: 'Transport' },
  PHASE_TYPE_DISTRIBUTION = { type: 'transport', name: 'Distribution' },
  PHASE_TYPE_PACKAGING = { type: 'packaging', name: 'Packaging' },
  PHASE_TYPE_USE = { type: 'use', name: 'Use' },
  PHASE_TYPE_EOL = { type: 'eol', name: 'End-of-life' },
  PHASE_TYPE_RAW_MATERIALS = { type: 'raw_materials', name: 'Raw materials' },
  PHASE_TYPE_RAW_PROCESSING = { type: 'processing', name: 'Processing' },
  PHASE_TYPE_RAW_MAINTENANCE = { type: 'maintenance', name: 'Maintenance' },
  PHASE_TYPE_A1_RAW_MATERIAL_SUPPLY = { type: 'raw_materials', name: 'A1 Raw material supply' },
  PHASE_TYPE_A1_3_PRODUCT_STAGE = { type: 'custom', name: 'A1-3 Product stage' },
  PHASE_TYPE_A2_TRANSPORT = { type: 'transport', name: 'A2 Transport' },
  PHASE_TYPE_A3_MANUFACTURING = { type: 'processing', name: 'A3 Manufacturing' },
  PHASE_TYPE_A4_5_CONSTRUCTION_PROCESS_STAGE = { type: 'custom', name: 'A4-5 Construction process stage' },
  PHASE_TYPE_B1_7_USE_STAGE = { type: 'custom', name: 'B1-7 Use stage' },
  PHASE_TYPE_C1_4_END_OF_LIFE_STAGE = { type: 'custom', name: 'C1-4 End-of-life stage' },
  PHASE_TYPE_D_BENEFITS_AND_LOADS_BEYOND_SYSTEM_BOUNDARY = { type: 'custom', name: 'D Benefits and loads beyond system boundary' }

const PhaseType = {
  custom: PHASE_TYPE_CUSTOM,
  production: PHASE_TYPE_PRODUCTION,
  transport: PHASE_TYPE_TRANSPORT,
  packaging: PHASE_TYPE_PACKAGING,
  use: PHASE_TYPE_USE,
  eol: PHASE_TYPE_EOL,
  list: [
    PHASE_TYPE_CUSTOM,
    PHASE_TYPE_PRODUCTION,
    PHASE_TYPE_TRANSPORT,
    PHASE_TYPE_PACKAGING,
    PHASE_TYPE_USE,
    PHASE_TYPE_EOL,
    PHASE_TYPE_RAW_MATERIALS,
    PHASE_TYPE_RAW_PROCESSING,
    PHASE_TYPE_RAW_MAINTENANCE
  ],
  icons: {
    production: <RocketOutlined />,
    packaging: <InboxOutlined />,
    transport: <CarOutlined />,
    use: <TeamOutlined />,
    eol: <RedoOutlined />,
    raw_materials: <GoldOutlined />,
    processing: <SettingOutlined />,
    maintenance: <ToolOutlined />
  },
  groups: {
    all: [
      PHASE_TYPE_CUSTOM,
      PHASE_TYPE_PRODUCTION,
      PHASE_TYPE_PACKAGING,
      PHASE_TYPE_TRANSPORT,
      PHASE_TYPE_USE,
      PHASE_TYPE_EOL
    ],
    std5: [
      PHASE_TYPE_PRODUCTION,
      PHASE_TYPE_PACKAGING,
      PHASE_TYPE_TRANSPORT,
      PHASE_TYPE_USE,
      PHASE_TYPE_EOL
    ],
    std3: [
      PHASE_TYPE_PRODUCTION,
      PHASE_TYPE_USE,
      PHASE_TYPE_EOL
    ],
    std2: [
      PHASE_TYPE_PRODUCTION,
      PHASE_TYPE_USE
    ],
    std7: [
      PHASE_TYPE_RAW_MATERIALS,
      PHASE_TYPE_TRANSPORT,
      PHASE_TYPE_PRODUCTION,
      PHASE_TYPE_PACKAGING,
      PHASE_TYPE_DISTRIBUTION,
      PHASE_TYPE_USE,
      PHASE_TYPE_EOL
    ],
    EN15804_full: [
      PHASE_TYPE_A1_3_PRODUCT_STAGE,
      PHASE_TYPE_A4_5_CONSTRUCTION_PROCESS_STAGE,
      PHASE_TYPE_B1_7_USE_STAGE,
      PHASE_TYPE_C1_4_END_OF_LIFE_STAGE,
      PHASE_TYPE_D_BENEFITS_AND_LOADS_BEYOND_SYSTEM_BOUNDARY
    ],
    EN15804_product_and_eol: [
      PHASE_TYPE_A1_3_PRODUCT_STAGE,
      PHASE_TYPE_C1_4_END_OF_LIFE_STAGE
    ],
    EN15804_product_split: [
      PHASE_TYPE_A1_RAW_MATERIAL_SUPPLY,
      PHASE_TYPE_A2_TRANSPORT,
      PHASE_TYPE_A3_MANUFACTURING
    ],
    EN15804_full_split: [
      PHASE_TYPE_A1_RAW_MATERIAL_SUPPLY,
      PHASE_TYPE_A2_TRANSPORT,
      PHASE_TYPE_A3_MANUFACTURING,
      PHASE_TYPE_A4_5_CONSTRUCTION_PROCESS_STAGE,
      PHASE_TYPE_B1_7_USE_STAGE,
      PHASE_TYPE_C1_4_END_OF_LIFE_STAGE,
      PHASE_TYPE_D_BENEFITS_AND_LOADS_BEYOND_SYSTEM_BOUNDARY
    ],
  },
  isProductionPhase: phase => returnNested(phase, 'type') === PHASE_TYPE_PRODUCTION.type
}

export default PhaseType
