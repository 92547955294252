import React from 'react'
import { Row, Col } from 'antd'
import { IInventoryTreeHeaderProps } from './inventoryTreeHeader.interface'

const InventoryTreeHeader:React.FC<IInventoryTreeHeaderProps> = props => {
  const {
    areImpactsBarsShown,
    impactUnit
  } = props

  const rightMarginClass = areImpactsBarsShown ? 'impact-bars-margin' : ''
  const unitContainerWidth = areImpactsBarsShown ? '94px' : '80px'

  return (
    <Row className="inventory-tree-header-labels" >
      <Col flex="1 0 0">Inventory</Col>
      <Col
        flex={`${unitContainerWidth} 0 0`}
        data-cy="tree-header-unit-label"
        className={`inventory-tree-unit-label ${rightMarginClass}`}
      >{impactUnit}</Col>
      { areImpactsBarsShown ?
        <Col flex="110px 0 0" className="bars">&nbsp;</Col>
        : null
      }
    </Row>
  )
}

export default InventoryTreeHeader
