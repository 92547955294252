import { gql } from '@apollo/client'

export default gql`
  query accountForBilling($id: ID!){
    account(id: $id) {
      id
      name
      hasValidSubscription
      usage {
          type
          count
          limit
      }
      trialStart
      trialEnd
      isTrialing
      createdAt
      spaces {
          id
          name
          slug
      }
    }
  }
`
