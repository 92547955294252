import React from 'react'
import compose from '../../utils/compose'
import { withTranslation } from 'react-i18next'
import { LoadingOutlined } from '@ant-design/icons'

const LoadingPage = () =>
  <div className="loading-page">
    <section>
      <div className="loading" data-cy="loading-something"><LoadingOutlined /></div>
    </section>
  </div>

export default compose(
  withTranslation(),
)(LoadingPage)
