import { gql } from '@apollo/client'

export default gql`
  mutation createLifecycle($name: String!, $workspaceID: ID!, $productID: ID, $description: String, $amount: String, $unit: String, $phases: [PhaseInput!]) {
    createLifecycle(name: $name, spaceID: $workspaceID, productID: $productID, description: $description, amount: $amount, unit: $unit, phases: $phases) {
      id
      name
      slug
    }
  }
`
