import { createContext } from 'react'
import { TFeatureContext } from '../interface/feature-context.type'

const FeatureContext = createContext<TFeatureContext>({
  features: [],
  isEnvEnabled: () => false,
  isFeatureEnabled: () => false
})

export default FeatureContext
