import { gql } from '@apollo/client'
import customer from '../fragment/customer'

export default gql`
  query subscriptionCustomer($accountID: ID!){
    subscriptionCustomer(accountID: $accountID) {
      ...customerFragment
    }
  }
  ${customer}
`
