import { createContext } from 'react'
import { TAnalysisContext } from '../../interface/analysis.context'
import { AnalysisMode, AnalysisType } from '../../enum/analysis'

const AnalysisContext = createContext<TAnalysisContext>({
  analysisMode: AnalysisMode.Comparison,
  analysisType: AnalysisType.ProductToProduct,
  updateAnalysis: () => {}
})

export default AnalysisContext
