import { gql } from '@apollo/client'
import phase from '../fragment/phase'

export default gql`
  mutation createPhase($lifecycleID: ID!, $name: String!, $type: String! ) {
      createPhase(lifecycleID: $lifecycleID, name: $name, type: $type){
          ...phaseFragment
      }
  }
  ${phase}
`
