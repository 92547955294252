import React, { useRef, useEffect, useState } from 'react'
import { getTextWidth } from '../../utils/tools'
import { Tooltip, Typography } from 'antd'

const Text = Typography.Text

const CropLongText = props => {
  const {
    tooltipText,
    fontSize,
    tooltipPlacement,
    className,
    dataCy,
    onClick,
    children,
    isHtmlContentType,
    containerClassName,
    isDetailsPanelOpen,
    forceCropOnClassName
  } = props
  const sidebar = document.querySelector('aside.sidebar-content')
  const textElementBlock = useRef()

  const [ widthOfTextElementBlock, setWidthOfTextElementBlock ] = useState(0)
  const [ fullTextWidth, setFullTextWidth ] = useState(0)

  const updateTextWidth = () => {
    setTimeout(() => {
      const currentElement = textElementBlock.current
      if (currentElement) {
        const fullTextWidth = getTextWidth(tooltipText, fontSize)
        setWidthOfTextElementBlock(currentElement.parentElement.clientWidth)
        setFullTextWidth(fullTextWidth)
        if (forceCropOnClassName) forceContainerCrop(currentElement, fullTextWidth)
      }
    }, 1)
  }

  const forceContainerCrop = (currentElement, fullTextWidth) => {
    const container = currentElement.closest(`.${forceCropOnClassName}`)
    if (container && container.clientWidth < fullTextWidth) {
      currentElement.parentElement.style.width = '0'
    }
  }

  useEffect(() => {
    sidebar && sidebar.addEventListener('transitionend', updateTextWidth)
    window.addEventListener('resize', updateTextWidth)
    updateTextWidth()
    return () => {
      sidebar && sidebar.removeEventListener('transitionend', updateTextWidth)
      window.removeEventListener('resize', updateTextWidth)
    }
  }, [])

  useEffect(() => {
    updateTextWidth()
  }, [ isDetailsPanelOpen ])

  const isTooltipNeeded = isHtmlContentType || fullTextWidth > widthOfTextElementBlock

  const content = <Text
    data-cy={dataCy}
    ellipsis={true}
    className={className}
    onClick={onClick}>
    {children}
  </Text>

  return (
    <span className={containerClassName} ref={textElementBlock}>
      {
        isTooltipNeeded ?
          <Tooltip
            placement={tooltipPlacement || 'top'}
            title={tooltipText}>
            {content}
          </ Tooltip>
          : content
      }
    </span>

  )
}

export default CropLongText
