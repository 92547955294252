import { gql } from '../../../__generated__'

export const CREATE_LIFECYCLE = gql(`
  mutation createLifecycle($name: String!, $workspaceID: ID!, $productID: ID, $description: String, $amount: String, $unit: String, $phases: [PhaseInput!]) {
    createLifecycle(name: $name, spaceID: $workspaceID, productID: $productID, description: $description, amount: $amount, unit: $unit, phases: $phases) {
      id
      name
      slug
    }
  }
`)

export const COPY_LIFECYCLE = gql(`
  mutation copyLifecycle($lifecycleID: ID!) {
    copyLifecycle(lifecycleID: $lifecycleID) {
      ...lifecycleFragment
    }
  }
`)

export const REMOVE_LIFECYCLE = gql(`
  mutation removeLifecycle($id: ID!) {
    removeLifecycle(id: $id)
  }
`)

export const RENAME_PHASE = gql(`
  mutation renamePhase($id: ID!, $name: String!) {
    renamePhase(id: $id, name: $name) {
      id
      name
    }
  }
`)

export const CHANGE_PHASE_TYPE = gql(`
  mutation changePhaseType($id: ID!, $type: String!) {
    changePhaseType(id: $id, type: $type) {
      id
      type
    }
  }
`)

export const REORDER_PHASES = gql(`
  mutation reorderPhases($lifecycleID: ID!, $phases: [String!]!) {
      reorderPhases(lifecycleID: $lifecycleID, phases: $phases)
  }
`)

export const CREATE_PHASE = gql(`
  mutation createPhase($lifecycleID: ID!, $name: String!, $type: String! ) {
      createPhase(lifecycleID: $lifecycleID, name: $name, type: $type){
          ...phaseFragment
      }
  }
`)

export const REMOVE_PHASE = gql(`
  mutation removePhase($id: ID!) {
    removePhase(id: $id)
  }
`)

export const REQUEST_LIFECYCLE_EXPORT = gql(`
  mutation requestLifecycleExport($lifecycleID: ID!, $timezone: String!) {
      requestLifecycleExport(lifecycleID: $lifecycleID, timezone: $timezone)
  }
`)
