import React from 'react'
import classnames from 'classnames'
import { Form, Select } from 'antd'
import { keyboardHandler, returnNested } from '../../../utils/tools'
import SingleFieldText from './singleFieldText'

class SelectWrapper extends React.PureComponent {

  getText = () => {
    const {
      dataCy,
      editHandler,
      disabled,
      displayedText
    } = this.props

    return (
      <SingleFieldText
        displayedText={displayedText}
        editHandler={editHandler}
        disabled={disabled}
        dataCy={dataCy}
      />
    )
  }

  getSelect = () => {
    const {
      children,
      autoFocus = true,
      autoComplete = 'on',
      dataCy,
      className,
      disabled,
      onSelect,
      onDeselect,
      onBlur,
      onChange,
      onSearch,
      onPressEscape,
      dropdownMatchSelectWidth,
      placeholder,
      filterOption,
      value,
      getPopupContainer,
      showSearch,
      defaultValue,
      mode,
      dropdownRender
    } = this.props

    return (
      <Select
        autoFocus={autoFocus}
        autoComplete={autoComplete || 'off'}
        mode={mode}
        className={classnames(`${dataCy}-select`, { className }, 'select-wrapper')}
        data-cy={`${dataCy}-select`}
        disabled={disabled}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onBlur={onBlur}
        onChange={onChange}
        onSearch={onSearch}
        onInputKeyDown={evt => this.handleEscapeKey(evt, onPressEscape)}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownClassName={`${dataCy}-dropdown cy-${dataCy}-dropdown`}
        placeholder={placeholder}
        value={value}
        filterOption={filterOption}
        getPopupContainer={getPopupContainer}
        showSearch={showSearch}
        defaultValue={defaultValue}
        dropdownRender={dropdownRender}
      >
        {children}
      </Select>
    )
  }

  handleEscapeKey = (evt, cb) => {
    keyboardHandler(returnNested(evt, 'key'), 'escape', cb)
  }

  render() {
    const {
      isFieldEditable,
      fieldLabel,
      fieldName,
      fieldRules,
      fieldInitialValue,
      dependencies
    } = this.props

    return (
      <Form.Item
        className="field-container"
        name={fieldName}
        label={ fieldLabel }
        rules={fieldRules}
        initialValue={fieldInitialValue}
        dependencies={dependencies}
      >
        {isFieldEditable ? this.getSelect() : this.getText()}
      </Form.Item>
    )
  }
}

export default SelectWrapper
