
export enum DecimalViewType {
  NumericValue = 'NumericValue',
  ScientificValue = 'ScientificValue',
  PercentageValue = 'PercentageValue'
}

export enum NotationRange {
  ExtraSmall = 0.00001,
  Small=0.01,
  Medium = 99,
  Large = 9999999
}
