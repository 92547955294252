import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import { COPY_LIFECYCLE } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../../shared/util/error'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { CopyLifecycleComponent } from '../component/copy-lifecycle.component'
import { TrackEventType } from '../../shared/enum/track-events'

type TCopyLifecycleContainerProps = {
  afterCopyLifecycle: (param: string, param1: TrackEventType) => void
}

export const CopyLifecycleContainer = ({
  afterCopyLifecycle
}: TCopyLifecycleContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const toast = useRef<Toast>(null)
  const { showCopyLifecycleDialog = false, selectedLifecycle, updateLifecycle } = useContext<TLifecycleContext>(LifecycleContext)
  const [
    copyLifecycle,
    {
      error: failedCopingLifecycle,
      data: copyLifecycleData,
      loading: copingLifecycle
    }
  ] = useMutation(COPY_LIFECYCLE)
  const lifecycleCopied = copyLifecycleData?.copyLifecycle

  useEffect(() => {
    if (!copingLifecycle && (lifecycleCopied || failedCopingLifecycle)) {
      copyLifecycleCallback()
    }
  }, [ copingLifecycle, lifecycleCopied, failedCopingLifecycle ])

  const closeCopyLifecycleDialog = () => {
    updateLifecycle({ showCopyLifecycleDialog: false })
  }

  const handleCopyLifecycle = () => {
    selectedLifecycle?.id && copyLifecycle({ variables: { lifecycleID: selectedLifecycle?.id } })
  }

  const copyLifecycleCallback = () => {
    try {
      if (failedCopingLifecycle) {
        throw failedCopingLifecycle
      } else if (lifecycleCopied) {
        afterCopyLifecycle(lifecycleCopied?.id, TrackEventType.COPIED_LIFECYCLE)
        closeCopyLifecycleDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: 'Successfully copied the lifecycle',
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, 'Failed coping the lifecycle', error?.graphQLErrors)
    }
  }

  return (
    <>
      <CopyLifecycleComponent
        handleCopyLifecycle={handleCopyLifecycle}
        closeCopyLifecycleDialog={closeCopyLifecycleDialog}
        showCopyLifecycleDialog={showCopyLifecycleDialog}
      />

      <Toast data-testid="copy-lifecycle-status" ref={toast} position="top-right" />
    </>
  )
}
